import * as EntityType from 'Models/EntityType';
import { ATTACHMENT_TYPE } from 'Constants/attachment';
import { PRODUCT, PROMOTION } from 'Constants/bounty/bountyType';
import { cloneDeep } from 'lodash';
import { getAttachmentStorageRef, uploadOneFile } from 'Services/StorageService';
import {
  getOrder,
  getNextAttachmentId,
  valueOfUploadFile,
  valueOfUploadFileWithUri,
  formatAttachment,
  getNextOrder,
} from 'Util/AttachmentsUtils';
import { getProductCoverUrl, getPromotionCoverUrl } from 'Util/BountyUtils';
import { getBountyDetailsLink, getPromotionDetailsLink } from '../util/LinkUtils';

export async function uploadAttachment(props) {
  const { attachmentId, attachment, attachmentStorageRef, order } = props;

  if (attachment.type === ATTACHMENT_TYPE.IMAGE) {
    const result = await uploadOneFile(valueOfUploadFile(attachment), attachmentStorageRef);
    return result ? formatAttachment(result, attachmentId, order) : null;
  }

  const result = await uploadOneFile(valueOfUploadFileWithUri(attachment), attachmentStorageRef);
  return result ? formatAttachment(result, attachmentId, order) : null;
}

export function uploadGenericBountyAttachments({ entityData, entityType, attachments, userData }) {
  const oldAttachments = entityData?.attachments?.attachments
    ? JSON.parse(JSON.stringify(entityData.attachments.attachments))
    : {};
  const takenAttachIds = Object.keys(oldAttachments) || [];
  let order = getOrder(Object.values(oldAttachments) || []);
  const promises = [];

  attachments
    .filter((attach) => !attach.id)
    .forEach((attach) => {
      const attachmentId = getNextAttachmentId(takenAttachIds);
      const attachmentStorageRef = getAttachmentStorageRef({
        entityType,
        hasAttachments: entityData,
        attachmentType: attach.type,
        attachmentId,
        userData,
      });

      promises.push(uploadAttachment({
        attachmentStorageRef,
        attachmentId,
        order,
        attachment: attach,
      }));

      order++;
      takenAttachIds.push(attachmentId);
    });

  return Promise
    .all(promises)
    .then((responses) => responses
      .filter((resp) => resp)
      .reduce((map, attach) => {
        map[attach.id] = attach; // eslint-disable-line
        return map;
      }, {}))
    .then((uploadedAttachments) => {
      const oldAttach = attachments
        .filter((attach) => attach.id)
        .reduce((map, attach) => {
          map[attach.id] = attach; // eslint-disable-line
          return map;
        }, {});

      return { attachments: { ...oldAttach, ...uploadedAttachments } };
    });
}

export function uploadBountyAttachments(bounty, attachments, userData) {
  return uploadGenericBountyAttachments({
    entityData: bounty,
    attachments,
    userData,
    entityType: EntityType.BOUNTY,
  });
}

export function uploadBountyResponseAttachments(response, attachments, userData) {
  return uploadGenericBountyAttachments({
    entityData: response,
    attachments,
    userData,
    entityType: EntityType.RESPONSE,
  });
}

export async function uploadUserProfilePicture(userInfo, attachment, userData) {
  const takenAttachIds = Object.keys(userInfo?.attachment || {}) || [];
  const order = getOrder(Object.values(userInfo?.attachment || {}));

  const attachmentId = getNextAttachmentId(takenAttachIds);
  const attachmentStorageRef = getAttachmentStorageRef({
    entityType: EntityType.USER,
    hasAttachments: userInfo,
    attachmentType: attachment.type,
    attachmentId,
    userData,
  });

  return uploadAttachment({
    attachmentStorageRef,
    attachmentId,
    order,
    attachment,
  });
}

export async function onPromotionAttachmentUpdate(oldBounty, promotions) {
  const oldAttachments = cloneDeep(oldBounty?.attachments?.attachments || {});
  const takenAttachIds = Object.keys(oldAttachments) || [];

  let order = getOrder(Object.values(oldAttachments));

  promotions.forEach((prom) => {
    const attachmentId = getNextAttachmentId(takenAttachIds);
    takenAttachIds.push(attachmentId);

    oldAttachments[attachmentId] = {
      id: attachmentId,
      title: prom?.description,
      url: getPromotionCoverUrl(prom) || null,
      type: ATTACHMENT_TYPE.IMAGE,
      meaning: PROMOTION,
      actionUrl: getPromotionDetailsLink(prom.id),
      order: getNextOrder(order),
    };

    order++;
  });

  return { attachments: oldAttachments };
}

export async function onProductAttachmentUpdate(oldBounty, products) {
  const oldAttachments = cloneDeep(oldBounty?.attachments?.attachments || {});
  const takenAttachIds = Object.keys(oldAttachments) || [];

  let order = getOrder(Object.values(oldAttachments));

  products.forEach((prod) => {
    const attachmentId = getNextAttachmentId(takenAttachIds);
    takenAttachIds.push(attachmentId);

    oldAttachments[attachmentId] = {
      id: attachmentId,
      title: prod?.product?.name,
      url: getProductCoverUrl(prod) || null,
      type: ATTACHMENT_TYPE.IMAGE,
      meaning: PRODUCT,
      actionUrl: getBountyDetailsLink(prod.id, PRODUCT),
      order: getNextOrder(order),
    };

    order++;
  });

  return { attachments: oldAttachments };
}
