import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool } from 'prop-types';

import { Autocomplete } from '@mui/material';
//  Components
import InputComponent from 'Common/components/InputComponent';

//  Actions/Selectors
import { updateDataSyncAction } from 'Store/actions/genericActions';
import * as modalSelectors from 'Modules/modal/store/selectors';
import { getIndustries } from 'Store/actions/industriesActions';
import { getSkills } from 'Store/actions/skillsActions';
import { industriesListSelector } from 'Store/selectors/industriesSelectors';
import { skillsListSelector } from 'Store/selectors/skillsSelectors';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';

//  Other resources
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA, MODAL_VALIDATION_ERRORS } from 'Store/reducerProperties';
import TextAreaAuto from 'Common/components/TextAreaAuto';
import TranslatableTitleContainer from 'Modules/posts/add/containers/TranslatableTitleContainer';
import { useIntl } from 'react-intl';
import { labelValueToValueKey } from 'Common/utils/formatters';

const JobPostContainer = ({ isTranslatableTitle }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const selectedBounty = useSelector(selectedBountySelector);
  const industriesDictionary = labelValueToValueKey(useSelector(industriesListSelector));
  const skillsData = labelValueToValueKey(useSelector(skillsListSelector));
  const validationErrors = useSelector(modalSelectors.validationErrorsSelector);
  const industries = useSelector((state) => modalSelectors.inputsData(state).industries || null);
  const skills = useSelector((state) => modalSelectors.inputsData(state).skills || []);
  const title = useSelector((state) => modalSelectors.inputsData(state).title);
  const maxPositions = useSelector((state) => modalSelectors.inputsData(state).maxPositions || 1);

  const dispatchChanges = (changes) => dispatch(updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, changes));

  const dispatchErrors = (errors) => dispatch(updateDataSyncAction(MODAL_NAMESPACE, MODAL_VALIDATION_ERRORS, { ...validationErrors, ...errors }));

  const transformSavedData = (values) => (
    values && Object.keys(values).length
      ? Object.keys(values)
        .map((key) => ({ value: values[key].name, key }))
      : []
  );

  useEffect(() => {
    dispatch(getIndustries());
  }, []);

  useEffect(() => {
    dispatch(getSkills());
  }, []);

  useEffect(() => {
    if (selectedBounty.jobInfo) {
      const { jobInfo: { industries: bountyIndustries, maxPositions: bountyMaxPositions, skillSet } } = selectedBounty;
      dispatchChanges({
        title: selectedBounty.title,
        maxPositions: bountyMaxPositions,
        industries: transformSavedData(bountyIndustries)[0],
        skills: transformSavedData(skillSet),
      });
    }
  }, [selectedBounty?.id]);

  const handleChanges = (name, value) => {
    dispatchChanges({ [name]: value });
    dispatchErrors({ [name]: null });
  };

  return (
    <div>
      <h4>{intl.formatMessage({ id: 'bounty.jobPost' })}</h4>
      <Autocomplete
        id="industries"
        fullWidth
        value={industries}
        options={industriesDictionary}
        isOptionEqualToValue={(option, { key }) => option.key === key}
        onChange={(e, newValue) => handleChanges('industries', newValue)}
        getOptionLabel={(option) => option.value || ''}
        renderInput={(params) => (
          <InputComponent
            {...params}
            label={intl.formatMessage({ id: 'bounty.formControls.labels.industry' })}
            name="industries"
            required
            validationErrors={validationErrors}
            size="small"
          />
        )}
      />
      <Autocomplete
        id="skills"
        fullWidth
        multiple
        autoComplete
        value={skills}
        options={skillsData}
        isOptionEqualToValue={(option, { key }) => option.key === key}
        onChange={(e, newValue) => handleChanges('skills', newValue)}
        getOptionLabel={(option) => option.value || ''}
        renderInput={(params) => (
          <InputComponent
            {...params}
            label={intl.formatMessage({ id: 'bounty.formControls.labels.skillset' })}
            name="skills"
            placeholder={intl.formatMessage({ id: 'bounty.formControls.labels.selectSkillset' })}
            required
            validationErrors={validationErrors}
            size="small"
          />
        )}
      />
      <InputComponent
        required
        type="number"
        label={intl.formatMessage({ id: 'bounty.formControls.labels.positions' })}
        name="maxPositions"
        value={maxPositions}
        validationErrors={validationErrors}
        handleChanges={({ target: { name, value } }) => handleChanges(name, parseInt(value, 10) || 1)}
        size="small"
      />
      {!isTranslatableTitle && (
        <TextAreaAuto
          aria-label="minimum height"
          placeholder={intl.formatMessage({ id: 'bounty.formControls.labels.jobTitle' })}
          name="title"
          id="title"
          required
          fullWidth
          validationErrors={validationErrors}
          handleChanges={({ target: { name, value } }) => handleChanges(name, value)}
          size="medium"
          value={title}
        />
      )}
      {isTranslatableTitle && (
        <TranslatableTitleContainer
          value={title}
          validationErrors={validationErrors}
        />
      )}
    </div>
  );
};

JobPostContainer.propTypes = {
  isTranslatableTitle: bool,
};

JobPostContainer.defaultProps = {
  isTranslatableTitle: false,
};

export default JobPostContainer;
