// Actions/Reducers
import { getHandlers } from 'Store/reducers/helpers';
import { ON_BOARDING_NAMESPACE } from 'Store/namespaces';

//  Others
import {
  // SELECTED_PRODUCT,
  USER_AGE,
  ACCEPTED_TERMS,
  ADDRESS,
  // CARD,
  TERMS,
  PRODUCTS,
  ONBOARDING_STEP,
  COMPANY_INFO,
} from '../utils/reducerProperties';

// initial state
const INIT_STATE = {
  // [SELECTED_PRODUCT]: {},
  [TERMS]: {
    [USER_AGE]: '',
    [ACCEPTED_TERMS]: '',
  },
  [ADDRESS]: {},
  // [CARD]: {},
  [PRODUCTS]: {},
  [ONBOARDING_STEP]: {},
  [COMPANY_INFO]: {},
};

const handlers = getHandlers(ON_BOARDING_NAMESPACE);

export default function onBoardingReducer(state = INIT_STATE, action = {}) {
  const handler = handlers[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}
