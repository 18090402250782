import { cloneDeep, isEqual } from 'lodash';
import { NotificationManager } from 'react-notifications';

import { asBountyInfo, getResponseType, getSurveyChoices } from 'Models/bounty/Bounty';
import { isAllowedToPublishResponse, isDraftState } from 'Models/bounty/BountyState';
import { isAllowedToRetract, isAllowedToRefuse, isAllowedToDelete, isAllowedToDemote } from 'Models/Response';
import { getIdentityType } from 'Models/IdentityMode';
import IntlMessage from 'Util/IntlMessages';

import { SENT_ITEMS } from 'Services/response/CommonService';
import { saveResponse, getRefs } from 'Services/response/OutboundResponseService';
import { addToQueue, postRequest } from 'Services/Api';
import { firebaseGetSentRef, firebaseGetCurrentUser } from 'Services/FirebaseService';
import { getOutboundPriorityForResponse, updateAllSortKeys } from 'Util/keyUtils';
import { uploadBountyResponseAttachments } from 'Services/UploadService';

import { queues } from 'Constants/queues';
import { identityType } from 'Constants/bounty/bounty';
import * as RESPONSE_STATE from 'Constants/response/responseState';
import { getChangedObj } from 'Modules/posts/bounty/utils/helpers';
import * as ResponseTypes from 'Constants/response/responseTypes';
import { BULLET_SEP } from 'Models/survey/Survey';
import { setSurveyChoice } from 'Services/bounty/BountyService';
import { getMyUser } from 'Services/BaseService';
import { formatReward } from '../../util/currencyUtils';

function getPriorityReference(type, ref) {
  if (type === SENT_ITEMS) {
    return ref.parent;
  }

  return ref;
}

function updateResponse(bounty, bountyResponse, updates) {
  const refs = getRefs(bounty, bountyResponse);

  Object.values(refs).forEach((ref) => (ref.update(updates)));
}

function updateOutboundPriority(bounty, bountyResponse) {
  const refs = getRefs(bounty, bountyResponse);

  Object.keys(refs).forEach((key) => {
    const ref = getPriorityReference(key, refs[key]);
    ref.setPriority(getOutboundPriorityForResponse(bountyResponse));
  });
}

function getOutboundResponse(bounty, bountyResponse) {
  if (!bounty) {
    console.log('Missing bounty'); // eslint-disable-line
    return;
  }

  if (bountyResponse && bountyResponse.identityMode && bountyResponse.identityMode.identityType === identityType.REAL) {
    return {
      ...bountyResponse,
      author: null,
    };
  }

  return bountyResponse;
}

async function newResponseInstance(author, bountyInfo) {
  const now = new Date().getTime();
  const creator = await getMyUser();

  const response = {
    creator,
    author: author || null,
    bountyInfo,
    type: getResponseType(bountyInfo),
    state: RESPONSE_STATE.DRAFT_STATE,
    createdAt: now,
    updatedAt: now,
  };

  updateAllSortKeys(response);
  return response;
}

export async function getNewResponse(bounty) {
  const bountyClone = cloneDeep(bounty);
  const mySelf = firebaseGetCurrentUser();
  const ref = firebaseGetSentRef(mySelf.uid).push();
  const bountyResponseId = ref.getKey();

  if (!bountyClone.outboundResponses) {
    bountyClone.outboundResponses = {};
  }

  const response = await newResponseInstance(bountyClone.myself, asBountyInfo(bountyClone));

  response.id = bountyResponseId;

  bountyClone.outboundResponses[response.id] = response;

  return response;
}

function updateResponseState(bounty, response, newState) {
  const oldState = response.state;

  const rr = getOutboundResponse(bounty, response);
  const update = { state: newState };

  updateResponse(bounty, rr, update);

  if (isDraftState(newState) !== isDraftState(oldState)) {
    updateOutboundPriority(bounty, rr);
  }
}

function publishResponse(bounty, response) {
  const isAllowedToPerformOp = isAllowedToPublishResponse(response.state);

  if (!isAllowedToPerformOp) {
    return NotificationManager.warning(<IntlMessage id="warnings.responses.cannotPublishResponse" />);
  }

  // if (isSpecialBounty(bounty.type)) {
  //   return addToQueue(queues.SEND_RESPONSE, { bountyId: bounty.id, response: response.note });
  // }

  updateResponseState(bounty, response, RESPONSE_STATE.ACTIVE_STATE);

  addToQueue(queues.PUBLISH_RESPONSE, {
    bountyId: bounty.id,
    responseId: response.id,
    identityType: getIdentityType(response.identityMode),
  });
}

export const createResponse = (bounty, response, userData, attachments) => async () => {
  const responseClone = cloneDeep(response);

  if (attachments && attachments.length) {
    responseClone.attachments = await uploadBountyResponseAttachments(responseClone, attachments, userData);
  }

  return new Promise((resolve) => {
    saveResponse(bounty, responseClone);
    publishResponse(bounty, responseClone);
    resolve();
  });
};

export const onPublishResponse = ({ bounty, response }) => () => (publishResponse(bounty, response));

export const reportResponse = ({ response, reason }) => () => {
  const me = firebaseGetCurrentUser();
  const isMine = response.creator && response.creator.id === me.uid;

  if (isMine) {
    return null;
  }

  addToQueue(queues.REPORT_RESPONSE, { responseId: response.id, message: reason });
};

function getResponseUpdates(origResponse, newResponse) {
  const updates = {};
  const changes = {};

  if (!isEqual(origResponse.note, newResponse.note)) {
    updates.note = newResponse.note;
    changes.note = getChangedObj(origResponse.note, newResponse.note);
  }

  const oldApplicant = origResponse.recommendation ? origResponse.recommendation.applicant : null;
  const newApplicant = newResponse.recommendation ? newResponse.recommendation.applicant : null;

  if (!isEqual(oldApplicant, newApplicant)) {
    updates.applicant = newApplicant;
    changes.applicant = getChangedObj(oldApplicant, newApplicant);
  }

  if (!isEqual(origResponse.attachments, newResponse.attachments)) {
    updates.attachments = newResponse.attachments;
    changes.attachments = getChangedObj(origResponse.attachments, newResponse.attachments);
  }

  if (origResponse.identityMode && newResponse.identityMode
    && (origResponse.identityMode.forced !== newResponse.identityMode.forced
      || origResponse.identityMode.identityType !== newResponse.identityMode.identityType)) {
    updates.identityMode = newResponse.identityMode;
    changes.identityMode = getChangedObj(origResponse.identityMode, newResponse.identityMode);
  }

  if (origResponse.visibilityMode !== newResponse.visibilityMode) {
    updates.visibilityMode = newResponse.visibilityMode;
    changes.visibilityMode = getChangedObj(origResponse.visibilityMode, newResponse.visibilityMode);
  }

  return { updates, changes };
}

export const updateBountyResponse = ({
  bounty, oldResponse, newResponse, attachments, userData,
}) => async () => {
  const me = firebaseGetCurrentUser();
  const isMine = newResponse.creator && newResponse.creator.id === me.uid;
  const editedAt = new Date().getTime();

  if (!isMine) {
    return NotificationManager.warning(<IntlMessage id="warnings.responses.notAllowedToEditResponse" />);
  }

  if (attachments && attachments.length) {
    newResponse.attachments = await uploadBountyResponseAttachments(newResponse, attachments, userData); // eslint-disable-line
  } else {
    newResponse.attachments = null; // eslint-disable-line
  }

  const { updates, changes } = getResponseUpdates(oldResponse, newResponse);

  if (!Object.keys(updates).length) {
    NotificationManager.info(
      <IntlMessage id="warnings.responses.nothingChangedInResponse" values={{ responseId: newResponse.id }} />,
    );
    return Promise.resolve();
  }

  updates.editedAt = editedAt;
  changes.editedAt = editedAt;

  const rr = getOutboundResponse(bounty, newResponse);
  updateResponse(bounty, rr, updates);

  addToQueue(queues.UPDATE_RESPONSE, { bountyId: bounty.id, responseId: newResponse.id, changes });
};

export const rejectResponse = ({ response, reason, callBack }) => {
  postRequest(queues.REJECT_RESPONSE, { responseId: response.id, reason }).then(() => callBack && callBack());
};

export const retractResponse = ({ bounty, response }) => {
  const me = firebaseGetCurrentUser();
  const isMine = response.creator && response.creator.id === me.uid;
  const isAllowedToPerformOp = isAllowedToRetract(response.state);

  if (!isAllowedToPerformOp) {
    return NotificationManager.warning(
      <IntlMessage id="warnings.responses.cannotAcceptResponse" values={{ response: response.state }} />,
    );
  }

  if (!isMine) {
    return NotificationManager.warning(<IntlMessage id="warnings.responses.dontHavePermissionToRetractResponse" />);
  }

  updateResponseState(bounty, response, RESPONSE_STATE.RETRACTED_STATE);
  addToQueue(queues.RETRACT_RESPONSE, { bountyId: bounty.id, responseId: response.id });
};

export const ackResponse = ({ bounty, response }) => {
  if (response.state !== RESPONSE_STATE.ACTIVE_STATE) {
    return NotificationManager.warning(
      <IntlMessage id="warnings.responses.cannotAcknowledgeResponse" values={{ response: response.state }} />,
    );
  }

  updateResponseState(bounty, response, RESPONSE_STATE.ACKED_STATE);
  addToQueue(queues.ACK_RESPONSE, { responseId: response.id });
};

export const acceptResponse = ({ response, callBack }) => postRequest(queues.ACCEPT_RESPONSE, { responseId: response.id }).then(() => callBack && callBack());

export const refuseResponse = ({ bounty, response, reason }) => {
  const isAllowedToPerformOp = isAllowedToRefuse(response.state);

  if (!isAllowedToPerformOp) {
    return NotificationManager.warning(
      <IntlMessage id="warnings.responses.cannotRefuseResponse" values={{ response: response.state }} />,
    );
  }

  const reasonTxt = reason || null;

  updateResponseState(bounty, response, RESPONSE_STATE.REFUSED_STATE);
  addToQueue(queues.REFUSE_RESPONSE, { responseId: response.id, reason: reasonTxt });

  return NotificationManager.warning(<IntlMessage id="warnings.responses.responseWasRefused" />);
};

const removeResponse = (bounty, bountyResponse) => {
  if (!bountyResponse) {
    return;
  }

  if (bounty.outboundResponses) {
    delete bounty.outboundResponses[bountyResponse.id]; // eslint-disable-line
  }

  const refs = getRefs(bounty, bountyResponse);
  Object.values(refs).forEach((ref) => (ref.remove()));
};

export const deleteResponse = ({ bounty, response, ignoreState }) => {
  const isAllowedToPerformOp = isAllowedToDelete(response.state);
  const me = firebaseGetCurrentUser();
  const isMine = response.creator && response.creator.id === me.uid;

  if (!ignoreState && !isAllowedToPerformOp) {
    return NotificationManager.warning(
      <IntlMessage id="warnings.responses.cannotDeleteResponse" values={{ response: response.state }} />,
    );
  }

  if (!isMine) {
    return NotificationManager.warning(<IntlMessage id="warnings.responses.dontHavePermissionToDeleteResponse" />);
  }

  const rr = getOutboundResponse(bounty, response);
  removeResponse(bounty, rr);

  addToQueue(queues.DELETE_RESPONSE, { bountyId: bounty.id, responseId: response.id });
};

export const demoteResponse = ({ bounty, response }) => {
  const isAllowedToPerformOp = isAllowedToDemote(response.state);

  if (!isAllowedToPerformOp) {
    return NotificationManager.warning(
      <IntlMessage id="warnings.responses.cannotDemoteResponse" values={{ response: response.state }} />,
    );
  }

  addToQueue(queues.DEMOTE_RESPONSE, { bountyId: bounty.id, responseId: response.id });
};

export const sendSurveyResponse = async ({ bounty, option, ownerId }) => {
  const { outboundResponses } = bounty;
  const wasChosen = getSurveyChoices(outboundResponses).includes(option.code);

  if (wasChosen) {
    if (outboundResponses) {
      Object.values(outboundResponses).forEach((outboundRes) => {
        if (outboundRes.type === ResponseTypes.CHOICE && outboundRes.choice === option.code) {
          deleteResponse({ bounty, response: outboundRes, ignoreState: true });
        }
      });
    }

    return null;
  }

  const bountyResponse = await getNewResponse(bounty);
  bountyResponse.type = ResponseTypes.CHOICE;
  bountyResponse.note = `${option.code}${BULLET_SEP} ${formatReward(option.reward, option.text)}`;
  bountyResponse.choice = option.code;

  saveResponse(bounty, bountyResponse);
  publishResponse(bounty, bountyResponse);

  setSurveyChoice({
    bounty, option, ownerId, populateSummaryEagerly: false,
  });

  return bountyResponse.id;
};

export function removeResponseAttachment(response, attachment) {
  addToQueue(queues.DELETE_ATTACHMENT, { responseId: response.id, attachmentId: attachment.id });
}
