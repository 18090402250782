import Validate from 'validate.js';
import { getEmptyLanguagesFromText } from 'Util/TranslationsUtils';

/**
 *  Overwrite validatejs isDefined method to allow empty strings.
 *  Returns false if the object is `null`, `undefined` or ''
 */
Validate.isDefined = (obj) => obj !== null && obj !== undefined && obj !== '';

Validate.validators.presence.message = 'This field is required';
Validate.validators.length.tooLong = 'This field needs to have %{count} characters or less';

Validate.validators.translatedTitlesNotEmpty = (value) => {
  const emptyLanguages = getEmptyLanguagesFromText(value);

  return emptyLanguages.length ? [`Empty title for ${emptyLanguages.join(', ')} language`] : null;
};

export function unflatten(object) {
  return Object.keys(object).reduce((result, objProperty) => {
    const properties = objProperty.split('.');
    const leaf = object[objProperty];

    properties.reduce((accumulator, currentValue, currentIndex, keys) => {
      if (!accumulator[currentValue]) {
        accumulator[currentValue] = keys.length - 1 === currentIndex ? leaf : {};
      }

      return accumulator[currentValue];
    }, result);

    return result;
  }, {});
}

export function validate(data, constraints, isFlat) {
  if (typeof data !== 'object') {
    return {};
  }

  const errors = Validate(data, constraints, { fullMessages: false }) || {};
  return isFlat ? errors : unflatten(errors);
}
