import { pointCurrency } from 'Models/PointCurrency';

export function hasStarsLeft(account) {
  const { points } = account;

  if (!points) {
    return false;
  }

  return (points[pointCurrency.STAR] && points[pointCurrency.STAR].amount) > 0
    || (points[pointCurrency.STAR2] && points[pointCurrency.STAR2].amount) > 0
    || (points[pointCurrency.STAR3] && points[pointCurrency.STAR3].amount) > 0;
}

export const isHavingVotesLeft = (accountInfo) => accountInfo && hasStarsLeft(accountInfo);
