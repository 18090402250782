import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getApplicationConfig } from 'Util/AppUtils';

import App from './App';
import packageJson from '../package.json';

// Instantiate Error Tracking
if (window && process.env.NODE_ENV === 'production') {
  const { SENTRY_CONFIG } = getApplicationConfig();
  Sentry.init({
    release: `${packageJson.name}@${packageJson.version}`,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
    ...SENTRY_CONFIG,
  });

  Sentry.configureScope((scope) => {
    scope.setTag('environment', process.env.NODE_ENV);
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
