import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import { Autocomplete, Box, TextField } from '@mui/material';

//  Actions
import * as modalSelectors from 'Modules/modal/store/selectors';
import { userDataSelector } from 'Store/selectors/settings';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import { firebasegetGroupMembers } from 'Services/FirebaseService';
import { updateDataSyncAction } from 'Store/actions/genericActions';

//  Other resource
import { convertObjToArray } from 'Util/helpers';
import { renderMemberAvatar } from 'Modules/group/util/MemberUtils';
import { MEMBER } from 'Models/Member';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA } from 'Store/reducerProperties';
import palette from 'Util/theme/palette';

const OnBehalfOfContainer = (props) => {
  const [users, setUsers] = useState([]);
  const { userData, selectedBounty, onBehalfOf } = props;
  const mySelf = {
    user: { ...userData.myself, name: 'Myself' },
  };

  useEffect(() => {
    if (userData?.owner) {
      firebasegetGroupMembers(userData.owner.id)
        .orderByChild('memberRoles')
        .equalTo(MEMBER)
        .once('value', (dataSnapshot) => {
          const dataSnapshotVal = convertObjToArray(dataSnapshot.val());

          setUsers([mySelf, ...dataSnapshotVal]);
        });
    }
  }, []);

  useEffect(() => {
    if (selectedBounty?.onBehalfOf) {
      props.updateDataSyncAction(
        MODAL_NAMESPACE,
        MODAL_INPUTS_DATA,
        {
          onBehalfOf: { user: selectedBounty.onBehalfOf },
        },
      );
    }
  }, [selectedBounty]);

  const handleSelection = (e, newValue) => {
    if (newValue?.user?.id === userData?.myself?.id) {
      return props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { onBehalfOf: null });
    }

    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { onBehalfOf: newValue });
  };

  return (
    <Box width="50%">
      <Autocomplete
        size="small"
        autoHighlight
        autoComplete
        disableClearable
        options={users}
        value={onBehalfOf || mySelf}
        onChange={handleSelection}
        getOptionLabel={(option) => option?.user?.name || ''}
        isOptionEqualToValue={(option, value) => option?.user?.id === value?.user?.id}
        renderOption={(autocompleteProps, option) => (
          <Box {...autocompleteProps} key={option?.user?.id} display="flex" alignItems="center">
            {renderMemberAvatar(option?.user, palette.common.darkRed)}
            <h4 className="ml-10 mb-0">{option?.user?.name}</h4>
          </Box>
        )}
        renderInput={(params) => <TextField fullWidth={false} {...params} label="On behalf of" />}
      />
    </Box>
  );
};

OnBehalfOfContainer.propTypes = {
  userData: object.isRequired,
  selectedBounty: object.isRequired,
  onBehalfOf: object,
  updateDataSyncAction: func.isRequired,
};

OnBehalfOfContainer.defaultProps = {
  onBehalfOf: {},
};

const mapStateToProps = (state) => ({
  onBehalfOf: modalSelectors.inputsData(state).onBehalfOf || null,
  userData: userDataSelector(state).data || {},
  selectedBounty: selectedBountySelector(state) || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnBehalfOfContainer);
