export const QUEUES_BASE = 'queues';
export const QUEUES_INDEX = '0';

export const queues = {
  // User events
  USER_ACTIVITY: ['user_activity'],
  UPDATE_DEVICE: ['device_update'],
  ACCEPT_DEVICE: ['device_accept'],
  REJECT_DEVICE: ['device_reject'],
  UPDATE_USER: ['user_update', 'user'],
  UPDATE_USER_PROFILE: ['user_profile_update', 'user'],
  UPDATE_USER_LOCATION: ['user_loc_update'],
  UPDATE_CAPS: ['user_caps'],
  CREATE_INVITE_IMG: ['user_invite_img'],
  REPORT_USER: ['user_report'],
  BLOCK_USER: ['user_block'],
  DELETE_USER: ['user_delete'],
  FETCH_USER: ['user_fetch'],
  FETCH_RANKINGS: ['rankings_fetch'],
  CASHOUT: ['user_cashout'],
  LEGAL: ['user_legal'],
  DEEPLINK_IN: ['user_deeplink_in', 'user'],
  NEW_CREDIT_CARD: ['user_credit_card_new'],
  RM_CREDIT_CARD: ['user_credit_card_rm'],
  DISMISS_BULLETIN: ['bulletin_dismiss'],
  BULLETIN_ACTION: ['bulletin_action'],
  CREATE_CHAT: ['chat_create'],
  CHAT_COMMAND: ['chat_command'],
  OPT_OUT: ['opt_out', 'queue', true],
  SEND_INVITE: ['invite_send'],
  RESEND_INVITE: ['invite_resend'],
  CANCEL_INVITE: ['invite_cancel'],
  ADD_GROUP: ['group_add'],
  DELETE_GROUP: ['group_delete'],
  UPDATE_GROUP: ['group_update'],
  ADD_MEMBER: ['member_add'],
  REMOVE_MEMBER: ['member_remove'],
  NEW_COMPANY: ['company_new'],
  ASSIGN_COMPANY: ['company_assign'],
  UPDATE_COMPANY: ['company_update'],
  LIST_EMPLOYEES: ['company_employees_list'],
  ADD_TRANSACTION: ['tx_add'],
  ADD_COMMENT: ['comment_add'],
  EDIT_COMMENT: ['comment_edit'],
  DELETE_COMMENT: ['comment_del'],
  PROMOTE_COMMENT: ['comment_promo'],
  UPDATE_ACCOUNT: ['account_update'],
  SEARCH_BOUNTIES: ['user_search_bounties'],
  REQUEST_ACCESS: ['user_req_access'],
  FORCE_SIGNOUT: ['user_signout_force'],
  SIGNOUT: ['user_signout'],

  //  Bounty events
  FETCH_BOUNTY: ['bounty_fetch'],
  ACTIVATE_BOUNTY: ['bounty_activate', 'bounty'],
  POST_BOUNTY: ['bounty_post', 'bounty'],
  ACCEPT_PARTICIPANT: ['bounty_participant_accept'],
  REJECT_PARTICIPANT: ['bounty_participant_reject'],
  SEAL_BOUNTY: ['bounty_seal'],
  APPLY_BOUNTY: ['bounty_apply'],
  DROPOFF_BOUNTY: ['bounty_dropoff'],
  WORK_STARTED_BOUNTY: ['bounty_work_started'],
  WORK_PAUSED_BOUNTY: ['bounty_work_paused'],
  WORK_PROGRESS_BOUNTY: ['bounty_work_progress'],
  WORK_COMPLETED_BOUNTY: ['bounty_work_completed'],
  SEND_BOUNTY: ['bounty_send', 'bounty'],
  UPDATE_BOUNTY: ['bounty_update'],
  DELETE_BOUNTY: ['bounty_delete'],
  RETRACT_BOUNTY: ['bounty_retract', 'bounty'],
  ARCHIVE_BOUNTY: ['bounty_archive', 'bounty'],
  SIMULATE_BOUNTY: ['bounty_simulate'],
  DISMISS_BOUNTY: ['bounty_dismiss'],
  PAUSE_BOUNTY: ['bounty_pause', 'bounty'],
  RESUME_BOUNTY: ['bounty_resume', 'bounty'],
  CLAIM_BOUNTY: ['bounty_claim'],
  CLOSE_BOUNTY: ['bounty_close'],
  INVOICE_BOUNTY: ['bounty_invoice', 'bounty'],
  PAY_BOUNTY: ['bounty_pay', 'bounty'],
  APPROVE_BOUNTY: ['bounty_approve'],
  REJECT_BOUNTY: ['bounty_reject'],
  REJECT_CLAIM: ['bounty_claim_reject'],
  CANCEL_CLAIM: ['bounty_claim_cancel'],
  REPORT_BOUNTY: ['bounty_report'],
  NOTIFY_RESUME_BOUNTY: ['bounty_resume_notify'],
  CREATE_SHARE_BOUNTY_IMG: ['bounty_share_img'],
  CREATE_BOUNTY_LINK: ['bounty_link'],
  CREATE_RECOMMENDATION: ['bounty_recommendation'],
  SEND_RECOMMENDATION: ['bounty_recommend'],
  CHANGE_BOUNTY_RATING_VALUE: ['bounty_change_rating_value'],
  PIN_BOUNTY: ['bounty_pin'],
  BOUNTY_EDIT_BADGE: ['bounty_edit_badge'],
  LIST_BOUNTY_PAYEE_CANDIDATES: ['bounty_payee_candidates'],
  LIST_BOUNTY_DISTRIBUTION_TARGETS: ['bounty_distribution'],
  MOVE_BOUNTY_TO_LIST: ['bounty_move_to_list'],
  REBOUNTY_BOUNTY: ['bounty_rebounty'],

  // Response events
  ACK_RESPONSE: ['response_ack'],
  ACCEPT_RESPONSE: ['response_accept'],
  REFUSE_RESPONSE: ['response_refuse'],
  PROMOTE_RESPONSE: ['response_promote'],
  UPDATE_RESPONSE: ['response_update'],
  DELETE_RESPONSE: ['response_delete'],
  TALENT_INTEREST: ['response_talent_interest'],
  BOUNTY_ATTACHMENT: ['response_attachment'],
  REPORT_RESPONSE: ['response_report'],
  CHANGE_RESPONSE_RATING_VALUE: ['response_change_rating_value'],
  REJECT_RESPONSE: ['response_reject'],
  RETRACT_RESPONSE: ['response_retract'],
  DEMOTE_RESPONSE: ['response_demote'],
  PIN_RESPONSE: ['response_pin'],
  RESPONSE_EDIT_BADGE: ['response_edit_badge'],
  PUBLISH_RESPONSE: ['response_publish'],
  SEND_RESPONSE: ['send_response'],

  GET_LIST: ['list_get'],
  REQUEST_BADGE: ['badge_request'],
  REMOVE_BADGE: ['badge_remove'],

  ADD_ATTACHMENT: ['attachment_add'],
  DELETE_ATTACHMENT: ['attachment_delete'],
  UPDATE_ATTACHMENT: ['attachment_update'],

  ADD_FAVORITE: ['favorite_add'],
  REMOVE_FAVORITE: ['favorite_remove'],

  TEST_RETRY_LATER: ['test_retry_later'],
  LIKE_BOUNTY: ['bounty_like'],
  LIKE_RESPONSE: ['response_like'],
  FOLLOW_USER: ['user_follow'],
};
