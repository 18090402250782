import * as reducerProperties from 'Store/reducerProperties';
import { createSelector } from 'reselect';
import { isBundle } from 'Models/bounty/Bounty';

export const userStreams = (state) => state.userStreamsReducer[reducerProperties.USER_STREAMS];

export const answerDetails = (state) => state.userStreamsReducer[reducerProperties.ANSWER_DETAILS];

export const answerComments = (state) => (
  state.userStreamsReducer[reducerProperties.ANSWER_DETAILS]
    ? state.userStreamsReducer[reducerProperties.ANSWER_DETAILS].comments : []
);

export const bountyDetails = (state) => state.userStreamsReducer[reducerProperties.BOUNTY_DETAILS];

export const bountyComments = (state) => state.userStreamsReducer[reducerProperties.BOUNTY_COMMENTS];
export const bountyRefreshCountSelector = (state) => state.userStreamsReducer[reducerProperties.REFRESH_BOUNTY_DETAILS];
export const bountyAnswers = (state) => state.userStreamsReducer[reducerProperties.BOUNTY_ANSWERS];
export const bountyAnswersComments = (state) => state.userStreamsReducer[reducerProperties.BOUNTY_ANSWERS_COMMENTS];

export const bountyDefaultFilter = (state) => state.userStreamsReducer[reducerProperties.BOUNTY_DEFAULT_FILTER];

export const bountyDefaultSort = (state) => state.userStreamsReducer[reducerProperties.BOUNTY_DEFAULT_SORT];

export const subBounties = (state) => state.userStreamsReducer[reducerProperties.SUB_BOUNTIES];
export const editingResponseSelector = (state) => state.userStreamsReducer[reducerProperties.EDITING_RESPONSE];
export const replyBountySelector = (state) => state.userStreamsReducer[reducerProperties.REPLY_BOUNTY];
export const bountyParentSelector = createSelector(
  bountyDetails,
  ({ data: bounty }) => (isBundle(bounty?.type) ? bounty : {}),
);
