import { MONEY, POINTS } from 'Util/RewardType';
import {
  TALENT_SEARCH,
  RECOMMENDATION,
  REALESTATE,
  COACHING,
  CHALLENGE,
  SURVEY,
  CLASSIFIED,
} from '../../bounty/constants/activityTypes';
import * as bountyTypes from '../../../../constants/bounty/bountyType';

export const types = {
  [TALENT_SEARCH]: {
    createClass: '',
    bountyType: bountyTypes.TALENT_SEARCH,
    [MONEY]: '1000',
    [POINTS]: '100',
  },
  [RECOMMENDATION]: {
    createClass: '',
    bountyType: bountyTypes.TALENT_RECOMMENDATION,
    [MONEY]: '1000',
    [POINTS]: '100',
  },
  [REALESTATE]: {
    createClass: '',
    bountyType: bountyTypes.REALESTATE_CUSTOMER,
    [MONEY]: '1000',
    [POINTS]: '100',
  },
  [COACHING]: {
    createClass: '',
    bountyType: bountyTypes.TMOB_COACHING,
    [MONEY]: '1000',
    [POINTS]: '100',
  },
  [CHALLENGE]: {
    createClass: '',
    bountyType: bountyTypes.TMOB_CHALLENGE,
    [MONEY]: '1000',
    [POINTS]: '100',
  },
  [SURVEY]: {
    createClass: '',
    bountyType: bountyTypes.SURVEY,
    [MONEY]: 'Optional...',
    [POINTS]: 'Optional...',
  },
  [CLASSIFIED]: {
    createClass: '',
    bountyType: bountyTypes.CLASSIFIED,
    [MONEY]: 'Optional...',
    [POINTS]: 'Optional...',
  },
};
