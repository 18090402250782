import { LOCALES } from 'Util/lang/langTexts';
import * as lang from 'Util/lang/entries';
import { merge } from 'lodash';
import { enLang } from 'Util/lang/entries';

const splitLanguage = (countryCode) => countryCode.substr(0, 2).toLowerCase();
const checkLanguage = (countryCode) => lang[`${countryCode}Lang`] || lang.enLang;
const mergeWithEngLang = (language) => merge(
  {},
  enLang,
  checkLanguage(splitLanguage(language)),
);

// This function is wrapper for the React Flag Kit library
// in order to get the flag of the country

const generateImageByCountryCode = (countryCode) => {
  const language = splitLanguage(countryCode);
  switch (language) {
    case 'en':
      return 'GB';
    case 'hy':
      return 'AM';
    case 'uk':
      return 'UA';
    case 'kk':
      return 'KZ';
    default:
      return language.toUpperCase();
  }
};

const browserLanguage = () => {
  const getBrowserLanguage = navigator.language
    || navigator.userLanguage
    || navigator.browserLanguage
    || navigator.systemLanguage;
  return getBrowserLanguage;
};

const getDefaultLanguage = () => {
  const locales = Object.values(LOCALES);
  const defaultLocale = locales.find(
    (locale) => (
      locale.toLowerCase() === navigator?.language?.toLowerCase())
      || (locale.split('-')[0] === navigator?.language?.toLowerCase()
      ),
  );

  return defaultLocale || LOCALES.ENGLISH;
};

export {
  splitLanguage,
  generateImageByCountryCode,
  browserLanguage,
  getDefaultLanguage,
  checkLanguage,
  mergeWithEngLang,
};
