import {
  STREAM_ROUTE,
  USER_SENTS_ROUTE,
  GROUPS_ROUTE,
  CHAT_ROUTE,
  MY_ACCOUNT_ROUTE,
  ORDER_LIST_ROUTE,
  COMPANY_SENTS_ROUTE,
  REPORTS_ROUTE,
  LEADERBOARD_ROUTE,
  PRODUCTS_ROUTE,
  PROMOTIONS_ROUTE,
  BADGE_COLLECTIONS_ROUTE,
  CREATE_NOTIFICATION_ROUTE,
  V2_APPLICATIONS_ROUTE,
  BLACK_LIST_APPLICANTS_ROUTE,
  COMPANIES_BY_ORG_TYPE,
  SETTINGS_ROUTE,
  RECEIVED_ORDER_LIST_ROUTE,
  MATCHES_ROUTE,
  REVIEWED_APPLICANTS_ROUTE,
  TERMINAL_ROUTE,
  V2_HOME_ROUTE,
  CANDIDATES_ROUTE,
  V2_JOBS_ROUTE,
  USER_MANAGEMENT_ROUTE,
} from 'Constants/routes';
import { generateLink } from 'Util/LinkUtils';
import * as ORG_TYPES from 'Constants/company/companyType';
import { stash } from 'Constants/common';

export const STREAM = {
  menu_title: 'sidebar.stream',
  menu_icon: 'icon-home-3',
  path: STREAM_ROUTE,
  child_routes: null,
};

export const V2HOME = {
  menu_title: 'sidebar.v2home',
  menu_icon: 'icon-home-3',
  path: V2_HOME_ROUTE,
  child_routes: null,
};

export const USER_SENTS = {
  menu_title: 'sidebar.sents',
  menu_icon: 'icon-send-3',
  path: USER_SENTS_ROUTE,
  child_routes: null,
};

export const GROUPS = {
  menu_title: 'sidebar.groups',
  menu_icon: 'icon-people',
  path: GROUPS_ROUTE,
  child_routes: null,
};

export const CHAT = {
  menu_title: 'sidebar.chat',
  menu_icon: 'icon-messages-3',
  path: CHAT_ROUTE,
  child_routes: null,
};

export const MY_ACCOUNT = {
  menu_title: 'sidebar.myAccount',
  menu_icon: 'icon-profile-circle',
  path: MY_ACCOUNT_ROUTE,
  child_routes: null,
};

export const ORDERS = {
  menu_title: 'sidebar.orders',
  menu_icon: 'history',
  path: ORDER_LIST_ROUTE,
  child_routes: null,
};

export const RECEIVED_ORDERS = {
  menu_title: 'sidebar.receivedOrders',
  menu_icon: 'archive',
  path: RECEIVED_ORDER_LIST_ROUTE,
  child_routes: null,
};

export const PRODUCTS = {
  menu_title: 'sidebar.stream',
  menu_icon: 'icon-home-3',
  path: PRODUCTS_ROUTE,
  child_routes: null,
};

export const COMPANY_SENTS = {
  menu_title: 'sidebar.companySents',
  menu_icon: 'icon-send-3',
  path: COMPANY_SENTS_ROUTE,
  child_routes: null,
};

export const REPORTS = {
  menu_title: 'sidebar.reports',
  menu_icon: 'icon-book',
  path: REPORTS_ROUTE,
  child_routes: null,
};

export const LEADERBOARD = {
  menu_title: 'sidebar.leaderboard',
  menu_icon: 'dashboard',
  path: LEADERBOARD_ROUTE,
  child_routes: null,
};

export const PROMOTIONS = {
  menu_title: 'sidebar.promotions',
  menu_icon: 'loyalty',
  path: PROMOTIONS_ROUTE,
  child_routes: null,
};

export const BADGES = {
  menu_title: 'sidebar.badges',
  menu_icon: 'shield',
  path: BADGE_COLLECTIONS_ROUTE,
  child_routes: null,
};

export const CREATE_NOTIFICATION = {
  menu_title: 'sidebar.notificationsManagement',
  menu_icon: 'notification_add',
  path: CREATE_NOTIFICATION_ROUTE,
  child_routes: null,
};

export const JOBS = {
  menu_title: 'sidebar.jobs',
  menu_icon: 'icon-briefcase',
  path: V2_JOBS_ROUTE,
  child_routes: null,
};

export const APPLICATIONS = {
  menu_title: 'sidebar.applications',
  menu_icon: 'icon-user-octagon',
  path: V2_APPLICATIONS_ROUTE,
  child_routes: null,
};

export const CANDIDATES = {
  menu_title: 'sidebar.candidates',
  menu_icon: 'icon-profile-2-user',
  path: CANDIDATES_ROUTE,
  child_routes: null,
};

export const REVIEWED_APPLICANTS = {
  menu_title: 'sidebar.reviewedApplications',
  menu_icon: 'icon-user-tick',
  path: REVIEWED_APPLICANTS_ROUTE,
  child_routes: null,
};

export const USER_MANAGEMENT = {
  menu_title: 'sidebar.admin',
  menu_icon: 'icon-book',
  path: USER_MANAGEMENT_ROUTE,
  child_routes: null,
};

export const BLACK_LIST_APPLICANTS = {
  menu_title: 'sidebar.blackListedApplicants',
  menu_icon: 'icon-user-remove',
  path: BLACK_LIST_APPLICANTS_ROUTE,
  child_routes: null,
};

export const AMBASSADOR = {
  menu_title: 'sidebar.ambassador',
  menu_icon: 'icon-location-tick',
  path: generateLink(COMPANIES_BY_ORG_TYPE, { orgType: ORG_TYPES.AMBASSADOR }),
  child_routes: null,
};

export const PARTNER = {
  menu_title: 'sidebar.partner',
  menu_icon: 'icon-medal',
  path: generateLink(COMPANIES_BY_ORG_TYPE, { orgType: ORG_TYPES.PARTNER }),
  child_routes: null,
};

export const EMPLOYER = {
  menu_title: 'sidebar.employer',
  menu_icon: 'icon-briefcase',
  path: generateLink(COMPANIES_BY_ORG_TYPE, { orgType: ORG_TYPES.EMPLOYER }),
  child_routes: null,
};

export const SETTINGS = {
  menu_title: 'sidebar.settings',
  menu_icon: 'icon-setting-2',
  path: generateLink(SETTINGS_ROUTE, { orgType: ORG_TYPES.EMPLOYER }),
  child_routes: null,
};

export const MATCH_DETAILS = {
  menu_title: 'sidebar.matches',
  menu_icon: 'sports_soccer',
  path: generateLink(MATCHES_ROUTE),
  child_routes: null,
};

export const TERMINAL = {
  menu_title: 'Terminal',
  menu_icon: 'icon-user-octagon',
  path: TERMINAL_ROUTE,
  child_routes: null,
};

export function sidebarNavLinks({ routePermissions, id, orgTypes }) {
  const organizationsItems = [];

  if (routePermissions.isAllowedToSeeCompanies) {
    orgTypes.forEach((type) => {
      if (itemsByOrgType[type]) {
        organizationsItems.push(itemsByOrgType[type]);
      }
    });
  }

  return {
    category2: [
      ...(!routePermissions.isAllowedToCreateJobs
        ? ([stash].includes(id) ? [PRODUCTS] : [STREAM])
        : []),
      ...(routePermissions.isAllowedToCreateJobs ? [V2HOME] : []),
      ...(routePermissions.isAllowedToSeeReports ? [REVIEWED_APPLICANTS] : []),
      ...(routePermissions.isAllowedToSeeResponses
        ? [APPLICATIONS, CANDIDATES]
        : []),
      ...(routePermissions.isAllowedToSeeReports ? [USER_MANAGEMENT] : []),
      ...(routePermissions.isAllowedToCreateJobs ? [JOBS] : []),
      ...(routePermissions.isAllowedToSeeMatch ? [MATCH_DETAILS] : []),
      ...(routePermissions.isAllowedToSeeMyAccount ? [MY_ACCOUNT] : []),
      ...(routePermissions.isAllowedToSeeMarket ? [ORDERS] : []),
      ...(routePermissions.isAllowedToSeeRecvOrders ? [RECEIVED_ORDERS] : []),
    ],
    category3: [
      ...(routePermissions.isAllowedToSeeResponses
        ? [USER_SENTS, GROUPS]
        : []),
      ...(routePermissions.isAllowedToSeeBlackList
        ? [BLACK_LIST_APPLICANTS]
        : []),
      ...organizationsItems,
      ...(routePermissions.isAllowedToNotify ? [CREATE_NOTIFICATION] : []),
      ...(routePermissions.isAllowedToSeeReports ? [CHAT] : []),
      ...(routePermissions.isAllowedToCreateBadge ? [BADGES] : []),
      ...(routePermissions.isAllowedToUploadProducts ? [COMPANY_SENTS] : []),
      ...(routePermissions.isAllowedToSeeReports ? [REPORTS] : []),
      ...(routePermissions.isAllowedToSeePromotions ? [PROMOTIONS] : []),
      ...(routePermissions.isAllowedToSeeLeaderBoard ? [LEADERBOARD] : []),
      ...(routePermissions.isAllowedShell ? [TERMINAL] : []),
    ],
  };
}

const itemsByOrgType = {
  [ORG_TYPES.AMBASSADOR]: AMBASSADOR,
  [ORG_TYPES.PARTNER]: PARTNER,
  [ORG_TYPES.EMPLOYER]: EMPLOYER,
};
