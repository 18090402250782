import {
  UPDATE_DATA_ASYNC,
  UPDATE_DATA_SYNC,
  IS_LOADING,
  IS_SORTABLE,
  UPDATE_DATA_ASYNC_PUSH,
  CLEAR_DATA_SYNC,
  DELETE_DATA_SYNC,
  UPDATE_LIST_SYNC,
  UPDATE_SIMPLE_DATA_SYNC,
  SET_DATA_SYNC,
  PUSH_DATA_SYNC,
} from 'Store/actionTypes';

export const updateDataAsyncAction = (namespace, reducerProperty, payload) => ({
  type: `${namespace}/${UPDATE_DATA_ASYNC}`,
  payload,
  reducerProperty,
});

export const updateDataAsyncActionPush = (namespace, reducerProperty, payload) => ({
  type: `${namespace}/${UPDATE_DATA_ASYNC_PUSH}`,
  payload,
  reducerProperty,
});

export const updateDataSyncAction = (namespace, reducerProperty, payload) => ({
  type: `${namespace}/${UPDATE_DATA_SYNC}`,
  payload,
  reducerProperty,
});

export const updateSimpleDataSyncAction = (namespace, reducerProperty, payload) => ({
  type: `${namespace}/${UPDATE_SIMPLE_DATA_SYNC}`,
  payload,
  reducerProperty,
});

export const updateListSyncAction = (namespace, reducerProperty, payload) => ({
  type: `${namespace}/${UPDATE_LIST_SYNC}`,
  payload,
  reducerProperty,
});

export const deleteDataSyncAction = (namespace, reducerProperty, payload) => ({
  type: `${namespace}/${DELETE_DATA_SYNC}`,
  payload,
  reducerProperty,
});

export const clearDataSyncAction = (namespace, reducerProperty) => ({
  type: `${namespace}/${CLEAR_DATA_SYNC}`,
  reducerProperty,
});

export const isLoadingAction = (namespace, reducerProperty, payload) => ({
  type: `${namespace}/${IS_LOADING}`,
  payload,
  reducerProperty,
});

export const isSortableAction = (namespace, reducerProperty, payload) => ({
  type: `${namespace}/${IS_SORTABLE}`,
  payload,
  reducerProperty,
});

export const setDataAction = (namespace, reducerProperty, payload) => ({
  type: `${namespace}/${SET_DATA_SYNC}`,
  reducerProperty,
  payload,
});

export const pushDataAction = (namespace, reducerProperty, payload) => ({
  type: `${namespace}/${PUSH_DATA_SYNC}`,
  reducerProperty,
  payload,
});
