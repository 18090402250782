import ProductListContainer from 'Modules/promotions/containers/ProductListContainer';
import IntlMessage from 'Util/IntlMessages';

// Others
import { buttons } from 'Constants/common';

export const addProductModal = ({ closeModal, handleSave }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <IntlMessage id="title.add.product" />,
  content: <ProductListContainer />,
  actions: {
    buttons: [
      {
        text: buttons.cancel,
        action: closeModal,
        color: 'secondary',
      },
      {
        text: buttons.saveSm,
        action: handleSave,
      },
    ],
  },
});
