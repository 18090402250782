// on android is handleCreateClick from BaseCreateActivity
import { cloneDeep } from 'lodash';
import { isAllowed, getAvailableBountyTypes } from 'Models/Settings';
import * as bountyType from 'Constants/bounty/bountyType';
import * as activityTypes from 'Modules/posts/bounty/constants/activityTypes';
import { CREATE_BOUNTY } from 'Models/Op';
import { isBundle, excludeFromBundle } from 'Models/bounty/Bounty';
import { PRODUCT_KIND } from 'Models/product/Product';

export function getAllowedBountyTypes(settings, userData) {
  let bountyTypes = [];

  if (isAllowed({ op: CREATE_BOUNTY.name, settings, userData })) {
    bountyTypes = getAvailableBountyTypes(settings, userData);
  }

  return bountyTypes;
}

export function generateBountyOptions({ bountyTypes, isAddToBundle = false }) {
  let bountyTypesClone = cloneDeep(bountyTypes);

  if (isAddToBundle) {
    bountyTypesClone = bountyTypesClone.filter((type) => !isBundle(type) && !excludeFromBundle(type));
  }

  return buildBountyMenu(bountyTypesClone);
}

/**
 * meniu definitions from "CreateBountyOptionsDialogFragment"
 */
const REQUEST_CODE_CREATE_BOUNTY = 30;
// const REQ_CODE_ADD_BUNDLE_CHILDREN = 31;
const REQ_CODE_CREATE_BUNDLE = 32;
const REQ_CODE_CREATE_QUEST = 35;

const TICKET_CONFIG = {
  bountyType: bountyType.PRODUCT,
  kind: PRODUCT_KIND.TICKET,
  name: 'bounty.createTicket',
  icon: 'confirmation_number',
  activityType: activityTypes.TICKET,
  requestCode: REQUEST_CODE_CREATE_BOUNTY,
};

export const bountyDialogOptionsConfig = {
  [bountyType.TMOB_CHALLENGE]: {
    bountyType: bountyType.TMOB_CHALLENGE,
    name: 'bounty.createChallenge',
    icon: 'emoji_events',
    activityType: activityTypes.CHALLENGE,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.TMOB_COACHING]: {
    bountyType: bountyType.TMOB_COACHING,
    name: 'bounty.createCoaching',
    icon: 'assignment',
    activityType: activityTypes.COACHING,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.ALBUM]: {
    bountyType: bountyType.ALBUM,
    name: 'bounty.createAlbum',
    icon: 'photo_album',
    activityType: activityTypes.ALBUM,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.NEWS]: {
    bountyType: bountyType.NEWS,
    name: 'bounty.createNews',
    icon: 'pages',
    activityType: activityTypes.NEWS,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.BANNER]: {
    bountyType: bountyType.BANNER,
    name: 'bounty.createBanner',
    icon: 'add_alarm',
    activityType: activityTypes.BANNER,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.PRODUCT]: {
    bountyType: bountyType.PRODUCT,
    name: 'bounty.createProduct',
    icon: 'local_offer',
    activityType: activityTypes.PRODUCT,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.TALENT_SEARCH]: {
    bountyType: bountyType.TALENT_SEARCH,
    name: 'bounty.createTalentSearch',
    icon: 'assignment', // TODO: change icon
    activityType: activityTypes.TALENT_SEARCH,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.REALESTATE_CUSTOMER]: {
    bountyType: bountyType.REALESTATE_CUSTOMER,
    name: 'bounty.createRealEstateCustomer',
    icon: 'assignment', // TODO: change icon
    activityType: activityTypes.REALESTATE,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.SURVEY]: {
    bountyType: bountyType.SURVEY,
    name: 'bounty.createPoll',
    icon: 'insert_chart',
    activityType: activityTypes.SURVEY,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.CLASSIFIED]: {
    bountyType: bountyType.CLASSIFIED,
    name: 'bounty.createClassified',
    icon: 'swap_vertical_circle',
    activityType: activityTypes.CLASSIFIED,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.SCORE]: {
    bountyType: bountyType.SCORE,
    name: 'bounty.askForScore',
    icon: 'star',
    activityType: activityTypes.SCORE,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.BUNDLE]: {
    bountyType: bountyType.BUNDLE,
    name: 'bounty.createBundle',
    icon: 'view_stream',
    activityType: activityTypes.BUNDLE,
    requestCode: REQ_CODE_CREATE_BUNDLE,
  },
  [bountyType.QUEST]: {
    bountyType: bountyType.QUEST,
    name: 'bounty.createGuest',
    icon: 'view_stream',
    activityType: activityTypes.QUEST,
    requestCode: REQ_CODE_CREATE_QUEST,
  },
  [bountyType.MCQ]: {
    bountyType: bountyType.MCQ,
    name: 'bounty.addMcq',
    icon: 'playlist_add_check',
    activityType: activityTypes.MCQ,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.JOB]: {
    bountyType: bountyType.JOB,
    name: 'bounty.createJob',
    icon: 'assignment',
    activityType: activityTypes.JOB,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.THREAD]: {
    bountyType: bountyType.THREAD,
    name: 'bounty.createCoaching',
    icon: 'message',
    activityType: activityTypes.THREAD,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.LIVE_STREAM]: {
    bountyType: bountyType.LIVE_STREAM,
    name: 'bounty.createLiveStream',
    icon: 'message',
    activityType: activityTypes.LIVE_STREAM,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.TRIVIA]: {
    bountyType: bountyType.TRIVIA,
    name: 'bounty.createTrivia',
    icon: 'quiz',
    activityType: activityTypes.TRIVIA,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [bountyType.LOTTERY]: {
    bountyType: bountyType.LOTTERY,
    name: 'bounty.createLottery',
    icon: 'local_activity',
    activityType: activityTypes.LOTTERY,
    requestCode: REQUEST_CODE_CREATE_BOUNTY,
  },
  [PRODUCT_KIND.TICKET]: TICKET_CONFIG,
  // [bountyType.CHECKLIST]: {
  //   bountyType: bountyType.CHECKLIST,
  //   name: labels.create_checklist,
  //   icon: 'check_box',
  //   activityType: activityTypes.CHECKLIST,
  //   requestCode: REQUEST_CODE_CREATE_BOUNTY,
  // },
};

const subtypesOptions = {
  [bountyType.PRODUCT]: [TICKET_CONFIG],
};

export function buildBountyMenu(bountyTypes) {
  const menuOptions = [];

  bountyTypes.forEach((type) => {
    const bountyConfig = bountyDialogOptionsConfig[type];

    if (bountyConfig) {
      menuOptions.push(bountyConfig);
    }

    if (subtypesOptions[type]) {
      menuOptions.push(...subtypesOptions[type]);
    }
  });

  return menuOptions;
}
