export const PENDING_CHATS = {
  fieldName: 'pendingChats',
  name: 'PENDING_CHATS',
};

export const TALKING = {
  fieldName: 'talking',
  name: 'TALKING',
};

export const REACH = {
  fieldName: 'reach',
  name: 'REACH',
};

export const REBOUNTIES = {
  fieldName: 'rebounties',
  name: 'REBOUNTIES',
};

export const SUBBOUNTIES = {
  fieldName: 'subBounties',
  name: 'SUBBOUNTIES',
};

export const SHARES_SENT = {
  fieldName: 'sharesSent',
  name: 'SHARES_SENT',
};

export const SHARES_RECV = {
  fieldName: 'sharesRecv',
  name: 'SHARES_RECV',
};

export const PARTICIPANT_COUNT = {
  fieldName: 'participantCount',
  name: 'PARTICIPANT_COUNT',
};

export const RESPONSE_COUNT = {
  fieldName: 'responseCount',
  name: 'RESPONSE_COUNT',
};

export const COMMENT_COUNT = {
  fieldName: 'commentCount',
  name: 'COMMENT_COUNT',
};
