import moment from 'moment';
import { DAY_IN_MILLIS, HOUR_IN_MILLIS, MINUTE_IN_MILLIS } from 'Constants/dateUtils';

export function getDaysHrsMinTimeUtilFormat(timeRefMs, timeUntil) {
  const timeDiff = new Date(timeUntil) - new Date(timeRefMs);

  const days = Math.floor(timeDiff / DAY_IN_MILLIS);
  const hours = Math.floor(timeDiff / HOUR_IN_MILLIS) % 24;
  const minutes = Math.floor(timeDiff / MINUTE_IN_MILLIS) % 60;

  return {
    days,
    hours,
    minutes,
  };
}

export function isExpired(expiresAt) {
  return expiresAt && moment(expiresAt).isBefore(moment());
}

export function formatExpireInLabel(expiresAt) {
  const { days, hours, minutes } = getDaysHrsMinTimeUtilFormat(new Date().getTime(), new Date(expiresAt));
  const expiresInLabel = 'expires in';

  if (days) {
    return `${expiresInLabel} ${`${days}d`}`;
  }

  if (hours) {
    return `${expiresInLabel} ${`${hours}h`}`;
  }

  if (minutes) {
    return `${expiresInLabel} ${`${minutes}m`}`;
  }

  return '';
}

export function formatChatTimestamp(timestamp) {
  if (moment(timestamp).isBefore(moment(), 'day')) {
    return moment(timestamp).format('LLLL');
  }

  return moment(timestamp).format('hh:mm:ss');
}

export function formatTime(timestamp) {
  return moment(timestamp).format('hh:mm');
}

export function formatLongDate(timestamp) {
  return moment(timestamp).format('LLLL');
}
