import { stash } from 'Constants/common';

const stashConfig = {
  FLAVOR: 'stash',
  id: stash,
  title: 'Stash',
  name: 'Stash app',
  brandName: 'Stash',
  appLinkSchema: 'stash',
  appTld: 'mystash.co',
  logo: {
    logoStyle: 'ml-5',
    containerStyle: 'MuiToolbar-regular-79 no-background-color',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDL9H96j2PULpu_TbXkKoKtYkUH62EOcrw',
    authDomain: 'stash-prod.firebaseapp.com',
    databaseURL: 'https://stash-prod.firebaseio.com',
    projectId: 'stash-prod',
    storageBucket: 'stash-prod.appspot.com',
    messagingSenderId: '272756466347',
    appId: '1:272756466347:web:e29aff20e0772500de4dee',
    measurementId: 'G-FXB80037Z7',
  },
  stripeKey: 'pk_test_Wadgf1aQfWi4h1btcl6Nd5Ox',
  API_BASE_URL: 'https://api.mystash.co/api/v1',
  API_REPORT: 'https://api.mystash.co/download/report',
  login: {
    customCss: '',
    loginWithPin: true,
  },
  algolia_app_id: 'APYEOJ4UNH',
  algolia_api_key: '534e91f42e5cc361776ab3c7b4dfb5a1',
  TERMS_OF_SERVICE_URL: 'http://mystash.co/terms/',
  PRIVACY_STATEMENT_URL: 'http://mystash.co/privacy/',
};

export default stashConfig;
