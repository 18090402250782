import { getApiData } from 'Services/Api';
import { getQueryString } from 'Util/GeneralUtils';

export const JobsService = {
  async getJobs(options) {
    const queryString = getQueryString(options);

    return getApiData(`/bounties/jobs${queryString}`);
  },
};

export default JobsService;
