import { object, func, array, bool } from 'prop-types';
import { FormControl, FormLabel, OutlinedInput, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const propTypes = {
  data: object.isRequired,
  fields: array.isRequired,
  handleInputChanges: func.isRequired,
  handleBlur: func,
  onSubmit: func,
  smallInputs: bool,
};

const defaultProps = {
  smallInputs: false,
  onSubmit: () => {},
  handleBlur: () => {},
};

const useStyles = makeStyles(() => ({
  labelRemove: {
    height: '47px',
    padding: '.375rem .7rem .375rem 0',

    '& .MuiOutlinedInput-input': {
      height: '16px',
    },

    '& .MuiOutlinedInput-notchedOutline > legend': {
      display: 'none',
    },
  },
}));

const GenericForm = ({ data, fields, handleInputChanges, handleBlur, onSubmit, smallInputs }) => {
  const classes = useStyles();
  return (
    <form noValidate onSubmit={onSubmit}>
      {fields.map(({ name, placeholder, icon, type = 'text', label, ...remainingProps }) => (
        <FormControl fullWidth key={name}>
          {label && <FormLabel>{label}</FormLabel>}
          <OutlinedInput
            {...remainingProps}
            type={type}
            value={data[name] || ''}
            name={name}
            id={name}
            className={smallInputs ? '' : classes.labelRemove}
            placeholder={placeholder}
            onChange={handleInputChanges}
            onBlur={handleBlur}
            endAdornment={(icon
              && (
              <InputAdornment position="end">
                <span className="has-icon material-icons">{icon}</span>
              </InputAdornment>
              )
            )}
          />
        </FormControl>
      ))}
    </form>
  );
};

GenericForm.propTypes = propTypes;
GenericForm.defaultProps = defaultProps;

export default GenericForm;
