import * as reducerProperties from 'Store/reducerProperties';

export const settings = (state) => state.modalReducer[reducerProperties.MODAL_SETTINGS];
export const drawerSettingsSelector = (state) => state.modalReducer[reducerProperties.DRAWER_SETTINGS];

export const inputsData = (state) => state.modalReducer[reducerProperties.MODAL_INPUTS_DATA];
export const optionsData = (state) => state.modalReducer[reducerProperties.MODAL_OPTIONS_DATA];
export const validationErrorsSelector = (state) => state.modalReducer[reducerProperties.MODAL_VALIDATION_ERRORS];
export const surveyOptionsData = (state) => state.modalReducer[reducerProperties.MODAL_SURVEY_OPTIONS_DATA];
export const rating = (state) => state.modalReducer[reducerProperties.MODAL_RATING];

export const selectedBounties = (state) => state.modalReducer[reducerProperties.MODAL_SELECTED_BOUNTIES];
export const selectedMembers = (state) => state.modalReducer[reducerProperties.MODAL_SELECTED_MEMBERS];
export const selectedPromotionsSelector = (state) => state.modalReducer[reducerProperties.PROMOTION_TO_ATTACH] || [];
export const selectedProductsSelector = (state) => state.modalReducer[reducerProperties.PRODUCT_TO_ATTACH] || [];

export const availablePts = (state) => state.modalReducer[reducerProperties.MODAL_AVAILABLE_PTS];
export const isLoading = (state) => state.modalReducer[reducerProperties.MODAL_IS_LOADING];
export const inputLanguage = (state) => state.modalReducer[reducerProperties.MODAL_INPUT_LANGUAGE];
