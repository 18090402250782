import { useEffect } from 'react';
import { connect } from 'react-redux';
import { object, func, string } from 'prop-types';
import { Box } from '@mui/material';

// Components
import CreatePromotionComponent from 'Modules/promotions/components/CreatePromotionComponent';
import { LoadingComponent } from 'Common/components';
import ModalButtons from 'Modules/modal/components/ModalButtons';

// Actions/selectors
import * as modalSelectors from 'Modules/modal/store/selectors';
import { editPromotion, createPromotion } from 'Modules/promotions/store/operations';
import { updateDataSyncAction, updateListSyncAction, clearDataSyncAction } from 'Store/actions/genericActions';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import { closeDrawer } from 'Modules/modal/store/operations';

// Others
import { SENTS_NAMESPACE, MODAL_NAMESPACE } from 'Store/namespaces';
import {
  SELECTED_BOUNTY,
  MODAL_INPUTS_DATA,
  DRAWER_SETTINGS,
  PRODUCT_TO_ATTACH,
} from 'Store/reducerProperties';
import { parsePromotion } from 'Models/Promotion';
import { formatDiscount } from 'Util/GeneralUtils';
import makeStyles from '@mui/styles/makeStyles';
import { selectedProductsSelector } from 'Modules/modal/store/selectors';

const propTypes = {
  selectedProductData: object.isRequired,
  closeDrawer: func.isRequired,
  createPromotion: func.isRequired,
  editPromotion: func.isRequired,
  updateListSyncAction: func.isRequired,
  promotionType: string.isRequired,
  updateDataSyncAction: func.isRequired,
  selectedBounty: object.isRequired,
  clearDataSyncAction: func.isRequired,
};

const CreatePromotionContainer = (props) => {
  const {
    selectedBounty,
    selectedProductData,
    promotionType,
  } = props;
  const bountyId = selectedBounty?.id;
  const isEditMode = !!selectedBounty.id;
  const isLoading = !!(isEditMode && !Object.keys(selectedBounty).length);
  const classes = useStyles();

  useEffect(() => {
    if (bountyId) {
      const {
        type, products, couponCode, ttl, discountInfo, ...remainingCustomValues
      } = parsePromotion(selectedBounty.description);

      const selectedProduct = products ? products[0] : null;
      const parsedResponse = { ...selectedBounty, ...remainingCustomValues };
      props.updateDataSyncAction(SENTS_NAMESPACE, SELECTED_BOUNTY, parsedResponse);
      props.updateListSyncAction(MODAL_NAMESPACE, PRODUCT_TO_ATTACH, [selectedProduct]);
      props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, {
        ttl: ttl || null,
        type: type || null,
        couponCode: couponCode || null,
        activateAt: selectedBounty.activateAt || null,
        expiresAt: selectedBounty.expiresAt || null,
        discount: formatDiscount(discountInfo?.percentageDiscount),
      });
    }

    return () => {
      props.clearDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA);
      props.clearDataSyncAction(SENTS_NAMESPACE, SELECTED_BOUNTY);
      props.clearDataSyncAction(MODAL_NAMESPACE, DRAWER_SETTINGS);
      clearProduct();
    };
  }, [bountyId]);

  const onCreatePromotion = () => {
    props.createPromotion()
      .then(() => {
        props.closeDrawer();
      });
  };

  const onEditPromotion = () => {
    props.editPromotion(selectedBounty)
      .then(() => {
        props.closeDrawer();
      });
  };

  const submit = () => {
    if (isEditMode) {
      return onEditPromotion();
    }

    onCreatePromotion();
  };

  const cancel = () => {
    props.closeDrawer();
  };

  const clearProduct = () => {
    props.clearDataSyncAction(MODAL_NAMESPACE, PRODUCT_TO_ATTACH);
  };

  const getTitle = () => (
    bountyId ? 'Create promotion' : 'Edit promotion'
  );

  return (
    <LoadingComponent isLoading={isLoading}>
      <div className={classes.root}>
        <h3>{getTitle()}</h3>
        <CreatePromotionComponent
          selectedProduct={selectedProductData}
          selectedPromotionType={promotionType}
          selectedBounty={selectedBounty}
          clearProduct={clearProduct}
        />
        <Box pb={3}>
          <ModalButtons
            onDismiss={cancel}
            onSubmit={submit}
          />
        </Box>
      </div>
    </LoadingComponent>
  );
};

const mapStateToProps = (state) => ({
  selectedBounty: selectedBountySelector(state) || {},
  selectedProductData: selectedProductsSelector(state)[0] || {},
  promotionType: modalSelectors.inputsData(state).type || '',
});

const mapDispatchToProps = {
  createPromotion,
  editPromotion,
  updateDataSyncAction,
  updateListSyncAction,
  clearDataSyncAction,
  closeDrawer,
};

CreatePromotionContainer.propTypes = propTypes;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '500px',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100vw - 50px)',
    },
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(CreatePromotionContainer);
