// Actions/Reducers
import { getHandlers } from 'Store/reducers/helpers';

// Others
import { USER_SENTS, COMPANY_SENTS, SELECTED_BOUNTY, COMPANY_SENTS_DETAILS, COMPANY_SUB_SENTS } from 'Store/reducerProperties';
import { SENTS_NAMESPACE } from 'Store/namespaces';

// initial state
const INIT_STATE = {
  [USER_SENTS]: [],
  [COMPANY_SENTS]: [],
  [COMPANY_SUB_SENTS]: [],
  [SELECTED_BOUNTY]: {},
  [COMPANY_SENTS_DETAILS]: {},
};

const handlerReducer = getHandlers(SENTS_NAMESPACE);

export default function sentsReducer(state = INIT_STATE, action = {}) {
  const handler = handlerReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}
