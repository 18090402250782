export const NONE_STATE = 'NONE';
export const DRAFT_STATE = 'DRAFT';
export const PENDING_STATE = 'PENDING';
export const ACTIVE_STATE = 'ACTIVE';
export const SEALED_STATE = 'SEALED';
export const PAUSED_STATE = 'PAUSED';
export const CLOSED_STATE = 'CLOSED';
export const EXPIRED_STATE = 'EXPIRED';
export const RETRACTED_STATE = 'RETRACTED';
export const REJECTED_STATE = 'REJECTED';
export const DECLINED_STATE = 'DECLINED';
export const REFUSED_STATE = 'REFUSED';
export const ACCEPTED_STATE = 'ACCEPTED';
export const ARCHIVED_STATE = 'ARCHIVED';
export const PAYING_STATE = 'PAYING';
export const PAID_STATE = 'PAID';
export const ACKED_STATE = 'ACKED';
export const PROGRESSING_STATE = 'PROGRESSING';
export const DELETED_STATE = 'DELETED';
export const UNKNOWN_STATE = 'UNKNOWN';

export const STATE_LABELS = {
  [DRAFT_STATE]: 'DRAFT',
  [RETRACTED_STATE]: 'PENDING',
  [PENDING_STATE]: 'PENDING',
  [REJECTED_STATE]: 'REJECTED',
  [REFUSED_STATE]: 'REFUSED',
  [ACCEPTED_STATE]: 'ACCEPTED',
};
