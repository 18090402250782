import { SEE_BUY_SELL, SEE_FAVORITES, SEE_MARKET } from 'Models/Op';
import * as listKind from './listKind';
import * as codeType from './codeType';
import * as bountyType from '../../../../constants/bounty/bountyType';

export const NONE = 'NONE';
export const STREAM = 'STREAM';
export const EDGE_COACHINGS = 'EDGE_COACHINGS';
export const EDGE_IN_FOCUS = 'EDGE_IN_FOCUS';
export const EDGE_CHALLENGES = 'EDGE_CHALLENGES';
export const BUY_SELL = 'BUY_SELL';
export const MARKET = 'MARKET';
export const ACADEMIA = 'ACADEMIA';
export const GOODS = 'GOODS';
export const COMMUNITY = 'COMMUNITY';
export const CUSTOM = 'CUSTOM';
export const WEBVIEW = 'WEBVIEW';
export const PROGRAM = 'PROGRAM';
export const FAVORITES = 'FAVORITES';
export const AUTOSUGGESTIONS = 'AUTOSUGGESTIONS';
export const JOB = 'JOBS';
export const APPLICATIONS = 'APPLICATIONS';
export const MATCH_DETAILS = 'MATCH_DETAILS';

const NONE_DATA = {
  name: NONE,
  code: null,
  bountyType: null,
  kind: null,
};

const STREAM_DATA = {
  name: STREAM,
  code: null,
  kind: listKind.INBOX,
  bountyType: null,
  id: STREAM,
};

const EDGE_COACHINGS_DATA = {
  name: EDGE_COACHINGS,
  code: codeType.CODE_REGULAR,
  bountyType: bountyType.TMOB_COACHING,
  kind: listKind.BUCKET,
  op: null,
};

const EDGE_IN_FOCUS_DATA = {
  name: EDGE_IN_FOCUS,
  code: codeType.CODE_IN_FOCUS,
  bountyType: bountyType.TMOB_COACHING,
  kind: listKind.BUCKET,
  op: null,
};
const EDGE_CHALLENGES_DATA = {
  name: EDGE_CHALLENGES,
  code: codeType.CODE_REGULAR,
  bountyType: bountyType.TMOB_CHALLENGE,
  kind: listKind.FILTER_BY_BOUNTY_TYPE,
  op: null,
};
const BUY_SELL_DATA = {
  name: BUY_SELL,
  code: codeType.CODE_REGULAR,
  bountyType: bountyType.CLASSIFIED,
  kind: listKind.FILTER_BY_BOUNTY_TYPE,
  op: SEE_BUY_SELL,
};

const CUSTOM_DATA = {
  name: CUSTOM,
  code: codeType.CODE_CUSTOM,
  bountyType: null,
  kind: null,
  op: null,
};

const FAVORITES_DATA = {
  name: FAVORITES,
  code: null,
  bountyType: null,
  kind: listKind.REFS,
  op: SEE_FAVORITES,
};

const AUTOSUGGESTIONS_DATA = {
  name: AUTOSUGGESTIONS,
  code: codeType.CODE_CUSTOM,
  bountyType: bountyType.AUTOSUGGESTION,
  kind: listKind.SPECIAL,
  userSpecial: null,
  companySpecial: 'autosuggestions',
};

const MARKET_DATA = {
  name: MARKET,
  code: codeType.CODE_CUSTOM,
  bountyType: bountyType.PRODUCT,
  kind: listKind.REST_CALL,
  op: SEE_MARKET,
};

const WEBVIEW_DATA = {
  name: WEBVIEW,
  code: null,
  bountyType: null,
  kind: listKind.WEBVIEW,
  url: 'https://www.youkno.ai',
};

export const listTypeData = {
  [NONE]: NONE_DATA,
  [STREAM]: STREAM_DATA,
  [EDGE_COACHINGS]: EDGE_COACHINGS_DATA,
  [EDGE_IN_FOCUS]: EDGE_IN_FOCUS_DATA,
  [EDGE_CHALLENGES]: EDGE_CHALLENGES_DATA,
  [BUY_SELL]: BUY_SELL_DATA,
  [MARKET]: MARKET_DATA,
  [CUSTOM]: CUSTOM_DATA,
  [FAVORITES]: FAVORITES_DATA,
  [AUTOSUGGESTIONS]: AUTOSUGGESTIONS_DATA,
  [WEBVIEW]: WEBVIEW_DATA,
  [AUTOSUGGESTIONS]: AUTOSUGGESTIONS_DATA,
};
