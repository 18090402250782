import { firebaseGetCurrentlySignedInUser } from 'Services/FirebaseService';
import { getApiData, postWithEmptyResponse } from 'Services/Api';
import { formatApiRoute } from 'Util/api/apiHelpers';
import { USERS_DATA } from 'Constants/apiRoutes';
import { getDefaultLanguage } from 'Util/languagesHelpers';

const getLocaleServer = (userID, setLocale) => {
  if (!userID) return;
  const path = formatApiRoute(
    {
      endpoint: USERS_DATA,
      params: { userId: userID },
    },
  );
  getApiData(path).then((data) => {
    if (data.locale) {
      setLocale(data.locale);
    } else {
      setLocale(getDefaultLanguage());
    }
  });
};

const postLocaleServer = (userID, data) => {
  if (!userID) return;
  const path = `/users/${userID}/locale?lang=${data}`;
  firebaseGetCurrentlySignedInUser()
    .then((token) => {
      postWithEmptyResponse(path, token).catch((e) => console.error('Error posting locale to server', e));
    });
};

export { getLocaleServer, postLocaleServer };
