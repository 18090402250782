import { JoiningStrategy } from 'Models/Group';
import { USER_IDENTITY_CONFIG_STATE, KYC_LEVELS } from 'Models/User';

export const KYC_ACTION_PERFORMED = [
  USER_IDENTITY_CONFIG_STATE.VERIFIED,
  USER_IDENTITY_CONFIG_STATE.REJECTED,
];

export function getEmptyContentResId(isGroupMembers, joiningStrategy, defaultEmptyMsg) {
  if (!isGroupMembers && joiningStrategy != null) {
    if (joiningStrategy === JoiningStrategy.INVITE_ONLY) {
      return 'empty_state_invite_only_subtitle';
    }

    if (joiningStrategy === JoiningStrategy.NEEDS_APPROVAL) {
      return 'empty_state_needs_approval_subtitle';
    }
  }

  return defaultEmptyMsg;
}

export function getCutName(fullName) {
  if (!fullName) {
    return '';
  }
  const splitNameArray = fullName.split(' ');
  splitNameArray.forEach((item, index) => {
    if (!item) {
      splitNameArray.splice(index, 1);
    }
  });

  if (splitNameArray.length === 1) {
    return fullName;
  }

  if (splitNameArray.length > 1) {
    return `${splitNameArray[0]} ${splitNameArray[1].charAt(0)}.`;
  }
}

export const sortUsersDueRelevantDate = (arr, entity = '') => {
  if (entity === 'applicant') {
    return arr.sort((a, b) => {
      if (a.userData?.createdAt < b.userData?.createdAt) {
        return 1;
      }
      if (a.userData?.createdAt > b.userData?.createdAt) {
        return -1;
      }
      return 0;
    });
  }
  if (entity === 'notifications') {
    return arr.sort((a, b) => {
      if (a?.sentAt < b?.sentAt) {
        return 1;
      }
      if (a?.sentAt > b?.sentAt) {
        return -1;
      }
      return 0;
    });
  }

  return arr.sort((a, b) => {
    if (a?.createdAt < b?.createdAt) {
      return 1;
    }
    if (a?.createdAt > b?.createdAt) {
      return -1;
    }
    return 0;
  });
};

export function getLevelsReadyForKyc(identityInfo) {
  const levels = Object.keys(identityInfo)
    .filter((key) => !KYC_ACTION_PERFORMED.includes(identityInfo[key]));

  return levels.reduce((acc, level) => {
    acc[level] = false;
    return acc;
  }, {});
}

export function isReadyForKyc(identityConfigInfo) {
  if (!identityConfigInfo) {
    return false;
  }

  // If at least one level in status defined under KYC_ACTION_PERFORMED, hide kyc buttons
  return !Object
    .values(identityConfigInfo)
    .find((item) => KYC_ACTION_PERFORMED.includes(item.state));
}

export function getKYCVisibleState(identityConfigInfo) {
  const levels = Object
    .keys(identityConfigInfo)
    .filter((key) => KYC_ACTION_PERFORMED.includes(identityConfigInfo[key].state));

  if (!levels) {
    return null;
  } if (levels.length === 1) {
    return {
      ...identityConfigInfo[levels[0]],
      level: levels[0],
    };
  }

  const internationalLevel = levels.find((level) => level === KYC_LEVELS.INTERNATIONAL);
  const europeLevel = levels.find((level) => level === KYC_LEVELS.EUROPE);
  const localLevel = levels.find((level) => level === KYC_LEVELS.LOCAL);
  const mainLevel = internationalLevel || europeLevel || localLevel;

  return {
    ...identityConfigInfo[mainLevel],
    level: mainLevel,
  };
}

export function getKycLevel(identity) {
  const ALLOWED_STATES = [
    USER_IDENTITY_CONFIG_STATE.REJECTED,
    USER_IDENTITY_CONFIG_STATE.VERIFIED,
    USER_IDENTITY_CONFIG_STATE.PENDING,
  ];

  if (identity[KYC_LEVELS.INTERNATIONAL] && ALLOWED_STATES.includes(identity[KYC_LEVELS.INTERNATIONAL].state)) {
    return KYC_LEVELS.INTERNATIONAL;
  }

  if (identity[KYC_LEVELS.EUROPE] && ALLOWED_STATES.includes(identity[KYC_LEVELS.EUROPE].state)) {
    return KYC_LEVELS.EUROPE;
  }

  if (identity[KYC_LEVELS.LOCAL] && ALLOWED_STATES.includes(identity[KYC_LEVELS.LOCAL].state)) {
    return KYC_LEVELS.LOCAL;
  }

  return null;
}

export function getIdentityInfo(identityConfigInfo) {
  return Object.keys(identityConfigInfo).reduce((acc, level) => {
    acc[level] = identityConfigInfo[level].state;
    return acc;
  }, {});
}

export function hasIdentityInfo(identity) {
  return identity?.identityConfigInfo && Object.keys(identity?.identityConfigInfo).length;
}
