export const MY_RESPONSES = 'MY_RESPONSES';
export const MY_DRAFTS = 'MY_DRAFTS';
export const POSTED = 'POSTED';

export const sentTypes = {
  MY_RESPONSES,
  MY_DRAFTS,
  POSTED,
};

export const commentType = {
  BOUNTY: 'BOUNTY',
  RESPONSE: 'RESPONSE',
};

export const identityType = {
  ANON: 'ANON',
  REAL: 'REAL',
  DEFAULT: 'DEFAULT',
};

export const defaultCameraConstraints = {
  video: {
    facingMode: 'environment',
  },
  audio: false,
};

/**
 * Ctrl types ("KeyUtils")
 */
export const SHOWN = 'A';
export const HIDDEN = 'Z';

/**
 * Post bounty options & social networks
 */
export const IN_APP = 'IN_APP';
export const REBOUNTY = 'REBOUNTY';
export const FACEBOOK = 'FACEBOOK';
export const TWITTER = 'TWITTER';
export const LINKEDIN = 'LINKEDIN';
export const MY_LISTS = 'MY_LISTS';

export const PIN = 'PIN';
export const SPLASH_TAP = 'SPLASH_TAP';

export const DEFAULT_RATE_STAR_POINTS_VALUE = 1;
