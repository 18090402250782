import { isEqual } from 'lodash';
import { getTopLevelVariant } from 'Models/product/Product';
import { isRewardEmpty, negateReward, subtractReward } from 'Models/Reward';

function isDiscount(initial, current) {
  if (initial && current) {
    return !isEqual(initial, current);
  }

  return false;
}

export function getSavings(initial, current) {
  if (!initial || !current) {
    return null;
  }

  return subtractPrice(initial, current);
}

export function subtractPrice(initial, current) {
  if (!current || isEmpty(current)) {
    return initial;
  }

  if (isEmpty(initial)) {
    return negatePrice(current);
  }

  return {
    mainPrice: subtractReward(initial.mainPrice, current.mainPrice),
    altPrice1: subtractReward(initial.altPrice1, current.altPrice1),
    altPrice2: subtractReward(initial.altPrice2, current.altPrice2),
  };
}

function isEmpty(price) {
  const { mainPrice, altPrice1, altPrice2 } = price;

  return (mainPrice == null || isRewardEmpty(mainPrice))
    && (altPrice1 == null || isRewardEmpty(altPrice1))
    && (altPrice2 == null || isRewardEmpty(altPrice2));
}

export function negatePrice(price) {
  const { mainPrice, altPrice1, altPrice2 } = price;

  if (isEmpty(price)) {
    return price;
  }
  const p = {};

  if (mainPrice != null) {
    p.mainPrice(negateReward(mainPrice));
  }

  if (altPrice1 != null) {
    p.mainPrice(negateReward(altPrice1));
  }

  if (altPrice2 != null) {
    p.mainPrice(negateReward(altPrice2));
  }

  return p;
}

export function isDiscountForPrice(initial, current) {
  if (initial && current) {
    return isDiscount(initial.mainPrice, current.mainPrice)
      || isDiscount(initial.altPrice1, current.altPrice1)
      || isDiscount(initial.altPrice2, current.altPrice2);
  }

  return false;
}

export function getPrices(product) {
  const productInfo = getTopLevelVariant(product);

  return {
    msrp: productInfo && productInfo.msrp ? productInfo.msrp : null,
    price: productInfo && productInfo.price ? productInfo.price : null,
  };
}

function getAmount(reward) {
  if (!reward || (reward && !reward.mainPrice)) {
    return '';
  }

  if (reward.mainPrice.money) {
    return reward.mainPrice.money.amount;
  }

  if (reward.mainPrice.points) {
    return reward.mainPrice.points.amount;
  }

  return '';
}

export function getProductAmounts(product) {
  const { msrp, price } = getPrices(product);

  return {
    price: getAmount(price),
    msrp: getAmount(msrp),
  };
}
