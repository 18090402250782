import { func, object, string, number, oneOfType } from 'prop-types';

//  Components
import CustomMUIDropdown from 'Common/components/CustomMUIDropdown';
import { useIntl } from 'react-intl';

const propTypes = {
  handleChanges: func.isRequired,
  value: oneOfType([string, number]),
  config: object.isRequired,
};

const defaultProps = {
  value: 0,
};

const DropdownComponent = (props) => {
  const { value, handleChanges, config } = props;
  const {
    name, text, options, ...otherProps
  } = config;
  const intl = useIntl();

  const opts = [
    {
      label: intl.formatMessage({ id: 'labels.selectValue' }),
      value: 0,
      disabled: true,
    },
    ...options,
  ];

  return (
    <CustomMUIDropdown
      name={name}
      label={intl.formatMessage({ id: text })}
      value={value || 0}
      onChange={handleChanges}
      color="primary"
      options={opts}
      {...otherProps}
    />
  );
};

DropdownComponent.propTypes = propTypes;
DropdownComponent.defaultProps = defaultProps;

export default DropdownComponent;
