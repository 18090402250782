import { NotificationManager } from 'react-notifications';

import { getBountyTypeAsText } from 'Modules/posts/add/utils/bountyStrings';
import { currencyTypes } from 'Models/Currency';
import { SURVEY, SCORE, CHECKLIST, JOB, ALBUM, NEWS, BANNER, PROMOTION } from 'Constants/bounty/bountyType';
import { getMyCurrency } from 'Models/Settings';
import IntlMessages from 'Util/IntlMessages';

const regex = /^[0-9.]+\,?[0-9]{0,4}$/; //eslint-disable-line

export function getDescriptionHint(bountyType, isDescriptionHint = false) {
  const bountyTypeName = getBountyTypeAsText(bountyType, isDescriptionHint);

  switch (bountyType) {
    case SCORE:
      return 'Describe your ask for a score…';
    case SURVEY:
      return 'Ask a question…';
    case CHECKLIST:
      return 'Description…';
    case JOB:
      return 'Opportunity Description (Brief Description of Job Position)';
    case ALBUM:
      return 'Album title';
    case NEWS:
      return 'News title';
    case BANNER:
      return 'Banner link';
    case PROMOTION:
      return 'Write description';
    default:
      return `Write ${bountyTypeName} #keywords`;
  }
}

function parseCurrency(settings, userData) {
  const currency = getMyCurrency(settings, userData);

  if (currency === currencyTypes.NONE) {
    NotificationManager.error(<IntlMessages id="bounty.currencyMissing" />);
    return null;
  }

  if (currency === currencyTypes.UNKNOWN) {
    NotificationManager.error(<IntlMessages id="bounty.currencyInvalid" />);
    return null;
  }

  return currency;
}

function parseMoneyAmount(amount, currency, missingMsg, invalidMsg) {
  const parsedAmount = amount || amount === 0 ? amount : null;

  if (parsedAmount === null) {
    NotificationManager.error(<IntlMessages id={missingMsg} />);
    return false;
  }

  if (!regex.test(parsedAmount)) {
    NotificationManager.error(<IntlMessages id={invalidMsg} />);
    return false;
  }

  // TODO: implement max amount
  if (regex.test(parsedAmount) && parseFloat(parsedAmount) > 1000000) {
    NotificationManager.error(<IntlMessages id="bounty.amountTooBig" />);
    return false;
  }

  return true;
}

export function parsePointsAmount(bountyAmount) {
  const amount = bountyAmount !== null && bountyAmount !== undefined ? +bountyAmount : null;

  if (amount === null) {
    NotificationManager.error(<IntlMessages id="bounty.amountMissing" />);
    return false;
  }

  if (!regex.test(amount)) {
    NotificationManager.error(<IntlMessages id="bounty.amountInvalid" />);
    return false;
  }

  if (regex.test(amount) && parseInt(amount, 10) > 1000000) {
    NotificationManager.error(<IntlMessages id="bounty.amountTooBig" />);
    return false;
  }

  return true;
}

export function isValidMoneyAmount(settings, userData, bountyAmount) {
  const currency = parseCurrency(settings, userData);

  if (!currency) {
    return false;
  }

  return parseMoneyAmount(bountyAmount, currency, 'bounty.amountMissing', 'bounty.amountInvalid');
}

//  On ANDROID is BigDecimal parseMoneyAmount
// TODO: need implementation "checkConfigMaxAmount"
export function isValidPrice(price) {
  if (!price || (price && !price.trim())) {
    NotificationManager.error('Provide a valid price.');
    return false;
  }

  return true;
}
