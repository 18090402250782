// Other resources
import { ORDERS, ORDER } from 'Store/reducerProperties';
import { ORDER_NAMESPACE } from 'Store/namespaces';
import { getHandlers } from 'Store/reducers/helpers';

// initial state
const INIT_STATE = {
  [ORDERS]: {},
  [ORDER]: {},
};

const handlersReducer = getHandlers(ORDER_NAMESPACE);

export default function orderReducer(state = INIT_STATE, action = {}) {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}
