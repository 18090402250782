import { ACTIONS } from 'Store/actions/StatisticsActions';

const initialState = {
  isLoading: false,
  activeJobs: 0,
  activeJobsApplications: 0,
  views: 0,
  totalCandidates: 0,
};

const StatisticsReducer = (state = initialState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.GET_DATA:
      return {
        ...state,
        ...payload,
      };
    case ACTIONS.LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return state;
  }
};

export default StatisticsReducer;
