import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { number, func, oneOfType, string } from 'prop-types';
import { DateTimePicker } from '@mui/lab';
import { TextField, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

//  Components
import ExpiryForm from 'Modules/posts/add/components/ExpiryForm';

//  Actions/Selectors
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA } from 'Store/reducerProperties';
import * as modalSelectors from 'Modules/modal/store/selectors';
import { updateDataSyncAction } from 'Store/actions/genericActions';

//  Other resources
import { durationFeatureField } from 'Constants/fields';
import IntlMessage from 'Util/IntlMessages';

const PromotionAvailabilityContainer = (props) => {
  const { activateAt, expiresAt, ttl } = props;
  const [duration, setDuration] = useState({ days: 0, hours: 0, minutes: 0 });
  const classes = useStyles();

  useEffect(() => {
    if (ttl && !duration.days && !duration.hours && !duration.minutes) {
      const seconds = Number(ttl);

      setDuration({
        days: Math.floor(seconds / (3600 * 24)),
        hours: Math.floor((seconds % (3600 * 24)) / 3600),
        minutes: Math.floor((seconds % 3600) / 60),
      });
    }
  }, [ttl]);

  const handleActiveDate = (value) => (
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { activateAt: new Date(value).getTime() }));
  const handleEndDate = (value) => (
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { expiresAt: new Date(value).getTime() }));

  const ttlChanges = ({ target: { name, value } }) => {
    const newDuration = { ...duration, [name]: value };
    const newTtl = (newDuration.days * 24 * 60 * 60) + (newDuration.hours * 60 * 60) + (newDuration.minutes * 60);

    setDuration(newDuration);
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { ttl: newTtl });
  };

  return (
    <Box>
      <Box display="flex" mb={2}>
        <Box width="50%" mr={3} className={classes.datePicket}>
          <DateTimePicker
            autoOk
            ampm={false}
            value={activateAt}
            onChange={handleActiveDate}
            renderInput={(inputProps) => <TextField {...inputProps} />}
            label={<IntlMessage id="label.activationDate" />}
          />
        </Box>
        <Box width="50%" className={classes.datePicket}>
          <DateTimePicker
            autoOk
            ampm={false}
            value={expiresAt}
            onChange={handleEndDate}
            renderInput={(inputProps) => <TextField {...inputProps} />}
            label={<IntlMessage id="label.endDate" />}
          />
        </Box>
      </Box>
      <ExpiryForm
        data={duration}
        fields={durationFeatureField}
        handleChange={ttlChanges}
      />
    </Box>
  );
};

PromotionAvailabilityContainer.propTypes = {
  updateDataSyncAction: func.isRequired,
  activateAt: number,
  expiresAt: number,
  ttl: oneOfType([number, string]),
};

PromotionAvailabilityContainer.defaultProps = {
  activateAt: null,
  expiresAt: null,
  ttl: 0,
};

const mapStateToProps = (state) => ({
  activateAt: modalSelectors.inputsData(state)?.activateAt || null,
  expiresAt: modalSelectors.inputsData(state)?.expiresAt || null,
  ttl: modalSelectors.inputsData(state)?.ttl || 0,
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

const useStyles = makeStyles(() => ({
  datePicket: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(PromotionAvailabilityContainer);
