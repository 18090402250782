import { connect } from 'react-redux';

// Components
// import PhotoCamera from 'Modules/attachments/containers/PhotoCamera';
// import MediaButtonComponent from 'Modules/attachments/components/MediaButtonComponent';
import FileUploadContainer from 'Modules/attachments/containers/FileUploadContainer';

const propTypes = {};

const MediaOptionsContainer = () => (
  <FileUploadContainer />
);

const mapDispatchToProps = {
};

MediaOptionsContainer.propTypes = propTypes;

export default connect(null, mapDispatchToProps)(MediaOptionsContainer);
