import { object } from 'prop-types';

//  Components
import AttachableProductsContainer from 'Common/containers/AttachableProductsContainer';

// Others
import { builtinList } from 'Models/ListManager';
import { MARKET } from 'Modules/posts/bounty/constants/listType';

const propTypes = {
  list: object,
};

const defaultProps = {
  list: builtinList[MARKET],
};

const ProductListContainer = (props) => {
  const { list } = props;

  return (
    <AttachableProductsContainer list={list} />
  );
};

ProductListContainer.propTypes = propTypes;
ProductListContainer.defaultProps = defaultProps;

export default ProductListContainer;
