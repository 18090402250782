import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { object, func, bool, string } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { NotificationManager } from 'react-notifications';

//  Actions/Selectors
import { updateDataSyncAction } from 'Store/actions/genericActions';
import { listBadges } from 'Services/SystemService';

//  Other resources
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA } from 'Store/reducerProperties';
import IntlMessage from 'Util/IntlMessages';
import * as modalSelectors from '../../../modal/store/selectors';

const BadgesContainer = (props) => {
  const [badges, setBadges] = useState([]);
  const [noAvailableBadges, setNoAvailableBadges] = useState(false); // eslint-disable-line

  const {
    classes, open, handleClose, bountyType, inputsData, rewardName,
  } = props;

  useEffect(() => {
    // const badge = selectedBounty.reward && selectedBounty.reward.badge && selectedBounty.reward.badge.info;
    //
    // if (badge && badge.parentBounty) {
    //   setSelectedCollection(badge.parentBounty);
    // } else {
    listBadges({ type: bountyType })
      .then(({ list }) => {
        if (list && list.length) {
          return setBadges(list);
        }

        setNoAvailableBadges(true);
      });
    // }
  }, []);

  useEffect(() => {
    if (open && noAvailableBadges) {
      handleClose();
      NotificationManager.warning(<IntlMessage id="no_badge_available" />);
    }
  }, [open]);

  // useEffect(() => {
  //   if (selectedCollection) {
  //     const ownerId = getOwnerId();
  //
  //     props.getCompanySentDetails(ownerId, selectedCollection.id, BADGE_SUBFOLDER);
  //     props.getCompanySentsSubs(ownerId, selectedCollection.id, BADGE_SUBFOLDER);
  //   }
  // }, [selectedCollection]);

  const onBadgeSelect = (selectedBadge) => {
    const reward = inputsData[rewardName];
    const payload = {
      ...(reward || {}),
      badges: {
        ...(reward && reward.badges ? reward.badges : {}),
        [selectedBadge.id]: selectedBadge,
      },
    };

    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [rewardName]: payload });
    handleClose();
  };

  // const renderCollection = (badgeCollection) => (
  //   <div
  //     role="presentation"
  //     className={classes.root}
  //     onClick={() => setSelectedCollection(badgeCollection)}
  //   >
  //     <div
  //       style={{
  //         backgroundImage: `url(${badgeCollection.info.icon})`,
  //         height: '70px',
  //         width: '70px',
  //         minWidth: '70px',
  //       }}
  //       className="image-post"
  //     />
  //     <div className={classes.collectionInfo}>
  //       <h2>{badgeCollection.info.name}</h2>
  //       {
  //         // !badge.bounty.parentBounty && (
  //         //   <span>
  //         //     <IntlMessage id="badge.number_of_badges" values={{ badgesNr: getNrOfSubBounties(badge.bounty) }} />
  //         //   </span>
  //         // )
  //       }
  //     </div>
  //   </div>
  // );

  const renderBadge = (badge) => (
    <div
      key={badge.id}
      role="presentation"
      className={classes.root}
      onClick={() => onBadgeSelect(badge)}
    >
      <div
        style={{
          backgroundImage: `url(${badge.info.icon})`,
          height: '70px',
          width: '70px',
          minWidth: '70px',
        }}
        className="image-post"
      />
      <div className={classes.collectionInfo}>
        <h2>{badge.info.label}</h2>
        <div>{badge.info.description}</div>
      </div>
    </div>
  );

  return (
    <Dialog
      maxWidth="sm"
      open={open && !noAvailableBadges}
      onClose={handleClose}
    >
      <DialogTitle>Select a badge</DialogTitle>
      <DialogContent className={classes.content}>
        {badges.map((item) => (
          item.badge
          && renderBadge(item.badge)
          // : renderCollection(item.badgeCollection)
        ))}
      </DialogContent>
      <DialogActions>
        <div className="text-right mt-40">
          <Button
            variant="contained"
            onClick={handleClose}
            className="text-white btn-success"
          >
            <IntlMessage id="button.cancel" />
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

BadgesContainer.propTypes = {
  inputsData: object.isRequired,
  classes: object.isRequired,
  open: bool.isRequired,
  bountyType: string.isRequired,
  rewardName: string.isRequired,
  handleClose: func.isRequired,
  updateDataSyncAction: func.isRequired,
};

const mapStateToProps = (state) => ({
  inputsData: modalSelectors.inputsData(state) || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

const useStyles = (theme) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    border: `1px solid ${theme.palette.common.borderLine}`,
    padding: theme.spacing(2),
    minHeight: '100px',
    borderRadius: '5px',
    marginBottom: theme.spacing(2),
  },
  collectionInfo: {
    flexGrow: 1,
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  content: {
    height: '350px',
    width: '500px',
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(useStyles, { withTheme: true })(BadgesContainer),
);
