/*
  Type definitions from "baseCreateActivity"
 */

export const TALENT_SEARCH = 'TALENT_SEARCH';
export const RECOMMENDATION = 'RECOMMENDATION';
export const REALESTATE = 'REALESTATE';
export const COACHING = 'COACHING';
export const ALBUM = 'ALBUM';
export const NEWS = 'NEWS';
export const BANNER = 'BANNER';
export const PRODUCT = 'PRODUCT';
export const CHALLENGE = 'CHALLENGE';
export const SURVEY = 'SURVEY';
export const CLASSIFIED = 'CLASSIFIED';
export const BUNDLE = 'BUNDLE';
export const QUEST = 'QUEST';
export const MCQ = 'MCQ';
export const SCORE = 'SCORE';
export const CHECKLIST = 'CHECKLIST';
export const FUNDING = 'FUNDING';
export const PROMOTION = 'PROMOTION';
export const JOB = 'JOB';
export const THREAD = 'THREAD';
export const MATCH = 'MATCH';
export const LIVE_STREAM = 'LIVE_STREAM';
export const TICKET = 'TICKET';
export const TRIVIA = 'TRIVIA';
export const LOTTERY = 'LOTTERY';

export const activityTypes = [
  TALENT_SEARCH,
  RECOMMENDATION,
  REALESTATE,
  COACHING,
  CHALLENGE,
  SURVEY,
  CLASSIFIED,
  PRODUCT,
  BUNDLE,
  SCORE,
  CHECKLIST,
  MCQ,
  FUNDING,
  PROMOTION,
  THREAD,
  ALBUM,
  NEWS,
  BANNER,
  JOB,
  MATCH,
  LIVE_STREAM,
  TICKET,
  TRIVIA,
  LOTTERY,
];
