import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { func, object, string } from 'prop-types';

//  Components
import PriceComponent from 'Modules/posts/add/components/PriceComponent';

//  Actions/Selectors
import * as modalSelectors from 'Modules/modal/store/selectors';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import { updateDataSyncAction } from 'Store/actions/genericActions';
import { getMyCurrencySelector } from 'Store/selectors/settings';

//  Other resources
import { getCurrencyProps } from 'Constants/fields';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA } from 'Store/reducerProperties';
import { PROMOTION } from 'Constants/bounty/bountyType';

const propTypes = {
  updateDataSyncAction: func.isRequired,
  selectedBounty: object.isRequired,
  inputsData: object.isRequired,
  priceObject: object.isRequired,
  myCurrency: string.isRequired,
};

const PriceContainer = (props) => {
  const [currencyProps, setCurrencyProps] = useState(getCurrencyProps());
  const { selectedBounty, priceObject, myCurrency } = props;

  const initializeCurrency = () => {
    setCurrencyProps(getCurrencyProps([{ label: myCurrency, value: myCurrency }]));
  };

  const initializePrice = () => {
    let amount = '';
    if (selectedBounty.reward && selectedBounty.reward.money) {
      amount = selectedBounty.reward.money.amount;
    }

    if (selectedBounty.type === PROMOTION && selectedBounty[priceObject.name]) {
      const [priceAmount] = selectedBounty[priceObject.name].split(' ') || [];
      amount = priceAmount || '';
    }

    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [priceObject.name]: amount });
  };

  useEffect(() => {
    initializeCurrency();

    if (selectedBounty && Object.keys(selectedBounty).length) {
      initializePrice();
    }
  }, []);

  const handleAmountChanges = ({ target: { name, value } }) => (
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: value })
  );

  return (
    <PriceComponent
      handleAmountChanges={handleAmountChanges}
      amount={priceObject}
      currency={currencyProps}
      data={{ ...props.inputsData, currency: myCurrency }}
    />
  );
};

const mapStateToProps = (state) => ({
  inputsData: modalSelectors.inputsData(state) || {},
  selectedBounty: selectedBountySelector(state) || {},
  myCurrency: getMyCurrencySelector(state) || 'USD',
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

PriceContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(PriceContainer);
