// on android is handleCreateClick from BaseCreateActivity
import { isAllowed, getAvailableOrganizationTypes } from 'Models/Settings';
import * as companyType from 'Constants/company/companyType';
import * as activityTypes from 'Modules/onboarding/company/constants/activityTypes';
import { CREATE_ORGANIZATION } from 'Models/Op';
// import { isBundle } from 'Models/bounty/Bounty';

export function getAllowedCompanyTypes(settings, userData) {
  let companyTypes = [];

  if (isAllowed({ op: CREATE_ORGANIZATION.name, settings, userData })) {
    companyTypes = getAvailableOrganizationTypes(settings, userData);
  }

  return companyTypes;
}

/**
 * menu definitions from "CreateCompanyOptionsFragment"
 */

export const companyDialogOptionsConfig = {
  [companyType.AUTHOR]: {
    bountyType: companyType.AUTHOR,
    name: 'bounty.createAuthor',
    icon: 'person_add',
    activityType: activityTypes.AUTHOR,
  },
  [companyType.MERCHANT]: {
    bountyType: companyType.MERCHANT,
    name: 'bounty.createMerchant',
    icon: 'person_add',
    activityType: activityTypes.MERCHANT,
  },
  [companyType.CHARITY]: {
    bountyType: companyType.CHARITY,
    name: 'bounty.createCharity',
    icon: 'person_add',
    activityType: activityTypes.CHARITY,
  },
  [companyType.FAMILY]: {
    bountyType: companyType.FAMILY,
    name: 'bounty.createFamily',
    icon: 'group_add',
    activityType: activityTypes.FAMILY,
  },
  [companyType.EMPLOYER]: {
    bountyType: companyType.EMPLOYER,
    name: 'bounty.createEmployer',
    icon: 'group_add',
    activityType: companyType.EMPLOYER,
  },
  [companyType.AMBASSADOR]: {
    bountyType: companyType.AMBASSADOR,
    name: 'bounty.createAmbassador',
    icon: 'group_add',
    activityType: companyType.AMBASSADOR,
  },
  [companyType.PARTNER]: {
    bountyType: companyType.PARTNER,
    name: 'bounty.createPartner',
    icon: 'group_add',
    activityType: companyType.PARTNER,
  },
};

export function buildOrgMenu(companyTypes) {
  const menuOptions = [];

  companyTypes.forEach((type) => {
    const companyConfig = companyDialogOptionsConfig[type];

    if (companyConfig) {
      menuOptions.push(companyConfig);
    }
  });

  return menuOptions;
}
