import { useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import { func, object, string } from 'prop-types';

//  Actions/Selectors
import * as modalSelectors from 'Modules/modal/store/selectors';
import { clearDataSyncAction, updateDataSyncAction } from 'Store/actions/genericActions';

//  Other resources
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA, MODAL_SURVEY_OPTIONS_DATA } from 'Store/reducerProperties';
import { DEFAULT_SURVEY_INITIAL_OPTIONS } from 'Modules/posts/add/utils/createPostActivity';
import {
  DEFAULT_CORRECT_ANSWER_WEIGHT,
  DEFAULT_WRONG_ANSWER_WEIGHT,
  SurveyResult,
} from 'Models/survey/Survey';
import { MCQ } from 'Constants/bounty/bountyType';
import IntlMessage from 'Util/IntlMessages';
import ChoiceComponent from '../components/ChoiceComponent';

const propTypes = {
  updateDataSyncAction: func.isRequired,
  surveyOptions: object.isRequired,
  bountyType: string.isRequired,
};

const SurveyOptionsContainer = (props) => {
  useEffect(() => {
    const { surveyOptions, bountyType } = props;

    if (!surveyOptions || !Object.keys(surveyOptions).length) {
      prepareDefaultChoices(surveyOptions);
    }

    if (bountyType === MCQ) {
      initializeCorrectWeight(DEFAULT_CORRECT_ANSWER_WEIGHT);
    }
  }, []);

  const initializeCorrectWeight = (correctWeight) => (
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { correctWeight }));

  const getDefaultWeight = () => {
    const { bountyType } = props;
    return bountyType === MCQ ? { weight: DEFAULT_WRONG_ANSWER_WEIGHT } : {};
  };

  const prepareDefaultChoices = () => {
    const options = {};

    for (let i = 0; i < DEFAULT_SURVEY_INITIAL_OPTIONS; i++) {
      const code = SurveyResult.getCodeByIndex({ index: i });
      options[code] = { code, ...getDefaultWeight() };
    }

    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_SURVEY_OPTIONS_DATA, options);
  };

  const handleSurveyOptionsChanges = (event) => {
    const { target: { name, value } } = event;
    const { surveyOptions } = props;

    const payload = {
      ...surveyOptions[name],
      text: value,
    };

    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_SURVEY_OPTIONS_DATA, { [name]: payload });
  };

  const handleCorrectChoiceSelection = ({ target }) => {
    const { surveyOptions } = props;
    const newOptions = {};

    Object.keys(surveyOptions).forEach((key) => {
      if (key === target.value) {
        newOptions[key] = { ...surveyOptions[key], weight: DEFAULT_CORRECT_ANSWER_WEIGHT };
      } else {
        newOptions[key] = { ...surveyOptions[key], weight: DEFAULT_WRONG_ANSWER_WEIGHT };
      }
    });

    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_SURVEY_OPTIONS_DATA, newOptions);
  };

  const handleDeleteOption = ({ code }) => {
    const { surveyOptions } = props;
    delete surveyOptions[code];
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_SURVEY_OPTIONS_DATA, surveyOptions);
  };

  const addNewSurveyOption = () => {
    const { surveyOptions } = props;
    const lastCode = Object.keys(surveyOptions).pop();
    const code = SurveyResult.getCodeByIndex({ code: lastCode, index: surveyOptions.length });

    const newOptions = {
      ...surveyOptions,
      [code]: { code, ...getDefaultWeight() },
    };

    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_SURVEY_OPTIONS_DATA, newOptions);
  };

  const { surveyOptions, bountyType } = props;

  return (
    <>
      <div className="my-10">
        {Object.values(surveyOptions).map((option, index) => (
          <ChoiceComponent
            key={option.code}
            handleSurveyOptionsChanges={handleSurveyOptionsChanges}
            handleCorrectChoiceSelection={handleCorrectChoiceSelection}
            handleDeleteOption={() => handleDeleteOption(option)}
            bountyType={bountyType}
            option={option}
            correctWeight={DEFAULT_CORRECT_ANSWER_WEIGHT}
            hasDelete={index > 1}
            defaultData={surveyOptions}
          />
        ))}
      </div>
      <button
        type="button"
        className="btn-without-style text-success pl-0"
        onClick={addNewSurveyOption}
      >
        <IntlMessage id="button.addOption" />
      </button>
    </>
  );
};

const mapStateToProps = (state) => ({
  surveyOptions: modalSelectors.surveyOptionsData(state) || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
  clearDataSyncAction,
};

SurveyOptionsContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(SurveyOptionsContainer);
