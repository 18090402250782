import {
  ATTACHMENT_TYPE,
  DISPLAY_STYLE,
  MEANING,
  PDF_MIME_TYPE,
  VISIBILITY,
} from 'Constants/attachment';
import {
  getMenuOptions,
  AUDIO_LIBRARY,
  PDF_FILES,
  PHOTO_LIBRARY,
  VIDEO_LIBRARY,
} from 'Modules/attachments/constants/attachmentSourceOptions';

export function getAttachmentType(mimeType) {
  const [baseType, fileType] = mimeType.split('/');

  if (mimeType === PDF_MIME_TYPE) {
    return ATTACHMENT_TYPE.PDF;
  }

  if (fileType === 'gif') {
    return ATTACHMENT_TYPE.GIF;
  }

  switch (baseType) {
    case 'image':
      return ATTACHMENT_TYPE.IMAGE;
    case 'audio':
      return ATTACHMENT_TYPE.AUDIO;
    case 'video':
      return ATTACHMENT_TYPE.VIDEO;
    default:
      return ATTACHMENT_TYPE.OTHER;
  }
}

/**
 * Format file before upload
 * @param data {Object} contains file props
 * @returns {Object}
 */
export function addNewFile(data) {
  if (!data) {
    return null;
  }

  const type = getAttachmentType(data.type);

  return {
    mimeType: data.type,
    filename: data.name,
    size: data.size,
    type,
    data,
  };
}

/**
 * Get a random integer between `min` and `max`.
 *
 * @param {number} min - min number
 * @param {number} max - max number
 * @return {number} a random integer
 */
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min) + 2;
}

export function getNextAttachmentId(ids = []) {
  for (let i = 0; i < 100; i++) {
    const nr = getRandomInt(0, 1000);
    const key = `a${nr}`;

    if (!ids.includes(key)) {
      return key;
    }
  }

  return null;
}

export function valueOfUploadFileWithUri(attach) {
  const {
    localUri, data, ...remainingProps
  } = attach;

  return {
    ...remainingProps,
    fileUri: localUri,
    fileContent: null,
  };
}

export function valueOfUploadFile(attach) {
  const {
    url, localUri, data, ...remainingProps
  } = attach;

  return {
    ...remainingProps,
    fileUri: localUri || url || null,
    fileContent: data,
  };
}

export function getOrder(attachments = []) {
  let maxOrderIndex = 0;

  attachments.forEach((attach) => {
    if (!Number.isNaN(attach.order) && +attach.order > maxOrderIndex) {
      maxOrderIndex = +attach.order;
    }
  });

  return maxOrderIndex + 1;
}

export function getNextOrder(order) {
  const newOrder = `000${order}`;
  return newOrder.substring(newOrder.length - 4);
}

export function formatAttachment(attachment, attachmentId, order) {
  return {
    id: attachmentId,
    url: attachment?.downloadUri?.toString() || null,
    type: attachment.type,
    filename: attachment.filename,
    size: attachment.size,
    mimeType: attachment.mimeType,
    meaning: attachment.meaning || MEANING.OTHER,
    order: getNextOrder(order),
    visibility: VISIBILITY.ALL,
    displayStyle: DISPLAY_STYLE.INLINE,
  };
}

export function getAttachmentsToDelete(oldAttachments, newAttachments) {
  if (!oldAttachments) {
    return null;
  }

  const oldIds = oldAttachments?.attachments ? Object.keys(oldAttachments?.attachments) : [];
  const newIds = newAttachments?.attachments ? Object.keys(newAttachments?.attachments) : [];

  if (!newIds?.length) {
    return oldIds;
  }

  return oldIds.filter((id) => !newIds.includes(id));
}

export function chooseAttachmentSource(options = null) {
  const menuOptions = options && options.length ? options : [PHOTO_LIBRARY, VIDEO_LIBRARY, AUDIO_LIBRARY, PDF_FILES];
  return getMenuOptions(menuOptions);
}
