import { forwardRef } from 'react';
import { connect } from 'react-redux';
import { func, shape, bool, string, object, oneOfType } from 'prop-types';
import { Dialog, DialogContent, DialogTitle, DialogActions, Toolbar, Slide } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';

//  Components
import { ContinueButtonComponent } from 'Common/components';

//  Selectors
import * as modalSelectors from 'Modules/modal/store/selectors';

//  Actions
import { closeModal } from 'Modules/modal/store/operations';
import { clearDataSyncAction } from 'Store/actions/genericActions';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_SETTINGS } from 'Store/reducerProperties';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

const styles = (theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  opacity: {
    opacity: 0.4,
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
});

const propTypes = {
  settings: shape({
    isOpen: bool,
    fullScreen: bool,
    fullWidth: bool,
    maxWidth: string,
    title: oneOfType([string, object]),
    backdrop: bool,
  }).isRequired,
  closeModal: func.isRequired,
  clearDataSyncAction: func.isRequired,
  isLoading: bool,
  classes: object.isRequired,
};

const defaultProps = {
  isLoading: false,
};

const ModalContainer = (props) => {
  const handleClose = (e, reason) => {
    if (reason === 'backdropClick' && isBackDropAllowed()) {
      return;
    }

    props.closeModal();
    props.clearDataSyncAction(MODAL_NAMESPACE, MODAL_SETTINGS);
  };

  const getHeader = () => {
    const { settings } = props;

    return !!settings.title && (
      <Toolbar>
        <h5 className="text-center w-100 mb-0">{settings.title}</h5>
      </Toolbar>
    );
  };

  const isBackDropAllowed = () => (!props.settings.backdrop);

  const { settings, isLoading, classes } = props;
  const title = getHeader();

  return (
    <Dialog
      fullScreen={settings.fullScreen}
      fullWidth={settings.fullWidth}
      maxWidth={settings.maxWidth}
      open={!!settings.isOpen}
      aria-labelledby="max-width-dialog-title"
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <>
        {!!title && (
        <DialogTitle id="form-dialog-title">
          {title}
        </DialogTitle>
        )}
        <DialogContent>
          <div className={isLoading ? classes.opacity : ''}>
            {settings.content}
          </div>
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </DialogContent>
        {settings.actions && settings.actions.buttons && (
        <DialogActions className={classes.dialogActions}>
          {
                settings.actions.buttons.map(({
                  action, text, disabled, variant, ...remainingProps
                }, index) => {
                  const isDisabled = disabled || isLoading;

                  return (
                    <ContinueButtonComponent
                      {...remainingProps}
                      key={`action-button-${index}`}
                      variant={variant || 'contained'}
                      name={text}
                      onSubmit={action}
                      className={isDisabled ? classes.opacity : ''}
                    />
                  );
                })
              }
        </DialogActions>
        )}
      </>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  settings: modalSelectors.settings(state),
  isLoading: modalSelectors.isLoading(state),
});

const mapDispatchToProps = {
  closeModal,
  clearDataSyncAction,
};

ModalContainer.propTypes = propTypes;
ModalContainer.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModalContainer));
