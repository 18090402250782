import { getApiData } from 'Services/Api';

const StatisticsService = {

  async getStatistics() {
    return getApiData('/users/statistics');
  },
};

export default StatisticsService;
