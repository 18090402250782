import get from 'lodash/get';
import IntlMessage from 'Util/IntlMessages';
import { CountriesList } from 'Util/CountriesList';
import { paginationItemsPerPage } from 'Constants/common';
import queryString from 'query-string';

export function getQuantityString(singularId, pluralId, value) {
  if (value > 1) {
    return <IntlMessage id={pluralId} values={{ value }} />;
  }

  return <IntlMessage id={singularId} />;
}

export function prepareFilters(filters) {
  return Object.keys(filters)
    .map((key) => {
      if (filters[key]) {
        return `${key}=${filters[key]}`;
      }
      return null;
    })
    .filter((item) => item)
    .join('&');
}

export function preparePagination(page, itemsPerPage = paginationItemsPerPage) {
  const start = page > 0 ? (page - 1) * itemsPerPage : page;
  return {
    start,
    length: itemsPerPage,
  };
}

export function preparePaginationApplicants(page, itemsPerPage = paginationItemsPerPage) {
  const offset = page > 0 ? (page - 1) * itemsPerPage : page;
  return {
    offset,
    limit: itemsPerPage,
  };
}

export function getCountryCode(countryName) {
  const country = countryName ? CountriesList.find((c) => c.label === countryName) : {};
  return country?.code || null;
}

export function getCountryName(countryCode) {
  const country = CountriesList.find((c) => c?.code === countryCode) || {};
  return country.label || null;
}

export function printUserProfile() {
  const printContents = document.getElementById('contentToPrint').innerHTML;
  const styleTags = Array
    .from(document.getElementsByTagName('style'))
    .map((x) => x.outerHTML).join('');

  const newWin = document.getElementById('printf').contentWindow;

  newWin.document.open();
  newWin.document.write(styleTags);
  newWin.document.write(printContents);
  newWin.document.close();

  newWin.focus();
  newWin.print();
}

export function formatDiscount(discount) {
  return discount ? discount * 100 : '0';
}

export function sortAlphabetically({ data, sortBy }) {
  return data.sort((a, b) => {
    if (get(a, sortBy).toLowerCase() < get(b, sortBy).toLowerCase()) {
      return -1;
    }

    if (get(a, sortBy).toLowerCase() > get(b, sortBy).toLowerCase()) {
      return 1;
    }

    return 0;
  });
}

/**
 * This function remove empty request properties from the object,
 * including empty arrays, empty strings, null and undefined values.
 */
export const remoteEmptyProps = (obj) => {
  if (!obj) return {};

  const newObj = { ...obj };
  Object.entries(newObj)
    .filter((item) => {
      const val = item[1];
      return (typeof val !== 'number' && !val) // if empty string/null/undefined
        || (Array.isArray(val) && val.length === 0); // if empty array
    })
    .forEach((item) => delete newObj[item[0]]);
  return newObj;
};

/**
 * This function generate a query string in proper format,
 * ignoring 'order' prop (as this should be formatted as FIELD$ASC / FIELD$DESC).
 * This done to prevent '$' convertation with URLSearchParams function.
 */
export const getQueryString = (obj) => {
  const { order, ...rest } = remoteEmptyProps(obj);

  let qs = queryString.stringify(rest);

  if (order) {
    qs += `&order=${order}`;
  }

  return qs ? `?${qs}` : '';
};
