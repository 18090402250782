/**
 * Language Provider Helper Component
 * Used to Display Localised Strings
 */
import { FormattedMessage, injectIntl, createIntl } from 'react-intl';
import { arrayOf, node, string, object, oneOfType } from 'prop-types';
import { enLang } from './lang/entries';

// Injected message
const InjectMessage = (props) => <FormattedMessage {...props} defaultMessage={enLang.messages[props?.id]} />;

export function IntlConsume({ children, locale, messages }) {
  // eslint-disable-next-line no-unused-vars
  const intlRef = createIntl({ locale, messages });

  return children;
}

InjectMessage.propTypes = {
  id: string.isRequired,
};

IntlConsume.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  locale: string.isRequired,
  messages: object.isRequired,
};

export const formatMessage = (props) => InjectMessage(props);

export default injectIntl(InjectMessage, {
  withRef: false,
});
