import { string, object, oneOfType, node } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const propTypes = {
  label: oneOfType([string, node]),
  className: string,
  classes: object.isRequired,
  text: string,
  icon: string,
  color: string,
};

const defaultProps = {
  className: '',
  text: '',
  label: '',
  icon: '',
  color: '',
};

const StatusLabel = ({
  label, text, icon, color, className, classes,
}) => {
  const labelToDisplay = label || text || '';
  const iconStyle = color ? { color } : {};

  return (
    <>
      {!!icon && <span className="material-icons vertical-align-bottom" style={iconStyle}>{icon}</span>}
      {labelToDisplay && (
        <span className={`${classes.label} ${classes[className]}`}>
          <strong>{labelToDisplay}</strong>
        </span>
      )}
    </>
  );
};

const useStyles = (theme) => ({
  box: {
    display: 'inline',
    verticalAlign: 'middle',
  },
  label: {
    padding: '6px 16px',
    borderRadius: theme.shape.borderRadius,
    fontSize: 12,
    background: theme.palette.common.borderLine,
    color: 'white',
    display: 'inline-block',
    height: 'fit-content',
    lineHeight: 1.75,
  },
  'active-label': {
    color: theme.palette.grey[500],
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.common.borderLine}`,
  },
  'draft-label': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.borderLine,
  },
  'received-label': {
    color: theme.palette.common.greenLabel,
    backgroundColor: theme.palette.common.white,
  },
  'rejected-label': {
    color: theme.palette.common.orangeLabel,
    backgroundColor: theme.palette.common.black,
  },
  'closed-label': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.redLabel,
  },
  accepted_label: {
    color: theme.palette.common.greenLabel,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.common.borderLine}`,
  },
  'in_paid-label': {
    color: theme.palette.common.greenLabel,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.common.borderLine}`,
  },
  'out_paid-label': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.blueLabel,
  },
  processing_label: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.redLabel,
  },
  payed_label: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.greenLabel,
  },
  'official-badge': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.orangeLabel,
  },
  'ror-badge': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.redLabel,
  },
});

StatusLabel.propTypes = propTypes;
StatusLabel.defaultProps = defaultProps;

export default withStyles(useStyles, { withTheme: true })(StatusLabel);
