/* eslint-disable max-classes-per-file */

import { firstNonNull } from 'Util/ObjectUtils';
import {
  CUSTOM,
  NONE,
  FAVORITES,
  STREAM,
  listTypeData,
} from 'Modules/posts/bounty/constants/listType';
import * as listKind from 'Modules/posts/bounty/constants/listKind';
import * as BountyType from 'Constants/bounty/bountyType';
import { DEFAULT_TYPE, VISIBLE_TYPE, USER_TYPE, UNKNOWN_TYPE } from 'Models/VisibilityMode';
import { WEBVIEW, MULTI } from 'Modules/posts/bounty/constants/listKind';
import * as PERMISSION_TYPES from 'Constants/bounty/permissionType';
import { SHOW_IN_TYPES } from 'Constants/common';

const visibilityTypes = [DEFAULT_TYPE, VISIBLE_TYPE, USER_TYPE, UNKNOWN_TYPE];

export class ListKind {
  static isViewable(kind) {
    return kind !== listKind.TARGET;
  }

  static isPostable(kind = '') {
    return ![
      listKind.FILTER_BY_BOUNTY_TYPE,
      listKind.WEBVIEW,
      listKind.REST_CALL,
      listKind.STATS,
      listKind.MULTI,
    ].includes(kind);
  }

  static asEnum(kind) {
    if (!kind) {
      return listKind.INBOX;
    }

    return listKind[kind] || listKind.UNKNOWN;
  }
}

export class ListType {
  static asEnum(type) {
    if (!type) {
      return listTypeData[NONE];
    }

    return listTypeData[type] || listTypeData[CUSTOM];
  }

  static isFavorites(typeId) {
    return typeId === FAVORITES;
  }

  static isPostable(item) {
    const permissionType = getPermissionTypeAsEnum(item);
    const kind = ListDef.getKindAsEnum(item);

    return ListKind.isPostable(kind)
      && !this.isFavorites(item.id)
      && isVisibleType(item?.visibilityInfo)
      && isWritable(permissionType);
  }

  static getOldStyleListType(code, bountyType) {
    Object.keys(listTypeData).forEach((key) => {
      const listType = listTypeData[key];

      if (listType && listType.code && code && listType.code === code && listType.bountyType === bountyType) {
        return listType.name;
      }
      return NONE;
    });
  }
}

export class ListDef {
  static isApplicable(listItem, type) {
    const { bountyType } = this.getTypeAsEnum(listItem.id);

    if (bountyType !== null) {
      return bountyType === type;
    }

    return !listItem.bountyTypes || this.getBountyTypesAsEnum(listItem).includes(type);
  }

  static getTypeAsEnum(typeId) {
    return ListType.asEnum(typeId);
  }

  static getKindAsEnum(listDef) {
    const { id, kind } = listDef;

    if (kind) {
      return ListKind.asEnum(kind);
    }

    const myKind = this.getTypeAsEnum(id).kind;

    if (myKind) {
      return myKind;
    }

    return listKind.BUCKET;
  }

  static getEffectiveUrl(listDef) {
    const { id, url } = listDef;

    if (url) {
      return url;
    }

    return this.getTypeAsEnum(id).url || null;
  }

  static getEffectiveCode(listDef) {
    return firstNonNull(listDef.code, this.getTypeAsEnum(listDef.id).code);
  }

  static getEffectiveListId(listId, listCode, bountyType) {
    let listType = ListType.asEnum(listId).name;

    if (listType === NONE) {
      listType = ListType.getOldStyleListType(listCode, bountyType);
    }

    switch (listType) {
      case NONE:
        return null;
      case CUSTOM:
        return listId;
      default:
        return listType;
    }
  }

  static isEffectiveFilterByType(list) {
    const cloneList = { ...list };

    return this.getKindAsEnum(cloneList) === listKind.FILTER_BY_BOUNTY_TYPE;
  }

  static getFilterBountyTypeAsEnum(list) {
    const cloneList = { ...list };
    const types = this.getBountyTypesAsEnum(cloneList);

    return types.length === 1 ? types[0] : this.getTypeAsEnum(list.id).bountyType;
  }

  static getBountyTypesAsEnum(list) {
    if (!list.bountyTypes) {
      const { bountyType } = this.getTypeAsEnum(list.id);
      return bountyType ? [bountyType] : [];
    }

    const bountyTypes = list.bountyTypes.split(',');
    const finalList = [];

    bountyTypes.forEach((item) => {
      const t = getBountyType(item);
      if (t !== BountyType.NONE && t !== BountyType.UNKNOWN) {
        finalList.push(t);
      }
    });

    return finalList;
  }

  static isWebview(listDef) {
    return this.getKindAsEnum(listDef) === WEBVIEW && this.getEffectiveUrl(listDef) !== null;
  }

  static isMulti(listDef) {
    return this.getKindAsEnum(listDef) === MULTI;
  }

  static isViewable(listDef) {
    const permissionType = getPermissionTypeAsEnum(listDef);

    return ListKind.isViewable(listDef.kind)
      && isVisibleType(listDef?.visibilityInfo)
      && isViewableByPermissionType(permissionType);
  }
}

export function getEffectiveOp(listType, op) {
  if (op) {
    return op;
  }

  return ListDef.getTypeAsEnum(listType).op || null;
}

export function isEffectiveFilterByType(listDef) {
  return ListDef.getKindAsEnum(listDef) === listKind.FILTER_BY_BOUNTY_TYPE;
}

export function getBountyType(type) {
  if (!type) {
    return BountyType.NONE;
  }

  return BountyType[type] || BountyType.UNKNOWN;
}

export function isListOf(listDef, bounty) {
  if (!bounty.listId) {
    return listDef?.id === STREAM;
  }

  return listDef?.id === ListDef.getEffectiveListId(bounty.listId, bounty.listCode, bounty.type);
}

export function permissionsAsEnum(type) {
  if (!type) {
    return PERMISSION_TYPES.READ_WRITE;
  }

  return PERMISSION_TYPES[type] || PERMISSION_TYPES.UNKNOWN;
}

function getPermissionTypeAsEnum(item) {
  if (!item?.permissionType) {
    const type = ListType.asEnum(item?.id)?.permissionType;
    return type || PERMISSION_TYPES.READ_WRITE;
  }

  return permissionsAsEnum(item.permissionType);
}

function isWritable(type) {
  return [PERMISSION_TYPES.WRITE_ONLY, PERMISSION_TYPES.READ_WRITE].includes(type);
}

export function mergePermissionTypes(curr, newValue) {
  switch (curr) {
    case PERMISSION_TYPES.HIDE:
      return newValue;
    case PERMISSION_TYPES.READ_ONLY:
      return newValue === PERMISSION_TYPES.READ_ONLY ? curr : PERMISSION_TYPES.READ_WRITE;
    case PERMISSION_TYPES.WRITE_ONLY:
      return newValue === PERMISSION_TYPES.WRITE_ONLY ? curr : PERMISSION_TYPES.READ_WRITE;
    default:
      return PERMISSION_TYPES.READ_WRITE;
  }
}

export function isViewableByPermissionType(permission) {
  return [PERMISSION_TYPES.READ_ONLY, PERMISSION_TYPES.READ_WRITE].includes(permission);
}

export function getVisibilityType(visibilityInfo) {
  if (!visibilityInfo?.visibilityType) {
    return DEFAULT_TYPE;
  }

  return visibilityTypes.includes(visibilityInfo?.visibilityType) ? visibilityInfo.visibilityType : UNKNOWN_TYPE;
}

function isVisibleType(visibilityInfo) {
  const type = getVisibilityType(visibilityInfo);
  return [DEFAULT_TYPE, VISIBLE_TYPE].includes(type);
}

export function getShownInAsEnum(showIn) {
  if (!showIn) {
    return SHOW_IN_TYPES.HOME;
  }

  return SHOW_IN_TYPES[showIn] || SHOW_IN_TYPES.UNKNOWN;
}

export function useDefaultsFrom(listDef, defaultDef) {
  const clone = JSON.parse(JSON.stringify(listDef));

  if (listDef.name == null) {
    clone.name = defaultDef.name;
  }
  if (clone.emptyListMsg == null) {
    clone.emptyListMsg = defaultDef.emptyListMsg;
  }
  if (clone.rank == null) {
    clone.rank = defaultDef.rank;
  }
  if (clone.kind == null) {
    clone.kind = defaultDef.kind;
  }
  if (clone.sublists == null) {
    clone.sublists = defaultDef.sublists;
  }

  return clone;
}
