import {
  isSubBounty,
  isRebounty,
  isCompanySpecial,
  getCompanyFolder,
} from 'Models/bounty/Bounty';
import {
  getCompanySpecialsRef,
  getMySubCardsRef,
  getStreamCardRef,
} from 'Services/FirebaseService';

export function adjustOriginRebounties({ bounty, isAdd }) { // eslint-disable-line
  // if rebounty, add/remove record from origin.rebounties
  if (isRebounty(bounty)) {
    // TODO: add implementation when we'll work on rebounties
  }
}

export const getOwnerId = (bounty) => (
  bounty.owner && bounty.owner.id ? bounty.owner.id : null
);

function getInboundCardRef(bounty, user) {
  if (isSubBounty(bounty)) {
    return getMySubCardsRef(user.uid).child(bounty.parentBounty.id).child(bounty.id);
  }

  if (isCompanySpecial(bounty?.type)) {
    const folderName = getCompanyFolder(bounty?.type);
    const ownerId = getOwnerId(bounty);
    return getCompanySpecialsRef(ownerId).child(folderName).child(bounty.id);
  }

  return getStreamCardRef(user.uid, bounty.id);
}

export function getInboundBountyRef(bounty, user) {
  return getInboundCardRef(bounty, user).child('bounty');
}
