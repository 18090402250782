import { BADGE, BADGE_COLLECTION, PRODUCT, PROMOTION, JOB, AUTOSUGGESTION, PRIVATE_LISTING, ORDER, NEWS, BANNER, MATCH } from 'Constants/bounty/bountyType';

export const AUTOSUGGESTION_FOLDER = 'autosuggestions';
export const PRIVATE_LISTING_FOLDER = 'listings';
export const PRODUCT_FOLDER = 'products';
export const ORDER_FOLDER = 'orders';
export const BADGE_COLLECTION_FOLDER = 'badgeCollection';
export const BADGE_FOLDER = 'badges';
export const PROMOTION_FOLDER = 'promotions';
export const JOBS_FOLDER = 'jobs';
export const NEWS_FOLDER = 'news';
export const BANNER_FOLDER = 'banners';
export const MATCH_FOLDER = 'matches';

export const SENTS_FOLDER_BY_TYPE = {
  [PRODUCT]: PRODUCT_FOLDER,
  [BADGE_COLLECTION]: BADGE_COLLECTION_FOLDER,
  [BADGE]: BADGE_FOLDER,
  [PROMOTION]: PROMOTION_FOLDER,
  [JOB]: JOBS_FOLDER,
  [AUTOSUGGESTION]: AUTOSUGGESTION_FOLDER,
  [PRIVATE_LISTING]: PRIVATE_LISTING_FOLDER,
  [ORDER]: ORDER_FOLDER,
  [NEWS]: NEWS_FOLDER,
  [BANNER]: BANNER_FOLDER,
  [MATCH]: MATCH_FOLDER,
};
