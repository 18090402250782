export function extractInitials(text, maxNbOfInitials) {
  if (!text || !text.trim()) {
    return null;
  }

  const parts = text.split(' ');

  if (parts.length === 0) {
    return null;
  }

  let result = '';
  const possibleNrOfInitials = maxNbOfInitials > parts.length ? parts.length : maxNbOfInitials;

  for (let i = 0; i < possibleNrOfInitials; i++) {
    result = `${result}${parts[i][0]}`;
  }

  return result;
}
