import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { func, object } from 'prop-types';

//  Components
import InputComponent from 'Common/components/InputComponent';

//  Actions/Selectors
import { updateDataSyncAction } from 'Store/actions/genericActions';
import * as modalSelectors from 'Modules/modal/store/selectors';

//  Other resources
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA } from 'Store/reducerProperties';
import IntlMessage from 'Util/IntlMessages';
import { selectedBountySelector } from '../../../companySents/store/selectors';

const FeeContainer = (props) => {
  const { minFee, maxFee, selectedBounty } = props;

  useEffect(() => {
    if (selectedBounty.jobInfo) {
      populateReward();
    }
  }, []);

  const populateReward = () => {
    const { jobInfo } = selectedBounty;

    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { minFee: jobInfo?.minFee || null });
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { maxFee: jobInfo?.maxFee || null });
  };

  const handleChanges = ({ target: { name, value } }) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: { money: { amount: value } } });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4}>
          <InputComponent
            fullWidth
            type="number"
            label={<IntlMessage id="label.minJoblioFee" />}
            name="minFee"
            value={minFee.money?.amount || ''}
            handleChanges={handleChanges}
            size="small"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <InputComponent
            fullWidth
            type="number"
            label={<IntlMessage id="label.maxJoblioFee" />}
            name="maxFee"
            value={maxFee.money?.amount || ''}
            handleChanges={handleChanges}
            size="small"
            InputProps={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
      </Grid>
    </div>
  );
};

FeeContainer.propTypes = {
  minFee: object.isRequired,
  maxFee: object.isRequired,
  selectedBounty: object.isRequired,
  updateDataSyncAction: func.isRequired,
};

const mapStateToProps = (state) => ({
  minFee: modalSelectors.inputsData(state).minFee || {},
  maxFee: modalSelectors.inputsData(state).maxFee || {},
  selectedBounty: selectedBountySelector(state) || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeeContainer);
