import { propatrimonioro } from 'Constants/common';

const propatrimonioroConfig = {
  FLAVOR: 'propatrimonioro',
  id: propatrimonioro,
  title: 'Pro Patrimonio',
  name: 'Pro Patrimonio app',
  brandName: 'Pro Patrimonio',
  appLinkSchema: 'propatrimonio',
  appTld: 'propatrimonio.org',
  logo: {
    logoStyle: 'ml-5',
    containerStyle: 'MuiToolbar-regular-79 no-background-color',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCo867PMdXcCjETt-JlPYaiXfk4v-mr7Fs',
    authDomain: 'propatrimonioro-prod.firebaseapp.com',
    databaseURL: 'https://propatrimonioro-prod.firebaseio.com',
    projectId: 'propatrimonioro-prod',
    storageBucket: 'propatrimonioro-prod.appspot.com',
    messagingSenderId: '98996751508',
    appId: '1:98996751508:web:9765475e8c59164704b881',
    measurementId: 'G-T0FTSY2TFL',
  },
  API_BASE_URL: 'https://api.youkno.ai/api/v1',
  API_REPORT: 'https://api.youkno.ai/download/report',
  login: {
    customCss: '',
    loginWithPin: true,
  },
  algolia_app_id: '',
  algolia_api_key: '',
  TERMS_OF_SERVICE_URL: 'https://www.propatrimonio.org/wp-content/uploads/2018/12/Termeni-si-conditii-1.pdf',
  PRIVACY_STATEMENT_URL: 'https://www.propatrimonio.org/wp-content/uploads/2018/12/Politica-de-Confidentialitate.pdf',
};

export default propatrimonioroConfig;
