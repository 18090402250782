// TODO: refactor this constants
export const SKILLSET_TYPE = 'SKILL';
export const COUNTRY_TYPE = 'COUNTRY';
export const INDUSTRY_TYPE = 'INDUSTRY';

// mode

export const MODE_TEST = 'TEST';
export const MODE_DEFAULT = 'DEFAULT';

export const APPLICATIONS_SEARCH_FIELDS = {
  USER_NAME: 'USER_NAME',
  APPLICANT_POSITION: 'APPLICANT_POSITION',
};

export const BOUNTY_SEARCH_FIELDS = {
  TITLE: 'BOUNTY_TITLE',
  DESCRIPTION: 'BOUNTY_DESCRIPTION',
};
