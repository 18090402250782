import get from 'lodash/get';
import { object, array } from 'prop-types';
import { Box, Grid } from '@mui/material';

//  Components
import CustomMUIDropdown from 'Common/components/CustomMUIDropdown';
import InputField from 'Common/components/fields/InputField';

//  Other resources
import * as FIELDS from 'Constants/fields';
import { MONEY, POINTS } from 'Util/RewardType';
import { getCurrencyProps } from 'Constants/fields';

const ProductPriceComponent = (props) => {
  const { formik, currencyOptions } = props;
  const currencyProps = {
    ...getCurrencyProps(currencyOptions, false),
    name: 'customData.currencyType',
  };
  const hasMainPrice = [MONEY, POINTS].includes(get(formik?.values, currencyProps.name));
  const currencyType = get(formik?.values, currencyProps.name);

  const handleCurrencyChanges = (name, value) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item xs={8}>
        <Box display="flex">
          {hasMainPrice
            ? (
              <InputField
                {...FIELDS.mainPriceField}
                formik={formik}
              />
            ) : (
              <>
                <Box pr={2}>
                  <InputField
                    {...FIELDS.alt1PriceField}
                    label={`${FIELDS.alt1PriceField.label} (${formik?.values?.customData?.currency})`}
                    formik={formik}
                  />
                </Box>
                <InputField
                  {...FIELDS.alt2PriceField}
                  formik={formik}
                />
              </>
            )}
        </Box>
      </Grid>
      <Grid item xs={4}>
        <CustomMUIDropdown
          fullWidth
          onChange={handleCurrencyChanges}
          color="primary"
          value={currencyType}
          {...currencyProps}
        />
      </Grid>

      <Grid item xs={8}>
        <Box display="flex">
          {hasMainPrice
            ? (
              <InputField
                {...FIELDS.mainMsrpField}
                formik={formik}
              />
            ) : (
              <>
                <Box pr={2}>
                  <InputField
                    {...FIELDS.alt1MsrpField}
                    label={`${FIELDS.alt1MsrpField.label} (${formik?.values?.customData?.currency})`}
                    formik={formik}
                  />
                </Box>
                <InputField
                  {...FIELDS.alt2MsrpField}
                  formik={formik}
                />
              </>
            )}
        </Box>
      </Grid>
      <Grid item xs={4}>
        <CustomMUIDropdown
          fullWidth
          onChange={handleCurrencyChanges}
          color="primary"
          value={get(formik?.values, currencyProps.name)}
          {...currencyProps}
        />
      </Grid>
    </Grid>
  );
};

ProductPriceComponent.propTypes = {
  formik: object.isRequired,
  currencyOptions: array.isRequired,
};

export default ProductPriceComponent;
