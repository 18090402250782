import IntlMessages from 'Util/IntlMessages';

export const DEFAULT_USER_RATING = 0;
export const EMPTY_STAR_COLOR = '#eee';

const basicInfoCommonLabels = {
  fullWidth: true,
  variant: 'standard',
};

export const PHONE = {
  phoneNumber: 'phoneNumber',
  label: <IntlMessages id="editProfile.formControls.labels.phoneNumber" />,
  phoneCountryCode: 'phoneCountryCode',
  ...basicInfoCommonLabels,
};

export const EMAIL = {
  name: 'email',
  label: <IntlMessages id="editProfile.formControls.labels.email" />,
  translatedlabelid: 'editProfile.formControls.placeholder.email',
  ...basicInfoCommonLabels,
};

export const PIN = {
  name: 'pin',
  translatedlabelid: 'label.enterThePin',
  ...basicInfoCommonLabels,
};

export const INVITE_CODE = {
  name: 'inviteCode',
  translatedlabelid: 'label.enterTheReferralCode',
  ...basicInfoCommonLabels,
};

export const ENGLISH_NAME = {
  name: 'latinizedName',
  label: <IntlMessages id="editProfile.formControls.labels.englishName" />,
  type: 'text',
  translatedlabelid: 'editProfile.formControls.placeholder.englishName',
  ...basicInfoCommonLabels,
};

export const COUNTRY_FIELD = {
  name: 'citizenship',
  label: <IntlMessages id="label.country" />,
  ...basicInfoCommonLabels,
};

export const AGE_PICKER = {
  translatedlabelid: 'label.dateOfBirth',
  name: 'dob',
  ...basicInfoCommonLabels,
};

export const PROFILE_DESCRIPTION_PROPS = {
  name: 'description',
  label: <IntlMessages id="modal_edit_descr_user_label" />,
  type: 'text',
  placeholder: '',
  autoFocus: true,
  fullWidth: true,
};

export const COMPANY_DESCRIPTION_PROPS = {
  name: 'description',
  label: <IntlMessages id="modal_edit_descr_company_label" />,
  type: 'text',
  placeholder: '',
  autoFocus: true,
  fullWidth: true,
};

export const ADJUSTMENT_ACCOUNT_PROPS = {
  name: 'adjustment',
  label: '',
  type: 'text',
  placeholder: 'update_account_hint',
  autoFocus: true,
  fullWidth: true,
};

export const WORK_EXPERIENCE_YEARS = {
  ONE_TO_THREE: 'ONE_TO_THREE',
  THREE_TO_FIVE: 'THREE_TO_FIVE',
  OVER_FIVE: 'OVER_FIVE',
  OVER_TEN: 'OVER_TEN',
  NONE: 'NONE',
  UNKNOWN: 'UNKNOWN',
};

export const WORK_EXPERIENCE_YEARS_LABELS = {
  [WORK_EXPERIENCE_YEARS.ONE_TO_THREE]: '1 to 3 years',
  [WORK_EXPERIENCE_YEARS.THREE_TO_FIVE]: '3 to 5 years',
  [WORK_EXPERIENCE_YEARS.OVER_FIVE]: '5 + years',
  [WORK_EXPERIENCE_YEARS.OVER_TEN]: '10 + years',
  [WORK_EXPERIENCE_YEARS.NONE]: 'None',
  [WORK_EXPERIENCE_YEARS.UNKNOWN]: 'Unknown',
};

export const WORK_EXPERIENCE_YEARS_AS_OPTIONS = [
  {
    label: WORK_EXPERIENCE_YEARS_LABELS.NONE,
    value: WORK_EXPERIENCE_YEARS.NONE,
  },
  {
    label: WORK_EXPERIENCE_YEARS_LABELS.ONE_TO_THREE,
    value: WORK_EXPERIENCE_YEARS.ONE_TO_THREE,
  },
  {
    label: WORK_EXPERIENCE_YEARS_LABELS.THREE_TO_FIVE,
    value: WORK_EXPERIENCE_YEARS.THREE_TO_FIVE,
  },
  {
    label: WORK_EXPERIENCE_YEARS_LABELS.OVER_FIVE,
    value: WORK_EXPERIENCE_YEARS.OVER_FIVE,
  },
  {
    label: WORK_EXPERIENCE_YEARS_LABELS.OVER_TEN,
    value: WORK_EXPERIENCE_YEARS.OVER_TEN,
  },
];

export const USER_STATUSES = {
  ACTIVE: 'ACTIVE',
  BLACKLISTED: 'BLACKLISTED',
  INACTIVE: 'INACTIVE',
  SUSPENDED: 'SUSPENDED',
  UNKNOWN: 'UNKNOWN',
  TEST: 'TEST',
};

export const USER = 'USER';

export const USER_ENVIRONMENT_MODE_STATUSES = {
  TEST: 'TEST',
  DEFAULT: 'DEFAULT',
};
