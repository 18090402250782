import { Avatar } from '@mui/material';
import { NO_AVATAR_URL, PLACEHOLDER_AVATAR_URL } from 'Constants/constants';
import { extractInitials } from 'Util/MiscStringUtils';

export const TEAM_COMPANY_ID = 'TEAM_COMPANY';

export const isInvalid = (member) => !(member && member.user && member.user.id);

export const getTeamCompanyName = (owner) => {
  let teamCompanyName = null;

  if (owner != null) {
    teamCompanyName = owner.name;
  }

  if (!teamCompanyName) {
    teamCompanyName = 'drawer_item_team_anonymous';
  }

  return teamCompanyName;
};

export const initTeamCompanyGroup = (owner) => {
  const teamCompany = {
    id: TEAM_COMPANY_ID,
    name: getTeamCompanyName(owner),
  };

  return {
    group: true,
    user: teamCompany,
  };
};

export const renderMemberAvatar = (user, avatarColor) => {
  if (user?.avatar) {
    return (
      <Avatar
        alt="avatar-img"
        src={user.avatar.includes(NO_AVATAR_URL) ? PLACEHOLDER_AVATAR_URL : user.avatar}
        className="rounded-circle border-primary"
      />
    );
  }

  const userInitials = user && user.name ? extractInitials(user.name, 2) : null;

  return (
    <Avatar
      style={{ backgroundColor: avatarColor || '#cecece', color: 'white' }}
      className="size-40 rounded-circle"
    >
      {userInitials}
    </Avatar>
  );
};
