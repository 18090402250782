import AttachablePromotionsContainer from 'Common/containers/AttachablePromotionsContainer';
import AttachableProductsContainer from 'Common/containers/AttachableProductsContainer';

//  Common
import IntlMessages from 'Util/IntlMessages';
import { buttons } from 'Constants/common';

export const requireAccessModal = ({
  title, message, closeModal, submitRequest,
}) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title,
  content: <div>{message}</div>,
  actions: {
    buttons: [
      {
        text: <IntlMessages id="labels.cancel" />,
        action: closeModal,
      },
      {
        text: <IntlMessages id="labels.ok" />,
        action: submitRequest,
      },
    ],
  },
});

export const deleteProductModal = ({ closeModal, deleteFn }) => ({
  maxWidth: 'sm',
  title: <IntlMessages id="title.delete.product" />,
  content:
  <div>
    <IntlMessages id="content.delete.product" />
    ?
  </div>,
  actions: {
    buttons: [
      {
        text: 'Cancel',
        action: closeModal,
        color: 'secondary',
      },
      {
        text: 'Delete',
        action: deleteFn,
      },
    ],
  },
});

export const jobBonusModal = ({ close }) => ({
  maxWidth: 'sm',
  title: '',
  content: (
    <div>
      <IntlMessages id="content.add.bonus" />
      <a
        rel="noopener noreferrer"
        href="http://blog.purplepass.com/5-effective-incentives-to-boost-employee-morale"
        target="_blank"
      >
        <IntlMessages id="content.learn.more" />
      </a>
      .
      <br />
      <br />
      <IntlMessages id="content.fill.zero" />
    </div>
  ),
  actions: {
    buttons: [
      {
        text: 'Ok',
        action: close,
      },
    ],
  },
});

export const addProductModal = ({ closeModal }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <IntlMessages id="title.add.product" />,
  content: <AttachableProductsContainer allowMultiple />,
  actions: {
    buttons: [
      {
        text: buttons.cancel,
        action: closeModal,
        color: 'secondary',
      },
      {
        text: buttons.saveSm,
        action: closeModal,
      },
    ],
  },
});

export const addPromotionModal = ({ closeModal }) => ({
  fullWidth: true,
  maxWidth: 'sm',
  title: <IntlMessages id="title.add.product" />,
  content: <AttachablePromotionsContainer />,
  actions: {
    buttons: [
      {
        text: buttons.cancel,
        action: closeModal,
        color: 'secondary',
      },
      {
        text: buttons.saveSm,
        action: closeModal,
      },
    ],
  },
});
