import { union } from 'lodash';
import { pointCurrencyAsEnum, normalizeCurrency } from 'Models/PointCurrency';
import * as Rating from 'Models/Rating';
import { normalizePoints, negate } from 'Models/Points';

export const DEFAULT_STAR_POINTS_VALUE = 1;

export function setStartPointValue(bounty, value) {
  const rating = !bounty?.rating ? Rating.ratingProps : bounty.rating;

  return {
    ...rating,
    starPointsValue: value,
  };
}

export function asMap(points) {
  if (points && Object.keys(points).length) {
    return { [points.currency]: points };
  }

  return null;
}

export function sumOfAbs(points) {
  if (!points || (points && !Object.keys(points).length)) {
    return 0;
  }

  let sum = 0;

  Object.values(points).forEach(({ amount }) => {
    sum += Math.abs(amount);
  });

  return sum;
}

export function getPointsAmount(reward, currency) {
  if (reward) {
    const { points } = reward;

    if (!points || (points && !Object.keys(points).length)) {
      return 0;
    }

    const point = points[currency];

    return (point && point.amount) || 0;
  }

  return 0;
}

function addInPlace(points, point) {
  if (point === null || !Object.keys(point).length) {
    return;
  }

  let old = points[point.currency];

  if (old !== undefined) {
    old.amount += point.amount;
  } else {
    old = point;
  }

  return {
    ...points,
    [point.currency]: old,
  };
}

export function addRating(rating, userId, point) {
  const newRating = rating || {};

  if (point === null) {
    return rating;
  }

  if (newRating.points === undefined) {
    newRating.points = {};
  }

  newRating.points = addInPlace(newRating.points, point);

  if (newRating.userRatings === undefined) {
    newRating.userRatings = {};
  }

  newRating.userRatings = {
    [userId]: point,
  };

  return newRating;
}

export function getPoints(rating, currency) {
  return {
    amount: rating !== null ? getPointsAmount(rating, currency) : 0,
    currency,
  };
}

export function getNormalizedPointCurrencies(points) {
  if (!points || (points && !Object.keys(points).length)) {
    return [];
  }

  const currencyList = [];

  Object.values(points).forEach((point) => {
    const normCurrency = normalizeCurrency(pointCurrencyAsEnum(point.currency));

    if (!currencyList.includes(normCurrency)) {
      currencyList.push(normCurrency);
    }
  });

  return currencyList;
}

export function getNormalizedPoints(points, currency) {
  if (!points || (points && !Object.keys(points).length)) {
    return 0;
  }

  let sum = 0;

  Object.values(points).forEach((point) => {
    const normPoint = normalizePoints(point);

    if (pointCurrencyAsEnum(normPoint.currency) === currency) {
      sum += normPoint.amount;
    }
  });

  return sum;
}

export function addPoints(existingPoints, pointsToAdd) {
  if (!pointsToAdd || !Object.keys(pointsToAdd).length) {
    return existingPoints;
  }

  if (!existingPoints || !Object.keys(existingPoints).length) {
    return pointsToAdd;
  }

  const allCurrencies = union(Object.keys(existingPoints), Object.keys(pointsToAdd));

  const points = {};

  allCurrencies.forEach((currency) => {
    if (!existingPoints[currency]) {
      points[currency] = pointsToAdd[currency];
    } else if (!pointsToAdd[currency]) {
      points[currency] = existingPoints[currency];
    } else {
      points[currency] = {
        currency,
        amount: +existingPoints[currency].amount + +pointsToAdd[currency].amount,
      };
    }
  });

  return points;
}

export function getAvgStartRating(bounty) {
  if (!bounty || !bounty.rating) {
    return null;
  }

  return Rating.getAvgStartRating(bounty.rating);
}

export function negatePoints(points) {
  if (points == null) {
    return null;
  }

  const map = {};

  if (!Object.keys(points).length) {
    return map;
  }

  Object.keys(points).forEach((key) => {
    map[key] = negate(points[key]);
  });

  return map;
}

export function subtractPoints(p1, p2) {
  if (p2 == null || !Object.keys(p2).length) {
    return p1 || null;
  }

  if (p1 == null || !Object.keys(p1).length) {
    return negatePoints(p2);
  }

  return addPoints(p1, negatePoints(p2));
}
