import StatisticsService from 'Services/StatisticsService';
import { NotificationManager } from 'react-notifications';

export const ACTIONS = {
  LOADING: 'LOADING',
  GET_DATA: 'GET_DATA',
};

const updateLoadingStatus = (bool) => (dispatch) => {
  dispatch({
    type: ACTIONS.LOADING,
    payload: bool,
  });
};

export const getStatistics = (options) => (dispatch) => {
  updateLoadingStatus(true)(dispatch);

  StatisticsService.getStatistics(options)
    .then((response) => {
      dispatch({ type: ACTIONS.GET_DATA, payload: response });
    })
    .catch((err) => {
      NotificationManager.error(err.message);
    })
    .finally(() => {
      updateLoadingStatus(false)(dispatch);
    });
};
