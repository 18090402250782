/* eslint-disable */
/**
 * Rct Card
 */
import { RctCardHeading } from './RctCardHeading';

const RctCard = ({ children, customClasses, heading, headingCustomClasses, colClasses }) => (
  <div className={colClasses && colClasses}>
    <div className={`rct-block ${customClasses || ''}`}>
      {heading
                && (
                <RctCardHeading
                  title={heading}
                  customClasses={headingCustomClasses}
                />
                )}
      {children}
    </div>
  </div>
);

export { RctCard };
