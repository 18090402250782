import * as bountyState from 'Constants/bounty/bountyState';
import * as DateUtils from 'Util/DateUtil';

export function isDraftState(state) {
  return [
    bountyState.NONE_STATE,
    bountyState.DRAFT_STATE,
    bountyState.PENDING_STATE,
    bountyState.RETRACTED_STATE,
    bountyState.REJECTED_STATE,
  ].includes(state);
}

export function isActiveState(state) {
  return [
    bountyState.ACTIVE_STATE,
    bountyState.PAUSED_STATE,
    bountyState.SEALED_STATE,
  ].includes(state);
}

export function isPostableState(state) {
  return [bountyState.ACTIVE_STATE].includes(state);
}

export function isClosedState(state) {
  return [
    bountyState.CLOSED_STATE,
    bountyState.EXPIRED_STATE,
    bountyState.ARCHIVED_STATE,
  ].includes(state);
}

export function isEditableState(state) {
  return isDraftState(state) || isActiveState(state);
}

export function isExpiredState(bounty) {
  const { state, expiresAt } = bounty;
  return state === bountyState.EXPIRED_STATE || (state === bountyState.ACTIVE_STATE && DateUtils.isExpired(expiresAt));
}

export function isAllowedToPublish(state) {
  return [bountyState.DRAFT_STATE, bountyState.RETRACTED_STATE].includes(state);
}

export function isAllowedToRetract(state) {
  return [
    bountyState.PENDING_STATE,
    bountyState.ACTIVE_STATE,
    bountyState.SEALED_STATE,
    bountyState.PAUSED_STATE,
    bountyState.CLOSED_STATE,
    bountyState.EXPIRED_STATE,
  ].includes(state);
}

export function isAllowedToDelete(state) {
  return [
    bountyState.DRAFT_STATE,
    bountyState.REJECTED_STATE,
    bountyState.RETRACTED_STATE,
    bountyState.ARCHIVED_STATE,
  ].includes(state);
}

export function isAllowedToPublishResponse(state) {
  return [bountyState.DRAFT_STATE, bountyState.RETRACTED_STATE, bountyState.REJECTED_STATE].includes(state);
}

export function isAllowedToMarkAsOfficial(state) {
  return [bountyState.ACTIVE_STATE, bountyState.PAUSED_STATE, bountyState.CLOSED_STATE].includes(state);
}

export function isAllowedToPin(state) {
  return [
    bountyState.ACTIVE_STATE,
    bountyState.PAUSED_STATE,
    bountyState.CLOSED_STATE,
    bountyState.EXPIRED_STATE,
  ].includes(state);
}

export function isAllowedToReject(state) {
  return [
    bountyState.PENDING_STATE,
    bountyState.ACTIVE_STATE,
    bountyState.PAUSED_STATE,
    bountyState.SEALED_STATE,
    bountyState.CLOSED_STATE,
    bountyState.EXPIRED_STATE,
    bountyState.ARCHIVED_STATE,
  ].includes(state);
}

export function isAllowedToSend(state) {
  return [
    bountyState.ACTIVE_STATE,
    bountyState.PAUSED_STATE,
  ].includes(state);
}

export function isAllowedToClose(state) {
  return [
    bountyState.ACTIVE_STATE,
    bountyState.PAUSED_STATE,
  ].includes(state);
}

export function isAllowedToSimulate(state) {
  return [
    bountyState.ACTIVE_STATE,
    bountyState.PAUSED_STATE,
  ].includes(state);
}

export function isCommentingAllowed(state) {
  return isActiveState(state) || isClosedState(state);
}
