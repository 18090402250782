import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import { EditorState } from 'draft-js';

//  Components
import MarkdownEditor from 'Modules/posts/add/components/MarkdownEditor';
import ContentLanguageManager from 'Modules/posts/add/components/ContentLanguageManager';

//  Actions/Selector
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import { updateDataSyncAction, updateSimpleDataSyncAction } from 'Store/actions/genericActions';

//  Other resources
import { descriptionField } from 'Constants/fields';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUT_LANGUAGE, MODAL_INPUTS_DATA } from 'Store/reducerProperties';
import { getDescription } from 'Util/BountyUtils';
import makeStyles from '@mui/styles/makeStyles';
import { convertToEditorStateObj, prepareMarkdownText } from 'Modules/posts/add/utils/markdownEditorUtils';
import useDebounce from 'Util/AppUtils';

const useStyles = makeStyles(() => ({
  editorWrapper: {
    marginBottom: '15px',
  },
}));

const TranslatableDescriptionContainer = ({ bountyType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedBounty = useSelector(selectedBountySelector);

  const descriptionProps = descriptionField(bountyType);
  const [editorStateObj, setEditorStateObj] = useState(EditorState.createEmpty());
  const [fullContent, setFullContent] = useState('');

  const debouncedStoreUpdate = useDebounce(fullContent, 700);

  useEffect(() => {
    dispatch(updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { description: fullContent }));
  }, [debouncedStoreUpdate]);

  useEffect(() => {
    if (selectedBounty && Object.keys(selectedBounty).length) {
      const description = getDescription({ bounty: selectedBounty }) || '';
      setEditorStateObj(convertToEditorStateObj(description));
    }
  }, [selectedBounty?.description]);

  // Reset language on unmount
  useEffect(() => () => {
    dispatch(updateSimpleDataSyncAction(MODAL_NAMESPACE, MODAL_INPUT_LANGUAGE, ''));
  }, []);

  const handleLanguageChange = (code) => {
    // TODO: remove setTimeout after fixing an actions. Right now - for calling action asynchronously.
    setTimeout(() => dispatch(updateSimpleDataSyncAction(MODAL_NAMESPACE, MODAL_INPUT_LANGUAGE, code)));
  };

  return (
    <div>
      <div className={classes.editorWrapper}>
        <MarkdownEditor
          {...descriptionProps}
          editorState={editorStateObj}
          handleChange={setEditorStateObj}
        />
      </div>

      <ContentLanguageManager
        content={prepareMarkdownText(editorStateObj)}
        onContentChange={(text) => setEditorStateObj(convertToEditorStateObj(text))}
        onFullContentChange={setFullContent}
        onLanguageChange={handleLanguageChange}
      />
    </div>
  );
};

TranslatableDescriptionContainer.propTypes = {
  bountyType: string.isRequired,
};

export default TranslatableDescriptionContainer;
