import { string, func, bool } from 'prop-types';

//  Components
import { ContinueButtonComponent } from 'Common/components';

const propTypes = {
  dismissName: string,
  submitName: string,
  onDismiss: func.isRequired,
  onSubmit: func.isRequired,
  disabled: bool,
  type: string,
};

const defaultProps = {
  dismissName: 'Cancel',
  submitName: 'Save',
  type: 'button',
  disabled: false,
};

const ModalButtons = ({
  disabled,
  dismissName,
  onDismiss,
  onSubmit,
  submitName,
  type,
}) => (
  <div className="text-right mt-40">
    <ContinueButtonComponent
      name={dismissName}
      onSubmit={onDismiss}
      disabled={disabled}
      color="secondary"
    />
    <ContinueButtonComponent
      name={submitName}
      onSubmit={onSubmit}
      disabled={disabled}
      type={type}
      className="ml-10"
    />
  </div>
);

ModalButtons.propTypes = propTypes;
ModalButtons.defaultProps = defaultProps;

export default ModalButtons;
