import StatusLabel from 'Modules/posts/common/components/StatusLabel';
import * as BADGE_TYPES from 'Modules/posts/bounty/constants/badgeType';

const OfficialBadgeStyle = 'official-badge';
const RorBadgeStyle = 'ror-badge';

export function containsType(badgeSource, badgeType) {
  if (!badgeSource || !badgeSource.stash || !Object.keys(badgeSource.stash).length) {
    return false;
  }

  return !!badgeSource.stash[badgeType];
}

export function getDisplayName(badgeType, defaultLabel = null) {
  if (!badgeType) {
    return null;
  }

  if (badgeType === BADGE_TYPES.OFFICIAL) {
    return 'Official';
  }

  if (badgeType === BADGE_TYPES.REQUEST_OFFICIAL_RESPONSE) {
    return 'R.O.R';
  }

  return defaultLabel;
}

export function prepareBadgeStyle(badgeType) {
  if (badgeType === BADGE_TYPES.OFFICIAL) {
    return OfficialBadgeStyle;
  }

  return RorBadgeStyle;
}

export function renderBadges(bounty) {
  const { badges } = bounty;

  if (badges && badges.stash) {
    return Object.keys(badges.stash).map((badgeType) => {
      const badge = badges.stash[badgeType];
      const defaultLabel = (badge.info && badge.info.label) || null;
      const label = getDisplayName(badgeType, defaultLabel);
      const style = prepareBadgeStyle(badgeType);

      return <StatusLabel key={label} label={label.toUpperCase()} className={style} />;
    });
  }

  return null;
}
