export const ratingProps = {
  points: null,
  userRatings: null,
  totalStars: 0,
  ratingCount: 0,
  starPointsValue: 0,
};

export function getEffectiveStartPointsValue(rating, defaultValue) {
  const { starPointsValue } = rating;
  return (starPointsValue === null || starPointsValue === 0) ? defaultValue : starPointsValue;
}

export function getAvgStartRating(rating) {
  if (rating.ratingCount === 0) {
    return null;
  }

  return parseFloat(rating.totalStars) / parseFloat(rating.ratingCount);
}
