import { SELECTED_BOUNTY, USER_SENTS, COMPANY_SENTS, COMPANY_SENTS_DETAILS, COMPANY_SUB_SENTS } from 'Store/reducerProperties';
import { orderBy } from 'lodash';

const orderByCreatedAt = (card) => {
  const cardType = Object.keys(card)[0];
  const { createdAt } = card[cardType];

  return createdAt;
};

export const userSents = (state) => {
  const { data } = state.sentsReducer[USER_SENTS];

  if (data) {
    const orderedByCreatedAt = orderBy(data, orderByCreatedAt, ['desc']);

    return {
      ...state.sentsReducer[USER_SENTS],
      data: orderedByCreatedAt,
    };
  }
  return state.sentsReducer[USER_SENTS];
};

export const companySentsSelector = (state) => state.sentsReducer[COMPANY_SENTS];
export const companySubSentsSelector = (state) => state.sentsReducer[COMPANY_SUB_SENTS];
export const companySentsDetailsSelector = (state) => state.sentsReducer[COMPANY_SENTS_DETAILS];
export const selectedBountySelector = (state) => state.sentsReducer[SELECTED_BOUNTY];
