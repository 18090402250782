export const HIDDEN = 'HIDDEN';
export const BOUNTY = 'BOUNTY';
export const BULLETIN = 'BULLETIN';
export const RESPONSE = 'RESPONSE';

export const bountyType = {
  bounty: 'bounty',
  response: 'response',
  hidden: 'hidden',
  bulletin: 'bulletin',
};
