import { string, func, bool } from 'prop-types';
import Button from '@mui/material/Button';

const ContinueButtonComponent = (props) => {
  const { name, onSubmit, type, fullWidth, className, variant, disabled, size, color } = props;

  return (
    <Button
      fullWidth={fullWidth}
      color={color}
      className={className}
      variant={variant}
      size={size}
      type={type}
      onClick={onSubmit}
      disabled={disabled}
    >
      {name}
    </Button>
  );
};

const propTypes = {
  className: string,
  type: string,
  size: string,
  name: string.isRequired,
  onSubmit: func,
  fullWidth: bool,
  variant: string,
  color: string,
  disabled: bool,
};

const defaultProps = {
  onSubmit: () => {},
  className: '',
  color: 'primary',
  type: 'button',
  fullWidth: false,
  variant: 'contained',
  disabled: false,
  size: 'medium',
};

ContinueButtonComponent.propTypes = propTypes;
ContinueButtonComponent.defaultProps = defaultProps;

export default ContinueButtonComponent;
