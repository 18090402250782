import { mergePermissionTypes, permissionsAsEnum } from 'Models/Lists';
import { AllowMode } from './ProductDefaults';
import { ALL } from './Op';

function isEmptyCapInfo(capInfo) {
  return !!(capInfo.allowMode === null || capInfo.allowMode === undefined);
}

export function mergeCapInfo(curr, newValue) {
  if (!newValue || isEmptyCapInfo(newValue)) {
    return curr;
  }

  return newValue;
}

function isEmptyListInfo(listInfo) {
  return listInfo.rank === null && !listInfo.permissionType;
}

export function mergeListInfo(curr, newValue) {
  if (!newValue || isEmptyListInfo(newValue)) {
    return newValue;
  }

  if (isEmptyListInfo(curr)) {
    return newValue;
  }

  let listInfo = JSON.parse(JSON.stringify(curr));

  if (listInfo.rank === null || (newValue.rank && newValue.rank > listInfo.rank)) {
    listInfo.rank = newValue.rank;
  }

  if (!listInfo.permissionType) {
    listInfo = newValue.permissionType;
    return listInfo;
  }

  if (!newValue.permissionType) {
    listInfo.permissionType = mergePermissionTypes(
      permissionsAsEnum(curr.permissionType),
      permissionsAsEnum(listInfo.permissionType),
    );
    return listInfo;
  }

  return listInfo;
}

export function getAllowModeAsEnum(allowMode) {
  return AllowMode.asEnum(allowMode);
}

export function getRoleDefByName(roles, roleName) {
  return roles && Object.keys(roles).length && roles[roleName] ? roles[roleName] : null;
}

function getRoleAuth(ops, op) {
  return ops ? ops[op] : null;
}

export function getExplicitlyAllowed(op, ops) {
  if (!op) {
    return null;
  }

  const opAuth = getRoleAuth(ops, op);
  return opAuth ? opAuth.allow : null;
}

export function isForbidden(op, ops) {
  const allAuth = getRoleAuth(ALL);

  if (allAuth && allAuth.forbid) {
    return true;
  }

  const opAuth = getRoleAuth(ops, op);

  return !!(opAuth && opAuth.forbid);
}
