import { array, object, func } from 'prop-types';
import { Grid, FormControlLabel, Checkbox } from '@mui/material';

const CheckboxList = ({ items, data, handleChanges }) => (
  <Grid container>
    {items.map(({ value, label }) => (
      <Grid item key={value} xs={6} sm={4} onClick={() => handleChanges(value)}>
        <FormControlLabel
          value={value}
          control={(
            <Checkbox
              color="primary"
              sx={{ whiteSpace: 'nowrap' }}
              checked={data[value]?.enabled || false}
            />
          )}
          label={label}
        />
      </Grid>
    ))}
  </Grid>
);

CheckboxList.propTypes = {
  items: array.isRequired,
  data: object.isRequired,
  handleChanges: func.isRequired,
};

export default CheckboxList;
