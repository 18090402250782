/**
 * @typedef {Object} DictionaryItem
 * @type {Object}
 * @property {string} label Item label
 * @property {string} value Item value
 *
 */

import moment from 'moment';

/**
 * Convert dictionary aka [label: 'a', value: 'val', ...] to {a: 'val, ...}
 *
 *
 * @param {DictionaryItem[]} dictionary Array of items
 * @return {Object}
 */
export const dictionaryToHash = (dictionary) => dictionary.reduce((map, obj) => {
  // eslint-disable-next-line no-param-reassign
  map[obj.value] = obj.label;
  return map;
}, {});

/**
 * Convert dictionary aka [value: 'My Value Label', key: 'my_value',... ] to [label: 'My Value Label', value: 'my_value', ... ]
 * @typedef {Object} OldDictionaryItem
 * @type {Object}
 * @property {string} value Item label
 * @property {string} key Item value
 *
 * @param {OldDictionaryItem[]} arr Array of old dictionary items
 * @return {DictionaryItem[]}
 */
export const valueKeyToLabelValue = (arr) => arr.map(({ key, value }) => ({ label: value, value: key }));

// TODO: remove as soon we move to the new interface
export const labelValueToValueKey = (arr) => arr.map(({ label, value }) => ({ value: label, key: value }));

export const hashToDictionary = (obj) => Object.entries(obj)
  .map((b) => ({ label: b[1], value: b[0] }));

export const dateFormatList = (dateValue, format = 'DD MMM YYYY') => moment(dateValue).format(format);
