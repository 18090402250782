import { getApiData } from 'Services/Api';
import { getQueryString } from 'Util/GeneralUtils';

export const CandidatesService = {
  async getCandidates(options) {
    const queryString = getQueryString(options);

    return getApiData(`/candidates${queryString}`);
  },
};

export default CandidatesService;
