import { isNil } from 'lodash';
import * as Reward from 'Models/Reward';
import * as Money from 'Models/Money';
import { isPartOfCompany, getMyCurrency, isAllowed } from 'Models/Settings';
import { asMap, getPointsAmount } from 'Util/pointsUtils';
import { pointCurrency } from 'Models/PointCurrency';
import { VOUCHERS } from 'Models/Op';
import { BADGE_COLLECTION, JOB } from 'Constants/bounty/bountyType';

export const MONEY = 'MONEY';
export const POINTS = 'POINTS';
export const VOUCHER = 'VOUCHER';
export const NONE = 'NONE';
export const BADGE = 'BADGE';
export const BOTH = 'BOTH';
export const EITHER = 'EITHER';

//  TODO: add intl after product creation switch to the new reward component
const getRewardName = ({ type, localCurrency = 'USD' }) => {
  switch (type) {
    case BADGE:
      return 'badge';
    case VOUCHER:
      return 'voucher';
    case POINTS:
      return 'points';
    case MONEY:
      return localCurrency;
    case BOTH:
      return 'Both';
    case EITHER:
      return 'Either';
    default:
      return '';
  }
};

export const getRewardTypes = (settings, userData, bountyType) => {
  const isAllowedToAddVouchers = isAllowed({ op: VOUCHERS.name, settings, userData });
  const rewardTypes = [
    POINTS,
  ];

  if (getMyCurrency(settings, userData)) {
    rewardTypes.unshift(MONEY);
  }

  if (isAllowedToAddVouchers) {
    rewardTypes.push(VOUCHER);
  }

  //  TODO: (disabled temporary) enabled BADGE reward after we have the final implementation
  if (isPartOfCompany(userData) && bountyType !== BADGE_COLLECTION && false) {
    rewardTypes.push(BADGE);
  }

  return rewardTypes;
};

export function generateRewardTypeOptions({ rewardTypes, localCurrency }) {
  const options = [];

  rewardTypes.forEach((type) => {
    const label = getRewardName({ type, localCurrency });

    if (label) {
      options.push({
        label,
        value: type,
      });
    }
  });

  return options;
}

export function getRewardTypesAsOptions({ userData, settings, bountyType }) {
  let listOfRewardTypes = [];
  const localCurrency = getMyCurrency(settings, userData);

  if (bountyType === JOB) {
    listOfRewardTypes.push(MONEY);
  } else {
    listOfRewardTypes = getRewardTypes(settings, userData, bountyType);
  }

  return generateRewardTypeOptions({ rewardTypes: listOfRewardTypes, localCurrency });
}

// [ANDROID] This information is set in onCreate functions from createPostActivity and BaseCreateActivity
export function formatFormikReward({ reward, userData, settings }) {
  if (reward) {
    if (Reward.hasMoney(reward, true)) {
      const { amount } = reward.money;
      return {
        amount,
        currency: MONEY,
      };
    }

    if (Reward.hasPoints(reward)) {
      return {
        amount: getPointsAmount(reward, pointCurrency.POINT),
        currency: POINTS,
      };
    }

    if (Reward.hasBadge(reward)) {
      return {
        amount: null,
        currency: BADGE,
        badges: reward.badges || null,
      };
    }

    // TODO: voucher implementation
  }

  const rewardTypes = getRewardTypes(settings, userData);

  return { currency: rewardTypes[0] };
}

export function formatApiReward(rewardData = {}, settings, userData) {
  const { currency, amount, badges } = rewardData;
  const amountValue = !isNil(amount) ? amount.toString() : null;
  const reward = JSON.parse(JSON.stringify(Reward.Reward.props));

  if (!rewardData || !rewardData.currency || !amountValue) {
    return null;
  }

  if (!currency) {
    return reward;
  }

  switch (currency) {
    case BADGE:
      reward.badges = badges || null;
      break;
    case POINTS:
      reward.points = asMap({ amount: +amountValue, currency: pointCurrency.POINT });
      break;
    case VOUCHER:
      // TODO: needs implementation
      break;
    default:
      reward.money = Money.moneyConstructor(amountValue, getMyCurrency(settings, userData));
      break;
  }

  return reward;
}
