/**
 * This component provide a CRUD operations for a content languages
 * @param {string} content Text editor content, as a plain text
 * @param {function} onContentChange Current content change handler (depend on selected language)
 * @param {function} onFullContentChange Full content change handler (full united content)
 * @param {function} onLanguageChange Selected language change handler (full united content)
 */

import { useEffect, useMemo, useState } from 'react';
import { CustomMUIDropdown } from 'Common/components';
import { translationsCommonCodes } from 'Constants/translations';
import { LANGUAGES } from 'Constants/languages';
import {
  addTranslation,
  getDictionaryFromTranslations,
  langSortFunction,
  textToTranslations,
  updateTranslationByCode,
} from 'Util/TranslationsUtils';
import { func, string } from 'prop-types';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

const propTypes = {
  content: string.isRequired,
  onContentChange: func.isRequired,
  onFullContentChange: func.isRequired,
  onLanguageChange: func,
};

const defaultProps = {
  onLanguageChange: () => {},
};

const useStyles = makeStyles(() => ({
  selectWrapper: {
    marginBottom: '10px',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  addIcon: {
    marginTop: '3px',
  },
}));

const ContentLanguageManager = (
  {
    content, onContentChange, onFullContentChange, onLanguageChange,
  },
) => {
  const intl = useIntl();
  const classes = useStyles();

  const [translations, setTranslations] = useState([]);
  const [langDictionary, setLangDictionary] = useState([]);
  const [langToAddDictionary, setLangToAddDictionary] = useState([]);
  const [selectedTranslation, setSelectedTranslation] = useState({ code: '', text: '' });
  const [langToAdd, setLangToAdd] = useState('');
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const getTranslationByCode = (code) => translations.find((t) => t.code === code);

  const getSelectedTranslation = () => getTranslationByCode(selectedTranslation.code);

  const handleAddLanguagePickerClose = () => setMenuAnchorEl(null);

  const handleNewLanguagePick = (code) => {
    setLangToAdd(code);
    handleAddLanguagePickerClose();
  };

  const handleLanguageChange = (code) => setSelectedTranslation({ code, text: getTranslationByCode(code).text });

  // Set initial content
  useEffect(() => {
    const translationsExceptUnited = translations.filter(({ code }) => code !== translationsCommonCodes.united);
    if (!translationsExceptUnited.length) {
      setTranslations(textToTranslations(content));
    }
  }, [content]);

  // Update dropdown dictionary
  useMemo(() => {
    setLangDictionary(getDictionaryFromTranslations(translations));
  }, [translations]);

  useEffect(() => {
    if (translations.length) {
      onFullContentChange(getTranslationByCode(translationsCommonCodes.united).text);
    }
  }, [translations]);

  // Set selected language if not picked.
  useEffect(() => {
    if (!selectedTranslation?.code && translations.length > 0) {
      const { code, text } = translations.find((t) => t.code === translationsCommonCodes.united);
      setSelectedTranslation({ code, text });
    }
  }, [translations.length]);

  // Set dictionary for languages which available to be added.
  useMemo(() => {
    const currentCodes = translations
      .filter(({ code }) => code !== translationsCommonCodes.united)
      .map(({ code }) => code);

    const availableCodes = LANGUAGES
      .filter(({ value }) => !(currentCodes.includes(value)))
      .sort(langSortFunction);

    setLangToAddDictionary(availableCodes);
  }, [translations.length]);

  // Set editor content depend on selected language.
  useEffect(() => {
    if (translations.length && selectedTranslation?.code) {
      onContentChange(getSelectedTranslation().text);
    }
  }, [selectedTranslation?.code]);

  // Save changes to translations array.
  useEffect(() => {
    if (!selectedTranslation?.code) return;

    const updatedTranslations = selectedTranslation.code === translationsCommonCodes.united
      ? textToTranslations(selectedTranslation.text) // Generate translations from the scratch
      : updateTranslationByCode(translations, selectedTranslation.code, selectedTranslation.text);

    setTranslations(updatedTranslations);
  }, [selectedTranslation?.text]);

  // Set content from editor as a plain text.
  useEffect(() => {
    if (selectedTranslation?.code && translations.length) {
      setSelectedTranslation({ ...selectedTranslation, text: content });
    }
  }, [content]);

  // Set active language as a new added
  useEffect(() => {
    if (langToAdd) {
      setSelectedTranslation({ code: langToAdd, text: '' });
      setLangToAdd('');
    }
  }, [translations.length]);

  // Update transitions with new language
  useEffect(() => {
    if (langToAdd) {
      setTranslations(addTranslation(translations, langToAdd));
    }
  }, [langToAdd]);

  useEffect(() => onLanguageChange(selectedTranslation.code), [selectedTranslation.code]);

  return (
    <div className={classes.actionButtons}>
      <CustomMUIDropdown
        fullWidth
        customStyle={classes.selectWrapper}
        label={intl.formatMessage({ id: 'language' })}
        value={selectedTranslation.code}
        variant="outlined"
        onChange={(name, value) => handleLanguageChange(value)}
        color="primary"
        options={langDictionary}
      />

      <IconButton
        color="primary"
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
        size="large"
      >
        <AddCircleOutline className={classes.addIcon} />
      </IconButton>

      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={handleAddLanguagePickerClose}
      >
        {langToAddDictionary.map(({ label, value }) => (
          <MenuItem key={value} onClick={() => handleNewLanguagePick(value)}>{label}</MenuItem>
        ))}
      </Menu>
    </div>
  );
};

ContentLanguageManager.propTypes = propTypes;
ContentLanguageManager.defaultProps = defaultProps;

export default ContentLanguageManager;
