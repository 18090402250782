/* eslint-disable */
/**
 * Rct Card Content
 */
const RctCardContent = ({ children, customClasses, noPadding }) => (
  <div className={`${noPadding ? 'rct-full-block' : 'rct-block-content'} ${customClasses || ''}`}>
    {children}
  </div>
);

export { RctCardContent };
