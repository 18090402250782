import { connect } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import { func } from 'prop-types';

//  Actions
import { closeModal, openModal } from 'Modules/modal/store/operations';

//  Other resources
import { addProductModal, addPromotionModal } from 'Modules/posts/add/utils/modalConfig';

const AttachProductContainer = (props) => {
  const onAddProduct = () => {
    props.openModal(addProductModal({
      closeModal: props.closeModal,
    }));
  };

  const onAddPromotion = () => {
    props.openModal(addPromotionModal({
      closeModal: props.closeModal,
    }));
  };

  return (
    <div>
      <Tooltip title="Attach product" arrow>
        <IconButton onClick={onAddProduct} size="large">
          <span className="color-green material-icons">
            store
          </span>
        </IconButton>
      </Tooltip>
      <Tooltip title="Attach promotion" arrow>
        <IconButton onClick={onAddPromotion} size="large">
          <span className="color-green material-icons">
            local_offer
          </span>
        </IconButton>
      </Tooltip>
    </div>
  );
};

AttachProductContainer.propTypes = {
  openModal: func.isRequired,
  closeModal: func.isRequired,
};

const mapDispatchToProps = {
  openModal,
  closeModal,
};

export default connect(null, mapDispatchToProps)(AttachProductContainer);
