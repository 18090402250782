import get from 'lodash/get';
import { array, func, object, string } from 'prop-types';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

//  Components
import InputField from 'Common/components/fields/InputField';
import DatePickerField from 'Common/components/fields/DatePickerField';
import ProductPriceComponent from 'Modules/products/components/ProductPriceComponent';
import { ContinueButtonComponent } from 'Common/components';
import RenderFilesToUpload from 'Modules/attachments/containers/RenderFilesToUpload';

//  Other resources
import * as FIELDS from 'Constants/fields';
import { PRODUCT_KIND } from 'Models/product/Product';
import IntlMessage from 'Util/IntlMessages';

const ProductForm = (props) => {
  const {
    formik, currencyOptions, kind, onCancel, selectedBounty,
  } = props;
  const classes = useStyles();

  const handleCheckboxChange = ({ target: { checked } }) => {
    formik.setFieldValue(FIELDS.unlimitedUseQuantityField.name, checked);
    formik.setFieldValue(FIELDS.useQuantityField.name, '');
    formik.setFieldError(FIELDS.useQuantityField.name, '');
  };

  return (
    <Box className={classes.root}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputField
              {...FIELDS.productName}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              {...FIELDS.productDescriptionField}
              name="product.description"
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              {...FIELDS.merchantSKU}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              {...FIELDS.additionalInfo}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductPriceComponent
              formik={formik}
              currencyOptions={currencyOptions}
            />
          </Grid>
        </Grid>
        {kind === PRODUCT_KIND.TICKET && (
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={6}>
              <DatePickerField
                label={<IntlMessage id="label.fromDate" />}
                name="customData.skuInfo.onlineProductInfo.availableFrom"
                formik={formik}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePickerField
                label={<IntlMessage id="label.toDate" />}
                name="customData.skuInfo.onlineProductInfo.availableTo"
                formik={formik}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                {...FIELDS.useQuantityField}
                formik={formik}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={(
                  <Checkbox
                    {...FIELDS.unlimitedUseQuantityField}
                    checked={get(formik?.values, FIELDS.unlimitedUseQuantityField.name) || false}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                )}
                label={FIELDS.unlimitedUseQuantityField.label}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                {...FIELDS.quantityField}
                formik={formik}
              />
            </Grid>
          </Grid>
        )}
        <Box py={2}>
          <InputField
            {...FIELDS.tagsField}
            name="customData.tag"
            formik={formik}
          />
        </Box>
        <RenderFilesToUpload bounty={selectedBounty} />
        <Box py={3} display="flex" justifyContent="flex-end">
          <ContinueButtonComponent
            color="secondary"
            className="mr-20"
            name="Cancel"
            onSubmit={onCancel}
          />
          <ContinueButtonComponent
            name="Save"
            type="submit"
          />
        </Box>
      </form>
    </Box>
  );
};

ProductForm.propTypes = {
  formik: object.isRequired,
  selectedBounty: object.isRequired,
  currencyOptions: array.isRequired,
  kind: string.isRequired,
  onCancel: func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '500px',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100vw - 50px)',
    },
  },
}));

export default ProductForm;
