import { Tooltip } from '@mui/material';
import IntlMessages from 'Util/IntlMessages';

//  Other resources
import { LOTTERY } from 'Constants/bounty/bountyType';
import { WORK_EXPERIENCE_YEARS_AS_OPTIONS } from 'Modules/myProfile/constants/constants';
import { APPLICANT_REGIONS_AS_OPTIONS } from 'Models/User';
import { getDescriptionHint } from 'Modules/posts/add/utils/baseCreateActivity';
import { generateTimeOptions } from 'Util/BountyUtils';

export const productName = {
  label: <IntlMessages id="label.name" />,
  name: 'product.name',
  type: 'text',
  placeholder: <IntlMessages id="placeholder.addProductName" />,
  autoFocus: true,
  fullWidth: true,
};

export const productDescriptionField = {
  label: <IntlMessages id="label.description" />,
  name: 'product.description',
  type: 'text',
  placeholder: <IntlMessages id="placeholder.addProductDescription" />,
  fullWidth: true,
  multiline: true,
};

export const additionalInfo = {
  label: <IntlMessages id="label.additionalInfo" />,
  name: 'product.additionalInfo',
  type: 'text',
  placeholder: <IntlMessages id="placeholder.addInformation" />,
  fullWidth: true,
  multiline: true,
};

export const merchantSKU = {
  label: <IntlMessages id="label.productSku" />,
  placeholder: <IntlMessages id="placeholder.addSku" />,
  name: 'product.merchantSku',
  fullWidth: true,
};

export const quantity = {
  label: <IntlMessages id="label.quantity" />,
  name: 'quantity',
  type: 'text',
  placeholder: <IntlMessages id="placeholder.addQuantity" />,
};

export const amount = {
  label: <IntlMessages id="cart.itemPrice" />,
  placeholder: <IntlMessages id="labels.addPrice" />,
  name: 'bountyAmount',
  type: 'number',
};

export const getCurrencyProps = (options = [], disabled = true) => ({
  name: 'currency',
  disabled,
  options,
});

export const titleField = {
  label: <IntlMessages id="label.newsTitle" />,
  name: 'title',
  type: 'text',
  placeholder: <IntlMessages id="placeholder.addTitle" />,
  fullWidth: true,
};

export const targetUrlField = {
  label: <IntlMessages id="bounty.targetUrl" />,
  name: 'targetUrl',
  type: 'text',
  placeholder: <IntlMessages id="bounty.targetUrl.placeholder" />,
  fullWidth: true,
};

export const termsField = {
  ...targetUrlField,
  label: <IntlMessages id="bounty.terms" />,
  placeholder: <IntlMessages id="bounty.terms.placeholder" />,
};

export const tagsField = {
  label: <IntlMessages id="label.tag" />,
  name: 'tags',
  placeholder: <IntlMessages id="placeholder.addTag" />,
  fullWidth: true,
};

export const streamUrlField = {
  label: <IntlMessages id="label.streamUrl" />,
  name: 'streamUrl',
  type: 'text',
  placeholder: <IntlMessages id="placeholder.addStreamUrl" />,
  fullWidth: true,
};

export const streamKeyField = {
  label: <IntlMessages id="label.streamKey" />,
  name: 'streamKey',
  type: 'text',
  placeholder: <IntlMessages id="placeholder.addStreamUrl" />,
  fullWidth: true,
};

export const useQuantityField = {
  label: <IntlMessages id="label.howManyTimesCanBeUsed" />,
  name: 'customData.skuInfo.onlineProductInfo.useQty',
  type: 'text',
  placeholder: <IntlMessages id="placeholder.specifyNumber" />,
  fullWidth: true,
};

export const unlimitedUseQuantityField = {
  label: <IntlMessages id="label.unlimitetUse" />,
  name: 'customData.skuInfo.onlineProductInfo.unlimitedUseQty',
};

export const quantityField = {
  label: <IntlMessages id="label.ticketsQuantity" />,
  name: 'customData.skuInfo.quantity',
  type: 'text',
  placeholder: <IntlMessages id="placeholder.specifyNumber" />,
  fullWidth: true,
};

export const mainPriceField = {
  label: <IntlMessages id="labels.price" />,
  name: 'customData.price.main',
  placeholder: <IntlMessages id="placeholder.addAmount" />,
  fullWidth: true,
  type: 'number',
};

export const alt1PriceField = {
  label: <IntlMessages id="labels.price" />,
  name: 'customData.price.money',
  placeholder: <IntlMessages id="placeholder.addAmount" />,
  fullWidth: true,
  type: 'number',
};

export const alt2PriceField = {
  label: <IntlMessages id="labels.pricePoints" />,
  name: 'customData.price.points',
  placeholder: <IntlMessages id="placeholder.addPoints" />,
  fullWidth: true,
  type: 'number',
};

export const mainMsrpField = {
  label: <IntlMessages id="labels.msrp" />,
  name: 'customData.msrp.main',
  placeholder: <IntlMessages id="placeholder.addAmount" />,
  fullWidth: true,
  type: 'number',
};

export const alt1MsrpField = {
  label: <IntlMessages id="labels.msrp" />,
  name: 'customData.msrp.money',
  placeholder: <IntlMessages id="placeholder.addAmount" />,
  fullWidth: true,
  type: 'number',
};

export const alt2MsrpField = {
  label: <IntlMessages id="labels.msrpPoints" />,
  name: 'customData.msrp.points',
  placeholder: <IntlMessages id="placeholder.addPoints" />,
  fullWidth: true,
  type: 'number',
};

export function getTargetUrlProperties(bountyType) {
  return bountyType === LOTTERY ? termsField : targetUrlField;
}

export const descriptionField = (bountyType) => ({
  name: 'description',
  type: 'text',
  placeholder: getDescriptionHint(bountyType, true),
  autoFocus: true,
  fullWidth: true,
});

export const officialResponseField = {
  name: 'officialResponse',
  text: 'bounty.requestOfficialResponse',
};

export const getShowInField = (options = []) => ({
  name: 'listId',
  text: 'bounty.showIn',
  options,
});

export const showAnonymityField = {
  name: 'anonymity',
  skipIntl: true,
  text: (
    <>
      <span>
        <IntlMessages id="bounty.postAnonymously" />
      </span>
      <Tooltip
        id="tooltip-top-start"
        title={<IntlMessages id="bounty.anonymityHelpContent" />}
        placement="top-start"
      >
        <span className="material-icons ml-10">info_outline</span>
      </Tooltip>
    </>),
};

export const controlResponsesPrivacyField = {
  name: 'visibilityType',
  text: 'bounty.makeResponsesPrivate',
};

export const expireFeatureField = {
  label: <IntlMessages id="bounty.expiresIn" />,
  days: {
    name: 'days',
    text: 'labels.days',
  },
  hours: {
    name: 'hours',
    text: 'labels.hours',
  },
  minutes: {
    name: 'minutes',
    text: 'labels.minutes',
  },
};

export const closeOnField = {
  ...expireFeatureField,
  label: <IntlMessages id="bounty.closeOn" />,
};

export const durationFeatureField = {
  ...expireFeatureField,
  label: <IntlMessages id="bounty.availableFor" />,
};

export const distTargetGroupField = {
  name: 'distTargetGroup',
  text: 'bounty.audience',
};

export const priceField = {
  label: <IntlMessages id="labels.price" />,
  placeholder: <IntlMessages id="labels.addPrice" />,
  name: 'price',
  type: 'number',
};

export const workExperienceField = {
  name: 'experienceYears',
  text: 'applicant.experienceYears',
  options: WORK_EXPERIENCE_YEARS_AS_OPTIONS,
  fullWidth: true,
};

export const applicantRegionField = {
  name: 'applicantsSourceRegion',
  text: 'applicant.region',
  options: APPLICANT_REGIONS_AS_OPTIONS,
  fullWidth: true,
};

export const scheduleForField = {
  name: 'postAt',
  label: <IntlMessages id="bounty.scheduleFor" />,
};

export const timeToRespondField = {
  fullWidth: true,
  name: 'timeLimit',
  text: 'bounty.timeToRespond',
  options: generateTimeOptions({ minValue: 5, maxValue: 180, step: 5 }),
};
