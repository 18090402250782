import { useRef } from 'react';
import Editor from '@draft-js-plugins/editor';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import createLinkPlugin from '@draft-js-plugins/anchor';
import createEmojiPlugin from 'draft-js-emoji-plugin';
import { string, object, func } from 'prop-types';
import {
  ItalicButton,
  BoldButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from '@draft-js-plugins/buttons';

// CSS
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';
import 'draft-js-emoji-plugin/lib/plugin.css';

// Plugins
const linkifyPlugin = createLinkifyPlugin();
const toolbarPlugin = createToolbarPlugin();
const linkPlugin = createLinkPlugin();
const emojiPlugin = createEmojiPlugin({ useNativeArt: true });
const plugins = [toolbarPlugin, linkifyPlugin, linkPlugin, emojiPlugin];

// Components
const { LinkButton } = linkPlugin;
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
const { Toolbar } = toolbarPlugin;
const toolbarButtons = [
  EmojiSelect,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  BlockquoteButton,
  UnorderedListButton,
  OrderedListButton,
  BoldButton,
  ItalicButton,
  LinkButton,
];

const MarkdownEditor = ({
  placeholder, editorState, handleChange, ...rest
}) => {
  const editorRef = useRef(null);
  const focus = () => {
    editorRef.current.focus();
  };

  return (
    <div className="my-10">
      <h4>{placeholder}</h4>
      <Toolbar>
        {
          (buttonProps) => (
            <div>
              {toolbarButtons.map((Button, i) => (
                buttonProps.getEditorState
                  ? <Button key={`toolbar-button${i}`} {...buttonProps} />
                  : null
              ))}
            </div>
          )
        }
      </Toolbar>
      <div
        className="editor"
        onClick={focus}
        role="presentation"
      >
        <Editor
          editorState={editorState}
          onChange={handleChange}
          plugins={plugins}
          ref={editorRef}
          {...rest}
        />
        <EmojiSuggestions />
      </div>
    </div>
  );
};

MarkdownEditor.propTypes = {
  handleChange: func.isRequired,
  editorState: object.isRequired,
  placeholder: string.isRequired,
};

export default MarkdownEditor;
