import { getApiData } from 'Services/Api';
import { formatApiRoute } from 'Util/api/apiHelpers';
import { LOOKUPS_API_ROUTE } from 'Constants/apiRoutes';

export const JobInformationService = {
  async getJobInfo(type, queryParams) {
    const queryObject = {
      ...queryParams,
      limit: 1000,
    };
    const path = formatApiRoute({ endpoint: LOOKUPS_API_ROUTE, params: { lookuptype: type }, queryParams: queryObject });
    return getApiData(path);
  },
};

export default JobInformationService;
