import { getApiData } from 'Services/Api';
import { getQueryString } from 'Util/GeneralUtils';

export const UserManagementService = {
  async getUsers(options) {
    const reqOptions = { ...options };
    const queryString = getQueryString(reqOptions);
    return getApiData(`/users${queryString}`);
  },
};

export default UserManagementService;
