import { useEffect } from 'react';
import { number, func, bool } from 'prop-types';
import Pagination from '@mui/material/Pagination';
import { Box } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

//  Other resources
import { paginationItemsPerPage } from 'Constants/common';

const PaginationComponent = (props) => {
  const { totalCount = 0, itemsPerPage, page, handlePageChange, defaultPage, demoStory } = props;
  const history = useHistory();

  const { search, pathname } = useLocation();
  const pageNumber = parseFloat(new URLSearchParams(search).get('page'));

  const onPageChange = (event, value) => {
    if (value !== page) {
      handlePageChange(value);
      history.push(`${pathname}?page=${value}`);
    }
  };

  useEffect(() => {
    if (!demoStory) {
      const scrollNode = document.getElementsByClassName('rct-scroll')[1];
      scrollNode?.firstChild?.scrollTo(0, 0);
      handlePageChange(pageNumber || 1);
    }
  }, [pageNumber]);

  return (
    <Box display="flex" justifyContent="center">
      <Pagination
        count={Math.ceil(totalCount / itemsPerPage)}
        page={page}
        onChange={onPageChange}
        defaultPage={defaultPage}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};

PaginationComponent.propTypes = {
  totalCount: number.isRequired,
  page: number.isRequired,
  itemsPerPage: number,
  defaultPage: number,
  handlePageChange: func.isRequired,
  demoStory: bool,
};

PaginationComponent.defaultProps = {
  defaultPage: 1,
  itemsPerPage: paginationItemsPerPage,
  demoStory: false,
};

export default PaginationComponent;
