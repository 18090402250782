import { Select, FormControl, MenuItem } from '@mui/material';
import { useContext, useState } from 'react';
import { FlagIcon } from 'react-flag-kit';
import { LanguageContext } from 'Contexts/languagesContext';
import langTexts from 'Util/lang/langTexts';
import { generateImageByCountryCode } from 'Util/languagesHelpers';
import { useSelector } from 'react-redux';
import { postLocaleServer } from 'Services/locale/LocaleService';
import * as userSelectors from 'AuthSelectors';
import IntlMessage from 'Util/IntlMessages';
import * as styles from './index.module.scss';
import { getShortLangCode } from '../../../../util/TranslationsUtils';

const LanguagePicker = () => {
  const userId = useSelector(userSelectors.getUserUid);
  const { locale, setLocale } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);

  const handleChange = ({ target: { value } }) => {
    setLocale(value);
    postLocaleServer(userId, value);
  };

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const renderArrowIcon = () => <i className="icon-arrow-down-1" />;

  return (
    <FormControl sx={{ maxWidth: 180 }}>
      <Select
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={locale}
        defaultValue={locale}
        variant="standard"
        autoWidth
        onChange={handleChange}
        disableUnderline
        IconComponent={renderArrowIcon}
        classes={{ select: open ? styles.selectOpen : styles.select }}
        className={styles.languageSelector}
        MenuProps={{
          PaperProps: { className: styles.paper },
          MenuListProps: { disablePadding: true },
        }}
      >
        {langTexts.map((language) => (
          <MenuItem
            key={language.key}
            value={language.key}
            title={<IntlMessage id={`lang-${getShortLangCode(language.key).toUpperCase()}`} />}
            className={styles.menuItem}
          >
            <span>
              <FlagIcon className={styles.icon} size={20} code={generateImageByCountryCode(language.key)} />
              <span className={styles.text}>
                <IntlMessage id={`lang-${getShortLangCode(language.key).toUpperCase()}`} />
              </span>
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguagePicker;
