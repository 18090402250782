import { getEffectiveVariant } from 'Models/product/Product';
import { getOptionItemName } from 'Models/product/Options';

export function valueOfProductInfo({ productBounty, sku }) {
  const { product } = productBounty;
  const productFlavor = getEffectiveVariant(product, sku);
  const { price } = productFlavor;

  const productInfo = {
    bountyId: productBounty.id || null,
    merchant: product.merchant || null,
    merchantId: product.merchantId || null,
    productSku: productFlavor.sku || null,
    opt1Name: getOptionItemName(product.options, 1, productFlavor.optionKey1),
    opt2Name: getOptionItemName(product.options, 2, productFlavor.optionKey2),
    opt3Name: getOptionItemName(product.options, 3, productFlavor.optionKey3),
    name: product.name || null,
    description: product.description || null,
    imageUrl: product.imageUrl || null,
    msrp: productFlavor.msrp || null,
    deliveryType: product.deliveryType || null,
    quantity: 1,
    addedAt: new Date().getTime(),
  };

  if (price) {
    // for now, assume it's the main price
    productInfo.price = price.mainPrice || null;
  }

  return productInfo;
}
