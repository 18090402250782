import { Component } from 'react';
import { array, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { Box } from '@mui/material';

//  Components
import DropzoneItemComponent from 'Modules/attachments/components/DropzoneItemComponent';

//  Actions/Reducers
import { attachmentsSelector } from 'Modules/attachments/store/selectors';
import { updateListSyncAction } from 'Store/actions/genericActions';

//  Other resources
import { addNewFile, chooseAttachmentSource } from 'Util/AttachmentsUtils';
import { ATTACHMENTS_NAMESPACE } from 'Store/namespaces';
import { ATTACHMENTS, REMOVED_ATTACHMENTS } from 'Store/reducerProperties';
import { ATTACHMENT_TYPE } from 'Constants/attachment';

const propTypes = {
  attachments: array.isRequired,
  options: array,
  updateListSyncAction: func.isRequired,
  multiple: bool,
};

const defaultProps = {
  options: [],
  multiple: true,
};

class FileUploadContainer extends Component {
  state = {
    options: chooseAttachmentSource(this.props.options),
  };

  componentWillUnmount() {
    this.props.updateListSyncAction(ATTACHMENTS_NAMESPACE, ATTACHMENTS, []);
    this.props.updateListSyncAction(ATTACHMENTS_NAMESPACE, REMOVED_ATTACHMENTS, []);
  }

  uploadOneFile = (file) => {
    const { attachments, multiple } = this.props;

    const data = addNewFile(file);

    if (data) {
      const reader = new FileReader();

      reader.onload = (event) => {
        data.localUri = event.target.result;

        if (data.type === ATTACHMENT_TYPE.IMAGE) {
          const image = new Image();
          image.src = event.target.result;

          image.onload = () => {
            data.w = image.width;
            data.h = image.height;
          };
        }

        if (!multiple) {
          this.props.updateListSyncAction(ATTACHMENTS_NAMESPACE, ATTACHMENTS, [data]);
          this.props.updateListSyncAction(ATTACHMENTS_NAMESPACE, REMOVED_ATTACHMENTS, attachments);
        } else {
          attachments.push(data);
          this.props.updateListSyncAction(ATTACHMENTS_NAMESPACE, ATTACHMENTS, attachments);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  handleUploadFiles = (acceptedFields) => {
    if (acceptedFields) {
      acceptedFields.forEach((file) => this.uploadOneFile(file));
    }
  };

  render() {
    const { options } = this.state;
    const { multiple } = this.props;

    return (
      <Box display="flex">
        {options.map((item, index) => (
          <DropzoneItemComponent
            key={index}
            item={item}
            multiple={multiple}
            handleUploadFiles={this.handleUploadFiles}
          />
        ))}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  attachments: attachmentsSelector(state) || [],
});

const mapDispatchToProps = {
  updateListSyncAction,
};

FileUploadContainer.propTypes = propTypes;
FileUploadContainer.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadContainer);
