import { useState, useEffect } from 'react';
import { node, func } from 'prop-types';
import { connect } from 'react-redux';

//  Components
import RctPageLoader from 'Common/components/RctPageLoader';

//  Actions/Selectors
import {
  getUserData,
  getCompanySettings,
  getSystemSettings,
  getSystemInfo,
  getOrgTypeSettings,
} from 'Store/actions/settingsActions';
import { getAccountInfo } from 'Store/actions/commonActions';
import { firebaseGetCurrentUser } from 'Services/FirebaseService';
import { getOrganizationType } from 'Models/Settings';

const AppInfoData = (props) => {
  const { children } = props;
  const [shouldRendering, setShouldRendering] = useState(false);

  useEffect(() => {
    const { uid } = firebaseGetCurrentUser();

    if (!uid) {
      return setShouldRendering(true);
    }

    getAppData(uid);
  }, []);

  const getAppData = (userId) => {
    Promise.all([
      props.getAccountInfo(),
      props.getSystemInfo(),
      props.getUserData({ userId }),
      props.getSystemSettings(),
    ])
      .then((result) => {
        const [,, userData, systemSettings] = result;

        const settings = {
          userSettings: (userData && userData.settings) || null,
          systemSettings: systemSettings || null,
        };

        if (userData && userData.company) {
          return props.getCompanySettings({ companyId: userData.company.id })
            .then((companySettingsResult) => {
              settings.companySettings = companySettingsResult || null;

              fetchOrgTypeSettings(settings);
            });
        }

        fetchOrgTypeSettings(settings);
      });
  };

  const fetchOrgTypeSettings = (settings) => {
    const orgType = getOrganizationType(settings);

    if (orgType) {
      return props.getOrgTypeSettings(orgType)
        .then(() => setShouldRendering(true));
    }

    return setShouldRendering(true);
  };

  if (!shouldRendering) {
    return <RctPageLoader />;
  }

  return (
    children
  );
};

AppInfoData.propTypes = {
  children: node.isRequired,
  getAccountInfo: func.isRequired,
  getSystemInfo: func.isRequired,
  getUserData: func.isRequired,
  getSystemSettings: func.isRequired,
  getCompanySettings: func.isRequired,
  getOrgTypeSettings: func.isRequired,
};

const mapDispatchToProps = {
  getAccountInfo,
  getUserData,
  getCompanySettings,
  getSystemInfo,
  getSystemSettings,
  getOrgTypeSettings,
};

export default connect(null, mapDispatchToProps)(AppInfoData);
