import { getPossibleCardType } from 'Modules/onboarding/utils/cardUtils';
import { isBlank } from 'Modules/onboarding/utils/StripeTextUtils';

export const DISMISSED_KEY_PREFIX = 'XX.';

export function getBrand(brand, number) {
  if (isBlank(brand) && !isBlank(number)) {
    const brandClone = getPossibleCardType(number, true);
    return brandClone;
  }

  return brand;
}

export function getLast4(last4, number) {
  if (!isBlank(last4)) {
    return last4;
  }

  if (number && number.length > 4) {
    const last4Clone = number.substring(number.length - 4);
    return last4Clone;
  }

  return null;
}
