import { bool, node } from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

const LightLoadingComponent = (props) => {
  const { isLoading, children, fullPage } = props;
  const classes = useStyles();

  return (
    <Box position="relative">
      {children}
      <Backdrop
        open={isLoading}
        className={`${classes.backdrop} ${fullPage && classes.fullPage}`}
      >
        {isLoading && <CircularProgress size={32} className={classes.loading} />}
      </Backdrop>
    </Box>
  );
};

LightLoadingComponent.propTypes = {
  isLoading: bool.isRequired,
  children: node,
  fullPage: bool,
};

LightLoadingComponent.defaultProps = {
  fullPage: false,
  children: null,
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  fullPage: {
    position: 'fixed',
  },
  loading: {
    color: theme.palette.primary.main,
  },
}));

export default LightLoadingComponent;
