/* eslint-disable max-len */
import { fcwiltz } from 'Constants/common';

const fcwiltzConfig = {
  FLAVOR: 'fcwiltz',
  id: fcwiltz,
  title: 'FC WILTZ 71',
  name: 'FC WILTZ 71 app',
  brandName: 'FC WILTZ 71',
  appLinkSchema: 'fcwiltz',
  appTld: 'fcwiltz.com',
  logo: {
    logoStyle: 'ml-5',
    imgStyle: 'fcwiltz-logo',
    containerStyle: 'MuiToolbar-regular-79 background-fcwiltz',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCUT0zvkYjlJmfiDTkJIvMCoaHeLLuNLkg',
    authDomain: 'fcwiltz-prod.firebaseapp.com',
    databaseURL: 'https://fcwiltz-prod-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'fcwiltz-prod',
    storageBucket: 'fcwiltz-prod.appspot.com',
    messagingSenderId: '604822142791',
    appId: '1:604822142791:web:dda3bfe85321d0e9d31662',
    measurementId: 'G-0R6XECBX6S',
  },
  stripeKey: 'pk_live_51HAd9YClAKpP590WbZpNar2FwxP2r9uZglZgIfEjT247vJnrji0TBdDoFfJVgy4X2HFVUl3eletPHW3JwTvd8YCt00mOlIW0gl',
  API_BASE_URL: 'https://api.youkno.ai/api/v1',
  API_REPORT: 'https://api.youkno.ai/download/report',
  login: {
    customCss: '',
    loginWithPin: true,
  },
  algolia_app_id: '',
  algolia_api_key: '',
  TERMS_OF_SERVICE_URL: 'https://www.fcwiltz.com/page/fc-wiltz-71-app-92544',
  PRIVACY_STATEMENT_URL: 'https://www.fcwiltz.com/page/fc-wiltz-71-app-92544',
};

export default fcwiltzConfig;
