import { string, oneOfType, number, bool, array, func } from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';

const Dropdown = ({
  id, name, className, options, value, placeholder, disabled, fullWidth, onChange,
}) => {
  const classes = useStyles();

  return (
    <Select
      id={id}
      value={value}
      name={name}
      onChange={onChange}
      fullWidth={fullWidth}
      disabled={disabled}
      className={`${classes.root} ${!value ? classes.selectPlaceholder : ''} ${className}`}
      displayEmpty
    >
      <MenuItem
        value=""
        disabled
      >
        {placeholder}
      </MenuItem>
      {
        options.map(({ value: optionValue, label }, index) => (
          <MenuItem key={`${index}-${value}`} value={optionValue}>
            { label }
          </MenuItem>
        ))
      }
    </Select>
  );
};

Dropdown.propTypes = {
  name: string,
  className: string,
  value: oneOfType([
    string,
    number,
  ]),
  options: array,
  onChange: func,
  id: string,
  placeholder: string,
  disabled: bool,
  fullWidth: bool,
};

Dropdown.defaultProps = {
  name: '',
  value: '',
  options: [],
  onChange: () => { },
  id: '',
  placeholder: '',
  disabled: false,
  fullWidth: false,
  className: '',
};

const useStyles = makeStyles(() => ({
  root: {
    height: '45px',
    borderRadius: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '10px 15px 15px',

    '& fieldset legend': {
      display: 'none',
    },
  },
  selectPlaceholder: {
    color: '#727891',
  },
}));

export default Dropdown;
