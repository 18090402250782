function getRoleList(grant) {
  const roles = grant ? grant.roles : '';

  if (roles) {
    return roles.split(',');
  }

  return [];
}

function isGranted(grant, userRoles) {
  const grantRoles = getRoleList(grant);

  if (!grantRoles.length || !userRoles.length) {
    return false;
  }

  return !!userRoles.find((role) => grantRoles.includes(role));
}

export { isGranted };
