import { func, object } from 'prop-types';
import { TextField } from '@mui/material';
import CustomMUIDropdown from 'Common/components/CustomMUIDropdown';

const PriceComponent = (props) => {
  const {
    handleAmountChanges,
    handleCurrencyChanges,
    amount,
    currency,
    data,
  } = props;

  return (
    <div className="row">
      <div className="col-sm-6 col-md-8 col-xl-8">
        <TextField
          margin="dense"
          fullWidth
          value={data[amount.name]}
          onChange={handleAmountChanges}
          {...amount}
        />
      </div>
      <div className="col-sm-6 col-md-4 col-xl-4 mt-20">
        <CustomMUIDropdown
          onChange={handleCurrencyChanges}
          color="primary"
          value={data[currency.name]}
          {...currency}
        />
      </div>
    </div>
  );
};

PriceComponent.propTypes = {
  handleAmountChanges: func.isRequired,
  handleCurrencyChanges: func,
  amount: object.isRequired,
  currency: object.isRequired,
  data: object.isRequired,
};

PriceComponent.defaultProps = {
  handleCurrencyChanges: () => {},
};

export default PriceComponent;
