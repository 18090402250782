import { Component } from 'react';
import { object, string, func, bool } from 'prop-types';
import Autosuggest from 'react-autosuggest';
import FormLabel from '@mui/material/FormLabel';
import withStyles from '@mui/styles/withStyles';
import {
  getSuggestions,
  renderInput,
  renderSuggestionsContainer,
  getSuggestionValue,
  renderSuggestion,
} from '../utils/helpers';

const styles = () => ({
  suggestionsContainerOpen: {
    marginTop: '-20px',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd',
  },
});

class CountryAutosuggestion extends Component {
  state = {
    suggestions: [],
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({ suggestions: getSuggestions(value) });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  render() {
    const {
      classes, countryName = '', onCountryChange, isJoblio, label,
    } = this.props;
    const { suggestions } = this.state;

    return (
      <>
        {Boolean(label) && <FormLabel component="legend">{label}</FormLabel>}
        <Autosuggest
          theme={{
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
            suggestionHighlighted: classes.suggestionHighlighted,
          }}
          renderInputComponent={renderInput}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          renderSuggestionsContainer={renderSuggestionsContainer}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            classes,
            placeholder: isJoblio ? 'Job Site Country' : 'Select country',
            value: countryName,
            onChange: onCountryChange,
          }}
        />
      </>
    );
  }
}

CountryAutosuggestion.propTypes = {
  classes: object.isRequired,
  countryName: string,
  label: string,
  onCountryChange: func.isRequired,
  isJoblio: bool,
};

CountryAutosuggestion.defaultProps = {
  countryName: '',
  label: '',
  isJoblio: false,
};

export default withStyles(styles)(CountryAutosuggestion);
