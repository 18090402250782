import { useState } from 'react';
import IntlMessage from 'Util/IntlMessages';
import {
  bool,
  string,
  func,
  object,
} from 'prop-types';
import withStyles from '@mui/styles/withStyles';

// Components
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// Styles set into material-ui theme provider
const useStyles = (theme) => {
  if (!theme) return null;
  return {
    root: {
      margin: 0,
    },
    switchBase: {
      margin: 0,
      height: 'auto',
    },
  };
};

const ToggleSwitch = ({ name, checked, classes, placeholder, checkedPlaceholder, labelPlacement, onChange }) => {
  const [isToggleOn, setToggle] = useState(checked);

  const handleToggleChange = (e) => {
    setToggle(!isToggleOn);

    onChange(e);
  };

  return (
    <FormControlLabel
      value={placeholder}
      classes={{
        root: classes.root,
      }}
      control={(
        <Switch
          id={name}
          color="primary"
          classes={classes}
          name={name}
          checked={isToggleOn}
          onChange={handleToggleChange}
          value={!isToggleOn}
        />
      )}
      label={isToggleOn ? checkedPlaceholder : placeholder}
      labelPlacement={labelPlacement}
    />
  );
};

ToggleSwitch.propTypes = {
  name: string,
  checked: bool,
  placeholder: string,
  labelPlacement: string,
  onChange: func.isRequired,
  checkedPlaceholder: string,
  classes: object,
};

ToggleSwitch.defaultProps = {
  name: '',
  checked: false,
  placeholder: <IntlMessage id="placeholder.off" />,
  labelPlacement: 'start',
  checkedPlaceholder: 'On',
  classes: {},
};

export default withStyles(useStyles, { withTheme: true })(ToggleSwitch);
