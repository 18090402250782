import { getApplicationConfig } from 'Util/AppUtils';
import { REBOUNTY, STASH, BACKSTAGE } from 'Modules/posts/bounty/constants/productFlavour';

const FLAVOUR = getApplicationConfig().FLAVOR;

const productFlavour = {
  REBOUNTY,
  BACKSTAGE,
  STASH,
};

/**
 * @param {string} product
 * @returns {string | null}
 */
function asEnum(product) {
  if (!product) {
    return null;
  }

  if (product === 'Youkno') {
    return BACKSTAGE;
  }

  return productFlavour[product.toUpperCase()] || null;
}

export function getCurrent() {
  return asEnum(FLAVOUR);
}
