import { NotificationManager } from 'react-notifications';
import moment from 'moment';

// Modules
import { bountyDialogOptionsConfig } from 'Modules/posts/add/utils/createBountyOptionsDialog';
import * as baseCreateActivity from 'Modules/posts/add/utils/baseCreateActivity';
import { featureNames, appliesTo, setupFeatures } from 'Modules/posts/add/utils/feature';
import { COACHING } from 'Modules/posts/bounty/constants/activityTypes';
import { getStreamListDefs, getPostableListDefs } from 'Models/ListManager';

// Models
import { getMyCurrency, isAllowed } from 'Models/Settings';
import { isContest, isSurveyType, setListDef } from 'Models/bounty/Bounty';
import { setScoreDescription, setSurveyDescription } from 'Models/survey/Survey';
import * as operations from 'Models/Op';
import { formatDistributionAreasList, formatSentTo } from 'Models/bounty/DistributionGroup';

// Utils
import { setStartPointValue } from 'Util/pointsUtils';
import { POINTS, MONEY, formatApiReward } from 'Util/RewardType';
import { getCountryCode } from 'Util/GeneralUtils';

// Constants
import { DEFAULT_RATE_STAR_POINTS_VALUE } from 'Constants/bounty/bounty';
import { DAY_IN_MILLIS, WEEK_IN_MILLIS } from 'Constants/dateUtils';
import { SURVEY, TMOB_CHALLENGE, SCORE, CLASSIFIED, BADGE_COLLECTION, BADGE, MCQ, JOB, BANNER, NEWS, LOTTERY } from 'Constants/bounty/bountyType';
import IntlMessages from 'Util/IntlMessages';
import * as OpManager from 'Models/OpManager';

export const DEFAULT_SURVEY_INITIAL_OPTIONS = 2;

export function onCreate({
  option, userData, settings, selectedBounty,
}) {
  const { activityType, bountyType, isContestSubBounty } = option;
  const commonProps = { activityType, userData, settings };
  const identityMode = OpManager.getIdentityMode(settings, userData);

  const allowedFeatures = setupFeatures(commonProps);
  const hasAttachments = appliesTo({ feature: featureNames.ATTACHMENTS, ...commonProps });
  const hasControlResponsesPrivacy = appliesTo({ feature: featureNames.CONTROL_RESPONSES_PRIVACY, ...commonProps });
  const hasDescription = appliesTo({ feature: featureNames.DESCRIPTION, ...commonProps });
  const hasDistTargetGroup = appliesTo({ feature: featureNames.DIST_TARGET_GROUP, ...commonProps });
  const hasDistExcludeGroup = appliesTo({ feature: featureNames.DIST_EXCLUDE_GROUP, ...commonProps });
  const hasExpiryFeature = appliesTo({ feature: featureNames.EXPIRY_DATE, ...commonProps })
    && (
      activityType !== COACHING || isAllowed({ op: operations.CONTROL_BOUNTY_DISTRIBUTION.name, settings, userData }));

  const streamList = getStreamListDefs(settings, userData);
  const lists = getPostableListDefs(streamList, userData, bountyType);
  const hasDisplayIn = lists && lists.length > 1 && allowedFeatures.hasDisplayIn;
  const isSubbountyOfaContest = isContestSubBounty || isContest(selectedBounty?.parentBounty?.type);

  return {
    ...allowedFeatures,
    hasReward: isSubbountyOfaContest ? false : allowedFeatures.hasReward,
    hasDistTargetGroup: isSubbountyOfaContest ? false : hasDistTargetGroup,
    hasPostOnBehalfOf: isSubbountyOfaContest ? false : allowedFeatures.hasPostOnBehalfOf,
    hasDisplayIn: isSubbountyOfaContest ? false : hasDisplayIn,
    hasExpiryFeature: isSubbountyOfaContest ? false : hasExpiryFeature,
    hasTimeToRespond: allowedFeatures.hasTimeToRespond && isSubbountyOfaContest,
    hasAttachments,
    hasDescription,
    hasControlResponsesPrivacy,
    hasDistExcludeGroup,
    hasControlAnonymity: !identityMode.forced,
    hasCategory: false, // for the time being, hide the cateogory stuff, we're not using it at this point
  };
}

export function getStarPointsValue(hasRatingValue, savedData) {
  if (!hasRatingValue) {
    return DEFAULT_RATE_STAR_POINTS_VALUE;
  }

  try {
    return parseInt(savedData.rating, 10);
  } catch (nfe) {
    return DEFAULT_RATE_STAR_POINTS_VALUE;
  }
}

export function prepareExpiryDate({ days = 0, minutes = 0, hours = 0 }) {
  if (!days && !minutes && !hours) {
    return null;
  }

  let now = new Date();
  now = moment(now).add(days, 'd').toDate();
  now = moment(now).add(hours, 'h').toDate();
  now = moment(now).add(minutes, 'm').toDate();

  return now.getTime();
}

export function getPrice({ settings, userData, price }) {
  const myCurrency = getMyCurrency(settings, userData);
  const amount = parseFloat(price).toFixed(2);
  return {
    amount: amount ? amount.toString() : null,
    currency: myCurrency || null,
  };
}

function prepareIndustryAndSkills(values) {
  return values.reduce((acc, cur) => {
    acc[cur.key] = {
      name: cur.value || cur.name,
    };
    return acc;
  }, {});
}

export function saveExtraData(props) {
  const {
    settings, userData, bountyType, bounty = {}, savedData,
  } = props;
  const hasRatingValue = appliesTo({ feature: featureNames.RATING_VALUE, ...commonProps });

  const rating = setStartPointValue(bounty, getStarPointsValue(hasRatingValue, savedData));
  const streamLists = getStreamListDefs(settings, userData);
  const lists = getPostableListDefs(streamLists, userData, bountyType);
  const extraProps = {};
  const bountyOptions = bountyDialogOptionsConfig[bountyType] || {};
  const commonProps = { activityType: bountyOptions.activityType, userData, settings };

  if (appliesTo({ feature: featureNames.REWARD, ...commonProps })) {
    extraProps.reward = formatApiReward(savedData.reward, settings, userData);
  }

  if (appliesTo({ feature: featureNames.SCHEDULE_FOR, ...commonProps })) {
    extraProps.postAt = savedData.postAt || null;
  }

  if (appliesTo({ feature: featureNames.TARGET_URL, ...commonProps })) {
    extraProps.targetUrl = savedData.targetUrl || null;
  }

  if (appliesTo({ feature: featureNames.STREAM_DETAILS, ...commonProps })) {
    extraProps.broadcastInfo = {
      streamUrl: savedData.streamUrl || null,
      streamKey: savedData.streamKey || null,
    };
  }

  if (appliesTo({ feature: featureNames.PRICE, ...commonProps })) {
    const money = getPrice({ settings, userData, price: savedData.price });
    extraProps.reward = {
      ...(extraProps.reward ? extraProps.reward : {}),
      money,
    };
  }

  if (lists && lists.length > 0) {
    const listDef = lists.length === 1
      ? lists[0]
      : lists.find((item) => item.id === savedData.listId);

    Object.assign(extraProps, setListDef(bounty, listDef));
  }

  if (appliesTo({ feature: featureNames.EXPIRY_DATE, ...commonProps })) {
    const expirationDate = prepareExpiryDate(savedData);

    if (bountyType === LOTTERY) {
      extraProps.contestInfo = {
        endedAt: expirationDate || null,
      };
    } else {
      extraProps.expiresAt = expirationDate || null;
    }
  }

  if (appliesTo({ feature: featureNames.DIST_TARGET_GROUP, ...commonProps })) {
    if (!savedData.distribution || (savedData.distribution && !savedData.distribution.length)) {
      extraProps.distribution = null;
      extraProps.to = null;
    } else {
      extraProps.distribution = formatDistributionAreasList(savedData.distribution, false);
      extraProps.to = formatSentTo(savedData.distribution);
    }
  }

  if (appliesTo({ feature: featureNames.CHOICES, ...commonProps })) {
    const { surveyOptions, description } = savedData;
    extraProps.description = setSurveyDescription(surveyOptions, description, bountyType);
  }

  if (bountyType === SCORE) {
    const { description } = savedData;
    extraProps.description = setScoreDescription(description);
  }

  if ([BADGE_COLLECTION, BADGE].includes(bountyType)) {
    extraProps.name = savedData.name;

    if (savedData.points) {
      extraProps.points = savedData.points;
    }
  }

  if (savedData?.timeLimit) {
    extraProps.timeLimit = savedData.timeLimit;
  }

  if (bountyType === JOB) {
    const {
      payLowEnd, payHighEnd, bonus, minFee, maxFee, currency, minGrossPay, maxGrossPay, maxNetPay, minNetPay,
    } = savedData;

    extraProps.jobInfo = {
      payLowEnd: formatApiReward({ amount: payLowEnd?.money.amount || null, currency }, settings, userData),
      payHighEnd: formatApiReward({ amount: payHighEnd?.money.amount || null, currency }, settings, userData),
      minFee: formatApiReward({ amount: minFee?.money.amount || null, currency }, settings, userData),
      maxFee: formatApiReward({ amount: maxFee?.money.amount || null, currency }, settings, userData),
      minGrossPay: formatApiReward({ amount: minGrossPay?.money.amount || null, currency }, settings, userData),
      maxGrossPay: formatApiReward({ amount: maxGrossPay?.money.amount || null, currency }, settings, userData),
      minNetPay: formatApiReward({ amount: minNetPay?.money.amount || null, currency }, settings, userData),
      maxNetPay: formatApiReward({ amount: maxNetPay?.money.amount || null, currency }, settings, userData),
      bonus: formatApiReward({ amount: bonus?.money.amount || null, currency }, settings, userData),
      payPeriod: savedData.payPeriod || null,
      bonusPeriod: savedData.bonusPeriod || null,
      bonusType: savedData.bonusType || null,
      benefits: savedData.benefits || null,
      countryCode: savedData.countryName ? getCountryCode(savedData.countryName) : null,
      skillSet: savedData.skills ? prepareIndustryAndSkills(savedData.skills) : null,
      industries: savedData.industries ? prepareIndustryAndSkills([savedData.industries]) : null,
      maxPositions: savedData.maxPositions || null,
      hrsPerWeekMax: +savedData.hrsPerWeekMax || null,
      hrsPerWeekMin: +savedData.hrsPerWeekMin || null,
      minAge: +savedData.minAge || null,
      maxAge: +savedData.maxAge || null,
      // experienceLevel: savedData.experienceLevel || null, // if needed later
      experienceYears: savedData.experienceYears || null,
      applicantsSourceRegion: savedData.applicantsSourceRegion || null,
    };
  }

  return {
    rating,
    ...extraProps,
    onBehalfOf: savedData?.onBehalfOf?.user || null,
  };
}

function getDefaultExpiryDate(activityTypes) {
  return new Date().getTime() + (activityTypes === SURVEY ? DAY_IN_MILLIS : WEEK_IN_MILLIS);
}

export function getExpiryDate(activityTypes, savedData) {
  const expiryDate = savedData.expiresAt || getDefaultExpiryDate(activityTypes);
  return new Date(expiryDate);
}

function isValidPointsAmount(bountyAmount) {
  return !!baseCreateActivity.parsePointsAmount(bountyAmount);
}

export function isValidBounty({
  bountyType, savedData, settings, userData,
}) {
  const {
    description,
    currency,
    bountyAmount,
    badges,
    surveyOptions,
    price,
    correctWeight,
  } = savedData;

  if (![NEWS, BANNER].includes(bountyType) && (!description || (description && !description.trim()))) {
    NotificationManager.error(<IntlMessages id="bounty.descriptionMissing" />);
    return false;
  }

  if (bountyType === TMOB_CHALLENGE) {
    if (currency === MONEY) {
      return baseCreateActivity.isValidMoneyAmount(settings, userData, bountyAmount);
    }

    if (currency === POINTS) {
      return isValidPointsAmount(bountyAmount);
    }

    if (currency === BADGE) {
      return !!badges;
    }

    return true;
  }

  if (bountyType === CLASSIFIED) {
    return baseCreateActivity.isValidPrice(price);
  }

  if (isSurveyType(bountyType)) {
    const emptyField = Object.keys(surveyOptions).find((key) => !surveyOptions[key].text);
    if (emptyField) {
      NotificationManager.error(<IntlMessages id="bounty.choiceIsRequired" values={{ code: emptyField }} />);
      return false;
    }
  }

  if (bountyType === MCQ) {
    const correctAnswer = Object.keys(surveyOptions).filter((key) => (
      +surveyOptions[key].weight === correctWeight
    ));

    if (!correctAnswer || !correctAnswer.length) {
      NotificationManager.error(<IntlMessages id="bounty.checkCorrectAnswer" />);
      return false;
    }
  }

  if (bountyType === LOTTERY && !savedData?.days && !savedData?.minutes && !savedData?.hours) {
    NotificationManager.error(<IntlMessages id="bounty.setLotteryCloseDate" />);
    return false;
  }

  return true;
}
