import { fetchBounty } from 'Services/bounty/BountyService';

import { NotificationManager } from 'react-notifications';
import { firebaseGetOutboundBountyRef } from 'Services/FirebaseService';
import { convertObjToArray } from 'Util/helpers';
import { formatMessage } from 'Util/IntlMessages';

export const ACTIONS = {
  LOADING: 'bountyReducer/Loading',
  GET_BOUNTY: 'bountyReducer/GetBounty',
  RESET: 'bountyReducer/Reset',
};

const updateLoadingStatus = (bool) => (dispatch) => {
  dispatch({
    type: ACTIONS.LOADING,
    payload: bool,
  });
};

export const getBounty = (id) => (dispatch) => {
  updateLoadingStatus(true)(dispatch);

  fetchBounty(id)
    .then((response) => {
      dispatch({ type: ACTIONS.GET_BOUNTY, payload: response });
    })
    .catch((err) => {
      NotificationManager.error(err.message);
    })
    .finally(() => {
      updateLoadingStatus(false)(dispatch);
    });
};

export const getOutboundBounty = (ownerId, bountyId) => (dispatch) => {
  try {
    firebaseGetOutboundBountyRef(ownerId, 'jobs', bountyId)
      .once('value', async (dataSnapshot) => {
        const { bounty } = convertObjToArray(dataSnapshot.val())[0] || {};
        if (bounty?.id) {
          dispatch({ type: ACTIONS.GET_BOUNTY, payload: bounty });
        } else {
          NotificationManager.error(formatMessage({ id: 'errors.bountyNotFound' }));
        }
      });
  } catch (e) {
    console.error(e);
  }
};

export const subscribeToOutboundBounty = (ownerId, bountyId) => (dispatch) => {
  try {
    firebaseGetOutboundBountyRef(ownerId, 'jobs', bountyId)
      .on('value', async (dataSnapshot) => {
        const { bounty } = convertObjToArray(dataSnapshot.val())[0] || {};
        if (bounty?.id) {
          dispatch({ type: ACTIONS.GET_BOUNTY, payload: bounty });
        } else {
          NotificationManager.error(formatMessage({ id: 'errors.bountyNotFound' }));
        }
      });
  } catch (e) {
    console.error(e);
  }
};

export const unsubscribeFromOutboundBounty = (ownerId, bountyId) => {
  firebaseGetOutboundBountyRef(ownerId, 'jobs', bountyId).off();
};
