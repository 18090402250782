import { NotificationManager } from 'react-notifications';
import * as Money from 'Models/Money';
import * as Voucher from 'Models/Voucher';
import * as Badge from 'Models/Badge';
import { addPoints, sumOfAbs, negatePoints, subtractPoints } from 'Util/pointsUtils';
import { firstNonNull } from 'Util/ObjectUtils';
import IntlMessages from 'Util/IntlMessages';
import { pointToString } from 'Models/Points';
import { voucherToString } from 'Models/Voucher';
import { badgeToString } from 'Models/Badge';

export const Reward = {
  props: {
    money: null,
    points: null,
    voucher: null,
    badges: null,
  },
};

export function hasMoney(reward, allowZero = false) {
  // eslint-disable-next-line no-unsafe-optional-chaining
  return reward && reward.money && !Number.isNaN(+reward.money?.amount) && (allowZero || !Money.isZero(reward.money));
}

export function hasPoints(reward) {
  return reward && reward.points && sumOfAbs(reward.points) > 0;
}

export function hasVoucher(reward) {
  return reward && reward.voucher && Voucher.isValid(reward.voucher);
}

export function hasBadge(reward) {
  return reward && reward.badges && Badge.isValid(reward.badges);
}

export function toMap(reward = {}) {
  return {
    money: reward?.money || null,
    points: reward?.points || null,
    voucher: reward?.voucher || null, // TODO: needs implementation
    badges: reward?.badges || null, // TODO: needs implementation
  };
}

export function isPayableDirectly(reward) {
  return isPointsOnly(reward) || (hasVoucher(reward) && Voucher.isIntrinsic(reward.voucher));
}

export function isPointsOnly(reward) {
  return hasPoints(reward) && !hasMoney(reward) && !hasVoucher(reward) && !hasBadge(reward);
}

export function addValueOfPoints(pts) {
  if (!pts || !pts.amount || !pts.currency) {
    return { points: null };
  }

  return {
    points: {
      [pts.currency]: pts,
    },
  };
}

function isSingleItemReward(reward) {
  if (hasMoney()) {
    return !hasPoints(reward) && !hasVoucher(reward) && !hasBadge(reward);
  }
  if (hasPoints(reward)) {
    if (Object.keys(reward.points).length > 1) {
      return false;
    }
    return !hasMoney(reward) && !hasVoucher(reward) && !hasBadge(reward);
  }
  if (hasVoucher(reward)) {
    return !hasMoney(reward) && !hasPoints(reward) && !hasBadge(reward);
  }
  if (hasBadge(reward)) {
    return !hasMoney(reward) && !hasPoints(reward) && !hasVoucher(reward);
  }
  return false;
}

export function unrollReward(reward) {
  if (!reward || (reward && !Object.keys(reward).length)) {
    return [];
  }

  if (isSingleItemReward(reward)) {
    return [reward];
  }

  const list = [];

  if (hasMoney(reward)) {
    list.push({ money: reward.money });
  }

  if (hasPoints(reward)) {
    Object.keys(reward.points).forEach((key) => {
      list.push({ points: { [key]: reward.points[key] } });
    });
  }

  if (hasVoucher()) {
    list.push({ voucher: reward.voucher });
  }

  return list;
}

export function isRewardEmpty(reward) {
  return !hasMoney(reward) && !hasPoints(reward) && !hasVoucher(reward) && !hasBadge(reward);
}

export function negateReward(reward) {
  const {
    money, points, voucher, badges,
  } = reward;

  if (isRewardEmpty(reward)) {
    return reward;
  }

  return {
    money: money ? Money.negateMoney(money) : null,
    points: negatePoints(points),
    voucher: voucher ? Voucher.negateVoucher(voucher) : null,
    badges: badges ? Badge.negateBadge(badges) : null,
  };
}

export function add(existingReward, rewardToAdd) {
  if (!existingReward) {
    return rewardToAdd;
  }

  if (!rewardToAdd || isRewardEmpty(rewardToAdd)) {
    return existingReward;
  }

  if (isRewardEmpty(rewardToAdd)) {
    return rewardToAdd;
  }

  if (hasVoucher(existingReward) && hasVoucher(rewardToAdd)) {
    NotificationManager.error(<IntlMessages id="reward.maxOneVoucher" />);
    return null;
  }

  if (hasBadge(existingReward) && hasBadge(rewardToAdd)) {
    NotificationManager.error(<IntlMessages id="reward.maxOneBadge" />);
    return null;
  }

  if (hasMoney(existingReward) && hasMoney(rewardToAdd)) {
    if (existingReward.money.currency !== rewardToAdd.money.currency) {
      NotificationManager.error(<IntlMessages id="reward.maxOneCurrency" />);
      return null;
    }
  }

  return {
    money: Money.addMoney(existingReward.money, rewardToAdd.money) || null,
    points: addPoints(existingReward.points, rewardToAdd.points) || null,
    voucher: firstNonNull(existingReward.voucher, rewardToAdd.voucher) || null,
    badges: firstNonNull(existingReward.badges, rewardToAdd.badges) || null,
  };
}

export function sum(rewards) {
  if (!rewards || !rewards.length) {
    return {};
  }

  if (rewards.length === 1) {
    return rewards[0];
  }

  let result = {};

  rewards.forEach((reward) => {
    result = add(result, reward);
  });

  return result;
}

export function cloneReward(reward) {
  const {
    money, points, voucher, badges,
  } = reward;

  if (isRewardEmpty(reward)) {
    return {};
  }

  return {
    money: money ? { ...money } : null,
    points: points ? { ...points } : null,
    voucher: voucher ? { ...voucher } : null,
    badges: badges ? { ...badges } : null,
  };
}

export function subtractReward(left, right) {
  if (!left) {
    return right ? negateReward(right) : null;
  }

  return subtract(left, right);
}

function subtract(left, right) {
  if (!right || isRewardEmpty(right)) {
    return cloneReward(left);
  }

  if (isRewardEmpty(left)) {
    return negateReward(right);
  }

  return {
    money: Money.subtractMoney(left.money, right.money),
    points: subtractPoints(left.points, right.points),
    voucher: left.voucher || null,
    badges: left.badges || null,
  };
}

export function rewardToString(reward) {
  const arr = [];

  if (isRewardEmpty(reward)) {
    return '';
  }

  if (hasMoney(reward)) {
    arr.push(Money.moneyToString(reward.money));
  }

  if (hasPoints(reward)) {
    Object.values(reward.points).forEach((point) => {
      arr.push(pointToString(point));
    });
  }

  if (hasVoucher(reward)) {
    arr.push(`voucher:${voucherToString(reward.voucher)}`);
  }

  if (hasBadge(reward)) {
    arr.push(`badge:${badgeToString(reward.badges)}`);
  }

  return arr.join(', ');
}
