// Actions/Reducers
import { getHandlers } from 'Store/reducers/helpers';

//  Others
import { MY_PROFILE_NAMESPACE } from 'Store/namespaces';
import * as DATA from 'Store/reducerProperties';

// initial state
const INIT_STATE = {
  [DATA.USER_PROFILE]: {
    data: {
      basicInfo: {
        address: {
          phoneNumber: '',
          phoneCode: '',
        },
        citizenship: '',
        dob: '',
        email: '',
        latinizedName: '',
        firstName: '',
        middleName: '',
        lastName: '',
        secondLastName: '',
        gender: '',
        skillset: '',
        mailingAddress: '',
        passportInfo: '',
        personalNumericalCode: '',
        sameAddress: false,
      },
    },
  },
  [DATA.USER_AVATAR_URL]: '',
  [DATA.USER_ENDORSEMENTS]: [],
  [DATA.USER_PRIVATE_INFO]: {},
  [DATA.USER_AUDIT_INFO]: {},
};

const handlersReducer = getHandlers(MY_PROFILE_NAMESPACE);

export default function myProfileReducer(state = INIT_STATE, action = {}) {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}
