import { useState } from 'react';
import { func } from 'prop-types';
import { useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const propTypes = {
  onDecline: func.isRequired,
  onSuccess: func.isRequired,
};

const ConfirmationDialog = ({ onSuccess, onDecline }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const intl = useIntl();
  const [modal, setModal] = useState(true);

  const toggle = () => setModal(!modal);

  const handleSuccess = () => {
    toggle();
    onSuccess?.();
  };

  const handleClose = () => {
    toggle();
    onDecline?.();
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            { intl.formatMessage({ id: 'bounty.modal' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            { intl.formatMessage({ id: 'bounty.modal.cancel' })}
          </Button>
          <Button onClick={handleSuccess} autoFocus>
            { intl.formatMessage({ id: 'bounty.modal.yes' })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ConfirmationDialog.propTypes = propTypes;

export default ConfirmationDialog;
