const NONE = 'NONE';
const COMMON = 'COMMON';
const BY_TYPE = 'BY_TYPE';
const BY_COMPANY = 'BY_COMPANY';
const BY_COMPANY_AND_TYPE = 'BY_COMPANY_AND_TYPE';
export const SEP = '_';

export const SearchBucketingPolicy = {
  [NONE]: NONE,
  [COMMON]: COMMON,
  [BY_TYPE]: BY_TYPE,
  [BY_COMPANY]: BY_COMPANY,
  [BY_COMPANY_AND_TYPE]: BY_COMPANY_AND_TYPE,
};

export function getBucketingPolicyAsEnum(policy) {
  if (!policy) {
    return NONE;
  }

  return SearchBucketingPolicy[policy] || NONE;
}
