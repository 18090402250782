import { ACTIONS } from 'Store/actions/candidatesActions';
import { formatSortQueryProp } from 'Common/utils/sort';
import { CANDIDATES_SORT_FIELDS, SORT_DIRECTION } from 'Constants/sort';

const lsLimitName = 'candidatesLimit';
const defaultLimit = Number(localStorage.getItem(lsLimitName)) || 10;

const initialState = {
  list: [],
  isLoading: false,
  reqProps: {
    start: 0,
    limit: defaultLimit,
    order: formatSortQueryProp(CANDIDATES_SORT_FIELDS.CREATED_AT, SORT_DIRECTION.DESC),
    query: '',
  },
  filters: {
    citizenship: [],
    country: [],
    gender: [],
    languages: [],
    withCertificates: false,
  },
  total: 0,
};

const candidatesReducer = (state = initialState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.GET_LIST:
      return {
        ...state,
        list: payload,
      };

    case ACTIONS.ERASE_LIST:
      return {
        ...state,
        list: [],
      };

    case ACTIONS.UPDATE_TOTAL:
      return {
        ...state,
        total: payload,
      };

    case ACTIONS.UPDATE_REQ_PROPS:
      if (payload.limit) {
        localStorage.setItem(lsLimitName, payload.limit);
      }
      return {
        ...state,
        reqProps: {
          ...state.reqProps,
          ...payload,
        },
      };

    case ACTIONS.UPDATE_FILTERS:
      return {
        ...state,
        reqProps: {
          ...state.reqProps,
          ...payload.reqProps,
        },
        filters: {
          ...state.filters,
          ...payload.filters,
        },
      };

    case ACTIONS.RESET_FILTERS:
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
      };

    case ACTIONS.LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default candidatesReducer;
