import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { object, func, string, bool } from 'prop-types';
import { TextField, Grid } from '@mui/material';
import { NotificationManager } from 'react-notifications';

//  Components
import CustomMUIDropdown from 'Common/components/CustomMUIDropdown';
import BadgesContainer from 'Modules/posts/add/containers/BadgesContainer';

//  Actions/Selectors
import { userDataSelector, settingsSelector } from 'Store/selectors/settings';
import { updateDataSyncAction } from 'Store/actions/genericActions';

//  Other resources
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA } from 'Store/reducerProperties';
import { getRewardTypesAsOptions, VOUCHER, BADGE } from 'Util/RewardType';
import * as modalSelectors from 'Modules/modal/store/selectors';
import IntlMessages from 'Util/IntlMessages';
import { types } from '../utils/activityType';
import { getRewardLabels } from '../utils/bountyStrings';

const propTypes = {
  isOptional: bool,
  range: bool,
  rewardName: string.isRequired,
  activityType: string.isRequired,
  bountyType: string.isRequired,
  userData: object.isRequired,
  settings: object.isRequired,
  inputsData: object.isRequired,
  updateDataSyncAction: func.isRequired,
};

const defaultProps = {
  isOptional: false,
  range: false,
};

const RewardComponent = (props) => {
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [isAmountDisabled, setIsAmountDisabled] = useState(false);
  const [isNotificationActive, setIsNotificationActive] = useState(false);
  const [placeholder, setPlaceholder] = useState('');
  const [openBadgesModal, setOpenBadgesModal] = useState(false);
  const {
    rewardName, inputsData, settings, userData, activityType, isOptional, bountyType, range,
  } = props;
  const {
    currency = '', amount, maxAmount = '', badges,
  } = inputsData[rewardName] || {};
  const labels = getRewardLabels(rewardName);

  useEffect(() => {
    prepareRewardTypes();
  }, []);

  useEffect(() => {
    preparePlaceholder(currency);
  }, [currency]);

  const toggleBadgesModal = () => setOpenBadgesModal(!openBadgesModal);

  const handleInputChanges = ({ target: { name, value } }) => {
    if (Number.isNaN(+value)) {
      if (!isNotificationActive) {
        NotificationManager.error(<IntlMessages id="bounty.amountInvalid" />);
        setIsNotificationActive(true);
        setTimeout(() => setIsNotificationActive(false), 5000);
      }

      return null;
    }

    handleUpdates(name, value);
  };

  const handleRewardTypeChanges = (name, value) => {
    handleUpdates(name, value);

    if (value === BADGE) {
      toggleBadgesModal();
    }
  };

  const handleUpdates = (name, value) => {
    const payload = {
      ...(inputsData[rewardName] ? inputsData[rewardName] : {}),
      [name]: value,
    };

    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [rewardName]: payload });
  };

  const prepareRewardTypes = () => {
    const options = getRewardTypesAsOptions({ userData, settings, bountyType });
    setCurrencyOptions(options);
  };

  const preparePlaceholder = (selectedType) => {
    const activityProps = types[activityType] || {};

    if ([VOUCHER, BADGE].includes(selectedType)) {
      return setIsAmountDisabled(true);
    }

    setIsAmountDisabled(false);
    setPlaceholder(isOptional ? 'Optional...' : activityProps[selectedType]);
  };

  const getAmountValue = () => {
    if (currency === BADGE && badges) {
      const badge = Object.values(badges)[0];
      return (badge && badge.info && badge.info.label) || '';
    }

    return amount || '';
  };

  const onAmountClick = () => {
    if (currency === BADGE) {
      toggleBadgesModal();
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField
            fullWidth
            label={labels.amount}
            onClick={onAmountClick}
            onChange={handleInputChanges}
            value={getAmountValue()}
            name="amount"
            placeholder={placeholder}
            disabled={isAmountDisabled}
          />
        </Grid>
        {range && (
          <Grid item xs>
            <TextField
              fullWidth
              label={labels.maxAmount}
              onClick={onAmountClick}
              onChange={handleInputChanges}
              value={maxAmount}
              name="maxAmount"
              placeholder={placeholder}
              disabled={isAmountDisabled}
            />
          </Grid>
        )}
        <Grid item xs>
          <CustomMUIDropdown
            fullWidth
            label={<IntlMessages id="private.currencyName" />}
            name="currency"
            value={currency || ''}
            onChange={handleRewardTypeChanges}
            color="primary"
            options={currencyOptions}
          />
        </Grid>
      </Grid>
      <BadgesContainer
        open={openBadgesModal}
        handleClose={toggleBadgesModal}
        bountyType={bountyType}
        rewardName={rewardName}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  inputsData: modalSelectors.inputsData(state) || {},
  userData: userDataSelector(state).data || {},
  settings: settingsSelector(state) || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

RewardComponent.propTypes = propTypes;
RewardComponent.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(RewardComponent);
