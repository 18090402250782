import * as React from 'react';
import classnames from 'classnames';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { allowedOrganizationTypesSelector, routePermissionsSelector } from 'Store/selectors/permissions';
import { sidebarNavLinks } from 'Constants/sidebarItems';
import { getApplicationConfig, APP_LOGO_SRC } from 'Util/AppUtils';
import IntlMessages from 'Util/IntlMessages';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useEffect } from 'react';
import NavMenuItem from './NavMenuItem/NavMenuItem';
import packageJson from '../../../../package.json';
import * as styles from './index.module.scss';

const { id, PRIVACY_STATEMENT_URL, TERMS_OF_SERVICE_URL } = getApplicationConfig();

const lsMenuName = 'isCompactMenu';

const SideBar = () => {
  const [open, setOpen] = React.useState(localStorage.getItem(lsMenuName) !== 'true');
  const routePermissions = useSelector(routePermissionsSelector) || {};
  const orgTypes = useSelector(allowedOrganizationTypesSelector) || {};
  const navLinks = sidebarNavLinks({ routePermissions, id, orgTypes });

  const toggleSideBar = () => setOpen(!open);

  useEffect(() => {
    if (open) {
      localStorage.removeItem(lsMenuName);
    } else {
      localStorage.setItem(lsMenuName, 'true');
    }
  }, [open]);

  return (
    <div className={`${styles.sidebar} ${open ? `${styles.open} open` : ''}`}>
      <div>
        <div className={classnames(styles.sidebarHeader, styles[`${id}Header`])}>
          {open && (
            <div className={styles.siteLogo}>
              <Link to="/">
                <img src={APP_LOGO_SRC} alt="site logo" className={classnames('image-logo', styles.imageLogo)} />
              </Link>
            </div>
          )}
          <IconButton
            className={styles.burgerIcon}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleSideBar}
            size="medium"
          >
            <i className="icon-menu-side" />
          </IconButton>
        </div>
        <div className={styles.menuWrapper}>
          {navLinks && navLinks.category2.map((menu, key) => (
            <NavMenuItem
              menu={menu}
              key={key}
            />
          ))}
        </div>
        <Accordion className={styles.accordion}>
          <AccordionSummary>
            <NavMenuItem
              menu={{ path: null, menu_icon: 'icon-more', menu_title: 'sidebar.more' }}
            />
          </AccordionSummary>
          <AccordionDetails>
            <div className={styles.menuWrapper}>
              {navLinks && navLinks.category3.map((menu, key) => (
                <NavMenuItem
                  menu={menu}
                  key={key}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        {TERMS_OF_SERVICE_URL && (
          <NavMenuItem
            menu={{ path: TERMS_OF_SERVICE_URL, menu_icon: 'icon-book', menu_title: 'widgets.terms_of_service' }}
            external
          />
        )}
        {PRIVACY_STATEMENT_URL && (
          <NavMenuItem
            menu={{ path: PRIVACY_STATEMENT_URL, menu_icon: 'icon-shield-done', menu_title: 'widgets.privacy' }}
            external
          />
        )}
      </div>
      {open && packageJson.version && (
        <div className={styles.buildVersion}>
          <IntlMessages id="build_version" values={{ value: packageJson.version }} />
        </div>
      )}
    </div>
  );
};

export default SideBar;
