import { NotificationManager } from 'react-notifications';
import { cloneDeep } from 'lodash';

//  Actions
import {
  updateDataAsyncAction,
  updateDataSyncAction,
  isLoadingAction,
  updateSimpleDataSyncAction,
} from 'Store/actions/genericActions';

//  Helpers
import { convertObjToArray, streamSnapshotToArray } from 'Util/helpers';
import {
  firebaseGetUserStreamsRef,
  firebaseGetUserCommentsRef,
  firebaseGetUserResponsesRef,
  firebaseGetSentRef,
  getMySubCardsRef,
  firebaseGetSentSubsRef,
  firebaseGetCompanySentSubsRef,
  getCompanySubCardsRef,
  firebaseGetCompanySents,
  firebaseGetCurrentUser,
} from 'Services/FirebaseService';

//  Others
import * as reducerProperties from 'Store/reducerProperties';
import { MODAL_NAMESPACE, SENTS_NAMESPACE, USER_STREAMS_NAMESPACE } from 'Store/namespaces';
import { KEY_SEP, FB_MATCH_ALL } from 'Constants/common';
import { REQUESTS } from 'Constants/apiRoutes';
import { addToQueue, getApiData } from 'Services/Api';
import { queues } from 'Constants/queues';
import * as bountyActions from 'Modules/posts/common/store/actions';
import { ListDef } from 'Models/Lists';
import {
  getBountyKeyInfo,
  getOutboundPriority,
  getPriority,
  getSortKeysObject,
  stitchParts,
  getParentOrderSortKey,
} from 'Util/keyUtils';
import { ACTIVE_STATE, CLOSED_STATE, RETRACTED_STATE } from 'Constants/bounty/bountyState';
import { IN_APP, REBOUNTY } from 'Constants/bounty/bounty';
import { OFFICIAL, REQUEST_OFFICIAL_RESPONSE } from 'Modules/posts/bounty/constants/badgeType';
import IntlMessages from 'Util/IntlMessages';
import {
  isAllowedToClose,
  isAllowedToDelete,
  isAllowedToReject,
  isAllowedToRetract,
  isAllowedToSimulate,
  isDraftState,
  isAllowedToSend,
} from 'Models/bounty/BountyState';
import { getAttachmentsToDelete } from 'Util/AttachmentsUtils';
import { getBountyUpdates } from 'Modules/posts/add/utils/addBountyHelpers';
import { buildAgentString } from 'Util/AppUtils';
import { getInboundBountyRef, adjustOriginRebounties, getOwnerId } from 'Services/bounty/CommonService';
import { SUBBOUNTIES } from 'Constants/bounty/BountyStatsType';
import { DISMISSED_KEY_PREFIX } from 'Models/Card';
import {
  setListDef,
  isRebounty,
  hasBadge,
  isNetworkLinkRequired,
  isSubBounty,
  getCompanyFolder,
  isOutboundPost,
  setOutbound,
} from 'Models/bounty/Bounty';
import { getCompletedSurveyProps } from 'Models/survey/Survey';
import { getAction } from 'Util/api/queues';
import { getSortByList, getSortByStream, getSortByType } from 'Models/bounty/Filters';
import { bountyType as availableTypes } from 'Models/CardType';
import { updateIdentity } from 'Services/BaseService';
import * as bountyTypes from 'Constants/bounty/bountyType';
import { getMainImageForProduct } from 'Util/productUtils';
import { attachmentsSelector, removedAttachmentsSelector } from 'Modules/attachments/store/selectors';
import { selectedProductsSelector, selectedPromotionsSelector } from 'Modules/modal/store/selectors';
import { bountyDetails, userStreams } from 'Modules/posts/common/store/selectors';
import { getLoggedUser } from 'Modules/authentication/store/selectors';
import {
  uploadBountyAttachments,
  onProductAttachmentUpdate,
  onPromotionAttachmentUpdate,
} from '../UploadService';

export const isSharedWithCompany = (bounty) => {
  const companyFolder = getCompanyFolder(bounty.type);

  if (!companyFolder) {
    return false;
  }

  switch (bounty.type) {
    case bountyTypes.ORDER:
      return !isMyBounty(bounty);
    default:
      return true;
  }
};

export const setDefaultFilter = ({ defaultFilter }) => (dispatch) => {
  dispatch(updateDataSyncAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DEFAULT_FILTER, defaultFilter));
};

export const setDefaultSort = ({ defaultSort }) => (dispatch) => {
  dispatch(updateDataSyncAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DEFAULT_SORT, defaultSort));
};

export const getBountiesStreamSortedBy = ({ userId, sort }) => (dispatch) => {
  dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, true));

  const sortKey = getSortByStream(sort.sortListValue);
  const path = `bounty/${sortKey}`;

  return firebaseGetUserStreamsRef(userId)
    .orderByChild(path)
    .limitToFirst(100)
    .on('value', (dataSnapshot) => {
      const dataSnapshotVal = streamSnapshotToArray(dataSnapshot);
      dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, dataSnapshotVal));
      dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, false));
    });
};

export const getBountiesStreamByListIdSortedBy = ({ userId, listId, sort }) => (dispatch) => {
  dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, true));

  const keyStart = `${listId}${KEY_SEP}`;
  const keyEnd = `${keyStart}${FB_MATCH_ALL}`;
  const sortKey = getSortByList(sort.sortListValue);
  const path = `bounty/${sortKey}`;

  return firebaseGetUserStreamsRef(userId)
    .orderByChild(path)
    .startAt(keyStart)
    .endAt(keyEnd)
    .limitToFirst(50)
    .on('value', (dataSnapshot) => {
      const dataSnapshotVal = streamSnapshotToArray(dataSnapshot);
      dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, dataSnapshotVal));
      dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, false));
    });
};

export const getBountiesStreamByTypeSortedBy = ({ userId, list, sort }) => (dispatch) => {
  dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, true));

  const bountyType = ListDef.getFilterBountyTypeAsEnum(list);
  const code = ListDef.getEffectiveCode(list);
  const keyStart = stitchParts({ bountyType, code });
  const keyEnd = `${keyStart}${FB_MATCH_ALL}`;
  const sortKey = getSortByType(sort.sortTypeValue);
  const path = `bounty/${sortKey}`;

  return firebaseGetUserStreamsRef(userId)
    .orderByChild(path)
    .startAt(keyStart)
    .endAt(keyEnd)
    .limitToFirst(100)
    .on('value', (dataSnapshot) => {
      const dataSnapshotVal = streamSnapshotToArray(dataSnapshot);
      dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, dataSnapshotVal));
      dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, false));
    });
};

export const getUserSents = (userId) => (dispatch) => {
  dispatch(isLoadingAction(SENTS_NAMESPACE, reducerProperties.USER_SENTS, true));

  return firebaseGetSentRef(userId)
    .limitToFirst(150)
    .on('value', (dataSnapshot) => {
      const userSents = convertObjToArray(dataSnapshot.val());
      const validTypes = [availableTypes.bounty, availableTypes.response];
      const bounties = [];

      userSents.forEach((card) => {
        const cardType = Object.keys(card)[0];

        if (validTypes.includes(cardType)) {
          card[cardType].metaInfo = setOutbound(card[cardType].metaInfo, true); // eslint-disable-line
          card[cardType].outbound = isOutboundPost(card[cardType].metaInfo); // eslint-disable-line

          if (card[cardType].id) {
            bounties.push(card);
          }
        }
      });

      dispatch(updateDataAsyncAction(SENTS_NAMESPACE, reducerProperties.USER_SENTS, bounties));
      dispatch(isLoadingAction(SENTS_NAMESPACE, reducerProperties.USER_SENTS, false));
    });
};

export const getOutboundRef = (bounty, user) => {
  const ownerId = bounty.parentBounty ? getOwnerId(bounty.parentBounty) : getOwnerId(bounty);

  if (isSubBounty(bounty)) {
    return isSharedWithCompany(bounty)
      ? firebaseGetCompanySentSubsRef(ownerId, getCompanyFolder(bounty.type)).child(bounty.parentBounty.id)
      : firebaseGetSentSubsRef(user.uid).child(bounty.parentBounty.id);
  }

  return isSharedWithCompany(bounty)
    ? firebaseGetCompanySents(ownerId, getCompanyFolder(bounty.type))
    : firebaseGetSentRef(user.uid);
};

export const getInboundRef = (bounty, user) => (
  isSubBounty(bounty) ? getMySubCardsRef(user.uid).child(bounty.parentBounty.id) : firebaseGetUserStreamsRef(user.uid)
);

/**
 * Get Bounty responses by id
 */
export const getBountyResponseById = (ownerId, bountyId, responseId) => (dispatch) => {
  dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.ANSWER_DETAILS, true));

  return new Promise((resolve) => (
    firebaseGetUserResponsesRef(ownerId)
      .child(bountyId)
      .child(responseId)
      .on('value', (dataSnapshot) => {
        const result = dataSnapshot.val();
        dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.ANSWER_DETAILS, result));
        dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.ANSWER_DETAILS, false));
        resolve(result);
      })));
};

export const getOutboundResponseById = (userId, responseId) => (dispatch) => {
  dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.ANSWER_DETAILS, true));

  return (
    new Promise((resolve) => (
      firebaseGetSentRef(userId)
        .child(responseId)
        .on('value', (dataSnapshot) => {
          const result = dataSnapshot.val();
          dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.ANSWER_DETAILS, result));
          dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.ANSWER_DETAILS, false));
          resolve(result);
        })))
  );
};

/**
 * Get Answers comments by response id
 */
export const getResponseCommentsById = (responseId) => (dispatch) => (
  firebaseGetUserCommentsRef()
    .child('responses')
    .child(responseId)
    .on('value', (dataSnapshot) => {
      const dataSnapshotVal = convertObjToArray(dataSnapshot.val());
      dispatch(bountyActions.answerCommentsById(dataSnapshotVal));
    })
);

export const updatePriority = (bounty, user) => {
  const ref = getOutboundRef(bounty, user).child(bounty.id);
  const priority = getPriority(bounty);

  ref.setPriority(priority);
};

export const updateBountyTimestamp = (bounty, user, timestamp) => {
  const bountyClone = { ...bounty };
  bountyClone.updatedAt = timestamp;

  updatePriority(bountyClone, user);
};

export const getOutboundBountyRefForStats = (bounty) => {
  const mySelf = firebaseGetCurrentUser();
  const ownerId = getOwnerId(bounty);

  return isSharedWithCompany(bounty)
    ? firebaseGetCompanySents(ownerId, getCompanyFolder(bounty.type)).child(bounty.id).child('bounty')
    : firebaseGetSentRef(mySelf.uid).child(bounty.id).child('bounty');
};

export const getOutboundBountyRef = (bounty) => {
  const mySelf = firebaseGetCurrentUser();

  if (isSharedWithCompany(bounty) || !bounty.creator || bounty.creator.id === mySelf.uid) {
    return getOutboundRef(bounty, mySelf).child(bounty.id).child('bounty');
  }

  return null;
};

export const getBountyRef = (bounty, user) => (
  bounty.outbound || bounty.isOutbound
    ? getOutboundBountyRef(bounty)
    : getInboundBountyRef(bounty, user)
);

export const updateSortKeys = (bounty, user) => {
  const bountyKeyInfo = getBountyKeyInfo(bounty);
  const sortKeys = getSortKeysObject(bountyKeyInfo);

  const newBounty = {
    ...bountyKeyInfo,
    id: bounty.id,
    type: bounty.type,
    parentBounty: bounty.parentBounty || null,
    owner: bounty.owner || null,
  };

  const ref = getBountyRef(newBounty, user);

  if (ref) {
    return ref.update(sortKeys);
  }

  return null;
};

const activateBounty = (bounty, user) => {
  const cloneBounty = { ...bounty };
  const activeState = { state: ACTIVE_STATE };
  cloneBounty.createdAt = new Date().getTime();
  cloneBounty.state = ACTIVE_STATE;

  adjustOriginRebounties({ bounty, isAdd: true });

  getBountyRef(cloneBounty, user)
    .child('createdAt')
    .set(cloneBounty.createdAt);
  getOutboundBountyRef(bounty).update(activeState);
  updatePriority(cloneBounty, user);
  updateSortKeys(cloneBounty, user);

  addToQueue(queues.ACTIVATE_BOUNTY, { bountyId: cloneBounty.id, bountyType: cloneBounty.type });
};

const savePosting = ({ bounty, network, postingId }) => {
  // TODO: check if the first part is necessary
  const posting = {
    postingId,
    postedAt: new Date().getTime(),
  };

  getOutboundBountyRef(bounty)
    .child('posting')
    .child(network)
    .set(posting);
};

const shareToSocialNetwork = ({ bounty, network, link }) => { // eslint-disable-line
  if (network === IN_APP) {
    savePosting({ bounty, network: REBOUNTY, postingId: null });
    addToQueue(queues.POST_BOUNTY, { bountyId: bounty.id });
  }

  // TODO: implement the logic for the rest of possible networks
  return null;
};

const postBounty = ({ network, bountyData }) => {
  const { link, bounty } = bountyData;
  // TODO: check/implement BountyProducer && bountyData.link
  if (isNetworkLinkRequired(network) && !link) {
    // TODO: implement postBounty(@NonNull BountyProducer producer from
    // TODO: -> check if is necessary to implement this part now
    // @NonNull List<PostBountyOption> networks) from DefaultBountyActionListener
    return null;
  }

  return shareToSocialNetwork({ bounty, network, link });
};

const onPostBounty = ({ bounty, hideRebounty }) => {
  if (!hideRebounty) {
    return postBounty({ network: IN_APP, bountyData: { bounty } });
  }

  return NotificationManager.warning('No network to post to');
};

export const requestBadge = (bounty, badgeType) => {
  addToQueue(queues.REQUEST_BADGE, { bountyId: bounty.id, badgeType });
};

const removeBadge = (bounty, badgeType) => {
  addToQueue(queues.REMOVE_BADGE, { bountyId: bounty.id, badgeType });
};

export const onRemoveROR = (bounty) => () => {
  if (hasBadge(bounty, OFFICIAL)) {
    return NotificationManager.warning(<IntlMessages id="bounty.alreadyHasOfficialResponse" />);
  }

  removeBadge(bounty, REQUEST_OFFICIAL_RESPONSE);
  return NotificationManager.info(<IntlMessages id="bounty.requestOfficialResponseCancelled" />);
};

export const onROR = (bounty) => () => {
  if (hasBadge(bounty, OFFICIAL)) {
    return NotificationManager.warning(<IntlMessages id="bounty.alreadyHasOfficialResponse" />);
  }

  requestBadge(bounty, REQUEST_OFFICIAL_RESPONSE);
  return NotificationManager.info(<IntlMessages id="bounty.requestOfficialResponseConfirmation" />);
};

function getPostState(state) {
  return {
    attachments: attachmentsSelector(state) || [],
    removedAttachments: removedAttachmentsSelector(state) || [],
    promotions: selectedPromotionsSelector(state) || [],
    products: selectedProductsSelector(state) || [],
    user: getLoggedUser(state)?.data || {},
  };
}

/**
 * Create a bounty post with state DRAFT visible only on user sents
 * @param bounty {Object}
 * @param stateAttachments {Array} bounty attachments
 * @returns {function(*): Promise<any>}
 */
export const createBounty = (bounty, stateAttachments) => (async (dispatch, getState) => {
  const state = getState();
  const {
    attachments: storeAttachments, // TODO: remove this after all bounty type will use the state
    promotions,
    products,
    user,
  } = getPostState(state);
  const attachments = storeAttachments.length ? storeAttachments : stateAttachments;

  dispatch(updateSimpleDataSyncAction(MODAL_NAMESPACE, reducerProperties.MODAL_IS_LOADING, true));

  const bountyRef = getOutboundRef(bounty, user).push();
  const identity = await updateIdentity(bounty);

  const cloneBounty = {
    ...bounty,
    ...identity,
  };
  const now = new Date().getTime();

  cloneBounty.id = bountyRef.key;
  cloneBounty.createdAt = now;
  cloneBounty.updatedAt = now;
  cloneBounty.outbound = true;

  cloneBounty.agentInfo = { createdOn: buildAgentString() };

  if (attachments && attachments.length) {
    cloneBounty.attachments = await uploadBountyAttachments(cloneBounty, attachments, user);

    if (cloneBounty.type === bountyTypes.PRODUCT) {
      cloneBounty.product.imageUrl = getMainImageForProduct(cloneBounty.attachments);
    }
  }

  if (promotions?.length) {
    cloneBounty.attachments = await onPromotionAttachmentUpdate(cloneBounty, promotions, user);
  }

  if (products?.length) {
    cloneBounty.attachments = await onProductAttachmentUpdate(cloneBounty, products, user);
  }

  bountyRef.setWithPriority({ bounty: cloneBounty }, getOutboundPriority(true, now));
  updateBountyTimestamp(cloneBounty, user, now);
  updateSortKeys(cloneBounty, user);

  if (isSubBounty(cloneBounty)) {
    const parentSortKey = getParentOrderSortKey(cloneBounty.parentBounty.id, -1); // -1 to be first in list on creation

    adjustBountyOutboundStat(cloneBounty.parentBounty, SUBBOUNTIES, 1);
    bountyRef.child('bounty').update({ orderInParentSortKey: parentSortKey });
  }

  dispatch(updateSimpleDataSyncAction(MODAL_NAMESPACE, reducerProperties.MODAL_IS_LOADING, false));

  return Promise.resolve(cloneBounty);
});

/**
 * The method use to publish a bounty post; this will set the state to ACTIVE
 * @param bounty {Object}
 * @param user {Object}
 * @returns {Function}
 */
export const onActivateBounty = (bounty, user) => () => {
  activateBounty(bounty, user);

  if (bounty.interactions && bounty.interactions.officialResponse) {
    if (hasBadge(bounty, OFFICIAL)) {
      NotificationManager.warning(<IntlMessages id="bounty.alreadyHasOfficialResponse" />);
    } else {
      requestBadge(bounty, REQUEST_OFFICIAL_RESPONSE);
      NotificationManager.info(<IntlMessages id="bounty.requestOfficialResponseConfirmation" />);
    }
  }
  // TODO: need implementation -> check if is necessary to implement this part now
  // isSharePostMenuEnabled = false all the time?
  // if (isSharePostMenuEnabled) {
  //   return null;
  // }

  onPostBounty({ bounty, hideRebounty: isRebounty(bounty) });
};

/**
 *  Method used to edit a bounty
 * @param bounty {Object}
 * @param stateAttachments {Object}
 */
export const onEditBounty = ({ newBounty, oldBounty, stateAttachments }) => (
  async (dispatch, getState) => {
    try {
      const state = getState();
      const {
        attachments: storeAttachments, // TODO: remove this after all bounty type will use the state
        promotions,
        products,
        user,
      } = getPostState(state);
      const attachments = storeAttachments.length ? storeAttachments : stateAttachments;

      dispatch(updateSimpleDataSyncAction(MODAL_NAMESPACE, reducerProperties.MODAL_IS_LOADING, true));

      const editedAt = new Date().getTime();
      const bountyClone = cloneDeep(newBounty);
      const allowedAttachments = attachments?.filter((attach) => !attach.onlyAsUrl);

      if (allowedAttachments && allowedAttachments.length) {
        bountyClone.attachments = await uploadBountyAttachments(oldBounty, allowedAttachments, user);

        if (bountyClone.type === bountyTypes.PRODUCT) {
          bountyClone.product.imageUrl = getMainImageForProduct(bountyClone.attachments, oldBounty);
        }
      }

      const attachmentsToRemove = getAttachmentsToDelete(oldBounty?.attachments, bountyClone?.attachments);
      attachmentsToRemove?.forEach((id) => removeAttachment(bountyClone, { id }));

      if (!attachments?.length) {
        bountyClone.attachments = null;
      }

      if (promotions?.length) {
        bountyClone.attachments = await onPromotionAttachmentUpdate(bountyClone, promotions);
      }

      if (products?.length) {
        bountyClone.attachments = await onProductAttachmentUpdate(bountyClone, products, user);
      }

      const { updates, changes } = getBountyUpdates(oldBounty, bountyClone);

      if (!Object.keys(updates).length) {
        NotificationManager.info(`Nothing really changed in bounty ${bountyClone.id}`);
        return Promise.resolve();
      }

      if (!isDraftState(bountyClone.state)) {
        bountyClone.editedAt = editedAt;
        updates.editedAt = editedAt;
        changes.editedAt = editedAt;
      }

      getOutboundBountyRef(bountyClone)
        .update(updates);

      updateBountyTimestamp(bountyClone, user, editedAt);
      addToQueue(queues.UPDATE_BOUNTY, { bountyId: bountyClone.id, changes });

      dispatch(updateSimpleDataSyncAction(MODAL_NAMESPACE, reducerProperties.MODAL_IS_LOADING, false));

      return Promise.resolve(bountyClone);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

/**
 * Request access to bounty creation
 * @param cap {String}
 */
export const requestAccess = (cap) => () => {
  addToQueue(queues.REQUEST_ACCESS, { cap });
};

export const updateBountyState = (bounty, state) => {
  const me = firebaseGetCurrentUser();
  const isMine = bounty.creator && bounty.creator.id === me.uid;
  if (!isMine) {
    return NotificationManager.warning('You are not allowed to close this post');
  }

  const result = { state };

  // Update my bounty table
  getOutboundBountyRef(bounty).update(result);
};

function getOutboundBountyStatsRef(bounty, statsType) {
  return getOutboundBountyRefForStats(bounty).child('stats').child(statsType.fieldName);
}

export function adjustBountyOutboundStat(bounty, statsType, diff) {
  const statsRef = getOutboundBountyStatsRef(bounty, statsType);

  statsRef.once('value', (dataSnapshot) => {
    const prevStat = dataSnapshot.val();
    const count = prevStat ? +prevStat : 0;

    statsRef.off();
    getOutboundBountyStatsRef(bounty, statsType).set(Math.max(count + diff, 0));
  });
}

export const retractBounty = (bounty, user) => () => {
  // TODO: remove after new interface for jobs will be implemented
  const bountyClone = cloneDeep(bounty);
  const isAllowedToPerformOp = isAllowedToRetract(bountyClone.state);

  if (!isAllowedToPerformOp) {
    return null;
  }

  adjustOriginRebounties({ bounty: bountyClone, isAdd: false });

  bountyClone.state = RETRACTED_STATE;
  getOutboundBountyRef(bounty).child('state').set(RETRACTED_STATE);
  getInboundBountyRef(bounty, user).remove();
  addToQueue(queues.RETRACT_BOUNTY, { bountyId: bountyClone.id });
};

export const onDeleteBounty = (bounty) => {
  // TODO: remove after new interface for jobs will be implemented
  const me = firebaseGetCurrentUser();
  const bountyClone = { ...bounty };
  const isMine = bountyClone.creator && bountyClone.creator.id === me.uid;
  const isAllowedToPerformOp = isAllowedToDelete(bountyClone.state);

  if (!isAllowedToPerformOp || (!isSharedWithCompany(bounty) && !isMine)) {
    NotificationManager.warning('You are not allowed to delete this post');
    return Promise.reject(new Error('You are not allowed to delete this post'));
  }

  getOutboundBountyRef(bountyClone).remove();
  addToQueue(queues.DELETE_BOUNTY, { bountyId: bountyClone.id });

  if (bountyClone.parentBounty && bountyClone.parentBounty.id) {
    adjustBountyOutboundStat(bountyClone.parentBounty, SUBBOUNTIES, -1);
  }

  return Promise.resolve();
};

export const reportBounty = ({ bounty, reason }) => () => {
  // TODO: remove after new interface for jobs will be implemented
  const me = firebaseGetCurrentUser();
  const isMine = bounty.creator && bounty.creator.id === me.uid;

  if (isMine) {
    return null;
  }
  addToQueue(queues.REPORT_BOUNTY, { bountyId: bounty.id, reason });
};

export const rejectBounty = ({
  bounty, user, reason, callBack,
}) => {
  // TODO: remove after new interface for jobs will be implemented
  const isAllowedToPerformOp = isAllowedToReject(bounty.state);

  if (!isAllowedToPerformOp) {
    return NotificationManager.warning(`Cannot reject when ${bounty.state}`);
  }

  getInboundBountyRef(bounty, user)
    .remove()
    .then(() => addToQueue(queues.REJECT_BOUNTY, { bountyId: bounty.id, reason }))
    .then(() => {
      NotificationManager.info('Post has been rejected');

      if (callBack) {
        setTimeout(callBack, 1000);
      }
    })
    .catch(() => NotificationManager.error('Post can not be rejected'));
};

export const sendBountyToUser = ({ bounty, userId }) => {
  const isAllowedToPerformOp = isAllowedToSend(bounty.state);

  if (!isAllowedToPerformOp) {
    return NotificationManager.warning(`Cannot send when ${bounty.state}`);
  }

  addToQueue(queues.SEND_BOUNTY, { bountyId: bounty.id, userId });
};

export const dismissBounty = ({ bounty, reason }) => () => {
  const me = firebaseGetCurrentUser();
  const isMine = bounty.creator && bounty.creator.id === me.uid;

  if (isMine) {
    return NotificationManager.warning('Cannot dismiss your own post');
  }

  const data = {
    dismissedAt: new Date().getTime(),
    sortKey: `${DISMISSED_KEY_PREFIX}${bounty.id}`,
  };

  firebaseGetUserStreamsRef(me.uid).child(bounty.id).update(data);
  addToQueue(queues.DISMISS_BOUNTY, { bountyId: bounty.id, reason });
};

export const closeBounty = ({ bounty }) => () => {
  // TODO: remove after new interface for jobs will be implemented
  const isAllowedToPerformOp = isAllowedToClose(bounty.state);

  if (!isAllowedToPerformOp) {
    return NotificationManager.warning(`Cannot close when ${bounty.state}`);
  }

  updateBountyState(bounty, CLOSED_STATE);
  addToQueue(queues.CLOSE_BOUNTY, { bountyId: bounty.id });
  return Promise.resolve();
};

export const moveBountyToList = ({ bounty, list, user }) => () => {
  const bountyClone = cloneDeep(bounty);
  const { listId, listCode } = setListDef(bounty, list);

  bountyClone.listId = listId;
  bountyClone.listCode = listCode;

  updateSortKeys(bountyClone, user);
  addToQueue(queues.MOVE_BOUNTY_TO_LIST, { bountyId: bountyClone.id, listId, listCode });
  return Promise.resolve();
};

export const simulateSurvey = (bounty) => () => {
  const me = firebaseGetCurrentUser();
  const isMine = bounty.creator && bounty.creator.id === me.uid;
  const isAllowedToPerformOp = isAllowedToSimulate(bounty.state);

  if (!isMine || !isAllowedToPerformOp) {
    return NotificationManager.warning('Cannot simulate this post');
  }

  addToQueue(queues.SIMULATE_BOUNTY, { bountyId: bounty.id });
};

export const setSurveyChoice = ({ bounty, option, ownerId, populateSummaryEagerly }) => { // eslint-disable-line
  const mySelf = firebaseGetCurrentUser();
  const work = getCompletedSurveyProps();

  getBountyRef(bounty, mySelf).child('work').update(work);

  if (populateSummaryEagerly) {
    //  TODO: is not used yet, we can implement it later
  }
};

export const listDistributionTargets = ({ bountyId = null, bountyType = null }) => {
  const action = getAction(queues.LIST_BOUNTY_DISTRIBUTION_TARGETS);

  return new Promise((resolve, reject) => {
    getApiData(`${REQUESTS}/${action}?bountyId=${bountyId}&bountyType=${bountyType}`)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export function isMyBounty(bounty) {
  const me = firebaseGetCurrentUser();
  return bounty.creator && bounty.creator.id === me.uid;
}

export function removeAttachment(bounty, attachment) {
  // NB: do not make changes to the DB until we save
  // getOutboundBountyRef(bounty).child("attachments").child("attachments").child(attachment.getId()).removeValue();
  addToQueue(queues.DELETE_ATTACHMENT, { bountyId: bounty.id, attachmentId: attachment.id });
}

export const getOutboundSubbounties = (bounty) => {
  const user = firebaseGetCurrentUser();
  const ownerId = getOwnerId(bounty);
  const ref = isSharedWithCompany(bounty)
    ? firebaseGetCompanySentSubsRef(ownerId, getCompanyFolder(bounty.type))
    : firebaseGetSentSubsRef(user.uid);

  return ref.child(bounty.id);
};

export const getInboundSubbounties = (bounty) => {
  const user = firebaseGetCurrentUser();
  const ownerId = getOwnerId(bounty);
  const ref = isSharedWithCompany(bounty)
    ? getCompanySubCardsRef(ownerId, getCompanyFolder(bounty.type))
    : getMySubCardsRef(user.uid);

  return ref.child(bounty.id);
};

const getBountySubbountiesRef = (bounty) => {
  const ref = bounty.outbound ? getOutboundSubbounties(bounty) : getInboundSubbounties(bounty);

  return ref.orderByChild('bounty/orderInParentSortKey');
};

export const getSubBounties = (bounty) => (dispatch) => {
  dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.SUB_BOUNTIES, true));

  getBountySubbountiesRef(bounty)
    .on('value', (dataSnapshot) => {
      const dataSnapshotVal = streamSnapshotToArray(dataSnapshot);
      dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.SUB_BOUNTIES, dataSnapshotVal));
      dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.SUB_BOUNTIES, false));
    });
};

export const updateOrderInParent = (parent = {}, list = []) => (
  new Promise((resolve, reject) => {
    if (parent.id) {
      for (let i = 0; i < list.length; i++) {
        const ref = getOutboundSubbounties(parent);
        const parentSortKey = getParentOrderSortKey(parent.id, i);

        ref.child(list[i].bounty.id)
          .child('bounty')
          .update({ orderInParentSortKey: parentSortKey })
          .catch(() => reject());

        addToQueue(queues.UPDATE_BOUNTY, { bountyId: parent.id, orderInParentSortKey: parentSortKey });
      }
      resolve();
    }

    reject();
  })
);

const getOutboundBountyById = (userId, bounty) => {
  const ref = getOutboundRef(bounty, { uid: userId });

  return new Promise((resolve) => (
    ref
      .child(bounty.id)
      .child('bounty')
      .on('value', (dataSnapshot) => {
        // eslint-disable-next-line no-shadow
        const bountyDetails = dataSnapshot.val();

        if (bountyDetails) {
          bountyDetails.metaInfo = setOutbound(bountyDetails.metaInfo, true);
          bountyDetails.outbound = isOutboundPost(bountyDetails.metaInfo);
        }

        resolve(bountyDetails);
      })
  ));
};

export const fetchBounty = (bountyId) => {
  const action = getAction(queues.FETCH_BOUNTY);
  return getApiData(`${REQUESTS}/${action}?bountyId=${bountyId}`);
};

const getIncomingBounty = (userId, bounty) => {
  const ref = getInboundRef(bounty, { uid: userId });

  return new Promise((resolve, reject) => {
    ref
      .child(bounty.id)
      .child('bounty')
      .on('value', (dataSnapshot) => {
        // eslint-disable-next-line no-shadow
        const bountyDetails = dataSnapshot.val();

        if (bountyDetails && bountyDetails.id) {
          return resolve(bountyDetails);
        }

        fetchBounty(bounty.id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
  });
};

export const getBountyAny = (bounty) => (dispatch) => {
  const me = firebaseGetCurrentUser();
  dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, true));

  return new Promise((resolve, reject) => {
    getOutboundBountyById(me.uid, bounty)
      .then((result) => {
        if (result && result.id) {
          dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, result));
          dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, false));
          return resolve(result);
        }

        getIncomingBounty(me.uid, bounty)
          .then((result2) => {
            if (result2 && result2.id) {
              dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, result2));
              dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, false));
              resolve(result2);
            }
          });
      })
      .catch((error) => {
        dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, {}));
        dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, false));
        reject(error);
      });
  });
};

export const getIncomingBountyDetails = (bounty) => (dispatch) => {
  const me = firebaseGetCurrentUser();
  dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, true));

  return new Promise((resolve, reject) => {
    getIncomingBounty(me.uid, bounty)
      .then((result2) => {
        if (result2 && result2.id) {
          dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, result2));
          dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, false));
          resolve(result2);
        }
      })
      .catch((error) => {
        dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, {}));
        dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, false));
        reject(error);
      });
  });
};

export const updateLocalBounty = (bounty) => (dispatch, getState) => {
  const state = getState();
  const localBounty = bountyDetails(state).data || null;
  const localBountyList = userStreams(state).data || null;

  if (localBounty) {
    return dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, bounty));
  }

  if (localBountyList) {
    const foundIndex = localBountyList.findIndex((item) => item?.bounty?.id === bounty.id || item?.id === bounty.id);

    if (foundIndex !== -1) {
      localBountyList[foundIndex] = 'bounty' in localBountyList[foundIndex] ? { bounty } : bounty;
      dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, localBountyList));
    }
  }
};
