export const INBOX = 'INBOX';
export const REFS = 'REFS';
export const BUCKET = 'BUCKET';
export const FILTER_BY_BOUNTY_TYPE = 'FILTER_BY_BOUNTY_TYPE';
export const REST_CALL = 'REST_CALL';
export const SPECIAL = 'SPECIAL';
export const WEBVIEW = 'WEBVIEW';
export const MULTI = 'MULTI';
export const STATS = 'STATS';
export const UNKNOWN = 'UNKNOWN';
export const TARGET = 'TARGET';
