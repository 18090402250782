import {
  GET_ANSWERS_COMMENTS,
  GET_BOUNTY_ANSWERS_COMMENTS,
} from 'Store/actionTypes';

export const bountyAnswersComments = (bountyResponseId, payload) => ({
  type: GET_BOUNTY_ANSWERS_COMMENTS,
  payload: {
    bountyResponseId,
    bountyAnswersComments: payload,
  },
});

export const answerCommentsById = (payload) => ({
  type: GET_ANSWERS_COMMENTS,
  payload,
});
