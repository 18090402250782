import IntlMessage from 'Util/IntlMessages';

// promotions type
export const SELECT_ONE = {
  value: 'SELECT_ONE',
  label: <IntlMessage id="label.select.one" />,
};
export const DISCOUNT = {
  value: 'DISCOUNT',
  label: <IntlMessage id="label.discount" />,
};

export const promotionOptions = [SELECT_ONE, DISCOUNT];

export const COUPON_CODE = 'couponCode';
export const COUPON_DISCOUNT = 'discount';

export const deliveryFee = {
  label: <IntlMessage id="label.delivery.fee" />,
  placeholder: <IntlMessage id="placeholder.add.delivery.fee" />,
  name: 'deliveryFee',
  type: 'number',
};

export const priceObject = {
  label: <IntlMessage id="labels.price" />,
  placeholder: <IntlMessage id="placeholder.add.price" />,
  name: 'price',
  type: 'number',
};
