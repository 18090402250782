export const NONE = 'NONE';
export const TALENT_SEARCH = 'TALENT_SEARCH';
export const TALENT_RECOMMENDATION = 'TALENT_RECOMMENDATION';
export const TMOB_CHALLENGE = 'TMOB_CHALLENGE';
export const TMOB_COACHING = 'TMOB_COACHING';
export const PRODUCT = 'PRODUCT';
export const REALESTATE_CUSTOMER = 'REALESTATE_CUSTOMER';
export const REALESTATE_RECOMMENDATION = 'REALESTATE_RECOMMENDATION';
export const SURVEY = 'SURVEY';
export const CLASSIFIED = 'CLASSIFIED';
export const SCORE = 'SCORE';
export const AUTOSUGGESTION = 'AUTOSUGGESTION';
export const CHECKLIST = 'CHECKLIST';
export const BUNDLE = 'BUNDLE';
export const QUEST = 'QUEST';
export const UNKNOWN = 'UNKNOWN';
export const ORDER = 'ORDER';
export const MCQ = 'MCQ';
export const FUNDING = 'FUNDING';
export const QUESTION = 'QUESTION';
export const PRIVATE_LISTING = 'PRIVATE_LISTING';
export const AUTOSUGGESTIONS = 'AUTOSUGGESTIONS';
export const PROMOTION = 'PROMOTION';
export const BADGE_COLLECTION = 'BADGE_COLLECTION';
export const BADGE = 'BADGE';
export const JOB = 'JOB';
export const THREAD = 'THREAD';
export const ALBUM = 'ALBUM';
export const NEWS = 'NEWS';
export const BANNER = 'BANNER';
export const MATCH = 'MATCH';
export const LIVE_STREAM = 'LIVE_STREAM';
export const TRIVIA = 'TRIVIA';
export const LOTTERY = 'LOTTERY';

export const SURVEY_TYPES = [SURVEY, SCORE, CHECKLIST, MCQ, FUNDING, LOTTERY];
