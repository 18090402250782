import {
  SORT_ORDER_BY_TYPE_RECENT,
  SORT_ORDER_BY_TYPE_POPULAR,
  SORT_ORDER_BY_TYPE_DISCUSSED,
  SORT_ORDER_BY_LIST_RECENT,
  SORT_ORDER_BY_LIST_POPULAR,
  SORT_ORDER_BY_LIST_DISCUSSED,
  SORT_ORDER_BY_STREAM_RECENT,
  SORT_ORDER_BY_STREAM_POPULAR,
  SORT_ORDER_BY_STREAM_DISCUSSED,
} from 'Modules/posts/bounty/constants/sortConfig';

const sortByStream = {
  [SORT_ORDER_BY_TYPE_RECENT]: SORT_ORDER_BY_STREAM_RECENT,
  [SORT_ORDER_BY_LIST_RECENT]: SORT_ORDER_BY_STREAM_RECENT,
  [SORT_ORDER_BY_TYPE_POPULAR]: SORT_ORDER_BY_STREAM_POPULAR,
  [SORT_ORDER_BY_LIST_POPULAR]: SORT_ORDER_BY_STREAM_POPULAR,
  [SORT_ORDER_BY_TYPE_DISCUSSED]: SORT_ORDER_BY_STREAM_DISCUSSED,
  [SORT_ORDER_BY_LIST_DISCUSSED]: SORT_ORDER_BY_STREAM_DISCUSSED,
};

const sortByList = {
  [SORT_ORDER_BY_TYPE_RECENT]: SORT_ORDER_BY_LIST_RECENT,
  [SORT_ORDER_BY_STREAM_RECENT]: SORT_ORDER_BY_LIST_RECENT,
  [SORT_ORDER_BY_TYPE_POPULAR]: SORT_ORDER_BY_LIST_POPULAR,
  [SORT_ORDER_BY_STREAM_POPULAR]: SORT_ORDER_BY_LIST_POPULAR,
  [SORT_ORDER_BY_TYPE_DISCUSSED]: SORT_ORDER_BY_LIST_DISCUSSED,
  [SORT_ORDER_BY_STREAM_DISCUSSED]: SORT_ORDER_BY_LIST_DISCUSSED,
};

const sortByType = {
  [SORT_ORDER_BY_LIST_RECENT]: SORT_ORDER_BY_TYPE_RECENT,
  [SORT_ORDER_BY_STREAM_RECENT]: SORT_ORDER_BY_TYPE_RECENT,
  [SORT_ORDER_BY_LIST_POPULAR]: SORT_ORDER_BY_TYPE_POPULAR,
  [SORT_ORDER_BY_STREAM_POPULAR]: SORT_ORDER_BY_TYPE_POPULAR,
  [SORT_ORDER_BY_LIST_DISCUSSED]: SORT_ORDER_BY_TYPE_DISCUSSED,
  [SORT_ORDER_BY_STREAM_DISCUSSED]: SORT_ORDER_BY_TYPE_DISCUSSED,
};

export function getSortByStream(sortKey) {
  const keyName = sortByStream[sortKey];
  return keyName || sortKey;
}

export function getSortByList(sortKey) {
  const keyName = sortByList[sortKey];
  return keyName || sortKey;
}

export function getSortByType(sortKey) {
  const keyName = sortByType[sortKey];
  return keyName || sortKey;
}
