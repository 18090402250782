import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { array, func, object, string } from 'prop-types';

//  Components
import DropdownComponent from 'Modules/posts/add/components/DropdownComponent';

//  Actions
import { updateDataSyncAction } from 'Store/actions/genericActions';
import * as modalSelectors from 'Modules/modal/store/selectors';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import { getStreamListDefsSelector, getPostableListDefs } from 'Models/ListManager';
import { userDataSelector } from 'Store/selectors/settings';

//  Other resources
import { getShowInField } from 'Constants/fields';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_OPTIONS_DATA } from 'Store/reducerProperties';
import { generateShowInOptions } from 'Modules/posts/bounty/utils/helpers';

const propTypes = {
  updateDataSyncAction: func.isRequired,
  selectedBounty: object.isRequired,
  optionsData: object.isRequired,
  userData: object.isRequired,
  bountyType: string.isRequired,
  streamLists: array.isRequired,
};

const ShowInListContainer = (props) => {
  const [showInOptions, setShowInOptions] = useState([]);
  const {
    optionsData, selectedBounty: { listId }, bountyType, streamLists, userData,
  } = props;

  const setSelectedList = (options) => {
    const selectedListId = listId || (options[0] && options[0].value);
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, { listId: selectedListId });
  };

  useEffect(() => {
    const lists = getPostableListDefs(streamLists, userData, bountyType);
    const options = generateShowInOptions(lists);
    setShowInOptions(options);
    setSelectedList(options);
  }, []);

  const handleDropdownChanges = (name, value) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, { [name]: value });
  };

  return (
    <DropdownComponent
      handleChanges={handleDropdownChanges}
      value={optionsData.listId}
      config={getShowInField(showInOptions)}
    />
  );
};

const mapStateToProps = (state) => ({
  selectedBounty: selectedBountySelector(state) || {},
  optionsData: modalSelectors.optionsData(state) || {},
  streamLists: getStreamListDefsSelector(state) || [],
  userData: userDataSelector(state).data || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

ShowInListContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(ShowInListContainer);
