import IntlMessage from 'Util/IntlMessages';
import { USER_AGE } from './reducerProperties';

const AMERICAN_EXPRESS = 'American Express';
const DISCOVER = 'Discover';
const JCB = 'JCB';
const DINERS_CLUB = 'Diners Club';
const VISA = 'Visa';
const MASTERCARD = 'MasterCard';
const UNIONPAY = 'UnionPay';
const UNKNOWN = 'Unknown';

const PREFIXES_AMERICAN_EXPRESS = ['34', '37'];
const PREFIXES_DISCOVER = ['60', '64', '65'];
const PREFIXES_JCB = ['35'];
const PREFIXES_DINERS_CLUB = ['300', '301', '302', '303', '304',
  '305', '309', '36', '38', '39'];
const PREFIXES_VISA = ['4'];
const PREFIXES_MASTERCARD = [
  '2221', '2222', '2223', '2224', '2225', '2226', '2227', '2228', '2229',
  '223', '224', '225', '226', '227', '228', '229',
  '23', '24', '25', '26',
  '270', '271', '2720',
  '50', '51', '52', '53', '54', '55', '67',
];
const PREFIXES_UNIONPAY = ['62'];

const AGE = {
  autoFocus: true,
  name: [USER_AGE],
  placeholder: <IntlMessage id="placeholder.specify.your.age" />,
  type: 'number',
};

export const ADDRESS_NAMES = {
  firstAddress: 'line1',
  secondAddress: 'line2',
  country: 'country',
  countryCode: 'countryCode',
  state: 'state',
  city: 'city',
  zipCode: 'zipcode',
};

const FIRST_ADDRESS = {
  autoFocus: true,
  name: ADDRESS_NAMES.firstAddress,
  placeholder: <IntlMessage id="label.address.one" />,
};

const SECOND_ADDRESS = {
  name: ADDRESS_NAMES.secondAddress,
  placeholder: <IntlMessage id="label.address.two" />,
};

const COUNTRY = {
  name: ADDRESS_NAMES.country,
  placeholder: <IntlMessage id="label.country" />,
};

const COUNTRY_CODE = {
  name: ADDRESS_NAMES.countryCode,
  placeholder: <IntlMessage id="label.country" />,
};

const STATE = {
  name: ADDRESS_NAMES.state,
  placeholder: <IntlMessage id="label.state" />,
};

const CITY = {
  name: ADDRESS_NAMES.city,
  placeholder: <IntlMessage id="label.city" />,
};

const ZIP = {
  name: ADDRESS_NAMES.zipCode,
  placeholder: <IntlMessage id="invalid_user" />,
};

export const WELCOME_FIELDS = [AGE];
export const ADDRESS_FIELDS = [FIRST_ADDRESS, SECOND_ADDRESS, ZIP, CITY, STATE, COUNTRY];
export const MY_ADDRESS_FIELDS = [
  FIRST_ADDRESS,
  SECOND_ADDRESS,
  { ...ZIP, name: 'postalCode' },
  CITY,
  STATE,
  COUNTRY_CODE,
];

export const CARD = {
  AMERICAN_EXPRESS,
  DISCOVER,
  JCB,
  DINERS_CLUB,
  VISA,
  MASTERCARD,
  UNIONPAY,
  UNKNOWN,
};
export const CARD_PREFIXES = {
  PREFIXES_AMERICAN_EXPRESS,
  PREFIXES_DISCOVER,
  PREFIXES_JCB,
  PREFIXES_DINERS_CLUB,
  PREFIXES_VISA,
  PREFIXES_MASTERCARD,
  PREFIXES_UNIONPAY,
};
export const CARD_ERRORS = {
  cardNumber: 'Card number is not valid',
  cardExpiry: 'Expiration date is not valid',
  cardCvc: 'CVC code is not valid',
};
