import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { object, func, bool } from 'prop-types';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';

//  Components
import FileUploadContainer from 'Modules/attachments/containers/FileUploadContainer';
import TranslatableDescriptionContainer from 'Modules/posts/add/containers/TranslatableDescriptionContainer';
import ExpiryComponent from 'Modules/posts/add/containers/ExpiryContainer';
import RewardContainer from 'Modules/posts/add/containers/RewardContainer';
import RenderFilesToUpload from 'Modules/attachments/containers/RenderFilesToUpload';
import TargetGroupContainer from 'Modules/posts/add/containers/TargetGroupContainer';
import ShowInListContainer from 'Modules/posts/add/containers/ShowInListContainer';
import PriceContainer from 'Modules/posts/add/containers/PriceContainer';
import JobPostContainer from 'Modules/posts/add/containers/JobPostContainer';
import OnBehalfOfContainer from 'Modules/posts/add/containers/OnBehalfOfContainer';
import StreamDetailsContainer from 'Modules/posts/add/containers/StreamDetailsContainer';
import AttachProductContainer from 'Modules/posts/add/containers/AttachProductContainer';
import AttachedPromotion from 'Modules/posts/add/containers/AttachedPromotion';
import DatePicker from 'Common/components/DatePicker';
import DropdownComponent from 'Modules/posts/add/components/DropdownComponent';
import InputComponent from 'Common/components/InputComponent';
import SwitchComponent from 'Modules/posts/add/components/SwitchComponent';
import { JobDetails } from 'Modules/posts/add/containers/JobDetails';

//  Actions/Selectors
import { userDataSelector, settingsSelector } from 'Store/selectors/settings';
import { setDataAction, updateListSyncAction, updateDataSyncAction } from 'Store/actions/genericActions';
import * as modalSelectors from 'Modules/modal/store/selectors';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import { canAttachProductSelector } from 'Store/selectors/permissions';

//  Other resources
import {
  controlResponsesPrivacyField,
  officialResponseField,
  priceField,
  scheduleForField,
  showAnonymityField,
  timeToRespondField,
  getTargetUrlProperties,
} from 'Constants/fields';
import { onCreate } from 'Modules/posts/add/utils/createPostActivity';
import { MODAL_NAMESPACE, SENTS_NAMESPACE } from 'Store/namespaces';
import { PHOTO_LIBRARY } from 'Modules/attachments/constants/attachmentSourceOptions';
import {
  MODAL_INPUTS_DATA,
  MODAL_OPTIONS_DATA,
  MODAL_SETTINGS,
  MODAL_SURVEY_OPTIONS_DATA,
  MODAL_VALIDATION_ERRORS,
  PRODUCT_TO_ATTACH,
  PROMOTION_TO_ATTACH,
  SELECTED_BOUNTY,
} from 'Store/reducerProperties';
import { JOB, NEWS } from 'Constants/bounty/bountyType';
import { getPostAnonymity, getVisibilityType } from 'Modules/posts/add/utils/addBountyHelpers';
import DescriptionContainer from 'Modules/posts/add/containers/DescriptionContainer';

const propTypes = {
  userData: object.isRequired,
  config: object.isRequired,
  selectedBounty: object.isRequired,
  settings: object.isRequired,
  setDataAction: func.isRequired,
  updateDataSyncAction: func.isRequired,
  canAttachProduct: bool,
  inputsData: object.isRequired,
};

const defaultProps = {
  canAttachProduct: false,
};

const CreateBountyContainer = (props) => {
  const [uiElements, setUiElements] = useState({});
  const classes = useStyles();
  const intl = useIntl();
  const {
    userData, config, settings, selectedBounty, canAttachProduct, inputsData,
  } = props;
  const targetUrlDef = getTargetUrlProperties(config.bountyType);

  const uploadOptions = config.bountyType === NEWS ? [PHOTO_LIBRARY] : [];
  const now = new Date();

  useEffect(() => {
    const elements = prepareUiElements();

    setUiElements(elements);
    initializeStore(elements);
  }, [config.bountyType]);

  useEffect(() => () => {
    props.setDataAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, {});
    props.setDataAction(MODAL_NAMESPACE, MODAL_SETTINGS, {});
    props.setDataAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, {});
    props.setDataAction(MODAL_NAMESPACE, PROMOTION_TO_ATTACH, []);
    props.setDataAction(MODAL_NAMESPACE, PRODUCT_TO_ATTACH, []);
    props.setDataAction(MODAL_NAMESPACE, MODAL_SURVEY_OPTIONS_DATA, {});
    props.setDataAction(MODAL_NAMESPACE, MODAL_VALIDATION_ERRORS, {});
    props.setDataAction(SENTS_NAMESPACE, SELECTED_BOUNTY, {});
  }, []);

  const initializeStore = (elements) => {
    props.updateDataSyncAction(
      MODAL_NAMESPACE,
      MODAL_INPUTS_DATA,
      {
        postAt: selectedBounty?.postAt || null,
        timeLimit: selectedBounty?.timeLimit || (elements.hasTimeToRespond ? 20 : null),
        targetUrl: selectedBounty?.targetUrl || '',
        officialResponse: selectedBounty?.interactions?.officialResponse || false,
        visibilityType: getVisibilityType(selectedBounty?.responseVisibilityMode, true) || false,
        anonymity: getPostAnonymity({ selectedBounty, userData, settings }),
      },
    );
  };

  const prepareUiElements = () => (
    onCreate({
      option: config,
      userData,
      settings,
      selectedBounty,
    })
  );

  const handleDateChanges = (name, date) => {
    props.updateDataSyncAction(
      MODAL_NAMESPACE,
      MODAL_INPUTS_DATA,
      { [name]: date ? new Date(date).getTime() : null },
    );
  };

  const handleDropdownChanges = (name, value) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: value });
  };

  const handleEventChange = ({ target: { name, value } }) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: value });
  };

  const handleSwitchChanges = ({ target: { name, checked } }) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: checked });
  };

  return (
    <div className={classes.root}>
      <Box display="flex">
        {uiElements.hasAttachments && (
          <FileUploadContainer
            options={uploadOptions}
            multiple={config.bountyType !== NEWS}
          />
        )}
        {canAttachProduct && <AttachProductContainer />}
      </Box>
      {uiElements.hasJobPost && (
        <JobPostContainer isTranslatableTitle={uiElements.hasTranslatableTitle} />
      )}
      {uiElements.hasDescription && (
        <DescriptionContainer
          bountyType={config.bountyType}
          hasSurvey={uiElements.hasSurvey}
        />
      )}
      {uiElements.hasTranslatableDescription && (
        <TranslatableDescriptionContainer
          bountyType={config.bountyType}
        />
      )}
      {uiElements.hasTimeToRespond && (
        <Box width="50%">
          <DropdownComponent
            config={timeToRespondField}
            value={inputsData.timeLimit}
            handleChanges={handleDropdownChanges}
          />
        </Box>
      )}
      {uiElements.hasTargetUrl && (
        <div>
          <InputComponent
            {...targetUrlDef}
            label={intl.formatMessage({ id: targetUrlDef.label })}
            placeholder={intl.formatMessage({ id: targetUrlDef.placeholder })}
            value={inputsData.targetUrl}
            handleChanges={handleEventChange}
          />
        </div>
      )}
      {uiElements.hasStreamDetails && <StreamDetailsContainer />}
      {uiElements.hasPrice && <PriceContainer priceObject={priceField} />}
      {uiElements.hasReward && (
        <RewardContainer
          rewardName="reward"
          activityType={config.activityType}
          bountyType={config.bountyType}
          isOptional={uiElements.hasOptionalReward}
        />
      )}
      {config.bountyType === JOB && (
        <JobDetails
          bountyType={config.bountyType}
          activityType={config.activityType}
        />
      )}
      {uiElements.hasExpiryFeature && (
        <ExpiryComponent
          activityType={config.activityType}
          bountyType={config.bountyType}
        />
      )}
      {uiElements.hasDistTargetGroup && <TargetGroupContainer bountyType={config.bountyType} />}
      {uiElements.hasRequestBadge && (
        <SwitchComponent
          config={officialResponseField}
          value={inputsData[officialResponseField.name]}
          handleChanges={handleSwitchChanges}
        />
      )}
      {uiElements.hasControlAnonymity && (
        <SwitchComponent
          config={showAnonymityField}
          value={inputsData[showAnonymityField.name]}
          handleChanges={handleSwitchChanges}
        />
      )}
      {uiElements.hasControlResponsesPrivacy && (
        <SwitchComponent
          config={controlResponsesPrivacyField}
          value={inputsData[controlResponsesPrivacyField.name]}
          handleChanges={handleSwitchChanges}
        />
      )}
      {uiElements.hasDisplayIn && <ShowInListContainer bountyType={config.bountyType} />}
      {uiElements.hasScheduleFor && (
        <DatePicker
          {...scheduleForField}
          minDate={now}
          selectedDate={inputsData?.postAt || null}
          handleDateChange={(date) => handleDateChanges(scheduleForField.name, date)}
        />
      )}
      {uiElements.hasPostOnBehalfOf && <OnBehalfOfContainer />}

      <RenderFilesToUpload bounty={selectedBounty} />
      {canAttachProduct && (
        <AttachedPromotion />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  inputsData: modalSelectors.inputsData(state) || {},
  selectedBounty: selectedBountySelector(state) || {},
  userData: userDataSelector(state).data || {},
  settings: settingsSelector(state) || {},
  canAttachProduct: canAttachProductSelector(state) || false,
});

const mapDispatchToProps = {
  setDataAction,
  updateListSyncAction,
  updateDataSyncAction,
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
}));

CreateBountyContainer.propTypes = propTypes;
CreateBountyContainer.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(CreateBountyContainer);
