export function hasBadge(badges, badgeType) {
  return badges.stash && badges.stash[badgeType];
}

export function isValid(badge) { // eslint-disable-line
  return true;
}

export function addBadge(badges, badgeInfo) {
  const stash = badges.stash || {};
  const badgeStash = stash.info ? stash.info.code : null;

  if (badgeStash) {
    badgeStash.count++;
  } else {
    stash[badgeInfo.code] = {
      count: 1,
      info: badgeInfo,
    };
  }

  return {
    ...badges,
    stash,
  };
}

export function removeBadge(badges, badgeCode) {
  let stash = badges.stash || {};

  if (stash) {
    delete stash[badgeCode];
  }

  if (stash && !Object.keys(stash).length) {
    stash = null;
  }

  return {
    ...badges,
    stash,
  };
}

export function negateBadge(badge) {
  return {
    ...badge,
    negative: badge.negative === null || !badge.negative ? true : null,
  };
}

function getCode(info) {
  return info ? info.code : null;
}

export function badgeToString(badge) {
  return `${getCode(badge.info)}:${badge.id}`;
}
