import { useEffect } from 'react';
import { connect } from 'react-redux';
import { array, func, object } from 'prop-types';
import { ImageList, ImageListItem, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

//  Actions/Selectors
import { attachmentsSelector, removedAttachmentsSelector } from 'Modules/attachments/store/selectors';
import { updateListSyncAction } from 'Store/actions/genericActions';

//  Other resources
import { ATTACHMENT_TYPE } from 'Constants/attachment';
import {
  getAttachmentsWithoutCustomMeaning,
  renderAudio,
  renderImage,
  renderPdf,
  renderVideo,
} from 'Modules/attachments/utils/helpers';
import { ATTACHMENTS_NAMESPACE } from 'Store/namespaces';
import { ATTACHMENTS, REMOVED_ATTACHMENTS } from 'Store/reducerProperties';
import { PRODUCT } from 'Constants/bounty/bountyType';
import makeStyles from '@mui/styles/makeStyles';

const propTypes = {
  attachments: array.isRequired,
  removedAttachments: array.isRequired,
  bounty: object,
  response: object,
  updateListSyncAction: func.isRequired,
};

const defaultProps = {
  response: null,
  bounty: null,
};

const RenderFilesToUpload = (props) => {
  const {
    bounty, response, attachments, removedAttachments,
  } = props;
  const classes = useStyles();

  const setInitialAttachments = () => {
    let initialAttach = [];

    if (response) {
      initialAttach = response.attachments && response.attachments.attachments
        ? Object.values(response.attachments.attachments)
        : [];
    } else if (bounty) {
      initialAttach = getAttachmentsWithoutCustomMeaning(bounty);

      if (bounty.type === PRODUCT && bounty.product && bounty.product.imageUrl) {
        const isAlreadyAttach = initialAttach.find((attach) => attach.url === bounty.product.imageUrl) || null;

        if (!isAlreadyAttach) {
          initialAttach.push({
            type: 'IMAGE',
            url: bounty.product.imageUrl,
            onlyAsUrl: true,
          });
        }
      }
    }

    props.updateListSyncAction(ATTACHMENTS_NAMESPACE, ATTACHMENTS, initialAttach);
  };

  useEffect(() => {
    if (bounty || response) {
      setInitialAttachments();
    }
  }, []);

  const handleRemoveAttachment = (index) => {
    const attachmentToRemove = attachments[index];
    attachments.splice(index, 1);

    props.updateListSyncAction(ATTACHMENTS_NAMESPACE, ATTACHMENTS, attachments);
    props.updateListSyncAction(ATTACHMENTS_NAMESPACE, REMOVED_ATTACHMENTS, [...removedAttachments, attachmentToRemove]);
  };

  return (
    <ImageList sx={{ maxHeight: 250 }} cols={2} rowHeight={150} gap={10} className="mt-10 mb-10">
      {attachments.map((attachment, key) => {
        const fileUrl = attachment.url || attachment.localUri;

        return (
          <ImageListItem key={key}>
            {(attachment.type === ATTACHMENT_TYPE.IMAGE || attachment.type === ATTACHMENT_TYPE.GIF) && (
              renderImage({ url: fileUrl })
            )}
            {attachment.type === ATTACHMENT_TYPE.PDF && renderPdf({ url: fileUrl, width: 250 })}
            {attachment.type === ATTACHMENT_TYPE.VIDEO && renderVideo({ url: fileUrl, height: 'inherit' })}
            {attachment.type === ATTACHMENT_TYPE.AUDIO && renderAudio({ url: fileUrl })}
            {!attachment.onlyAsUrl && (
              <IconButton
                color="secondary"
                className={classes.removeButton}
                size="small"
                onClick={() => handleRemoveAttachment(key)}
              >
                <CloseIcon className={classes.icon} fontSize="small" />
              </IconButton>
            )}
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};

RenderFilesToUpload.propTypes = propTypes;
RenderFilesToUpload.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  attachments: attachmentsSelector(state) || [],
  removedAttachments: removedAttachmentsSelector(state) || [],
});

const mapDispatchToProps = {
  updateListSyncAction,
};

const useStyles = makeStyles((theme) => ({
  removeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.main,
    },
  },
  icon: {
    color: 'white',
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(RenderFilesToUpload);
