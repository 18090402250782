import { USER_ROLE_NAME, ADMIN_ROLE_NAME } from 'Constants/roles';
import { getInstance } from 'Models/ProductConfig';
import { op } from 'Models/Op';
import * as allowMode from 'Modules/posts/bounty/constants/allowMode';
import * as ProductFlavour from 'Models/ProductFlavour';

const flavour = ProductFlavour.getCurrent();
let productConfig = {};
const adminOps = [];
const defaultOps = [];

export const AllowMode = {
  isSupreme: (mode) => (mode === allowMode.ALWAYS || mode === allowMode.NEVER),
  isAllowed: (mode) => (mode === allowMode.ALWAYS || mode === allowMode.SHOULD),
  asEnum: (mode) => (!mode ? allowMode.DEFAULT : (allowMode[mode] || allowMode.DEFAULT)),
};

const ADMIN_ROLE_DEF = {
  ops: adminOps,
  isDefault: true,
  forbiddenOps: [],
  isAllowed: () => true,
  isForbidden: () => false,
  isAllowedUnrestrictedAccess: () => true,
  getExplicitlyAllowed: () => null,
};

const USER_ROLE_DEF = {
  ops: defaultOps,
  isDefault: true,
  forbiddenOps: [],
  isForbidden: () => false,
  isAllowed: (operation) => {
    const mode = productConfig.getAllowed(operation);

    if (mode !== allowMode.DEFAULT) {
      return AllowMode.isAllowed(mode);
    }

    return false;
  },
  isAllowedUnrestrictedAccess: () => true,
  getExplicitlyAllowed: () => null,
};

/**
 * @param {string} productFlavour
 * @returns {({isFollowUserEnabled, getAllowed, listTypes, identityMode, accessMode, bountyTypes, hideBulletins, isSocialPostEnabled}&{getAllowed})
 * |({isFollowUserEnabled, getAllowed, listTypes, identityMode, accessMode, bountyTypes, hideBulletins, isSocialPostEnabled}&{isFollowUserEnabled})
 * |{isFollowUserEnabled, getAllowed, listTypes, identityMode, accessMode, bountyTypes, hideBulletins, isSocialPostEnabled}}
 */
export function getDefaults(productFlavour) {
  const config = getInstance(productFlavour);

  op.forEach((operation) => {
    const mode = config.getAllowed(operation);

    if (AllowMode.isSupreme(mode) && !AllowMode.isAllowed(mode)) {
      return null;
    }

    adminOps.push(operation);

    if (!AllowMode.isAllowed(mode)) {
      return null;
    }

    if (operation.enabledByDefault) {
      defaultOps.push(operation);
    }
  });

  return config;
}

/**
 * Get default definition of user role (user or admin)
 * @param role {String}
 * @returns {Object}
 */
export function getRoleDef(role) {
  productConfig = getDefaults(flavour);

  if (!role) {
    return null;
  }

  if (role === USER_ROLE_NAME) {
    return USER_ROLE_DEF;
  }

  if (role === ADMIN_ROLE_NAME) {
    return ADMIN_ROLE_DEF;
  }

  return null;
}
