import { useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { array, func, object } from 'prop-types';
import { Tooltip, Typography, Box } from '@mui/material';
import { EditorState } from 'draft-js';
import { NotificationManager } from 'react-notifications';
import { useFormik } from 'formik';

//  Components
import MediaOptionsContainer from 'Modules/attachments/containers/MediaOptionsContainer';
import RenderFilesToUpload from 'Modules/attachments/containers/RenderFilesToUpload';
import MarkdownEditor from 'Modules/posts/add/components/MarkdownEditor';
import ModalButtons from 'Modules/modal/components/ModalButtons';
import SwitchField from 'Common/components/fields/SwitchField';
import { LoadingComponent } from 'Common/components';

//  Actions/Selectors
import { closeDrawer } from 'Modules/modal/store/operations';
import { userDataSelector, settingsSelector } from 'Store/selectors/settings';
import { attachmentsSelector } from 'Modules/attachments/store/selectors';
import { createResponse, updateBountyResponse, getNewResponse } from 'Services/response/ResponseService';
import { drawerSettingsSelector } from 'Modules/modal/store/selectors';

//  Other resources
import IntlMessages, { formatMessage } from 'Util/IntlMessages';
import { getDescription } from 'Util/BountyUtils';
import { PRIVATE_TYPE } from 'Models/VisibilityMode';
import { prepareMarkdownText, convertToEditorStateObj } from 'Modules/posts/add/utils/markdownEditorUtils';
import * as OpManage from 'Models/OpManager';
import { getIdentityMode } from 'Modules/posts/add/utils/addBountyHelpers';
import { identityType } from 'Constants/bounty/bounty';
import { options } from 'Constants/options';

const propTypes = {
  updateBountyResponse: func.isRequired,
  createResponse: func.isRequired,
  closeDrawer: func.isRequired,
  bounty: object.isRequired,
  settings: object.isRequired,
  userData: object.isRequired,
  attachments: array.isRequired,
};

const defaultProps = {};

const AddReply = (props) => {
  const [editorValue, setEditorValue] = useState(EditorState.createEmpty());
  const [identityMode, setIdentityMode] = useState({});
  const {
    bounty, userData, settings, attachments,
  } = props;
  const bountyInfo = bounty?.bountyInfo || bounty;
  const response = bounty?.bountyInfo ? bounty : {};
  const isEditMode = !!(bounty?.bountyInfo && Object.keys(bounty?.bountyInfo).length > 0);

  const initAnonymity = () => {
    if (isEditMode) {
      return formik.setFieldValue(
        options.postAnonymously.name,
        bounty.identityMode && bounty.identityMode.identityType !== identityType.REAL,
      );
    }

    const mode = OpManage.getIdentityMode(settings, userData);
    setIdentityMode(mode);
    formik.setFieldValue(options.postAnonymously.name, mode.identityType !== identityType.REAL);
  };

  useEffect(() => {
    if (isEditMode) {
      setEditorValue(convertToEditorStateObj(bounty?.note || ''));
    }

    initAnonymity();
  }, []);

  useEffect(() => {
    if (attachments && attachments.length) {
      formik?.setFieldValue('attachments', attachments);
    }
  }, [attachments]);

  const handleEditorChange = (editorState) => setEditorValue(editorState);

  const isResponsePrivate = () => {
    const visibilityMode = bountyInfo ? bountyInfo.responseVisibilityMode : null;
    return visibilityMode && visibilityMode.visibilityType === PRIVATE_TYPE;
  };

  const addReply = async (newResponse, attach) => {
    await props.createResponse(bounty, newResponse, userData, attach);
    props.closeDrawer();
  };

  const editReply = (newResponse, attach) => {
    props.updateBountyResponse({
      bounty: bountyInfo,
      oldResponse: bounty,
      newResponse,
      attachments: attach,
      userData,
    })
      .then(props.closeDrawer);
  };

  const submitReply = async (values, { setSubmitting }) => {
    const note = prepareMarkdownText(editorValue);

    if (!note) {
      setSubmitting(false);
      return NotificationManager.warning('Provide a message for your reply.');
    }

    const newResponse = isEditMode ? cloneDeep(bounty) : await getNewResponse(bounty);

    newResponse.note = note || null;
    newResponse.identityMode = getIdentityMode({
      settings,
      userData,
      anonymity: values[options.postAnonymously.name],
    });

    return isEditMode
      ? editReply(newResponse, values.attachments)
      : addReply(newResponse, values.attachments);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      [options.postAnonymously.name]: false,
    },
    onSubmit: submitReply,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <LoadingComponent isLoading={formik.isSubmitting}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className="reply-to-description mb-10">
          {getDescription({ bounty: bountyInfo })}
        </div>
        <MediaOptionsContainer />
        <MarkdownEditor
          placeholder={formatMessage({ id: 'placeholder.yourMessage' })}
          editorState={editorValue}
          handleChange={handleEditorChange}
        />
        {isResponsePrivate() && (
          <Typography>
            Your reply will be visible only to the author of the challenge.
          </Typography>
        )}
        {!identityMode.forced && (
          <SwitchField
            {...options.postAnonymously}
            formik={formik}
            label={(
              <Box display="flex" alignItems="center">
                <span><IntlMessages id="bounty.postAnonymously" /></span>
                <Tooltip
                  id="tooltip-top-start"
                  title={<IntlMessages id="bounty.anonymityHelpContent" />}
                  placement="top-start"
                >
                  <span className="material-icons ml-10">info_outline</span>
                </Tooltip>
              </Box>
            )}
          />
        )}
        <RenderFilesToUpload bounty={bountyInfo} response={response} />
        <ModalButtons
          onDismiss={props.closeDrawer}
          onSubmit={formik.handleSubmit}
          submitName="Send"
          type="submit"
        />
      </form>
    </LoadingComponent>
  );
};

const mapStateToProps = (state) => ({
  userData: userDataSelector(state).data || {},
  settings: settingsSelector(state) || {},
  attachments: attachmentsSelector(state) || [],
  bounty: drawerSettingsSelector(state)?.selectedBounty || {},
});

const mapDispatchToProps = {
  createResponse,
  updateBountyResponse,
  closeDrawer,
};

AddReply.propTypes = propTypes;
AddReply.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddReply);
