import { array, func, object } from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

//  Components
import { getProductBackgroundImage, textTruncate } from 'Util/helpers';
import { RctCard } from './RctCard';

// Others

const propTypes = {
  promotion: object.isRequired,
  handleSelect: func.isRequired,
  selectedPromotions: array.isRequired,
};
const defaultProps = {};

const SelectablePromotionCard = ({ promotion, handleSelect, selectedPromotions }) => {
  const productDetails = promotion?.products ? Object.values(promotion.products)[0]?.product : {};
  const backgroundImage = getProductBackgroundImage(productDetails);
  const classes = useStyles();
  const isActive = !!selectedPromotions?.find((selectedProm) => selectedProm.id === promotion.id);

  const onSelect = () => {
    handleSelect(promotion);
  };

  return (
    <div
      role="presentation"
      onClick={onSelect}
      className={classnames('cursor-pointer')}
    >
      <RctCard
        customClasses={classnames('d-flex mb-0 flex-column justify-content-between overflow-hidden', { [classes.selectProduct]: isActive })}
      >
        <div className="overlay-wrap overflow-hidden">
          <div className="text-center p-4">
            <div
              style={{ ...backgroundImage, height: '200px' }}
              className="image-post product-image-placeholder"
            />
          </div>
        </div>
        <div className="product-info border-top p-3">
          <h4 className="text-dark">
            {promotion?.description ? textTruncate(promotion.description, 30) : '-'}
          </h4>
        </div>
      </RctCard>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  selectProduct: {
    border: `2px solid ${theme.palette.common.greenLabel}`,
  },
}));

SelectablePromotionCard.propTypes = propTypes;
SelectablePromotionCard.defaultProps = defaultProps;

export default SelectablePromotionCard;
