import { useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { node, object } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

//  Components
import Header from 'Common/components/Header';
import SearchWrapper from 'Modules/algoliaSearch/containers/SearchWrapper';
import { Results } from 'Modules/algoliaSearch/containers/CustomHits';

//  Selectors
import { userDataSelector } from 'Store/selectors/settings';

//  Other resources
import {
  CHAT_ROUTE,
  REPORTS_ROUTE,
  UPLOAD_PRODUCTS_ROUTE,
  V2_HOME_ROUTE,
  V2_APPLICATIONS_ROUTE,
  V2_JOBS_ROUTE,
  CANDIDATES_ROUTE,
  V2_JOB_DETAILS_ROUTE,
  USER_MANAGEMENT_ROUTE,
  STREAM_ROUTE,
  PRODUCTS_DETAIL_ROUTE,
  USER_SENTS_ROUTE,
  GROUPS_PROFILE_ROUTE,
  COMPANY_PROFILE_ROUTE,
  SETTINGS_ROUTE,
  SIGN_IN_ROUTE,
  NOT_FOUND,
} from 'Constants/routes';
import {
  subscribeToAuthUserProfile,
  unsubscribeFromAuthUserProfile,
  getAuthUserPrivateInfo,
  subscribeToAuthUserEndorsements,
  unsubscribeFromAuthUserEndorsements, logoutUserFromFirebase,
} from 'Modules/authentication/store/operations';
import SideBar from '../../../common/components/SideBar';

// TODO: remove 'colored-bg' classes after moving to a new design
const routesClassNames = {
  [V2_JOB_DETAILS_ROUTE]: 'colored-bg',
  [UPLOAD_PRODUCTS_ROUTE]: 'colored-bg',
  [V2_HOME_ROUTE]: 'colored-bg',
  [V2_APPLICATIONS_ROUTE]: 'colored-bg',
  [V2_JOBS_ROUTE]: 'colored-bg',
  [CANDIDATES_ROUTE]: 'colored-bg',
  [USER_MANAGEMENT_ROUTE]: 'colored-bg',
  [STREAM_ROUTE]: 'colored-bg',
  [PRODUCTS_DETAIL_ROUTE]: 'colored-bg',
  [USER_SENTS_ROUTE]: 'colored-bg',
  [GROUPS_PROFILE_ROUTE]: 'colored-bg',
  [COMPANY_PROFILE_ROUTE]: 'colored-bg',
  [SETTINGS_ROUTE]: 'colored-bg',
  [REPORTS_ROUTE]: 'colored-bg',
  [NOT_FOUND]: 'colored-bg',
};

//  TODO: check how to use preload components
const Layout = (props) => {
  const [pageClassNames, setPageClassNames] = useState('');
  const { children, userData } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  //  TODO: ".split('/').slice(0, 2).join('/')" temporary construction for url '/job/$bountyId'
  const filter = location.pathname.split('/').slice(0, 2).join('/');
  const classes = useStyles();
  const scrollRef = useRef(null);
  const userDataCheck = userData?.myself?.id;

  useMemo(() => {
    const route = Object.keys(routesClassNames).find((r) => r.includes(filter));
    setPageClassNames(routesClassNames[route]);
  }, [location.pathname]);

  useEffect(() => {
    if (!userDataCheck) {
      dispatch(logoutUserFromFirebase());
      history.push(SIGN_IN_ROUTE);
    }
    dispatch(subscribeToAuthUserProfile(userDataCheck));
    return () => dispatch(unsubscribeFromAuthUserProfile(userDataCheck));
  }, [userDataCheck]);

  useEffect(() => {
    if (!userDataCheck) {
      dispatch(logoutUserFromFirebase());
      history.push(SIGN_IN_ROUTE);
    }
    dispatch(subscribeToAuthUserEndorsements(userDataCheck));
    return () => dispatch(unsubscribeFromAuthUserEndorsements(userDataCheck));
  }, [userDataCheck]);

  useEffect(() => {
    if (!userDataCheck) {
      dispatch(logoutUserFromFirebase());
      history.push(SIGN_IN_ROUTE);
    }
    dispatch(getAuthUserPrivateInfo(userDataCheck));
  }, [userDataCheck]);

  useEffect(() => {
    scrollRef?.current?.scrollToTop();
  }, [location.pathname]);

  const renderPage = () => {
    if (location.pathname.startsWith(CHAT_ROUTE)) {
      return (
        <div className="rct-page-content p-0">
          {children}
        </div>
      );
    }

    return (
      <Scrollbars
        ref={scrollRef}
        className={`rct-scroll ${classes.scroll} `}
        autoHide
        autoHideDuration={100}
      >
        <Box p={4} className={`rct-page-content position-relative ${pageClassNames}`}>
          <Box className={`${location.pathname.startsWith(REPORTS_ROUTE) ? '' : classes.content}`}>
            <Results />
            {children}
          </Box>
        </Box>
      </Scrollbars>
    );
  };

  return (
    <div className="app">
      <div className="app-main-container">
        <SideBar />
        <Box width="100%">
          <SearchWrapper userData={userData}>
            <div className="app-container">
              <div className="rct-app-content">
                <div className="app-header">
                  <Header />
                </div>
                <div className="rct-page">
                  {renderPage()}
                </div>
              </div>
            </div>
          </SearchWrapper>
        </Box>
      </div>
    </div>
  );
};

Layout.propTypes = {
  userData: object.isRequired,
  children: node.isRequired,
};

const useStyles = makeStyles((theme) => ({
  scroll: {
    height: 'calc(100vh - 65px)!important',
  },
  content: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
      maxWidth: '1700px', // limit max width of the content area
    },
    borderRight: 'none',
  },
}));

const mapStateToProps = (state) => ({
  ...state.settings,
  userData: userDataSelector(state).data || {},
});

export default connect(mapStateToProps)(Layout);
