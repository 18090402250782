/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import loadable from '@loadable/component';
import RctPageLoader from 'Common/components/RctPageLoader';

export const AsyncChatComponent = loadable(
  () => import('Modules/chat/components'),
  { fallback: <RctPageLoader /> },
);

export const AsyncStreamPageContainer = loadable(
  () => import('Modules/posts/bounty/containers/BountyListWrapper'),
  { fallback: <RctPageLoader /> },
);

export const AsyncBountyDetailsContainer = loadable(
  () => import('Modules/posts/bounty/containers/BountyDetailsPageContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncBountyResponseDetailsContainer = loadable(
  () => import('Modules/posts/responses/containers/BountyResponseDetailsPageContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncMyAccountPageContainer = loadable(
  () => import('Modules/myAccount/containers/MyAccountPageContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncLeaderboardPageContainer = loadable(
  () => import('Modules/leaderboard/containers/LeaderboardPageContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncMyProfilePageContainer = loadable(
  () => import('Modules/myProfile/containers/MyProfilePageContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncUserSentsContainer = loadable(
  () => import('Modules/sents/containers/UserSentsContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncReportsContainer = loadable(
  () => import('Modules/reports/containers/ReportsWrapper'),
  { fallback: <RctPageLoader /> },
);

export const AsyncProductsContainer = loadable(
  () => import('Modules/posts/bounty/containers/BountyListWrapper'),
  { fallback: <RctPageLoader /> },
);

export const AsyncProductsDetailContainer = loadable(
  () => import('Modules/products/containers/ProductDetailContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncCompanySentsContainer = loadable(
  () => import('Modules/companySents/containers/CompanyProductsContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncUploadProductsContainer = loadable(
  () => import('Modules/products/containers/UploadProductsContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncCompanyPinContainer = loadable(
  () => import('Modules/authentication/containers/VerifyPinContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncLoginContainer = loadable(
  () => import('Modules/authentication/containers/LoginContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncSignupWizard = loadable(
  () => import('Modules/authentication/containers/SignupWizard'),
  { fallback: <RctPageLoader /> },
);

export const AsyncOnBoardingLayout = loadable(
  () => import('Modules/onboarding/containers/OnBoardingLayout'),
  { fallback: <RctPageLoader /> },
);

export const AsyncForgotPassword = loadable(
  () => import('Modules/authentication/containers/ForgotPassword'),
  { fallback: <RctPageLoader /> },
);

export const AsyncNotFoundPage = loadable(
  () => import('../components/NotFoundPage'),
  { fallback: <RctPageLoader /> },
);

export const AsyncCoverBounty = loadable(
  () => import('Modules/posts/bounty/containers/CoverBounty'),
  { fallback: <RctPageLoader /> },
);

export const AsyncMerchantLanding = loadable(
  () => import('Modules/authentication/components/MerchantLanding'),
  { fallback: <RctPageLoader /> },
);

export const AsyncMerchantSignup = loadable(
  () => import('Modules/authentication/components/MerchantSignup'),
  { fallback: <RctPageLoader /> },
);

export const AsyncMerchantRegisterCompany = loadable(
  () => import('Modules/authentication/components/MerchantRegisterCompany'),
  { fallback: <RctPageLoader /> },
);

export const AsyncMerchantUrlCompany = loadable(
  () => import('Modules/authentication/components/MerchantUrlCompany'),
  { fallback: <RctPageLoader /> },
);

export const AsyncHashtagSearch = loadable(
  () => import('Modules/posts/bounty/containers/HashtagsSearchResults'),
  { fallback: <RctPageLoader /> },
);

export const AsyncCart = loadable(
  () => import('Modules/cart/containers/Cart'),
  { fallback: <RctPageLoader /> },
);

export const AsyncGroupsContainer = loadable(
  () => import('Modules/group/containers/GroupsListContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncGroupsMembersContainer = loadable(
  () => import('Modules/group/containers/GroupMembersContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncGroupProfileContainer = loadable(
  () => import('Modules/group/containers/GroupProfileContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncGroupInvitesContainer = loadable(
  () => import('Modules/group/containers/GroupInvitesContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncOrderDetails = loadable(
  () => import('Modules/order/containers/OrderDetails'),
  { fallback: <RctPageLoader /> },
);

export const AsyncOrderList = loadable(
  () => import('Modules/order/containers/OrderList'),
  { fallback: <RctPageLoader /> },
);

export const AsyncOrderMerchDetails = loadable(
  () => import('Modules/order/containers/OrderMerchantDetails'),
  { fallback: <RctPageLoader /> },
);

export const AsyncUserAddresses = loadable(
  () => import('Modules/cart/containers/address/UserAddresses'),
  { fallback: <RctPageLoader /> },
);

export const AsyncUserCards = loadable(
  () => import('Modules/cart/containers/creditCards/UserCards'),
  { fallback: <RctPageLoader /> },
);

export const AsyncCreateCompany = loadable(
  () => import('Modules/onboarding/company/containers/CreateCompanyContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncCompanyProfile = loadable(
  () => import('Modules/group/containers/company/CompanyProfile'),
  { fallback: <RctPageLoader /> },
);

export const AsyncPromotionsContainer = loadable(
  () => import('Modules/promotions/containers/PromotionsContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncPromotionDetailsContainer = loadable(
  () => import('Modules/promotions/containers/PromotionDetailsContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncBadgeCollections = loadable(
  () => import('Modules/badges/containers/BadgeCollections'),
  { fallback: <RctPageLoader /> },
);

export const AsyncBadgeCollectionDetails = loadable(
  () => import('Modules/badges/containers/BadgeCollectionDetails'),
  { fallback: <RctPageLoader /> },
);

export const AsyncCreateBadgeCollection = loadable(
  () => import('Modules/badges/containers/CreateBadgeCollection'),
  { fallback: <RctPageLoader /> },
);

export const AsyncCreateBadge = loadable(
  () => import('Modules/badges/containers/CreateBadge'),
  { fallback: <RctPageLoader /> },
);

export const AsyncCreateNotification = loadable(
  () => import('Modules/notification/containers/CreateNotification'),
  { fallback: <RctPageLoader /> },
);

export const AsyncCompanyJobs = loadable(
  () => import('Modules/companySents/containers/CompanyJobsContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncApplicants = loadable(
  () => import('Modules/applications/containers/ApplicantsContainerWrapper'),
  { fallback: <RctPageLoader /> },
);

export const AsyncV2Applications = loadable(
  () => import('Common/containers/v2ApplicationsContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncV2Candidates = loadable(
  () => import('Common/containers/CandidatesContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncReviewedApplicants = loadable(
  () => import('Modules/applications/containers/ReviewedApplicationsContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncBlackListApplicants = loadable(
  () => import('Modules/applications/containers/BlackListApplicants'),
  { fallback: <RctPageLoader /> },
);

export const AsyncCompanies = loadable(
  () => import('Modules/company/containers/CompanyListContainerWrapper'),
  { fallback: <RctPageLoader /> },
);

export const AsyncSettings = loadable(
  () => import('Modules/settings/containers/SettingsWrapperContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncMatchesList = loadable(
  () => import('Modules/posts/matches/containers/MatchesContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncMatchDetails = loadable(
  () => import('Modules/posts/matches/containers/MatchDetailsContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncHomePage = loadable(
  () => import('Modules/HomePage'),
  { fallback: <RctPageLoader /> },
);

export const AsyncTerminal = loadable(
  () => import('Modules/terminal'),
  { fallback: <RctPageLoader /> },
);

export const AsyncUserManagement = loadable(
  () => import('Common/containers/UserManagement'),
  { fallback: <RctPageLoader /> },
);

export const AsyncV2Jobs = loadable(
  () => import('Common/containers/v2JobsContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncV2Job = loadable(
  () => import('Common/containers/v2JobContainer'),
  { fallback: <RctPageLoader /> },
);

export const AsyncV2JobEdit = loadable(
  () => import('Common/containers/v2JobEditContainer'),
  { fallback: <RctPageLoader /> },
);
