import { PRODUCT_KIND } from 'Models/product/Product';
import { BOTH, EITHER, MONEY, POINTS } from 'Util/RewardType';
import { asMap } from 'Util/pointsUtils';
import { pointCurrency } from 'Models/PointCurrency';

export function prepareProduct(userData, kind) {
  return {
    merchant: userData.company || null,
    merchantId: userData.company.id || null,
    kind: kind || null,
    published: false,
    seoTitle: null,
    seoDescription: null,
    deliveryType: null,
  };
}

export function prepareTags(tags) {
  if (!tags) {
    return null;
  }

  const tagKey = tags.split(' ').filter((item) => item).join('_');

  return {
    tags: {
      [tagKey]: {
        id: tagKey,
        displayName: tags,
      },
    },
  };
}

export function prepareVariants({
  oldVariants, kind, merchantSku, customData,
}) {
  const {
    skuInfo, price, msrp, currencyType, currency,
  } = customData;

  if (!merchantSku) {
    return oldVariants || null;
  }

  return {
    ...(oldVariants || {}),
    [merchantSku]: {
      ...(oldVariants?.[merchantSku] ? oldVariants[merchantSku] : {}),
      price: preparePrice(price, currencyType, currency),
      msrp: preparePrice(msrp, currencyType, currency),
      sku: merchantSku,
      quantity: skuInfo?.quantity ? +skuInfo?.quantity : null,
      ...(kind === PRODUCT_KIND.TICKET ? prepareOnlineProductInfo(skuInfo?.onlineProductInfo) : {}),
    },
  };
}

function prepareOnlineProductInfo(onlineProductInfo = {}) {
  return {
    onlineProductInfo: {
      ...onlineProductInfo,
      useQty: onlineProductInfo?.useQty ? +onlineProductInfo.useQty : null,
    },
  };
}

function preparePrice(price, currencyType, currency) {
  const { main, money, points } = price || {};

  if (!main && !money && !points) {
    return null;
  }

  if ([BOTH, EITHER].includes(currencyType)) {
    return {
      altPrice1: {
        [MONEY.toLowerCase()]: {
          amount: money.toString(),
          currency,
        },
      },
      altPrice2: {
        [POINTS.toLowerCase()]: asMap({ amount: +points, currency: pointCurrency.POINT }),
      },
    };
  }

  return {
    mainPrice: {
      [currencyType.toLowerCase()]: currencyType === POINTS
        ? asMap({ amount: +main, currency: pointCurrency.POINT })
        : ({
          amount: main.toString(),
          currency,
        }),
    },
  };
}
