import JobInformationService from 'Services/JobInformationService';
import { NotificationManager } from 'react-notifications';
import { valueKeyToLabelValue } from 'Common/utils/formatters';

export const ACTIONS = {
  LOADING: 'skills/Loading',
  LOADED: 'skills/Loaded',
  GET_DATA: 'skills/GetSkills',
};

export const getSkills = (isForceUpdate = false) => (dispatch, getState) => {
  const { skillsReducer: { isLoaded, isLoading } } = getState();

  if (isForceUpdate || (!isLoaded && !isLoading)) {
    dispatch({ type: ACTIONS.LOADING, payload: true });
    JobInformationService.getJobInfo('SKILL')
      .then((response) => {
        dispatch({ type: ACTIONS.GET_DATA, payload: valueKeyToLabelValue(response.list) });
      })
      .catch((err) => {
        NotificationManager.error(err.message);
      })
      .finally(() => {
        dispatch({ type: ACTIONS.LOADING, payload: false });
        dispatch({ type: ACTIONS.LOADED, payload: true });
      });
  }
};
