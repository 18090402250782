import { isBlank, removeSpacesAndHyphens, hasAnyPrefix } from 'Modules/onboarding/utils/StripeTextUtils';
import { CARD, CARD_PREFIXES } from 'Modules/onboarding/utils/constants';

export function getPossibleCardType(cardNumber, shouldNormalize) {
  if (isBlank(cardNumber)) {
    return CARD.UNKNOWN;
  }

  let spacelessCardNumber = cardNumber;

  if (shouldNormalize) {
    spacelessCardNumber = removeSpacesAndHyphens(cardNumber);
  }

  if (hasAnyPrefix(spacelessCardNumber, CARD_PREFIXES.PREFIXES_AMERICAN_EXPRESS)) {
    return CARD.AMERICAN_EXPRESS;
  }

  if (hasAnyPrefix(spacelessCardNumber, CARD_PREFIXES.PREFIXES_DISCOVER)) {
    return CARD.DISCOVER;
  }

  if (hasAnyPrefix(spacelessCardNumber, CARD_PREFIXES.PREFIXES_JCB)) {
    return CARD.JCB;
  }

  if (hasAnyPrefix(spacelessCardNumber, CARD_PREFIXES.PREFIXES_DINERS_CLUB)) {
    return CARD.DINERS_CLUB;
  }

  if (hasAnyPrefix(spacelessCardNumber, CARD_PREFIXES.PREFIXES_VISA)) {
    return CARD.VISA;
  }

  if (hasAnyPrefix(spacelessCardNumber, CARD_PREFIXES.PREFIXES_MASTERCARD)) {
    return CARD.MASTERCARD;
  }

  if (hasAnyPrefix(spacelessCardNumber, CARD_PREFIXES.PREFIXES_UNIONPAY)) {
    return CARD.UNIONPAY;
  }

  return CARD.UNKNOWN;
}
