import { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object, string } from 'prop-types';

//  Components
import CountryAutosuggestion from 'Modules/authentication/components/CountryAutosuggestion';

//  Actions/Selectors
import { updateDataSyncAction } from 'Store/actions/genericActions';
import * as modalSelectors from 'Modules/modal/store/selectors';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';

//  Other resources
import { getCountryName } from 'Util/GeneralUtils';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA } from 'Store/reducerProperties';

const CountryContainer = (props) => {
  const { selectedBounty } = props;

  useEffect(() => {
    if (selectedBounty.jobInfo && selectedBounty.jobInfo.countryCode) {
      populateCountryName();
    }
  }, []);

  const populateCountryName = () => {
    const countryName = getCountryName(selectedBounty.jobInfo.countryCode);
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { countryName });
  };

  const onCountryChange = (e, { newValue }) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { countryName: newValue });
  };

  return (
    <CountryAutosuggestion
      onCountryChange={onCountryChange}
      countryName={props.countryName}
      isJoblio
    />
  );
};

CountryContainer.propTypes = {
  countryName: string.isRequired,
  selectedBounty: object.isRequired,
  updateDataSyncAction: func.isRequired,
};

const mapStateToProps = (state) => ({
  countryName: modalSelectors.inputsData(state).countryName || '',
  selectedBounty: selectedBountySelector(state) || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryContainer);
