import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, string } from 'prop-types';
import { EditorState } from 'draft-js';
import { useIntl } from 'react-intl';

//  Components
import SurveyOptionsContainer from 'Modules/posts/add/containers/SurveyOptionsContainer';
import MarkdownEditor from 'Modules/posts/add/components/MarkdownEditor';

//  Actions/Selector
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import { updateDataSyncAction } from 'Store/actions/genericActions';

//  Other resources
import { descriptionField } from 'Constants/fields';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA, MODAL_SURVEY_OPTIONS_DATA } from 'Store/reducerProperties';
import { getAsSurvey, isSurveyType } from 'Models/bounty/Bounty';
import { getDescription } from 'Util/BountyUtils';
import { MCQ } from 'Constants/bounty/bountyType';
import { convertToEditorStateObj, prepareMarkdownText } from 'Modules/posts/add/utils/markdownEditorUtils';
import useDebounce from 'Util/AppUtils';

const DescriptionContainer = ({ bountyType, hasSurvey }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const selectedBounty = useSelector(selectedBountySelector);

  const descriptionProps = descriptionField(bountyType);
  const [editorStateObj, setEditorStateObj] = useState(EditorState.createEmpty());
  const [survey, setSurvey] = useState(null);

  const debouncedStoreUpdate = useDebounce(editorStateObj, 700);

  useEffect(() => {
    if (debouncedStoreUpdate) {
      dispatch(updateDataSyncAction(
        MODAL_NAMESPACE,
        MODAL_INPUTS_DATA,
        { description: prepareMarkdownText(editorStateObj) },
      ));
    }
  }, [debouncedStoreUpdate]);

  useEffect(() => {
    if (selectedBounty && Object.keys(selectedBounty).length) {
      const asSurvey = selectedBounty && isSurveyType(selectedBounty.type) ? getAsSurvey(selectedBounty) : null;
      const surveyOptions = {};

      if (asSurvey && asSurvey.options) {
        asSurvey.options.forEach((option) => {
          surveyOptions[option.code] = option;
        });
      }

      setSurvey(asSurvey);

      const description = getDescription({ survey: asSurvey, bounty: selectedBounty }) || '';

      setEditorStateObj(convertToEditorStateObj(description));

      dispatch(updateDataSyncAction(MODAL_NAMESPACE, MODAL_SURVEY_OPTIONS_DATA, surveyOptions));
    }
  }, [selectedBounty?.description]);

  return (
    <>
      <MarkdownEditor
        {...descriptionProps}
        editorState={editorStateObj}
        handleChange={setEditorStateObj}
      />

      {bountyType === MCQ && <p>{intl.formatMessage({ id: 'bounty.checkCorrectAnswer' })}</p>}

      {hasSurvey && (
        <SurveyOptionsContainer survey={survey} bountyType={bountyType} />
      )}
    </>
  );
};

DescriptionContainer.propTypes = {
  bountyType: string.isRequired,
  hasSurvey: bool,
};
DescriptionContainer.defaultProps = {
  hasSurvey: false,
};

export default DescriptionContainer;
