import appLogoText from 'Assets/img/logo/appLogoText.png';

export default {
  logo: {
    appLogo: appLogoText,
    loggedOutLogo: appLogoText,
  },
  stripeKey: 'pk_live_8n9cBjQ64DllvAPgH09bL0Dk',
  SENTRY_CONFIG: {
    dsn: 'https://9424823d10f144509217bcbfff64bad6@o116454.ingest.sentry.io/5253020',
  },
};
