import { cloneDeep } from 'lodash';
import { NotificationManager } from 'react-notifications';

//  Models
import { isAllowedToMarkAsOfficial, isDraftState, isAllowedToPin } from 'Models/bounty/BountyState';
import { isCreatedByMe } from 'Models/bounty/Bounty';
import { newComment } from 'Models/Comment';
import { addValueOfPoints } from 'Models/Reward';
import * as CART_TYPE from 'Models/CardType';
import { BOUNTY } from 'Models/EntityType';

//  Services
import {
  getOutboundBountyRef,
  getBountyRef,
  updateSortKeys,
  isSharedWithCompany,
  updateLocalBounty,
} from 'Services/bounty/BountyService';
import { getInboundBountyRef } from 'Services/bounty/CommonService';
import { setMyPoints } from 'Services/UserService';

//  Other resources
import * as PointUtils from 'Util/pointsUtils';
import { updateBadges } from 'Util/BountyUtils';
import { addToQueue } from 'Services/Api';
import { queues } from 'Constants/queues';
import {
  firebaseGetUserCommentsRef,
  firebaseGetUserStreamsRef,
  firebaseGetMyFavorites,
  firebaseGetCurrentUser,
  firebaseGetTimestamp,
} from 'Services/FirebaseService';
import { getFavoritePriority, getPriorityForComment } from 'Util/keyUtils';
import { convertObjToArray } from 'Util/helpers';
import { updateDataAsyncAction } from 'Store/actions/genericActions';
import { USER_STREAMS_NAMESPACE } from 'Store/namespaces';
import * as reducerProperties from 'Store/reducerProperties';

function getBountyRefs(bounty, user) {
  const bountyRefs = [];

  if (!isDraftState(bounty.state)) {
    bountyRefs.push(getInboundBountyRef(bounty, user));
  }

  if (isCreatedByMe(bounty, user)) {
    bountyRefs.push(getOutboundBountyRef(bounty));
  }

  return bountyRefs;
}

function getCommentRef(entityType, entityId, commentId) {
  const commentsRef = entityType === CART_TYPE.BOUNTY
    ? firebaseGetUserCommentsRef().child('bounties').child(entityId)
    : firebaseGetUserCommentsRef().child('responses').child(entityId);

  if (!commentId) {
    return commentsRef.push();
  }

  return commentsRef.child(commentId);
}

/* **** Comments **** */
const increaseComments = ({
  userId, bountyId, commentCount, adjustment = 1,
}) => () => (
  firebaseGetUserStreamsRef(userId).child(bountyId).child('bounty/stats/commentCount').set(commentCount + adjustment)
);

const getBountyComments = (bountyId) => (dispatch) => (
  firebaseGetUserCommentsRef()
    .child('bounties')
    .child(bountyId)
    .on('value', (dataSnapshot) => {
      const dataSnapshotVal = convertObjToArray(dataSnapshot.val()).reverse();
      dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_COMMENTS, dataSnapshotVal));
    })
);

const addBountyComments = (bountyId, comment) => () => {
  const commentRef = firebaseGetUserCommentsRef().child('bounties').child(bountyId).push();
  comment.id = commentRef.key; // eslint-disable-line

  commentRef.setWithPriority(comment, getPriorityForComment(comment));
  addToQueue(queues.ADD_COMMENT, { comment, commentId: commentRef.key });
};

/* **** Likes **** */
function adjustLikesStat({ bounty, adjustment }) {
  const bountyClone = cloneDeep(bounty);

  if (!bountyClone.stats) {
    bountyClone.stats = { likesCount: 0 };
  }

  const currentLikes = bountyClone.stats.likesCount || 0;
  bountyClone.stats.likesCount = currentLikes + adjustment;

  return bountyClone;
}

const toggleLikedState = (bounty, user) => (dispatch) => {
  let bountyClone = cloneDeep(bounty);
  const adjustment = bounty.likedAt ? -1 : 1;

  bountyClone.likedAt = bountyClone.likedAt ? null : new Date().getTime();
  bountyClone = adjustLikesStat({ bounty: bountyClone, adjustment });

  if (!isSharedWithCompany(bounty)) {
    const bountyRefs = getBountyRefs(bountyClone, user);
    bountyRefs.forEach((ref) => ref.child('stats/likesCount').set(bountyClone?.stats?.likesCount || 0));
  }

  getBountyRef(bounty, user).child('likedAt').set(bountyClone.likedAt);
  addToQueue(queues.LIKE_BOUNTY, { bountyId: bountyClone.id, likedAt: bountyClone.likedAt });

  dispatch(updateLocalBounty(bountyClone));
};

const rateBounty = ({
  bounty, text, rateAmt, availablePts,
}) => async (dispatch) => {
  const bountyClone = cloneDeep(bounty);
  const currentUser = firebaseGetCurrentUser();

  const pointCurrency = availablePts.currency;
  const pts = {
    amount: rateAmt,
    currency: pointCurrency,
  };

  bountyClone.rating = PointUtils.addRating(bountyClone.rating, currentUser.uid, pts);
  const rating = PointUtils.getPoints(bountyClone.rating, pointCurrency);

  const refs = getBountyRefs(bounty, currentUser);
  refs.forEach((ref) => {
    ref.child('rating').child('points').child(pointCurrency).set(rating);
    ref.child('rating').child('userRatings').set(bountyClone.rating.userRatings);
  });

  setMyPoints({ amount: availablePts.amount - pts.amount, currency: pointCurrency }); // this method gives perm denied

  const comment = await newComment(bountyClone.myself, text, BOUNTY, bountyClone.id);
  comment.reward = addValueOfPoints(pts);

  dispatch(addBountyComments(bountyClone.id, comment));
};

const changeBountyRatingValue = ({ bounty, user, ratingValue }) => () => {
  getBountyRef(bounty, user).child('rating').child('starPointsValue').set(ratingValue);
  addToQueue(queues.CHANGE_BOUNTY_RATING_VALUE, { bountyId: bounty.id, starPointsValue: ratingValue });
};

const toggleBadgeType = ({
  bounty, user, badgeType, mark,
}) => () => {
  const bountyClone = cloneDeep(bounty);
  const isAllowedToPerformOp = isAllowedToMarkAsOfficial(bountyClone.state);

  if (!isAllowedToPerformOp) {
    return NotificationManager.warning(`Cannot edit badges when ${bountyClone.state}`);
  }

  const ref = getBountyRef(bountyClone, user);
  bountyClone.badges = updateBadges(bountyClone, badgeType, mark);
  ref.child('badges').set(bountyClone.badges);
  addToQueue(queues.BOUNTY_EDIT_BADGE, { bountyId: bountyClone.id, badgeType, mark });
};

const pinToTop = ({ bounty, user, pin }) => () => {
  // TODO: remove after new interface for jobs will be implemented
  const bountyClone = cloneDeep(bounty);
  const isAllowedToPerformOp = isAllowedToPin(bountyClone.state);

  if (!isAllowedToPerformOp) {
    return NotificationManager.warning(`Cannot edit badges when ${bountyClone.state}`);
  }

  const ref = getBountyRef(bountyClone, user);
  const pinnedAt = pin ? firebaseGetTimestamp() : null;
  ref.child('pinnedAt').set(pinnedAt);

  updateSortKeys(bountyClone, user);
  addToQueue(queues.PIN_BOUNTY, { bountyId: bountyClone.id, pin, pinnedAt });
};

const addToFavorites = ({ ownerId, bounty, creatorId }) => (dispatch) => {
  const me = firebaseGetCurrentUser();
  const now = firebaseGetTimestamp();

  if (!isSharedWithCompany(bounty)) {
    getBountyRef(bounty, me).child('favoritedAt').set(now);
  }

  const prio = getFavoritePriority(new Date().getTime());
  const key = {
    cardType: CART_TYPE.BOUNTY,
    creatorId,
    bountyId: bounty.id,
    outbound: !!(bounty.outbound || bounty.isOutbound),
    '.priority': prio,
  };

  firebaseGetMyFavorites({ ownerId, userId: me.uid }).child(bounty.id).setWithPriority(key, prio);
  addToQueue(queues.ADD_FAVORITE, { bountyId: bounty.id, favoritedAt: now });

  dispatch(updateLocalBounty({ ...bounty, favoritedAt: now }));
};

const removeFromFavorites = ({ ownerId, bounty }) => (dispatch) => {
  const me = firebaseGetCurrentUser();

  if (!isSharedWithCompany(bounty)) {
    getBountyRef(bounty, me).child('favoritedAt').remove();
  }

  firebaseGetMyFavorites({ ownerId, userId: me.uid }).child(bounty.id).remove();
  addToQueue(queues.REMOVE_FAVORITE, { bountyId: bounty.id });

  dispatch(updateLocalBounty({ ...bounty, favoritedAt: null }));
};

const shareBountyLink = (bountyId) => () => getBountyCommentsRef(bountyId);

const getBountyCommentsRef = (bountyId) => firebaseGetUserCommentsRef().child('bounties').child(bountyId);

const getBountyCommentsQuery = (bountyId) => getBountyCommentsRef(bountyId).orderByPriority();

const checkIfCommentedOnBounty = (bountyId) => {
  const me = firebaseGetCurrentUser();

  return new Promise((resolve) => (
    getBountyCommentsRef(bountyId)
      .orderByChild('creator/id')
      .equalTo(me.uid)
      .once('value', (dataSnapshot) => {
        resolve(dataSnapshot.exists());
      })
  ));
};

const getBountyCommentStats = ({ userId, bountyId }) => new Promise((resolve) => (
  firebaseGetUserStreamsRef(userId)
    .child(bountyId)
    .child('bounty/stats/commentCount')
    .once('value', (dataSnapshot) => (resolve(dataSnapshot.val())))
));

export const deleteComment = (comment) => {
  const commentRef = getCommentRef(comment.commentType, comment.entityId, comment.id);
  commentRef.remove();

  addToQueue(queues.DELETE_COMMENT, { commentId: comment.id });
};

export {
  toggleLikedState,
  toggleBadgeType,
  changeBountyRatingValue,
  rateBounty,
  pinToTop,
  getBountyComments,
  increaseComments,
  addBountyComments,
  addToFavorites,
  removeFromFavorites,
  shareBountyLink,
  getBountyCommentsQuery,
  checkIfCommentedOnBounty,
  getBountyCommentStats,
};
