export const RESPONSES = '/responses';
export const REQUESTS = '/requests';
export const CLIENTS = '/clients';
export const EMPLOYEES = '/employees';
export const USERS = '/users';
export const ROLES_ALLOWED = '/roles/allowed';
export const APPLICANTS = '/applicants';
export const CLIENTS_BY_ORG_TYPE = `${CLIENTS}/:orgType`;
export const USERS_PRIVATE = '/users/:userId/private';
export const USERS_DATA = '/users/:userId/data';
export const NOTIFICATION_API_ROUTE = '/notify';
export const LOOKUPS_API_ROUTE = '/lookups/:lookuptype';
export const COUNTRIES_ROUTE = '/system/countries';
export const AUDIT = '/system/audit/log';
export const CURRENCIES_ROUTE = '/system/currencies';
export const SYSTEM_INFO_BY_COUNTRY_ROUTE = '/system/countries/:countryCode';
export const POST_SHARE_URL = '/bounties/:bountyId/shares';
export const ADD_BLACK_LISTED = '/users/:userId/blacklist';
export const REMOVE_BLACK_LISTED = '/users/:userId/unblacklist';
export const USER_COMMENTS = '/users/:userId/comments';
