//  Async
export const USER_STREAMS = 'userStreams';
export const BOUNTY_DETAILS = 'bountyDetails';
export const REFRESH_BOUNTY_DETAILS = 'refreshBountyDetails';
export const ANSWER_DETAILS = 'answerDetails';
export const BOUNTY_ANSWERS = 'bountyAnswers';
export const BOUNTY_ANSWERS_COMMENTS = 'bountyAnswersComments';
export const BOUNTY_COMMENTS = 'bountyComments';
export const ANSWER_COMMENTS = 'answerComments';
export const USER_SENTS = 'userSents';
export const COMPANY_SENTS = 'companySents';
export const COMPANY_SUB_SENTS = 'companySubSents';
export const COMPANY_SENTS_DETAILS = 'companySentDetails';
export const SELECTED_BOUNTY = 'selectedBounty';
export const SUB_BOUNTIES = 'subBounties';
export const GROUPS = 'groups';
export const GROUP_PROFILE = 'groupProfile';
export const GROUP_INVITES = 'groupInvites';
export const GROUP_MEMBERS = 'groupMembers';
export const EMPLOYEES = 'employees';
export const EDITING_RESPONSE = 'editingResponse';
export const REPLY_BOUNTY = 'replyBounty';

// My Account
export const LOGGED_USER = 'user';
export const USER_ACCOUNT = 'userAccount';
export const USER_TRANSACTIONS = 'userTransactions';
export const USER_PROFILE = 'userProfile';
export const USER_AVATAR_URL = 'userAvatarURL';
export const USER_ENDORSEMENTS = 'userEndorsements';
export const USER_PRIVATE_INFO = 'userPrivateInfo';
export const USER_PIN = 'userPIN';
export const USER_AUDIT_INFO = 'userAuditInfo';

// Auth
export const RESET_STATE = 'RESET_STATE';
export const REGISTRATION_ACTIVE_STEP = 'signupStep';

export const RANKINGS_DATA = 'rankingsData';
export const ACCESS_TOKEN = 'accessToken';

// Orders
export const ORDERS = 'orders';
export const ORDER = 'orderDetails';

//  Common
export const ACCOUNT_INFO = 'accountInfo';
export const USER_DATA = 'userData';
export const COMPANY_SETTINGS = 'companySettings';
export const SYSTEM_SETTINGS = 'systemSettings';
export const ORG_TYPE_SETTINGS = 'orgTypeSettings';
export const SYSTEM_INFO = 'systemInfo';
export const IS_DRAWER_OPEN = 'isDrawerOpen';

//  Sync
export const BOUNTY_DEFAULT_FILTER = 'defaultFilter';
export const BOUNTY_DEFAULT_SORT = 'defaultSort';
export const MODAL_SETTINGS = 'settings';
export const DRAWER_SETTINGS = 'drawerSettings';
export const MODAL_INPUTS_DATA = 'inputsData';
export const MODAL_INPUT_LANGUAGE = 'inputLanguage';
export const MODAL_OPTIONS_DATA = 'optionsData';
export const MODAL_VALIDATION_ERRORS = 'validationErrors';
export const MODAL_SURVEY_OPTIONS_DATA = 'surveyOptions';
export const MODAL_RATING = 'rating';
export const MODAL_IS_LOADING = 'isLoading';
export const MODAL_SELECTED_POST = 'selectedPost';
export const MODAL_SELECTED_BOUNTIES = 'selectedBounties';
export const MODAL_SELECTED_MEMBERS = 'selectedMembers';
export const MODAL_AVAILABLE_PTS = 'availablePts';
export const PROMOTION_TO_ATTACH = 'selectedPromotions';
export const PRODUCT_TO_ATTACH = 'selectedProducts';

//  Attachments
export const ATTACHMENTS = 'attachmentsList';
export const REMOVED_ATTACHMENTS = 'removedAttachments';

// Applicants
export const APPLICANTS_IS_LOADING = 'isLoading';

// Companies
export const COMPANIES_IS_LOADING = 'isLoading';
