export const GroupType = {
  GENERIC: 'GENERIC',
  INFLUENCERS: 'INFLUENCERS',
  ORDER_HANDLING: 'ORDER_HANDLING',
  SUPPORT: 'SUPPORT',
  UNKNOWN: 'UNKNOWN',
};

export const JoiningStrategy = {
  INVITE_ONLY: 'INVITE_ONLY',
  NEEDS_APPROVAL: 'NEEDS_APPROVAL',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  GROUPS_ONLY: 'GROUPS_ONLY',
  UNKNOWN: 'UNKNOWN',
};

export function getJoinStrategyAsEnum(joinStrategy) {
  return joinStrategy || JoiningStrategy.INVITE_ONLY;
}

export function getJoininingStrategyText(joinStrategy) {
  if (joinStrategy === JoiningStrategy.INVITE_ONLY) {
    return 'lbl_join_policy_invitation';
  }

  if (joinStrategy === JoiningStrategy.NEEDS_APPROVAL) {
    return 'lbl_join_policy_approval';
  }

  if (joinStrategy === JoiningStrategy.OPEN) {
    return 'lbl_join_policy_freely';
  }

  return null;
}
