import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { IntlProvider } from 'react-intl';
import { FiltersProvider } from 'Contexts/filtersContext';
import customTheme from 'Util/theme/customTheme';
import { IntlConsume } from 'Util/IntlMessages';
import { LanguageContext } from 'Contexts/languagesContext';
import { splitLanguage, browserLanguage, mergeWithEngLang } from 'Util/languagesHelpers';
import { useEffect, useState, useMemo } from 'react';
import { bool, node } from 'prop-types';

const InterfaceLocaleProvider = ({ children, rtlLayout }) => {
  const [locale, setLocale] = useState(browserLanguage());
  const currentAppLocale = mergeWithEngLang(browserLanguage());
  const [currentAppLocaleMessages, setCurrentAppLocaleMessages] = useState(currentAppLocale.messages);

  const theme = customTheme;
  theme.direction = rtlLayout ? 'rtl' : 'ltr';

  const languageContext = useMemo(() => ({
    locale,
    setLocale,
  }), [locale]);

  useEffect(() => {
    const langFromContext = mergeWithEngLang(locale);
    setCurrentAppLocaleMessages(langFromContext.messages);
  }, [locale]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LanguageContext.Provider value={languageContext}>
          <IntlProvider
            locale={splitLanguage(locale)}
            messages={currentAppLocaleMessages}
          >
            <FiltersProvider>
              <IntlConsume
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
              >
                {children}
              </IntlConsume>
            </FiltersProvider>
          </IntlProvider>
        </LanguageContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

InterfaceLocaleProvider.defaultProps = {
  rtlLayout: false,
};

InterfaceLocaleProvider.propTypes = {
  children: node.isRequired,
  rtlLayout: bool,
};

export default InterfaceLocaleProvider;
