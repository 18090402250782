export const stash = 'stash';
export const youkno = 'youkno';
export const dripdrop = 'dripdrop';
export const belong = 'belong';
export const joblio = 'joblio';
export const viitorul = 'viitorul';
export const propatrimonioro = 'propatrimonioro';
export const fcdinamoge = 'fcdinamoge';
export const backstage = 'backstage';
export const fcwiltz = 'fcwiltz';
export const stiinta = 'stiinta';
export const banfield = 'banfield';

export const paginationItemsPerPage = 10;

export const KEY_SEP = '-';
export const FB_MATCH_ALL = '\\uf8ff';
export const buttons = {
  cancel: 'Cancel',
  publish: 'Publish',
  submit: 'SUBMIT',
  submitSm: 'Submit',
  delete: 'DELETE',
  keepGroup: 'KEEP GROUP', // lbl_keep_group
  save: 'SAVE',
  saveSm: 'Save',
  ok: 'Ok',
  send: 'SEND',
  create: 'Create',
  edit: 'Edit',
  continue: 'Continue',
};

export const SHOW_IN_TYPES = {
  HOME: 'HOME',
  EXPLICIT: 'EXPLICIT',
  UNKNOWN: 'UNKNOWN',
};
