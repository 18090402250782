import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { object, string } from 'prop-types';
import { NotificationManager } from 'react-notifications';

import IntlMessage from 'Util/IntlMessages';
import undercoverImage from 'Assets/img/avatar/ic_undercover.png';
import behalfOfImage from 'Assets/img/avatar/ic_onBehalfOf.png';
import { getAvatarProperties } from 'Util/helpers';
import { canSeeRealIdentitySelector } from 'Store/selectors/permissions';
import { NO_AVATAR_URL, PLACEHOLDER_AVATAR_URL } from 'Constants/constants';

const AvatarComponent = ({ authorToDisplay, size, selectedUser }) => {
  const canSeeRealIdentity = useSelector(canSeeRealIdentitySelector);
  const history = useHistory();

  const { anon, avatar } = authorToDisplay;
  const isAnonAuthor = anon || false;
  const isAnonAvatar = avatar && avatar.split(':')[0] === 'avatar';
  const showLetters = isAnonAvatar || isAnonAuthor;
  const avatarImage = getAvatarImage(showLetters, authorToDisplay);
  const avatarProperties = getAvatarProperties(avatar || '');

  const onAvatar = () => {
    if (!authorToDisplay.id && !selectedUser?.user?.collocutor?.id) {
      return NotificationManager.warning(<IntlMessage id="invalid_user" />);
    }

    if (!isAnonAuthor || canSeeRealIdentity) {
      return history.push(`/applicant/profile/${selectedUser?.user?.collocutor?.id || authorToDisplay.id}`);
    }

    return NotificationManager.warning(<IntlMessage id="msg_cannot_access_profile" />);
  };

  return (avatarImage ? (
    <div role="presentation" onClick={onAvatar}>
      <Avatar
        alt="avatar-img"
        src={avatarImage}
        className={`${size} rounded-circle border-primary avatar-bg d-flex`}
      />
    </div>
  ) : (
    <div role="presentation" onClick={onAvatar}>
      <Avatar
        style={{ backgroundColor: avatarProperties.color, color: 'white' }}
        className="size-40 rounded-circle d-flex"
      >
        {avatarProperties.initials}
      </Avatar>
    </div>
  ));
};

const getAvatarImage = (showLetters, { avatar, isOnBehalfOf }) => {
  const isUndercoverAvatar = avatar && avatar.split(':')[1] === 'undercover';
  const avatarImage = showLetters ? '' : avatar;

  if (isUndercoverAvatar) return undercoverImage;
  if (isOnBehalfOf) return behalfOfImage;
  if (!showLetters) return !avatarImage || avatarImage.includes(NO_AVATAR_URL) ? PLACEHOLDER_AVATAR_URL : avatarImage;

  return null;
};

AvatarComponent.propTypes = {
  authorToDisplay: object,
  selectedUser: object,
  size: string,
};

AvatarComponent.defaultProps = {
  authorToDisplay: {},
  selectedUser: {},
  size: 'size-40',
};

export default AvatarComponent;
