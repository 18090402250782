import { cloneDeep } from 'lodash';
import { getMyUserId } from 'Services/BaseService';
import {
  firebaseGetCurrentlySignedInUser,
  getUserShoppingCartsRef,
  firebaseGetCurrentUser,
} from 'Services/FirebaseService';
import { getPriorityForProduct } from 'Util/keyUtils';
import { valueOfProductInfo } from 'Models/shoppingCart/ShoppingCart';
import { postApiData } from 'Services/Api';

export function getMyShoppingCartId() {
  return `cart_${getMyUserId()}`;
}

export function getShoppingCartRef(shoppingCartId) {
  const me = firebaseGetCurrentUser();
  return getUserShoppingCartsRef(me.uid).child(shoppingCartId);
}

export function getShoppingCartProductsRef(shoppingCartId) {
  const cartId = shoppingCartId || getMyShoppingCartId();
  return getShoppingCartRef(cartId).child('products');
}

export function getShoppingCartProductsInfo(shoppingCartId) {
  return getShoppingCartProductsRef(shoppingCartId).orderByPriority();
}

export function addToMyShoppingCart({ productBounty, sku }) {
  const productInfo = valueOfProductInfo({ productBounty, sku });
  addToShoppingCart(getMyShoppingCartId(), productInfo);
}

function addToShoppingCart(shoppingCartId, productInfo) {
  const productInfoClone = cloneDeep(productInfo);
  const ref = getShoppingCartProductsRef(shoppingCartId).push();
  productInfoClone.id = ref.key;

  ref.setWithPriority(productInfoClone, getPriorityForProduct(productInfoClone));
}

export function removeFromShoppingCart({ shoppingCartId, productInfo }) {
  if (!productInfo) {
    return;
  }

  getShoppingCartProductsRef(shoppingCartId).child(productInfo.id).remove();
}

export function updateProductQuantity({ shoppingCartId, productInfo, newQuantity }) {
  if (!productInfo) {
    return;
  }

  if (newQuantity === 0) {
    return removeFromShoppingCart({ shoppingCartId, productInfo });
  }

  const changes = {
    updatedAt: new Date().getTime(),
    quantity: newQuantity,
  };

  getShoppingCartProductsRef(shoppingCartId).child(productInfo.id).update(changes);
}

export function saveShoppingCartDeliveryAddress(deliveryAddress) {
  getShoppingCartRef(getMyShoppingCartId()).child('deliveryAddress').set(deliveryAddress);
}

export function saveShoppingCartPaymentInfo(cardInfo) {
  getShoppingCartRef(getMyShoppingCartId()).child('paymentInfo').set(cardInfo);
}

export function saveShoppingCartCoupon(coupon) {
  getShoppingCartRef(getMyShoppingCartId()).child('coupon').set(coupon);
}

export function saveShoppingCartNegotiatedAmount(negotiatedAmount) {
  getShoppingCartRef(getMyShoppingCartId()).child('negotiatedAmount').set(negotiatedAmount);
}

export function calculateOrderSummary(cart) {
  const cartId = getMyShoppingCartId();
  cart.id = cartId; // eslint-disable-line

  return new Promise((resolve, reject) => {
    firebaseGetCurrentlySignedInUser()
      .then((token) => {
        postApiData(`/shoppingcart/${cartId}/summary`, token, cart)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
  });
}

export function placeOrder(cart) {
  const cartId = getMyShoppingCartId();
  cart.id = cartId; // eslint-disable-line

  return new Promise((resolve, reject) => {
    firebaseGetCurrentlySignedInUser()
      .then((token) => {
        postApiData(`/shoppingcart/${cartId}/order`, token, cart)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
  });
}
