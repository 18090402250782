import { ACTIONS } from 'Store/actions/jobsActions';
import { BOUNTY_SEARCH_FIELDS } from 'Constants/filtersByType';
import { formatSortQueryProp } from 'Common/utils/sort';
import { SORT_DIRECTION } from 'Constants/sort';
import { ACTIVE_STATE } from 'Constants/bounty/bountyState';

const lsLimitName = 'jobsLimit';
const defaultLimit = Number(localStorage.getItem(lsLimitName)) || 10;

const initialState = {
  list: [],
  isLoading: false,
  reqProps: {
    start: 0,
    limit: defaultLimit,
    order: formatSortQueryProp('MODIFIED_DATE', SORT_DIRECTION.DESC),
    query: '',
  },
  searchFields: [
    BOUNTY_SEARCH_FIELDS.TITLE,
    BOUNTY_SEARCH_FIELDS.DESCRIPTION,
  ],
  filters: {
    state: [ACTIVE_STATE],
  },
  total: 0,
};

const jobsReducer = (state = initialState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.GET_LIST:
      return {
        ...state,
        list: payload,
      };

    case ACTIONS.ERASE_LIST:
      return {
        ...state,
        list: [],
      };

    case ACTIONS.UPDATE_JOB_STATE_LOCALLY:
      return {
        ...state,
        list: state.list.map((j) => (j.bountyInfo.id === payload.id ? { ...j, state: payload.state } : j)),
      };

    case ACTIONS.REMOVE_JOB_LOCALLY:
      return {
        ...state,
        list: state.list.filter((j) => j.bountyInfo.id !== payload),
        total: state.total - 1,
      };

    case ACTIONS.UPDATE_REQ_PROPS:
      if (payload.limit) {
        localStorage.setItem(lsLimitName, payload.limit);
      }
      return {
        ...state,
        reqProps: {
          ...state.reqProps,
          ...payload,
        },
      };

    case ACTIONS.UPDATE_SEARCH_FIELDS:
      return {
        ...state,
        searchFields: payload,
      };

    case ACTIONS.UPDATE_FILTERS:
      return {
        ...state,
        reqProps: {
          ...state.reqProps,
          ...payload.reqProps,
        },
        filters: {
          ...state.filters,
          ...payload.filters,
        },
      };

    case ACTIONS.RESET_FILTERS:
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
      };

    case ACTIONS.UPDATE_TOTAL:
      return {
        ...state,
        total: payload,
      };

    case ACTIONS.LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default jobsReducer;
