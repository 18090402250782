import { ADMIN_ROLE_NAME, USER_ROLE_NAME } from 'Constants/roles';
import { getClientId } from 'Util/api/apiHelpers';

const ROLE_SEP = ',';

export const DEFAULT_USER_ROLES = [USER_ROLE_NAME];

/**
 * Parse a string to get a list of role names
 * ex: "USER, ADMIN" => [USER, ADMIN]
 * @param roles {String} contains user roles as a string separated by comma
 * @returns {Array} - A list with role names
 */
export function parseRolesList(roles) {
  const roleList = [];

  if (!roles) {
    return roleList;
  }

  const parts = roles.split(ROLE_SEP);

  parts.forEach((role) => {
    const currentRole = role ? role.toUpperCase() : null;

    if (currentRole && !roleList.includes(currentRole)) {
      roleList.push(currentRole);
    }
  });

  return roleList;
}

export function renderRolesList(roles) {
  return roles.toString();
}

export function isAdmin(roles = '') {
  const rolesAsList = parseRolesList(roles);
  return rolesAsList.includes(ADMIN_ROLE_NAME);
}

export function isSystemAdmin(roles = '') {
  const rolesAsList = parseRolesList(roles);
  return rolesAsList.includes(ADMIN_ROLE_NAME) && +getClientId() === 101;
}
