import { func, instanceOf, oneOfType, shape, string, node } from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';

const SwitchField = (props) => {
  const {
    name, label, labelPlacement, formik, ...remainingProps
  } = props;
  const { value } = formik.getFieldMeta(name);

  return (
    <FormControlLabel
      className="ml-0"
      control={(
        <Switch
          {...remainingProps}
          color="primary"
          checked={!!value}
          name={name}
          onChange={formik.handleChange}
        />
      )}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};

SwitchField.propTypes = {
  name: string.isRequired,
  label: oneOfType([string, node]).isRequired,
  labelPlacement: string,
  formik: shape({
    handleChange: func.isRequired,
    errors: instanceOf(Object),
    touched: instanceOf(Object),
  }).isRequired,
};

SwitchField.defaultProps = {
  labelPlacement: 'start',
};

export default SwitchField;
