import { getApiData } from 'Services/Api';
import { getQueryString } from 'Util/GeneralUtils';

export const ApplicationsService = {
  async getApplicants(options) {
    const queryString = getQueryString(options);

    // TODO: rename end-point to '/applications'.
    return getApiData(`/applications${queryString}`);
  },
};

export default ApplicationsService;
