import cs from 'classnames';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase, Avatar, Collapse, ClickAwayListener, MenuItem } from '@mui/material';

import IntlMessage from 'Util/IntlMessages';
import { logoutUserFromFirebase } from 'Modules/authentication/store/operations';
import { userDataSelector } from 'Store/selectors/settings';
import * as styles from './styles.module.scss';

const Profile = () => {
  const [open, setOpen] = useState(false);
  const { myself } = useSelector(userDataSelector).data || {};
  const history = useHistory();
  const dispatch = useDispatch();

  const toggle = () => setOpen(!open);
  const close = () => setOpen(false);
  const goTo = (to) => () => {
    history.push(to);
    close();
  };
  const onLogout = () => {
    localStorage.clear();
    dispatch(logoutUserFromFirebase({ showNotification: true }));
    history.push('/');
  };

  return (
    <div className={styles.profile}>
      <ButtonBase onClick={toggle} disableRipple>
        <p className={cs(styles.profileItemTitle, { [styles.profileItemActive]: open })}>
          {myself?.name}
        </p>
        <Avatar className={styles.profileItemAvatar} alt={myself?.name} src={myself?.avatar} />
        <i className={`icon-arrow-${open ? 'up-2' : 'down-1'} ${styles.profileItemIcon}`} />
      </ButtonBase>
      <Collapse
        in={open}
        className={styles.profileContent}
        mountOnEnter
        unmountOnExit
      >
        <ClickAwayListener onClickAway={close}>
          <ul>
            <MenuItem onClick={goTo('/settings')} className={styles.profileContentItem}>
              <i className="icon-setting1" />
              <IntlMessage id="sidebar.settings" tagName="span" />
            </MenuItem>
            <MenuItem onClick={onLogout} className={styles.profileContentItem}>
              <i className="icon-logout1" />
              <IntlMessage id="widgets.logOut" tagName="span" />
            </MenuItem>
          </ul>
        </ClickAwayListener>
      </Collapse>
    </div>
  );
};

export default Profile;
