import { NotificationManager } from 'react-notifications';

// Actions/Selectors
import { updateDataSyncAction } from 'Store/actions/genericActions';
import * as reducerProperties from 'Store/reducerProperties';
import { MODAL_NAMESPACE, USER_STREAMS_NAMESPACE } from 'Store/namespaces';
import * as modalSelectors from 'Modules/modal/store/selectors';
import { getLoggedUser } from 'AuthSelectors';
import { userDataSelector, companySettingsSelector, settingsSelector } from 'Store/selectors/settings';
import { attachmentsSelector, removedAttachmentsSelector } from 'Modules/attachments/store/selectors';
import { openModal, closeModal } from 'Modules/modal/store/operations';

// Helpers
import { updateOrderInParent, onEditBounty, createBounty } from 'Services/bounty/BountyService';
import { isEditableState } from 'Models/bounty/BountyState';
import { isValidBounty } from 'Modules/posts/add/utils/createPostActivity';
import { prepareBountyObjectForCreate, prepareBountyObjectForEdit } from 'Modules/posts/add/utils/addBountyHelpers';
import { getApiData } from 'Services/Api';
import { LOOKUPS_API_ROUTE } from 'Constants/apiRoutes';
import { formatApiRoute } from 'Util/api/apiHelpers';
import { MODAL_VALIDATION_ERRORS } from 'Store/reducerProperties';
import { validate } from 'Util/validation/validate';
import { getConstraintsByBountyType } from 'Util/validation/createBounty';
import { hasAtLeastOneAttachment, hasExactlyOneImage } from 'Util/BountyUtils';
import IntlMessages from 'Util/IntlMessages';
import { JOB } from 'Constants/bounty/bountyType';
import { jobBonusModal } from '../../add/utils/modalConfig';

const getBountyState = (state) => ({
  inputsData: modalSelectors.inputsData(state) || {},
  optionsData: modalSelectors.optionsData(state) || {},
  surveyOptions: modalSelectors.surveyOptionsData(state) || {},
  user: getLoggedUser(state).data || {},
  userData: userDataSelector(state).data || {},
  companySettings: companySettingsSelector(state).data || {},
  attachments: attachmentsSelector(state) || [],
  removedAttachments: removedAttachmentsSelector(state) || [],
  settings: settingsSelector(state) || {},
});

function validateBounty({
  data, bountyType, dispatch, settings, userData, attachments,
}) {
  const constraints = getConstraintsByBountyType(bountyType);
  const errors = validate(data, constraints, true);

  if (errors && Object.keys(errors).length) {
    dispatch(updateDataSyncAction(MODAL_NAMESPACE, MODAL_VALIDATION_ERRORS, errors));
    return false;
  }

  if (hasExactlyOneImage(bountyType) && attachments?.length !== 1) {
    NotificationManager.error(<IntlMessages id="bounty.required.oneImage" />);
    return false;
  }

  if (hasAtLeastOneAttachment(bountyType) && !attachments?.length) {
    NotificationManager.error(<IntlMessages id="bounty.required.atLeastOneMedia" />);
    return false;
  }

  // TODO: try adding all validations to CREATE_BOUNTY_CONSTRAINTS
  return isValidBounty({
    bountyType,
    savedData: data,
    settings,
    userData,
  });
}
/**
 * Creates bounty
 * @param optionConfig {Object}
 * @param bountyId {number}
 * @param parentType {string}
 * @param withValidation {bool}
 * @returns {function(*): Promise<any>}
 */
export const handleCreateBounty = ({
  optionConfig, bountyId, parentBounty = null, withValidation,
}) => (
  (dispatch, getState) => {
    const {
      inputsData,
      optionsData,
      surveyOptions,
      userData,
      attachments,
      settings,
    } = getBountyState(getState());

    return new Promise((resolve, reject) => {
      if (withValidation) {
        const data = {
          ...inputsData,
          ...optionsData,
          surveyOptions,
        };
        const isValid = validateBounty({
          data, bountyType: optionConfig.bountyType, dispatch, settings, userData, attachments,
        });

        if (!isValid) {
          return reject();
        }
      }

      if (optionConfig.bountyType === JOB) {
        const { bonus } = inputsData;

        const noBonus = !bonus || !bonus.money.amount;
        if (noBonus) {
          return dispatch(openModal(jobBonusModal({
            close: () => {
              dispatch(closeModal());
              reject();
            },
          })));
        }
      }

      const bounty = prepareBountyObjectForCreate({
        inputsData,
        optionsData,
        surveyOptions,
        userData,
        settings,
        optionConfig,
        parentBounty: bountyId ? { id: bountyId } : parentBounty,
      });

      dispatch(createBounty(bounty, null))
        .then((response) => resolve(response))
        .catch((err) => reject(new Error(err)));
    });
  }
);

export const handleEditBounty = ({ bounty, withValidation = false }) => (dispatch, getState) => {
  const {
    inputsData,
    optionsData,
    surveyOptions,
    user,
    userData,
    attachments,
    settings,
  } = getBountyState(getState());

  return new Promise((resolve, reject) => {
    if (isEditableState(bounty.state)) {
      if (withValidation) {
        const data = {
          ...inputsData,
          ...optionsData,
          surveyOptions,
        };
        const isValid = validateBounty({
          data,
          bountyType: bounty.type,
          dispatch,
          settings,
          userData,
          attachments,
        });

        if (!isValid) {
          return reject(new Error('Form is invalid!'));
        }
      }

      const newBounty = prepareBountyObjectForEdit({
        bounty,
        userData,
        optionsData,
        surveyOptions,
        settings,
        inputsData,
      });

      dispatch(onEditBounty({ newBounty, oldBounty: bounty, user }))
        .then((editedBounty) => {
          resolve(editedBounty);
        })
        .catch((err) => reject(new Error(err)));
    } else {
      reject(new Error('Unable to edit'));
    }
  });
};

export const onReorderPosts = ({
  subBounties, oldIndex, newIndex, bounty,
}) => (dispatch) => {
  const subBountiesClone = subBounties.slice();
  const newBounty = subBountiesClone.splice(oldIndex, 1)[0];

  subBountiesClone.splice(newIndex, 0, newBounty);

  updateOrderInParent(bounty, subBountiesClone)
    .then(() => {
      dispatch(updateDataSyncAction(
        USER_STREAMS_NAMESPACE,
        reducerProperties.SUB_BOUNTIES,
        { data: subBountiesClone },
      ));
    })
    .catch(() => {
      NotificationManager.error('Unable to reorder posts');
    });
};

export function getJobInfoByType(type, queryParams) {
  const queryObject = {
    ...queryParams,
    limit: 1000,
  };
  const path = formatApiRoute({ endpoint: LOOKUPS_API_ROUTE, params: { lookuptype: type }, queryParams: queryObject });
  return getApiData(path);
}
