// Actions/Reducers
import { GET_BOUNTY_ANSWERS_COMMENTS, GET_ANSWERS_COMMENTS } from 'Store/actionTypes';
import { USER_STREAMS_NAMESPACE } from 'Store/namespaces';
import { getHandlers } from 'Store/reducers/helpers';

//  Others
import {
  BOUNTY_DETAILS, ANSWER_DETAILS, USER_STREAMS, BOUNTY_ANSWERS, BOUNTY_COMMENTS, BOUNTY_DEFAULT_FILTER,
  BOUNTY_DEFAULT_SORT, BOUNTY_ANSWERS_COMMENTS, SUB_BOUNTIES,
} from 'Store/reducerProperties';

// initial state
const INIT_STATE = {
  [BOUNTY_DETAILS]: {},
  [SUB_BOUNTIES]: {},
  [ANSWER_DETAILS]: {
    comments: [],
  },
  [USER_STREAMS]: {},
  [BOUNTY_ANSWERS]: {},
  [BOUNTY_ANSWERS_COMMENTS]: {},
  [BOUNTY_COMMENTS]: [],
  [BOUNTY_DEFAULT_FILTER]: '',
  [BOUNTY_DEFAULT_SORT]: '',
};

function getBountyAnswersComments(state, payload) {
  const { bountyResponseId, bountyAnswersComments } = payload;
  const answerIndex = state[BOUNTY_ANSWERS].data.findIndex((item) => item.response.id === bountyResponseId);

  if (state[BOUNTY_ANSWERS].data && state[BOUNTY_ANSWERS].data[answerIndex]) {
    state[BOUNTY_ANSWERS].data[answerIndex].bountyAnswersComments = bountyAnswersComments; // eslint-disable-line
  }

  return state;
}

function getAnswerComments(state, payload) {
  state[ANSWER_DETAILS].comments = payload; // eslint-disable-line
  return state;
}

const handlersReducer = {
  ...getHandlers(USER_STREAMS_NAMESPACE),
  [GET_BOUNTY_ANSWERS_COMMENTS]: getBountyAnswersComments,
  [GET_ANSWERS_COMMENTS]: getAnswerComments,
};

export default function userStreamsReducer(state = INIT_STATE, action = {}) {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}
