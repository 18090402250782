const PRIVATE_TYPE = 'PRIVATE';
const VISIBLE_TYPE = 'VISIBLE';
const USER_TYPE = 'USER';
const DEFAULT_TYPE = 'DEFAULT';
const UNKNOWN_TYPE = 'UNKNOWN';

const DEFAULT = {
  forced: true,
  visibilityType: VISIBLE_TYPE,
};

function asEnum(type) {
  return type || DEFAULT_TYPE;
}

function getVisibilityTypeAsEnum(visibilityMode) {
  return asEnum(visibilityMode?.visibilityType);
}

export {
  PRIVATE_TYPE,
  VISIBLE_TYPE,
  DEFAULT_TYPE,
  USER_TYPE,
  UNKNOWN_TYPE,
  DEFAULT,
  getVisibilityTypeAsEnum,
};
