import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Drawer, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// Components
import ContinueButtonComponent from 'Common/components/ContinueButtonComponent';

//  Actions/Selectors
import { drawerSettingsSelector } from 'Modules/modal/store/selectors';
import { closeDrawer } from 'Modules/modal/store/operations';
import { setDataAction } from 'Store/actions/genericActions';
import { MODAL_NAMESPACE, SENTS_NAMESPACE } from 'Store/namespaces';
import { DRAWER_SETTINGS, SELECTED_BOUNTY } from 'Store/reducerProperties';
import { drawerContent } from 'Modules/modal/utils/drawerUtils';
import ConfirmationDialog from './ConfirmationDialog';

const DrawerComponent = () => {
  const dispatch = useDispatch();

  const {
    cancelBtn,
    content,
    handleClose,
    handleSubmit,
    isOpen,
    submitBtn,
    title,
  } = useSelector(drawerSettingsSelector);

  const [showAlert, setShowAlert] = useState(false);
  const classes = useStyles();
  const Container = drawerContent[content] || null;

  const onClose = () => {
    dispatch(setDataAction(MODAL_NAMESPACE, DRAWER_SETTINGS, {}));
    dispatch(setDataAction(SENTS_NAMESPACE, SELECTED_BOUNTY, {}));

    if (handleClose) {
      return handleClose();
    }

    dispatch(closeDrawer());
  };

  const handleOnClose = () => {
    setShowAlert(false);
    onClose();
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={Boolean(isOpen)}
        onClose={() => setShowAlert(true)}
        style={{ zIndex: 800 }}
        classes={{
          paper: classes.paper,
        }}
      >
        <Box p={3} height="100%">
          <Box display="flex" alignItems="center">
            {title && <Typography variant="h6">{title}</Typography>}
          </Box>
          <Box height="90%">
            {Container && <Container />}
          </Box>
          <Box>
            {handleClose && (
            <ContinueButtonComponent
              color="secondary"
              name={cancelBtn}
              onSubmit={onClose}
            />
            )}
            {handleSubmit && (
            <ContinueButtonComponent
              name={submitBtn}
              onSubmit={handleSubmit}
            />
            )}
          </Box>
        </Box>
      </Drawer>
      {showAlert && (
      <div style={{ zIndex: 2000 }}>
        <ConfirmationDialog onSuccess={handleOnClose} onDecline={() => setShowAlert(false)} />
      </div>
      ) }
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: '300px',
    marginRight: 0,
    borderTopLeftRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0.5),
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    '& svg': {
      fill: theme.palette.text.primary,
    },
  },
  actionButtons: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
}));

export default DrawerComponent;
