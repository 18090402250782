export function getAction(type) {
  return type ? type[0] : null;
}

export function getQueue(type) {
  if (type) {
    return type[1] || type[0];
  }

  return null;
}
