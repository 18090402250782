import { createSelector } from 'reselect';

import { ListType, ListDef, getEffectiveOp, isEffectiveFilterByType, getVisibilityType, getShownInAsEnum, useDefaultsFrom } from 'Models/Lists';
import * as OpManager from 'Models/OpManager';
import { SEE_BUY_SELL, SEE_FAVORITES, SEE_MARKET } from 'Models/Op';
import * as ProductFlavour from 'Models/ProductFlavour';
import * as ProductDefaults from 'Models/ProductDefaults';
import { getEffectiveCustomLists, isAllowed } from 'Models/Settings';
import { isAllowedByRole } from 'Models/OpManager';
import { parseRolesList } from 'Models/RoleDef';
import { USER_TYPE } from 'Models/VisibilityMode';
import { SHOW_IN_TYPES } from 'Constants/common';
import {
  BUY_SELL,
  EDGE_CHALLENGES,
  EDGE_COACHINGS,
  EDGE_IN_FOCUS,
  FAVORITES,
  AUTOSUGGESTIONS,
  MARKET,
  ACADEMIA,
  NONE,
  STREAM,
  WEBVIEW,
  listTypeData,
} from 'Modules/posts/bounty/constants/listType';
import * as typesOfBounty from 'Constants/bounty/bountyType';
import { userDataSelector, settingsSelector } from 'Store/selectors/settings';

const productFlavour = ProductFlavour.getCurrent();
const productDefaults = ProductDefaults.getDefaults(productFlavour);

const ALLOWED_MULTI_LIST = [MARKET, ACADEMIA];

export const builtinList = {
  [STREAM]: {
    id: STREAM,
    code: null,
    kind: null,
    rank: 100,
    name: 'Stream',
    emptyListMsg: 'No bounties yet.',
    bountyTypes: null,
    userSpecial: null,
    companySpecial: null,
    op: null,
  },
  [EDGE_IN_FOCUS]: {
    id: EDGE_IN_FOCUS,
    code: listTypeData[EDGE_IN_FOCUS].code,
    kind: null,
    rank: 110,
    name: 'In Focus',
    emptyListMsg: 'No In Focus posts yet.',
    bountyTypes: typesOfBounty.TMOB_COACHING,
    userSpecial: null,
    companySpecial: null,
    op: null,
  },
  [EDGE_CHALLENGES]: {
    id: EDGE_CHALLENGES,
    code: listTypeData[EDGE_CHALLENGES].code,
    kind: null,
    rank: 120,
    name: 'Challenges',
    emptyListMsg: 'No challenges yet.',
    bountyTypes: typesOfBounty.TMOB_CHALLENGE,
    userSpecial: null,
    companySpecial: null,
    op: null,
  },
  [EDGE_COACHINGS]: {
    id: EDGE_COACHINGS,
    code: listTypeData[EDGE_COACHINGS].code,
    kind: null,
    rank: 130,
    name: 'Coachings',
    emptyListMsg: 'No coaching posts yet.',
    bountyTypes: typesOfBounty.TMOB_COACHING,
    userSpecial: null,
    companySpecial: null,
    op: null,
  },
  [FAVORITES]: { // we want it to always be the last
    id: FAVORITES,
    code: listTypeData[FAVORITES].code,
    kind: null,
    rank: 1001,
    name: 'Bookmarks',
    emptyListMsg: 'No bookmarks yet.',
    bountyTypes: null,
    userSpecial: null,
    companySpecial: null,
    op: null,
  },
  [BUY_SELL]: {
    id: BUY_SELL,
    code: listTypeData[BUY_SELL].code,
    kind: null,
    rank: 140,
    name: 'Buy & Sell',
    emptyListMsg: 'The market is empty',
    bountyTypes: typesOfBounty.CLASSIFIED,
    userSpecial: null,
    companySpecial: null,
    op: null,
  },
  [MARKET]: {
    id: MARKET,
    code: listTypeData[MARKET].code,
    kind: null,
    rank: 150,
    name: 'Market',
    emptyListMsg: 'No goods on the market.',
    bountyTypes: null,
    userSpecial: null,
    companySpecial: null,
    op: null,
  },
  [AUTOSUGGESTIONS]: {
    id: AUTOSUGGESTIONS,
    code: listTypeData[AUTOSUGGESTIONS].code,
    kind: null,
    rank: 170,
    name: 'Auto-suggestions',
    emptyListMsg: 'No auto-suggestions created.',
    bountyTypes: null,
    userSpecial: listTypeData[AUTOSUGGESTIONS].userSpecial,
    companySpecial: listTypeData[AUTOSUGGESTIONS].companySpecial,
    op: null,
    url: null,
  },
  [WEBVIEW]: {
    id: WEBVIEW,
    code: listTypeData[WEBVIEW].code,
    kind: listTypeData[WEBVIEW].kind,
    rank: 190,
    name: null,
    emptyListMsg: null,
    bountyTypes: null,
    url: listTypeData[WEBVIEW].url,
    userSpecial: null,
    companySpecial: null,
    op: null,
  },
};

function getBuiltinListDef(type, rank) {
  const builtinListDef = builtinList[type] || null;

  if (builtinListDef) {
    return {
      ...builtinListDef,
      rank: rank !== null ? rank : builtinListDef.rank,
    };
  }

  return null;
}

/**
 * Get the default list defs
 * @param defaultList {Array}
 * @returns {Array}
 */
function getDefaultStreamListDefs(defaultList) {
  const list = [];

  defaultList.forEach((item) => {
    const builtinData = getBuiltinListDef(item.name, null);

    if (builtinData) {
      list.push(builtinData);
    }
  });

  return list;
}

/**
 * Sort a list of defs
 * The ones without a rank go to bottom, ordered by id (type of 'id' is {String})
 * @param list {Array}
 * @returns {Array}
 */
function sortDefsList(list) {
  list.sort((item1, item2) => {
    if (item1.rank === null && item2.rank === null) {
      return item1.id.localeCompare(item2.id);
    }

    if (item1.rank === null) {
      return 1;
    }

    if (item2.rank === null) {
      return -1;
    }

    if (item1.rank - item2.rank === 0) {
      return item1.id.localeCompare(item2.id);
    }

    return item1.rank - item2.rank;
  });

  return list;
}

/**
 * Check if a list of defs contain a specific list type
 * @param listDefs {Array}
 * @param type {String} list type
 * @returns {bool}
 */
function contains(listDefs, type) {
  return listDefs.find((item) => item?.id === type);
}

export const getStreamListDefsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => getStreamListDefs(settings, userData.data),
);

export const getHomeListDefs = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => getViewableListDefs({ settings, userData: userData.data, shownIn: SHOW_IN_TYPES.HOME }),
);

/**
 * Generate the list of defs depend on the company settings
 * (show in options list)
 * @param settings {Object}
 * @param userData {Object}
 * @returns {Array}
 */
export function getStreamListDefs(settings, userData) {
  const listDefs = [];
  const customLists = getEffectiveCustomLists(settings);
  let hasMarket = false;

  if (Object.keys(customLists).length) {
    Object.keys(customLists).forEach((key) => {
      if (getVisibilityType(customLists[key]?.visibilityInfo) === USER_TYPE) {
        return;
      }

      const { name } = ListDef.getTypeAsEnum(customLists[key].id);

      if (name === NONE) {
        return;
      }

      const builtinData = getBuiltinListDef(name, customLists[key].rank);

      if (builtinData) {
        listDefs.push(useDefaultsFrom(customLists[key], builtinData));
      } else {
        listDefs.push(customLists[key]);
      }
    });
  } else {
    const defaultList = productDefaults.listTypes;
    listDefs.push(...getDefaultStreamListDefs(defaultList));
  }

  if (!listDefs.length) {
    listDefs.push(listTypeData[STREAM]);
  }

  if (!contains(listDefs, MARKET) && isAllowed({ op: SEE_MARKET.name, settings, userData })) {
    listDefs.push(getBuiltinListDef(MARKET, 0));
    hasMarket = true;
  }

  if (!contains(listDefs, BUY_SELL) && isAllowed({ op: SEE_BUY_SELL.name, settings, userData })) {
    listDefs.push(getBuiltinListDef(BUY_SELL, hasMarket ? 1 : 0));
  }

  if (!contains(listDefs, BUY_SELL)
    && OpManager.isBountyTypeAllowed({ bountyType: typesOfBounty.AUTOSUGGESTION, settings, userData })) {
    listDefs.push(getBuiltinListDef(AUTOSUGGESTIONS, null));
  }

  if (!contains(listDefs, FAVORITES) && isAllowed({ op: SEE_FAVORITES.name, settings, userData })) {
    listDefs.push(getBuiltinListDef(FAVORITES, null));
  }

  // filter out the ones that are not allowed base on op

  sortDefsList(listDefs)
    .forEach((item, index) => {
      const op = getEffectiveOp(item.id, item.op);

      if (op && !isAllowed({ op: op.name, settings, userData })) {
        listDefs.splice(index, 1);
      }
    });

  return listDefs;
}

function isListPostable(item, userData) {
  if (!ListType.isPostable(item)) {
    return false;
  }

  if (item?.postabilityInfo?.postabilityRoles) {
    return isAllowedByRole(
      parseRolesList(item.postabilityInfo.postabilityRoles),
      parseRolesList(userData?.roles),
      true,
    );
  }

  return true;
}

/**
 * Get the list of defs depend on the user configuration and bounty type
 * @param streamList {Object}
 * @param userData {Object}
 * @param bountyType {String}
 * @param includeFilters {boolean|null}
 * @returns {Array}
 */
export function getPostableListDefs(streamList, userData, bountyType = null, includeFilters = false) {
  const list = [];

  streamList.forEach((item) => {
    if (item.id !== 'APPROVALS' // TODO: check what is missing from the below function
      && isListPostable(item, userData)
      && (bountyType === null || ListDef.isApplicable(item, bountyType))
      && (includeFilters || !isEffectiveFilterByType(item))) {
      list.push(item);
    }
  });

  return list;
}

export function hasMultipleLists(bountyType, settings, userData) {
  const streamLists = getStreamListDefs(settings, userData);
  return getPostableListDefs(streamLists, userData, bountyType).length > 1;
}

export function isViewable(list, userData, allowMulti) {
  if (!ListDef.isViewable(list)) {
    return false;
  }

  if (ListDef.isMulti(list)) {
    return allowMulti || ALLOWED_MULTI_LIST.includes(list.id);
  }

  if (list?.visibilityInfo) {
    return isAllowedByRole(
      parseRolesList(list.visibilityInfo?.visibilityRoles),
      parseRolesList(userData?.roles),
      true,
    );
  }

  return true;
}

export function getViewableListDefs({ settings, userData, shownIn }) {
  const listDefs = getStreamListDefs(settings, userData);

  const filteredList = listDefs.filter((list) => (
    !(!isViewable(list, userData, false) || (shownIn && shownIn !== getShownInAsEnum(list.shownIn)))
  ));

  if (shownIn === 'HOME' && !filteredList.length) {
    return listTypeData[STREAM];
  }

  return filteredList;
}

export function getHomeSublists({ list, streamLists }) {
  if (list?.sublists) {
    const sublist = Object
      .keys(list.sublists)
      .reduce((acc, key) => {
        const listDef = streamLists.find((item) => item.id === key);

        if (listDef) {
          acc[key] = { ...list.sublists[key], ...(listDef || {}) };
        }

        return acc;
      }, {});

    return Object.values(sublist).sort((a, b) => {
      if (a.order > b.order) {
        return 1;
      }

      return b.order > a.order ? -1 : 0;
    });
  }

  return [];
}
