import moment from 'moment';
import { DATE_FORMAT, MONTH_FORMAT, NO_AVATAR_URL, PLACEHOLDER_AVATAR_URL } from 'Constants/constants';

import * as avatarConstants from 'Modules/posts/bounty/constants/avatar';
import { ATTACHMENT_TYPE } from 'Constants/attachment';
import { setOutbound } from 'Models/bounty/Bounty';

// Function to convert hex to rgba
export function hexToRgbA(hex, alpha) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`; // eslint-disable-line
  }
  throw new Error('Bad Hex');
}

// Text Truncate
export function textTruncate(str, length = 100, ending = '...') {
  if (str && str.length > length) {
    return str.substring(0, length) + ending;
  }
  return str;
}

// Users Chat List Last Message Truncate
export function lastMessageTruncate(str, length = 100, ending = '...') {
  if (str.length > length) {
    return str.substring(0, length - 3) + ending;
  }
  return str;
}

// Get The Date
export function getTheDate(timestamp, format) {
  const formatDate = format || DATE_FORMAT;
  return moment(timestamp).format(formatDate);
}

// Get Date and Time
export function getDateAndTime(timestamp, format) {
  const formatDate = format || DATE_FORMAT;
  return moment(timestamp).format(formatDate);
}

// Get Current Month
export function getCurrentMonth() {
  return moment().format(MONTH_FORMAT);
}

// Convert Date To Timestamp
export function convertDateToTimeStamp(date, format) {
  const formatDate = format || 'YYYY-MM-DD';
  return moment(date, formatDate).unix();
}

export function getUserAge(date) {
  return moment().diff(date, 'years');
}

// Function to return current app layout
export function getAppLayout(url) {
  const location = url.pathname;
  const path = location.split('/');
  return path[1];
}

export function convertObjToArray(data) {
  return data ? Object.values(data) : [];
}

export function streamSnapshotToArray(dataSnapshot, isOutbound) {
  const dataSnapshotVal = [];

  dataSnapshot.forEach((child) => {
    const data = child.val();
    const cardType = Object.keys(data)[0];

    if (!cardType || data.dismissedAt || !data[cardType].id) {
      return null;
    }

    if (isOutbound) {
      const key = Object.keys(data)[0];
      data[key].metaInfo = setOutbound(data[key].metaInfo, true);
    }

    dataSnapshotVal.push(data);
  });

  return dataSnapshotVal;
}

export function splitStringAtCaretUp(str) {
  return str ? str.split('^') : '';
}

export function convertListsToDropdownOptions(companySettingsLists) {
  const lists = convertObjToArray(companySettingsLists);

  return lists.map((item) => ({
    value: item.id,
    label: item.name === 'Flow' ? 'Stream' : item.name,
    ...item,
  }));
}

export function getAvatar(url) {
  return (url && url.includes(NO_AVATAR_URL)) || !url
    ? PLACEHOLDER_AVATAR_URL
    : url;
}

// firebase provides small versions of avatars for users who use google/fb accounts
// too get full size images, urls need to be adopted
export function getFullSizeAvatar(url) {
  let avatar = getAvatar(url);

  if (avatar.includes('google')) {
    avatar = avatar.replace('=s96-c', '');
  }
  if (avatar.includes('facebook')) {
    avatar = `${avatar}?height=500`;
  }

  return avatar;
}

export function getScrollBarStyle() {
  return {
    height: 'calc(100vh - 50px)',
  };
}

export function getAuthorName(data) {
  if (data && data.author) {
    return data.author.name;
  }

  return (data && data.creator && data.creator.name) || '';
}

export function getAvatarProperties(avatar = '') {
  const options = avatar && (avatar.split('avatar:')[1] || '');

  if (!options) {
    return {};
  }

  const properties = options.split('&') || [];
  const avatarProperties = {};

  properties.forEach((option) => {
    const property = option.split('=');
    const [prop, value] = property;
    avatarProperties[prop] = prop === avatarConstants.KEY_COLOR ? `${avatarConstants.CODE_COLOR}${value}` : value;
  });

  return avatarProperties;
}

export function getProductBackgroundImage(product = {}) {
  return product.imageUrl ? { backgroundImage: `url(${product.imageUrl})` } : {};
}

export function getClassifiedBackgroundImage(attachments) {
  const firstImage = Object.values(attachments.attachments || {})
    .find((attachment) => attachment.type === ATTACHMENT_TYPE.IMAGE);

  return firstImage ? { backgroundImage: `url(${firstImage.url})` } : {};
}

export const currencyFormatter = ({ currency = 'USD', amount = 0, minimumFractionDigits = 2 }) => {
  const formatConfig = {
    style: 'currency',
    currency, // default to USD
    minimumFractionDigits,
    currencyDisplay: 'symbol',
  };

  return new Intl.NumberFormat('en', formatConfig).format(amount);
};

export const toggleValueInArray = (arr, value) => (arr.includes(value) ? arr.filter((v) => v !== value) : arr.concat(value));

export const bytesToSize = (bytes) => {
  const sizes = ['bytes', 'kb', 'mb', 'gb'];

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${(bytes / (1024 ** i)).toFixed(2)} ${sizes[i]}`;
};

export const stringToNumber = (str) => (str ? Number(str) : null);
