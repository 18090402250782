import { stateToMarkdown } from 'draft-js-export-markdown';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { EditorState } from 'draft-js';

export const convertToMarkdown = (editorStateObj = {}) => (
  editorStateObj.getCurrentContent && stateToMarkdown(editorStateObj.getCurrentContent())
);

export const convertToEditorStateObj = (markdown = '') => (
  EditorState.createWithContent(stateFromMarkdown(markdown))
);

export const prepareMarkdownText = (editorState) => {
  const convertToMD = convertToMarkdown(editorState).replace(/\u21b5/g, '').trim();
  // Editor inserts a nonEditable charCode of 8203, only when field is empty
  return convertToMD.length === 1 && convertToMD.charCodeAt(0) === 8203 ? '' : convertToMD;
};
