/**
 * Helpers Functions
 */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

import { QUEUES_BASE, QUEUES_INDEX } from 'Constants/queues';

const { auth, database } = firebase;

/**
 * Firebase Sign In With Token
 */
export const firebaseSignInWithCustomToken = (jwtToken) => auth().signInWithCustomToken(jwtToken);

/**
 * Firebase Get Chat Messages Ref
 */
export const firebaseGetChatMessagesRef = (roomId) => database().ref(`chatMessages/${roomId}`);

/**
 * Firebase Get User Chat Ref
 */
export const firebaseGetUserChatRef = (userId) => database().ref(`userChats/${userId}`);

/**
 * Firebase Get User Chat Room Ref
 */
export const firebaseGetUserChatRoomRef = (userId, roomId) => database().ref(`userChats/${userId}`).child(roomId);
export const firebaseGetUserDataRef = (userId) => database().ref(`userData/${userId}`);

export const getMyUserAddressesRef = (userId) => firebaseGetUserDataRef(userId).child('addresses');
export const getMyUserPaymentsRef = (userId) => firebaseGetUserDataRef(userId).child('payments');
export const getMySpecialsRef = (userId) => database().ref(`userSpecials/${userId}`);
export const getMyOrdersRef = (userId) => getMySpecialsRef(userId).child('orders');
export const getMyOrderRef = (userId, order) => getMyOrdersRef(userId).child(order.id).child('bounty');

/**
 * Firebase Get User Chat Ref
 */
export const firebaseMarkChatRoomAsUpdated = (roomId) => {
  const shard = '0'; // for now we use only one shard
  return database().ref('chatUpdates').child(shard).child(roomId)
    .set(database.ServerValue.TIMESTAMP);
};

/**
 * Firebase Get User Chat Ref
 */
export const firebaseGetClientsRef = (userPin) => database().ref('clients').child(userPin);

/**
 * Firebase Get User Streams Ref
 */
export const firebaseGetUserStreamsRef = (userId) => database().ref(`userStreams/${userId}`);

/**
 * Firebase Get User Streams Ref
 */
export const firebaseGetNotificationsRef = (userId) => database().ref(`userNotifications/${userId}`);

/**
 * Firebase Get User Streams Ref
 */
export const firebaseGetSentRef = (userId) => database().ref(`userSents/${userId}`);
export const firebaseGetSentSubsRef = (userId) => database().ref(`userSentSubs/${userId}`);

/**
 * Firebase Get Bounty Comments Ref
 */
export const firebaseGetUserCommentsRef = () => database().ref('userComments');

/**
 * Firebase Get Bounty Responses Ref
 */
export const firebaseGetUserResponsesRef = (ownerId) => database().ref(`bountyResponses/${ownerId}`);

export const firebaseGetCurrentUser = () => auth().currentUser || {};

/**
 * Firebase Get currently signed in user
 * The recommended way to get the current user is by setting an observer on the Auth object
 * Note: currentUser might also be null because the auth object has not finished initializing.
 * If you use an observer to keep track of the user's sign-in status, you don't need to handle this case
 */
export const firebaseGetCurrentlySignedInUser = () => (
  new Promise((resolve, reject) => {
    auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        user.getIdToken()
          .then((res) => resolve(res))
          .catch((error) => reject(error));
      }
    });
  })
);

export const firebaseQueuesRef = (QUEUE) => database().ref(QUEUES_BASE).child(QUEUE).child(QUEUES_INDEX);

export const firebaseGetMyAccountRef = (userId) => database().ref(`userAccounts/${userId}`);

export const firebaseGetMyTransactionsRef = (userId) => database().ref(`userTransactions/${userId}`);
export const firebaseGetUserBadges = (userId) => (
  database()
    .ref('userAccountExtras')
    .child(userId)
    .child('badges/stash')
);

export const firebaseGetUserListsRef = ({ ownerId, userId }) => database().ref(`userLists/${ownerId}/${userId}`);
export const firebaseGetMyFavorites = ({ ownerId, userId }) => (
  firebaseGetUserListsRef({ ownerId, userId }).child('favorites'));

export const firebaseGetMyProfileRef = (userId) => database().ref('userProfiles').child(userId);

export const firebaseGetUserDataAvatarRef = (userId) => firebaseGetUserDataRef(userId).child('myself').child('avatar');

export const firebaseGetUserRef = (userId) => database().ref('users').child(userId);

export const firebaseGetProductsRef = (promotionId) => (
  database().ref(`promotionalOffers/${promotionId}/products`)
);

export const firebaseGetPromotionalOffersRef = () => database().ref('promotionalOffers');

/**
 * Firebase Company Refs
 */
export const firebaseGetMyCompanySettingsRef = (companyId) => (
  database().ref('companies').child(companyId).child('settings'));
export const firebaseGetCompanySents = (ownerId, type) => database().ref(`companySents/${ownerId}/${type}`);
export const firebaseGetCompanySentSubsRef = (ownerId, type) => database().ref(`companySentSubs/${ownerId}/${type}`);
export const firebaseGetOutboundBountyRef = (ownerId, type, bountyId) =>
  database().ref(`companySents/${ownerId}/${type}`)
    .orderByChild('bounty/id')
    .equalTo(bountyId);

/**
 * Firebase User Refs
 */
export const getUserSpecialsRef = (userID) => database().ref(`userSpecials/${userID}`);

/**
 * Firebase subcards refs
 */
export const getMySubCardsRef = (userId) => database().ref(`userSubCards/${userId}`);
export const getStreamCardRef = (userId, cardId) => firebaseGetUserStreamsRef(userId).child(cardId);

export const getCompanySubCardsRef = (ownerId) => database().ref(`companySubCards/${ownerId}`);
export const getCompanySpecialsRef = (ownerId) => database().ref(`companySpecials/${ownerId}`);
export const getCompanyOrdersRef = (ownerId) => getCompanySpecialsRef(ownerId).child('orders');
export const getCompanyOrderRef = (ownerId, orderId) => (
  getCompanySpecialsRef(ownerId)
    .child(`orders/${orderId}`)
    .child('bounty'));

/**
 * Firebase Order Refs refs
 */
export const firebaseGetOrdersRefs = (userId) => getUserSpecialsRef(userId).child('orders');
export const firebaseGetOrderDetailsRef = (userId, bountyId) => (
  getUserSpecialsRef(userId)
    .child(`orders/${bountyId}`)
    .child('bounty'));

/**
 * Firebase responses refs
 */
export const getInboundResponsesRef = (ownerId, responseKey) => (
  database().ref(`bountyResponses/${ownerId}/${responseKey}`));
export const getSurveyRef = (ownerId, bountyId) => (database().ref(`/surveys/${ownerId}`).child(bountyId));

export const getSocialActionsRef = (userId) => {
  const currentUser = firebaseGetCurrentUser();
  return database().ref('/userSocials').child(currentUser.uid).child(userId);
};

export const getSocialActionStateRef = (userId, socialActionType) => (
  getSocialActionsRef(userId).child(socialActionType)
);

export const getMyBlocksRef = (userId) => (database().ref(`/userBlocks/${userId}`));

export const getUserShoppingCartsRef = (userId) => database().ref(`shoppingCarts/${userId}`);

export const firebasegetGroupMembers = (groupId) => database().ref('groupMembers').child(groupId);
export const firebasegetGetGroupMemberRef = (groupId, memberId) => firebasegetGroupMembers(groupId).child(memberId);
export const firebasegetGroupInvites = (groupId) => database().ref(`groupInvites/${groupId}`);
export const firebaseGetGroupsRef = () => database().ref('groups');
export const firebaseGetGroupRef = (groupId) => firebaseGetGroupsRef().child(groupId);
export const firebaseGetGroupStreamsRef = () => database().ref('groupStreams');
export const firebaseGetGroupSubCardsRef = () => database().ref('groupSubCards');

export const firebaseGetSystemSettingsRef = () => database().ref('system/system/settings');
export const firebaseGetSystemInfoRef = () => database().ref('system/info');
export const firebaseGetOrgTypeSettingsRef = (orgType) => (
  database().ref('/system/orgTypes')
    .child(orgType)
    .child('settings')
);

export const firebaseGetTimestamp = () => database.ServerValue.TIMESTAMP;
