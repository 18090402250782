import { object } from 'prop-types';
import { Rating } from '@mui/material';

//  Other resources
import { formatCreateDate } from 'Models/bounty/Bounty';

const Reviews = ({ reviews }) => {
  const getRewardValue = (reward) => (
    (reward && reward.points && reward.points.RATING && reward.points.RATING.amount) || 0
  );
  const getAuthorName = (creator) => (creator && creator.name) || '';

  return (
    Object.values(reviews).map(({ text, reward, creator, sentAt }) => (
      <div key={sentAt} className="mb-10">
        <Rating
          readOnly
          name="product-rating"
          size="small"
          value={getRewardValue(reward)}
        />
        <div>{text}</div>
        <span>
          -
          {getAuthorName(creator)}
          ,
          {formatCreateDate(sentAt)}
        </span>
      </div>
    ))
  );
};

Reviews.propTypes = {
  reviews: object.isRequired,
};

export default Reviews;
