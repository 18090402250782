import { Link } from 'react-router-dom';
import { ButtonBase } from '@mui/material';

import IntlMessage from 'Util/IntlMessages';
import * as styles from './styles.module.scss';

const ChatNotifications = () => (
  <div className={styles.chat}>
    <ButtonBase
      disableRipple
      size="large"
      aria-label="chat"
      className={`${styles.chatButton} no-shake`}
      LinkComponent={Link}
      to="/chats"
    >
      <i className={`icon-message-1 ${styles.chatIcon}`} />
      <IntlMessage id="title.messages" tagName="span" />
    </ButtonBase>
  </div>
);

export default ChatNotifications;
