import { useEffect, useRef, useState } from 'react';
import { merge } from 'lodash';

//  Other resources
import { withRouter, useHistory } from 'react-router-dom';
import * as CONFIGS from '../../config';
import {
  stash,
  youkno,
  dripdrop,
  belong,
  joblio,
  viitorul,
  propatrimonioro,
  fcdinamoge,
  backstage,
  fcwiltz,
  stiinta,
  banfield,
} from '../constants/common';

export const APP_CONFIGS = {
  [stash]: {
    appName: stash,
    config: CONFIGS.stashConfig,
  },
  [youkno]: {
    appName: youkno,
    config: CONFIGS.youknoConfig,
  },
  [backstage]: {
    appName: backstage,
    config: CONFIGS.youknoConfig,
  },
  [dripdrop]: {
    appName: dripdrop,
    config: CONFIGS.dripdropConfig,
  },
  [belong]: {
    appName: belong,
    config: CONFIGS.belongConfig,
  },
  [joblio]: {
    appName: joblio,
    config: CONFIGS.joblioConfig,
  },
  [viitorul]: {
    appName: viitorul,
    config: CONFIGS.viitorulConfig,
  },
  [propatrimonioro]: {
    appName: propatrimonioro,
    config: CONFIGS.propatrimonioroConfig,
  },
  [fcdinamoge]: {
    appName: fcdinamoge,
    config: CONFIGS.fcdinamogeConfig,
  },
  [fcwiltz]: {
    appName: fcwiltz,
    config: CONFIGS.fcwiltzConfig,
  },
  [stiinta]: {
    appName: stiinta,
    config: CONFIGS.stiintaConfig,
  },
  [banfield]: {
    appName: banfield,
    config: CONFIGS.banfieldConfig,
  },
};

export function getApplicationConfig() {
  const { hostname } = window.location;
  const isDev = process.env.NODE_ENV === 'development';

  const runningApp = Object.values(APP_CONFIGS).find(({ appName }) => hostname.indexOf(appName) > -1);
  const appConfig = runningApp?.config || (isDev ? CONFIGS.youknoConfig : {});

  return merge(CONFIGS.commonConfig, appConfig);
}

export function buildAgentString() {
  return `${AppConfig.FLAVOR}/web`;
}

export function useIsMountedRef() {
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    return () => { isMountedRef.current = false; };
  });
  return isMountedRef;
}

export const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return children || null;
});

export default function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value],
  );

  return debouncedValue;
}

export const goTo = (to) => () => {
  const history = useHistory();

  history.push(to);
};

export const AppConfig = {
  ...getApplicationConfig(),
  navCollapsed: false, // Sidebar collapse
  darkMode: false, // Dark Mode
  boxLayout: false, // Box Layout
  rtlLayout: false, // RTL Layout
  miniSidebar: false, // Mini Sidebar
  enableSidebarBackgroundImage: false, // Enable Sidebar Background Image
  // sidebarImage: require('Assets/img/sidebar-4.jpg'),     // Select sidebar image
  isDarkSidenav: false, // Set true to dark sidebar
  enableThemeOptions: true, // Enable Theme Options
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'en',
  },
  enableUserTour: false,
  copyRightText: 'Belong © 2020 All Rights Reserved.', // Copy Right Text
  // light theme colors
  themeColors: {
    primary: '#00D014',
    secondary: '#677080',
    success: '#00D014',
    danger: '#FF3739',
    warning: '#FFB70F',
    info: '#00D0BD',
    dark: '#464D69',
    default: '#FAFAFA',
    greyLighten: '#A5A7B2',
    grey: '#677080',
    white: '#FFFFFF',
    purple: '#896BD6',
    edgeOrange: '#FFA800',
    yellow: '#FFA800',
  },
  // dark theme colors
  darkThemeColors: {
    darkBgColor: '#424242',
  },
};

export const APP_LOGO_SRC = process.env.NODE_ENV === 'production'
  ? '/assets/img/logo/appLogoText.png'
  : require(`../../artifacts/${AppConfig.id}/assets/img/logo/appLogoText.png`); // eslint-disable-line
