import { connect } from 'react-redux';
import { func } from 'prop-types';
import { Button } from '@mui/material';

// Actions/Selectors
import { closeModal, openModal } from 'Modules/modal/store/operations';
import { clearDataSyncAction } from 'Store/actions/genericActions';

// Others
import { addProductModal } from 'Modules/promotions/utils/modals';
import IntlMessage from 'Util/IntlMessages';

const propTypes = {
  openModal: func.isRequired,
  closeModal: func.isRequired,
};

const AddProductContainer = (props) => {
  const handleCloseModal = () => {
    props.closeModal();
  };

  const onAddProduct = () => {
    props.openModal(addProductModal({
      isCreateDialog: true,
      closeModal: handleCloseModal,
      handleSave: props.closeModal,
    }));
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        color="primary"
        className="my-20 text-white no-text-transform"
        onClick={onAddProduct}
      >
        <IntlMessage id="button.addProduct" />
      </Button>
    </div>
  );
};

AddProductContainer.propTypes = propTypes;

const mapDispatchToProps = {
  openModal,
  closeModal,
  clearDataSyncAction,
};

export default connect(null, mapDispatchToProps)(AddProductContainer);
