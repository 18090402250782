// Actions/Reducers
import { getHandlers } from 'Store/reducers/helpers';

//  Others
import { USER_ACCOUNT, USER_TRANSACTIONS } from 'Store/reducerProperties';
import { USER_ACCOUNT_NAMESPACE } from 'Store/namespaces';

// initial state
const INIT_STATE = {
  [USER_ACCOUNT]: {},
  [USER_TRANSACTIONS]: [],
};

const handlersReducer = getHandlers(USER_ACCOUNT_NAMESPACE);

export default function userAccountReducer(state = INIT_STATE, action = {}) {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}
