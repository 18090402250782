import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { object, func, string } from 'prop-types';
import { Close } from '@mui/icons-material';
import { IconButton, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useIntl } from 'react-intl';

//  Component
import ExpiryForm from 'Modules/posts/add/components/ExpiryForm';

//  Actions
import { updateDataSyncAction } from 'Store/actions/genericActions';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import * as modalSelectors from 'Modules/modal/store/selectors';

//  Other resources
import { closeOnField, expireFeatureField } from 'Constants/fields';
import { getExpiryComponents } from 'Modules/posts/add/utils/addBountyHelpers';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA } from 'Store/reducerProperties';
import { LOTTERY } from 'Constants/bounty/bountyType';

const propTypes = {
  inputData: object.isRequired,
  selectedBounty: object.isRequired,
  activityType: string.isRequired,
  bountyType: string.isRequired,
  updateDataSyncAction: func.isRequired,
  classes: object.isRequired,
};

const ExpiryContainer = (props) => {
  const intl = useIntl();
  const [showForm, setShowForm] = useState(false);
  const [prevExpiration, setPrevExpiration] = useState({});
  const {
    inputData, selectedBounty, activityType, classes, bountyType,
  } = props;
  const expirationProps = bountyType === LOTTERY ? closeOnField : expireFeatureField;

  const prepareExpiry = () => {
    const expiration = getExpiryComponents(selectedBounty, activityType);
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, expiration);
  };

  useEffect(() => {
    prepareExpiry();
  }, []);

  const clearExpiry = () => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { days: 0, minutes: 0, hours: 0 });
  };

  const cancelSetExpiry = () => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, prevExpiration);
    setPrevExpiration({});
    setShowForm(!showForm);
  };

  const toggleShowForm = () => {
    const { days, hours, minutes } = expirationProps;
    const isFormVisible = !showForm;

    if (isFormVisible) {
      setPrevExpiration({
        [hours.name]: inputData[hours.name] || 0,
        [days.name]: inputData[days.name] || 0,
        [minutes.name]: inputData[minutes.name] || 0,
      });
    }

    setShowForm(isFormVisible);
  };

  const getExpiryAsReadonly = () => {
    const { days, hours, minutes } = expirationProps;
    const expiryElements = [];

    if (!inputData[days.name] && !inputData[hours.name] && !inputData[minutes.name]) {
      return intl.formatMessage({ id: 'bounty.setDuration' });
    }

    if (inputData[days.name]) {
      expiryElements.push(intl.formatMessage({ id: 'bounty.expireDays' }, { count: inputData[days.name] }));
    }

    if (inputData[hours.name]) {
      expiryElements.push(intl.formatMessage({ id: 'bounty.expireHours' }, { count: inputData[hours.name] }));
    }

    if (inputData[minutes.name]) {
      expiryElements.push(intl.formatMessage({ id: 'bounty.expireMinutes' }, { count: inputData[minutes.name] }));
    }

    return expiryElements.join(', ');
  };

  const handleChange = ({ target: { name, value } }) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: value });
  };

  const canRemoveExpiryInfo = () => {
    const { days, hours, minutes } = expirationProps;
    return bountyType !== LOTTERY && !!(inputData[days.name] || inputData[hours.name] || inputData[minutes.name]);
  };

  const renderExpiryInfo = () => {
    const { days, hours, minutes } = expirationProps;
    const emptyExpiry = !inputData[days.name] && !inputData[hours.name] && !inputData[minutes.name];

    return (
      <div className={`MuiTypography-body2-306 ${classes.expiryContainer}`}>
        {!emptyExpiry && (
          <span className="black-color">
            {intl.formatMessage({ id: expirationProps.label })}
          </span>
        )}
        <span
          className="black-color cursor-pointer"
          role="presentation"
          onClick={toggleShowForm}
        >
          {getExpiryAsReadonly()}
        </span>
        {canRemoveExpiryInfo() && (
          <IconButton
            onClick={clearExpiry}
            size="small"
            className={`mr-10 ml-10 p-0 text-danger ${classes.toRight}`}
            aria-label="cancel"
          >
            <Close />
          </IconButton>
        )}
      </div>
    );
  };

  return (
    <Box mt={2}>
      {showForm
        ? (
          <ExpiryForm
            onSave={toggleShowForm}
            onCancel={cancelSetExpiry}
            handleChange={handleChange}
            fields={expirationProps}
            data={inputData}
          />
        )
        : renderExpiryInfo()}
    </Box>
  );
};

ExpiryContainer.propTypes = propTypes;

const useStyles = () => ({
  expiryContainer: {
    display: 'flex',
    marginTop: '10px',
  },
  toRight: {
    marginLeft: 'auto !important',
  },
});

const mapStateToProps = (state) => ({
  inputData: modalSelectors.inputsData(state) || {},
  selectedBounty: selectedBountySelector(state) || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(useStyles, { withTheme: true })(ExpiryContainer));
