import { shape, string, func, bool } from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { IconButton, Tooltip } from '@mui/material';
import { AppConfig } from 'Util/AppUtils';
import IntlMessages from 'Util/IntlMessages';

const DropzoneItemComponent = (props) => {
  const { item, handleUploadFiles, multiple } = props;
  const { icon, accept, label } = item;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUploadFiles,
    accept,
    multiple,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Tooltip title={<IntlMessages id={label} />} arrow>
        <IconButton size="large">
          <span
            style={{ color: AppConfig.themeColors.primary }}
            className="material-icons"
            aria-hidden="true"
          >
            {icon}
          </span>
        </IconButton>
      </Tooltip>
    </div>
  );
};

DropzoneItemComponent.propTypes = {
  item: shape({
    icon: string,
    accept: string,
    label: string,
  }).isRequired,
  handleUploadFiles: func.isRequired,
  multiple: bool.isRequired,
};

export default DropzoneItemComponent;
