import { cloneDeep } from 'lodash';

// Other resources
import {
  SET_DELIVERY_ADDRESS,
  SET_PAYMENT_METHOD,
  SET_COUPON,
  SET_NEGOTIATED_AMOUNT,
  SET_CART_PRODUCTS,
  CLEAR_CART,
  INCREASE_TOTAL_PRODUCTS_QUANTITY,
  DECREASE_TOTAL_PRODUCTS_QUANTITY,
  SET_TOTAL_PRODUCTS_QUANTITY,
} from 'Store/actionTypes';
import {
  saveShoppingCartCoupon,
  saveShoppingCartDeliveryAddress,
  saveShoppingCartPaymentInfo,
  saveShoppingCartNegotiatedAmount,
} from 'Services/ShoppingCart';

// initial state
const INIT_STATE = {
  cart: {
    state: 'ACTIVE',
    products: null,
    deliveryAddress: null,
    paymentInfo: null,
    coupon: null,
    negotiatedAmount: null,
  },
  totalProductsQuantity: 0,
};

function setDeliveryAddress(state, payload) {
  const newState = cloneDeep(state);
  newState.cart.deliveryAddress = payload;
  saveShoppingCartDeliveryAddress(payload);

  return newState;
}

function setPaymentMethod(state, payload) {
  const newState = cloneDeep(state);
  newState.cart.paymentInfo = payload;
  saveShoppingCartPaymentInfo(payload);

  return newState;
}

function setCoupon(state, payload) {
  const newState = cloneDeep(state);
  newState.cart.coupon = payload;
  saveShoppingCartCoupon(payload);

  return newState;
}

function setNegotiatedAmount(state, payload) {
  const newState = cloneDeep(state);
  newState.cart.negotiatedAmount = payload;
  saveShoppingCartNegotiatedAmount(payload);

  return newState;
}

function setProducts(state, payload) {
  const newState = cloneDeep(state);
  newState.cart.products = payload;

  return newState;
}

function increaseTotalProductsQuantity(state) {
  const newState = cloneDeep(state);
  newState.totalProductsQuantity += 1;

  return newState;
}

function decreaseTotalProductsQuantity(state, payload = 1) {
  const newState = cloneDeep(state);
  newState.totalProductsQuantity -= payload;

  return newState;
}

function setTotalProductsQuantity(state, payload) {
  const newState = cloneDeep(state);
  newState.totalProductsQuantity = payload;

  return newState;
}

function clearCart() {
  return INIT_STATE;
}

const handlersReducer = {
  [SET_DELIVERY_ADDRESS]: setDeliveryAddress,
  [SET_PAYMENT_METHOD]: setPaymentMethod,
  [SET_COUPON]: setCoupon,
  [SET_NEGOTIATED_AMOUNT]: setNegotiatedAmount,
  [SET_CART_PRODUCTS]: setProducts,
  [CLEAR_CART]: clearCart,
  [INCREASE_TOTAL_PRODUCTS_QUANTITY]: increaseTotalProductsQuantity,
  [DECREASE_TOTAL_PRODUCTS_QUANTITY]: decreaseTotalProductsQuantity,
  [SET_TOTAL_PRODUCTS_QUANTITY]: setTotalProductsQuantity,
};

export default function cartReducer(state = INIT_STATE, action = {}) {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}
