const VOUCHER_TYPES = {
  NONE: 'NONE',
  INTRINSIC: 'INTRINSIC',
  UNKNOWN: 'UNKNOWN',
};

export function isValid(voucher) {
  return voucher.expiresAt > new Date().getTime();
}

export function isIntrinsic(voucher) {
  return voucher.type === VOUCHER_TYPES.INTRINSIC;
}

export function negateVoucher(voucher) {
  return {
    ...voucher,
    negative: voucher.negative === null || !voucher.negative ? true : null,
  };
}

export function voucherToString({ type, id }) {
  return `${type}:${id}`;
}
