import { useEffect, useState } from 'react';
import { object, func } from 'prop-types';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { Box } from '@mui/material';

//  Components
import ProductForm from 'Modules/products/components/ProductForm';
import FileUploadContainer from 'Modules/attachments/containers/FileUploadContainer';
import LightLoadingComponent from 'Common/components/LightLoadingComponent';

//  Actions/Selectors
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import { drawerSettingsSelector } from 'Modules/modal/store/selectors';
import { closeDrawer } from 'Modules/modal/store/operations';
import { clearDataSyncAction } from 'Store/actions/genericActions';
import { createProduct, editProduct } from 'Modules/products/store/operations';

//  Other resources
import { companySettingsSelector } from 'Store/selectors/settings';
import { PRODUCT_KIND } from 'Models/product/Product';
import { BOTH, EITHER, MONEY, POINTS, generateRewardTypeOptions } from 'Util/RewardType';
import { currencyTypes } from 'Models/Currency';
import { getProductConstraints } from 'Util/validation/createBounty';
import { validate } from 'Util/validation/validate';
import { MODAL_NAMESPACE, SENTS_NAMESPACE } from 'Store/namespaces';
import { DRAWER_SETTINGS, SELECTED_BOUNTY } from 'Store/reducerProperties';
import { PHOTO_LIBRARY } from 'Modules/attachments/constants/attachmentSourceOptions';

const uploadOptions = [PHOTO_LIBRARY];

const CreateProductContainer = (props) => {
  const [initialValues, setInitialValues] = useState({});
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const { selectedBounty, drawerSettings, companySettings } = props;
  const companyCurrency = companySettings.currency || currencyTypes.USD;
  const kind = drawerSettings?.optionConfig?.kind || '';

  useEffect(() => {
    const currencies = generateRewardTypeOptions({
      rewardTypes: [MONEY, POINTS, BOTH, EITHER],
      localCurrency: companyCurrency,
    });
    prepareInitialValues();
    setCurrencyOptions(currencies);

    return () => {
      props.clearDataSyncAction(SENTS_NAMESPACE, SELECTED_BOUNTY);
      props.clearDataSyncAction(MODAL_NAMESPACE, DRAWER_SETTINGS);
    };
  }, [selectedBounty]);

  const prepareInitialValues = () => {
    let data = {
      customData: {
        tag: initialTags(),
        currencyType: MONEY,
        currency: companyCurrency,
      },
    };

    if (selectedBounty && Object.keys(selectedBounty).length) {
      const { variants } = selectedBounty?.product;
      const currentSku = variants?.[selectedBounty?.product?.merchantSku] || {};

      data = {
        ...selectedBounty,
        customData: {
          ...data.customData,
          currency: companyCurrency,
          currencyType: selectedBounty?.product?.privateListingInfo?.listingMode || MONEY,
          price: initialPricing(currentSku.price),
          msrp: initialPricing(currentSku.msrp),
          skuInfo: {
            ...currentSku,
            onlineProductInfo: {
              ...currentSku.onlineProductInfo,
              unlimitedUseQty: !currentSku?.onlineProductInfo?.useQty,
            },
          },
        },
      };
    }

    setInitialValues(data);
  };

  const initialPricing = (price) => {
    if (price?.mainPrice) {
      return {
        main: price?.mainPrice?.money?.amount || price?.mainPrice?.points?.POINT?.amount,
      };
    }

    return {
      money: price?.altPrice1?.money?.amount || price?.altPrice2?.money?.amount,
      points: price?.altPrice1?.points?.POINT?.amount || price?.altPrice2?.points?.POINT?.amount,
    };
  };

  const initialTags = () => {
    const { tags } = selectedBounty?.product || {};
    const tag = (tags?.tags && Object.values(tags.tags)[0]) || {};
    const defaultTag = kind === PRODUCT_KIND.TICKET ? 'ticket' : '';

    return tag?.displayName || defaultTag;
  };

  const onSubmit = (values, { setErrors, setSubmitting }) => {
    const rules = getProductConstraints(values, kind === PRODUCT_KIND.TICKET);
    const errors = validate(values, rules);

    if (errors && Object.keys(errors).length) {
      setErrors(errors);
      return setSubmitting(false);
    }

    if (selectedBounty && Object.keys(selectedBounty).length) {
      return props.editProduct(values, kind, selectedBounty)
        .then(() => props.closeDrawer())
        .catch(() => setSubmitting(false));
    }

    props.createProduct(values, kind)
      .then(() => props.closeDrawer())
      .catch(() => setSubmitting(false));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <LightLoadingComponent isLoading={formik.isSubmitting}>
      <Box mb={2}>
        <FileUploadContainer options={uploadOptions} />
      </Box>
      <ProductForm
        selectedBounty={selectedBounty}
        formik={formik}
        onCancel={props.closeDrawer}
        currencyOptions={currencyOptions}
        kind={kind}
      />
    </LightLoadingComponent>
  );
};

CreateProductContainer.propTypes = {
  companySettings: object.isRequired,
  selectedBounty: object.isRequired,
  drawerSettings: object.isRequired,
  createProduct: func.isRequired,
  editProduct: func.isRequired,
  closeDrawer: func.isRequired,
  clearDataSyncAction: func.isRequired,
};

const mapStateToProps = (state) => ({
  selectedBounty: selectedBountySelector(state) || {},
  companySettings: companySettingsSelector(state).data || {},
  drawerSettings: drawerSettingsSelector(state) || {},
});

const dispatchToProps = {
  clearDataSyncAction,
  closeDrawer,
  createProduct,
  editProduct,
};

export default connect(mapStateToProps, dispatchToProps)(CreateProductContainer);
