import { firebaseGetCurrentlySignedInUser, firebaseGetNotificationsRef } from 'Services/FirebaseService';
import { convertObjToArray } from 'Util/helpers';
import { postApiData } from 'Services/Api';
import { NOTIFICATION_API_ROUTE } from 'Constants/apiRoutes';

/**
 * Get Recent User Notifications
 */
export const getUserNotifications = (userId, done) => (
  firebaseGetNotificationsRef(userId).limitToFirst(50).on(
    'value',
    (dataSnapshot) => {
      const notifications = convertObjToArray(dataSnapshot.val());
      return done(notifications);
    },
  )
);

export const createNotification = (payload) => (
  firebaseGetCurrentlySignedInUser()
    .then((accessToken) => postApiData(NOTIFICATION_API_ROUTE, accessToken, payload))
);
