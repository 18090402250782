import { getProductAmounts } from 'Models/Price';
import { getTheDate } from 'Util/helpers';
import { PRODUCT_KIND } from 'Models/product/Product';

export function getMainImageForProduct(attachments, oldBounty) {
  if (oldBounty && oldBounty.product && oldBounty.product.imageUrl) {
    return oldBounty.product.imageUrl;
  }

  if (attachments && attachments.attachments && Object.keys(attachments.attachments).length) {
    const firstAttachKey = Object.keys(attachments.attachments)[0];
    return attachments.attachments[firstAttachKey].url;
  }

  return null;
}

export function getInitialInputData(product = {}, kind) {
  const {
    description, name, additionalInfo, tags, variants,
  } = product;
  const { price, msrp } = getProductAmounts(product);

  const tag = (tags?.tags && Object.values(tags.tags)[0]) || {};
  const defaultTag = kind === PRODUCT_KIND.TICKET ? 'ticket' : '';
  const currentSku = variants?.[product?.merchantSku] || {};

  return {
    ...(currentSku.onlineProductInfo || {}),
    description,
    name,
    additionalInfo,
    bountyAmount: price,
    msrp,
    tags: tag?.displayName || defaultTag,
    merchantSku: product.merchantSku,
    unlimitedUseQty: product && Object.keys(product).length
      && !currentSku?.onlineProductInfo?.useQty,
    quantity: currentSku?.quantity,
  };
}

export function formatTicketAvailability(product) {
  const firstSku = product?.variants
    ? Object.values(product?.variants).find((sku) => sku.onlineProductInfo) || {}
    : {};
  const { availableFrom, availableTo } = firstSku?.onlineProductInfo || {};
  const format = 'LL';

  if (availableFrom && availableTo) {
    return `Ticket is valid between: ${getTheDate(availableFrom, format)} – ${getTheDate(availableTo, format)}`;
  }

  if (availableFrom) {
    return `Ticket is valid starting from ${getTheDate(availableFrom, format)}`;
  }

  if (availableTo) {
    return `Ticket is valid until ${getTheDate(availableTo, format)}`;
  }

  return '-';
}
