import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { array, bool, func, object, string } from 'prop-types';
import { Grid, Box } from '@mui/material';

//  Components
import ProductCard from 'Common/components/SelectableProductCard';
import EmptyResults from 'Common/components/EmptyResults';
import PaginationComponent from 'Common/components/PaginationComponent';

//  Actions/Selectors
import { getProducts } from 'Services/bounty/ProductService';
import { selectedProductsSelector } from 'Modules/modal/store/selectors';
import { updateListSyncAction, pushDataAction } from 'Store/actions/genericActions';

// Others
import { builtinList } from 'Models/ListManager';
import { MARKET } from 'Modules/posts/bounty/constants/listType';
import { preparePagination } from 'Util/GeneralUtils';
import EmptyImage from 'Assets/img/emptyContent/es_incoming.png';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { PRODUCT_TO_ATTACH } from 'Store/reducerProperties';
import LoadingComponent from '../components/LoadingComponent';

const propTypes = {
  list: object,
  getProducts: func.isRequired,
  groupId: string,
  selectedProducts: array,
  allowMultiple: bool,
  updateListSyncAction: func.isRequired,
  pushDataAction: func.isRequired,
};

const defaultProps = {
  list: builtinList[MARKET],
  groupId: '',
  selectedProducts: [],
  allowMultiple: false,
};

const AttachableProductsContainer = (props) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const { list, groupId, selectedProducts, allowMultiple } = props;
  const ref = useRef(null);

  const handleSelectProduct = (item) => {
    const index = selectedProducts?.findIndex((selectedProm) => selectedProm.id === item.id);

    if (index > -1) {
      const newData = selectedProducts?.filter(((selectedProm) => selectedProm.id !== item.id));
      return props.updateListSyncAction(MODAL_NAMESPACE, PRODUCT_TO_ATTACH, newData);
    }

    return allowMultiple
      ? props.pushDataAction(MODAL_NAMESPACE, PRODUCT_TO_ATTACH, item)
      : props.updateListSyncAction(MODAL_NAMESPACE, PRODUCT_TO_ATTACH, [item]);
  };

  const fetchProducts = () => {
    const defaultFilter = {
      listId: 'product',
    };

    const filter = {
      ...defaultFilter,
      ...preparePagination(page),
      ...(groupId ? { groupId } : {}),
      order: 'pinned_at',
    };

    props.getProducts(filter)
      .then((response) => {
        setProducts(response && response.list ? response.list : []);
        setTotalCount(response?.totalCount || 0);
        setIsLoading(false);

        ref?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      })
      .catch(() => {
        setIsLoading(false);
        setPage(1);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, [page]);

  return (
    <LoadingComponent isLoading={isLoading}>
      {products?.length ? (
        <Grid container spacing={3}>
          {
            products.map((product) => (
              <Grid item xs={6} key={product.id}>
                <ProductCard
                  description={product?.product?.name}
                  product={product}
                  handleSelect={handleSelectProduct}
                  selectedProducts={selectedProducts}
                />
              </Grid>
            ))
          }
        </Grid>
      ) : (
        <EmptyResults
          title={list.emptyListMsg}
          subTitle="emptyContent.stayTuned"
          emptyImage={EmptyImage}
        />
      )}
      {totalCount > 1 && (
        <Box my={2}>
          <PaginationComponent
            page={page}
            totalCount={totalCount}
            handlePageChange={setPage}
          />
        </Box>
      )}
    </LoadingComponent>
  );
};

const mapStateToProps = (state) => ({
  selectedProducts: selectedProductsSelector(state) || [],
});

const mapDispatchToProps = {
  getProducts,
  pushDataAction,
  updateListSyncAction,
};

AttachableProductsContainer.propTypes = propTypes;
AttachableProductsContainer.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(AttachableProductsContainer);
