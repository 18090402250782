import { SURVEY, TMOB_CHALLENGE, TMOB_COACHING, SCORE, BUNDLE, QUEST } from 'Constants/bounty/bountyType';

import {
  SCORE as ACTIVITY_SCORE,
  BUNDLE as ACTIVITY_BUNDLE,
  SURVEY as ACTIVITY_SURVEY,
  CHALLENGE as ACTIVITY_CHALLENGE,
  COACHING as ACTIVITY_COACHING,
  QUEST as ACTIVITY_QUEST,
} from 'Modules/posts/bounty/constants/activityTypes';
import { isBundle } from 'Models/bounty/Bounty';

export const bountyTypeNames = {
  [TMOB_COACHING]: 'post',
  [TMOB_CHALLENGE]: 'challenge',
  [SURVEY]: 'Ask a question…',
  [SCORE]: 'score',
  [BUNDLE]: 'post',
  [QUEST]: 'quest',
};

export function getBountyTypeAsText(type, isDescriptionHint) {
  if (isBundle(type) && isDescriptionHint) {
    return 'description';
  }

  return bountyTypeNames[type] || 'post';
}

/**
 * Converts value to the inverse of its bounty name or type.
 * Used to convert URL param to type or type to be URL friendly
 * @param {string} bountyType
 */
export const matchBountyTypeToActivity = (bountyType) => {
  const matchings = {
    [TMOB_COACHING]: ACTIVITY_COACHING,
    [TMOB_CHALLENGE]: ACTIVITY_CHALLENGE,
    [SURVEY]: ACTIVITY_SURVEY,
    [SCORE]: ACTIVITY_SCORE,
    [BUNDLE]: ACTIVITY_BUNDLE,
    [QUEST]: ACTIVITY_QUEST,
  };

  const bountyValue = Object.entries(matchings).reduce((acc, [key, value]) => (
    bountyType === key || bountyType === value ? { ...acc, [key]: value } : acc
  ), {});

  return bountyValue[bountyType] || Object.keys(bountyValue)[0] || bountyType;
};

const labelByRewardName = {
  reward: {
    amount: 'Reward',
  },
  payment: {
    amount: 'Min Pay',
    maxAmount: 'Max Pay',
  },
  bonus: {
    amount: 'Bonus',
  },
};

export function getRewardLabels(rewardName) {
  return labelByRewardName[rewardName] || { amount: 'Reward' };
}
