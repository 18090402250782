import { fcdinamoge } from 'Constants/common';

const fcdinamogeConfig = {
  FLAVOR: 'fcdinamoge',
  id: fcdinamoge,
  title: 'FC Dinamo Tbilisi',
  name: 'FC Dinamo Tbilisi app',
  brandName: 'FC Dinamo Tbilisi',
  appLinkSchema: 'fcdinamoge',
  appTld: 'fcdinamo.ge',
  logo: {
    logoStyle: 'ml-5',
    imgStyle: 'fcdinamoge-logo',
    containerStyle: 'MuiToolbar-regular-79 no-background-color',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCYnkyjE8vvPUzG92GSDOmweItgdS5fs-c',
    authDomain: 'fcdinamoge-prod.firebaseapp.com',
    databaseURL: 'https://fcdinamoge-prod.firebaseio.com',
    projectId: 'fcdinamoge-prod',
    storageBucket: 'fcdinamoge-prod.appspot.com',
    messagingSenderId: '151006572896',
    appId: '1:151006572896:web:b3c59b62edf993094fee05',
    measurementId: 'G-5TMZQH5TJS',
  },
  API_BASE_URL: 'https://api.youkno.ai/api/v1',
  API_REPORT: 'https://api.youkno.ai/download/report',
  login: {
    customCss: '',
    loginWithPin: true,
  },
  algolia_app_id: '',
  algolia_api_key: '',
  TERMS_OF_SERVICE_URL: 'TBA',
  PRIVACY_STATEMENT_URL: 'TBA',
};

export default fcdinamogeConfig;
