import { array, func, object } from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

//  Components

// Others
import { getProductBackgroundImage, textTruncate } from 'Util/helpers';
import classnames from 'classnames';
import { RctCard } from './RctCard';

const SelectableProductCard = ({ product, handleSelect, selectedProducts }) => {
  const productDetails = product.product || product;
  const backgroundImage = getProductBackgroundImage(productDetails);
  const classes = useStyles();
  const isActive = !!selectedProducts?.find((selectedProm) => selectedProm.id === product.id);

  return (
    <div
      role="presentation"
      onClick={() => handleSelect(product)}
      className={classnames('cursor-pointer', { [classes.selectProduct]: isActive })}
    >
      <RctCard customClasses="d-flex mb-0 flex-column justify-content-between overflow-hidden">
        <div className="overlay-wrap overflow-hidden">
          <div className="text-center p-4">
            <div
              style={{ ...backgroundImage, height: '200px' }}
              className="image-post product-image-placeholder"
            />
          </div>
        </div>
        <div className="product-info border-top p-3">
          <h4 className="text-dark">
            {!!productDetails.name && textTruncate(productDetails.name, 25)}
          </h4>
        </div>
      </RctCard>
    </div>
  );
};

const propTypes = {
  product: object.isRequired,
  handleSelect: func.isRequired,
  selectedProducts: array.isRequired,
};
const defaultProps = {};

const useStyles = makeStyles((theme) => ({
  selectProduct: {
    border: `2px solid ${theme.palette.common.greenLabel}`,
  },
}));

SelectableProductCard.propTypes = propTypes;
SelectableProductCard.defaultProps = defaultProps;

export default SelectableProductCard;
