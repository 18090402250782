import { ACTIONS } from 'Store/actions/userManagementActions';
import { USER_MANAGEMENT_SORT_FIELDS } from 'Constants/sort';

const lsLimitName = 'applicationsLimit';
const defaultLimit = Number(localStorage.getItem(lsLimitName)) || 10;

const initialState = {
  list: [],
  isLoading: false,
  reqProps: {
    start: 0,
    limit: defaultLimit,
    order: 'LAST_SIGN_IN_DATE$DESC',
    query: '',
  },
  filters: {
    citizenship: [],
    country: [],
    provider: [],
  },
  searchFields: [
    USER_MANAGEMENT_SORT_FIELDS.NAME,
    USER_MANAGEMENT_SORT_FIELDS.ENGLISH_NAME,
    USER_MANAGEMENT_SORT_FIELDS.EMAIL_ADDRESS,
    USER_MANAGEMENT_SORT_FIELDS.REFERRAL_CODE,
    USER_MANAGEMENT_SORT_FIELDS.PIN,
  ],
  total: 0,
};

const managementReducer = (state = initialState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.GET_LIST:
      return {
        ...state,
        list: payload,
      };

    case ACTIONS.UPDATE_TOTAL:
      return {
        ...state,
        total: payload,
      };

    case ACTIONS.UPDATE_REQ_PROPS:
      if (payload.limit) {
        localStorage.setItem(lsLimitName, payload.limit);
      }
      return {
        ...state,
        reqProps: {
          ...state.reqProps,
          ...payload,
        },
      };

    case ACTIONS.UPDATE_SEARCH_FIELDS:
      return {
        ...state,
        searchFields: payload,
      };

    case ACTIONS.UPDATE_FILTERS:
      return {
        ...state,
        reqProps: {
          ...state.reqProps,
          ...payload.reqProps,
        },
        filters: {
          ...state.filters,
          ...payload.filters,
        },
      };

    case ACTIONS.RESET_FILTERS:
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
      };

    case ACTIONS.LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default managementReducer;
