import { hashToDictionary } from 'Common/utils/formatters';

export const NONE_PERIOD = 'NONE';
export const UNKNOWN_PERIOD = 'UNKNOWN';

//  time units
export const HOUR = 'HOUR';
export const DAY = 'DAY';
export const WEEK = 'WEEK';
export const MONTH = 'MONTH';
export const YEAR = 'YEAR';
export const JOB = 'JOB';

// metric units

export const KILOGRAM = 'KILOGRAM';
export const POUND = 'POUND';
export const LINEAR_METER = 'LINEAR_METER';
export const LINEAR_FOOT = 'LINEAR_FOOT';
export const SQ_METER = 'SQ_METER';
export const SQ_FOOT = 'SQ_FOOT';
export const CUBIC_METER = 'CUBIC_METER';
export const CUBIC_FOOT = 'CUBIC_FOOT';

export const PERIOD_TYPES = [NONE_PERIOD, HOUR, DAY, WEEK, MONTH, YEAR, JOB, UNKNOWN_PERIOD];
export const JOB_UNITS_TYPES = [
  NONE_PERIOD,
  HOUR,
  DAY,
  WEEK,
  MONTH,
  YEAR,
  JOB,
  UNKNOWN_PERIOD,
  KILOGRAM,
  POUND,
  LINEAR_METER,
  LINEAR_FOOT,
  SQ_METER,
  SQ_FOOT,
  CUBIC_METER,
  CUBIC_FOOT,
];

export const JOB_UNITS_AS_STRING = {
  [HOUR]: 'Hourly',
  [DAY]: 'Daily',
  [WEEK]: 'Weekly',
  [MONTH]: 'Monthly',
  [YEAR]: 'Yearly',
  [JOB]: 'On job done',
  [KILOGRAM]: 'Per kilogram',
  [POUND]: 'Per pound',
  [LINEAR_METER]: 'Per linear meter',
  [LINEAR_FOOT]: 'Per linear foot',
  [SQ_METER]: 'Per square meter',
  [SQ_FOOT]: 'Per square foot',
  [CUBIC_METER]: 'Per cubic meter',
  [CUBIC_FOOT]: 'Per cubic foot',
};

export const JOB_UNITS_DICTIONARY = hashToDictionary(JOB_UNITS_AS_STRING);

//  Bonus types
export const LOYALTY_BONUS = 'Loyalty';
export const PROJECT_BONUS = 'Project';
export const OTHER_BONUS = 'Other';

export const BONUS_TYPE_AS_OPTIONS = [
  { label: LOYALTY_BONUS, value: LOYALTY_BONUS },
  { label: PROJECT_BONUS, value: PROJECT_BONUS },
  { label: OTHER_BONUS, value: OTHER_BONUS },
];
