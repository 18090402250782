import { AppBar, Toolbar } from '@mui/material';
import Profile from './Profile';
import LanguagePicker from './LanguagePicker';
import NotificationsBell from './Notifications';
import ChatNotifications from './ChatNotifications';
import * as styles from './index.module.scss';

const Header = () => (
  <AppBar position="static" className={`rct-header ${styles.header}`}>
    <Toolbar className="d-flex justify-content-end w-100 pl-0 pr-0">
      <div className={styles.headerItem}>
        <LanguagePicker />
      </div>
      <div className={styles.headerItem}>
        <ChatNotifications />
      </div>
      <div className={styles.headerItem}>
        <NotificationsBell />
      </div>
      <div className={styles.headerItem}>
        <Profile />
      </div>
    </Toolbar>
  </AppBar>
);

export default Header;
