import { identityType } from 'Constants/bounty/bounty';

const identityMode = {
  FORCED_ANON: {
    forced: true,
    identityType: identityType.ANON,
  },
  FORCED_REAL: {
    forced: true,
    identityType: identityType.REAL,
  },
  ANON: {
    forced: false,
    identityType: identityType.ANON,
  },
  REAL: {
    forced: false,
    identityType: identityType.REAL,
  },
};

function getIdentityType(mode) {
  const type = mode ? mode.identityType : null;
  return getIdentityTypeAsEnum(type);
}

function getIdentityTypeAsEnum(type) {
  if (!type) {
    return identityType.DEFAULT;
  }

  return type;
}

function isEnforced(mode) {
  return mode.forced !== null && mode.forced !== undefined && mode.forced;
}

function isComplete(mode) {
  return mode.forced !== null && mode.forced !== undefined && mode.identityType !== identityType.DEFAULT;
}

function merge(currentMode, mode) {
  const curr = currentMode;

  if (curr.forced === null && mode.forced !== undefined) {
    curr.forced = mode.forced;
  }

  if (curr.forced === undefined) {
    curr.forced = null;
  }

  if (curr.identityType === identityType.DEFAULT) {
    curr.identityType = mode.identityType;
  }

  return curr;
}

function mergeAll(identityModes) {
  let currentMode = { forced: null, identityType: identityType.DEFAULT };

  identityModes.forEach((mode) => {
    if (isComplete(currentMode)) {
      return currentMode;
    }

    if (mode) {
      currentMode = merge(currentMode, mode);
    }
  });

  if (currentMode.identityType === identityType.DEFAULT) {
    currentMode.identityType = identityType.ANON;
  }

  return currentMode;
}

export {
  identityMode,
  getIdentityTypeAsEnum,
  getIdentityType,
  isEnforced,
  isComplete,
  merge,
  mergeAll,
};
