import { array, string, func } from 'prop-types';
import { Input, Label } from 'reactstrap';

const RadioButton = ({ name, className, onChange, options, ...remainingProps }) => (
  <div>
    {options.length && options.map((el, i) => (
      <div key={el.label} className="d-flex align-items-center">
        <Input
          {...remainingProps}
          type="radio"
          value={el.value}
          name={name}
          id={`${name}${i}${el.label}`}
          className="position-relative m-0"
          onChange={onChange}
        />
        <Label className="px-15 m-0" for={el.value}>{el.label}</Label>
      </div>
    ))}
  </div>
);

RadioButton.propTypes = {
  name: string,
  id: string,
  className: string,
  onChange: func.isRequired,
  options: array,
};

RadioButton.defaultProps = {
  name: '',
  id: '',
  className: '',
  options: ['No options available'],
};

export default RadioButton;
