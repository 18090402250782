import pick from 'lodash/pick';
import { NotificationManager } from 'react-notifications';

import * as modalSelectors from 'Modules/modal/store/selectors';
import { settingsSelector, userDataSelector } from 'Store/selectors/settings';
import capitalize from 'lodash/capitalize';
import { COUPON_CODE_CONSTRAINTS, CREATE_PROMOTION_CONSTRAINTS } from 'Util/validation/constraints';
import { DISCOUNT, SELECT_ONE } from 'Modules/promotions/constants/promotion';
import { validate } from 'Util/validation/validate';
import IntlMessages from 'Util/IntlMessages';
import { promotionToString } from 'Models/Promotion';
import { DRAFT_STATE } from 'Constants/bounty/bountyState';
import { getLoggedUser } from 'AuthSelectors';
import { createBounty, onEditBounty } from 'Services/bounty/BountyService';
import { isEditableState } from 'Models/bounty/BountyState';
import { PROMOTION } from 'Constants/bounty/bountyType';
import { getMyCurrency } from 'Models/Settings';
import { prepareExpiryDate } from 'Modules/posts/add/utils/createPostActivity';
import { identityType } from 'Constants/bounty/bounty';
import { selectedProductsSelector } from 'Modules/modal/store/selectors';

const getPromotionState = (state) => ({
  userData: userDataSelector(state).data || {},
  user: getLoggedUser(state).data || {},
  settings: settingsSelector(state) || {},
  inputsData: modalSelectors.inputsData(state) || {},
  selectedProducts: selectedProductsSelector(state) || [],
});

function isPromotionValid(promotion) {
  const errorConstraints = {
    ...CREATE_PROMOTION_CONSTRAINTS,
    ...((promotion.type === DISCOUNT.value) && COUPON_CODE_CONSTRAINTS),
  };

  const errors = validate(promotion, errorConstraints);

  if (Object.keys(errors).length) {
    Object.keys(errors).forEach((field) => {
      NotificationManager.error(capitalize(`${field} ${errors[field][0]}`));
    });

    return false;
  }

  if (!Object.keys(promotion.selectedProductData).length) {
    NotificationManager.error(<IntlMessages id="promotion.productMissing" />);
    return false;
  }

  return true;
}

const generalInfoFields = ['type', 'ttl', 'couponCode', 'deliveryFee', 'price'];
const commonProductInfoFields = ['merchantId', 'description', 'imageUrl'];

function preparePromotionProduct(product, productType) {
  if (!product) {
    return null;
  }

  const commonProps = pick(product, commonProductInfoFields);
  let sku = null;

  if (productType === 'PRODUCT') {
    const variants = product?.variants ? Object.keys(product?.variants) : [];
    sku = variants[0] || null;
  }

  return {
    ...commonProps,
    productType,
    sku,
  };
}

function preparePromotion(promotion, userData, settings) {
  const payload = {
    owner: userData.owner || null,
    state: DRAFT_STATE,
    type: PROMOTION,
    identityMode: {
      identityType: identityType.REAL,
      forced: false,
    },
  };
  const myCurrency = getMyCurrency(settings, userData);

  const generalInfo = pick(promotion, generalInfoFields);
  const product = promotion?.selectedProductData?.product
    ? preparePromotionProduct(promotion?.selectedProductData?.product, 'PRODUCT')
    : promotion?.selectedProductData;

  const metaInfo = {
    products: product ? [product] : null,
    ...generalInfo,
    price: generalInfo.price ? `${generalInfo.price} ${myCurrency}` : null,
    deliveryFee: generalInfo.deliveryFee ? `${generalInfo.deliveryFee} ${myCurrency}` : null,
    discountInfo: promotion.discount ? { percentageDiscount: promotion.discount / 100 } : null,
  };

  payload.description = { text: promotionToString(promotion.description, metaInfo) };

  if (promotion?.type === DISCOUNT.value) {
    payload.activateAt = promotion.activateAt || null;
    payload.expiresAt = promotion.expiresAt || null;
  }

  if (promotion?.type === SELECT_ONE.value && (promotion.days || promotion.minutes || promotion.hours)) {
    payload.expiresAt = prepareExpiryDate(promotion);
  }

  return payload;
}

export function createPromotion() {
  return (dispatch, getState) => {
    const {
      userData,
      settings,
      inputsData,
      selectedProducts,
    } = getPromotionState(getState());
    const promotionData = { ...inputsData, selectedProductData: selectedProducts[0] || {} };

    return new Promise((resolve, reject) => {
      const isValid = isPromotionValid(promotionData);

      if (!isValid) {
        return reject();
      }

      const payload = preparePromotion(promotionData, userData, settings);

      dispatch(createBounty(payload, null))
        .then((response) => resolve(response))
        .catch((err) => reject(new Error(err)));
    });
  };
}

export function editPromotion(bounty) {
  return (dispatch, getState) => {
    const {
      user,
      userData,
      settings,
      inputsData,
      selectedProducts,
    } = getPromotionState(getState());

    const promotionData = { ...inputsData, selectedProductData: selectedProducts[0] || {} };

    return new Promise((resolve, reject) => {
      const isValid = isPromotionValid(promotionData);

      if (!isValid) {
        return;
      }

      if (!isEditableState(bounty.state)) {
        return;
      }

      const payload = {
        ...bounty,
        ...preparePromotion(promotionData, userData, settings),
      };

      dispatch(onEditBounty({ newBounty: payload, oldBounty: bounty, user }))
        .then((response) => resolve(response))
        .catch((err) => reject(new Error(err)));
    });
  };
}
