import { cloneDeep } from 'lodash';
import { NotificationManager } from 'react-notifications';

import { newComment } from 'Models/Comment';
import { addValueOfPoints } from 'Models/Reward';
import { isDraftState } from 'Models/bounty/BountyState';
import { getEffectiveResponseKey, isCreatedByMe } from 'Models/bounty/Bounty';
import { SORT_ORDER_POPULAR } from 'Modules/posts/bounty/constants/sortConfig';
import { areRepliesPrivate, isAllowedToMarkAsOfficial, isAllowedToPin } from 'Models/Response';

import {
  forResponse,
  getFavoritePriority, getPriorityForComment,
  getSortKeysForResponse,
} from 'Util/keyUtils';
import { prepareFilters } from 'Util/GeneralUtils';
import {
  firebaseGetUserCommentsRef,
  firebaseGetUserListsRef,
  firebaseGetUserResponsesRef,
  getInboundResponsesRef,
  firebaseGetCurrentUser,
  firebaseGetTimestamp,
} from 'Services/FirebaseService';
import { getApiData, addToQueue } from 'Services/Api';
import { getAction, getQueue } from 'Util/api/queues';

import { queues } from 'Constants/queues';
import * as cardType from 'Models/CardType';

import * as PointUtils from 'Util/pointsUtils';
import { updateBadges } from 'Util/BountyUtils';

import { setMyPoints } from 'Services/UserService';
import { INBOUND_GLOBAL, getOutboundResponseRefs } from 'Services/response/CommonService';
import { convertObjToArray, streamSnapshotToArray } from 'Util/helpers';
import { updateDataAsyncAction } from 'Store/actions/genericActions';
import { USER_STREAMS_NAMESPACE } from 'Store/namespaces';
import * as reducerProperties from 'Store/reducerProperties';
import { JOB, CLASSIFIED } from 'Constants/bounty/bountyType';
import { RESPONSES } from 'Constants/apiRoutes';

const addToFavorites = ({
  ownerId, userId, bountyId, responseId, creatorId,
}) => () => {
  const now = firebaseGetTimestamp();
  firebaseGetUserResponsesRef(ownerId).child(bountyId).child(responseId).child('response/favoritedAt')
    .set(now);

  const prio = getFavoritePriority(new Date().getTime());
  const key = {
    cardType: cardType.RESPONSE,
    creatorId,
    bountyId,
    responseKey: bountyId,
    '.priority': prio,
  };

  //  TODO check prio (prio must be send to set function, but can't accept 2 arguments,
  //  TODO see if .priority from key object is working)
  firebaseGetUserListsRef({ ownerId, userId }).child('favorites').child(responseId).set(key);
  addToQueue(getAction(queues.ADD_FAVORITE), getQueue(queues.ADD_FAVORITE), { responseId, favoritedAt: now });
};

const removeFromFavorites = ({
  ownerId, userId, bountyId, responseId,
}) => () => {
  firebaseGetUserResponsesRef(ownerId).child(bountyId).child(responseId).child('response/favoritedAt')
    .remove();
  firebaseGetUserListsRef({ ownerId, userId }).child('favorites').child(responseId).remove();
  addToQueue(getAction(queues.REMOVE_FAVORITE), getQueue(queues.REMOVE_FAVORITE), { responseId });
};

function updateSortKeys(bounty, bountyResponse, ownerId, keys) {
  const keyInfo = forResponse(bountyResponse);

  const ref = getInboundResponseRef(bounty, bountyResponse, ownerId);
  ref.update(getSortKeysForResponse(keyInfo, keys));
}

function getResponseCardRef(responseKey, responseId, ownerId, type) {
  if (type === INBOUND_GLOBAL) {
    return getInboundResponsesRef(ownerId, responseKey).child(responseId);
  }

  console.log('Should never get here'); // eslint-disable-line
}

function getResponseRef(responseKey, responseId, ownerId, type) {
  return getResponseCardRef(responseKey, responseId, ownerId, type).child('response');
}

function getInboundResponseRef(bounty, bountyResponse, ownerId) {
  return getResponseRef(getEffectiveResponseKey(bounty), bountyResponse.id, ownerId, INBOUND_GLOBAL);
}

function getResponseRefs(bounty, bountyResponse, ownerId, user) {
  const responseRefs = {};

  if (!isDraftState(bountyResponse)) {
    responseRefs[INBOUND_GLOBAL] = getInboundResponseRef(bounty, bountyResponse, ownerId);
  }
  if (isCreatedByMe(bountyResponse, user)) {
    Object.assign(responseRefs, getOutboundResponseRefs(bounty, bountyResponse, user));
  }
  return responseRefs;
}

/* **** Answers comments **** */
const getBountyAnswersComments = (bountyResponseId) => () => (
  new Promise((resolve) => (
    firebaseGetUserCommentsRef()
      .child('responses')
      .child(bountyResponseId)
      .once('value', (dataSnapshot) => {
        resolve(convertObjToArray(dataSnapshot.val()).reverse());
      })
  ))
);

const prepareBountyAnswersComments = (result) => (dispatch) => {
  const promises = [];

  result.forEach((item) => {
    if (item.response.id) {
      promises.push(dispatch(getBountyAnswersComments(item.response.id)));
    }
  });

  Promise.all(promises)
    .then((response) => {
      const comments = {};
      result.forEach((item, index) => {
        comments[item.response.id] = response[index];
      });

      dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_ANSWERS_COMMENTS, comments));
    });
};

const saveBountyAnswersToStore = (response, dispatch) => {
  const result = streamSnapshotToArray(response);

  dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_ANSWERS, result));
  dispatch(prepareBountyAnswersComments(result));
};

const getBountyAnswers = (ownerId, bounty, sortOrder) => (dispatch) => {
  const me = firebaseGetCurrentUser();
  const privateReplies = bounty.type === CLASSIFIED || areRepliesPrivate(bounty.responseVisibilityMode);

  if (!bounty?.id || !ownerId) {
    return null;
  }

  if (privateReplies && !isCreatedByMe(bounty, me)) {
    return firebaseGetUserResponsesRef(ownerId)
      .child(bounty.id)
      .orderByChild('response/creator')
      .equalTo(me.uid)
      .on('value', (dataSnapshot) => saveBountyAnswersToStore(dataSnapshot, dispatch));
  }

  firebaseGetUserResponsesRef(ownerId)
    .child(bounty.id)
    .orderByChild(`response/${sortOrder}`)
    .on('value', (dataSnapshot) => saveBountyAnswersToStore(dataSnapshot, dispatch));
};

const getBountyAnswersAny = (filters = {}) => (dispatch) => {
  getApiData(`${RESPONSES}/${JOB}?${prepareFilters(filters)}`)
    .then((response) => {
      dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_ANSWERS, response.list));
      dispatch(prepareBountyAnswersComments(response.list));
    })
    .catch(() => {
      dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.BOUNTY_DETAILS, {}));
    });
};

/* **** Response comments **** */
const increaseResponseComments = ({
  ownerId, bountyId, responseId, commentCount,
}) => () => (
  firebaseGetUserResponsesRef(ownerId).child(bountyId).child(responseId).child('response/stats/commentCount')
    .set(commentCount + 1)
);

const addResponseComments = (responseId, comment) => () => {
  const testRef = firebaseGetUserCommentsRef().child('responses').child(responseId).push();
  comment.id = testRef.key; // eslint-disable-line

  testRef.setWithPriority(comment, getPriorityForComment(comment));
  addToQueue(queues.ADD_COMMENT, { comment, commentId: testRef.key });
};

/* **** Like **** */
function increaseLikesStat(bounty, bountyResponse, ownerId, responseRefs) {
  adjustLikesStat(bounty, bountyResponse, ownerId, responseRefs, 1);
}

function decreaseLikesStat(bounty, bountyResponse, ownerId, responseRefs) {
  adjustLikesStat(bounty, bountyResponse, ownerId, responseRefs, -1);
}

function adjustLikesStat(bounty, bountyResponse, ownerId, responseRefs, adjustment) {
  const bountyResponseClone = cloneDeep(bountyResponse);

  if (!bountyResponse.stats) {
    bountyResponseClone.stats = { likesCount: 0 };
  }

  const likesCount = bountyResponseClone.stats.likesCount + adjustment;
  bountyResponseClone.stats = { likesCount };

  Object.keys(responseRefs).forEach((ref) => responseRefs[ref].child('stats/likesCount').set(likesCount));
  updateSortKeys(bounty, bountyResponse, ownerId, [SORT_ORDER_POPULAR]);
}

const toggleLikedStateForResponse = (bounty, response, user, ownerId) => () => {
  const responseClone = cloneDeep(response);
  const responseRefs = getResponseRefs(bounty, responseClone, ownerId, user);

  if (responseClone.likedAt) {
    responseClone.likedAt = null;
    decreaseLikesStat(bounty, responseClone, ownerId, responseRefs);
  } else {
    responseClone.likedAt = new Date().getTime();
    increaseLikesStat(bounty, responseClone, ownerId, responseRefs);
  }

  Object.keys(responseRefs).forEach((ref) => responseRefs[ref].child('likedAt').set(responseClone.likedAt));

  addToQueue(queues.LIKE_RESPONSE, { responseId: responseClone.id, likedAt: responseClone.likedAt });
};

const rateBountyResponse = ({
  bounty, bountyResponse, text, rateAmt, availablePts, ownerId,
}) => async (dispatch) => {
  const bountyResponseClone = cloneDeep(bountyResponse);
  const currentUser = firebaseGetCurrentUser();

  const pointCurrency = availablePts.currency;
  const pts = {
    amount: rateAmt,
    currency: pointCurrency,
  };

  bountyResponseClone.rating = PointUtils.addRating(bountyResponseClone.rating, currentUser.uid, pts);
  const rating = PointUtils.getPoints(bountyResponseClone.rating, pointCurrency);

  const refs = getResponseRefs(bounty, bountyResponseClone, ownerId, currentUser);
  Object.keys(refs).forEach((ref) => {
    refs[ref].child('rating').child('points').child(pointCurrency).set(rating);
    refs[ref].child('rating').child('userRatings').set(bountyResponseClone.rating.userRatings);
  });

  updateSortKeys(bounty, bountyResponseClone, ownerId, [SORT_ORDER_POPULAR]);
  setMyPoints({ amount: availablePts.amount - pts.amount, currency: pointCurrency }); // this method gives perm denied

  const comment = await newComment(bounty.myself, text, cardType.RESPONSE, bountyResponseClone.id);
  comment.reward = addValueOfPoints(pts);

  dispatch(addResponseComments(bountyResponseClone.id, comment));
};

const changeResponseRatingValue = ({
  bounty, bountyResponse, ownerId, ratingValue,
}) => () => {
  const ref = getInboundResponseRef(bounty, bountyResponse, ownerId);
  ref.child('rating/starPointsValue').set(ratingValue);
  addToQueue(queues.CHANGE_BOUNTY_RATING_VALUE, { responseId: bountyResponse.id, starPointsValue: ratingValue });
};

const toggleBadgeType = ({
  bounty, bountyResponse, user, ownerId, badgeType, mark,
}) => () => {
  const responseClone = cloneDeep(bountyResponse);
  const isAllowedToPerformOp = isAllowedToMarkAsOfficial(responseClone.state);

  if (!isAllowedToPerformOp) {
    return NotificationManager.warning(`Cannot edit badges when ${responseClone.state}`);
  }

  responseClone.badges = updateBadges(responseClone, badgeType, mark);
  const responseRefs = getResponseRefs(bounty, responseClone, ownerId, user);

  Object.keys(responseRefs).forEach((ref) => responseRefs[ref].child('badges').set(responseClone.badges));
  addToQueue(queues.RESPONSE_EDIT_BADGE, { responseId: responseClone.id, badgeType, mark });
};

const pinToTop = ({ bountyResponse, pin }) => () => {
  const responseClone = cloneDeep(bountyResponse);
  const isAllowedToPerformOp = isAllowedToPin(responseClone.state);

  if (!isAllowedToPerformOp) {
    return NotificationManager.warning(`Cannot edit badges when ${responseClone.state}`);
  }

  const pinnedAt = pin ? firebaseGetTimestamp() : null;

  addToQueue(queues.PIN_RESPONSE, { responseId: responseClone.id, pin, pinnedAt });
};

export const setResponseDisplayMode = ({ bountyResponse, displayMode }) => {
  const changes = {
    displayMode: {
      newValue: displayMode,
      oldValue: bountyResponse?.displayMode || null,
    },
  };

  addToQueue(queues.UPDATE_RESPONSE, { responseId: bountyResponse.id, changes });
};

const getReplyCommentStats = ({ ownerId, bountyId, responseId }) => new Promise((resolve) => (
  firebaseGetUserResponsesRef(ownerId)
    .child(bountyId)
    .child(responseId)
    .child('response/stats/commentCount')
    .once('value', (dataSnapshot) => (resolve(dataSnapshot.val())))
));

export {
  toggleLikedStateForResponse,
  removeFromFavorites,
  addToFavorites,
  rateBountyResponse,
  changeResponseRatingValue,
  toggleBadgeType,
  pinToTop,
  increaseResponseComments,
  addResponseComments,
  getBountyAnswers,
  getBountyAnswersAny,
  getBountyAnswersComments,
  getReplyCommentStats,
};
