import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TerminalContextProvider } from 'react-terminal';

// Others
import { HOME_ROUTE } from 'Constants/routes';
import { getApplicationConfig } from 'Util/AppUtils';

// CSS
import './assets/reactifyCss';

// Firebase
import 'Util/firebase';

// Components
import InterfaceLocaleProvider from 'Modules/layout/containers/locale/InterfaceLocaleProvider';
import CompatibilityMessage from 'Modules/notification/components/CompatibilityMessage';
import * as Sentry from '@sentry/react';
import { configureStore } from 'Util/store';
import DataLocaleProvider from 'Modules/layout/containers/locale/DataLocaleProvider';
import Routes from './Routes';

const { title } = getApplicationConfig();

const MainApp = () => (
  <Sentry.ErrorBoundary>
    <Provider store={configureStore()}>
      <TerminalContextProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <InterfaceLocaleProvider>
            <DataLocaleProvider>
              <NotificationContainer />
              <CompatibilityMessage />
              <Router>
                <Switch>
                  <Route path={HOME_ROUTE} component={Routes} />
                </Switch>
              </Router>
            </DataLocaleProvider>
          </InterfaceLocaleProvider>
        </LocalizationProvider>
      </TerminalContextProvider>
    </Provider>
  </Sentry.ErrorBoundary>
);

export default MainApp;
