import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { func, string } from 'prop-types';

// Other resources
import * as modalSelectors from 'Modules/modal/store/selectors';
import { updateDataSyncAction } from 'Store/actions/genericActions';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { COUPON_CODE, COUPON_DISCOUNT } from 'Modules/promotions/constants/promotion';
import { MODAL_INPUTS_DATA } from 'Store/reducerProperties';
import IntlMessage from 'Util/IntlMessages';

const propTypes = {
  updateDataSyncAction: func.isRequired,
  couponCode: string.isRequired,
  discount: string.isRequired,
};

const CouponContainer = (props) => {
  const handleCouponChange = (e, name) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: e.target.value });
  };

  return (
    <Box width="50%" className="mb-30 pr-15">
      <TextField
        className="mb-20"
        id={COUPON_CODE}
        fullWidth
        type="text"
        label={<IntlMessage id="label.createCouponCode" />}
        value={props.couponCode || ''}
        onChange={(e) => handleCouponChange(e, COUPON_CODE)}
      />
      <TextField
        id={COUPON_DISCOUNT}
        fullWidth
        type="number"
        label={<IntlMessage id="label.specDiscountPercentage" />}
        value={props.discount || ''}
        onChange={(e) => handleCouponChange(e, COUPON_DISCOUNT)}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  couponCode: modalSelectors.inputsData(state)?.couponCode || '',
  discount: modalSelectors.inputsData(state)?.discount || '',
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

CouponContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(CouponContainer);
