import { identityMode } from 'Models/IdentityMode';
import { PIN } from 'Constants/bounty/bounty';
import { STREAM, listTypeData } from 'Modules/posts/bounty/constants/listType';
import {
  TMOB_COACHING,
  TMOB_CHALLENGE,
  SURVEY,
  CLASSIFIED,
  PRODUCT,
} from 'Constants/bounty/bountyType';
import * as Operations from 'Models/Op';
import * as allowMode from 'Modules/posts/bounty/constants/allowMode';
import { REBOUNTY, STASH, BACKSTAGE } from 'Modules/posts/bounty/constants/productFlavour';

export function getAllowed(op) {
  switch (op) {
    case Operations.SEE_ADDRESSES.name:
    case Operations.SEE_MARKET.name:
    case Operations.SEE_BUY_SELL.name:
    case Operations.SEE_LIKE.name:
      return allowMode.MAY;
    case Operations.SEE_FAVORITES.name:
    case Operations.SEE_LEADERBOARD.name:
      return allowMode.SHOULD;
    default:
      return allowMode.DEFAULT;
  }
}

export const ProductConfig = {
  identityMode: identityMode.ANON,
  isSocialPostEnabled: true,
  accessMode: PIN,
  hideBulletins: false,
  isFollowUserEnabled: true,
  bountyTypes: [
    TMOB_COACHING,
    TMOB_CHALLENGE,
    SURVEY,
    CLASSIFIED,
  ],
  listTypes: [listTypeData[STREAM]],
  getAllowed: (op) => getAllowed(op),
};

function BackstageProductConfig() {
  return {
    ...ProductConfig,
    isFollowUserEnabled: false,
  };
}

function RebountyProductConfig() {
  return {
    ...ProductConfig,
    getAllowed: (op) => {
      switch (op) {
        case Operations.SEE_ADDRESSES.name:
        case Operations.SEE_MARKET.name:
        case Operations.SEE_BUY_SELL.name:
        case Operations.SEE_LIKE.name:
        case Operations.SEE_FAVORITES.name:
        case Operations.SEE_LEADERBOARD.name:
          return allowMode.MAY;
        default:
          return allowMode.DEFAULT;
      }
    },
  };
}

function StashProductConfig() {
  return {
    ...ProductConfig,
    getAllowed: (op) => {
      switch (op) {
        case Operations.SEE_DASHBOARD.name:
        case Operations.SEE_ADDRESSES.name:
        case Operations.SEE_MARKET.name:
        case Operations.SEE_BUY_SELL.name:
        case Operations.SEE_LIKE.name:
          return allowMode.SHOULD;
        case Operations.SEE_FAVORITES.name:
          return allowMode.MAY;
        default:
          return allowMode.DEFAULT;
      }
    },
    bountyTypes: [
      CLASSIFIED,
      PRODUCT,
    ],
  };
}

export function getInstance(productFlavour) {
  switch (productFlavour) {
    case REBOUNTY:
      return RebountyProductConfig();
    case BACKSTAGE:
      return BackstageProductConfig();
    case STASH:
      return StashProductConfig();
    default:
      return ProductConfig;
  }
}
