import { createStructuredSelector } from 'reselect';

//  Others
import { AUTH_NAMESPACE, SETTINGS_NAMESPACE } from 'Store/namespaces';
import {
  LOGGED_USER,
  USER_PROFILE,
  REGISTRATION_ACTIVE_STEP,
  ACCOUNT_INFO,
  USER_ENDORSEMENTS,
  USER_PRIVATE_INFO,
} from 'Store/reducerProperties';

export const getUserUid = (state) => state[AUTH_NAMESPACE][LOGGED_USER].data.uid;
export const getLoggedUser = (state) => state[AUTH_NAMESPACE][LOGGED_USER];
export const getLoggedUserProfile = (state) => state[AUTH_NAMESPACE][USER_PROFILE];
export const getLoggedUserPrivateInfo = (state) => state[AUTH_NAMESPACE][USER_PRIVATE_INFO];
export const getLoggedUserEndorsements = (state) => state[AUTH_NAMESPACE][USER_ENDORSEMENTS];
export const getRegistrationStep = (state) => state[AUTH_NAMESPACE][REGISTRATION_ACTIVE_STEP];
export const getUserName = (state) => state[AUTH_NAMESPACE][LOGGED_USER].data.displayName;
export const getUserEmail = (state) => state[AUTH_NAMESPACE][LOGGED_USER].data.email;
export const getUserAvatar = (state) => state[SETTINGS_NAMESPACE].userData.data.myself;
export const accountInfoSelector = (state) => state[AUTH_NAMESPACE][ACCOUNT_INFO];

/**
 * Memorised Selectors
 */
export const userDataForMsg = createStructuredSelector({
  avatar: (state) => (state[AUTH_NAMESPACE][LOGGED_USER].data.photoURL || ''),
  name: getUserName,
  uid: getUserUid,
});
