import { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import { BENEFITS } from 'Constants/bounty/benefit';
import { Grid } from '@mui/material';

//  Components
import CheckboxList from 'Modules/posts/add/components/CheckboxList';

//  Actions/Selectors
import { updateDataSyncAction } from 'Store/actions/genericActions';
import * as modalSelectors from 'Modules/modal/store/selectors';

//  Other resources
import { getBenefitsAsList } from 'Models/bounty/Bounty';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_OPTIONS_DATA } from 'Store/reducerProperties';
import { selectedBountySelector } from '../../../companySents/store/selectors';

const BenefitsContainer = (props) => {
  const availableBenefits = getBenefitsAsList(BENEFITS);
  const { benefits, selectedBounty } = props;

  useEffect(() => {
    if (selectedBounty.jobInfo) {
      populateBenefits();
    }
  }, []);

  const populateBenefits = () => {
    const { jobInfo } = selectedBounty;

    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, { benefits: jobInfo?.benefits || null });
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, { bonusType: jobInfo?.bonusType || null });
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, { bonusPeriod: jobInfo?.bonusPeriod || null });
  };

  const handleChanges = (benefit) => {
    const payload = {
      ...benefits,
      [benefit]: { enabled: !(benefits[benefit]?.enabled || false) },
    };
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, { benefits: payload });
  };

  return (
    <div className="mt-20">
      <h4>Benefits:</h4>
      <Grid container>
        <CheckboxList
          items={availableBenefits}
          data={benefits}
          handleChanges={handleChanges}
        />
      </Grid>
    </div>
  );
};

BenefitsContainer.propTypes = {
  benefits: object.isRequired,
  selectedBounty: object.isRequired,
  updateDataSyncAction: func.isRequired,
};

const mapStateToProps = (state) => ({
  benefits: modalSelectors.optionsData(state).benefits || {},
  selectedBounty: selectedBountySelector(state) || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsContainer);
