import { ATTACHMENT_TYPE, PDF_MIME_TYPE } from 'Constants/attachment';

export const TAKE_PHOTO = 'TAKE_PHOTO';
export const PHOTO_LIBRARY = 'PHOTO_LIBRARY';
export const VIDEO_LIBRARY = 'VIDEO_LIBRARY';
export const AUDIO_LIBRARY = 'AUDIO_LIBRARY';
export const PDF_FILES = 'PDF_FILES';

const OPTIONS_PROPS = {
  [AUDIO_LIBRARY]: {
    attachmentType: ATTACHMENT_TYPE.AUDIO,
    type: AUDIO_LIBRARY,
    icon: 'audiotrack',
    label: 'library.audio',
    accept: 'audio/*',
  },
  [VIDEO_LIBRARY]: {
    attachmentType: ATTACHMENT_TYPE.VIDEO,
    type: VIDEO_LIBRARY,
    icon: 'video_library',
    label: 'library.video',
    accept: 'video/*',
  },
  [PHOTO_LIBRARY]: {
    attachmentType: ATTACHMENT_TYPE.IMAGE,
    type: PHOTO_LIBRARY,
    icon: 'photo_library',
    label: 'library.photo',
    accept: 'image/*',
  },
  [PDF_FILES]: {
    attachmentType: ATTACHMENT_TYPE.PDF,
    type: PDF_FILES,
    icon: 'picture_as_pdf',
    label: 'library.pdf',
    accept: PDF_MIME_TYPE,
  },
  [TAKE_PHOTO]: {
    attachmentType: ATTACHMENT_TYPE.IMAGE,
    type: TAKE_PHOTO,
    icon: 'photo_camera',
    label: 'library.takePicture',
  },
};

export function getMenuOptions(options) {
  const menuItems = [];

  options.forEach((option) => {
    const props = OPTIONS_PROPS[option];

    if (props) {
      menuItems.push(props);
    }
  });

  return menuItems;
}
