import * as responseState from 'Constants/response/responseState';
import { PRIVATE_TYPE } from 'Models/VisibilityMode';

export function getLevel1Id(response) {
  return response?.bountyInfo?.id || null;
}

export function getLevel2Id(response) {
  return response.id;
}

export function isAllowedToMarkAsOfficial(state) {
  return [
    responseState.ACTIVE_STATE,
    responseState.ACCEPTED_STATE,
    responseState.ACKED_STATE,
    responseState.PAID_STATE,
    responseState.PAYING_STATE,
  ].includes(state);
}

export function isAllowedToPin(state) {
  return [
    responseState.ACTIVE_STATE,
    responseState.ACCEPTED_STATE,
    responseState.ACKED_STATE,
    responseState.PAID_STATE,
    responseState.PAYING_STATE,
  ].includes(state);
}

export function isAllowedToAccept(state) {
  return [
    responseState.ACTIVE_STATE,
    responseState.ACKED_STATE,
    responseState.PROGRESSING_STATE,
  ].includes(state);
}

export function isAllowedToRetract(state) {
  return [
    responseState.ACTIVE_STATE,
    responseState.ACKED_STATE,
    responseState.ACCEPTED_STATE,
  ].includes(state);
}

export function isAllowedToRefuse(state) {
  return [
    responseState.ACTIVE_STATE,
    responseState.ACKED_STATE,
    responseState.PROGRESSING_STATE,
  ].includes(state);
}

export function isAllowedToDelete(state) {
  return [
    responseState.DRAFT_STATE,
    responseState.RETRACTED_STATE,
    responseState.REJECTED_STATE,
    responseState.REFUSED_STATE,
  ].includes(state);
}

export function isAllowedToDemote(state) {
  return [
    responseState.DRAFT_STATE,
    responseState.PENDING_STATE,
    responseState.ACTIVE_STATE,
    responseState.ACKED_STATE,
    responseState.RETRACTED_STATE,
    responseState.REJECTED_STATE,
    responseState.REFUSED_STATE,
  ].includes(state);
}

export function areRepliesPrivate(responseVisibilityMode) {
  return responseVisibilityMode && responseVisibilityMode.visibilityType === PRIVATE_TYPE;
}
