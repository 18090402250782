import { translationsCommonCodes } from 'Constants/translations';
import { LANGUAGES } from 'Constants/languages';

/**
 * @typedef {Object} DictionaryItem
 * @type {Object}
 * @property {string} value Language name
 * @property {string} label Language code
 *
 */

/**
 * @typedef {Object} Translation
 * @type {Object}
 * @property {string} code Language code
 * @property {string} text Translated text
 */

/**
 * This function parse input text and split it by language codes
 * @param {string} text Text to be parsed
 *
 * @returns {Translation[]} An array of objects with a language code as a key and translated text as a value.
 * translationsDropdownCodes.default key - if there is no lead language,
 * translationsDropdownCodes.united key - for full/source text.
 */

export const textToTranslations = (text) => {
  const regExpDivider = new RegExp(/<!-- *lang: *(([A-Za-z]){2}) *-->/g);

  const trimmedText = text.trim();
  const separators = [];
  const langCodes = [];

  let n;
  // eslint-disable-next-line no-cond-assign
  while (n = regExpDivider.exec(trimmedText)) {
    separators.push(n[0]);
    langCodes.push(n[1].toLowerCase());
  }

  // No language separators case.
  if (!separators.length) {
    const noSeparatorsTranslations = [{ code: translationsCommonCodes.united, text }];
    if (trimmedText.length) {
      noSeparatorsTranslations.unshift({ code: translationsCommonCodes.default, text });
    }
  }

  const translations = separators.length ? trimmedText
    .split(new RegExp(separators.join('|'), '')) : [trimmedText];

  // Lead language case.
  if (translations[0] === '') {
    translations.shift();
  }

  // No lead language case.
  if (langCodes.length < translations.length) {
    langCodes.unshift(translationsCommonCodes.default);
  }

  return langCodes
    .map((code, index) => ({ code, text: translations[index].trim() }))
    .concat({ code: translationsCommonCodes.united, text });
};

/**
 * This function get translates codes used in the provided text
 * @param {string} text Text to be parsed
 * @returns {string[]} Array of language codes
 */
export const getTranslationsCodesFromText = (text) => textToTranslations(text).map(({ code }) => code);

/**
 * This function update text of Translation by code
 * @param {Translation[]} translations Array of Translations to be updated
 * @param {string} code Language code for Translation to be updated
 * @param {string} text Text to update
 * @returns {Translation[]} Array of updated Translations
 */

export const updateTranslationByCode = (translations, code, text) => {
  let updatedTranslations = translations.map((t) => (t.code === code ? ({ code, text }) : t));

  if (code !== translationsCommonCodes.united) {
    const unitedText = translationsToText(updatedTranslations);

    updatedTranslations = updatedTranslations
      .map((t) => (t.code === translationsCommonCodes.united ? ({ code: t.code, text: unitedText }) : t));
  }

  return updatedTranslations;
};

/**
 * This function format united text from translations
 * @param {Translation[]} translations Array of Translations to be updated
 * @returns {string} String of united Translation
 */
export const translationsToText = (translations) => translations
  .filter((t) => t.code !== translationsCommonCodes.united)
  .map((t) => ((t.code === translationsCommonCodes.default) ? t.text : `<!-- lang: ${t.code} --> \n\n${t.text}`))
  .join('\n\n');

/**
 * This function add new Translation
 * @param {Translation[]} translations Array of Translations to be updated
 * @param {string} code New translation code
 * @param {string=} text New translation text
 * @returns {Translation[]} Array of updated Translations
 */
export const addTranslation = (translations, code, text = '') => {
  const updTranslations = translations
    .filter((t) => t.code !== translationsCommonCodes.united)
    .concat({ code, text });

  return updTranslations.concat({ code: translationsCommonCodes.united, text: translationsToText(updTranslations) });
};

/**
 * This function return a translated string by preferred language. If there is no preferred language in the list,
 * lead language will be returned. Otherwise there will be a source text as function result
 * @param {string} text Text to be parsed
 * @param {string} preferredLanguageCode Requested translation language code
 * @returns {string} Translated text
 */

export const getPreferredLanguageText = (text, preferredLanguageCode = translationsCommonCodes.default) => {
  const translations = textToTranslations(text || '');
  const preferredTranslation = translations
    .find(({ code }) => code === preferredLanguageCode);

  return preferredTranslation?.text || translations[0]?.text || text;
};

/**
 * This function return a language codes for translations with empty text.
 * @param {string} text Text to be parsed
 * @returns {Translation[]} Array of empty translations except united
 */
const getEmptyTranslationsFromText = (text) => textToTranslations(text || '')
  .filter((t) => t.code !== translationsCommonCodes.united)
  .filter((t) => !t.text);

/**
 * This function compare languages by name ignoring 'Default' and 'Show all' options.
 * @param {DictionaryItem} itemA
 * @param {DictionaryItem} itemB
 * @returns {number} Number
 */
export const langSortFunction = (itemA, itemB) => {
  const { label: labelA, value: valueA } = itemA;
  const { label: labelB, value: valueB } = itemB;

  // eslint-disable-next-line no-nested-ternary
  return (valueA === translationsCommonCodes.default || valueB === translationsCommonCodes.united ? -1
    : (valueB === translationsCommonCodes.default || valueA === translationsCommonCodes.united
      ? 1 : labelA.localeCompare(labelB)));
};

export const getLanguageNameByCode = (code) => LANGUAGES
  .find((lang) => lang.value === code)?.label || code;

/**
 * This function convert translations to a sorted dropdown dictionary, where label is a full language name.
 * @param {Translation[]} translations Array of Translations to be converted.
 * @returns {DictionaryItem[]} Languages dictionary
 */
export const getDictionaryFromTranslations = (translations) => translations
  .map(({ code }) => {
    let label;
    switch (code) {
      case translationsCommonCodes.united:
        label = 'Show all';
        break;

      case translationsCommonCodes.default:
        label = 'Default';
        break;

      default: {
        label = getLanguageNameByCode(code);
      }
    }

    return ({ label, value: code });
  })
  .sort(langSortFunction);

/**
 * This function return a list of languages names which translations are empty.
 * @param {string} text Text to be parsed.
 * @returns {string[]} Arrays of full languages names.
 */
export const getEmptyLanguagesFromText = (text) => {
  const translations = getEmptyTranslationsFromText(text);

  return getDictionaryFromTranslations(translations)
    .map(({ label }) => label);
};

/**
 * Convert 4-letters language codes to 2-letters.
 * @param {string} fullLanguageCode Full language code aka 'en-US'
 * @returns {string} Short langauge code aka 'en'
 */
export const getShortLangCode = (fullLanguageCode) => fullLanguageCode.split('-')[0];

/**
 * Get translation text by code from the translations array.
 * @param {Translation[]} arr Array of translations
 * @param {string} code Language code
 * @returns {string} Translation text
 */
export const getTranslationByCode = (arr, code) => arr.find((t) => t.code === code)?.text || '';

/**
 * Get sections of translations. This function parse title and description and combine that into an objects in array.
 * @typedef {Object} CombinedTranslation
 * @type {Object}
 * @property {string} title Title text
 * @property {string} description Description text
 * @property {string} code Language code
 *
 * @param {string} title Title text
 * @param {string} description Description text
 * @returns {CombinedTranslation[]} Array of objects like { code: '...', title: '...', description: '...' }
 */
export const getCombinedTranslations = (title, description) => {
  const titleTranslations = textToTranslations(title);
  const descriptionTranslations = textToTranslations(description);

  const uniqueCodesList = [...new Set([
    ...titleTranslations.map(({ code }) => code),
    ...descriptionTranslations.map(({ code }) => code),
  ])]
    .filter((code) => code !== translationsCommonCodes.united); // TODO: remove as we refuse from the old form with 'united' option;

  return uniqueCodesList.map((code) => ({
    code,
    title: getTranslationByCode(titleTranslations, code),
    description: getTranslationByCode(descriptionTranslations, code),
  }));
};

/**
 * Convert combined sections of translations to title and description text with separators.
 *
 * @typedef {Object} TitleAndDescriptionObj
 * @property {string} title Title text
 * @property {string} description Description text
 *
 * @param {CombinedTranslation[]} combinedTranslations Array of combined translations
 * @returns {TitleAndDescriptionObj} Object like { title: '...', description: '...' }
 */
export const combinedTranslationsToText = (combinedTranslations) => {
  const title = translationsToText(combinedTranslations.map((t) => ({ code: t.code, text: t.title })));
  const description = translationsToText(combinedTranslations.map((t) => ({ code: t.code, text: t.description })));
  return { title, description };
};
