import * as interactions from 'Modules/posts/bounty/constants/interactions';

function getDefaultInteractionsList() {
  return {
    [interactions.CHAT]: false,
    [interactions.OFFICIAL_RESPONSE]: false,
    [interactions.REBOUNTY]: false,
    [interactions.RECOMMEND]: false,
    [interactions.SEEN]: false,
    [interactions.SHARE]: false,
  };
}

export function addInteraction(interaction, interactionList = null) {
  if (!interactionList) {
    const defaultInteractions = getDefaultInteractionsList();
    return {
      ...defaultInteractions,
      [interaction]: true,
    };
  }

  return {
    ...interactionList,
    [interaction]: true,
  };
}

export function removeInteraction(interaction, interactionList = null) {
  if (interactionList) {
    return {
      interactionList,
      [interaction]: false,
    };
  }
}
