//  Services
import { addBountyComments, increaseComments } from 'Services/bounty/StatsService';

//  Helpers
import { firebaseGetCurrentlySignedInUser, firebaseGetCurrentUser } from 'Services/FirebaseService';
import { getApiData, upload } from 'Services/Api';
import { prepareFilters } from 'Util/GeneralUtils';

//  Constants
import { queues } from 'Constants/queues';
import { REQUESTS, CLIENTS } from 'Constants/apiRoutes';
import { getAction } from 'Util/api/queues';
import { newComment } from 'Models/Comment';
import { BOUNTY } from 'Models/EntityType';
import { addValueOfPoints } from 'Models/Reward';
import { pointCurrency } from 'Models/PointCurrency';

export const uploadProducts = (file) => {
  const pin = window.localStorage.getItem('pin');

  return firebaseGetCurrentlySignedInUser()
    .then((accessToken) => upload(`${CLIENTS}/${pin}/products`, file, accessToken));
};

export const getProducts = (filter = {}) => () => {
  const endpoint = `${REQUESTS}/${getAction(queues.GET_LIST)}?${prepareFilters(filter)}`;

  return getApiData(endpoint)
    .then((response) => response)
    .catch((err) => {
      console.log('err', err); // eslint-disable-line
    });
};

export const getDetailProduct = (bountyId) => {
  const action = getAction(queues.FETCH_BOUNTY);

  return getApiData(`${REQUESTS}/${action}?bountyId=${bountyId}`)
    .then((response) => response)
    .catch((err) => err);
};

export const onReviewProduct = (bounty, text, rating) => async (dispatch) => {
  const { stats, myself, id } = bounty;
  const me = firebaseGetCurrentUser();
  const comment = await newComment(myself, text, BOUNTY, id);
  const commentCount = stats && stats.commentCount ? stats.commentCount : 0;

  if (rating && typeof rating === 'number') {
    comment.reward = addValueOfPoints({
      amount: rating,
      currency: pointCurrency.RATING,
    });
  }

  dispatch(addBountyComments(bounty.id, comment));
  // increase stats also, to get immediate visual feedback on btn counter
  dispatch(increaseComments({ userId: me.uid, bountyId: id, commentCount }));
};
