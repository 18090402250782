import { currencyFormat, currencyTypes } from 'Models/Currency';

export function moneyConstructor(amount = null, currency = null) {
  return {
    amount: amount !== null && amount !== undefined ? amount : null,
    currency: currency !== null && currency !== undefined ? currency : null,
  };
}

// TODO: refactor after found a library for bigDecimal
export function getAmountAsBigDecimal(amount) {
  if (!amount || amount === '0' || amount === '0.0') {
    // return BigDecimal.ZERO;
    return 0;
  }

  try {
    const floatAmount = Number.parseFloat(amount);

    return (
      Number.isInteger(floatAmount)
        ? floatAmount.toString()
        : floatAmount.toFixed(2)
    );
  } catch (ex) {
    return 0;
  }
}

export function isZero(money) {
  return !money.amount || money.amount === '0';
}

export function formatMoney(reward = {}) {
  const { money, points } = reward;

  if (money && money.amount) {
    const { amount, currency } = reward.money;

    if (currency === currencyTypes.NONE) {
      return amount;
    }

    if (currencyFormat[currency]) {
      return `${currencyFormat[currency]} ${amount}`;
    }

    return `${amount} ${currency}`;
  }

  if (points && points.POINT) {
    return `${points.POINT.amount} ${+points.POINT.amount > 1 ? 'points' : 'point'}`;
  }

  return '';
}

export function addMoney(existingMoney, moneyToAdd) {
  if (!existingMoney) {
    return moneyToAdd;
  }

  if (!moneyToAdd || !moneyToAdd.amount) {
    return existingMoney;
  }

  if (existingMoney.currency !== moneyToAdd.currency) {
    //  TODO: needs to be implemented
    return 'error';
    // amt = amt.convertTo(getCurrencyAsEnum());
    // if (amt == null) {
    //   return null;
    // }
  }

  if (!existingMoney.amount) {
    return moneyToAdd;
  }

  return {
    amount: +existingMoney.amount + +moneyToAdd.amount,
    currency: existingMoney.currency,
  };
}

export function negateMoney(money) {
  const { amount, currency } = money;

  if (amount == null || amount === undefined) {
    return money;
  }

  const decimal = getAmountAsBigDecimal(amount);

  if (decimal === 0) {
    return money;
  }

  return {
    amount: -decimal,
    currency,
  };
}

export function subtractMoney(a = null, b = null) {
  if (b === null) {
    return a;
  }

  if (a === null) {
    return negateMoney(b);
  }

  return subtract(a, b);
}

function subtract(a, b) {
  if (b.amount === null || b.amount === undefined || getAmountAsBigDecimal(b.amount) === 0) {
    return { amount: a.amount, currency: a.currency };
  }

  if (a.currency !== b.currency) {
    return null;
  }

  const decimal = getAmountAsBigDecimal(a.amount);

  if (decimal === 0) {
    return negateMoney(b);
  }

  const diff = decimal - getAmountAsBigDecimal(b.amount);

  return {
    amount: diff.toString(),
    currency: a.currency,
  };
}

export function moneyToString({ amount, currency }) {
  return `${amount} ${currency}`;
}
