import IntlMessage from 'Util/IntlMessages';

export const SORT_ORDER_CREATOR = 'creatorUpdatedAtSortKey';
export const SORT_ORDER_BY_TYPE_RECENT = 'typeCreatedAtSortKey';
export const SORT_ORDER_BY_TYPE_POPULAR = 'typePopularitySortKey';
export const SORT_ORDER_BY_TYPE_DISCUSSED = 'typeDiscussedSortKey';
export const SORT_ORDER_BY_LIST_RECENT = 'listCreatedAtSortKey';
export const SORT_ORDER_BY_LIST_POPULAR = 'listPopularitySortKey';
export const SORT_ORDER_BY_LIST_DISCUSSED = 'listDiscussedSortKey';
export const SORT_ORDER_BY_STREAM_RECENT = 'streamCreatedAtSortKey';
export const SORT_ORDER_BY_STREAM_POPULAR = 'streamPopularitySortKey';
export const SORT_ORDER_BY_STREAM_DISCUSSED = 'streamDiscussedSortKey';
export const SORT_ORDER_RECENT = 'bountyCreatedAtSortKey';
export const SORT_ORDER_POPULAR = 'bountyPopularitySortKey';
export const SORT_ORDER_DISCUSSED = 'bountyDiscussedSortKey';

const RECENT = 'RECENT';
const POPULAR = 'POPULAR';
const DISCUSSED = 'DISCUSSED';

const RECENT_DATA = {
  value: RECENT,
  label: <IntlMessage id="label.recent" />,
  sortListValue: SORT_ORDER_BY_LIST_RECENT,
  sortTypeValue: SORT_ORDER_BY_TYPE_RECENT,
};

const POPULAR_DATA = {
  value: POPULAR,
  label: <IntlMessage id="label.popular" />,
  sortListValue: SORT_ORDER_BY_LIST_POPULAR,
  sortTypeValue: SORT_ORDER_BY_TYPE_POPULAR,
};

const DISCUSSED_DATA = {
  value: DISCUSSED,
  label: <IntlMessage id="label.discussed" />,
  sortListValue: SORT_ORDER_BY_LIST_DISCUSSED,
  sortTypeValue: SORT_ORDER_BY_TYPE_DISCUSSED,
};

export const sortFilter = {
  defaultValue: RECENT_DATA,
  options: {
    [RECENT]: RECENT_DATA,
    [POPULAR]: POPULAR_DATA,
    [DISCUSSED]: DISCUSSED_DATA,
  },
};

export const sortKeys = [
  SORT_ORDER_CREATOR,
  SORT_ORDER_BY_TYPE_RECENT,
  SORT_ORDER_BY_TYPE_POPULAR,
  SORT_ORDER_BY_TYPE_DISCUSSED,
  SORT_ORDER_BY_LIST_RECENT,
  SORT_ORDER_BY_LIST_POPULAR,
  SORT_ORDER_BY_LIST_DISCUSSED,
  SORT_ORDER_BY_STREAM_RECENT,
  SORT_ORDER_BY_STREAM_POPULAR,
  SORT_ORDER_BY_STREAM_DISCUSSED,
];
