import enMessages from '../locales/console-strings-en.json';
import esMessages from '../locales/console-strings-es.json';
import itMessages from '../locales/console-strings-it.json';
import plMessages from '../locales/console-strings-pl.json';
import roMessages from '../locales/console-strings-ro.json';
import ruMessages from '../locales/console-strings-ru.json';

const langSelector = (language, locale) => ({ messages: { ...language }, locale });

const enLang = langSelector(enMessages, 'en');
const esLang = langSelector(esMessages, 'es');
const itLang = langSelector(itMessages, 'it');
const plLang = langSelector(plMessages, 'pl');
const roLang = langSelector(roMessages, 'ro');
const ruLang = langSelector(ruMessages, 'ru');

export {
  enLang,
  esLang,
  itLang,
  plLang,
  roLang,
  ruLang,
};
