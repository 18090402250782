export const DISPLAY_STYLE = {
  INLINE: 'INLINE',
  ATTACH: 'ATTACH',
};

export const VISIBILITY = {
  ALL: 'ALL',
  PARTICIPANTS: 'PARTICIPANTS',
  CREATOR: 'CREATOR',
  NONE: 'NONE',
};

export const ATTACHMENT_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  PDF: 'PDF',
  FILE: 'FILE',
  YOUTUBE: 'YOUTUBE',
  WEBSITE: 'WEBSITE',
  OTHER: 'OTHER',
  GIF: 'GIF',
};

export const MEANING = {
  RESUME: 'RESUME',
  OTHER: 'OTHER',
};

export const ATTACHMENT_STATUS = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
};

export const IMAGE_MIME_TYPES = [
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/png',
];

export const VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/m4v',
  'video/mov',
  'video/avi',
  'video/flv',
  'video/mpg',
  'video/wmv',
  'application/x-mpegURL',
];

export const AUDIO_MIME_TYPES = [
  'audio/mpeg',
];

export const PDF_MIME_TYPE = 'application/pdf';

export const MIME_TYPES = {
  [ATTACHMENT_TYPE.IMAGE]: 'image/jpeg',
  [ATTACHMENT_TYPE.VIDEO]: 'video/mp4',
  [ATTACHMENT_TYPE.PDF]: PDF_MIME_TYPE,
  [ATTACHMENT_TYPE.AUDIO]: 'audio/mpeg',
  [ATTACHMENT_TYPE.OTHER]: 'application/octet-stream',
};
