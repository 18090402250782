import { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object, string } from 'prop-types';
import { Grid } from '@mui/material';

//  Components
import InputComponent from 'Common/components/InputComponent';
import DropdownComponent from 'Modules/posts/add/components/DropdownComponent';
import HtmlTooltip from 'Modules/posts/add/components/HtmlTooltip';

//  Actions/Selectors
import { updateDataSyncAction } from 'Store/actions/genericActions';
import * as modalSelectors from 'Modules/modal/store/selectors';
import { getMyCurrencySelector, settingsSelector } from 'Store/selectors/settings';

//  Other resources
import { getJobUnitsAsOptionsList } from 'Models/bounty/Bounty';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA, MODAL_OPTIONS_DATA } from 'Store/reducerProperties';
import { getDisableMode } from 'Modules/posts/add/utils/addBountyHelpers';
import IntlMessage from 'Util/IntlMessages';
import { selectedBountySelector } from '../../../companySents/store/selectors';

const GrossPayContainer = (props) => {
  const periodOptions = getJobUnitsAsOptionsList();

  const {
    label, optionsData, currency, selectedBounty, companySettings: { fees },
  } = props;
  const {
    inputsData: {
      minNetPay, maxNetPay, minGrossPay, maxGrossPay, minFee, maxFee,
    },
  } = props;

  const IS_ONBUDGET_PAY = !getDisableMode(fees);

  const payPeriodConfiguration = {
    text: 'bounty.paymentType',
    name: 'payPeriod',
    fullWidth: true,
    options: periodOptions,
    disabled: IS_ONBUDGET_PAY,
  };

  useEffect(() => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { currency });
    if (selectedBounty.jobInfo) {
      populateGrossMinPay();
    }
  }, []);

  const populateGrossMinPay = () => {
    const { jobInfo } = selectedBounty;

    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { payHighEnd: jobInfo?.payHighEnd || null });
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { payLowEnd: jobInfo?.payLowEnd || null });
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { minGrossPay: jobInfo?.minGrossPay || null });
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { maxGrossPay: jobInfo?.maxGrossPay || null });
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { minNetPay: jobInfo?.minNetPay });
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { maxNetPay: jobInfo?.maxNetPay });
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, { payPeriod: jobInfo?.payPeriod || null });
  };

  const handleChangesGross = ({ name, value }, feeLabel, budgetLabel) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: { money: { amount: value } } });

    if (fees && fees.JOBS.commissionType === 'ON_GROSS_PAY') {
      const calcFeeValue = setMoneyPay(value, fees.JOBS.commissionPercentage);
      const calcBudgetValue = calcBudget(value, calcFeeValue.money.amount);

      props.updateDataSyncAction(
        MODAL_NAMESPACE,
        MODAL_INPUTS_DATA,
        { [feeLabel]: calcFeeValue },
      );

      props.updateDataSyncAction(
        MODAL_NAMESPACE,
        MODAL_INPUTS_DATA,
        {
          [budgetLabel]: calcBudgetValue,
        },
      );
    } else if (fees && fees.JOBS.commissionType === 'ON_NET_PAY') {
      const feeValue = feeLabel === 'minFee' ? minFee : maxFee;
      const calcBudgetValue = calcBudget(value, feeValue?.money?.amount || 0);
      props.updateDataSyncAction(
        MODAL_NAMESPACE,
        MODAL_INPUTS_DATA,
        {
          [budgetLabel]: calcBudgetValue,
        },
      );
    }
  };

  const handleChangesNet = ({ name, value }, feeLabel, budgetLabel) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: { money: { amount: value } } });

    const calcFeeValue = setMoneyPay(value, fees?.JOBS?.commissionPercentage || 0);
    const budgetValue = budgetLabel === 'payLowEnd' ? minGrossPay : maxGrossPay;

    const calcBudgetValue = calcBudget(budgetValue?.money?.amount || 0, calcFeeValue.money.amount);

    if (fees && fees.JOBS.commissionType === 'ON_NET_PAY') {
      props.updateDataSyncAction(
        MODAL_NAMESPACE,
        MODAL_INPUTS_DATA,
        { [feeLabel]: calcFeeValue },
      );

      props.updateDataSyncAction(
        MODAL_NAMESPACE,
        MODAL_INPUTS_DATA,
        {
          [budgetLabel]: calcBudgetValue,
        },
      );
    }
  };

  const setMoneyPay = (value, percent) => ({ money: { amount: String(value * Number(percent)) } });
  const calcBudget = (grossValue, feesValues) => (
    { money: { amount: String(Number(grossValue) + Number(feesValues)) } }
  );

  const handleDropdownChanges = (name, value) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, { [name]: value });
  };

  return (
    <div className="mt-20">
      <h4>
        {label}
        {' '}
        (
        {currency}
        )
      </h4>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4}>
          <InputComponent
            fullWidth
            type="number"
            label={<IntlMessage id="label.minAmount" />}
            name="minGrossPay"
            value={minGrossPay?.money?.amount || ''}
            handleChanges={(e) => handleChangesGross(e.target, 'minFee', 'payLowEnd')}
            size="small"
            InputProps={{
              readOnly: IS_ONBUDGET_PAY,
            }}
            variant={IS_ONBUDGET_PAY ? 'filled' : 'standard'}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <InputComponent
            fullWidth
            type="number"
            label={<IntlMessage id="label.maxAmount" />}
            name="maxGrossPay"
            value={maxGrossPay?.money?.amount || ''}
            handleChanges={(e) => handleChangesGross(e.target, 'maxFee', 'payHighEnd')}
            size="small"
            InputProps={{
              readOnly: IS_ONBUDGET_PAY,
            }}
            variant={IS_ONBUDGET_PAY ? 'filled' : 'standard'}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <DropdownComponent
            handleChanges={handleDropdownChanges}
            value={optionsData[payPeriodConfiguration.name]}
            config={payPeriodConfiguration}
          />
        </Grid>
      </Grid>
      <h4 className="mt-20 position-relative">
        Estimated Monthly Net Pay Based on Gross (
        {currency}
        )
        <HtmlTooltip
          text="The<strong> Estimated Monthly Net Pay </strong>
            shows the applicant what the net salary could be after taxes."
        />
      </h4>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4}>
          <InputComponent
            fullWidth
            type="number"
            label={<IntlMessage id="label.minAmount" />}
            name="minNetPay"
            value={minNetPay?.money?.amount || ''}
            handleChanges={(e) => handleChangesNet(e.target, 'minFee', 'payLowEnd')}
            size="small"
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputComponent
            fullWidth
            type="number"
            label={<IntlMessage id="label.maxAmount" />}
            name="maxNetPay"
            value={maxNetPay?.money?.amount || ''}
            handleChanges={(e) => handleChangesNet(e.target, 'maxFee', 'payHighEnd')}
            size="small"
          />
        </Grid>
      </Grid>
    </div>
  );
};

GrossPayContainer.propTypes = {
  inputsData: object.isRequired,
  currency: string.isRequired,
  label: string.isRequired,
  companySettings: object.isRequired,
  selectedBounty: object.isRequired,
  optionsData: object.isRequired,
  updateDataSyncAction: func.isRequired,
};

const mapStateToProps = (state) => ({
  inputsData: modalSelectors.inputsData(state) || {},
  payPeriod: modalSelectors.optionsData(state).payPeriod || '',
  currency: getMyCurrencySelector(state) || 'USD',
  selectedBounty: selectedBountySelector(state) || {},
  optionsData: modalSelectors.optionsData(state) || {},
  companySettings: settingsSelector(state).companySettings || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(GrossPayContainer);
