import { getApiData } from 'Services/Api';
import { NotificationManager } from 'react-notifications';
import IntlMessage, { formatMessage } from 'Util/IntlMessages';
import { logoutUserFromFirebase } from 'Modules/authentication/store/operations';
import { HOME_ROUTE } from 'Constants/routes';

const handleLogin = (dispatch, history) => {
  getApiData('/user/info').then(({
    block,
    clientId,
  }) => {
    if (block) {
      NotificationManager.error(formatMessage({ id: 'notification.error.account.blocked' }));
      dispatch(logoutUserFromFirebase());
      return;
    }
    if (clientId !== window.localStorage.pin) {
      dispatch(logoutUserFromFirebase());
      NotificationManager.error(<IntlMessage id="notification.error.pinNotValid" />);
    } else {
      NotificationManager.success(<IntlMessage id="notification.success.login" />);
      history.push(HOME_ROUTE);
    }
  }).catch((error) => {
    dispatch(logoutUserFromFirebase());
    NotificationManager.error(error.message);
  });
};

const handleRestriction = (dispatch) => {
  getApiData('/user/info').then(({ block }) => {
    if (block) {
      NotificationManager.error(formatMessage({ id: 'notification.error.account.blocked' }));
      dispatch(logoutUserFromFirebase());
    }
  }).catch((error) => {
    dispatch(logoutUserFromFirebase());
    NotificationManager.error(error.message);
  });
};

export { handleLogin, handleRestriction };
