import CreateBountyDrawer from 'Modules/posts/add/containers/CreateBountyDrawer';
import AddComment from 'Modules/posts/stats/containers/AddComment';
import AddReply from 'Modules/posts/stats/containers/AddReply';
import CreateProductContainer from 'Modules/products/containers/CreateProductContainer';
import CreatePromotionContainer from 'Modules/promotions/containers/CreatePromotionContainer';
import { PROMOTION, PRODUCT } from 'Constants/bounty/bountyType';

export const CREATE_PRODUCT_CONTENT = 'CREATE_PRODUCT_CONTENT';
export const CREATE_BOUNTY_CONTENT = 'CREATE_BOUNTY_CONTENT';
export const CREATE_PROMOTION_CONTENT = 'CREATE_PROMOTION_CONTENT';
export const ADD_COMMENT_CONTENT = 'ADD_COMMENT_CONTENT';
export const REPLY_CONTENT = 'REPLY_CONTENT';

export const drawerContent = {
  [CREATE_PRODUCT_CONTENT]: CreateProductContainer,
  [CREATE_BOUNTY_CONTENT]: CreateBountyDrawer,
  [ADD_COMMENT_CONTENT]: AddComment,
  [REPLY_CONTENT]: AddReply,
  [CREATE_PROMOTION_CONTENT]: CreatePromotionContainer,
};

export const contentTypes = {
  [PROMOTION]: CREATE_PROMOTION_CONTENT,
  [PRODUCT]: CREATE_PRODUCT_CONTENT,
};

export const getBountyDrawerContentType = (bountyType) => (
  contentTypes[bountyType] || CREATE_BOUNTY_CONTENT
);
