/**
 * App Light Theme
 */
import { createTheme } from '@mui/material/styles';
import overrides from 'Util/theme/overrides';
import palette from 'Util/theme/palette';

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  components: {
    ...overrides,
    MuiButton: {
      styleOverrides: {
        ...overrides.MuiButton.overrides,
        root: {
          textTransform: 'none',
        },
        containedSecondary: {
          color: '#fff',
          backgroundColor: palette.common.textMuted,
          '&:hover': {
            color: '#fff',
            backgroundColor: palette.common.textMuted,
          },
        },
      },
    },
  },
  palette,
});

export default theme;
