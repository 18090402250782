import { formatMessage } from 'Util/IntlMessages';
import { hashToDictionary } from 'Common/utils/formatters';

export const NONE = 'NONE';
export const TRAVEL = 'TRAVEL';
export const HOUSING = 'HOUSING';
export const HEALTH_INSURANCE = 'HEALTH_INSURANCE';
export const MEALS = 'MEALS';
export const UNKNOWN = 'UNKNOWN';

export const BENEFITS = {
  NONE,
  HOUSING,
  TRAVEL,
  HEALTH_INSURANCE,
  MEALS,
  UNKNOWN,
};

export const BENEFITS_LABELS = {
  [TRAVEL]: formatMessage({ id: 'benefits.label.travel' }),
  [HOUSING]: formatMessage({ id: 'benefits.label.housing' }),
  [HEALTH_INSURANCE]: formatMessage({ id: 'benefits.label.healthInsurance' }),
  [MEALS]: formatMessage({ id: 'benefits.label.meals' }),
};

export const BENEFITS_DICTIONARY = hashToDictionary(BENEFITS_LABELS);
