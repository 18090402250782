export const pointCurrency = {
  NONE: 'NONE',
  UNKNOWN: 'UNKNOWN',
  STAR: 'STAR',
  STAR2: 'STAR2',
  STAR3: 'STAR3',
  POINT: 'POINT',
  RATING: 'RATING',
  REPU: 'REPU',
  LIKE: 'LIKE',
  MIN: 'MIN',
  INVITE: 'INVITE',
};

export const starsValue = {
  [pointCurrency.STAR]: 1,
  [pointCurrency.STAR2]: 2,
  [pointCurrency.STAR3]: 3,
};

export function pointCurrencyAsEnum(currency) {
  if (!currency) {
    return pointCurrency.NONE;
  }

  return pointCurrency[currency] || pointCurrency.UNKNOWN;
}
export function normalizeCurrency(currency) {
  switch (currency) {
    case pointCurrency.STAR2:
    case pointCurrency.STAR3:
      return pointCurrency.STAR;
    default:
      return currency;
  }
}

export function multiplier(currency) {
  return starsValue[currency] || 1;
}
