import { MODAL_NAMESPACE } from 'Store/namespaces';
import { getHandlers } from 'Store/reducers/helpers';
import {
  MODAL_SETTINGS,
  DRAWER_SETTINGS,
  MODAL_INPUTS_DATA,
  MODAL_INPUT_LANGUAGE,
  MODAL_OPTIONS_DATA,
  MODAL_SURVEY_OPTIONS_DATA,
  MODAL_RATING,
  MODAL_SELECTED_BOUNTIES,
  MODAL_SELECTED_MEMBERS,
  MODAL_AVAILABLE_PTS,
  MODAL_IS_LOADING,
  MODAL_SELECTED_POST,
  MODAL_VALIDATION_ERRORS,
} from 'Store/reducerProperties';

const handlersReducer = {
  ...getHandlers(MODAL_NAMESPACE),
};

// initial state
const INIT_STATE = {
  [MODAL_SETTINGS]: {
    isOpen: false,
  },
  [DRAWER_SETTINGS]: {
    isOpen: false,
  },
  [MODAL_IS_LOADING]: false,
  [MODAL_INPUTS_DATA]: {},
  [MODAL_RATING]: {},
  [MODAL_OPTIONS_DATA]: {},
  [MODAL_SURVEY_OPTIONS_DATA]: {},
  [MODAL_SELECTED_POST]: {},
  [MODAL_SELECTED_BOUNTIES]: {},
  [MODAL_SELECTED_MEMBERS]: {},
  [MODAL_AVAILABLE_PTS]: {},
  [MODAL_INPUT_LANGUAGE]: '',
  [MODAL_VALIDATION_ERRORS]: {},
};

export default function modalReducer(state = INIT_STATE, action = {}) {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}
