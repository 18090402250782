import country from 'country-codes-list';

const excludedCountries = ['AQ', 'BQ', 'EH'];

/** Returns an object of the form
 * {
 *   0: {label: 'Andorra', code: 'AD', phoneCode: '+376'},
 *   1: {label: 'Afghanistan', code: 'AF', phoneCode: '+93'},
 *   ...
 * }
 */

export const CountriesList = country.customArray(
  { label: '{countryNameEn}', code: '{countryCode}', phoneCode: '+{countryCallingCode}' },
).filter((item) => !excludedCountries.some((element) => element === item.code) ?? item)
  .sort((a, b) => a.label.localeCompare(b.label));

export const CountriesDictionary = CountriesList.map(({ label, code }) => ({ label, value: code }));

/** Returns an object of the form
 * {
 *   ...
 *   "AF": "Afghanistan",
 *   "AG": "Antigua and Barbuda",
 *   ...
 * }
 */
export const CountriesNames = country.customList('countryCode', '{countryNameEn}');
