import { ACTIONS } from 'Store/actions/bountyActions';
import { REGION_LEVELS } from 'Models/User';
import { OTHER_BONUS } from 'Constants/bounty/jobUnit';

const payObj = {
  money: {
    amount: null,
    currency: 'USD',
  },
};

// TODO: responseCount: 0,  crushed in some cases. example link http://joblio.local.ro:3001/v2_jobs/JOB/-Mj47jhxGg_HD4dOPihg

const initialState = {
  bounty: {
    agentInfo: {
      createdOn: '',
      editedOn: '',
    },
    attachments: {
      attachments: [],
    },
    author: {
      id: '',
      name: '',
      avatar: '',
      rating: null,
      reputation: '',
      kind: '',
    },
    createdAt: null,
    creator: {
      avatar: '',
      id: '',
      latinizedName: '',
      name: '',
      reputation: '',
    },
    creatorUpdatedAtSortKey: '',
    description: {
      text: '',
    },
    editedAt: null,
    id: '',
    jobInfo: {
      payLowEnd: { ...payObj },
      payHighEnd: { ...payObj },
      minFee: { ...payObj },
      maxFee: { ...payObj },
      minGrossPay: { ...payObj },
      maxGrossPay: { ...payObj },
      minNetPay: { ...payObj },
      maxNetPay: { ...payObj },
      payPeriod: null,
      bonus: { ...payObj },
      bonusType: OTHER_BONUS,
      hrsPerWeekMin: null,
      hrsPerWeekMax: null,
      benefits: {},
      skillSet: {},
      industries: {},
      experienceYears: null,
      applicantsSourceRegion: REGION_LEVELS.GLOBAL,
    },
    listCreatedAtSortKey: '',
    listDiscussedSortKey: '',
    listId: '',
    listPopularitySortKey: '',
    metaInfo: {
      outbound: false,
      incomplete: false,
    },
    outboundResponses: {},
    owner: {
      id: '',
      name: '',
      avatar: '',
      reputation: '',
      kind: '',
    },
    postedAt: null,
    rating: {
      ratingCount: 0,
      starPointsValue: 1,
      totalStars: 0,
    },
    shortId: '',
    state: 'ACTIVE',
    stats: {
      commentCount: 0,
      inviteeCount: 0,
      likesCount: 0,
      participantCount: 0,
      pendingChats: 0,
      reach: 0,
      rebounties: 0,
      responseCount: 0,
      sharesRecv: 0,
      sharesSent: 0,
      subBounties: 0,
      talking: 0,
      viewsCount: 0,
    },
    streamCreatedAtSortKey: '',
    streamDiscussedSortKey: '',
    streamPopularitySortKey: '',
    title: '',
    type: null,
    typeCreatedAtSortKey: '',
    typeDiscussedSortKey: '',
    typePopularitySortKey: '',
    updatedAt: 0,
    visibility: 'GLOBAL',
  },
  isLoading: false,
};

const bountyReducer = (state = initialState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.GET_BOUNTY:
      return {
        ...state,
        bounty: payload,
      };

    case ACTIONS.RESET:
      return {
        ...state,
        ...initialState,
      };

    case ACTIONS.LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default bountyReducer;
