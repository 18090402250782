import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { func, object, string } from 'prop-types';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import makeStyles from '@mui/styles/makeStyles';

//  Components
import SwitchField from 'Common/components/fields/SwitchField';
import InputField from 'Common/components/fields/InputField';
import ModalButtons from 'Modules/modal/components/ModalButtons';

//  Actions
import { addBountyComments, getBountyCommentStats, increaseComments } from 'Services/bounty/StatsService';
import { addResponseComments, getReplyCommentStats, increaseResponseComments } from 'Services/response/StatsService';
import { firebaseGetCurrentUser } from 'Services/FirebaseService';
import { getMyUser } from 'Services/BaseService';

//  Selectors
import { closeDrawer } from 'Modules/modal/store/operations';
import { drawerSettingsSelector } from 'Modules/modal/store/selectors';
import { userDataSelector, settingsSelector } from 'Store/selectors/settings';

//  Others
import { identityMode } from 'Models/IdentityMode';
import { commentType as COMMENT_TYPE, identityType } from 'Constants/bounty/bounty';
import { getIdentityMode } from 'Models/OpManager';
import { validate } from 'Util/validation/validate';
import { CREATE_COMMENT_CONSTRAINTS } from 'Util/validation/constraints';
import { inputs } from 'Constants/inputs';
import { options } from 'Constants/options';

const propTypes = {
  commentType: string.isRequired,
  closeDrawer: func.isRequired,
  bounty: object.isRequired,
  settings: object.isRequired,
  userData: object.isRequired,
  addBountyComments: func.isRequired,
  increaseComments: func.isRequired,
  addResponseComments: func.isRequired,
  increaseResponseComments: func.isRequired,
};

const AddComment = (props) => {
  const [commentCount, setCommentCount] = useState(0);
  const classes = useStyles();

  const {
    bounty, commentType, settings, userData,
  } = props;

  const initAnonymity = () => {
    const mode = getIdentityMode(settings, userData);
    const isAnonymous = mode.identityType !== identityType.REAL;

    formik.setFieldValue(options.postAnonymously.name, isAnonymous);
  };

  const initCommentCount = () => {
    const me = firebaseGetCurrentUser();

    if (bounty?.bountyInfo) {
      return (
        getReplyCommentStats({
          ownerId: bounty.owner && bounty.owner.id,
          bountyId: bounty.bountyInfo.id,
          responseId: bounty.id,
        })
          .then((response) => setCommentCount(response))
      );
    }

    getBountyCommentStats({
      userId: me.uid,
      bountyId: bounty.id,
    })
      .then((response) => setCommentCount(response));
  };

  useEffect(() => {
    initAnonymity();
    initCommentCount();
  }, []);

  const addComment = async (values, { setErrors, setSubmitting }) => {
    const { commentText, postAnonymously } = values;
    const errors = validate(values, CREATE_COMMENT_CONSTRAINTS);

    if (Object.keys(errors).length) {
      setSubmitting(false);
      return setErrors(errors);
    }

    const creator = await getMyUser();
    const { uid } = firebaseGetCurrentUser();
    const identityModeValue = postAnonymously ? identityMode.ANON : identityMode.REAL;

    const comment = {
      creator,
      sentAt: new Date().getTime(),
      text: commentText,
      author: bounty.myself || null,
      identityMode: identityModeValue,
      commentType,
      entityId: bounty.id,
    };

    if (comment.commentType === COMMENT_TYPE.BOUNTY) {
      props.addBountyComments(bounty.id, comment);
      props.increaseComments({
        userId: uid,
        bountyId: bounty.id,
        commentCount,
      });
    }

    if (comment.commentType === COMMENT_TYPE.RESPONSE) {
      props.addResponseComments(bounty.id, comment);
      props.increaseResponseComments({
        ownerId: bounty && bounty.owner ? bounty.owner.id : null,
        bountyId: bounty?.bountyInfo?.id,
        responseId: bounty?.id,
        commentCount,
      });
    }

    props.closeDrawer();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      [inputs.commentText.name]: '',
      [options.postAnonymously.name]: false,
    },
    onSubmit: addComment,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <Box mt={2} className={classes.root}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <InputField
          {...inputs.commentText}
          formik={formik}
        />
        <SwitchField
          {...options.postAnonymously}
          formik={formik}
        />
        <ModalButtons
          onDismiss={props.closeDrawer}
          onSubmit={formik.handleSubmit}
          submitName="Send"
          type="submit"
        />
      </form>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  userData: userDataSelector(state).data || {},
  settings: settingsSelector(state) || {},
  bounty: drawerSettingsSelector(state)?.selectedBounty || {},
  commentType: drawerSettingsSelector(state)?.commentType || COMMENT_TYPE.BOUNTY,
});

const mapDispatchToProps = {
  addBountyComments,
  addResponseComments,
  increaseComments,
  increaseResponseComments,
  closeDrawer,
};

AddComment.propTypes = propTypes;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '350px',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100vw - 50px)',
    },
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(AddComment);
