import { func, object, bool } from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';

const SwitchComponent = (props) => {
  const { value, handleChanges, config } = props;
  const {
    name, text, skipIntl, ...otherProps
  } = config;
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div>
      <FormControlLabel
        key={`option-${name}`}
        classes={{ root: classes.root }}
        control={(
          <Switch
            name={name}
            checked={!!value}
            onChange={handleChanges}
            color="primary"
            className="switch-btn"
            {...otherProps}
          />
        )}
        label={skipIntl ? text : intl.formatMessage({ id: text })}
        labelPlacement="start"
      />
    </div>
  );
};

SwitchComponent.propTypes = {
  handleChanges: func.isRequired,
  value: bool,
  skipIntl: bool,
  config: object.isRequired,
};

SwitchComponent.defaultProps = {
  value: false,
  skipIntl: false,
};

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
  },
}));

export default SwitchComponent;
