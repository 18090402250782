import { getAction, getQueue } from 'Util/api/queues';
import { getApplicationConfig } from 'Util/AppUtils';
import { REQUESTS } from 'Constants/apiRoutes';
import {
  getClientId,
  getUserAgent,
  getHeaders,
  checkStatus,
  responseParser,
  errorParser,
} from 'Util/api/apiHelpers';
import {
  firebaseQueuesRef,
  firebaseGetCurrentUser,
  firebaseGetTimestamp,
  firebaseGetCurrentlySignedInUser,
} from './FirebaseService';

const { API_BASE_URL, API_REPORT } = getApplicationConfig();

export const addToQueue = (TYPE, data) => {
  const userId = firebaseGetCurrentUser().uid;
  const clientId = getClientId();
  const meta = {
    action: getAction(TYPE),
    userId,
    clientId,
    createdAt: new Date().getTime(),
    addedAt: firebaseGetTimestamp(),
    agent: getUserAgent(),
    sync: null,
  };

  const dataToSet = {
    meta,
    ...data,
  };

  return firebaseQueuesRef(getQueue(TYPE)).push()
    .set(dataToSet);
};

export async function getApiData(path) {
  const token = await firebaseGetCurrentlySignedInUser();
  const headers = { headers: getHeaders(token) };
  const url = `${API_BASE_URL}${path}`;

  return fetch(url, headers)
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
}

export async function postRequest(actionType, data = {}) {
  const userId = firebaseGetCurrentUser().uid;
  const token = await firebaseGetCurrentlySignedInUser();
  const clientId = getClientId();
  const headers = getHeaders(token);
  const action = getAction(actionType);
  const url = `${API_BASE_URL}${REQUESTS}/${action}`;
  const meta = {
    action,
    userId,
    clientId,
    createdAt: new Date().getTime(),
    // addedAt: firebaseGetTimestamp(),
    agent: getUserAgent(),
    // sync: null,
  };
  const dataToSet = {
    meta,
    ...data,
  };
  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(dataToSet),
  })
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
}

export function postApiData(path, token, data = {}) {
  const headers = getHeaders(token);
  const url = `${API_BASE_URL}${path}`;
  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
}

export function postWithEmptyResponse(path, token) {
  const headers = getHeaders(token);
  const url = `${API_BASE_URL}${path}`;
  return fetch(url, {
    method: 'POST',
    headers,
  })
    .then(checkStatus)
    .catch(errorParser);
}

export function getDataUsingUser(path, user) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${btoa(`${user.email}:${user.password}`)}`,
    },
  };
  const url = `${API_BASE_URL}${path}`;

  return fetch(url, options)
    .then((res) => res.json())
    .catch((error) => error);
}

export function requestTerminal(token, command) {
  const options = {
    headers: getHeaders(token, true),
    method: 'GET',
  };
  const url = `${API_BASE_URL}/shell?cmd=${command}`;
  return fetch(url, options)
    .then(checkStatus)
    .catch(errorParser);
}

export function upload(path, file, token) {
  const url = `${API_BASE_URL}${path}`;
  const formData = new FormData();
  formData.append('file', file);

  const options = {
    headers: getHeaders(token, true),
    method: 'POST',
    body: formData,
  };

  return fetch(url, options)
    .then(checkStatus)
    .then(responseParser)
    .catch(errorParser);
}

export function getReport(bodyReports) {
  const clientId = getClientId();
  const options = {
    headers: {
      Authorization: `Bearer ${bodyReports.accessToken}`,
      'Content-Type': 'application/json',
      'X-edge-agent': getUserAgent(),
    },
  };

  if (clientId) {
    options.headers['X-edge-client-id'] = clientId;
  }

  const body = {
    reportId: bodyReports.selectedReportType,
    subType: bodyReports.selectedReportSubType,
    fromDateString: bodyReports.txt_from_date,
    toDateString: bodyReports.txt_to_date,
    userFilter: bodyReports.userFilter,
    format: 'json',
    clientId: clientId || null,
  };

  return fetch(API_REPORT, { method: 'POST', body: JSON.stringify(body), ...options })
    .then((res) => res.json());
}
