import { firebaseGetSentRef } from 'Services/FirebaseService';
import { getInboundBountyRef } from 'Services/bounty/CommonService';

export const INBOUND_GLOBAL = 'INBOUND_GLOBAL';
export const SENT_IN_BOUNTY = 'SENT_IN_BOUNTY';
export const SENT_ITEMS = 'SENT_ITEMS';

function getBountyOutboundResponsesRef(bounty, user) {
  return getInboundBountyRef(bounty, user).child('outboundResponses');
}

export function getOutboundResponseRefs(bounty, bountyResponse, user) {
  const ref1 = getBountyOutboundResponsesRef(bounty, user).child(bountyResponse.id);
  const ref2 = firebaseGetSentRef(user.uid).child(bountyResponse.id).child('response');

  return {
    [SENT_IN_BOUNTY]: ref1,
    [SENT_ITEMS]: ref2,
  };
}
