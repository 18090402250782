import { bool, func } from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import { AppConfig } from 'Util/AppUtils';
import IntlMessage from 'Util/IntlMessages';

const propTypes = {
  acceptedTerms: bool.isRequired,
  onAcceptingTerms: func.isRequired,
};

const CheckTermsAndConditions = ({ acceptedTerms, onAcceptingTerms }) => (
  <FormGroup check className="mb-20">
    <Label check>
      <Input
        type="checkbox"
        checked={acceptedTerms}
        onChange={onAcceptingTerms}
      />
      {' '}
      <IntlMessage id="label.iAgreeToThe" />
      <a
        href={AppConfig.TERMS_OF_SERVICE_URL}
        target="_blank"
        rel="noopener noreferrer"
        className="color-green"
      >
        <u><IntlMessage id="labels.u.termsAndConditions" /></u>
      </a>
    </Label>
  </FormGroup>
);

CheckTermsAndConditions.propTypes = propTypes;

export default CheckTermsAndConditions;
