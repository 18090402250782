import { isAllowed } from 'Models/Settings';
import * as operations from 'Models/Op';
import {
  TALENT_SEARCH,
  RECOMMENDATION,
  REALESTATE,
  COACHING,
  CHALLENGE,
  SURVEY,
  CLASSIFIED,
  MCQ,
  activityTypes,
  CHECKLIST,
  FUNDING,
  JOB,
  THREAD,
  BANNER,
  NEWS,
  MATCH,
  LIVE_STREAM,
  TICKET,
  TRIVIA,
  LOTTERY,
} from '../../bounty/constants/activityTypes';

export const featureNames = {
  DESCRIPTION: 'DESCRIPTION',
  DESCRIPTION_INFO: 'DESCRIPTION_INFO',
  CATEGORY: 'CATEGORY',
  ATTACHMENTS: 'ATTACHMENTS',
  REWARD: 'REWARD',
  REWARD_OPTIONAL: 'REWARD_OPTIONAL',
  REWARD_RECEIVERS: 'REWARD_RECEIVERS',
  PRICE: 'PRICE',
  RATING_VALUE: 'RATING_VALUE',
  EXPIRY_DATE: 'EXPIRY_DATE',
  DISTRIBUTION_AREA: 'DISTRIBUTION_AREA',
  DIST_TARGET_GROUP: 'DIST_TARGET_GROUP',
  DIST_EXCLUDE_GROUP: 'DIST_EXCLUDE_GROUP',
  REQUEST_BADGE: 'REQUEST_BADGE',
  CONTROL_ANONYMITY: 'CONTROL_ANONYMITY',
  CONTROL_RESPONSES_PRIVACY: 'CONTROL_RESPONSES_PRIVACY',
  DISPLAY_IN: 'DISPLAY_IN',
  CHOICES: 'CHOICES',
  BENEFITS: 'BENEFITS',
  PAYMENT: 'PAYMENT',
  COUNTRY_CODE: 'COUNTRY_CODE',
  JOB_POST: 'JOB_POST',
  TITLE: 'TITLE',
  TARGET_URL: 'TARGET_URL',
  POST_ON_BEHALF_OF: 'POST_ON_BEHALF_OF',
  STREAM_DETAILS: 'STREAM_DETAILS',
  AVAILABILITY: 'AVAILABILITY',
  QUANTITY: 'QUANTITY',
  USE_QUANTITY: 'USE_QUANTITY',
  TAGS: 'TAGS',
  SKU: 'SKU',
  SCHEDULE_FOR: 'SCHEDULE_FOR',
  TIME_TO_RESPOND: 'TIME_TO_RESPOND',
  TITLE_LANGUAGE_SELECTOR: 'TITLE_LANGUAGE_SELECTOR',
  DESCRIPTION_LANGUAGE_SELECTOR: 'DESCRIPTION_LANGUAGE_SELECTOR',
};

export const features = {
  [featureNames.DESCRIPTION]: {
    enabled: true,
    op: null,
    types: allExcept([BANNER, JOB]),
    conditionName: 'hasDescription',
  },
  [featureNames.DESCRIPTION_INFO]: {
    enabled: false,
    op: null,
    types: allExcept([SURVEY]),
    conditionName: 'hasDescriptionInfo',
  },
  [featureNames.CATEGORY]: {
    enabled: true,
    op: null,
    types: allExcept([SURVEY]),
    conditionName: 'hasCategory',
  },
  [featureNames.ATTACHMENTS]: { // foto, video, audio, file, image
    enabled: true,
    op: null,
    types: null,
    conditionName: 'hasAttachments',
  },
  [featureNames.REWARD]: {
    enabled: true,
    op: operations.ATTACH_REWARD.name,
    types: [
      TALENT_SEARCH,
      RECOMMENDATION,
      CHALLENGE,
      SURVEY,
      CHECKLIST,
      MCQ,
      TRIVIA,
      LOTTERY,
    ],
    conditionName: 'hasReward',
  },
  [featureNames.REWARD_OPTIONAL]: {
    enabled: true,
    op: null,
    types: [
      SURVEY,
      CHECKLIST,
      MCQ,
      LOTTERY,
    ],
    conditionName: 'hasOptionalReward',
  },
  [featureNames.REWARD_RECEIVERS]: {
    enabled: true,
    op: null,
    types: [
      REALESTATE,
      TALENT_SEARCH,
    ],
    conditionName: 'hasRewardReceivers',
  },
  [featureNames.PRICE]: {
    enabled: true,
    op: null,
    types: [CLASSIFIED],
    conditionName: 'hasPrice',
  },
  [featureNames.RATING_VALUE]: {
    enabled: false,
    op: null,
    types: [CHALLENGE],
    conditionName: 'hasRatingValue',
  },
  [featureNames.EXPIRY_DATE]: {
    enabled: true,
    op: null,
    types: [
      COACHING,
      THREAD,
      CHALLENGE,
      SURVEY,
      LOTTERY,
    ],
    conditionName: 'hasExpiryFeature',
  },
  [featureNames.DISTRIBUTION_AREA]: {
    enabled: true,
    op: null,
    types: [
      TALENT_SEARCH,
      RECOMMENDATION,
    ],
    conditionName: 'hasDistributionArea',
  },
  [featureNames.DIST_TARGET_GROUP]: {
    enabled: true,
    op: operations.CONTROL_BOUNTY_DISTRIBUTION.name,
    types: allExcept([JOB, TICKET, TRIVIA]),
    conditionName: 'hasDistTargetGroup',
  },
  [featureNames.DIST_EXCLUDE_GROUP]: {
    enabled: false,
    op: operations.CONTROL_BOUNTY_DISTRIBUTION.name,
    types: null,
    conditionName: 'hasDistExcludeGroup',
  },
  [featureNames.REQUEST_BADGE]: {
    enabled: true,
    op: operations.REQUEST_ROR.name,
    types: allExcept([
      CHALLENGE,
      SURVEY,
      CLASSIFIED,
      BANNER,
      NEWS,
    ]),
    conditionName: 'hasRequestBadge',
  },
  [featureNames.CONTROL_ANONYMITY]: {
    enabled: true,
    op: operations.CTRL_IDENTITY.name,
    types: null,
    conditionName: 'hasControlAnonymity',
  },
  [featureNames.CONTROL_RESPONSES_PRIVACY]: {
    enabled: true,
    op: null,
    types: [CHALLENGE],
    conditionName: 'hasControlResponsesPrivacy',
  },
  [featureNames.DISPLAY_IN]: {
    enabled: true,
    op: null,
    types: allExcept([TRIVIA]),
    conditionName: 'hasDisplayIn',
  },
  [featureNames.CHOICES]: {
    enabled: true,
    op: null,
    types: [SURVEY, MCQ, CHECKLIST, FUNDING],
    conditionName: 'hasSurvey',
  },
  [featureNames.BENEFITS]: {
    enabled: true,
    op: null,
    types: [JOB],
    conditionName: 'hasBenefits',
  },
  [featureNames.PAYMENT]: {
    enabled: true,
    op: null,
    types: [JOB],
    conditionName: 'hasPayment',
  },
  [featureNames.COUNTRY_CODE]: {
    enabled: true,
    op: null,
    types: [JOB],
    conditionName: 'hasCountryCode',
  },
  [featureNames.JOB_POST]: {
    enabled: true,
    op: null,
    types: [JOB],
    conditionName: 'hasJobPost',
  },
  [featureNames.TARGET_URL]: {
    enabled: true,
    op: null,
    types: [NEWS, BANNER, LOTTERY],
    conditionName: 'hasTargetUrl',
  },
  [featureNames.POST_ON_BEHALF_OF]: {
    enabled: true,
    op: operations.CAN_GHOSTWRITE.name,
    types: allExcept([NEWS, MATCH, BANNER, JOB, TICKET]),
    conditionName: 'hasPostOnBehalfOf',
  },
  [featureNames.STREAM_DETAILS]: {
    enabled: true,
    op: null,
    types: [LIVE_STREAM],
    conditionName: 'hasStreamDetails',
  },
  [featureNames.AVAILABILITY]: {
    enabled: true,
    op: null,
    types: [TICKET],
    conditionName: 'hasAvailability',
  },
  [featureNames.QUANTITY]: {
    enabled: true,
    op: null,
    types: [TICKET],
    conditionName: 'hasQuantity',
  },
  [featureNames.USE_QUANTITY]: {
    enabled: true,
    op: null,
    types: [TICKET],
    conditionName: 'hasUseQuantity',
  },
  [featureNames.TAGS]: {
    enabled: true,
    op: null,
    types: [TICKET],
    conditionName: 'hasTags',
  },
  [featureNames.SKU]: {
    enabled: true,
    op: null,
    types: [TICKET],
    conditionName: 'hasSku',
  },
  [featureNames.SCHEDULE_FOR]: {
    enabled: true,
    op: null,
    types: [TRIVIA],
    conditionName: 'hasScheduleFor',
  },
  [featureNames.TIME_TO_RESPOND]: {
    enabled: true,
    op: null,
    types: [MCQ],
    conditionName: 'hasTimeToRespond',
  },
  [featureNames.DESCRIPTION_LANGUAGE_SELECTOR]: {
    enabled: true,
    op: null,
    types: [JOB],
    conditionName: 'hasTranslatableDescription',
  },
  [featureNames.TITLE_LANGUAGE_SELECTOR]: {
    enabled: true,
    op: null,
    types: [JOB],
    conditionName: 'hasTranslatableTitle',
  },
};

function allExcept(typesToExclude) {
  return activityTypes.filter((type) => !typesToExclude.includes(type));
}

export function appliesTo({
  feature, activityType, settings, userData,
}) {
  const featuresProps = features[feature];

  return featuresProps.enabled
    && (featuresProps.types === null || featuresProps?.types?.includes(activityType))
    && (featuresProps.op === null || isAllowed({ op: featuresProps.op, settings, userData }));
}

export function setupFeatures({ activityType, settings, userData }) {
  const uiElements = {};
  Object.keys(features).forEach((feature) => {
    const featureData = features[feature];
    uiElements[featureData.conditionName] = appliesTo({
      feature, activityType, settings, userData,
    });
  });

  return uiElements;
}
