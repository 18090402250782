const overrides = {
  MuiButton: {
    styleOverrides: {
      contained: {
        color: '#fff',
      },
      containedSecondary: {
        color: '#fff',
      },
      containedPrimary: {
        color: '#fff',
      },
    },
  },
};

export default overrides;
