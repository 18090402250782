import { AUTH_NAMESPACE } from 'Store/namespaces';
import { getHandlers } from 'Store/reducers/helpers';
import {
  LOGGED_USER,
  USER_PROFILE,
  REGISTRATION_ACTIVE_STEP,
  USER_PRIVATE_INFO,
  USER_ENDORSEMENTS,
} from 'Store/reducerProperties';

const INIT_STATE = {
  [LOGGED_USER]: {
    data: {
      uid: '',
      email: '',
      emailVerified: false,
      displayName: '',
      isAnonymous: false,
      photoURL: '',
    },
    isLoading: false,
  },
  [USER_PROFILE]: {
    data: {
      basicInfo: {
        address: {
          phoneNumber: '',
          phoneCode: '',
        },
        citizenship: '',
        dob: '',
        email: '',
        latinizedName: '',
        firstName: '',
        middleName: '',
        lastName: '',
        secondLastName: '',
        gender: '',
        skillset: '',
        mailingAddress: '',
        passportInfo: '',
        personalNumericalCode: '',
        sameAddress: false,
      },
      label: '',
      description: '',
      rating: '',
      attachment: {},
      identity: {},
      user: {},
    },
  },
  [USER_PRIVATE_INFO]: {},
  [USER_ENDORSEMENTS]: {},
  [REGISTRATION_ACTIVE_STEP]: null,
};

const handlersReducer = getHandlers(AUTH_NAMESPACE);

export default function authUserReducer(state = INIT_STATE, action = {}) {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}
