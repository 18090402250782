import { object, func, string } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

// Components
import DescriptionContainer from 'Modules/posts/add/containers/DescriptionContainer';
import PriceContainer from 'Modules/posts/add/containers/PriceContainer';
import ExpiryContainer from 'Modules/posts/add/containers/ExpiryContainer';
import AddProductContainer from 'Modules/promotions/containers/AddProductContainer';
import PromotionTypeContainer from 'Modules/promotions/containers/PromotionTypeContainer';
import CouponContainer from 'Modules/promotions/containers/CouponContainer';
import PromotionAvailabilityContainer from 'Modules/promotions/containers/PromotionAvailabilityContainer';

// Others
import { PROMOTION } from 'Constants/bounty/bountyType';
import { PROMOTION as PROMOTIONS_ACTIVITY_TYPE } from 'Modules/posts/bounty/constants/activityTypes';
import { deliveryFee, priceObject, DISCOUNT } from 'Modules/promotions/constants/promotion';
import { getProductBackgroundImage, textTruncate } from 'Util/helpers';

const propTypes = {
  selectedProduct: object.isRequired,
  classes: object.isRequired,
  selectedPromotionType: string.isRequired,
  clearProduct: func.isRequired,
};

const CreatePromotionComponent = (props) => {
  const {
    selectedProduct = {}, classes, selectedPromotionType, clearProduct,
  } = props;
  const productDetails = selectedProduct.product || selectedProduct;
  const backgroundImage = getProductBackgroundImage(productDetails);
  const hasSelectedProduct = Object.keys(productDetails).length;
  const isDiscountType = selectedPromotionType === DISCOUNT.value;
  // TODO implement a mechanism for displaying elements similar to the one on createBounty if more cases arise

  return (
    <div className="pt-30">
      <PromotionTypeContainer />
      <DescriptionContainer bountyType={PROMOTION} />
      {isDiscountType && (
        <>
          <CouponContainer />
          <PromotionAvailabilityContainer />
        </>
      )}
      {!isDiscountType && (
        <>
          <PriceContainer priceObject={deliveryFee} />
          <PriceContainer priceObject={priceObject} />
          <div className="mt-30">
            <ExpiryContainer activityType={PROMOTIONS_ACTIVITY_TYPE} />
          </div>
        </>
      )}
      <div className="mt-30 ml-10">
        {!!hasSelectedProduct
        && (
          <div className={`flex-left-h ${classes.productTitle} position-relative`}>
            <div
              style={{ ...backgroundImage, height: '50px', width: '50px' }}
              className="image-post product-image-placeholder"
            />
            <div className="text-dark pl-10">
              {!!productDetails.name && textTruncate(productDetails.name, 25)}
            </div>
            <sup className={classes.clearContainer}>
              <IconButton aria-label="delete" size="small" onClick={clearProduct}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </sup>
          </div>
        )}
      </div>
      <AddProductContainer />
    </div>
  );
};

const useStyles = () => ({
  productTitle: {
    lineHeight: 4,
  },
  clearContainer: {
    top: '.5rem',
  },
});

CreatePromotionComponent.propTypes = propTypes;

export default withStyles(useStyles, { withTheme: true })(CreatePromotionComponent);
