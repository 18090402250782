import { AUTO, CREATOR, OWNER, EXTERNAL } from 'Constants/authorPref';

/**
 * Get author to display
 * @param {object} data -> can be bounty/response/comment
 * @param {string} pref
 */
export function getDisplayAuthor(data, pref = AUTO) {
  const {
    creator, owner, author, onBehalfOf,
  } = data;

  if (onBehalfOf && Object.keys(onBehalfOf).length) {
    return { ...onBehalfOf, isOnBehalfOf: true };
  }

  return getDisplayUser(pref, creator, owner, author) || {};
}

export function getDisplayUser(pref, creator, owner, author) {
  switch (pref) {
    case CREATOR:
      return creator;
    case OWNER:
      return getFinalDisplayUser(creator, owner, null);
    case EXTERNAL:
      return getFinalDisplayUser(creator, owner, author);
    default:
      return getFinalDisplayUser(creator, null, author);
  }
}

export function getFinalDisplayUser(creator, owner, author) {
  if (author && Object.keys(author).length) {
    return author;
  }

  if (owner && Object.keys(owner).length) {
    return owner;
  }

  return creator;
}
