export const TOP_PRODUCT_KEY = '-';

export const DELIVERY_TYPES = {
  STANDARD: 'STANDARD',
  DRIVER: 'DRIVER',
  UNKNOWN: 'UNKNOWN',
};

export const INVENTORY_TRACKER = {
  NONE: 'NONE',
  SHOPIFY: 'SHOPIFY',
  SHIPWIRE: 'SHIPWIRE',
  AMAZON_MARKETPLACE_WEB: 'AMAZON_MARKETPLACE_WEB',
  CUSTOM: 'CUSTOM',
};

export const INVENTORY_POLICY = {
  DENY: 'DENY',
  CONTINUE: 'CONTINUE',
};

export const FULFILLMENT_SERVICE = {
  NONE: 'NONE',
  MANUAL: 'MANUAL',
  SHIPWIRE: 'SHIPWIRE',
  WEBGISTIX: 'WEBGISTIX',
  AMAZON_MARKETPLACE_WEB: 'AMAZON_MARKETPLACE_WEB',
  CUSTOM: 'CUSTOM',
};

export const WEIGHT_UNIT = {
  G: 'G',
  KG: 'KG',
  LB: 'LB',
  OZ: 'OZ',
};

export const PRODUCT_KIND = {
  PRODUCT: 'PRODUCT',
  TICKET: 'TICKET',
  GIFTCARD: 'GIFTCARD',
  UNKNOWN: 'UNKNOWN',
};

const PRODUCT_SPECIFIC_INFO = {
  sku: null,
  optionKey1: null,
  optionKey2: null,
  optionKey3: null,
  manufacturer: null,
  model: null,
  weight: null,
  weightUnit: null,
  inventoryTracker: null,
  quantity: null,
  inventoryPolicy: null,
  fulfillmentService: null,
  price: null,
  msrp: null,
  costPerItem: null,
  barcode: null,
  imageUrl: null,
  requiresShipping: null,
  taxable: null,
  taxCode: null,
};

export function deliveryTypeAsEnum(type) {
  if (!type) {
    return DELIVERY_TYPES.STANDARD;
  }

  return DELIVERY_TYPES[type.toUpperCase()] || DELIVERY_TYPES.UNKNOWN;
}

export function inventoryTrackerAsEnum(tracker) {
  if (!tracker) {
    return INVENTORY_TRACKER.NONE;
  }

  return INVENTORY_TRACKER[tracker.toUpperCase()] || INVENTORY_TRACKER.CUSTOM;
}

export function inventoryPolicyAsEnum(policy) {
  if (!policy) {
    return INVENTORY_POLICY.DENY;
  }

  return INVENTORY_POLICY[policy.toUpperCase()] || INVENTORY_POLICY.DENY;
}

export function fulfillmentServiceAsEnum(service) {
  if (!service) {
    return FULFILLMENT_SERVICE.NONE;
  }

  return FULFILLMENT_SERVICE[service.toUpperCase()] || FULFILLMENT_SERVICE.CUSTOM;
}

export function weightUnitAsEnum(weight) {
  if (!weight) {
    return WEIGHT_UNIT.KG;
  }

  return WEIGHT_UNIT[weight.toUpperCase()] || WEIGHT_UNIT.KG;
}

export function productKindAsEnum(kind) {
  if (!kind) {
    return PRODUCT_KIND.PRODUCT;
  }

  return PRODUCT_KIND[kind.toUpperCase()] || PRODUCT_KIND.UNKNOWN;
}

function hasVariants(variants) {
  return variants && Object.keys(variants).length;
}

function getVariant(variants, sku) {
  if (!sku || !hasVariants(variants)) {
    return null;
  }

  return variants[sku];
}

function inheritFrom(productSpecificInfo) {
  if (!productSpecificInfo) {
    return null;
  }

  const props = {};

  Object.keys(PRODUCT_SPECIFIC_INFO).forEach((key) => {
    const value = productSpecificInfo[key];

    if (value) {
      props[key] = value;
    }
  });

  return props;
}

export function addVariant(variants, newVariant) {
  if (!hasVariants(variants)) {
    return {
      [newVariant.sku]: newVariant,
    };
  }

  return {
    ...variants,
    [newVariant.sku]: newVariant,
  };
}

export function deleteVariant(variants, sku) {
  if (!sku || !hasVariants(variants)) {
    return;
  }
  if (!variants[sku]) {
    return;
  }

  variants.delete(sku);

  return variants;
}

export function getTopLevelVariant(product) {
  if (!product) {
    return null;
  }

  const { merchantSku, variants } = product;
  const psi = {};
  const psi0 = getVariant(variants, TOP_PRODUCT_KEY);
  const psi1 = getVariant(variants, merchantSku);

  Object.assign(psi, inheritFrom(psi0));
  Object.assign(psi, inheritFrom(psi1));

  if (!psi.sku) {
    psi.sku = merchantSku;
  }

  return psi;
}

export function getEffectiveVariant(product, sku) {
  const { merchantSku, variants } = product;
  const tlv = getTopLevelVariant(product);

  if (!sku || merchantSku === sku) {
    return tlv;
  }

  const psi = getVariant(variants, sku);

  if (psi == null) {
    return null;
  }

  Object.assign(tlv, inheritFrom(psi));
  tlv.sku = sku;

  return tlv;
}

/**
 * Fetch a specific product flavor given a selection of options by the user.
 * If that option is not available, pass null.
 *
 * For example, say we have 2 options and the user selected "opt14" for option1
 * and "opt23" for option2, we would call:
 *     getVariantByOption("opt14", "opt23", null);
 */
export function getSku(product, optionKey1, optionKey2, optionKey3) {
  const { merchantSku, variants } = product;

  if (optionKey1 == null && optionKey2 == null && optionKey3 == null) {
    return merchantSku;
  }

  if (hasVariants(variants)) {
    return null;
  }

  const variant = Object.values(variants).find((value) => (
    value.optionKey1 === optionKey1 && value.optionKey2 === optionKey2 && value.optionKey3 === optionKey3)) || {};

  return variant.sku || null;
}

export function getTotalQuantity(variants) {
  if (!hasVariants(variants)) {
    return 0;
  }

  let sum = 0;

  Object.values(variants).forEach((variant) => {
    if (variant.quantity) {
      sum += variant.quantity;
    }
  });

  return sum;
}

/**
 * Test if an option is available given previous selections.
 * Pass null for optionKeyX if the selection was not yet made.
 *
 * For example, to test if a given option item for option1 (say "opt12")
 * is available, call:
 *    isOptionAvailable(null, null, "opt12");
 *
 * Say the user not selected item "opt14" from option1 and now
 * we want to see if option2 item (say "opt23") is available:
 *    isOptionAvailable("opt14", null, "opt23");
 */
//  TODO: needs to be verify
export function isOptionAvailable(variants, optionKey1, optionKey2, optionKey) {
  if (!hasVariants(variants)) {
    return false;
  }

  const option = Object.values(variants).find((productSpecificInfo) => {
    if (optionKey1) {
      if (productSpecificInfo.optionKey1 !== optionKey1) {
        if (optionKey2) {
          if (productSpecificInfo.optionKey2 !== optionKey2 && productSpecificInfo.optionKey3 === optionKey) {
            return true;
          }
        } else if (productSpecificInfo.optionKey2 === optionKey) {
          return true;
        }
      }
    } else if (productSpecificInfo.optionKey1 === optionKey) {
      return true;
    }

    return false;
  }) || null;

  return !!option;
}
