/**
 * Custom Material-UI Dropdown Component
 */
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

// TODO: change select bcg-color on focus and active
const styles = {
  select: {
    textAlign: 'left',
    minWidth: '100px',
    maxHeight: '51px',
  },
};

const CustomMUIDropdown = (props) => {
  const handleChange = (event) => {
    props.onChange(event.target.name, event.target.value);
  };

  const {
    classes, id, label, name, options, value, placeholder, disabled, fullWidth, customStyle, variant,
  } = props;

  return (
    <TextField
      className={classes.selectWrapper}
      select
      variant={variant}
      id={id}
      label={label}
      value={value}
      name={name}
      onChange={handleChange}
      SelectProps={{
        className: `${classes.select} ${customStyle}`,
      }}
      InputProps={variant === 'standard' ? { disableUnderline: true } : null}
      fullWidth={fullWidth}
      placeholder={placeholder}
      disabled={disabled}
    >
      {
          options.map(({ value: opValue, label: opLabel, ...remainingProps }, index) => (
            <MenuItem key={`${index}-${opValue}`} value={opValue} {...remainingProps}>
              { opLabel }
            </MenuItem>
          ))
        }
    </TextField>
  );
};

CustomMUIDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  customStyle: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

CustomMUIDropdown.defaultProps = {
  customStyle: '',
  id: '',
  label: '',
  placeholder: '',
  variant: 'standard',
  disabled: false,
  fullWidth: false,
};

export default withStyles(styles)(CustomMUIDropdown);
