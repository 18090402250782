import IntlMessage from 'Util/IntlMessages';

export const inputs = {
  emailAddress: {
    name: 'email',
    label: <IntlMessage id="label.email" />,
    type: 'email',
    autoFocus: true,
    fullWidth: true,
  },
  firstName: {
    name: 'firstName',
    label: <IntlMessage id="label.firstname" />,
    type: 'text',
    defaultValue: '',
    placeholder: <IntlMessage id="placeholder.sayNice" />,
    autoFocus: false,
    fullWidth: true,
  },
  commentText: {
    name: 'commentText',
    label: '',
    type: 'text',
    // defaultValue: '',
    placeholder: <IntlMessage id="placeholder.sayHelpful" />,
    autoFocus: true,
    fullWidth: true,
  },
  postDescription: {
    name: 'description',
    type: 'text',
    placeholder: <IntlMessage id="placeholder.postDescr" />,
    autoFocus: true,
    fullWidth: true,
  },
  profileDescription: {
    name: 'profileDescription',
    label: '',
    type: 'text',
    placeholder: <IntlMessage id="placeholder.addFewWordsAboutYourself" />,
    autoFocus: true,
    fullWidth: true,
  },
  groupName: {
    name: 'addGroup',
    label: <IntlMessage id="label.groupName" />,
    type: 'text',
    placeholder: '',
    autoFocus: true,
    fullWidth: true,
  },
  groupDescription: {
    name: 'editGroup',
    label: <IntlMessage id="description" />,
    type: 'text',
    placeholder: '',
    autoFocus: false,
    fullWidth: true,
  },
  updateAccount: {
    name: 'adjustment',
    label: '',
    type: 'text',
    placeholder: <IntlMessage id="update_account_hint" />,
    autoFocus: true,
    fullWidth: true,
  },
  addGradeText: {
    name: 'addGradeText',
    label: '',
    type: 'text',
    defaultValue: '',
    placeholder: <IntlMessage id="placeholder.sayNice" />,
    autoFocus: false,
    fullWidth: true,
  },
  reportUser: {
    name: 'reason',
    label: '',
    type: 'text',
    defaultValue: '',
    placeholder: 'report_claim_no_reason',
    autoFocus: false,
    fullWidth: true,
  },
};
