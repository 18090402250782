import { combineReducers } from 'redux';

import sentsReducer from 'Modules/companySents/store/reducers';
import * as namespaces from 'Store/namespaces';
import attachmentsReducer from 'Modules/attachments/store/reducers';
import cartReducer from 'Modules/cart/store/reducers';

import onBoardingReducer from 'Modules/onboarding/store/reducers';
import userStreamsReducer from 'Modules/posts/common/store/reducers';
import authUserReducer from 'Modules/authentication/store/reducers';
import userAccountReducer from 'Modules/myAccount/store/reducers';
import leaderboardReducer from 'Modules/leaderboard/store/reducers';
import myProfileReducer from 'Modules/myProfile/store/reducers';
import modalReducer from 'Modules/modal/store/reducers';
import reportsReducer from 'Modules/reports/store/reducers';
import groupReducer from 'Modules/group/store/reducers';
import orderReducer from 'Modules/order/store/reducers';
import bountyReducer from 'Store/reducers/bountyReducer';
import applicationsReducer from 'Store/reducers/applicationsReducer';
import candidatesReducer from 'Store/reducers/candidatesReducer';
import jobsReducer from 'Store/reducers/jobsReducer';
import managementReducer from 'Store/reducers/userManagementReducer';

import settings from './settings';
import chatAppReducer from './chatReducer';
import statisticsReducer from './statisticsReducer';
import settingsReducer from './settingsReducer';
import applicantsReducer from '../../modules/applications/store/applicantsReducer';
import companiesReducer from '../../modules/company/store/companiesReducer';
import industriesReducer from './industriesReducer';
import skillsReducer from './skillsReducer';
// import emailAppReducer from '../reactify-staff/reducers/EmailAppReducer';
// import sidebarReducer from '../reactify-staff/reducers/SidebarReducer';
// import todoAppReducer from '../reactify-staff/reducers/TodoAppReducer';
// import feedbacksReducer from '../reactify-staff/reducers/FeedbacksReducer';
// import ecommerceReducer from '../reactify-staff/reducers/EcommerceReducer';

const appReducer = combineReducers({
  // emailApp: emailAppReducer,
  // sidebar: sidebarReducer,
  // todoApp: todoAppReducer,
  // feedback: feedbacksReducer,
  // ecommerce: ecommerceReducer,
  [namespaces.APPLICANTS_NAMESPACE]: applicantsReducer,
  applicationsReducer,
  [namespaces.ATTACHMENTS_NAMESPACE]: attachmentsReducer,
  [namespaces.AUTH_NAMESPACE]: authUserReducer,
  bountyReducer,
  candidatesReducer,
  managementReducer,
  [namespaces.CART_NAMESPACE]: cartReducer,
  chatAppReducer,
  [namespaces.COMPANIES_NAMESPACE]: companiesReducer,
  [namespaces.GROUP_NAMESPACE]: groupReducer,
  industriesReducer,
  jobsReducer,
  [namespaces.LEADERBOARD_NAMESPACE]: leaderboardReducer,
  [namespaces.MODAL_NAMESPACE]: modalReducer,
  [namespaces.MY_PROFILE_NAMESPACE]: myProfileReducer,
  [namespaces.ON_BOARDING_NAMESPACE]: onBoardingReducer,
  [namespaces.ORDER_NAMESPACE]: orderReducer,
  [namespaces.REPORTS_NAMESPACE]: reportsReducer,
  [namespaces.SENTS_NAMESPACE]: sentsReducer,
  settings,
  [namespaces.SETTINGS_NAMESPACE]: settingsReducer,
  skillsReducer,
  statisticsReducer,
  [namespaces.USER_ACCOUNT_NAMESPACE]: userAccountReducer,
  [namespaces.USER_STREAMS_NAMESPACE]: userStreamsReducer,
});

const rootReducer = (state, action) => {
  if (action.reducerProperty === 'RESET_STATE') {
    state = undefined; // eslint-disable-line
  }

  return appReducer(state, action);
};

export default rootReducer;
