/**
 * Rct Page Loader
 */
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: 'absolute',
  top: '50%',
  right: '50%',
  transform: 'translate(50%, -50%)',
};

const RctPageLoader = () => (
  <div className="page-loader d-flex justify-content-center mb-30 mt-20" style={style}>
    <CircularProgress />
  </div>
);

export default RctPageLoader;
