import { viitorul } from 'Constants/common';

const viitorulConfig = {
  FLAVOR: 'viitorul',
  id: viitorul,
  title: 'FC Viitorul',
  name: 'FC Viitorul app',
  brandName: 'FC Viitorul',
  appLinkSchema: 'viitorul',
  appTld: 'fcviitorul.ro',
  logo: {
    logoStyle: 'full-width',
    imgStyle: 'full-width-i',
    containerStyle: 'no-padding',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDIGI7dt1v4FGjFRMCu4ladpv2ZGs8Dv94', // Your Api key will be here - this is the prod
    authDomain: 'viitorul-prod.firebaseapp.com', // Your auth domain
    databaseURL: 'https://viitorul-prod.firebaseio.com', // data base url
    projectId: 'viitorul-prod', // project id
    storageBucket: 'viitorul-prod.appspot.com', // storage bucket
    messagingSenderId: '606478663632', // messaging sender id
    appId: '1:606478663632:web:4d423b60e577f62d4a03d5',
    measurementId: 'G-V5NY73830P',
  },
  API_BASE_URL: 'https://api.youkno.ai/api/v1',
  API_REPORT: 'https://api.youkno.ai/download/report',
  login: {
    customCss: '',
    loginWithPin: true,
  },
  algolia_app_id: '',
  algolia_api_key: '',
  TERMS_OF_SERVICE_URL: 'https://www.fcviitorul.ro/termeni-conditii',
  PRIVACY_STATEMENT_URL: 'https://www.fcviitorul.ro/politica-confidentialitate',
};

export default viitorulConfig;
