// Others
import { SIGN_IN_ROUTE, SIGN_UP_ROUTE } from 'Constants/routes';
import { getApplicationConfig } from 'Util/AppUtils';
import IntlMessage from 'Util/IntlMessages';

const { login: { customCss, loginWithPin } } = getApplicationConfig();

const CITY = {
  name: 'city',
  placeholder: 'placeholder.select.city',
  label: <IntlMessage id="label.city" />,
  icon: 'location_city',
};

const MERCHANT_CITY = {
  ...CITY,
  label: <IntlMessage id="label.city" />,
};

const STATE = {
  name: 'state',
  placeholder: 'placeholder.select.state',
  label: <IntlMessage id="label.state" />,
  // icon: 'location_city',
};

const USERNAME = {
  name: 'name',
  placeholder: 'placeholder.enter.name',
  label: <IntlMessage id="label.display.name" />,
  icon: 'ti-user',
  autoFocus: true,
};

const EMAIL = {
  name: 'email',
  placeholder: 'placeholder.enter.email',
  label: <IntlMessage id="kyc.email" />,
  icon: 'ti-email',
};

const PASSWORD = {
  name: 'password',
  type: 'password',
  placeholder: 'placeholder.enter.password',
  label: <IntlMessage id="placeholder.password" />,
  icon: 'ti-lock',
};

const HEALTH_INTEREST = {
  icon: 'favorite',
  title: <IntlMessage id="label.health.CBD" />,
  name: 'health',
};

const MEDICAL_INTEREST = {
  icon: 'local_hospital',
  title: <IntlMessage id="title.medical" />,
  name: 'medical',
};

const RECREATIONAL_INTEREST = {
  icon: 'tag_faces',
  title: <IntlMessage id="title.recreational" />,
  name: 'recreational',
};

const COMMUNITY_INTEREST = {
  icon: 'people',
  title: <IntlMessage id="title.community" />,
  name: 'community',
};

const BUY_SELL_INTEREST = {
  icon: 'swap_horiz',
  title: <IntlMessage id="title.buy.and.sell" />,
  name: 'buy_sell',
};

const CLIENT_NAME = {
  name: 'clientName',
  placeholder: 'placeholder.enter.your.company.name',
  label: <IntlMessage id="label.company.name" />,
  autoFocus: true,
};

const CLIENT_URL = {
  name: 'clientWebsiteUrl',
  placeholder: 'placeholder.enter.your.company.URL',
  label: <IntlMessage id="label.company.url" />,
};

const ADDRESS_LINE_1 = {
  name: 'line1',
  placeholder: 'placeholder.enter.your.address.one',
  label: <IntlMessage id="label.address.one" />,
};

const ADDRESS_LINE_2 = {
  name: 'line2',
  placeholder: 'placeholder.enter.your.address.two',
  label: <IntlMessage id="label.address.two" />,
};

const POSTAL_CODE = {
  name: 'postalCode',
  placeholder: 'placeholder.enter.your.postal.code',
  label: <IntlMessage id="label.postal.code" />,
};

export const PIN_FIELDS = [{
  name: 'pin',
  placeholder: 'placeholder.enter.pin',
  icon: 'ti-pin',
  label: <IntlMessage id="label.enter.pin" />,
  autoFocus: true,
}];

export const SIGN_IN_FIELDS = [
  {
    ...EMAIL,
    autoFocus: true,
  },
  PASSWORD,
];

export const SIGN_UP_FIELDS = [
  USERNAME,
  EMAIL,
  PASSWORD,
];

export const FORGOT_PASSWORD_FIELDS = [
  EMAIL,
];

export const NICE_TO_MEET_FIELDS = [
  CITY,
];

export const INTERESTS_LIST = [
  HEALTH_INTEREST,
  MEDICAL_INTEREST,
  RECREATIONAL_INTEREST,
  COMMUNITY_INTEREST,
  BUY_SELL_INTEREST,
];

export const SIGN_UP_SWITCH = {
  question: <IntlMessage id="question.create.new.account" />,
  name: 'Sign Up',
  link: SIGN_UP_ROUTE,
};

export const SIGN_IN_SWITCH = {
  question: <IntlMessage id="question.already.have.account" />,
  name: 'Sign In',
  link: SIGN_IN_ROUTE,
};

export const REGISTER_COMPANY_FIELDS = [
  CLIENT_NAME,
  CLIENT_URL,
];

export const REGISTER_COMPANY_ADDRESS_FIELDS = [
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  MERCHANT_CITY,
  STATE,
  POSTAL_CODE,
];

export const FIRST_SIGNUP_STEP = 'FIRST_SIGNUP_STEP';
export const NICE_TO_MEET_STEP = 'NICE_TO_MEET_STEP';
export const WELCOME_STEP = 'WELCOME_STEP';
export const GEO_IP = 'geoip';
export const REGISTRATION_CUSTOM_CSS = customCss;
export const LOGIN_WITH_PIN = loginWithPin;
