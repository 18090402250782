export const NONE = 'NONE';
export const UNKNOWN = 'UNKNOWN';
export const AUTHOR = 'AUTHOR';
export const MERCHANT = 'MERCHANT';
export const CHARITY = 'CHARITY';
export const FAMILY = 'FAMILY';
export const EMPLOYER = 'EMPLOYER';
export const AMBASSADOR = 'AMBASSADOR';
export const PARTNER = 'PARTNER';
export const APPLICANT = 'APPLICANT';
