import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { object, func, number, string, oneOfType } from 'prop-types';

//  Components
import InputComponent from 'Common/components/InputComponent';

//  Actions/Selectors
import { updateDataSyncAction } from 'Store/actions/genericActions';
import * as modalSelectors from 'Modules/modal/store/selectors';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import HtmlTooltip from 'Modules/posts/add/components/HtmlTooltip';

//  Other resources
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA, MODAL_VALIDATION_ERRORS } from 'Store/reducerProperties';
import IntlMessage from 'Util/IntlMessages';

const WorkingHours = (props) => {
  const {
    hrsPerWeekMin, hrsPerWeekMax, validationErrors, selectedBounty,
  } = props;

  useEffect(() => {
    const { jobInfo } = selectedBounty;

    if (jobInfo?.hrsPerWeekMax || jobInfo?.hrsPerWeekMin) {
      props.updateDataSyncAction(
        MODAL_NAMESPACE,
        MODAL_INPUTS_DATA,
        {
          hrsPerWeekMax: jobInfo.hrsPerWeekMax || '',
          hrsPerWeekMin: jobInfo.hrsPerWeekMin || '',
        },
      );
    }
  }, []);

  const handleChanges = ({ target: { name, value } }) => {
    const errors = { ...validationErrors, [name]: null };
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_VALIDATION_ERRORS, errors);
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: value });
  };

  return (
    <div className="mt-20">
      <h4 className="position-relative">
        Estimated Monthly Workload (hours)
        <HtmlTooltip
          text='When entering less than 120 min hours
              we will display &quot;part-time&quot; to applicants
              When entering 120 or more we will
              display &quot;full-time.&quot;'
        />
      </h4>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4}>
          <InputComponent
            fullWidth
            type="number"
            label={<IntlMessage id="label.minHours" />}
            name="hrsPerWeekMin"
            value={hrsPerWeekMin}
            validationErrors={validationErrors}
            handleChanges={handleChanges}
            size="small"
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <InputComponent
            fullWidth
            type="number"
            label={<IntlMessage id="label.maxHours" />}
            name="hrsPerWeekMax"
            value={hrsPerWeekMax}
            validationErrors={validationErrors}
            handleChanges={handleChanges}
            size="small"
          />
        </Grid>
      </Grid>
    </div>
  );
};

WorkingHours.propTypes = {
  hrsPerWeekMax: oneOfType([number, string]),
  hrsPerWeekMin: oneOfType([number, string]),
  validationErrors: object.isRequired,
  selectedBounty: object.isRequired,
  updateDataSyncAction: func.isRequired,
};

WorkingHours.defaultProps = {
  hrsPerWeekMax: '',
  hrsPerWeekMin: '',
};

const mapStateToProps = (state) => ({
  hrsPerWeekMin: modalSelectors.inputsData(state).hrsPerWeekMin || '',
  hrsPerWeekMax: modalSelectors.inputsData(state).hrsPerWeekMax || '',
  validationErrors: modalSelectors.validationErrorsSelector(state) || {},
  selectedBounty: selectedBountySelector(state) || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkingHours);
