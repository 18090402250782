import { useEffect } from 'react';
import { string, shape, func, instanceOf } from 'prop-types';
import { TextField, FormControl } from '@mui/material';

const InputField = (props) => {
  const {
    name, formik, component, helperText, ...remainingProps
  } = props;
  const { value, error } = formik.getFieldMeta(name);
  const { setError } = formik.getFieldHelpers(name);
  const errorMessage = error && error[0];

  useEffect(() => {
    if (errorMessage) {
      setError('');
    }
  }, [value]);

  return (
    <FormControl fullWidth>
      <TextField
        {...remainingProps}
        name={name}
        value={value || ''}
        onChange={formik.handleChange}
        helperText={errorMessage}
        error={!!errorMessage}
      />
    </FormControl>
  );
};

InputField.propTypes = {
  name: string.isRequired,
  label: string,
  component: string,
  formik: shape({
    handleChange: func.isRequired,
    errors: instanceOf(Object),
    touched: instanceOf(Object),
  }).isRequired,
  helperText: string,
};

InputField.defaultProps = {
  label: '',
  component: '',
  helperText: '',
};

export default InputField;
