import moment from 'moment';

import { ListDef } from 'Models/Lists';
import * as Badges from 'Models/Badge';
import { CUSTOM, NONE, STREAM } from 'Modules/posts/bounty/constants/listType';
import * as BOUNTY_TYPES from 'Constants/bounty/bountyType';
import * as ResponseTypes from 'Constants/response/responseTypes';
import { JOB_UNITS_TYPES, UNKNOWN_PERIOD, NONE_PERIOD, JOB_UNITS_AS_STRING } from 'Constants/bounty/jobUnit';
import { BENEFITS, BENEFITS_LABELS } from 'Constants/bounty/benefit';
import { isExpired } from 'Util/DateUtil';
import { ACTIVE_STATE, CLOSED_STATE, DRAFT_STATE, EXPIRED_STATE } from 'Constants/bounty/bountyState';
import * as PRGGRESS_STATE from 'Constants/bounty/progressState';
import { IN_APP } from 'Constants/bounty/bounty';
import { SENTS_FOLDER_BY_TYPE } from 'Constants/bounty/companySentsFolder';
import { SORT_ORDER_POPULAR, SORT_ORDER_RECENT } from 'Modules/posts/bounty/constants/sortConfig';
import { getTotalCount, valueOf } from '../survey/Survey';
import * as VisibilityMode from '../VisibilityMode';
import { BOUNTY } from '../EntityType';
import { getCountryName } from '../../util/GeneralUtils';

const REBOUNTY_TYPES = {
  NONE: 'NONE',
  DISTRIBUTION: 'DISTRIBUTION',
  INTERMEDIATION: 'INTERMEDIATION',
  UNKNOWN: 'UNKNOWN',
};

export function isCreatedByMe(data, user) {
  return data.creator && user && data.creator.id === user.uid;
}

export function setListDef(bounty, listDef) {
  if (listDef === null || listDef === undefined) {
    return {
      listId: null,
      listCode: null,
    };
  }

  const { name } = ListDef.getTypeAsEnum(listDef.id);

  if (name === NONE || name === STREAM) {
    return {
      listId: null,
      listCode: null,
    };
  }

  if (name === CUSTOM) {
    return {
      listId: listDef.id,
      listCode: null,
    };
  }

  const listCode = ListDef.getEffectiveCode(listDef);
  return {
    listId: listDef.id,
    listCode: listCode !== undefined ? listCode : null,
  };
}

export function getEffectiveResponseVisibilityMode(responseVisibilityMode) {
  return responseVisibilityMode || VisibilityMode.DEFAULT;
}

export function getAsSurvey(bounty) {
  if (isSurveyType(bounty.type)) {
    return bounty.description ? valueOf(bounty.description, bounty.type) : null;
  }

  return null;
}

export function getLevel1Id(bounty) {
  return bounty.id;
}

export function getLevel2Id() {
  return null;
}

export function getEntityType() {
  return BOUNTY;
}

export function isPosted(postings) {
  return !!(postings && Object.keys(postings).length);
}

export function isRecommendation(bountyType) {
  return [BOUNTY_TYPES.TALENT_RECOMMENDATION, BOUNTY_TYPES.REALESTATE_RECOMMENDATION].includes(bountyType);
}

export function isSubBounty(bounty) {
  return bounty.parentBounty && bounty.parentBounty.id != null;
}

export function getEffectiveResponseKey(bounty) {
  return bounty.responseKey || bounty.id;
}

export function isClosable(type) {
  return type !== BOUNTY_TYPES.TMOB_COACHING;
}

export function isSurveyType(type) {
  return BOUNTY_TYPES.SURVEY_TYPES.includes(type);
}

export function hideSurveyOptions(type) {
  return [BOUNTY_TYPES.LOTTERY].includes(type);
}

export function isResumeSent(bounty) {
  const { outboundResponses } = bounty;

  if (!outboundResponses || !Object.keys(outboundResponses).length) {
    return false;
  }

  return Object.keys(outboundResponses).find((key) => (
    outboundResponses[key].recommendation && outboundResponses[key].recommendation.resumeUrl
  )) || false;
}

export function isBundle(type) {
  return [
    BOUNTY_TYPES.BUNDLE,
    BOUNTY_TYPES.QUEST,
    BOUNTY_TYPES.TRIVIA,
  ].includes(type);
}

export function isContest(type) {
  return type === BOUNTY_TYPES.TRIVIA;
}

export function excludeFromBundle(type) {
  return [
    BOUNTY_TYPES.PRODUCT,
    BOUNTY_TYPES.BANNER,
    BOUNTY_TYPES.NEWS,
  ].includes(type);
}

function getRecommendationType(type) {
  switch (type) {
    case BOUNTY_TYPES.TALENT_SEARCH:
      return BOUNTY_TYPES.TALENT_RECOMMENDATION;
    case BOUNTY_TYPES.REALESTATE_CUSTOMER:
      return BOUNTY_TYPES.REALESTATE_RECOMMENDATION;
    case BOUNTY_TYPES.ORDER:
      return BOUNTY_TYPES.ORDER;
    default:
      return NONE;
  }
}

export function hasRecommendationResponse(type) {
  return getRecommendationType(type) !== NONE;
}

function getDefaultRebountyTypeAsEnum(bountyType) {
  switch (bountyType) {
    case BOUNTY_TYPES.REALESTATE_CUSTOMER:
    case BOUNTY_TYPES.TALENT_SEARCH:
      return REBOUNTY_TYPES.DISTRIBUTION;
    default:
      return REBOUNTY_TYPES.NONE;
  }
}

function getRebountyTypeAsEnum(bounty) {
  const { terms, type } = bounty;

  if (terms && terms.rebountyType) {
    return terms.rebountyType;
  }

  getDefaultRebountyTypeAsEnum(type);
}

export function getEffectiveRebountyTypeAsEnum(bounty) {
  const { varTerms } = bounty;

  if (!varTerms || varTerms.hopsToLive <= 0) {
    return REBOUNTY_TYPES.NONE;
  }

  return getRebountyTypeAsEnum(bounty);
}

export function hasBadge(badges, badgeType) {
  return badges && Badges.hasBadge(badges, badgeType);
}

export function getSurveyTotalCount(summary) {
  return summary != null && summary.surveyResult ? getTotalCount(summary.surveyResult.answers) : 0;
}

export function cloneBounty(bounty) {
  return {
    creator: bounty.creator || null,
    owner: bounty.owner || null,
    author: bounty.author || null,
    type: bounty.type || null,
    description: bounty.description || null,
    reward: bounty.reward || null,
    typeCreatedAtSortKey: bounty.typeCreatedAtSortKey || null,
    typePopularitySortKey: bounty.typePopularitySortKey || null,
    typeDiscussedSortKey: bounty.typeDiscussedSortKey || null,
    terms: bounty.terms || null,
    varTerms: bounty.varTerms || null,
    attachments: bounty.attachments || null,
    product: bounty.product || null,
    order: bounty.order || null,
    visibility: bounty.visibility || null,
  };
}

export function getMyResponse(outboundResponses, me) {
  if (!outboundResponses) {
    return null;
  }

  return Object.keys(outboundResponses).find((key) => {
    const response = outboundResponses[key];
    return isCreatedByMe(response, me);
  }) || null;
}

export function asBountyInfo(bounty) {
  return {
    id: bounty.id,
    creator: bounty.creator,
    author: bounty.author || bounty.myself || null,
    type: bounty.type,
    description: (bounty.description && bounty.description.text) || null,
  };
}

export function getResponseType(bounty) {
  switch (bounty.type) {
    case BOUNTY_TYPES.TALENT_SEARCH:
    case BOUNTY_TYPES.REALESTATE_CUSTOMER:
      return ResponseTypes.RECOMMENDATION;
    case BOUNTY_TYPES.MATCH:
      return ResponseTypes.TIMELINE_EVENT;
    default:
      return ResponseTypes.NOTE;
  }
}

export function isBountyExpired(state, expiresAt) {
  return state === EXPIRED_STATE || (shouldCheckForExpiry(state) && isExpired(expiresAt));
}

export function shouldCheckForExpiry(state) {
  return state === ACTIVE_STATE;
}

export function getSurveyChoices(outboundResponses) {
  const surveyChoices = [];

  if (outboundResponses) {
    Object.values(outboundResponses).forEach((outboundRes) => {
      if (outboundRes.type === ResponseTypes.CHOICE) {
        surveyChoices.push(outboundRes.choice);
      }
    });
  }

  return surveyChoices;
}

export function isSurveyCompleted(bountyType, summary) {
  if (bountyType === BOUNTY_TYPES.CHECKLIST) {
    return false;
  }

  return summary && summary.surveyResult && summary.progressState === PRGGRESS_STATE;
}

export function isSurveyAnswerable(bounty, isOutbound) {
  const {
    state, type, expiresAt, outboundResponses,
  } = bounty;

  return !isOutbound
    && !isBountyExpired(state, expiresAt)
    && ![DRAFT_STATE, CLOSED_STATE].includes(state)
    && !isSurveyAnswered(type, outboundResponses)
    && !isSurveyCompleted();
}

export function isSurveyAnswered(bountyType, outboundResponses) {
  if (bountyType === BOUNTY_TYPES.CHECKLIST) {
    return false;
  }

  return getSurveyChoices(outboundResponses).length > 0;
}

export function isRebounty(bounty) {
  return bounty ? !!bounty.originBounty : false;
}

export function isNetworkLinkRequired(network) {
  return network !== IN_APP;
}

export function isFavorite(bounty) {
  return bounty.favoritedAt !== null && bounty.favoritedAt > 0;
}

export function getDateFromBounty(date) {
  const secondsDiff = moment().diff(date, 'seconds');
  const minutesDiff = moment().diff(date, 'minutes');
  const hoursDiff = moment().diff(date, 'hours');
  const daysDiff = moment().diff(date, 'days');

  if (secondsDiff < 60) {
    return 'Just now';
  }

  if (minutesDiff < 60) {
    return `${minutesDiff} minutes`;
  }

  if (hoursDiff < 24) {
    return `${hoursDiff} hours`;
  }

  if (daysDiff === 1) {
    return `${daysDiff} day`;
  }

  if (daysDiff < 7) {
    return `${daysDiff} days`;
  }

  return formatCreateDate(date);
}

export function formatCreateDate(date) {
  return moment(date).format('D MMM YYYY');
}

function allowAll() {
  return {
    allowComments: true,
    allowRating: true,
    allowLike: true,
    allowBookmarks: true,
  };
}

export function initBehaviour(type) {
  let bountyOps = {
    allowComments: null,
    allowRating: null,
    allowLike: null,
    allowBookmarks: null,
  };
  let responseOps = {
    allowComments: null,
    allowRating: null,
    allowLike: null,
    allowBookmarks: null,
  };
  const behaviour = {};

  behaviour.defaultResponseSorting = SORT_ORDER_RECENT;
  behaviour.closable = true;
  behaviour.noShare = true;
  behaviour.onlyPrivateResponses = isSurveyType(type);

  switch (type) {
    case BOUNTY_TYPES.SURVEY:
    case BOUNTY_TYPES.SCORE:
    case BOUNTY_TYPES.CHECKLIST:
    case BOUNTY_TYPES.MCQ:
    case BOUNTY_TYPES.FUNDING:
      if (type === BOUNTY_TYPES.MCQ) {
        behaviour.immutableResponse = true;
      }
      bountyOps = allowAll();
      bountyOps.commentAsIcon = true;
      break;
    case BOUNTY_TYPES.CLASSIFIED:
      bountyOps = allowAll();
      responseOps = allowAll();
      responseOps.allowLike = false;
      behaviour.onlyPrivateResponses = true;
      break;
    case BOUNTY_TYPES.THREAD:
    case BOUNTY_TYPES.MATCH:
    case BOUNTY_TYPES.LIVE_STREAM:
      bountyOps = allowAll();
      responseOps = allowAll();
      bountyOps.allowComments = false;
      responseOps.commentAsIcon = true;
      behaviour.responseAsComments = true;
      break;
    case BOUNTY_TYPES.JOB:
      bountyOps = allowAll();
      responseOps = allowAll();
      bountyOps.allowComments = false;
      behaviour.noShare = false;
      break;
    default:
      behaviour.allowResponseSorting = true;

      if (type === BOUNTY_TYPES.QUESTION) {
        behaviour.defaultResponseSorting = SORT_ORDER_POPULAR;
      }
      if (type === BOUNTY_TYPES.TMOB_COACHING) {
        behaviour.closable = false;
      }

      if ([
        BOUNTY_TYPES.TALENT_SEARCH,
        BOUNTY_TYPES.TALENT_RECOMMENDATION,
        BOUNTY_TYPES.REALESTATE_CUSTOMER,
        BOUNTY_TYPES.REALESTATE_RECOMMENDATION,
      ].includes(type)) {
        behaviour.onlyPrivateResponses = true;
      }

      bountyOps = allowAll();
      responseOps = allowAll();
      break;
  }

  return {
    ...behaviour,
    userBountyOps: bountyOps,
    userResponseOps: responseOps,
  };
}

export function getNrOfSubBounties(bounty) {
  if (!bounty) {
    return 0;
  }

  const { stats } = bounty;
  return (stats && stats.subBounties) || 0;
}

export function getBountyTypeAsEnum(type) {
  if (!type) {
    return BOUNTY_TYPES.NONE;
  }

  return BOUNTY_TYPES[type] || BOUNTY_TYPES.UNKNOWN;
}

export function getCompanyFolder(type) {
  return SENTS_FOLDER_BY_TYPE[type] || null;
}

export function periodAsEnum(period) {
  if (!period) {
    return NONE_PERIOD;
  }

  return JOB_UNITS_TYPES.includes(period.toUpperCase()) || UNKNOWN_PERIOD;
}

export function getJobUnitsAsOptionsList(allowedTypes = JOB_UNITS_TYPES) {
  const list = [];

  allowedTypes.forEach((period) => {
    if (![NONE_PERIOD, UNKNOWN_PERIOD].includes(period)) {
      list.push({ label: JOB_UNITS_AS_STRING[period], value: period });
    }
  });

  return list;
}

export function isValidBenefit(benefit) {
  return ![BENEFITS.NONE, BENEFITS.UNKNOWN].includes(benefit);
}

export function benefitAsEnum(benefit) {
  if (!benefit) {
    return BENEFITS.NONE;
  }

  return BENEFITS[benefit.toUpperCase()] || BENEFITS.UNKNOWN;
}

export function hasBenefit(benefits, benefitName) {
  if (!benefits) {
    return false;
  }

  return !!(benefits[benefitName] && benefits[benefitName].enabled);
}

export function getBenefitsAsList(benefits) {
  const list = [];

  if (!benefits) {
    return list;
  }

  Object.keys(benefits).forEach((key) => {
    const benefit = benefitAsEnum(key);

    if (isValidBenefit(benefit)) {
      list.push({
        value: benefit,
        label: BENEFITS_LABELS[benefit] || benefit,
      });
    }
  });

  return list;
}

export function bountyTags(bounty) {
  const { benefits, countryCode, industries } = bounty.jobInfo || {};
  let tags = [];

  if (countryCode) {
    tags.push(getCountryName(countryCode));
  }

  if (benefits) {
    tags = tags.concat(getBenefitsAsList(benefits).map((item) => item.label));
  }

  if (industries && Object.keys(industries).length) {
    Object.values(industries).forEach((industry) => {
      const { name } = industry;

      if (name) {
        tags.push(name.toUpperCase());
      }
    });
  }

  return tags;
}

export function isOutboundPost(metaInfo) {
  return metaInfo && metaInfo.outbound;
}

export function setOutbound(metaInfo, isOutbound) {
  if (metaInfo) {
    return {
      ...metaInfo,
      outbound: isOutbound,
    };
  }

  return { outbound: isOutbound };
}

export function isCompanySpecial(type) {
  return [BOUNTY_TYPES.MATCH].includes(type);
}

export function isEditableByEmployee(bounty) {
  return [
    BOUNTY_TYPES.AUTOSUGGESTION,
    BOUNTY_TYPES.PRIVATE_LISTING,
    BOUNTY_TYPES.PRODUCT,
    BOUNTY_TYPES.ORDER,
    BOUNTY_TYPES.PROMOTION,
    BOUNTY_TYPES.JOB,
    BOUNTY_TYPES.NEWS,
    BOUNTY_TYPES.BANNER,
    BOUNTY_TYPES.MATCH,
  ].includes(bounty?.type);
}

export function fromSameCompany(user, companyId) {
  return !!(user?.kind === 'COMPANY' && user?.id === companyId);
}

export function isOwnByMyCompany(bounty, userData) {
  const myCompanyId = userData?.company?.id;

  if (!myCompanyId) {
    return false;
  }

  return fromSameCompany(bounty?.owner, myCompanyId)
    || fromSameCompany(bounty?.creator, myCompanyId)
    || fromSameCompany(bounty?.author, myCompanyId);
}

export function hasSameOwner(bounty, userData) {
  return bounty?.owner?.id === userData?.owner?.id;
}

export function isSpecialBounty(bountyType) {
  return [
    BOUNTY_TYPES.JOB,
    BOUNTY_TYPES.PRODUCT,
    BOUNTY_TYPES.MATCH,
    BOUNTY_TYPES.NEWS,
    BOUNTY_TYPES.BANNER,
  ].includes(bountyType);
}

export function hasSuccessMessage(bounties) {
  const questions = bounties.filter(({ bounty }) => bounty.type === BOUNTY_TYPES.THREAD);
  return questions.length > 0;
}
