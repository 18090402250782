import { isLoadingAction, updateDataAsyncAction } from 'Store/actions/genericActions';
import { queues } from 'Constants/queues';
import { AUTH_NAMESPACE, GROUP_NAMESPACE, USER_STREAMS_NAMESPACE } from 'Store/namespaces';
import * as reducerProperties from 'Store/reducerProperties';
import { addToQueue } from 'Services/Api';
import { convertObjToArray } from 'Util/helpers';
import {
  firebaseGetUserDataRef,
  firebaseGetUserStreamsRef,
  firebaseGetUserResponsesRef,
  firebaseGetMyAccountRef,
  getSocialActionStateRef,
  getMyBlocksRef,
  firebasegetGroupMembers,
  firebasegetGroupInvites,
  firebaseGetMyProfileRef,
  firebaseGetUserCommentsRef,
  firebaseGetMyFavorites,
  firebaseGetNotificationsRef,
  firebaseGetUserRef,
  firebaseGetCurrentUser,
} from 'Services/FirebaseService';
import { fetchBounty } from 'Services/bounty/BountyService';
import { BOUNTY, RESPONSE } from 'Models/CardType';
import { hasStarsLeft } from 'Models/Account';
import { isInvalid } from 'Modules/group/util/MemberUtils';
import { FOLLOW } from 'Models/UserSocial';
import { uploadUserProfilePicture } from 'Services/UploadService';
import { LOGGED_USER } from 'Store/reducerProperties';
import { sortAlphabetically } from 'Util/GeneralUtils';

function getBookmarkedBounty(userId, bookmark) {
  return new Promise((resolve) => {
    firebaseGetUserStreamsRef(userId)
      .child(bookmark.bountyId)
      .once('value', (dataSnapshotBounty) => {
        const bountyDetails = dataSnapshotBounty.val();

        if (bountyDetails && bountyDetails.bounty && bountyDetails.bounty.id) {
          return resolve(bountyDetails);
        }

        fetchBounty(bookmark.bountyId)
          .then((response) => resolve({ bounty: response }))
          .catch(() => resolve(null));
      });
  });
}

function getBookmarkedResponse(ownerId, bookmark, key) {
  return new Promise((resolve) => {
    firebaseGetUserResponsesRef(ownerId)
      .child(bookmark.bountyId).child(key)
      .once('value', (dataSnapshotResponse) => resolve(dataSnapshotResponse.val()));
  });
}

export const getMyFavorites = ({ ownerId, userId }) => (dispatch) => {
  dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, true));

  return firebaseGetMyFavorites({ ownerId, userId })
    .on('value', (dataSnapshot) => {
      const bookmarks = dataSnapshot.val();
      const promises = [];

      if (!bookmarks || !Object.keys(bookmarks).length) {
        dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, []));
        return dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, false));
      }

      Object.keys(bookmarks).forEach((key) => {
        const bookmark = bookmarks[key];

        if ((bookmark.cardType === BOUNTY || bookmark.cardType === 'B') && bookmark.bountyId) {
          promises.push(getBookmarkedBounty(userId, bookmark));
        }

        if (bookmark.cardType === RESPONSE && bookmark.bountyId) {
          promises.push(getBookmarkedResponse(ownerId, bookmark, key));
        }
      });

      Promise.all(promises)
        .then((values) => {
          const filteredValues = values.filter((el) => el != null);

          dispatch(updateDataAsyncAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, filteredValues));
          dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, false));
        })
        .catch((err) => {
          dispatch(isLoadingAction(USER_STREAMS_NAMESPACE, reducerProperties.USER_STREAMS, false));
          console.log('err promise all bookmarks', err); // eslint-disable-line
        });
    });
};

export const saveCreditCard = ({ userId, token, cardInfo }) => () => (
  new Promise((resolve) => {
    const ref = firebaseGetUserDataRef(userId).child('payments').push();
    const cardInfoClone = { ...cardInfo };
    cardInfoClone.id = ref.key;

    ref.set(cardInfoClone);

    addToQueue(
      queues.NEW_CREDIT_CARD,
      { cardId: cardInfoClone.id, stripeTokenId: token.id, cardInfo: cardInfoClone },
    );

    resolve(cardInfoClone);
  })
);

export const getGroupMembers = ({ groupId }) => (dispatch) => (
  new Promise((resolve) => {
    dispatch(isLoadingAction(GROUP_NAMESPACE, reducerProperties.GROUP_MEMBERS, true));

    firebasegetGroupMembers(groupId).on('value', (dataSnapshot) => {
      const dataSnapshotVal = convertObjToArray(dataSnapshot.val());
      dispatch(updateDataAsyncAction(GROUP_NAMESPACE, reducerProperties.GROUP_MEMBERS, dataSnapshotVal));
      dispatch(isLoadingAction(GROUP_NAMESPACE, reducerProperties.GROUP_MEMBERS, false));

      return resolve(dataSnapshot.val());
    });
  })
);

export const getGroupProfile = ({ userGroups, groupId }) => (dispatch) => (
  new Promise((resolve) => {
    dispatch(isLoadingAction(GROUP_NAMESPACE, reducerProperties.GROUP_PROFILE, true));

    firebasegetGroupMembers(userGroups.id)
      .orderByChild('user/id')
      .equalTo(groupId)
      .on('value', (groupSnapshot) => {
        const groupSnapshotVal = groupSnapshot.val() || {};
        const group = Object.values(groupSnapshotVal).length ? Object.values(groupSnapshotVal)[0] : {};

        firebaseGetMyProfileRef(groupId).on('value', (dataSnapshot) => {
          const dataSnapshotVal = dataSnapshot.val();

          firebasegetGroupMembers(groupId)
            .on('value', (membersSnapshot) => {
              group.members = convertObjToArray(membersSnapshot.val());
              group.groupProfile = dataSnapshotVal;

              dispatch(updateDataAsyncAction(GROUP_NAMESPACE, reducerProperties.GROUP_PROFILE, group));
              dispatch(isLoadingAction(GROUP_NAMESPACE, reducerProperties.GROUP_PROFILE, false));

              return resolve(group);
            });
        });
      });
  })
);
export const getGroups = ({ groupId, teamCompany, canSeeGroupEverybody }) => (dispatch) => {
  dispatch(isLoadingAction(GROUP_NAMESPACE, reducerProperties.GROUPS, true));

  firebasegetGroupMembers(groupId).on('value', (dataSnapshot) => {
    const dataSnapshotVal = convertObjToArray(dataSnapshot.val());
    const groups = [];
    const promises = [];

    dataSnapshotVal.forEach((group) => {
      if (!isInvalid(group)) {
        groups.push(group);
        promises.push(new Promise((resolve) => {
          firebaseGetMyProfileRef(group.user.id).once('value', (myProfileSnapshot) => {
            resolve(myProfileSnapshot.val());
          });
        }));
        promises.push(new Promise((resolve) => {
          firebasegetGroupMembers(group.user.id).once('value', (groupMembersSnapshot) => {
            const members = convertObjToArray(groupMembersSnapshot.val());

            resolve({ membersData: true, members, groupId: group?.user?.id });
          });
        }));
      }
    });

    Promise.all(promises)
      .then((values) => {
        values.forEach((data) => {
          if (data && data.membersData) {
            const group = groups.find((groupData) => groupData.user && groupData.user.id === data.groupId);
            group.members = data.members;
          }
          if (data && data.user) {
            const group = groups.find((groupData) => groupData.user && groupData.user.id === data.user.id);
            group.groupProfile = data;
          }
        });

        const sortedGroups = sortAlphabetically({ data: groups, sortBy: 'user.name' });

        if (teamCompany != null && canSeeGroupEverybody) {
          sortedGroups.unshift(teamCompany);
        }

        dispatch(updateDataAsyncAction(GROUP_NAMESPACE, reducerProperties.GROUPS, sortedGroups));
        dispatch(isLoadingAction(GROUP_NAMESPACE, reducerProperties.GROUPS, false));
      });
  });
};

export const getGroupInvites = ({ groupId }) => (dispatch) => {
  //  Group id from company settings
  dispatch(isLoadingAction(GROUP_NAMESPACE, reducerProperties.GROUP_INVITES, true));

  firebasegetGroupInvites(groupId).on('value', (dataSnapshot) => {
    const dataSnapshotVal = convertObjToArray(dataSnapshot.val());
    dispatch(updateDataAsyncAction(GROUP_NAMESPACE, reducerProperties.GROUP_INVITES, dataSnapshotVal));
    dispatch(isLoadingAction(GROUP_NAMESPACE, reducerProperties.GROUP_INVITES, false));
  });
};

export const getMyEmployees = ({ ownerId }) => (dispatch) => {
  //  Owner id from userData
  dispatch(isLoadingAction(GROUP_NAMESPACE, reducerProperties.EMPLOYEES, true));

  firebasegetGroupMembers(ownerId).on('value', (dataSnapshot) => {
    const dataSnapshotVal = convertObjToArray(dataSnapshot.val());
    dispatch(updateDataAsyncAction(GROUP_NAMESPACE, reducerProperties.EMPLOYEES, dataSnapshotVal));
    dispatch(isLoadingAction(GROUP_NAMESPACE, reducerProperties.EMPLOYEES, false));
  });
};

export const getMyAccount = (userId) => (
  new Promise((resolve) => (
    firebaseGetMyAccountRef(userId).once('value', (dataSnapshot) => (resolve(dataSnapshot.val())))
  ))
);

export const isHavingVotesLeft = async (userId) => {
  const myAccount = await getMyAccount(userId);
  return myAccount && hasStarsLeft(myAccount);
};

function getRelAvailablePointsPath(currency) {
  return `points/${currency}/amount`;
}

export function setMyPoints({ amount, currency }) {
  const currentUser = firebaseGetCurrentUser();
  firebaseGetMyAccountRef(currentUser.uid).child(getRelAvailablePointsPath(currency)).set(amount);
}

export const checkSocialActionState = (myUserId, userId, socialActionType) => new Promise((resolve) => {
  getSocialActionStateRef(userId, socialActionType)
    .once('value', (dataSnapshot) => resolve(dataSnapshot.val()));
});

export const changeSocialActionState = (userId, socialActionType, userSocial) => {
  const ref = getSocialActionStateRef(userId, socialActionType);

  if (userSocial != null) {
    return ref.set(userSocial);
  }

  ref.remove();
};

export const reportUser = ({ loggedUserId, userId, reason }) => {
  const userBlock = {
    blockedUserId: userId,
    reason,
    blockedAt: new Date().getTime(),
  };

  getMyBlocksRef(loggedUserId).child(userId).set(userBlock);
  addToQueue(queues.REPORT_USER, { reportedUserId: userId, reason });
};

export const updateAccount = ({
  userId, adjSet, adjReward, note,
}) => {
  const adjData = {
    accountId: userId,
    set: adjSet,
    reward: adjReward,
    note,
  };

  addToQueue(queues.UPDATE_ACCOUNT, adjData);
};

export const followUser = ({ userId, follow, assumeSuccess }) => {
  const userSocial = {
    actedAt: new Date().getTime(),
    pending: assumeSuccess ? null : true,
  };

  addToQueue(queues.FOLLOW_USER, { userId, follow });
  changeSocialActionState(userId, FOLLOW, follow ? userSocial : null);
};

/**
 * @param {Object} user - Firebase user
 */
function updateMyUser(user) {
  const {
    displayName, email, photoURL, providerId,
  } = user;
  const avatar = photoURL != null ? photoURL.toString() : null;
  let name = displayName;

  if (!displayName && email) {
    name = email.split('@')[0]; // eslint-disable-line
  }

  addToQueue(queues.UPDATE_USER, {
    clientId: null, deviceJson: null, providerId, name, avatar, email,
  });
}

/**
 * @param {Object} user - Firebase user
 */
function updateMyData(user) {
  const userDataChanges = {};
  const { email, photoURL, uid } = user;

  if (email) {
    userDataChanges.email = email;
  }

  if (photoURL) {
    userDataChanges['myself/avatar'] = photoURL;
  }

  if (Object.keys(userDataChanges).length) {
    firebaseGetUserDataRef(uid).update(userDataChanges);
  }
}

function saveMyUser(user) {
  updateMyUser(user);
  updateMyData(user);
}

export const updateMyPicture = (userData, userProfile, file) => async (dispatch) => {
  const attachment = await uploadUserProfilePicture(userProfile, file, userData);
  const user = firebaseGetCurrentUser();
  const photoURL = attachment?.url || null;

  const payload = {
    displayName: user.displayName,
    photoURL,
  };

  user.updateProfile(payload)
    .then(() => {
      const { uid, email, emailVerified, displayName, isAnonymous } = firebaseGetCurrentUser();
      const dataForFirebase = firebaseGetCurrentUser();
      const data = {
        uid,
        email,
        emailVerified,
        displayName,
        isAnonymous,
        photoURL,
      };

      dispatch(updateDataAsyncAction(AUTH_NAMESPACE, LOGGED_USER, data));

      saveMyUser(dataForFirebase);
    })
    .catch((error) => {
      console.error('error', error); // eslint-disable-line
    });
};

export const updateCompanyPicture = (userData, userProfile, file) => async () => {
  const attachment = await uploadUserProfilePicture(userProfile, file, userData);
  const photoURL = attachment?.url || null;

  addToQueue(queues.UPDATE_COMPANY, { avatar: photoURL, companyId: userData?.company?.id || null });
};

export function getUserCommentsRef(userId) {
  return firebaseGetUserCommentsRef().child('users').child(userId);
}

export function deleteMyNotifications() {
  const user = firebaseGetCurrentUser();
  firebaseGetNotificationsRef(user.uid).remove();
}

function isMe(user) {
  const me = firebaseGetCurrentUser();
  return user && user.id === me.uid;
}

export async function updateUserPicture(user, avatar) {
  if (isMe(user)) {
    firebaseGetUserRef(user.id).child('avatar').set(avatar);
  }

  addToQueue(queues.UPDATE_USER, { userId: user.id, avatar });
}

export async function prepareGroupAttachment({
  userData, groupProfile, file, isGroupAvatar,
}) {
  const attachment = await uploadUserProfilePicture(groupProfile, file, userData);
  const photoURL = attachment?.url || null;

  if (isGroupAvatar) {
    return updateUserPicture(groupProfile.user, photoURL);
  }
}
