// STANDARD / AUTH
export const NOT_FOUND = '/not-found';
export const HOME_ROUTE = '/';
export const V2_HOME_ROUTE = '/home';
export const SIGN_UP_ROUTE = '/signup';
export const SIGN_IN_ROUTE = '/signin';
export const FORGOT_PASSWORD_ROUTE = '/forgotPassword';
export const COVER_BOUNTY_ROUTE = '/cover/:bountyId';

// LANDING
export const LANDING_ROUTE = '/landing';
export const LANDING_REGISTRATION_ROUTE = `${LANDING_ROUTE}/registration`;
export const LANDING_WELCOME_ROUTE = `${LANDING_ROUTE}/welcome`;
export const LANDING_ADDRESS_ROUTE = `${LANDING_ROUTE}/address`;
export const LANDING_PAYMENT_ROUTE = `${LANDING_ROUTE}/place-order`;
export const LANDING_ORDER_CONFIRMATION_ROUTE = `${LANDING_ROUTE}/order-confirmation`;
export const LANDING_STEP_ROUTE = `${LANDING_ROUTE}/:stepName`;
export const CANDIDATES_ROUTE = '/candidates';

// USER
export const MY_ACCOUNT_ROUTE = '/myAccount';
export const PROFILE_ROUTE = '/myProfile';
export const LOGGED_OUT_PROFILE_ROUTE = '/myProfile';
export const USER_ADDRESSES_ROUTE = '/userAddresses';
export const USER_CARDS_ROUTE = '/userCards';
export const USER_PROFILE_ROUTE = `${PROFILE_ROUTE}/:userId`;

// MERCHANT
export const MERCHANT_ROUTE = '/merchant';
export const MERCHANT_LANDING_ROUTE = `${MERCHANT_ROUTE}/landing`;
export const MERCHANT_SIGNUP_ROUTE = `${MERCHANT_ROUTE}/signup`;
export const MERCHANT_REGISTER_COMPANY_ROUTE = `${MERCHANT_ROUTE}/registerCompany`;
export const MERCHANT_URL_COMPANY_ROUTE = `${MERCHANT_ROUTE}/urlCompany`;

// CLIENT
export const COMPANY_ROUTE = '/company';
export const COMPANY_PROFILE_ROUTE = `${COMPANY_ROUTE}/:id`;
export const COMPANY_CREATE_ROUTE = `${COMPANY_ROUTE}/create/:orgType`;
export const COMPANY_CREATE_BASE_ROUTE = `${COMPANY_ROUTE}/create`;
export const COMPANIES_BY_ORG_TYPE = `${COMPANY_ROUTE}/org/:orgType`;

export const JOBS_ROUTE = '/v1_jobs';
export const JOB_DETAILS_WITH_TYPE_ROUTE = `${JOBS_ROUTE}/:bountyType/:bountyId`;
export const COMPANY_SENTS_ROUTE = '/companySents';
export const COMPANY_PIN_ROUTE = '/c/:pin';

// STREAM
export const STREAM_ROUTE = '/stream';
export const STREAM_DETAILS_ROUTE = `${STREAM_ROUTE}/:bountyId`;
export const STREAM_DETAILS_WITH_TYPE_ROUTE = `${STREAM_ROUTE}/:bountyType/:bountyId`;
export const STREAM_SUB_DETAILS_ROUTE = `${STREAM_DETAILS_WITH_TYPE_ROUTE}/:subBountyId`;

//  USER SENTS
export const USER_SENTS_ROUTE = '/userSents';
export const USER_SENTS_DETAILS_ROUTE = `${USER_SENTS_ROUTE}/:bountyType/:bountyId`;
export const USER_SENTS_SUB_DETAILS_ROUTE = `${USER_SENTS_DETAILS_ROUTE}/:subBountyId`;

//  STATS
export const RESPONSE_DETAILS_ROUTE = `${STREAM_ROUTE}/bounty/:bountyId/responses/:responseId`;

// export const CLASSIFIED_DETAILS_ROUTE = `${STREAM_DETAILS_WITH_TYPE_ROUTE}/:type`;

// SEARCH
export const BOUNTY_SEARCH_BY_HASHTAGS = '/bountySearch';

// CHAT
export const CHAT_ROUTE = '/chats';
export const CHAT_ROOM_ROUTE = `${CHAT_ROUTE}/:roomId`;

// PRODUCTS
export const PRODUCTS_ROUTE = '/products';
export const PRODUCTS_DETAIL_ROUTE = '/products/details/:productId';
export const UPLOAD_PRODUCTS_ROUTE = '/uploadProducts';

// CART
export const CART_ROUTE = '/cart';

// ORDERS
export const ORDER_LIST_ROUTE = '/orders';
export const RECEIVED_ORDER_LIST_ROUTE = '/receivedOrders';
export const ORDER_ROUTE = `${ORDER_LIST_ROUTE}/:bountyId`;
export const RECEIVED_ORDER_ROUTE = `${RECEIVED_ORDER_LIST_ROUTE}/:bountyId`;
export const ORDER_MERCHANT_ROUTE = `${ORDER_ROUTE}/merchant/:merchantId`;

// LEADERBOARD
export const LEADERBOARD_ROUTE = '/leaderboard';

// REPORTS
export const REPORTS_ROUTE = '/reports';

// GROUPS
export const GROUPS_ROUTE = '/groups';
export const GROUPS_PROFILE_ROUTE = `${GROUPS_ROUTE}/:groupId`;
export const GROUPS_MEMBERS_ROUTE = `${GROUPS_ROUTE}/:groupId/members`;
export const GROUP_INVITES_ROUTE = `${GROUPS_ROUTE}/:groupId/invites`;

// PROMOTIONS
export const PROMOTIONS_ROUTE = '/promotions';
export const PROMOTION_DETAILS_ROUTE = `${PROMOTIONS_ROUTE}/:bountyId`;

// BADGES
export const BADGE_COLLECTIONS_ROUTE = '/badges';
export const BADGE_COLLECTION_DETAILS_ROUTE = `${BADGE_COLLECTIONS_ROUTE}/:collectionId`;
export const CREATE_BADGE_COLLECTION_ROUTE = '/create/badgeCollection';
export const EDIT_BADGE_COLLECTION_ROUTE = '/edit/badgeCollection/:collectionId';
export const CREATE_BADGE_ROUTE = `${CREATE_BADGE_COLLECTION_ROUTE}/:collectionId/badge`;

//  NOTIFICATIONS
export const CREATE_NOTIFICATION_ROUTE = '/notification';

//  APPLICANTS
export const APPLICANTS_ROUTE = '/v1_applications';
export const BLACK_LIST_APPLICANTS_ROUTE = '/blacklist-applicants';
export const REVIEWED_APPLICANTS_ROUTE = '/reviewed-applicants';
export const V2_APPLICATIONS_ROUTE = '/applications';
//  SETTINGS
export const SETTINGS_ROUTE = '/settings';

//  MATCHES
export const MATCHES_ROUTE = '/matches';
export const MATCH_DETAILS_ROUTE = '/matches/:id';

// TERMINAL
export const TERMINAL_ROUTE = '/terminal';

// JOB PREVIEW
export const V2_JOBS_ROUTE = '/jobs';

// USER MANAGEMENT
export const USER_MANAGEMENT_ROUTE = '/management';

// Job details
export const V2_JOB_DETAILS_ROUTE = `${V2_JOBS_ROUTE}/preview/:bountyId`;
export const V2_JOB_EDIT_ROUTE = `${V2_JOBS_ROUTE}/edit/:bountyId`;
export const V2_JOB_ADD_ROUTE = `${V2_JOBS_ROUTE}/new`;
