import { useEffect, useState, useCallback } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

//  Components
import RctPageLoader from 'Common/components/RctPageLoader';
import AppInfoData from 'Modules/layout/containers/AppInfoData';
import ModalContainer from 'Modules/modal/container/ModalContainer';
import DrawerContainer from 'Modules/modal/container/DrawerContainer';

//  Selectors
import { getLoggedUser, getRegistrationStep } from 'AuthSelectors';
import { routePermissionsSelector } from 'Store/selectors/permissions';

//  Actions
import { fetchCurrentUser } from 'Modules/authentication/store/operations';

//  Others
import {
  SIGN_IN_ROUTE, SIGN_UP_ROUTE, STREAM_ROUTE, LANDING_ROUTE, PRODUCTS_ROUTE, MERCHANT_LANDING_ROUTE,
  MERCHANT_SIGNUP_ROUTE, FORGOT_PASSWORD_ROUTE, LOGGED_OUT_PROFILE_ROUTE,
} from 'Constants/routes';
import { stash, youkno, dripdrop } from 'Constants/common';
import { getApplicationConfig } from 'Util/AppUtils';

import { handleRestriction } from 'Modules/authentication/utils/loginHandlers';
import { AsyncSignupWizard, AsyncForgotPassword } from './routes/configs/AsyncRoutes';
import RoutesComponent from './routes/components/RoutesComponent';

const appConfig = getApplicationConfig();

const propTypes = {
  location: object.isRequired,
  match: object.isRequired,
};

const authRoutes = [
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  LANDING_ROUTE,
  MERCHANT_LANDING_ROUTE,
  MERCHANT_SIGNUP_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGGED_OUT_PROFILE_ROUTE,
];

const Routes = ({ location, match }) => {
  const [beforeFetching, setBeforeFetching] = useState(true);
  const dispatch = useDispatch();
  const user = useSelector(getLoggedUser)?.data || {};
  const loading = useSelector(getLoggedUser)?.isLoading || false;
  const step = useSelector(getRegistrationStep) || {};
  const routePermissions = useSelector(routePermissionsSelector) || {};

  useEffect(() => {
    dispatch(fetchCurrentUser());
    setBeforeFetching(false);
  }, []);

  const handleRoutePermissions = useCallback(() => {
    !isAuth && handleRestriction(dispatch);
  }, [user.uid]);

  useEffect(() => {
    handleRoutePermissions();
  }, [user.uid]);

  const isAuth = !!Object.keys(user).length;

  if ((loading || beforeFetching) && location.pathname !== SIGN_UP_ROUTE) {
    return <RctPageLoader />;
  }

  if (authRoutes.includes(location.pathname) && isAuth && !Object.keys(step)) {
    if (appConfig.id === youkno) {
      return <Redirect to={STREAM_ROUTE} />;
    }

    if (appConfig.id === dripdrop) {
      return <Redirect to={STREAM_ROUTE} />;
    }

    if (appConfig.id === stash) {
      return <Redirect to={PRODUCTS_ROUTE} />;
    }
  }

  if ((!isAuth && location.pathname !== SIGN_IN_ROUTE
        && (!authRoutes.includes(location.pathname)
        && location.pathname.indexOf(LANDING_ROUTE) !== 0))) {
    return <Redirect to={SIGN_IN_ROUTE} />;
  }

  if (location.pathname === SIGN_UP_ROUTE) {
    return <Route exact path={SIGN_UP_ROUTE} component={AsyncSignupWizard} />;
  }

  if (location.pathname === FORGOT_PASSWORD_ROUTE) {
    return <Route exact path={FORGOT_PASSWORD_ROUTE} component={AsyncForgotPassword} />;
  }

  return (
    <>
      <ModalContainer />
      <DrawerContainer />
      <AppInfoData>
        <RoutesComponent
          location={location}
          user={user}
          match={match}
          permissions={routePermissions}
        />
      </AppInfoData>
    </>
  );
};

Routes.propTypes = propTypes;

export default Routes;
