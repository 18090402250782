//  Others
import {
  firebaseGetMyAccountRef,
  firebaseGetCurrentUser,
} from 'Services/FirebaseService';
import {
  updateDataAsyncAction,
  updateSimpleDataSyncAction,
  isSortableAction,
} from 'Store/actions/genericActions';
import * as reducerProperties from 'Store/reducerProperties';
import { AUTH_NAMESPACE, SETTINGS_NAMESPACE } from 'Store/namespaces';

export const getAccountInfo = () => (dispatch) => {
  const me = firebaseGetCurrentUser();

  firebaseGetMyAccountRef(me.uid).on('value', (dataSnapshot) => {
    const accountInfo = dataSnapshot.val();
    dispatch(updateDataAsyncAction(AUTH_NAMESPACE, reducerProperties.ACCOUNT_INFO, accountInfo));
  });
};

export const handleDrawerToggle = (val) => (dispatch) => {
  dispatch(updateSimpleDataSyncAction(SETTINGS_NAMESPACE, reducerProperties.IS_DRAWER_OPEN, val));
};

export const setSortable = (namespace, reduceProperty, value) => (dispatch) => {
  dispatch(isSortableAction(namespace, reduceProperty, value));
};
