import { createStore, applyMiddleware, compose } from 'redux';
import Thunk from 'redux-thunk';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';
import reducers from '../../store/reducers';

const getEnhancer = (options = {}) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-underscore-dangle
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined') {
      // eslint-disable-next-line no-underscore-dangle
      return window.__REDUX_DEVTOOLS_EXTENSION__(options);
    } if (typeof window.devToolsExtension !== 'undefined') {
      return window.devToolsExtension(options);
    }
  }
  return (f) => f;
};

export function configureStore(initialState) {
  const store = createStore(
    reducers,
    initialState,
    compose(
      applyMiddleware(
        Thunk,
        createSentryMiddleware(Sentry, {
        // adds user info from redux to Sentry error (searchable)
          getUserContext: ({ authUser: { user: { data } } }) => ({
            email: data ? data.email : null,
            id: data ? data.uid : null,
            username: data ? data.displayName : null,
          }),
          stateTransformer: (state) => {
            const newState = { ...state };
            delete newState.authUser; // omit user data from Sentry state

            return newState;
          },
        }),
      ),
      getEnhancer({ name: 'Rebounty' }),
    ),
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../../store/reducers', () => {
      const nextRootReducer = require('../../store/reducers'); // eslint-disable-line
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
