import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { isFirefox, isChrome, isSafari, isEdge } from 'react-device-detect';

const useStyles = makeStyles({
  container: {
    width: 360,
    padding: 16,
    margin: 'auto',
    borderRadius: 6,
    color: 'rgba(34, 34, 34, 1)',
    backgroundColor: '#fff',
    boxShadow: '0px 12px 24px rgba(103, 113, 121, 0.15)',
  },
  title: {
    marginBottom: 4,
    fontSize: '16px',
    lineHeight: '24px',
  },
  descriptions: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  wrapper: {
    backgroundColor: 'rgba(0, 0, 0, .2)',
    position: 'absolute',
    display: 'flex',
    zIndex: 999,
    width: '100%',
    height: '100%',
  },
  hide: {
    display: 'none',
  },
});

const CompatibilityMessage = () => {
  const styles = useStyles();
  const intl = useIntl();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!isFirefox && !isChrome && !isSafari && !isEdge) {
      setShow(true);
      document.body.style.pointerEvents = 'none';
    }
  }, []);

  return (
    <div className={show ? styles.wrapper : styles.hide}>
      <div className={styles.container}>
        <p className={styles.title}>
          {intl.formatMessage({ id: 'browser.compability.title' })}
        </p>
        <div className={styles.description}>
          {intl.formatMessage({ id: 'browser.compability.description' })}
        </div>
      </div>
    </div>
  );
};

export default CompatibilityMessage;
