export function extractDistributionTargetList(distribution) {
  return distribution && distribution.areasList && distribution.areasList[0] && distribution.areasList[0].areas
    ? distribution.areasList[0].areas
    : [];
}

export function extractDistributionTargetCodes(areas) {
  return areas.map((item) => (item.code));
}

export function formatDistributionAreasList(distributionList, exclude) {
  const areas = [];

  distributionList.forEach((area) => {
    if (area.exclude !== exclude) {
      areas.push({
        ...area,
        exclude,
      });
    } else {
      areas.push(area);
    }
  });

  return {
    areasList: {
      0: {
        areas,
      },
    },
  };
}

export function filterSelectedDistributionAreas(distributionList, codes) {
  return distributionList.filter((item) => (codes.includes(item.code)));
}

export function formatSentTo(distributionList) {
  const SPLIT_DELIMITER = '; ';
  const list = [];

  distributionList.forEach((item) => {
    list.push(item.text);
  });

  return { text: list.join(SPLIT_DELIMITER) };
}

// export function isEmpty(areasList) {
//   if (!areasList || (areasList && !Object.keys(areasList).length)) {
//     return true;
//   }
//
//   let isAreasListEmpty = true;
//
//   for (let i = 0; i < Object.keys(areasList).length; i++) {
//     if (areasList[i] && Object.keys(areasList[i]).length) {
//       isAreasListEmpty = false;
//       break;
//     }
//   }
//
//   return isAreasListEmpty;
// }
