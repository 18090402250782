import { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { instanceOf, string } from 'prop-types';
import Typography from '@mui/material/Typography';
import DatePicker from '@mui/lab/DatePicker';

import makeStyles from '@mui/styles/makeStyles';
import { TextField } from '@mui/material';

const DatePickerField = (props) => {
  const classes = useStyles();
  const {
    name, label, formik, errorValues, variant, component, format, ...remainingProps
  } = props;
  const { value, error } = formik.getFieldMeta(name);
  const { setError, setValue } = formik.getFieldHelpers(name);

  useEffect(() => {
    if (errorMessage) {
      setError('');
    }
  }, [value]);

  const errorMessage = error && error[0];

  const handleChanges = (date) => {
    setValue(new Date(date).getTime());
  };

  return (
    <FormControl fullWidth>
      {label && (
        <FormLabel className={classes.label}>
          <Typography component="span" variant="body2">{label}</Typography>
        </FormLabel>
      )}
      <DatePicker
        {...remainingProps}
        clearable
        inputFormat={format}
        // inputVariant={variant}
        name={name}
        value={value || null}
        onChange={handleChanges}
        helperText={errorMessage}
        error={!!errorMessage}
        animateYearScrolling
        renderInput={(inputProps) => <TextField {...inputProps} />}
      />
    </FormControl>
  );
};

DatePickerField.propTypes = {
  name: string.isRequired,
  label: string,
  errorValues: instanceOf(Object),
  formik: instanceOf(Object),
  component: string,
  size: string,
  variant: string,
  format: string,
};

DatePickerField.defaultProps = {
  errorValues: {},
  formik: {},
  component: '',
  label: '',
  size: 'medium',
  variant: 'default',
  format: 'dd/MM/yyyy',
};

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(1),
  },
}));

export default DatePickerField;
