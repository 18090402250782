import * as ROUTES from 'Constants/routes';
import * as AsyncComponents from './AsyncRoutes';

const STREAM = {
  path: ROUTES.STREAM_ROUTE,
  component: AsyncComponents.AsyncStreamPageContainer,
  exact: true,
};

const STREAM_DETAILS = {
  path: ROUTES.STREAM_DETAILS_WITH_TYPE_ROUTE,
  component: AsyncComponents.AsyncBountyDetailsContainer,
  exact: true,
};

const JOB_DETAILS = {
  path: ROUTES.JOB_DETAILS_WITH_TYPE_ROUTE,
  component: AsyncComponents.AsyncBountyDetailsContainer,
  exact: true,
};

const V2_JOB_DETAILS = {
  path: ROUTES.V2_JOB_DETAILS_ROUTE,
  component: AsyncComponents.AsyncV2Job,
  exact: true,
};

const V2_JOB_ADD = {
  path: ROUTES.V2_JOB_ADD_ROUTE,
  component: AsyncComponents.AsyncV2JobEdit,
  exact: true,
};

const V2_JOB_EDIT = {
  path: ROUTES.V2_JOB_EDIT_ROUTE,
  component: AsyncComponents.AsyncV2JobEdit,
  exact: true,
};

const SIMPLE_STREAM_DETAILS = {
  path: ROUTES.STREAM_DETAILS_ROUTE,
  component: AsyncComponents.AsyncBountyDetailsContainer,
  exact: true,
};

const STREAM_SUB_DETAILS = {
  path: ROUTES.STREAM_SUB_DETAILS_ROUTE,
  component: AsyncComponents.AsyncBountyDetailsContainer,
  exact: true,
};

const RESPONSE_DETAILS = {
  path: ROUTES.RESPONSE_DETAILS_ROUTE,
  component: AsyncComponents.AsyncBountyResponseDetailsContainer,
  exact: true,
};

const MY_ACCOUNT = {
  path: ROUTES.MY_ACCOUNT_ROUTE,
  component: AsyncComponents.AsyncMyAccountPageContainer,
  exact: true,
};

const LEADERBOARD = {
  path: ROUTES.LEADERBOARD_ROUTE,
  component: AsyncComponents.AsyncLeaderboardPageContainer,
  exact: true,
};

const PROFILE = {
  path: `${ROUTES.PROFILE_ROUTE}/:userId`,
  component: AsyncComponents.AsyncMyProfilePageContainer,
  exact: true,
};

const ORG_PROFILE = {
  path: '/:companyType/profile/:userId',
  component: AsyncComponents.AsyncMyProfilePageContainer,
  exact: true,
};

const LOGGED_OUT_PROFILE = {
  path: ROUTES.LOGGED_OUT_PROFILE_ROUTE,
  component: AsyncComponents.AsyncMyProfilePageContainer,
  exact: true,
};

const USER_SENTS = {
  path: ROUTES.USER_SENTS_ROUTE,
  component: AsyncComponents.AsyncUserSentsContainer,
  exact: true,
};

const USER_SENTS_DETAILS = {
  path: ROUTES.USER_SENTS_DETAILS_ROUTE,
  component: AsyncComponents.AsyncBountyDetailsContainer,
  exact: true,
};

const USER_SENTS_SUB_DETAILS = {
  path: ROUTES.USER_SENTS_SUB_DETAILS_ROUTE,
  component: AsyncComponents.AsyncBountyDetailsContainer,
  exact: true,
};

export const CHAT = {
  path: ROUTES.CHAT_ROUTE,
  component: AsyncComponents.AsyncChatComponent,
  exact: true,
};

const CHAT_ROOM = {
  path: ROUTES.CHAT_ROOM_ROUTE,
  component: AsyncComponents.AsyncChatComponent,
};

const BOUNTY_SEARCH = {
  path: ROUTES.BOUNTY_SEARCH_BY_HASHTAGS,
  component: AsyncComponents.AsyncHashtagSearch,
};

const CART = {
  path: ROUTES.CART_ROUTE,
  component: AsyncComponents.AsyncCart,
  exact: true,
};

const GROUPS = {
  path: ROUTES.GROUPS_ROUTE,
  component: AsyncComponents.AsyncGroupsContainer,
  exact: true,
};

const GROUPS_MEMBERS = {
  path: ROUTES.GROUPS_MEMBERS_ROUTE,
  component: AsyncComponents.AsyncGroupsMembersContainer,
  exact: true,
};

const GROUPS_PROFILE = {
  path: ROUTES.GROUPS_PROFILE_ROUTE,
  component: AsyncComponents.AsyncGroupProfileContainer,
  exact: true,
};

const GROUP_INVITES = {
  path: ROUTES.GROUP_INVITES_ROUTE,
  component: AsyncComponents.AsyncGroupInvitesContainer,
  exact: true,
};

const ORDER_LIST = {
  path: ROUTES.ORDER_LIST_ROUTE,
  component: AsyncComponents.AsyncOrderList,
  exact: true,
};

const RECEIVED_ORDER_LIST = {
  path: ROUTES.RECEIVED_ORDER_LIST_ROUTE,
  component: AsyncComponents.AsyncOrderList,
  exact: true,
};

const ORDER = {
  path: ROUTES.ORDER_ROUTE,
  component: AsyncComponents.AsyncOrderDetails,
  exact: true,
};

const RECEIVED_ORDER = {
  path: ROUTES.RECEIVED_ORDER_ROUTE,
  component: AsyncComponents.AsyncOrderDetails,
  exact: true,
};

const ORDER_MERCHANT = {
  path: ROUTES.ORDER_MERCHANT_ROUTE,
  render: (props) => {
    const Component = AsyncComponents.AsyncOrderMerchDetails;
    return <Component {...props} orderRoute={ROUTES.RECEIVED_ORDER_LIST_ROUTE} />;
  },
};

const PRODUCTS_DETAIL = {
  path: ROUTES.PRODUCTS_DETAIL_ROUTE,
  component: AsyncComponents.AsyncProductsDetailContainer,
  exact: true,
};

const USER_ADDRESSES = {
  path: ROUTES.USER_ADDRESSES_ROUTE,
  component: AsyncComponents.AsyncUserAddresses,
  exact: true,
};

const USER_CARDS = {
  path: ROUTES.USER_CARDS_ROUTE,
  component: AsyncComponents.AsyncUserCards,
  exact: true,
};

const COMPANY_PROFILE = {
  path: ROUTES.COMPANY_PROFILE_ROUTE,
  component: AsyncComponents.AsyncCompanyProfile,
  exact: true,
};

const COMPANY_CREATE = {
  path: ROUTES.COMPANY_CREATE_ROUTE,
  component: AsyncComponents.AsyncCreateCompany,
  exact: false,
};

const COMPANY_SENTS = {
  path: ROUTES.COMPANY_SENTS_ROUTE,
  component: AsyncComponents.AsyncCompanySentsContainer,
};

const UPLOAD_PRODUCTS = {
  path: ROUTES.UPLOAD_PRODUCTS_ROUTE,
  component: AsyncComponents.AsyncUploadProductsContainer,
};

const REPORTS = {
  path: ROUTES.REPORTS_ROUTE,
  component: AsyncComponents.AsyncReportsContainer,
};

const SETTINGS = {
  path: ROUTES.SETTINGS_ROUTE,
  component: AsyncComponents.AsyncSettings,
};

export const PRODUCTS = {
  path: ROUTES.PRODUCTS_ROUTE,
  component: AsyncComponents.AsyncProductsContainer,
  exact: true,
};

export const COMPANY_PIN = {
  path: ROUTES.COMPANY_PIN_ROUTE,
  component: AsyncComponents.AsyncCompanyPinContainer,
};

export const PROMOTION = {
  path: ROUTES.PROMOTIONS_ROUTE,
  component: AsyncComponents.AsyncPromotionsContainer,
  exact: true,
};

export const PROMOTION_DETAILS = {
  path: ROUTES.PROMOTION_DETAILS_ROUTE,
  component: AsyncComponents.AsyncPromotionDetailsContainer,
  exact: true,
};

export const BADGE_COLLECTIONS = {
  path: ROUTES.BADGE_COLLECTIONS_ROUTE,
  component: AsyncComponents.AsyncBadgeCollections,
  exact: true,
};

export const BADGE_COLLECTION_DETAILS = {
  path: ROUTES.BADGE_COLLECTION_DETAILS_ROUTE,
  component: AsyncComponents.AsyncBadgeCollectionDetails,
  exact: true,
};

export const CREATE_BADGE_COLLECTION = {
  path: ROUTES.CREATE_BADGE_COLLECTION_ROUTE,
  component: AsyncComponents.AsyncCreateBadgeCollection,
  exact: true,
};

export const EDIT_BADGE_COLLECTION = {
  path: ROUTES.EDIT_BADGE_COLLECTION_ROUTE,
  component: AsyncComponents.AsyncCreateBadgeCollection,
  exact: true,
};

export const CREATE_BADGE = {
  path: ROUTES.CREATE_BADGE_ROUTE,
  component: AsyncComponents.AsyncCreateBadge,
  exact: true,
};

export const CREATE_NOTIFICATION = {
  path: ROUTES.CREATE_NOTIFICATION_ROUTE,
  component: AsyncComponents.AsyncCreateNotification,
  exact: true,
};

export const MERCHANT_LANDING = {
  path: ROUTES.MERCHANT_LANDING_ROUTE,
  component: AsyncComponents.AsyncMerchantLanding,
  exact: true,
};

export const MERCHANT_SIGNUP = {
  path: ROUTES.MERCHANT_SIGNUP_ROUTE,
  component: AsyncComponents.AsyncMerchantSignup,
  exact: true,
};

export const MERCHANT_REGISTER_COMPANY = {
  path: ROUTES.MERCHANT_REGISTER_COMPANY_ROUTE,
  component: AsyncComponents.AsyncMerchantRegisterCompany,
  exact: true,
};

export const MERCHANT_URL_COMPANY = {
  path: ROUTES.MERCHANT_URL_COMPANY_ROUTE,
  component: AsyncComponents.AsyncMerchantUrlCompany,
  exact: true,
};

export const NOT_FOUND = {
  path: ROUTES.NOT_FOUND,
  component: AsyncComponents.AsyncNotFoundPage,
  exact: true,
};

export const COVER_BOUNTY = {
  path: ROUTES.COVER_BOUNTY_ROUTE,
  component: AsyncComponents.AsyncCoverBounty,
  exact: true,
};

export const SIGN_IN = {
  path: ROUTES.SIGN_IN_ROUTE,
  component: AsyncComponents.AsyncLoginContainer,
  exact: true,
};

export const SIGN_UP = {
  path: ROUTES.SIGN_UP_ROUTE,
  component: AsyncComponents.AsyncSignupWizard,
  exact: true,
};

export const LANDING_STEP = {
  path: ROUTES.LANDING_STEP_ROUTE,
  component: AsyncComponents.AsyncOnBoardingLayout,
  exact: true,
};

export const LANDING = {
  path: ROUTES.LANDING_ROUTE,
  component: AsyncComponents.AsyncOnBoardingLayout,
  exact: true,
};

export const JOBS_SENT = {
  path: ROUTES.JOBS_ROUTE,
  component: AsyncComponents.AsyncCompanyJobs,
  // exact: true,
};

export const APPLICANTS = {
  path: ROUTES.APPLICANTS_ROUTE,
  component: AsyncComponents.AsyncApplicants,
  exact: true,
};

export const V2_APPLICANTS = {
  path: ROUTES.V2_APPLICATIONS_ROUTE,
  component: AsyncComponents.AsyncV2Applications,
  exact: true,
};

export const V2_CANDIDATES = {
  path: ROUTES.CANDIDATES_ROUTE,
  component: AsyncComponents.AsyncV2Candidates,
  exact: true,
};

export const TERMINAL = {
  path: ROUTES.TERMINAL_ROUTE,
  component: AsyncComponents.AsyncTerminal,
  exact: true,
};

export const USER_MANAGEMENT = {
  path: ROUTES.USER_MANAGEMENT_ROUTE,
  component: AsyncComponents.AsyncUserManagement,
  exact: true,
};

export const REVIEWED_APPLICANTS = {
  path: ROUTES.REVIEWED_APPLICANTS_ROUTE,
  component: AsyncComponents.AsyncReviewedApplicants,
  exact: true,
};

export const BLACK_LIST_APPLICANTS = {
  path: ROUTES.BLACK_LIST_APPLICANTS_ROUTE,
  component: AsyncComponents.AsyncBlackListApplicants,
  exact: true,
};

export const COMPANY_LIST = {
  path: ROUTES.COMPANIES_BY_ORG_TYPE,
  component: AsyncComponents.AsyncCompanies,
  exact: true,
};

export const MATCHES_LIST = {
  path: ROUTES.MATCHES_ROUTE,
  component: AsyncComponents.AsyncMatchesList,
  exact: true,
};

export const MATCH_DETAILS_LIST = {
  path: ROUTES.MATCH_DETAILS_ROUTE,
  component: AsyncComponents.AsyncMatchDetails,
  exact: true,
};

export const V2JOBS = {
  path: ROUTES.V2_JOBS_ROUTE,
  component: AsyncComponents.AsyncV2Jobs,
  exact: true,
};

export const HOME = {
  path: ROUTES.V2_HOME_ROUTE,
  component: AsyncComponents.AsyncHomePage,
  exact: true,
};

export const commonRoutesWithoutLayout = [
  MERCHANT_LANDING,
  MERCHANT_SIGNUP,
  MERCHANT_REGISTER_COMPANY,
  MERCHANT_URL_COMPANY,
  COVER_BOUNTY,
  SIGN_IN,
  SIGN_UP,
  LANDING_STEP,
  LANDING,
];

export const commonRoutes = [
  STREAM,
  STREAM_DETAILS,
  SIMPLE_STREAM_DETAILS,
  STREAM_SUB_DETAILS,
  RESPONSE_DETAILS,
  JOB_DETAILS,
  V2_JOB_DETAILS,
  V2_JOB_EDIT,
  PROFILE,
  ORG_PROFILE,
  LOGGED_OUT_PROFILE,
  USER_SENTS,
  USER_SENTS_DETAILS,
  USER_SENTS_SUB_DETAILS,
  CHAT,
  CHAT_ROOM,
  BOUNTY_SEARCH,
  PRODUCTS_DETAIL,
  USER_ADDRESSES,
  USER_CARDS,
  COMPANY_PROFILE,
  PRODUCTS,
  COMPANY_PIN,
  SETTINGS,
  GROUPS,
  GROUPS_MEMBERS,
  GROUPS_PROFILE,
  GROUP_INVITES,
  HOME,
  TERMINAL,
  V2JOBS,
  USER_MANAGEMENT,
  NOT_FOUND,
];

export function getUploadsProductsRoutes(isAllowedToUploadProducts) {
  return isAllowedToUploadProducts ? [COMPANY_SENTS, UPLOAD_PRODUCTS] : [];
}

export function getReportRoutes(isAllowedToSeeReports) {
  return isAllowedToSeeReports ? [REPORTS] : [];
}
export function getBadgeManagementRoutes(isAllowedToCreateBadge) {
  return isAllowedToCreateBadge
    ? [
      BADGE_COLLECTIONS,
      BADGE_COLLECTION_DETAILS,
      CREATE_BADGE_COLLECTION,
      CREATE_BADGE,
      EDIT_BADGE_COLLECTION,
    ]
    : [];
}

export function getPromotionsRoutes(isAllowedToSeePromotions) {
  return isAllowedToSeePromotions ? [PROMOTION, PROMOTION_DETAILS] : [];
}

export function getDefaultRoutes(permissions) {
  const {
    isAllowedToUploadProducts,
    isAllowedToSeeReports,
    isAllowedToSeePromotions,
    isAllowedToCreateBadge,
  } = permissions;
  const routesForAllowedToUploadProducts = getUploadsProductsRoutes(isAllowedToUploadProducts);
  const reportRoutes = getReportRoutes(isAllowedToSeeReports);
  const promotionsRoutes = getPromotionsRoutes(isAllowedToSeePromotions);
  const badgeManagement = getBadgeManagementRoutes(isAllowedToCreateBadge);

  return [
    ...commonRoutes,
    ...routesForAllowedToUploadProducts,
    ...reportRoutes,
    ...promotionsRoutes,
    ...badgeManagement,
    ...(permissions.isAllowedToSeeMyAccount ? [MY_ACCOUNT] : []),
    ...(permissions.isAllowedToSeeMarket ? [ORDER_LIST, ORDER, ORDER_MERCHANT, CART] : []),
    ...(permissions.isAllowedToSeeRecvOrders ? [RECEIVED_ORDER_LIST, RECEIVED_ORDER, ORDER_MERCHANT] : []),
    ...(permissions.isAllowedToSeeLeaderBoard ? [LEADERBOARD] : []),
    ...(permissions.isAllowedToNotify ? [CREATE_NOTIFICATION] : []),
    ...(permissions.isAllowedToCreateJobs ? [JOBS_SENT, V2_JOB_ADD, V2_JOB_EDIT] : []),
    ...(permissions.isAllowedToSeeResponses ? [APPLICANTS, V2_APPLICANTS, V2_CANDIDATES, BLACK_LIST_APPLICANTS, REVIEWED_APPLICANTS] : []),
    ...(permissions.isAllowedToSeeCompanies ? [COMPANY_LIST] : []),
    ...(permissions.isAllowedToSeeApplicants ? [COMPANY_LIST] : []),
    ...(permissions.isAllowedToSeeMatch ? [MATCHES_LIST, MATCH_DETAILS_LIST] : []),
    ...(permissions.isAllowedToCreateOrganization ? [COMPANY_CREATE] : []),
  ];
}
