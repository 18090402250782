import { func, object } from 'prop-types';
import { TextField, Grid, IconButton } from '@mui/material';
import { Close, Check } from '@mui/icons-material';
import { useIntl } from 'react-intl';

const propTypes = {
  fields: object.isRequired,
  data: object.isRequired,
  handleChange: func.isRequired,
  onSave: func,
  onCancel: func,
};

const defaultProps = {
  onSave: null,
  onCancel: null,
};

const validation = {
  days: {
    min: 0,
    max: 100,
  },
  hours: {
    min: 0,
    max: 23,
  },
  minutes: {
    min: 0,
    max: 59,
  },
};

const ExpiryForm = (props) => {
  const intl = useIntl();
  const {
    data, fields, handleChange, onSave, onCancel,
  } = props;
  const {
    days, hours, minutes, label,
  } = fields;

  const handleInputChange = (event) => {
    const { target: { value, name } } = event;
    if (value < validation[name].min || value > validation[name].max) {
      return null;
    }

    handleChange(event);
  };

  return (
    <Grid container spacing={1}>
      <Grid className="expiry-label" item xs>
        <div className="black-color">{intl.formatMessage({ id: label })}</div>
      </Grid>
      <Grid item xs>
        <TextField
          size="small"
          id="number"
          fullWidth
          label={intl.formatMessage({ id: days.text })}
          name={days.name}
          value={data[days.name] || ''}
          onChange={handleInputChange}
          type="number"
          InputLabelProps={{ shrink: true }}
          inputProps={validation.days}
        />
      </Grid>
      <Grid item xs>
        <TextField
          size="small"
          id="number"
          fullWidth
          label={intl.formatMessage({ id: hours.text })}
          name={hours.name}
          value={data[hours.name] || ''}
          onChange={handleInputChange}
          type="number"
          InputLabelProps={{ shrink: true }}
          inputProps={validation.hours}
        />
      </Grid>
      <Grid item xs>
        <TextField
          size="small"
          id="number"
          fullWidth
          label={intl.formatMessage({ id: minutes.text })}
          name={minutes.name}
          value={data[minutes.name] || ''}
          onChange={handleInputChange}
          type="number"
          InputLabelProps={{ shrink: true }}
          inputProps={validation.minutes}
        />
      </Grid>
      {!!onSave && (
        <Grid className="expiry-action-buttons" item xs>
          <IconButton
            onClick={onCancel}
            size="small"
            className="mr-10 p-0 text-danger"
            aria-label="cancel"
          >
            <Close />
          </IconButton>
          <IconButton
            onClick={onSave}
            color="primary"
            className="p-0"
            size="small"
            aria-label="save"
          >
            <Check />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

ExpiryForm.propTypes = propTypes;
ExpiryForm.defaultProps = defaultProps;

export default ExpiryForm;
