export const currencyTypes = {
  NONE: 'NONE',
  UNKNOWN: 'UNKNOWN',
  USD: 'USD',
  CAD: 'CAD',
  EUR: 'EUR',
  RON: 'RON',
  MDL: 'MDL',
  GBP: 'GBP',
  PLN: 'PLN',
  GEL: 'GEL',
  ARS: 'ARS',
  AED: 'AED',
};

export const currencyFormat = {
  [currencyTypes.USD]: '$',
  [currencyTypes.CAD]: '$',
  [currencyTypes.EUR]: '€',
  [currencyTypes.RON]: 'lei',
  [currencyTypes.MDL]: 'lei',
  [currencyTypes.GBP]: '£',
  [currencyTypes.PLN]: 'zł',
  [currencyTypes.GEL]: 'gel',
  [currencyTypes.ARS]: '$',
  [currencyTypes.AED]: 'د.إ',
};

const invalidTypes = [
  currencyTypes.NONE,
  currencyTypes.UNKNOWN,
];

export function isValid(currency) {
  return currency && !invalidTypes.includes(currency);
}
