export function getOption(product, optionId) {
  const { options } = product;
  let cnt = -1;

  if (!options) {
    return null;
  }

  const { option1, option2, option3 } = options;

  if (option1 && Object.keys(option1).length) {
    cnt++;
  }

  if (optionId === cnt) {
    return option1;
  }

  if (option2 && Object.keys(option2).length) {
    cnt++;
  }

  if (optionId === cnt) {
    return option2;
  }

  if (option3 && Object.keys(option3).length) {
    cnt++;
  }

  if (optionId === cnt) {
    return option3;
  }

  return null;
}

export function getOptionCount(product) {
  const { options } = product;
  let cnt = 0;

  if (!options) {
    return 0;
  }

  const { option1, option2, option3 } = options;

  if (option1 && Object.keys(option1).length) {
    cnt++;
  }

  if (option2 && Object.keys(option2).length) {
    cnt++;
  }

  if (option3 && Object.keys(option3).length) {
    cnt++;
  }

  return cnt;
}

function getItem(options, i, key) {
  const option = options[`option${i}`] || null;
  return option && option.items && key ? option.items[key] : null;
}

function getOptionItem(options, i, key) {
  return getItem(options, i, key) || null;
}

export function getOptionItemName(options, i, key) {
  const item = options ? getOptionItem(options, i, key) : null;
  return item ? item.displayName : null;
}
