import { getMyUser } from 'Services/BaseService';
import { firebaseGetCurrentUser } from 'Services/FirebaseService';

export const newComment = async (myself, text, type, entityId) => {
  const creator = await getMyUser();
  const me = firebaseGetCurrentUser();

  return ({
    creator: creator || null,
    author: {
      id: me.uid || null,
      avatar: me.photoURL || null,
      name: me.displayName || null,
    },
    sentAt: new Date().getTime(),
    text: text || null,
    commentType: type.toString(),
    entityId,
  });
};

export const isMyComment = (creator) => {
  const me = firebaseGetCurrentUser();
  return !!(creator && creator.id && creator.id === me.uid);
};
