import { NotificationManager } from 'react-notifications';
import JobsService from 'Services/JobsService';

export const ACTIONS = {
  LOADING: 'jobsReducer/Loading',
  GET_LIST: 'jobsReducer/GetList',
  ERASE_LIST: 'jobsReducer/EraseList',
  UPDATE_TOTAL: 'jobsReducer/UpdateTotal',
  UPDATE_FILTERS: 'jobsReducer/UpdateFilters',
  UPDATE_REQ_PROPS: 'jobsReducer/UpdateReqProps',
  UPDATE_SEARCH_FIELDS: 'jobsReducer/UpdateSearchFields',
  REMOVE_JOB_LOCALLY: 'jobsReducer/RemoveJobLocally',
  UPDATE_JOB_STATE_LOCALLY: 'jobsReducer/UpdateJobStateLocally',
  RESET_FILTERS: 'jobsReducer/ResetFilters',
};

const updateLoadingStatus = (bool) => (dispatch) => {
  dispatch({
    type: ACTIONS.LOADING,
    payload: bool,
  });
};

export const getJobs = (options) => (dispatch) => {
  updateLoadingStatus(true)(dispatch);

  JobsService.getJobs(options)
    .then((response) => {
      dispatch({ type: ACTIONS.GET_LIST, payload: response.list });
      dispatch({ type: ACTIONS.UPDATE_TOTAL, payload: response.totalCount });
    })
    .catch((err) => {
      NotificationManager.error(err.message);
    })
    .finally(() => {
      updateLoadingStatus(false)(dispatch);
    });
};

export const eraseJobs = () => (dispatch) => dispatch({ type: ACTIONS.ERASE_LIST });

export const removeJobLocally = (id) => (dispatch) => dispatch({ type: ACTIONS.REMOVE_JOB_LOCALLY, payload: id });

export const updateJobStateLocally = (id, state) => (dispatch) =>
  dispatch({ type: ACTIONS.UPDATE_JOB_STATE_LOCALLY, payload: { id, state } });

const updateJobsReqProps = (reqProps) => (dispatch) =>
  dispatch({ type: ACTIONS.UPDATE_REQ_PROPS, payload: reqProps });

export const updateJobsOffset = (offset) => updateJobsReqProps({ start: offset });

export const updateJobsLimit = (limit) => updateJobsReqProps({ limit });

export const updateJobsOrder = (order) => updateJobsReqProps({ order, start: 0 });

export const updateJobsQuery = (query) => updateJobsReqProps({ query, start: 0 });

export const updateJobsFilters = (filters) => (dispatch) =>
  dispatch({
    type: ACTIONS.UPDATE_FILTERS,
    payload: { filters, reqProps: { start: 0, query: '' } }, // set query to '' on tabs switch
  });

export const updateJobsSearchFields = (fields) => (dispatch) =>
  dispatch({ type: ACTIONS.UPDATE_SEARCH_FIELDS, payload: fields });

export const resetApplicationsFilters = () => (dispatch) =>
  dispatch({ type: ACTIONS.RESET_FILTERS });
