import { NotificationManager } from 'react-notifications';

// Actions
import { updateDataAsyncAction, updateDataSyncAction, isLoadingAction } from 'Store/actions/genericActions';

// Other resources
import {
  firebaseSignInWithCustomToken,
  firebaseGetClientsRef,
  firebaseGetCurrentUser,
  firebaseGetUserDataRef,
  firebaseGetMyProfileRef,
  firebaseGetUserCommentsRef,
} from 'Services/FirebaseService';
import { addToQueue, getApiData, getDataUsingUser } from 'Services/Api';
import { HOME_ROUTE } from 'Constants/routes';
import { AUTH_NAMESPACE } from 'Store/namespaces';
import { queues } from 'Constants/queues';
import {
  USER_PROFILE,
  LOGGED_USER,
  RESET_STATE,
  REGISTRATION_ACTIVE_STEP,
  USER_PRIVATE_INFO,
  USER_ENDORSEMENTS,
} from 'Store/reducerProperties';
import { NICE_TO_MEET_STEP, GEO_IP } from 'Modules/authentication/utils/constants';
import IntlMessages from 'Util/IntlMessages';
import { formatApiRoute } from 'Util/api/apiHelpers';
import { USERS_PRIVATE } from 'Constants/apiRoutes';
import { convertObjToArray } from 'Util/helpers';
import {
  GoogleAuthProvider, RecaptchaVerifier,
  FacebookAuthProvider, GithubAuthProvider,
  TwitterAuthProvider, signInWithEmailAndPassword,
  signOut, signInWithPopup,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { auth } from 'Util/firebase';

export const saveMyInterests = (interests) => () => (addToQueue(queues.UPDATE_USER, { interests }));

export const subscribeToAuthUserEndorsements = (userId) => (dispatch) => {
  try {
    firebaseGetUserCommentsRef()
      .child('users')
      .child(userId)
      .on('value', (dataSnapshot) => {
        const data = convertObjToArray(dataSnapshot.val());
        dispatch(updateDataAsyncAction(AUTH_NAMESPACE, USER_ENDORSEMENTS, data));
      });
  } catch (e) {
    console.error(e);
  }
};

export const unsubscribeFromAuthUserEndorsements = (userId) => {
  firebaseGetUserCommentsRef(userId).off();
};

export const subscribeToAuthUserProfile = (userId) => (dispatch) => {
  try {
    firebaseGetMyProfileRef(userId)
      .on('value', async (dataSnapshot) => {
        const {
          basicInfo,
          user,
          label,
          description,
          rating,
          attachment,
          identity,
        } = dataSnapshot.val();

        const data = {
          basicInfo,
          user,
          label,
          description,
          rating,
          attachment,
          identity,
        };
        dispatch(updateDataAsyncAction(AUTH_NAMESPACE, USER_PROFILE, data));
      });
  } catch (e) {
    console.error(e);
  }
};

export const unsubscribeFromAuthUserProfile = (userId) => {
  firebaseGetMyProfileRef(userId).off();
};

export const getAuthUserPrivateInfo = (userId) => (dispatch) => {
  const path = formatApiRoute({ endpoint: USERS_PRIVATE, params: { userId } });

  getApiData(path).then((response) => {
    dispatch(updateDataAsyncAction(AUTH_NAMESPACE, USER_PRIVATE_INFO, response));
  });
};

export const saveBasicInfo = (data) => {
  const currentUser = firebaseGetCurrentUser();
  const uid = currentUser ? currentUser.uid : null;

  return firebaseGetUserDataRef(uid)
    .update(data)
    .then(() => {
      addToQueue(queues.UPDATE_USER, data);
      return Promise.resolve();
    })
    .catch((error) => Promise.reject(error));
};

export const getGeoIpInfo = () => getApiData(`/${GEO_IP}`)
  .then((response) => Promise.resolve(response));

export const verifyUserPin = (userPin) => () => (
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve) => firebaseGetClientsRef(userPin)
    .once('value', (datasnapshot) => {
      const data = datasnapshot.val();

      if (data) {
        return resolve(data);
      }

      NotificationManager.error(<IntlMessages id="invalid_pin" />);
    })
    .catch(() => NotificationManager.error(<IntlMessages id="invalid_pin" />))));

export const signinUserInFirebase = (user) => (dispatch) => {
  dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, true));

  return getDataUsingUser(`/clients/${user.pin}/users/${user.email}/auth?deviceId=web&deviceAuth=web`, user)
    .then((response) => (
      firebaseSignInWithCustomToken(response.jwt)
    ))
    .then((userResp) => {
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      dispatch(updateDataAsyncAction(AUTH_NAMESPACE, LOGGED_USER, userResp.user));

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      return Promise.reject(error);
    });
};

export const signinUserWithoutPin = ({ email, password }) => (dispatch) => {
  dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, true));

  return signInWithEmailAndPassword(auth, email, password)
    .then((userResp) => {
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      dispatch(updateDataAsyncAction(AUTH_NAMESPACE, LOGGED_USER, userResp.user));

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      return Promise.reject(error);
    });
};

export const fetchCurrentUser = () => (dispatch) => {
  dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, true));

  onAuthStateChanged(auth, (user) => {
    const { uid, email, emailVerified, displayName, isAnonymous, photoURL } = user || {};

    const data = {
      uid,
      email,
      emailVerified,
      displayName,
      isAnonymous,
      photoURL,
    };

    dispatch(updateDataAsyncAction(AUTH_NAMESPACE, LOGGED_USER, data));
    dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
  });
};

export const logoutUserFromFirebase = (props) => (dispatch) => {
  const { showNotification } = props || {};

  dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, true));

  return signOut(auth)
    .then(() => {
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      dispatch(updateDataAsyncAction(AUTH_NAMESPACE, LOGGED_USER, null));
      dispatch(updateDataAsyncAction(AUTH_NAMESPACE, RESET_STATE, null));
      localStorage.removeItem('pin');

      if (showNotification) {
        NotificationManager.success(<IntlMessages id="notification.success.logout" />);
      }

      Promise.resolve();
    })
    .catch((error) => {
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      NotificationManager.error(error.message);
      Promise.reject(error);
    });
};

export const signupUserInFirebase = ({
  email, password, name, clientId,
}, nextStep, history) => (dispatch) => {
  dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, true));

  window.recaptchaVerifier = new RecaptchaVerifier('recaptcha', {
    callback: () => {
      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          const user = firebaseGetCurrentUser();

          user.updateProfile({ displayName: name });
          dispatch(updateDataSyncAction(AUTH_NAMESPACE, REGISTRATION_ACTIVE_STEP, { step: NICE_TO_MEET_STEP }));
          dispatch(updateDataAsyncAction(AUTH_NAMESPACE, LOGGED_USER, user));

          return addToQueue(queues.UPDATE_USER, {
            clientId, userId: user.uid, email, name,
          });
        })
        .then(() => {
          dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));

          NotificationManager.success(<IntlMessages id="notification.success.accountCreated" />);

          if (nextStep) {
            history.push(nextStep);
          }
        })
        .catch((error) => {
          window.recaptchaVerifier.clear();
          dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
          NotificationManager.error(error.message);
        });
    },
  }, auth);
  window.recaptchaVerifier.render();
};

export const signinUserWithFacebook = () => (dispatch) => {
  dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, true));
  const provider = new FacebookAuthProvider();

  return signInWithPopup(auth, provider)
    .then((result) => {
      const { user } = result;
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      dispatch(updateDataAsyncAction(AUTH_NAMESPACE, LOGGED_USER, user));

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      NotificationManager.error(error.message);
      return Promise.reject(error);
    });
};

export const signinUserWithGoogle = () => (dispatch) => {
  dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, true));
  const provider = new GoogleAuthProvider();

  return signInWithPopup(auth, provider)
    .then((result) => {
      const { user } = result;
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      dispatch(updateDataAsyncAction(AUTH_NAMESPACE, LOGGED_USER, user));

      return Promise.resolve();
    })
    .catch((error) => {
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      NotificationManager.error(error.message);

      return Promise.reject(error);
    });
};

export const signinUserWithGithub = (history) => (dispatch) => {
  dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, true));
  const provider = new GithubAuthProvider();

  signInWithPopup(auth, provider)
    .then((result) => {
      const { user } = result;
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      dispatch(updateDataAsyncAction(AUTH_NAMESPACE, LOGGED_USER, user));

      history.push(HOME_ROUTE);
      NotificationManager.success(`Hi ${result.user.displayName}!`);
    })
    .catch((error) => {
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      NotificationManager.error(error.message);
    });
};

export const signinUserWithTwitter = (history) => (dispatch) => {
  dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, true));
  const provider = new TwitterAuthProvider();

  signInWithPopup(auth, provider)
    .then((result) => {
      const { user } = result;
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      dispatch(updateDataAsyncAction(AUTH_NAMESPACE, LOGGED_USER, user));

      history.push(HOME_ROUTE);
      NotificationManager.success(<IntlMessages id="notification.success.login" />);
    })
    .catch((error) => {
      dispatch(isLoadingAction(AUTH_NAMESPACE, LOGGED_USER, false));
      NotificationManager.error(error.message);
    });
};

export const forgotPassword = (email) => () => sendPasswordResetEmail(auth, email);
