import { ACTIONS } from 'Store/actions/skillsActions';

const initialState = {
  list: [],
  isLoading: false,
  isLoaded: false,
};

const skillsReducer = (state = initialState, action = {}) => {
  const { payload } = action;
  switch (action.type) {
    case ACTIONS.GET_DATA:
      return {
        ...state,
        list: payload,
      };

    case ACTIONS.LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case ACTIONS.LOADED:
      return {
        ...state,
        isLoaded: payload,
      };

    default:
      return state;
  }
};

export default skillsReducer;
