import { currencyFormat, currencyTypes } from 'Models/Currency';
import * as Money from 'Models/Money';
import { pointCurrency, multiplier, normalizeCurrency } from 'Models/PointCurrency';
import * as PointsUtils from 'Util/pointsUtils';
import { getQuantityString } from 'Util/GeneralUtils';
import { getTopLevelVariant } from 'Models/product/Product';
import { hasMoney, hasPoints, isRewardEmpty } from 'Models/Reward';
import { isZero } from 'Models/Points';

export const EMPTY_REWARD = '0';

export function getCurrencySymbol(currency) {
  return currencyFormat[currency] || currencyFormat[currencyTypes.CAD];
}

export function getCurrencyByCountry(countryCode) {
  switch (countryCode.toUpperCase()) {
    case 'RO':
      return currencyTypes.RON;
    case 'CA':
      return currencyTypes.CAD;
    case 'AD':
    case 'AT':
    case 'BE':
    case 'CY':
    case 'DE':
    case 'EE':
    case 'ES':
    case 'FI':
    case 'FR':
    case 'GR':
    case 'IE':
    case 'IT':
    case 'LT':
    case 'LU':
    case 'LV':
    case 'MC':
    case 'ME':
    case 'MT':
    case 'NL':
    case 'PT':
    case 'SK':
    case 'SI':
    case 'SM':
    case 'VA':
    case 'XK':
      return currencyTypes.EUR;
    default:
      return currencyTypes.USD;
  }
}

// !IMPORTANT this function is not 100% as the one from android
export function formatMoney(money, formal, defaultValue) {
  if (!money) {
    return defaultValue;
  }

  if (!money.amount) {
    return defaultValue;
  }

  const amountAsDec = Money.getAmountAsBigDecimal(money.amount);
  let amount = '';

  switch (money.currency) {
    case currencyTypes.EUR:
      amount = `${amountAsDec.replace('.', ',')} EUR`;
      break;
    case currencyTypes.CAD:
      amount = `${amountAsDec.replace('.', ',')} CAD`;
      break;
    case currencyTypes.USD:
      amount = `${amountAsDec.replace('.', ',')} USD`;
      break;
    case currencyTypes.RON:
      amount = `${amountAsDec.replace('.', ',')} RON`;
      break;
    case currencyTypes.NONE:
      amount = amountAsDec.toString();
      break;

    default: {
      if (money.currency) {
        amount = `${amountAsDec} ${money.currency}`;
      } else {
        amount = amountAsDec.toString();
      }
    }
  }

  if (!formal) {
    amount = amount.replace('[.]0*$', '');
  }

  return amount;
}

export function formatReward(reward, defaultValue) {
  let moneyStr = null;
  let pointStr = null;
  let voucherStr = null;

  if (!reward) {
    return defaultValue;
  }

  if (reward.money && !Money.isZero(reward.money)) {
    moneyStr = formatMoney(reward.money, false, defaultValue);
  }

  if (reward.points && PointsUtils.sumOfAbs(reward.points) > 0) {
    const stars = PointsUtils.getNormalizedPoints(reward.points, pointCurrency.STAR);

    if (stars > 0) {
      pointStr = stars === 1 ? 'one star' : `${stars} stars`;
    } else {
      const points = PointsUtils.getPointsAmount(reward, pointCurrency.POINT);
      pointStr = points === 1 ? '1 point' : `${points} points`;
    }
  }

  if (reward.voucher) {
    voucherStr = `1 ${reward.voucher.gist}`;
  }

  const arr = [];

  if (moneyStr && moneyStr.trim()) {
    arr.push(moneyStr);
  }

  if (pointStr && pointStr.trim()) {
    arr.push(pointStr);
  }

  if (voucherStr && voucherStr.trim()) {
    arr.push(voucherStr);
  }

  return arr.join(', ');
}

export function formatPrice(price) {
  if (!price) {
    return '';
  }

  const parts = [];

  if (price.mainPrice && Object.keys(price.mainPrice).length) {
    const reward = formatReward(price.mainPrice, EMPTY_REWARD);

    if (reward) {
      parts.push(reward);
    }
  }

  if (price.altPrice1 && Object.keys(price.altPrice1).length) {
    const reward = formatReward(price.altPrice1, EMPTY_REWARD);

    if (reward) {
      parts.push(reward);
    }
  }

  if (price.altPrice2 && Object.keys(price.altPrice2).length) {
    const reward = formatReward(price.altPrice2, EMPTY_REWARD);

    if (reward) {
      parts.push(reward);
    }
  }

  return parts.join(' / ');
}

export function getPriceCurrency(product, currencyList) {
  if (product) {
    return currencyList[0].value;
  }

  const productInfo = getTopLevelVariant(product);
  const reward = productInfo && productInfo.price ? productInfo.price : null;

  if (!reward || (reward && !reward.mainPrice)) {
    return currencyList[0].value;
  }

  if (reward.mainPrice.money) {
    return reward.mainPrice.money.currency;
  }

  if (reward.mainPrice.points) {
    return reward.mainPrice.points.currency;
  }

  return currencyList[0].value;
}

function multiplyMoney(money, quantity) {
  if (!money || !quantity) {
    return null;
  }

  return {
    amount: money.amount * quantity,
    currency: money.currency,
  };
}

function multiplyPts(point, quantity) {
  if (!point || !quantity) {
    return null;
  }

  if (isZero(point)) {
    return point;
  }

  return {
    amount: point.amount * quantity,
    currency: point.currency,
  };
}

function multiplyPoints(points, quantity) {
  const result = {};

  if (!points || !quantity) {
    return null;
  }

  if (!Object.keys(points).length) {
    return points;
  }

  Object.keys(points).forEach((pointType) => {
    result[pointType] = multiplyPts(points[pointType], quantity);
  });

  return result;
}

export function multiply(reward, quantity) {
  const result = {};

  if (!reward || !quantity) {
    return null;
  }

  if (isRewardEmpty(reward)) {
    return reward;
  }

  if (hasMoney(reward)) {
    result.money = multiplyMoney(reward.money, quantity);
  }
  if (hasPoints(reward)) {
    result.points = multiplyPoints(reward.points, quantity);
  }

  return result;
}

export function formatPoints(pts, skipEmpty = false, shortTexts = false) {
  if (!pts || !Object.keys(pts).length) {
    return '';
  }

  finalFormatPoints(pts.amount, pts.currency, skipEmpty, shortTexts);
}

/**
 * format points
 * @param {int} amount
 * @param {string} currency
 * @param {boolean} skipEmpty
 * @param {boolean} shortTexts
 * @returns {*}
 */
export function finalFormatPoints(amount, currency, skipEmpty, shortTexts) {
  if (amount === 0 && skipEmpty) {
    return '';
  }

  amount *= multiplier(currency); // eslint-disable-line

  switch (normalizeCurrency(currency)) {
    case pointCurrency.STAR:
      return getQuantityString('lbl_stars_count_one', 'lbl_stars_count_other', amount);
    case pointCurrency.POINT: {
      const labelIds = shortTexts
        ? ['lbl_pts_quantity_one', 'lbl_pts_quantity_other']
        : ['lbl_points_quantity_one', 'lbl_points_quantity_other'];
      return getQuantityString(...labelIds, amount);
    }
    case pointCurrency.MIN:
      return getQuantityString('lbl_mins_quantity_one', 'lbl_mins_quantity_other', amount);
    case pointCurrency.INVITE:
      return getQuantityString('lbl_invites_quantity_one', 'lbl_invites_quantity_other', amount);
    default:
      return `${amount} ${currency}`;
  }
}
