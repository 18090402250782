import { useState, useEffect } from 'react';
import { array, func, object } from 'prop-types';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';

//  Components
import LoadingComponent from 'Common/components/LoadingComponent';
import EmptyResults from 'Common/components/EmptyResults';
import SelectablePromotionCard from 'Common/components/SelectablePromotionCard';

//  Actions/Selectors
import { firebaseGetPromotionalOffersRef } from 'Services/FirebaseService';
import { updateListSyncAction, pushDataAction } from 'Store/actions/genericActions';
import { selectedPromotionsSelector } from 'Modules/modal/store/selectors';

// Others
import { builtinList } from 'Models/ListManager';
import { MARKET } from 'Modules/posts/bounty/constants/listType';
import { convertObjToArray } from 'Util/helpers';
import EmptyImage from 'Assets/img/emptyContent/es_incoming.png';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { PROMOTION_TO_ATTACH } from 'Store/reducerProperties';

const propTypes = {
  list: object,
  selectedPromotions: array,
  updateListSyncAction: func.isRequired,
  pushDataAction: func.isRequired,
};

const defaultProps = {
  list: builtinList[MARKET],
  selectedPromotions: [],
};

const AttachablePromotionsContainer = (props) => {
  const [promotions, setPromotions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { list, selectedPromotions } = props;

  const handleSelectPromotion = (item) => {
    const index = selectedPromotions?.findIndex((selectedProm) => selectedProm.id === item.id);

    if (index > -1) {
      const newData = selectedPromotions?.filter(((selectedProm) => selectedProm.id !== item.id));
      return props.updateListSyncAction(MODAL_NAMESPACE, PROMOTION_TO_ATTACH, newData);
    }

    return props.pushDataAction(MODAL_NAMESPACE, PROMOTION_TO_ATTACH, item);
  };

  const fetchPromotions = () => {
    setIsLoading(true);
    firebaseGetPromotionalOffersRef().once('value', (dataSnapshot) => {
      setPromotions(convertObjToArray(dataSnapshot.val()));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchPromotions();
  }, []);

  return (
    <LoadingComponent isLoading={isLoading}>
      {promotions?.length ? (
        <Grid container spacing={3}>
          {
            promotions.map((item) => (
              <Grid item xs={6} key={`product-${item.id}`}>
                <SelectablePromotionCard
                  promotion={item}
                  handleSelect={handleSelectPromotion}
                  selectedPromotions={selectedPromotions}
                />
              </Grid>
            ))
          }
        </Grid>
      ) : (
        <EmptyResults
          title={list.emptyListMsg}
          subTitle="emptyContent.stayTuned"
          emptyImage={EmptyImage}
        />
      )}
    </LoadingComponent>
  );
};

AttachablePromotionsContainer.propTypes = propTypes;
AttachablePromotionsContainer.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  selectedPromotions: selectedPromotionsSelector(state) || [],
});

const mapDispatchToProps = {
  pushDataAction,
  updateListSyncAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachablePromotionsContainer);
