//  Actions
import { createBounty, onEditBounty } from 'Services/bounty/BountyService';

//  Selectors
import { getLoggedUser } from 'AuthSelectors';
import { settingsSelector, userDataSelector } from 'Store/selectors/settings';
import { attachmentsSelector, removedAttachmentsSelector } from 'Modules/attachments/store/selectors';

//  Other resources
import { getIdentityMode } from 'Modules/posts/add/utils/addBountyHelpers';
import { prepareProduct, prepareTags, prepareVariants } from 'Modules/products/utils';
import { PRODUCT } from 'Constants/bounty/bountyType';
import { DRAFT_STATE } from 'Constants/bounty/bountyState';

function getStoreData(state) {
  return {
    user: getLoggedUser(state).data || {},
    attachments: attachmentsSelector(state) || [],
    removedAttachments: removedAttachmentsSelector(state) || [],
  };
}

export function createProduct(values, kind) {
  return (dispatch, getState) => {
    const state = getState();
    const userData = userDataSelector(state)?.data || {};
    const settings = settingsSelector(state)?.data || {};

    const { product, customData } = values;

    const bounty = {
      owner: userData.owner || null,
      identityMode: getIdentityMode({ settings, userData }),
      state: DRAFT_STATE,
      type: PRODUCT,
      description: { text: values.product.description },
      product: {
        ...prepareProduct(userData, kind),
        ...product,
        privateListingInfo: { listingMode: customData?.currencyType || null },
        tags: prepareTags(customData?.tag),
        variants: prepareVariants({
          kind,
          merchantSku: product.merchantSku,
          customData,
        }),
      },
    };

    return dispatch(createBounty(bounty, null));
  };
}

export function editProduct(values, kind, oldBounty) {
  return (dispatch, getState) => {
    const state = getState();
    const data = getStoreData(state);

    const { product, customData, ...remainingProps } = values;

    const newBounty = {
      ...remainingProps,
      description: { text: product.description },
      product: {
        ...product,
        privateListingInfo: { listingMode: customData?.currencyType || null },
        tags: prepareTags(customData?.tag),
        variants: prepareVariants({
          oldVariants: oldBounty?.product?.variants,
          merchantSku: product.merchantSku,
          kind,
          customData,
        }),
      },
    };

    const payload = {
      ...data,
      newBounty,
      oldBounty,
    };

    return dispatch(onEditBounty(payload));
  };
}
