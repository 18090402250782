export const USER_STREAMS_NAMESPACE = 'userStreamsReducer';
export const MODAL_NAMESPACE = 'modalReducer';
export const ON_BOARDING_NAMESPACE = 'onBoarding';
export const AUTH_NAMESPACE = 'authUser';
export const USER_ACCOUNT_NAMESPACE = 'userAccountReducer';
export const LEADERBOARD_NAMESPACE = 'leaderboardReducer';
export const MY_PROFILE_NAMESPACE = 'myProfileReducer';
export const SENTS_NAMESPACE = 'sentsReducer';
export const REPORTS_NAMESPACE = 'reportsReducer';
export const GROUP_NAMESPACE = 'groupReducer';
export const ATTACHMENTS_NAMESPACE = 'attachmentsReducer';
export const CART_NAMESPACE = 'cartReducer';
export const ORDER_NAMESPACE = 'orderReducer';
export const SETTINGS_NAMESPACE = 'settingsReducer';
export const APPLICANTS_NAMESPACE = 'applicantsReducer';
export const COMPANIES_NAMESPACE = 'companiesReducer';
