import { getDisplayName } from 'Util/BadgesUtils';
import { addBadge, removeBadge } from 'Models/Badge';
import {
  CLASSIFIED,
  PROMOTION,
  THREAD,
  LOTTERY,
  NEWS,
} from 'Constants/bounty/bountyType';
import { AppConfig } from 'Util/AppUtils';
import { getPromDescription } from 'Models/Promotion';
import IntlMessages from 'Util/IntlMessages';

export function updateBadges(bounty, badgeType, mark) {
  let newBadges = bounty.badges || {};

  if (mark) {
    const badgeInfo = {
      code: badgeType,
      id: badgeType,
      label: getDisplayName(badgeType),
    };
    newBadges = addBadge(newBadges, badgeInfo);
  } else {
    newBadges = removeBadge(newBadges, badgeType);
  }

  return newBadges;
}

export function getDescription({ survey, bounty }) {
  if (survey && survey.question) {
    return survey.question || null;
  }

  if (bounty?.type === PROMOTION) {
    return getPromDescription(bounty.description) || null;
  }

  if (bounty && bounty.description) {
    return bounty.description.text || bounty.description || null;
  }

  return null;
}

export function getReplyProps(bounty, hasMyResponse, isResponseAsComments) {
  const color = hasMyResponse ? AppConfig.themeColors.yellow : AppConfig.themeColors.primary;

  if (isResponseAsComments) {
    return {
      color: AppConfig.themeColors.primary,
      icon: 'chat_bubble_outline',
    };
  }

  if (bounty && bounty.type === CLASSIFIED) {
    return {
      color,
      icon: 'loyalty',
      label: hasMyResponse ? 'menu_see_my_offer' : 'menu_make_an_offer',
    };
  }

  return {
    color,
    icon: 'reply',
    label: hasMyResponse ? 'lbl_replied' : 'menu_bounty_reply',
  };
}

export function getPromotionCoverUrl(promotion) {
  const productDetails = promotion?.products ? Object.values(promotion.products)[0]?.product : {};
  return productDetails.imageUrl;
}

export function getProductCoverUrl(product) {
  const productDetails = product?.product || {};
  return productDetails.imageUrl;
}

export function getQuestionIndex({ bounty, prevIndex }) {
  if (bounty.type === THREAD) {
    return 0;
  }

  return prevIndex + 1;
}

export function generateTimeOptions({ minValue, maxValue, step }) {
  const options = [];

  for (let value = minValue; value <= maxValue; value += step) {
    options.push({ label: <IntlMessages id="bounty.timeInSec" values={{ value }} />, value });
  }

  return options;
}

export function hasExactlyOneImage(type) {
  return [NEWS].includes(type);
}

export function hasAtLeastOneAttachment(type) {
  return [LOTTERY].includes(type);
}

export const dictionaryObjToArray = (obj) =>
  (obj ? Object.entries(obj).map((x) => ({ name: x[1]?.name, value: x[0] })) : []);

export const benefitsToArray = (obj) => (
  obj ? Object.entries(obj)
    .filter((b) => b[1].enabled)
    .map((b) => (b[0])) : []
);

export const attachmentsToArray = (obj) => (obj ? Object.entries(obj).map((a) => (a[1])) : []);
