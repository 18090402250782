import { string, func, object } from 'prop-types';
import { TextField } from '@mui/material';

const InputComponent = (props) => {
  const { name, handleChanges, validationErrors, ...otherProps } = props;

  return (
    <TextField
      {...otherProps}
      className="mt-10 mb-10"
      margin="dense"
      onChange={handleChanges}
      name={name}
      error={!!validationErrors[name]}
      helperText={validationErrors[name]}
    />
  );
};

InputComponent.propTypes = {
  name: string.isRequired,
  validationErrors: object,
  handleChanges: func,
};

InputComponent.defaultProps = {
  handleChanges: () => {},
  validationErrors: {},
};

export default InputComponent;
