import { normalizeCurrency, pointCurrencyAsEnum, multiplier } from 'Models/PointCurrency';

export function normalizePoints(point) {
  const { amount, currency } = point;
  const pointCurrency = pointCurrencyAsEnum(currency);
  const norm = normalizeCurrency(pointCurrency);

  if (pointCurrency === norm) {
    return point;
  }

  return {
    currency: norm,
    amount: amount * multiplier(currency),
  };
}

export function isZero(point) {
  const { amount } = point;

  return amount === 0;
}

export function negate(point) {
  const { amount, currency } = point;

  return isZero(point) ? point : { amount: -amount, currency };
}

export function pointToString({ amount, currency }) {
  return `${amount} ${currency}`;
}
