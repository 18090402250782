import { ADDRESS_NAMES } from 'Modules/onboarding/utils/constants';
import * as FIELDS from 'Constants/fields';

export const CREATE_GROUP_CONSTRAINTS = {
  addGroup: {
    presence: true,
    length: {
      maximum: 40,
    },
  },
  editGroup: {
    presence: true,
    length: {
      maximum: 160,
    },
  },
};

export const LANDING_ADDRESS_CONSTRAINTS = {
  [ADDRESS_NAMES.firstAddress]: {
    presence: {
      message: 'address 1',
    },
  },
  [ADDRESS_NAMES.secondAddress]: {
    presence: {
      message: 'address 2',
    },
  },
  [ADDRESS_NAMES.country]: {
    presence: {
      message: 'country',
    },
  },
  [ADDRESS_NAMES.state]: {
    presence: {
      message: 'state',
    },
  },
  [ADDRESS_NAMES.city]: {
    presence: {
      message: 'city',
    },
  },
  [ADDRESS_NAMES.zipCode]: {
    presence: {
      message: 'zip',
    },
  },
};

export const LANDING_PAYMENT_CONSTRAINTS = {
  cardNumber: {
    presence: {
      message: 'Card number is required',
    },
  },
  cardExpiry: {
    presence: {
      message: 'Expiration date is required',
    },
  },
  cardCvc: {
    presence: {
      message: 'CVC is required',
    },
  },
};

export const CREATE_BADGE_CONSTRAINTS = {
  name: {
    presence: true,
  },
  description: {
    presence: true,
  },
};

export const CREATE_NOTIFICATION_CONSTRAINTS = {
  message: {
    presence: true,
  },
  days_unengaged: {
    numericality: true,
  },
};

export const CREATE_PROMOTION_CONSTRAINTS = {
  description: {
    presence: true,
  },
  type: {
    presence: true,
  },
};

export const COUPON_CODE_CONSTRAINTS = {
  discount: {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100,
    },
  },
};

export const CREATE_COMMENT_CONSTRAINTS = {
  commentText: {
    presence: true,
  },
};

export const PRODUCT_CONSTRAINTS = {
  [FIELDS.productName.name]: { presence: true },
  [FIELDS.merchantSKU.name]: { presence: true },
  [FIELDS.mainMsrpField.name]: { numericality: true },
  [FIELDS.productDescriptionField.name]: { presence: true },
  [FIELDS.mainPriceField.name]: { numericality: true },
  [FIELDS.alt1PriceField.name]: { numericality: true },
  [FIELDS.alt2PriceField.name]: { numericality: true },
  [FIELDS.alt1MsrpField.name]: { numericality: true },
  [[FIELDS.alt2MsrpField.name]]: { numericality: true },
};
