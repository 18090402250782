import { useEffect } from 'react';
import { connect } from 'react-redux';
import pick from 'lodash/pick';
import { object, func } from 'prop-types';
import { Grid } from '@mui/material';

//  Components
import BudgetContainer from 'Modules/posts/add/containers/BudgetContainer';
import BenefitsContainer from 'Modules/posts/add/containers/BenefitsContainer';
import FeeContainer from 'Modules/posts/add/containers/FeeContainer';
import GrossPayContainer from 'Modules/posts/add/containers/GrossPayContainer';
import CountryContainer from 'Modules/posts/add/containers/CountryContainer';
import WorkingHours from 'Modules/posts/add/components/WorkingHours';
import BonusContainer from 'Modules/posts/add/containers/BonusContainer';
import InputComponent from 'Common/components/InputComponent';
import DropdownComponent from 'Modules/posts/add/components/DropdownComponent';

//  Actions/Selectors
import { settingsSelector } from 'Store/selectors/settings';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import { updateDataSyncAction } from 'Store/actions/genericActions';
import * as modalSelectors from 'Modules/modal/store/selectors';

//  Other resources
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA } from 'Store/reducerProperties';
import { getDisableMode } from 'Modules/posts/add/utils/addBountyHelpers';
import { workExperienceField, applicantRegionField } from 'Constants/fields';
import { REGION_LEVELS } from 'Models/User';
import IntlMessage, { formatMessage } from 'Util/IntlMessages';

const JobDetailsInner = (props) => {
  const { companySettings: { fees }, selectedBounty, inputsData } = props;

  useEffect(() => {
    if (selectedBounty?.jobInfo) {
      const existingProps = pick(
        selectedBounty.jobInfo,
        ['minAge', 'maxAge', 'experienceYears', 'applicantsSourceRegion'],
      );

      if (!selectedBounty?.jobInfo?.applicantsSourceRegion) {
        existingProps.applicantsSourceRegion = REGION_LEVELS.GLOBAL;
      }

      props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, existingProps);
    }
  }, [selectedBounty?.jobInfo]);

  const handleChanges = ({ target: { name, value } }) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: value });
  };

  const handleDropdownChanges = (name, value) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: value });
  };

  return (
    <div>
      <CountryContainer />
      {!getDisableMode(fees)
        ? renderWithBudgetEmployer()
        : renderWithNetEmployer()}
      <WorkingHours />
      <BenefitsContainer />
      <BonusContainer />
      <Grid container spacing={2}>
        <Grid item xs={8} sm={4}>
          <DropdownComponent
            handleChanges={handleDropdownChanges}
            value={inputsData[applicantRegionField.name]}
            config={applicantRegionField}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={4}>
          <DropdownComponent
            handleChanges={handleDropdownChanges}
            value={inputsData[workExperienceField.name]}
            config={workExperienceField}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputComponent
            fullWidth
            name="minAge"
            label={<IntlMessage id="label.minAge" />}
            value={inputsData.minAge || ''}
            handleChanges={handleChanges}
          />
        </Grid>
        <Grid item xs={4}>
          <InputComponent
            fullWidth
            name="maxAge"
            label={<IntlMessage id="label.maxAge" />}
            value={inputsData.maxAge || ''}
            handleChanges={handleChanges}
          />
        </Grid>
      </Grid>
    </div>
  );
};

JobDetailsInner.propTypes = {
  companySettings: object.isRequired,
  selectedBounty: object.isRequired,
  inputsData: object.isRequired,
  updateDataSyncAction: func.isRequired,
};

/**
* The function returns a block of components, provided that there is no tax rate
* or it is equal {ON_BUDGET_PAY}
*/
const renderWithBudgetEmployer = () => (
  <>
    <BudgetContainer />
    <FeeContainer />
    <GrossPayContainer
      label={formatMessage({ id: 'label.grossPayFee' })}
    />
  </>
);

/**
 * The function returns a block of components provided
* that the tax type is ON_GROSS_PAY or ON_NET_PAY
*/
const renderWithNetEmployer = () => (
  <>
    <GrossPayContainer
      label={formatMessage({ id: 'label.grossPay' })}
    />
    <FeeContainer />
  </>
);

const mapStateToProps = (state) => ({
  companySettings: settingsSelector(state).companySettings || {},
  selectedBounty: selectedBountySelector(state) || {},
  inputsData: modalSelectors.inputsData(state) || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

export const JobDetails = connect(mapStateToProps, mapDispatchToProps)(JobDetailsInner);
