import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';

const FilterContext = createContext({
  filters: {},
  searchTerms: {},
});

export const FiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState({});
  const [searchTerms, setSearchTerms] = useState({
    searchCriteria: '',
  });

  const store = useMemo(() => ({
    filters,
    setFilters,
    searchTerms,
    setSearchTerms,
    resetFilters: () => {
      setFilters({});
      setSearchTerms({});
    },
  }), [filters, setFilters, searchTerms, setSearchTerms]);

  return (
    <FilterContext.Provider value={store}>
      {children}
    </FilterContext.Provider>
  );
};

FiltersProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default FilterContext;
