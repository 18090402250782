import { useCallback, useEffect, useState } from 'react';
import { func, object, number } from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';

//  Components
import CreateBountyContainer from 'Modules/posts/add/containers/CreateBountyContainer';
import ModalButtons from 'Modules/modal/components/ModalButtons';
import LightLoadingComponent from 'Common/components/LightLoadingComponent';

//  Actions/Selectors
import { handleCreateBounty, handleEditBounty } from 'Modules/posts/common/store/operations';
import { clearDataSyncAction, setDataAction } from 'Store/actions/genericActions';
import { updateLocalBounty } from 'Services/bounty/BountyService';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import { closeDrawer } from 'Modules/modal/store/operations';
import { drawerSettingsSelector, validationErrorsSelector } from 'Modules/modal/store/selectors';
import { bountyParentSelector, bountyRefreshCountSelector } from 'Modules/posts/common/store/selectors';

//  Other resources
import { USER_SENTS_ROUTE, V2_JOBS_ROUTE } from 'Constants/routes';
import { MODAL_NAMESPACE, SENTS_NAMESPACE, USER_STREAMS_NAMESPACE } from 'Store/namespaces';
import { DRAWER_SETTINGS, SELECTED_BOUNTY, REFRESH_BOUNTY_DETAILS } from 'Store/reducerProperties';
import { JOB, THREAD } from 'Constants/bounty/bountyType';
import { userDataSelector } from 'Store/selectors/settings';
import { firebaseGetCompanySents } from 'Services/FirebaseService';
import { JOBS_FOLDER } from 'Constants/bounty/companySentsFolder';
import { isContest } from 'Models/bounty/Bounty';
import { NotificationManager } from 'react-notifications';

const CreateBountyDrawer = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [configuration, setConfiguration] = useState(null);
  const [bountyType, setBountyType] = useState(null);
  const validationErrors = useSelector(validationErrorsSelector);

  const intl = useIntl();

  const {
    history, selectedBounty, settings, refreshCount, userData, parentBounty,
  } = props;
  const isEditMode = !!(selectedBounty && Object.keys(selectedBounty).length);
  const isInitializing = !!(isEditMode && !Object.keys(selectedBounty).length);
  const classes = useStyles();

  useEffect(() => {
    if (selectedBounty.type === JOB) {
      fetchBountyFB();
    }

    if (settings.optionConfig && Object.keys(settings.optionConfig).length) {
      setConfiguration(settings.optionConfig);
      setBountyType(selectedBounty.type || settings.bountyType);
      return;
    }

    props.clearDataSyncAction(SENTS_NAMESPACE, SELECTED_BOUNTY);
    props.clearDataSyncAction(MODAL_NAMESPACE, DRAWER_SETTINGS);
    props.closeDrawer();
  }, [settings, selectedBounty.id]);

  const fetchBountyFB = () => {
    const ownerId = userData.owner ? userData.owner.id : null;
    setIsFetching(true);

    firebaseGetCompanySents(ownerId, JOBS_FOLDER)
      .child(selectedBounty.id)
      .once('value', (dataSnapshot) => {
        setIsFetching(false);
        const fetchedBounty = dataSnapshot.val();

        if (fetchedBounty?.bounty) {
          props.setDataAction(SENTS_NAMESPACE, SELECTED_BOUNTY, fetchedBounty.bounty);
        }
      });
  };

  const onDismiss = () => props.closeDrawer();

  const onCreateBounty = () => {
    setIsSubmitting(true);

    const payload = {
      optionConfig: configuration,
      withValidation: true,
    };

    if (settings?.parentId) {
      payload.parentBounty = parentBounty.id === settings.parentId
        ? { id: parentBounty.id, type: parentBounty.type }
        : { id: settings.parentId };
    }

    props.handleCreateBounty(payload)
      .then(() => {
        props.setDataAction(USER_STREAMS_NAMESPACE, REFRESH_BOUNTY_DETAILS, refreshCount + 1);
        props.closeDrawer();

        if (!settings?.parentId) {
          goToSents();
        }
      })
      .catch(() => setIsSubmitting(false));
  };

  const notificateErrors = useCallback(() => {
    if (Object.keys(validationErrors).length) {
      NotificationManager.warning(
        intl.formatMessage({ id: 'invalid_form_data' }),
      );
    }
  }, [validationErrors]);

  const onEditBounty = () => {
    setIsSubmitting(true);

    props.handleEditBounty({ bounty: selectedBounty, withValidation: true })
      .then((editedBounty) => {
        props.setDataAction(USER_STREAMS_NAMESPACE, REFRESH_BOUNTY_DETAILS, refreshCount + 1);
        props.updateLocalBounty(editedBounty);
        props.closeDrawer();
      })
      .catch(() => {
        notificateErrors();
        setIsSubmitting(false);
      });
  };

  const goToSents = () => (history.push(bountyType === JOB ? V2_JOBS_ROUTE : USER_SENTS_ROUTE));

  const onSubmit = () => {
    if (isEditMode) {
      return onEditBounty();
    }

    onCreateBounty();
  };

  const getTitle = () => {
    const { isContestSubBounty, questionIndex } = configuration;

    if (!bountyType) {
      return;
    }

    if (isContestSubBounty || isContest(selectedBounty?.parentBounty?.type)) {
      if (isEditMode) {
        return intl.formatMessage({ id: 'labels.edit' });
      }

      if (questionIndex) {
        return intl.formatMessage({ id: 'bounty.createQuestion' }, { number: questionIndex });
      }

      if (configuration.bountyType === THREAD) {
        return intl.formatMessage({ id: 'bounty.createSuccessMessage' });
      }
    }

    const modeLabel = intl.formatMessage({ id: isEditMode ? 'labels.edit' : 'labels.create' });

    return configuration.name
      ? intl.formatMessage({ id: configuration.name }, { type: modeLabel })
      : `${modeLabel} ${bountyType}`;
  };

  return (
    <LightLoadingComponent isLoading={isFetching || isInitializing || isSubmitting}>
      {!isFetching && !isInitializing && configuration && (
        <Box pb={3} className={classes.root}>
          <h3>{getTitle()}</h3>
          <CreateBountyContainer config={configuration} />
          <ModalButtons
            onDismiss={onDismiss}
            onSubmit={onSubmit}
          />
        </Box>
      )}
    </LightLoadingComponent>
  );
};

CreateBountyDrawer.propTypes = {
  closeDrawer: func.isRequired,
  handleCreateBounty: func.isRequired,
  handleEditBounty: func.isRequired,
  clearDataSyncAction: func.isRequired,
  setDataAction: func.isRequired,
  settings: object.isRequired,
  selectedBounty: object.isRequired,
  history: object.isRequired,
  userData: object.isRequired,
  parentBounty: object.isRequired,
  refreshCount: number.isRequired,
  updateLocalBounty: func.isRequired,
};

const mapStateToProps = (state) => ({
  selectedBounty: selectedBountySelector(state) || {},
  settings: drawerSettingsSelector(state) || {},
  refreshCount: bountyRefreshCountSelector(state) || 0,
  userData: userDataSelector(state).data || {},
  parentBounty: bountyParentSelector(state) || {},
});

const mapDispatchToProps = {
  closeDrawer,
  handleCreateBounty,
  handleEditBounty,
  clearDataSyncAction,
  setDataAction,
  updateLocalBounty,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '500px',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100vw - 50px)',
    },
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateBountyDrawer));
