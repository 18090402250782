import { banfield } from 'Constants/common';

const banfieldConfig = {
  FLAVOR: 'banfield',
  id: banfield,
  title: 'C.A. Banfield',
  name: 'C.A. Banfield app',
  brandName: 'C.A. Banfield',
  appLinkSchema: 'banfield',
  appTld: 'clubabanfield.org',
  logo: {
    logoStyle: 'full-width',
    imgStyle: 'full-width-i',
    containerStyle: 'no-padding',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDcYXOoGPCuu738yzzME3MUGipNeaE1qSY',
    authDomain: 'banfield-prod.firebaseapp.com',
    databaseURL: 'https://banfield-prod-default-rtdb.firebaseio.com',
    projectId: 'banfield-prod',
    storageBucket: 'banfield-prod.appspot.com',
    messagingSenderId: '497037420245',
    appId: '1:497037420245:web:69450a1a8958b623f7645c',
    measurementId: 'G-E0KVEFC41P',
  },
  API_BASE_URL: 'https://api.youkno.ai/api/v1',
  API_REPORT: 'https://api.youkno.ai/download/report',
  login: {
    customCss: '',
    loginWithPin: true,
  },
  algolia_app_id: '',
  algolia_api_key: '',
  TERMS_OF_SERVICE_URL: 'https://belong.is/terms',
  PRIVACY_STATEMENT_URL: 'https://belong.is/privacy',
};

export default banfieldConfig;
