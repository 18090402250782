import intersection from 'lodash/intersection';
import * as IdentityMode from 'Models/IdentityMode';
import { ProductConfig } from 'Models/ProductConfig';
import { CTRL_IDENTITY } from 'Models/Op';
import { getAvailableBountyTypes, isAllowed as isOpAllowed } from 'Models/Settings';

/**
 * Check if a user is allowed to do a certain operation
 * @param op {String}
 * @param userData {Object}
 * @param companySettings {Object}
 * @returns {boolean}
 */
function isAllowed({ op, settings, userData }) {
  return isOpAllowed({ op, settings, userData });
}

function isBountyTypeAllowed({ bountyType, settings, userData }) {
  return getAvailableBountyTypes(settings, userData).includes(bountyType);
}

function getIdentityMode(settings, userData, bounty = null) { // eslint-disable-line
  const { companySettings } = settings || {};
  const threadMode = null; // FIXME: bounty != null ? bounty.getIdentityMode() : null;
  const modes = [threadMode, userData?.identityMode, companySettings?.identityMode, ProductConfig.identityMode];
  const itemMode = IdentityMode.mergeAll(modes);

  if (itemMode.forced) {
    if (isOpAllowed({ userData, op: CTRL_IDENTITY.name, settings })) {
      itemMode.forced = false;
    }
  }

  return itemMode;
}

function isAllowedByRole(roles, userRoles, defAllow) {
  if (!roles || !roles.length) {
    return defAllow;
  }
  const includedRoles = intersection(roles, userRoles);

  return includedRoles.length > 0;
}

export {
  isAllowed,
  isBountyTypeAllowed,
  getIdentityMode,
  isAllowedByRole,
};
