import { Input } from 'reactstrap';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Paper } from '@mui/material';
import { CountriesList } from 'Util/CountriesList';
import IntlMessages from '../../../util/IntlMessages';

/**
 * Find the index of an DOM element within a given container.
 */
export function findDomIndex(container, el) {
  return Array.prototype.indexOf.call(container.children, el);
}

/**
 * Move an array item from one index to another.
 * The given array is transformed, not returned.
 */
export function moveSelectedOption(array, fromIndex, toIndex) {
  array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
}

/**
 * Helper for autosuggest
 */

export function renderInput(inputProps) {
  const { ...other } = inputProps;

  const iconStyle = {
    top: '15px',
    right: '10px',
  };

  return (
    <div className="has-wrapper mb-20">
      <span className="has-icon material-icons" style={iconStyle}>
        <IntlMessages id="label.flag" />
      </span>
      <Input
        className="input-lg"
        {...other}
      />
    </div>
  );
}

export function renderSuggestion(suggestion, { query }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    parts.map((part, index) => (
      <span key={String(index)} style={{ fontWeight: part.highlight ? 500 : 300 }}>
        {part.text}
      </span>
    ))
  );
}

export function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

export function getSuggestionValue(suggestion) {
  return suggestion.label;
}

export function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : CountriesList.filter((suggestion) => {
      const keep = count < 5 && suggestion.label.toLowerCase().includes(inputValue);

      if (keep) {
        count += 1;
      }

      return keep;
    });
}
