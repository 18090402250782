import { stiinta } from 'Constants/common';

const stiintaConfig = {
  FLAVOR: 'stiinta',
  id: stiinta,
  title: 'Știința București',
  name: 'Știința București app',
  brandName: 'Știința București',
  appLinkSchema: 'stiinta',
  appTld: 'stiintabucuresti.ro',
  logo: {
    containerStyle: 'justify-content-center stiinta-logo-container',
    imgStyle: 'stiinta-logo',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyB6G8sV4yiVWmbcmxHX2nGL7JuAroKZFT8',
    authDomain: 'stiinta-prod.firebaseapp.com',
    databaseURL: 'https://stiinta-prod-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'stiinta-prod',
    storageBucket: 'stiinta-prod.appspot.com',
    messagingSenderId: '83991175673',
    appId: '1:83991175673:web:d64ad0fd705588d4eadd28',
    measurementId: 'G-9GXN76SQ6Z',
  },
  API_BASE_URL: 'https://api.youkno.ai/api/v1',
  API_REPORT: 'https://api.youkno.ai/download/report',
  login: {
    customCss: '',
    loginWithPin: true,
  },
  algolia_app_id: '',
  algolia_api_key: '',
  TERMS_OF_SERVICE_URL: 'https://stiintabucuresti.ro/termeni-si-conditii',
  PRIVACY_STATEMENT_URL: 'https://stiintabucuresti.ro/politica-de-confidentialitate',
};

export default stiintaConfig;
