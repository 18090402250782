import { getBucketingPolicyAsEnum, SearchBucketingPolicy, SEP } from 'Models/Search';

export function getIndexName(prefix, searchInfo, type) { // eslint-disable-line
  //  TODO check with Dimi
  // console.log('type', type); // eslint-disable-line
  const { bucketKey, bucketingPolicy } = searchInfo;

  switch (getBucketingPolicyAsEnum(bucketingPolicy)) {
    case SearchBucketingPolicy.NONE:
      return null;
    case SearchBucketingPolicy.COMMON:
      return `${prefix}${SEP}COMMON`;
    case SearchBucketingPolicy.BY_TYPE:
      return `${prefix}${SEP}`;
    case SearchBucketingPolicy.BY_COMPANY:
      return `${prefix}${SEP}${bucketKey}`;
    case SearchBucketingPolicy.BY_COMPANY_AND_TYPE:
      return `${prefix}${SEP}${bucketKey}${SEP}`;
    default:
      return false;
  }
}

export function getSearchIndex(searchInfo) {
  const prefix = 'PROD';
  return searchInfo != null ? getIndexName(prefix, searchInfo, null) : null;
}
