import { getApiData } from 'Services/Api';

function getMandatoryFilters(searchInfo, types = []) {
  const list = [];
  const searchBucketKey = `searchBucketKey: ${searchInfo && searchInfo.bucketKey ? searchInfo.bucketKey : 'xxx'}`;

  types.forEach((type) => list.push(`objectType: ${type}`));

  return `${searchBucketKey}${list.length ? ` AND ${list.join(' OR ')}` : ''}`;
}

export function getDefaultFilters(searchInfo) {
  return getMandatoryFilters(searchInfo);
}

export function listBadges(bounty) {
  const type = bounty && bounty.type ? bounty.type : null;
  return getApiData(`/badges?bountyType=${type}`);
}
