import { useEffect } from 'react';
import { instanceOf, func, string } from 'prop-types';
import { connect } from 'react-redux';

//  Component
import InputComponent from 'Common/components/InputComponent';

//  Actions/Selected
import * as modalSelectors from 'Modules/modal/store/selectors';
import { companySettingsSelector, userDataSelector } from 'Store/selectors/settings';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import { updateDataSyncAction } from 'Store/actions/genericActions';

//  Other resources
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA } from 'Store/reducerProperties';
import { streamUrlField, streamKeyField } from 'Constants/fields';

const StreamDetailsContainer = (props) => {
  const { streamUrl, streamKey, selectedBounty } = props;

  useEffect(() => {
    if (selectedBounty?.broadcastInfo) {
      props.updateDataSyncAction(
        MODAL_NAMESPACE,
        MODAL_INPUTS_DATA,
        {
          streamUrl: selectedBounty.broadcastInfo.streamUrl,
          streamKey: selectedBounty.broadcastInfo.streamKey,
        },
      );
    }
  }, [selectedBounty]);

  const handleChange = ({ target: { name, value } }) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: value });
  };

  return (
    <div>
      <InputComponent
        {...streamUrlField}
        value={streamUrl}
        handleChanges={handleChange}
      />
      <InputComponent
        {...streamKeyField}
        value={streamKey}
        handleChanges={handleChange}
      />
    </div>
  );
};

StreamDetailsContainer.propTypes = {
  streamUrl: string.isRequired,
  streamKey: string.isRequired,
  selectedBounty: instanceOf(Object).isRequired,
  updateDataSyncAction: func.isRequired,
};

const mapStateToProps = (state) => ({
  streamUrl: modalSelectors.inputsData(state).streamUrl || '',
  streamKey: modalSelectors.inputsData(state).streamKey || '',
  userData: userDataSelector(state).data || {},
  companySettings: companySettingsSelector(state).data || {},
  selectedBounty: selectedBountySelector(state) || {},
});

const dispatchToProps = {
  updateDataSyncAction,
};

export default connect(mapStateToProps, dispatchToProps)(StreamDetailsContainer);
