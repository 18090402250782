import { createSelector } from 'reselect';
import * as reducerProperties from 'Store/reducerProperties';
import { getAvailableBountyTypes, getMyCurrency, isPartOfCompany, findFirst } from 'Models/Settings';

export const userDataSelector = (state) => state.settingsReducer[reducerProperties.USER_DATA] || {};
export const companySettingsSelector = (state) => state.settingsReducer[reducerProperties.COMPANY_SETTINGS] || {};
export const systemSettingsSelector = (state) => state.settingsReducer[reducerProperties.SYSTEM_SETTINGS] || {};
export const orgTypeSettingsSelector = (state) => state.settingsReducer[reducerProperties.ORG_TYPE_SETTINGS] || {};

export const ownerSelector = createSelector(
  userDataSelector,
  ({ data }) => ((data && data.owner) || null),
);

export const companyGroupsSelector = createSelector(
  companySettingsSelector,
  ({ data }) => ((data && data.groups) || {}),
);

export const settingsSelector = createSelector(
  userDataSelector,
  companySettingsSelector,
  systemSettingsSelector,
  orgTypeSettingsSelector,
  (userData, companySettings, systemSettings, orgTypeSettings) => ({
    userSettings: (userData && userData.data && userData.data.settings) || null,
    companySettings: (companySettings && companySettings.data) || null,
    systemSettings: (systemSettings && systemSettings.data) || null,
    orgTypeSettings: (orgTypeSettings && orgTypeSettings.data) || null,
  }),
);

export const legalInfoSelector = createSelector(
  settingsSelector,
  (settings) => findFirst(settings, (s) => (s.sysInfo ? s.sysInfo.legal : null)),
);

export const isPartOfCompanySelector = createSelector(
  userDataSelector,
  ({ data }) => isPartOfCompany(data),
);

export const getMyCurrencySelector = createSelector(
  settingsSelector,
  userDataSelector,
  (settings, userData) => getMyCurrency(settings, userData.data),
);

export const isVoucherEnabledSelector = createSelector(
  settingsSelector,
  (settings) => {
    const voucherEnabled = findFirst(settings, (s) => s.voucherEnabled);
    return voucherEnabled == null || voucherEnabled;
  },
);

export const getSearchInfoSelector = createSelector(
  settingsSelector,
  (settings) => (findFirst(settings, (s) => s.searchInfo)),
);

export const availableBountyTypesSelector = createSelector(
  settingsSelector,
  userDataSelector,
  (settings, userData) => getAvailableBountyTypes(settings, userData.data),
);

export const isDrawerOpenSelector = (state) => (
  state.settingsReducer[reducerProperties.IS_DRAWER_OPEN]
);
