import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FilterContext from '../../contexts/filtersContext';

export const useFilters = () => {
  const [page, setPage] = useState(1);

  const location = useLocation();
  const history = useHistory();
  const {
    filters,
    searchTerms,
    setFilters,
    resetFilters,
    setSearchTerms,
  } = useContext(FilterContext);

  const handleSearchTyping = (e) => {
    const { name, value } = e.target;

    setSearchTerms({ ...searchTerms, [name]: value });
  };

  const handleFilterChanges = (name, value) => {
    setPage(1);
    history.push(`${location.pathname}?page=1`);

    setFilters({
      ...filters,
      ...(name ? { [name]: value } : {}),
      ...searchTerms,
    });
  };

  return {
    searchTerms,
    filters,
    page,
    setPage,
    resetFilters,
    handleSearchTyping,
    handleFilterChanges,
  };
};
