// Actions/Reducers
import { getHandlers } from 'Store/reducers/helpers';

//  Others
import { SETTINGS_NAMESPACE } from 'Store/namespaces';

// initial state
const INIT_STATE = {
  userData: null,
  companySettings: null,
  orgTypeSettings: null,
  systemSettings: null,
  systemInfo: null,
};

const handlersReducer = {
  ...getHandlers(SETTINGS_NAMESPACE),
};

export default function settingsReducer(state = INIT_STATE, action = {}) {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}
