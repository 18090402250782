import { node, object } from 'prop-types';
import { Configure, InstantSearch } from 'react-instantsearch/dom';
import algoliasearch from 'algoliasearch/lite';

//  Services
import { getDefaultFilters } from 'Services/SystemService';

//  Other resources
import { getSearchIndex } from 'Modules/algoliaSearch/utils';
import { AppConfig } from 'Util/AppUtils';

const searchClient = algoliasearch(
  AppConfig.algolia_app_id,
  AppConfig.algolia_api_key,
);

const SearchWrapper = ({ children, userData }) => {
  const { searchInfo } = userData;
  const indexName = getSearchIndex(searchInfo);
  const filters = getDefaultFilters(searchInfo);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
    >
      <Configure filters={filters} />
      {children}
    </InstantSearch>
  );
};

SearchWrapper.propTypes = {
  children: node.isRequired,
  userData: object.isRequired,
};

export default SearchWrapper;
