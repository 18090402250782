import { ATTACHMENTS_NAMESPACE } from 'Store/namespaces';
import { ATTACHMENTS, REMOVED_ATTACHMENTS } from 'Store/reducerProperties';
import { getHandlers } from 'Store/reducers/helpers';

const INIT_STATE = {
  [ATTACHMENTS]: [],
  [REMOVED_ATTACHMENTS]: [],
};

const handlersReducer = getHandlers(ATTACHMENTS_NAMESPACE);

export default function attachmentsReducer(state = INIT_STATE, action = {}) {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}
