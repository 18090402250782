import { isEqual } from 'lodash';

import { getEffectiveResponseVisibilityMode } from 'Models/bounty/Bounty';
import * as Reward from 'Models/Reward';
import * as OpManager from 'Models/OpManager';
import * as VisibilityMode from 'Models/VisibilityMode';
import { identityType } from 'Constants/bounty/bounty';
import { getExpiryDate, saveExtraData } from 'Modules/posts/add/utils/createPostActivity';
import { DRAFT_STATE } from 'Constants/bounty/bountyState';
import { addInteraction } from 'Models/Interactions';
import { OFFICIAL_RESPONSE } from 'Modules/posts/bounty/constants/interactions';
import { getChangedObj } from 'Modules/posts/bounty/utils/helpers';
import { getDaysHrsMinTimeUtilFormat } from 'Util/DateUtil';
import { buildAgentString } from 'Util/AppUtils';
import { LOTTERY } from 'Modules/posts/bounty/constants/activityTypes';

export function getVisibilityMode(responseVisibilityMode) {
  return responseVisibilityMode
    ? getEffectiveResponseVisibilityMode(responseVisibilityMode)
    : VisibilityMode.DEFAULT;
}

export function getVisibilityType(responseVisibilityMode, hasControlResponsesPrivacy) {
  const visibilityMode = getVisibilityMode(responseVisibilityMode);

  return hasControlResponsesPrivacy
    ? VisibilityMode.getVisibilityTypeAsEnum(visibilityMode) === VisibilityMode.PRIVATE_TYPE
    : null;
}

export function getIdentityMode({ settings, userData, anonymity }) {
  const identityMode = OpManager.getIdentityMode(settings, userData);

  return {
    forced: identityMode?.forced,
    identityType: anonymity ? identityType.ANON : identityType.REAL,
  };
}

export function getExpiryComponents(selectedBounty, activityType) {
  if (activityType === LOTTERY) {
    return selectedBounty?.contestInfo?.endedAt
      ? getDaysHrsMinTimeUtilFormat(new Date().getTime(), selectedBounty?.contestInfo?.endedAt)
      : { days: 1 };
  }

  if (selectedBounty?.expiresAt) {
    const time = getExpiryDate(activityType, selectedBounty).getTime();
    return getDaysHrsMinTimeUtilFormat(new Date().getTime(), time);
  }

  return null;
}

export function getDisableMode(fees) {
  return fees || fees?.JOBS?.commissionType === 'ON_BUDGET_PAY';
}

export function prepareBountyObjectForCreate(data) {
  const {
    inputsData,
    optionsData,
    surveyOptions,
    userData,
    optionConfig: { bountyType },
    uiElements = {},
    parentBounty = {},
    settings,
  } = data;
  const { officialResponse, visibilityType = null } = optionsData;
  const { anonymity } = inputsData;

  const extraData = saveExtraData({
    userData,
    settings,
    bountyType,
    savedData: {
      ...optionsData,
      ...inputsData,
      surveyOptions,
    },
    uiElements,
  });

  const bounty = {
    owner: userData.owner || null,
    identityMode: getIdentityMode({ settings, userData, anonymity }),
    state: DRAFT_STATE,
    type: bountyType,
    title: inputsData.title ? inputsData.title : null,
    description: inputsData.description ? { text: inputsData.description } : null,
    ...(parentBounty && parentBounty.id && { parentBounty }),
    ...extraData,
  };

  if (officialResponse) {
    bounty.interactions = addInteraction(OFFICIAL_RESPONSE);
  }

  if (visibilityType !== null) {
    const vt = visibilityType ? VisibilityMode.PRIVATE_TYPE : VisibilityMode.VISIBLE_TYPE;

    if (vt !== VisibilityMode.DEFAULT.visibilityType) {
      bounty.responseVisibilityMode = {
        forced: VisibilityMode.DEFAULT.forced,
        visibilityType: vt,
      };
    }
  }

  return bounty;
}

export function prepareBountyObjectForEdit(data) {
  const {
    bounty,
    userData,
    optionsData,
    surveyOptions,
    settings,
    inputsData,
  } = data;

  const { officialResponse, visibilityType = null } = optionsData;
  const { anonymity } = inputsData;
  const identityMode = OpManager.getIdentityMode(settings, userData);
  const extraData = saveExtraData({
    userData,
    settings,
    bountyType: bounty.type,
    savedData: {
      ...inputsData,
      ...optionsData,
      surveyOptions,
    },
  });
  const newBounty = {
    ...bounty,
    title: inputsData.title ? inputsData.title : null,
    description: inputsData.description ? { text: inputsData.description } : null,
    identityMode: {
      forced: identityMode.forced,
      identityType: anonymity ? identityType.ANON : identityType.REAL,
    },
    ...extraData,
  };

  if (officialResponse) {
    newBounty.interactions = addInteraction(OFFICIAL_RESPONSE);
  }

  if (visibilityType !== null) {
    const vt = visibilityType ? VisibilityMode.PRIVATE_TYPE : VisibilityMode.VISIBLE_TYPE;

    if (vt !== VisibilityMode.DEFAULT.visibilityType) {
      newBounty.responseVisibilityMode = {
        forced: VisibilityMode.DEFAULT.forced,
        visibilityType: vt,
      };
    }
  }
  return newBounty;
}

function removeObjNullProps(data = {}) {
  const copy = { ...data };

  Object.keys(copy).forEach((key) => {
    if (copy[key] === null) {
      delete copy[key];
    }
  });

  return copy;
}

export function getBountyUpdates(origBounty, newBounty) {
  const updates = {};
  const changes = {};

  newBounty.agentInfo = { editedOn: buildAgentString() }; // eslint-disable-line

  if (origBounty.name !== newBounty.name) {
    updates.name = newBounty.name;
    changes.name = getChangedObj(origBounty.name, newBounty.name);
  }

  if (!isEqual(origBounty.description, newBounty.description)) {
    updates.description = newBounty.description;
    changes.description = getChangedObj(origBounty.description, newBounty.description);
  }

  if (!isEqual(origBounty.title, newBounty.title)) {
    updates.title = newBounty.title;
    changes.title = getChangedObj(origBounty.title, newBounty.title);
  }

  if (!isEqual(origBounty.targetUrl, newBounty.targetUrl)) {
    updates.targetUrl = newBounty.targetUrl;
    changes.targetUrl = getChangedObj(origBounty.targetUrl, newBounty.targetUrl);
  }

  if (!isEqual(origBounty.broadcastInfo, newBounty.broadcastInfo)) {
    updates.broadcastInfo = newBounty.broadcastInfo;
    changes.broadcastInfo = getChangedObj(origBounty.broadcastInfo, newBounty.broadcastInfo);
  }

  if (!isEqual(origBounty.reward, removeObjNullProps(newBounty.reward))) {
    updates.reward = newBounty.reward ? Reward.toMap(newBounty.reward) : null;
    changes.reward = getChangedObj(origBounty.reward, Reward.toMap(newBounty.reward));
  }

  if (!isEqual(origBounty.distribution, newBounty.distribution)) {
    updates.distribution = newBounty.distribution;
    changes.distribution = getChangedObj(origBounty.distribution, newBounty.distribution);
    updates.to = newBounty.to;
  }

  if (!isEqual(origBounty.attachments, newBounty.attachments)) {
    updates.attachments = newBounty.attachments;
    changes.attachments = getChangedObj(origBounty.attachments, newBounty.attachments);
  }

  if (origBounty.expiresAt !== newBounty.expiresAt) {
    updates.expiresAt = newBounty.expiresAt;
    changes.expiresAt = getChangedObj(origBounty.expiresAt, newBounty.expiresAt);
  }

  if (origBounty.terms !== newBounty.terms) {
    updates.terms = newBounty.terms;
    changes.terms = getChangedObj(origBounty.terms, newBounty.terms);
  }

  if ((origBounty.listId || newBounty.listId) && origBounty.listId !== newBounty.listId) {
    updates.listId = newBounty.listId;
    changes.listId = getChangedObj(origBounty.listId, newBounty.listId);
  }

  if (origBounty.listCode && newBounty.listCode && origBounty.listCode !== newBounty.listCode) {
    updates.listCode = newBounty.listCode;
    changes.listCode = getChangedObj(origBounty.listCode, newBounty.listCode);
  }

  if (origBounty.summary !== newBounty.summary) {
    updates.summary = newBounty.summary;
    changes.summary = getChangedObj(origBounty.summary, newBounty.summary);
  }

  if (origBounty.identityMode && newBounty.identityMode
    && (origBounty.identityMode.forced !== newBounty.identityMode.forced
      || origBounty.identityMode.identityType !== newBounty.identityMode.identityType)) {
    updates.identityMode = newBounty.identityMode;
    changes.identityMode = getChangedObj(origBounty.identityMode, newBounty.identityMode);
  }

  if (origBounty.visibilityMode !== newBounty.visibilityMode) {
    updates.visibilityMode = newBounty.visibilityMode;
    changes.visibilityMode = getChangedObj(origBounty.visibilityMode, newBounty.visibilityMode);
  }

  if (origBounty.postAt !== newBounty.postAt) {
    updates.postAt = newBounty.postAt;
    changes.postAt = getChangedObj(origBounty.postAt, newBounty.postAt);
  }

  if (!isEqual(origBounty.product, newBounty.product)) {
    updates.product = newBounty.product;
    changes.product = getChangedObj(origBounty.product, newBounty.product);
  }

  if (!isEqual(origBounty.jobInfo, newBounty.jobInfo)) {
    updates.jobInfo = newBounty.jobInfo;
    changes.jobInfo = getChangedObj(origBounty.jobInfo, newBounty.jobInfo);
  }

  if (!isEqual(origBounty.onBehalfOf, newBounty.onBehalfOf)) {
    updates.onBehalfOf = newBounty.onBehalfOf;
    changes.onBehalfOf = getChangedObj(origBounty.onBehalfOf, newBounty.onBehalfOf);
  }

  if (!isEqual(origBounty.contestInfo, newBounty.contestInfo)) {
    updates.contestInfo = newBounty.contestInfo;
    changes.contestInfo = getChangedObj(origBounty.contestInfo, newBounty.contestInfo);
  }

  if (!isEqual(origBounty.timeLimit, newBounty.timeLimit)) {
    updates.timeLimit = newBounty.timeLimit;
    changes.timeLimit = getChangedObj(origBounty.timeLimit, newBounty.timeLimit);
  }

  updates['agentInfo/editedOn'] = newBounty.agentInfo.editedOn;

  return { updates, changes };
}

export function getPostAnonymity({ selectedBounty, settings, userData }) {
  if (selectedBounty && selectedBounty.identityMode) {
    return selectedBounty.identityMode.identityType !== identityType.REAL;
  }

  const identityMode = OpManager.getIdentityMode(settings, userData);

  return identityMode.identityType !== identityType.REAL;
}
