import { cloneDeep } from 'lodash';

import { DEFAULT } from 'Modules/posts/bounty/constants/allowMode';
import { USER_TYPE, VISIBLE_TYPE } from 'Models/VisibilityMode';
import * as Currency from './Currency';
import * as CurrencyUtils from '../util/currencyUtils';
import { getAllowModeAsEnum, getRoleDefByName, mergeCapInfo, getExplicitlyAllowed, isForbidden, mergeListInfo } from './BaseSettings';
import * as ProductFlavour from './ProductFlavour';
import * as ProductDefaults from './ProductDefaults';
import { ALL } from './Op';
import * as RoleDef from './RoleDef';
import * as BountyTypes from '../constants/bounty/bountyType';
import * as OrgTypes from '../constants/company/companyType';
import { getBountyTypeAsEnum } from './bounty/Bounty';
import { getOrgTypeAsEnum } from './organization/Organization';

import { isGranted } from './Grant';

const productFlavour = ProductFlavour.getCurrent();
const productDefaults = ProductDefaults.getDefaults(productFlavour);

export function getSettingsPriorityOrder(settings) {
  const settingsPriorityOrder = [];

  if (settings?.userSettings) {
    settingsPriorityOrder.push(settings.userSettings);
  }

  if (settings?.companySettings) {
    settingsPriorityOrder.push(settings.companySettings);
  }

  if (settings?.orgTypeSettings) {
    settingsPriorityOrder.push(settings.orgTypeSettings);
  }

  if (settings?.systemSettings) {
    settingsPriorityOrder.push(settings.systemSettings);
  }

  return settingsPriorityOrder;
}

export function findFirst(settings, callback) {
  let foundValue = null;
  const settingsPriorityOrder = getSettingsPriorityOrder(settings);

  settingsPriorityOrder.find((s) => {
    foundValue = callback(s);
    return foundValue;
  });

  return foundValue !== null && foundValue !== undefined ? foundValue : null;
}

export function findAllByKey(settings, callback, key) {
  const settingsPriorityOrder = getSettingsPriorityOrder(settings);
  const list = [];

  settingsPriorityOrder.forEach((s) => {
    const result = callback(s);

    if (result && result[key]) {
      list.push(result[key]);
    }
  });

  return list;
}

export function mergeAll(settings, fetcher, merger, key) {
  const list = findAllByKey(settings, fetcher, key);

  if (!list || !list.length) {
    return null;
  }

  if (list.length === 1) {
    return list[0];
  }

  list.reverse();
  let finalValue = list[0];

  list.forEach((item) => {
    finalValue = merger(finalValue, item);
  });

  return finalValue;
}

export function mapAll(settings, fetcher, list = {}) {
  const settingsPriorityOrder = getSettingsPriorityOrder(settings);
  const listClone = cloneDeep(list);

  settingsPriorityOrder.forEach((s) => {
    const mapResult = fetcher(s);

    if (!mapResult) {
      return;
    }

    Object.keys(mapResult).forEach((key) => {
      if (!listClone[key]) {
        listClone[key] = mapResult[key];
      }
    });
  });

  return listClone;
}

export function forEachSettings(settings, callback) {
  const settingsPriorityOrder = getSettingsPriorityOrder(settings);
  settingsPriorityOrder.forEach((item) => {
    callback(item);
  });
}

// ------------------------------------------------------------------------ //
//     ********************  App-relevant methods ********************      //
//  ----------------------------------------------------------------------- //
export const getCapInfo = (settings, op) => (
  mergeAll(settings, (s) => (s.capInfos), mergeCapInfo, op)
);

/**
 * @param settings {Object}
 * @param role {String}
 * @returns {Object}
 */
function getRoleDef({ settings, role }) {
  let roleDef = findFirst(settings, (s) => getRoleDefByName(s.roles, role));

  if (!roleDef && productDefaults) {
    roleDef = ProductDefaults.getRoleDef(role);
  }

  return roleDef;
}

/**
 * Prepares an array with all the role names a user can take base on the 'roles' property from user data and if the
 * array is empty, the user will be assigned an array with the default role (user role)
 * @param userData {Object} which contains 'roles' property
 * @returns {Array} of role names (ex: [USER])
 */
function getMyRoles(userData) {
  const roles = RoleDef.parseRolesList(userData ? userData.roles : null);
  return roles.length ? roles : RoleDef.DEFAULT_USER_ROLES;
}

/**
 * Check if a user is allowed to do a certain operation
 * @param op {String}
 * @param userData {Object}
 * @param settings {Object} contains userSettings, companySettings, orgTypeSettings and systemSettings
 * @returns {boolean|null}
 */
export const isAllowed = ({ settings, userData, op }) => {
  const capInfo = getCapInfo(settings, op);
  const allowMode = capInfo ? getAllowModeAsEnum(capInfo.allowMode) : productDefaults.getAllowed(op);

  if (ProductDefaults.AllowMode.isSupreme(allowMode)) {
    return ProductDefaults.AllowMode.isAllowed(allowMode);
  }

  const myRoles = getMyRoles(userData);

  let isOpAllowed = null;
  let isOpForbidden = null;
  let rolesDef = myRoles.map((role) => getRoleDef({ settings, role }));
  rolesDef = rolesDef.filter((def) => !!def);

  rolesDef.find((role) => {
    const fn = role.getExplicitlyAllowed || getExplicitlyAllowed;
    const isExplicitlyAllowed = fn(op, role.ops);

    if (role && isExplicitlyAllowed !== null) {
      isOpAllowed = isExplicitlyAllowed;
      return isExplicitlyAllowed;
    }

    return false;
  });

  if (isOpAllowed !== null) {
    return isOpAllowed;
  }

  rolesDef.find((role) => {
    const fn = (role && role.isForbidden) || isForbidden;

    if (fn(op)) {
      isOpForbidden = true;
      return isOpForbidden;
    }

    return false;
  });

  if (isOpForbidden) {
    return false;
  }

  rolesDef.find((role) => {
    const fn = role.getExplicitlyAllowed || getExplicitlyAllowed;
    const isAllExplicitAllowed = fn(ALL) || null;

    if (role && isAllExplicitAllowed !== null) {
      isOpAllowed = isAllExplicitAllowed;
      return isAllExplicitAllowed;
    }

    return false;
  });

  if (isOpAllowed !== null) {
    return isOpAllowed;
  }

  if (allowMode !== DEFAULT) {
    return ProductDefaults.AllowMode.isAllowed(allowMode);
  }

  return false;
};

/**
 * @param settings {Object}
 * @param roleName {String}
 * @param roleDef {Object}
 * @returns {Object}
 */
export function getBountyTypes(settings, roleName, roleDef) {
  if (roleDef === null || roleDef === undefined) {
    return {};
  }

  const result = mapAll(settings, (s) => {
    if (!s.bountyTypes) {
      return null;
    }

    const map = {};

    Object.keys(s.bountyTypes).forEach((type) => {
      const value = s.bountyTypes[type];
      const bt = getBountyTypeAsEnum(type);
      let allowed = value.enabled && roleDef.isAllowedUnrestrictedAccess && roleDef.isAllowedUnrestrictedAccess(bt);

      if (!allowed) {
        allowed = isGranted(value.grant, [roleName]);
      }

      if (![BountyTypes.NONE, BountyTypes.UNKNOWN].includes(bt)) {
        map[bt] = allowed;
      }
    });

    return map;
  });

  if (productDefaults.bountyTypes) {
    productDefaults.bountyTypes.forEach((type) => {
      if (!result[type]) {
        result[type] = roleDef.isAllowedUnrestrictedAccess && roleDef.isAllowedUnrestrictedAccess(type);
      }
    });
  }

  return result;
}

/**
 * Get available bounty types base on the user configuration
 * @param settings {object}
 * @param userData {object}
 * @returns {Array}
 */
export function getAvailableBountyTypes(settings, userData) {
  const map = {};
  const myRoles = getMyRoles(userData);

  myRoles.forEach((role) => {
    const bountyTypes = getBountyTypes(settings, role, getRoleDef({ settings, role }));
    Object.keys(bountyTypes).forEach((type) => {
      map[type] = bountyTypes[type] || false;
    });
  });

  const types = [];

  Object.keys(map).forEach((key) => {
    if (map[key]) {
      types.push(key);
    }
  });

  return types;
}

export function isSocialPostEnabled(bountyType) {
  switch (bountyType) {
    case BountyTypes.TALENT_SEARCH:
    case BountyTypes.REALESTATE_CUSTOMER:
      return true;
    default:
      return false;
  }
}

export function hasAccessToBountyType(bountyType, settings, userData) {
  return bountyType != null && getAvailableBountyTypes(settings, userData).includes(bountyType);
}

export function isPartOfCompany(userData) {
  return !!(userData && userData.company);
}

export function getMyCurrency(settings, userData) {
  const currency = findFirst(settings, (s) => s.currency);

  if (Currency.isValid(currency)) {
    return currency;
  }

  if (!isPartOfCompany(userData)) {
    // for individuals, default a currency based on country
    if (userData != null && userData.countryCode != null) {
      return CurrencyUtils.getCurrencyByCountry(userData.countryCode);
    }
  }

  return Currency.currencyTypes.USD;
}

export function getOrganizationType(settings) {
  return findFirst(settings, (s) => s.organizationType);
}

export function getOrgTypes(settings, roleName, roleDef) {
  if (roleDef === null || roleDef === undefined) {
    return {};
  }

  return mapAll(settings, (s) => {
    if (!s.allowedOrgTypes) {
      return null;
    }

    const map = {};

    Object.keys(s.allowedOrgTypes).forEach((type) => {
      const value = s.allowedOrgTypes[type];
      const bt = getOrgTypeAsEnum(type);
      let allowed = value.enabled;

      if (!allowed) {
        allowed = isGranted(value.grant, [roleName]);
      }

      if (![OrgTypes.NONE, OrgTypes.UNKNOWN].includes(bt)) {
        map[bt] = allowed;
      }
    });

    return map;
  });
}

function getListInfo(settings, listId) {
  return mergeAll(settings, (s) => (s.listInfos), mergeListInfo, listId);
}

export function getAvailableOrganizationTypes(settings, userData) {
  const map = {};
  const myRoles = getMyRoles(userData);

  myRoles.forEach((role) => {
    const orgTypes = getOrgTypes(settings, role, getRoleDef({ settings, role }));

    Object.keys(orgTypes).forEach((type) => {
      map[type] = orgTypes[type] || false;
    });
  });

  const types = [];

  Object.keys(map).forEach((key) => {
    if (map[key]) {
      types.push(key);
    }
  });

  return types;
}

function getCustomListById(settings, listId) {
  if (!listId) {
    return null;
  }

  return findFirst(settings, (item) => {
    if (!item.customLists || !item.customLists.lists) {
      return null;
    }

    return item.customLists.lists[listId];
  });
}

function getEffectiveCustomListById(settings, listId) {
  const def = getCustomListById(settings, listId);

  if (def === null) {
    return null;
  }

  const listInfo = getListInfo(settings, listId);

  if (listInfo === null) {
    return def;
  }

  const defClone = JSON.parse(JSON.stringify(def));

  if (defClone.visibilityType === USER_TYPE) {
    if (defClone.visibilityInfo === null) {
      defClone.visibilityInfo = {};
    }

    defClone.visibilityInfo.visibilityType = VISIBLE_TYPE;
  }

  if (listInfo.rank !== null) {
    defClone.rank = listInfo.rank;
  }

  if (!listInfo.permissionType) {
    defClone.permissionType = listInfo.permissionType;
  }

  return defClone;
}

function getCustomLists(settings) {
  let customLists = {};

  forEachSettings(settings, (s) => {
    if (s.customLists) {
      customLists = { ...customLists, ...s.customLists.lists };
    }
  });

  return customLists;
}

export function getEffectiveCustomLists(settings) {
  const customLists = getCustomLists(settings);
  const set = {};

  Object.keys(customLists).forEach((keyDef) => {
    const listDef = getEffectiveCustomListById(settings, keyDef);

    if (listDef !== null) {
      set[keyDef] = listDef;
    }
  });

  return set;
}
