import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

//  Components
import DropdownComponent from 'Modules/posts/add/components/DropdownComponent';
import InputComponent from 'Common/components/InputComponent';

//  Actions/Selectors
import { updateDataSyncAction } from 'Store/actions/genericActions';
import * as modalSelectors from 'Modules/modal/store/selectors';
import { getMyCurrencySelector, settingsSelector } from 'Store/selectors/settings';

//  Other resources
import { getJobUnitsAsOptionsList } from 'Models/bounty/Bounty';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA, MODAL_OPTIONS_DATA, MODAL_VALIDATION_ERRORS } from 'Store/reducerProperties';
import { getDisableMode } from 'Modules/posts/add/utils/addBountyHelpers';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import useDebounce from 'Util/AppUtils';
import { useIntl } from 'react-intl';

let commissionPercentage = 0.15;

const BudgetContainer = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const currency = useSelector(getMyCurrencySelector) || 'USD';
  const { fees } = useSelector((state) => settingsSelector(state).companySettings || {});
  const optionsData = useSelector((state) => modalSelectors.optionsData(state) || {});
  const selectedBounty = useSelector(selectedBountySelector);
  const validationErrors = useSelector(modalSelectors.validationErrorsSelector);

  const [values, setValues] = useState({});

  const IS_ON_BUDGET_PAY = getDisableMode(fees);

  const payPeriodConfiguration = {
    text: 'bounty.paymentType',
    name: 'payPeriod',
    fullWidth: true,
    options: getJobUnitsAsOptionsList(),
  };

  const dispatchChanges = (changes) => dispatch(updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, changes));

  const dispatchErrors = (errors) => dispatch(updateDataSyncAction(MODAL_NAMESPACE, MODAL_VALIDATION_ERRORS, { ...validationErrors, ...errors }));

  const debouncedStoreUpdate = useDebounce(values, 500);

  useEffect(() => {
    if (debouncedStoreUpdate) {
      dispatchChanges(values);
    }
  }, [debouncedStoreUpdate]);

  useEffect(() => {
    let changes = { ...values, currency };

    if (IS_ON_BUDGET_PAY) {
      commissionPercentage = fees.JOBS.commissionPercentage;
    }

    if (selectedBounty.jobInfo) {
      // Fill form with data
      const { jobInfo } = selectedBounty;

      changes = {
        ...changes,
        payLowEnd: jobInfo.payLowEnd || null,
        payHighEnd: jobInfo.payHighEnd || null,
        payPeriod: jobInfo.payPeriod || null,
      };
    }

    setValues(changes);
  }, []);

  const handleChanges = ({ name, value }, propName, feeLabel, grossLabel) => {
    if (value < 0) return;

    setValues({
      ...values,
      [propName]: { money: { amount: value } },
      [feeLabel]: ({ money: { amount: value * commissionPercentage } }), // Set money pay
      [grossLabel]: ({ money: { amount: value - value * commissionPercentage } }), // Set reward pay
    });

    dispatchErrors({ [name]: null });
  };

  const handleDropdownChanges = (name, value) => dispatch(updateDataSyncAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, { [name]: value }));

  return (
    <div>
      <h4>
        {intl.formatMessage({ id: 'bounty.budget' })}
        {' '}
        (
        {currency}
        )
      </h4>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4}>
          <InputComponent
            required
            fullWidth
            type="number"
            label={intl.formatMessage({ id: 'bounty.formControls.labels.minPay' })}
            name="payLowEnd.money.amount"
            value={values.payLowEnd?.money?.amount || ''}
            handleChanges={(e) => handleChanges(e.target, 'payLowEnd', 'minFee', 'minGrossPay')}
            size="small"
            InputProps={{
              readOnly: IS_ON_BUDGET_PAY,
              inputProps: { min: 0 },
            }}
            variant={IS_ON_BUDGET_PAY ? 'filled' : 'standard'}
            validationErrors={validationErrors}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <InputComponent
            fullWidth
            type="number"
            label={intl.formatMessage({ id: 'bounty.formControls.labels.maxPay' })}
            name="payHighEnd.money.amount"
            value={values.payHighEnd?.money?.amount || ''}
            min="0"
            handleChanges={(e) => handleChanges(e.target, 'payHighEnd', 'maxFee', 'maxGrossPay')}
            size="small"
            InputProps={{
              readOnly: IS_ON_BUDGET_PAY,
              inputProps: { min: 0 },
            }}
            variant={IS_ON_BUDGET_PAY ? 'filled' : 'standard'}
            validationErrors={validationErrors}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <DropdownComponent
            handleChanges={handleDropdownChanges}
            value={optionsData[payPeriodConfiguration.name]}
            config={payPeriodConfiguration}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BudgetContainer;
