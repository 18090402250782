/* eslint-disable */
/**
 * Chat App Reducers
 */
import update from 'react-addons-update';

// actions types
import {
    CHAT_WITH_SELECTED_USER,
    SEND_MESSAGE_TO_USER,
    UPDATE_USERS_SEARCH,
    SEARCH_USERS,
    ADD_RECENT_CHAT_USERS
} from 'Store/actionTypes';

const INITIAL_STATE = {
    admin_photo_url: null,
    recentChatUsers: null,
    allRecentChatUsers: null,
    allChatUsers: null,
    selectedUser: null,
    searchUsers: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // get recent chat user
        case ADD_RECENT_CHAT_USERS:
            return { ...state,
                recentChatUsers: action.payload,
                allRecentChatUsers: action.payload,
                allChatUsers: action.payload };

        // chat with selected user
        case CHAT_WITH_SELECTED_USER:
            let indexOfSelectedUser;
            indexOfSelectedUser = state.recentChatUsers.indexOf(action.payload);
            return update(state, {
                selectedUser: { $set: action.payload },
                recentChatUsers: {
                    [indexOfSelectedUser]: {
                        isSelectedChat: { $set: true },
                        new_message_count: { $set: 0 }
                    }
                }
            });

        // send message to user
        case SEND_MESSAGE_TO_USER:
            let adminReplyData = {
                isAdmin: action.payload.isAdmin,
                message: action.payload.message,
                sent: action.payload.time
            };
            let pos = state.selectedUser.previousChats.length;
            return update(state, {
                selectedUser: { previousChats: { $splice: [[pos, 0, adminReplyData]] } }
            });

        // update search
        case UPDATE_USERS_SEARCH:
            return { ...state, searchUsers: action.payload };

        // search user
        case SEARCH_USERS:
            if (action.payload === '') {
                return { ...state, recentChatUsers: state.allChatUsers };
            } else {
                const searchUsers = state.allRecentChatUsers.filter((user) =>
                    user.user.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
                return { ...state, recentChatUsers: searchUsers }
            }

        default: return { ...state };
    }
}

/**
 * Selectors
 */

const anonUserDataForMsg = state => state.selectedUser ? state.selectedUser.myself : null;
const getEffectiveRealCollocutor = state => state.selectedUser && (state.selectedUser.realCollocutor ? state.selectedUser.realCollocutor : state.selectedUser.user);

const selectors = {
    anonUserDataForMsg,
    getEffectiveRealCollocutor
};

export { selectors };

