const palette = {
  primary: {
    main: '#0078f2',
  },
  secondary: {
    main: '#677179',
  },
  common: {
    darkRed: '#ec1313',
    redLabel: '#FF4C4C',
    orangeLabel: '#FFA800',
    greenLabel: '#05c043',
    blueLabel: '#1083CE',
    lightBlue: '#00c5ff',
    borderLine: '#ececec',
    lightBorderLine: '#EBEDF2',
    mainText: '#000000de',
    textMuted: '#727891',
  },
};

export default palette;
