import { getApplicationConfig } from 'Util/AppUtils';
import packageJson from '../../../package.json';

export function getUserAgent() {
  const { FLAVOR } = getApplicationConfig();

  return `${FLAVOR}/release/web/${packageJson.version}/0 ${navigator.userAgent}`;
}

export function getClientId() {
  return window.localStorage.getItem('pin') || null;
}

export function getHeaders(token, isUpload = false) {
  const agent = getUserAgent();
  const clientId = getClientId();

  const headers = {
    'X-edge-agent': agent,
  };

  if (!isUpload) {
    headers['Content-Type'] = 'application/json';
  }

  if (clientId) {
    headers['X-edge-client-id'] = clientId;
  }

  if (token) {
    return {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }

  return { ...headers };
}

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;

  throw error;
}

export function responseParser(response) {
  return response.json();
}

export function errorParser(err) {
  if (!err || !err.response) {
    return Promise.reject(new Error('Unknown error'));
  }

  return responseParser(err.response)
    .then((errorResponse) => {
      const error = { msg: errorResponse, status: err.response.status };
      return Promise.reject(error);
    });
}

function replaceRouteParams(route, params) {
  let formattedRoute = route;

  Object.keys(params).forEach((key) => {
    formattedRoute = formattedRoute.replace(`:${key}`, params[key]);
  });

  return formattedRoute;
}

function addQueryParams(route, queryParams) {
  const queryStrings = [];

  Object.keys(queryParams).forEach((key) => (
    queryStrings.push(`${key}=${queryParams[key]}`)
  ));

  return `${route}?${queryStrings.join('&')}`;
}

export function formatApiRoute({ endpoint, queryParams, params }) {
  let formattedRoute = endpoint;

  if (params) {
    formattedRoute = replaceRouteParams(formattedRoute, params);
  }

  if (queryParams && Object.keys(queryParams).length) {
    formattedRoute = addQueryParams(formattedRoute, queryParams);
  }
  return formattedRoute;
}
