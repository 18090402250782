import { useState, useEffect } from 'react';
import { array } from 'prop-types';
import { connectHits } from 'react-instantsearch-dom';
import { connectStateResults } from 'react-instantsearch/connectors';
import Scrollbars from 'react-custom-scrollbars';
import { Highlight } from 'react-instantsearch/dom';
import { Link } from 'react-router-dom';

//  Components
import AvatarComponent from 'Common/components/AvatarComponent';

//  Others
import { RESPONSE_DETAILS_ROUTE, PROFILE_ROUTE } from 'Constants/routes';
import { generateLink, generateLinkForBountyDetails } from 'Util/LinkUtils';
import { getDisplayAuthor } from 'Util/ViewUtils';
import { getScrollBarStyle } from 'Util/helpers';
import { RESPONSE, BOUNTY } from 'Models/CardType';
import IntlMessage from 'Util/IntlMessages';

export const Results = connectStateResults(({ searchState }) => (
  searchState && searchState.query
    ? (
      <Scrollbars
        className="rct-scroll"
        style={getScrollBarStyle()}
      >
        <CustomHits />
      </Scrollbars>
    )
    : null
));

const getPathByBountyType = (props) => {
  const {
    objectType, bountyId, objectID, bountyType,
  } = props;

  if (objectType === BOUNTY) {
    return generateLinkForBountyDetails({ id: objectID, type: bountyType });
  }

  if (objectType === RESPONSE) {
    const params = { bountyId, responseId: objectID };
    return generateLink(RESPONSE_DETAILS_ROUTE, params);
  }

  if (objectType === 'USER') {
    return `${PROFILE_ROUTE}/${objectID}`;
  }
  return '/';
};

const handleInputSearchChange = () => {
  const input = document.getElementsByClassName('ais-SearchBox-input')[0];
  const lastValue = input.value;
  input.value = '';
  const event = new Event('input', { bubbles: true });

  // hack React16
  const tracker = input._valueTracker;

  if (tracker) {
    tracker.setValue(lastValue);
  }

  input.dispatchEvent(event);
};

const Hits = ({ hits }) => {
  const [results, setResults] = useState([]);

  useEffect(() => {
    setResults(hits);
  }, [hits]);

  const handleUserClick = (authorToDisplay) => {
    if (!authorToDisplay.id) {
      return null;
    }

    setResults([]);
    handleInputSearchChange();
  };

  if (!results.length) {
    return (
      <div className="text-center">
        <p className="font-700 font-18"><IntlMessage id="emptyContent.searchTitle" /></p>
        <p><IntlMessage id="emptyContent.searchSubtitle" /></p>
      </div>
    );
  }

  return (
    <div className="mb-50">
      {
        results.map((hit) => {
          const getRedirectPath = getPathByBountyType(hit);
          const authorToDisplay = getDisplayAuthor(hit);

          return (
            <div key={hit.objectID}>
              <div className="post-content d-flex mt-15">
                <div
                  className="post-img mr-10"
                  role="presentation"
                  onClick={() => handleUserClick(authorToDisplay)}
                >
                  <AvatarComponent authorToDisplay={authorToDisplay} />
                </div>
                <div className="p-20">
                  <Link onClick={handleInputSearchChange} to={getRedirectPath} className="black-color">
                    <div className="post-info">
                      <Highlight hit={hit} attribute="text" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

Hits.propTypes = {
  hits: array.isRequired,
};

export const CustomHits = connectHits(Hits);
