import { bool, node } from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const propTypes = {
  isLoading: bool.isRequired,
  children: node.isRequired,
};

const LoadingComponent = ({ isLoading, children }) => {
  const renderChildren = children || <div />;

  return (
    isLoading
      ? (
        <div className="page-loader d-flex justify-content-center mb-30">
          <CircularProgress />
        </div>
      ) : renderChildren
  );
};

LoadingComponent.propTypes = propTypes;

export default LoadingComponent;
