import ApplicationsService from 'Services/ApplicationsService';
import { NotificationManager } from 'react-notifications';

export const ACTIONS = {
  LOADING: 'applicationsReducer/Loading',
  GET_LIST: 'applicationsReducer/GetList',
  ERASE_LIST: 'applicationsReducer/EraseList',
  UPDATE_TOTAL: 'applicationsReducer/UpdateTotal',
  UPDATE_FILTERS: 'applicationsReducer/UpdateFilters',
  UPDATE_REQ_PROPS: 'applicationsReducer/UpdateReqProps',
  UPDATE_SEARCH_FIELDS: 'applicationsReducer/UpdateSearchFields',
  RESET_FILTERS: 'applicationsReducer/ResetFilters',
};

const updateLoadingStatus = (bool) => (dispatch) => {
  dispatch({
    type: ACTIONS.LOADING,
    payload: bool,
  });
};

export const getApplications = (options) => (dispatch) => {
  updateLoadingStatus(true)(dispatch);

  ApplicationsService.getApplicants(options)
    .then((response) => {
      dispatch({ type: ACTIONS.GET_LIST, payload: response.list });
      dispatch({ type: ACTIONS.UPDATE_TOTAL, payload: response.totalCount });
    })
    .catch((err) => {
      NotificationManager.error(err.message);
    })
    .finally(() => {
      updateLoadingStatus(false)(dispatch);
    });
};

export const eraseApplications = () => (dispatch) => dispatch({ type: ACTIONS.ERASE_LIST });

export const getCompanyApplications = (options, companyId) => getApplications({ ...options, companyId });

const updateApplicationsReqProps = (reqProps) => (dispatch) =>
  dispatch({ type: ACTIONS.UPDATE_REQ_PROPS, payload: reqProps });

export const updateApplicationsOffset = (offset) => updateApplicationsReqProps({ start: offset });

export const updateApplicationsLimit = (limit) => updateApplicationsReqProps({ limit });

export const updateApplicationsOrder = (order) => updateApplicationsReqProps({ order, start: 0 });

export const updateApplicationsQuery = (query) => updateApplicationsReqProps({ query, start: 0 });

export const updateApplicationsFilters = (filters) => (dispatch) =>
  dispatch({
    type: ACTIONS.UPDATE_FILTERS,
    payload: { filters, reqProps: { start: 0 } },
  });

export const updateApplicationsSearchFields = (fields) => (dispatch) =>
  dispatch({ type: ACTIONS.UPDATE_SEARCH_FIELDS, payload: fields });

export const resetApplicationsFilters = () => (dispatch) =>
  dispatch({ type: ACTIONS.RESET_FILTERS });
