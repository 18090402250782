import CandidatesService from 'Services/CandidatesService';
import { NotificationManager } from 'react-notifications';

export const ACTIONS = {
  LOADING: 'candidatesReducer/Loading',
  GET_LIST: 'candidatesReducer/GetList',
  ERASE_LIST: 'candidatesReducer/EraseList',
  UPDATE_TOTAL: 'candidatesReducer/UpdateTotal',
  UPDATE_FILTERS: 'candidatesReducer/UpdateFilters',
  UPDATE_REQ_PROPS: 'candidatesReducer/UpdateReqProps',
  RESET_FILTERS: 'candidatesReducer/ResetFilters',
};

const updateLoadingStatus = (bool) => (dispatch) => {
  dispatch({
    type: ACTIONS.LOADING,
    payload: bool,
  });
};

export const getCandidates = (options) => (dispatch) => {
  updateLoadingStatus(true)(dispatch);

  CandidatesService.getCandidates(options)
    .then((response) => {
      dispatch({ type: ACTIONS.GET_LIST, payload: response.candidates });
      dispatch({ type: ACTIONS.UPDATE_TOTAL, payload: response.totalCount });
    })
    .catch((err) => {
      NotificationManager.error(err.message);
    })
    .finally(() => {
      updateLoadingStatus(false)(dispatch);
    });
};

export const eraseCandidates = () => (dispatch) => dispatch({ type: ACTIONS.ERASE_LIST });

const updateCandidatesReqProps = (reqProps) => (dispatch) =>
  dispatch({ type: ACTIONS.UPDATE_REQ_PROPS, payload: reqProps });

export const updateCandidatesOffset = (offset) => updateCandidatesReqProps({ start: offset });

export const updateCandidatesLimit = (limit) => updateCandidatesReqProps({ limit });

export const updateCandidatesOrder = (order) => updateCandidatesReqProps({ order, start: 0 });

export const updateCandidatesQuery = (query) => updateCandidatesReqProps({ query, start: 0 });

export const updateCandidatesFilters = (filters) => (dispatch) =>
  dispatch({
    type: ACTIONS.UPDATE_FILTERS,
    payload: { filters, reqProps: { start: 0 } },
  });

export const resetCandidatesFilters = () => (dispatch) =>
  dispatch({ type: ACTIONS.RESET_FILTERS });
