import set from 'lodash/set';
import {
  IS_LOADING,
  UPDATE_DATA_ASYNC,
  UPDATE_DATA_ASYNC_PUSH,
  UPDATE_DATA_SYNC,
  UPDATE_SIMPLE_DATA_SYNC,
  CLEAR_DATA_SYNC,
  DELETE_DATA_SYNC,
  UPDATE_LIST_SYNC,
  IS_SORTABLE,
  SET_DATA_SYNC,
  PUSH_DATA_SYNC,
} from 'Store/actionTypes';

/**
 * Function to use when you get data from firebase or API and you want to store data in redux
 */

export function updateDataAsyncReducer(state, payload, reducerProperty) {
  return {
    ...state,
    [reducerProperty]: {
      ...state[reducerProperty],
      data: payload,
    },
  };
}

export function updateDataAsyncReducerPush(state, payload, reducerProperty) {
  return {
    ...state,
    [reducerProperty]: {
      ...state[reducerProperty],
      data: [
        ...(state[reducerProperty].data || []),
        payload,
      ],
    },
  };
}

/**
 * Function to use when you want to put some sync data to redux store
 */

export function updateDataSyncReducer(state, payload, reducerProperty) {
  return {
    ...state,
    [reducerProperty]: {
      ...state[reducerProperty],
      ...payload,
    },
  };
}

export function updateListSyncReducer(state, payload, reducerProperty) {
  return {
    ...state,
    [reducerProperty]: [...payload],
  };
}

export function updateSimpleDataSyncReducer(state, payload, reducerProperty) {
  return {
    ...state,
    [reducerProperty]: payload,
  };
}

/**
 * Function to use when you want know when getting data from firebase or API is done
 */
export function isLoadingReducer(state, payload, reducerProperty) {
  return {
    ...state,
    [reducerProperty]: {
      ...state[reducerProperty],
      isLoading: payload,
    },
  };
}

export function clearDataSyncReducer(state, payload, reducerProperty) {
  return {
    ...state,
    [reducerProperty]: {},
  };
}

export function deleteDataSyncReducer(state, payload, reducerProperty) {
  const stateClone = JSON.parse(JSON.stringify(state));

  if (stateClone[reducerProperty]) {
    delete stateClone[reducerProperty][payload];
  }

  return stateClone;
}

export function isSortableReducer(state, payload, reducerProperty) {
  return {
    ...state,
    [reducerProperty]: {
      ...state[reducerProperty],
      isSortable: payload,
    },
  };
}

export function setDataReducer(state, payload, reducerProperty) {
  const newState = JSON.parse(JSON.stringify(state));
  set(newState, reducerProperty, payload);

  return newState;
}

export function pushDataReducer(state, payload, reducerProperty) {
  return {
    ...state,
    [reducerProperty]: [
      ...(state[reducerProperty] || []),
      payload,
    ],
  };
}

export const getHandlers = (namespace) => ({
  [`${namespace}/${UPDATE_DATA_ASYNC}`]: updateDataAsyncReducer,
  [`${namespace}/${UPDATE_DATA_ASYNC_PUSH}`]: updateDataAsyncReducerPush,
  [`${namespace}/${UPDATE_SIMPLE_DATA_SYNC}`]: updateSimpleDataSyncReducer,
  [`${namespace}/${UPDATE_DATA_SYNC}`]: updateDataSyncReducer,
  [`${namespace}/${UPDATE_LIST_SYNC}`]: updateListSyncReducer,
  [`${namespace}/${CLEAR_DATA_SYNC}`]: clearDataSyncReducer,
  [`${namespace}/${IS_LOADING}`]: isLoadingReducer,
  [`${namespace}/${IS_SORTABLE}`]: isSortableReducer,
  [`${namespace}/${DELETE_DATA_SYNC}`]: deleteDataSyncReducer,
  [`${namespace}/${DELETE_DATA_SYNC}`]: deleteDataSyncReducer,
  [`${namespace}/${SET_DATA_SYNC}`]: setDataReducer,
  [`${namespace}/${PUSH_DATA_SYNC}`]: pushDataReducer,
});
