import { validate } from 'Util/validation/validate';
import { CREATE_NOTIFICATION_CONSTRAINTS } from 'Util/validation/constraints';
import {
  GROUPS_PROFILE_ROUTE,
  LEADERBOARD_ROUTE,
  MY_ACCOUNT_ROUTE,
  STREAM_DETAILS_ROUTE,
  USER_PROFILE_ROUTE,
} from 'Constants/routes';
import { generateLink } from 'Util/LinkUtils';
import { formatMessage } from 'Util/IntlMessages';

//  TODO: include all the checks from android
export const getRouteByLink = (link) => {
  const [, hostAndPathname] = link.split('//') || [];
  const notificationUrl = `https://${hostAndPathname}`;
  const route = new URL(notificationUrl);
  const [segment1, segment2, segment3] = route?.pathname?.split('/')?.filter((part) => part) || [];

  if (segment1 === 'leaderboard') {
    return LEADERBOARD_ROUTE;
  }

  if (segment1 === 'account') {
    return MY_ACCOUNT_ROUTE;
  }

  if (segment1 === 'bounties') {
    if (segment2) {
      return generateLink(STREAM_DETAILS_ROUTE, { bountyId: segment2 });
    }
  }

  if (segment1 === 'profiles' && segment2) {
    if (segment2 === 'group') {
      return generateLink(GROUPS_PROFILE_ROUTE, { groupId: segment3 });
    }

    if (!['company', 'me'].includes(segment2)) {
      return generateLink(USER_PROFILE_ROUTE, { userId: segment2 });
    }
  }

  return '';
};

export const validateLinkParams = (appLink, linkParams = {}) => {
  const errors = {};

  if (appLink && appLink.additionalInfo) {
    appLink.additionalInfo.forEach((param) => {
      if (!linkParams[param.name]) {
        errors[param.name] = formatMessage({ id: 'validate.required_field' });
      }
    });
  }

  return errors;
};

export const validateNotificationForm = (data) => {
  const errors = validate(data, CREATE_NOTIFICATION_CONSTRAINTS);
  const linkErrors = validateLinkParams(data.app_link, data.linkParams);

  return {
    ...errors,
    ...linkErrors,
  };
};
