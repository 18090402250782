import { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object, string } from 'prop-types';
import { Grid } from '@mui/material';

//  Components
import DropdownComponent from 'Modules/posts/add/components/DropdownComponent';
import InputComponent from 'Common/components/InputComponent';

//  Actions/Selectors
import { updateDataSyncAction } from 'Store/actions/genericActions';
import * as modalSelectors from 'Modules/modal/store/selectors';
import { getMyCurrencySelector } from 'Store/selectors/settings';

//  Other resources
import { getJobUnitsAsOptionsList } from 'Models/bounty/Bounty';
import { BONUS_TYPE_AS_OPTIONS, PERIOD_TYPES } from 'Constants/bounty/jobUnit';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA, MODAL_OPTIONS_DATA } from 'Store/reducerProperties';
import IntlMessage from 'Util/IntlMessages';

import { selectedBountySelector } from '../../../companySents/store/selectors';

const BonusContainer = (props) => {
  const periodOptions = getJobUnitsAsOptionsList(PERIOD_TYPES);
  const {
    bonusPeriod, bonusType, selectedBounty, currency,
  } = props;
  const { bonus } = props;

  const bonusPeriodConfiguration = {
    text: 'bounty.bonusPeriod',
    name: 'bonusPeriod',
    fullWidth: true,
    options: periodOptions,
  };
  const bonusTypeConfiguration = {
    text: 'bounty.bonusType',
    name: 'bonusType',
    fullWidth: true,
    options: BONUS_TYPE_AS_OPTIONS,
  };

  useEffect(() => {
    if (selectedBounty.jobInfo) {
      populateBonus();
    }
  }, []);

  const populateBonus = () => {
    const { jobInfo } = selectedBounty;
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { bonus: jobInfo?.bonus || {} });
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, { bonusPeriod: jobInfo?.bonusPeriod || null });
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, { bonusType: jobInfo?.bonusType || null });
  };

  const handleChanges = ({ target: { name, value } }) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: { money: setBonusMoney(value) } });
  };

  const setBonusMoney = (value) => ({ amount: value });

  const handleDropdownChanges = (name, value) => {
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, { [name]: value });
  };

  return (
    <div className="mt-20">
      <h4>
        Bonus (
        {currency}
        )
      </h4>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <InputComponent
            fullWidth
            type="number"
            label={<IntlMessage id="label.bonusAmount" />}
            name="bonus"
            value={bonus.money?.amount || ''}
            handleChanges={handleChanges}
            size="small"
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <DropdownComponent
            handleChanges={handleDropdownChanges}
            value={bonusPeriod}
            config={bonusPeriodConfiguration}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <DropdownComponent
            handleChanges={handleDropdownChanges}
            value={bonusType}
            config={bonusTypeConfiguration}
          />
        </Grid>
      </Grid>
    </div>
  );
};

BonusContainer.propTypes = {
  bonus: object.isRequired,
  bonusPeriod: string.isRequired,
  bonusType: string.isRequired,
  currency: string.isRequired,
  selectedBounty: object.isRequired,
  updateDataSyncAction: func.isRequired,
};

const mapStateToProps = (state) => ({
  bonus: modalSelectors.inputsData(state).bonus || {},
  bonusPeriod: modalSelectors.optionsData(state).bonusPeriod || '',
  bonusType: modalSelectors.optionsData(state).bonusType || '',
  selectedBounty: selectedBountySelector(state) || {},
  currency: getMyCurrencySelector(state) || 'USD',
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BonusContainer);
