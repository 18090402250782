// extracted by mini-css-extract-plugin
export var wrapper = "sJ65JV";
export var sidebarHeader = "ZgBD23";
export var sidebar = "DVQVOh";
export var open = "FabhTA";
export var siteLogo = "D6tZCt";
export var imageLogo = "YZQFDP";
export var burgerIcon = "a8MATa";
export var menuWrapper = "Fd5CRK";
export var buildVersion = "iVymtS";
export var belongHeader = "lVspQb";
export var youknoHeader = "IbBcjW";
export var accordionHeader = "MxRf7P";
export var accordionLink = "wDd54j";
export var accordion = "_NG8fm";