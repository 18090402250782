import { any, bool, func, string } from 'prop-types';
import { FormLabel, TextField } from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';

import makeStyles from '@mui/styles/makeStyles';
import IntlMessage from 'Util/IntlMessages';

const propTypes = {
  placeholder: string,
  label: string,
  selectedDate: any,
  handleDateChange: func.isRequired,
  fullWidth: bool,
  format: string,
};

const defaultProps = {
  placeholder: <IntlMessage id="placeholder.choose.date" />,
  label: '',
  fullWidth: false,
  format: 'dd/MM/yyyy',
  selectedDate: null,
};

const DatePickers = ({ format, fullWidth, handleDateChange, label, placeholder, selectedDate, ...remainingProps }) => {
  const classes = useStyles();

  return (
    <div className="rct-picker mt-20">
      <FormControl fullWidth={fullWidth}>
        {label && <FormLabel>{label}</FormLabel>}
        <DatePicker
          {...remainingProps}
          fullWidth={fullWidth}
          inputFormat={format}
          showTodayButton
          value={selectedDate}
          onChange={handleDateChange}
          placeholder={placeholder}
          animateYearScrolling
          renderInput={(props) => <TextField {...props} className={classes.datePicker} />}
        />
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  datePicker: {
    '& .MuiOutlinedInput-root > fieldset > legend': {
      display: 'none',
    },
    '& .MuiOutlinedInput-input': {
      maxHeight: '15px',
      padding: '16px 14px',
    },
  },
}));

DatePickers.propTypes = propTypes;
DatePickers.defaultProps = defaultProps;

export default DatePickers;
