import { connect } from 'react-redux';
import { func, string } from 'prop-types';

// Components
import DropdownComponent from 'Modules/posts/add/components/DropdownComponent';

// Actions/Selectors
import { updateDataSyncAction } from 'Store/actions/genericActions';

// Other resources
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA } from 'Store/reducerProperties';
import {
  promotionOptions,
  DISCOUNT,
  SELECT_ONE,
  deliveryFee,
  priceObject,
} from 'Modules/promotions/constants/promotion';
import * as modalSelectors from 'Modules/modal/store/selectors';

const propTypes = {
  updateDataSyncAction: func.isRequired,
  selectedPromotionType: string.isRequired,
};

const PromotionTypeContainer = (props) => {
  const { selectedPromotionType } = props;
  const dropdownConfig = {
    name: 'type',
    text: 'bounty.type',
    options: promotionOptions,
  };

  const handleChanges = (name, value) => {
    const shouldClearCouponData = selectedPromotionType === DISCOUNT.value
      && value !== DISCOUNT.value;
    const shouldClearPriceData = selectedPromotionType === SELECT_ONE.value
      && value !== SELECT_ONE.value;

    if (shouldClearCouponData) {
      props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { couponCode: '', discount: '' });
    }

    if (shouldClearPriceData) {
      props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [deliveryFee.name]: null });
      props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [priceObject.name]: null });
    }

    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [name]: value });
  };

  return (
    <DropdownComponent
      handleChanges={handleChanges}
      value={selectedPromotionType}
      config={dropdownConfig}
    />
  );
};

const mapStateToProps = (state) => ({
  selectedPromotionType: modalSelectors.inputsData(state).type || '',
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

PromotionTypeContainer.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(PromotionTypeContainer);
