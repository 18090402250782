import { Redirect, Route, Switch } from 'react-router-dom';
import { object } from 'prop-types';
import { companySettingsSelector } from 'Store/selectors/settings';
import { useSelector } from 'react-redux';

//  Components
import Layout from 'Modules/layout/containers/Layout';

//  Others
import { HOME_ROUTE, NOT_FOUND, PRODUCTS_ROUTE, STREAM_ROUTE, V2_HOME_ROUTE } from 'Constants/routes';
import { stash } from 'Constants/common';
import { getApplicationConfig } from 'Util/AppUtils';
import { commonRoutesWithoutLayout, getDefaultRoutes } from '../configs/common';

const appConfig = getApplicationConfig();

const propTypes = {
  location: object.isRequired,
  match: object.isRequired,
  user: object.isRequired,
  permissions: object.isRequired,
};

const RoutesComponent = ({ location, permissions }) => {
  const currentOrgType = useSelector(companySettingsSelector)?.data?.organizationType || {};
  const isEmployer = currentOrgType === 'EMPLOYER';

  if (location.pathname === HOME_ROUTE && [stash].includes(appConfig.id)) {
    return <Redirect to={PRODUCTS_ROUTE} />;
  }

  if (location.pathname === HOME_ROUTE && isEmployer) {
    return <Redirect to={V2_HOME_ROUTE} />;
  }

  // if (location.pathname === APPLICANTS_ROUTE && isEmployer) {
  //   return <Redirect to={V2_APPLICATIONS_ROUTE} />;
  // }

  // if (location.pathname === JOBS_ROUTE && isEmployer) {
  //   return <Redirect to={V2_JOBS_ROUTE} />;
  // }
  //
  if (location.pathname === HOME_ROUTE && ![stash].includes(appConfig.id)) {
    return <Redirect to={STREAM_ROUTE} />;
  }

  const routesWithLayout = getDefaultRoutes(permissions);

  return (
    <Switch>
      {
        commonRoutesWithoutLayout.map(({
          path, component, exact, ...remainingProps
        }, index) => (
          <Route
            key={index}
            exact={exact}
            path={path}
            component={component}
            {...remainingProps}
          />
        ))
      }

      <Layout>
        <Switch>
          {
            routesWithLayout.map(({
              path, component, exact, ...remainingProps
            }, index) => (
              <Route
                key={index}
                exact={exact}
                path={path}
                component={component}
                {...remainingProps}
              />
            ))
          }
          <Route render={() => <Redirect to={NOT_FOUND} />} />
        </Switch>
      </Layout>
    </Switch>
  );
};

RoutesComponent.propTypes = propTypes;

export default RoutesComponent;
