export const NONE_STATE = 'NONE';
export const DRAFT_STATE = 'DRAFT';
export const APPROVAL_STATE = 'APPROVAL';
export const PENDING_STATE = 'PENDING';
export const ACTIVE_STATE = 'ACTIVE';
export const ACKED_STATE = 'ACKED';
export const PROGRESSING_STATE = 'PROGRESSING';
export const ACCEPTED_STATE = 'ACCEPTED';
export const REFUSED_STATE = 'REFUSED';
export const PAYING_STATE = 'PAYING';
export const PAID_STATE = 'PAID';
export const RETRACTED_STATE = 'RETRACTED';
export const REJECTED_STATE = 'REJECTED';
export const DELETED_STATE = 'DELETED';
export const UNKNOWN_STATE = 'UNKNOWN';
export const NEW_STATE = 'NEW';
export const DECLINED_STATE = 'DECLINED';
export const UNAVAILABLE_STATE = 'UNAVAILABLE';
