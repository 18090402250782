import { cloneDeep } from 'lodash';
import { getOutboundResponseRefs, SENT_ITEMS } from 'Services/response/CommonService';
import { firebaseGetCurrentUser } from 'Services/FirebaseService';
import { getOutboundPriorityForResponse } from 'Util/keyUtils';

function isCard(type) {
  return type === SENT_ITEMS;
}

export function getRefs(bounty, bountyResponse) {
  const mySelf = firebaseGetCurrentUser();

  if (!bountyResponse) {
    console.log('missing response'); // eslint-disable-line
  } else if (!bountyResponse.id) {
    console.log('missing response id'); // eslint-disable-line
  }

  return getOutboundResponseRefs(bounty, bountyResponse, mySelf);
}

export function saveResponse(bounty, bountyResponse) {
  const bountyClone = cloneDeep(bounty);

  if (!bountyResponse) {
    console.log('no response to save'); // eslint-disable-line
    return;
  }

  if (bountyClone && bountyClone.outboundResponses) {
    bountyClone.outboundResponses[bountyResponse.id] = bountyResponse;
  }

  const responseRefs = getRefs(bountyClone, bountyResponse);
  const priority = getOutboundPriorityForResponse(bountyResponse);

  Object.keys(responseRefs).forEach((refKey) => {
    if (isCard(refKey)) {
      const card = {
        response: bountyResponse,
      };
      const ref = responseRefs[refKey].parent;
      return ref.setWithPriority(card, priority);
    }
    return responseRefs[refKey].setWithPriority(bountyResponse, priority);
  });
}
