export const SORT_DIRECTION = {
  DESC: 'DESC',
  ASC: 'ASC',
};

export const APPLICATIONS_SORT_FIELDS = {
  APPLICATION_STATUS: 'APPLICATION_STATUS',
  APPLICANT_NAME: 'APPLICANT_NAME',
  APPLICANT_GENDER: 'APPLICANT_GENDER',
  APPLICATION_CREATED_AT: 'APPLICATION_CREATED_AT',
  APPLICANT_AGE: 'APPLICANT_AGE',
  COUNTRY_CODE: 'COUNTRY_CODE',
  CITIZENSHIP: 'CITIZENSHIP',
  POSITION: 'POSITION',
  CERTIFICATES: 'CERTIFICATES',
  LANGUAGES: 'LANGUAGES',
};

export const CANDIDATES_SORT_FIELDS = {
  CREATED_AT: 'CREATED_AT',
  NAME: 'NAME',
  GENDER: 'GENDER',
  AGE: 'AGE',
  COUNTRY: 'COUNTRY',
  CITIZENSHIP: 'CITIZENSHIP',
  SKILLSET: 'SKILLSET',
  CERTIFICATES: 'CERTIFICATES',
  LANGUAGES: 'LANGUAGES',
};

export const USER_MANAGEMENT_SORT_FIELDS = {
  NAME: 'NAME',
  ENGLISH_NAME: 'ENGLISH_NAME',
  AGE: 'AGE',
  SOURCE_COUNTRY: 'SOURCE_COUNTRY',
  CITIZENSHIP: 'CITIZENSHIP',
  CREATED_AT: 'CREATED_DATE ',
  SIGN_IN_DATE: 'SIGN_IN_DATE',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  REFERRAL_CODE: 'REFERRAL_CODE',
  PIN: 'PIN',
  PROVIDER: 'PROVIDER',
};

export const BOUNTIES_SORT_FIELDS = {
  MODIFIED_DATE: 'MODIFIED_DATE',
  TITLE: 'TITLE',
};
