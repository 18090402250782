import { useContext, useEffect, useState } from 'react';
import { LanguageContext } from 'Contexts/languagesContext';
import { getLocaleServer } from 'Services/locale/LocaleService';
import { getApiData } from 'Services/Api';
import { useDispatch, useSelector } from 'react-redux';
import { getIndustries } from 'Store/actions/industriesActions';
import { getSkills } from 'Store/actions/skillsActions';
import { industriesIsLoadedSelector } from 'Store/selectors/industriesSelectors';
import { skillsIsLoadedSelector } from 'Store/selectors/skillsSelectors';

const DataLocaleProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const { locale, setLocale } = useContext(LanguageContext);
  const isLoadedIndustries = useSelector(industriesIsLoadedSelector);
  const isLoadedSkills = useSelector(skillsIsLoadedSelector);
  const dispatch = useDispatch();

  const getUid = () => {
    getApiData('/user/info').then(({ userId }) => {
      setData(userId);
    });
  };

  useEffect(() => {
    getUid();
  }, []);

  useEffect(() => {
    data && getLocaleServer(data, setLocale);
  }, [data]);

  useEffect(() => {
    isLoadedIndustries && dispatch(getIndustries(true));
    isLoadedSkills && dispatch(getSkills(true));
  }, [locale]);

  return children;
};

export default DataLocaleProvider;
