import { SORT_DIRECTION } from 'Constants/sort';

export const toggleSortDirection = (direction) =>
  (direction === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC);

export const formatSortQueryProp = (fieldName, direction) => `${fieldName}$${direction}`;

export const parseSortQueryProp = (queryProp) => {
  const sortProps = queryProp.split('$');

  return {
    by: sortProps[0],
    direction: sortProps[1],
  };
};
