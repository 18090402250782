import { MODAL_NAMESPACE } from 'Store/namespaces';
import * as reducerProperties from 'Store/reducerProperties';
import { setDataAction, updateDataSyncAction } from 'Store/actions/genericActions';

export const closeModal = () => (dispatch) => (
  dispatch(setDataAction(MODAL_NAMESPACE, reducerProperties.MODAL_SETTINGS, { isOpen: false, content: null })));

export const openModal = (payload) => (dispatch) => (
  dispatch(updateDataSyncAction(MODAL_NAMESPACE, reducerProperties.MODAL_SETTINGS, { isOpen: true, ...payload })));

export const closeDrawer = () => (dispatch) => (
  dispatch(setDataAction(MODAL_NAMESPACE, reducerProperties.DRAWER_SETTINGS, { isOpen: false, content: null })));

export const openDrawer = (payload) => (dispatch) => (
  dispatch(updateDataSyncAction(MODAL_NAMESPACE, reducerProperties.DRAWER_SETTINGS, { isOpen: true, ...payload })));
