import IntlMessage from 'Util/IntlMessages';

export const options = {
  postAnonymously: {
    name: 'postAnonymously',
    text: 'Post anonymously',
    label: <IntlMessage id="label.post.anonymously" />,
  },
  // makePublic: {
  //   name: 'makePublic',
  //   checked: false,
  //   text: 'Short text',
  // },
};
