import { useEffect } from 'react';
import { connect } from 'react-redux';
import { object, func, string } from 'prop-types';

//  Components
import RewardComponent from 'Modules/posts/add/components/RewardComponent';

//  Actions/Selectors
import { userDataSelector, settingsSelector } from 'Store/selectors/settings';
import { updateDataSyncAction } from 'Store/actions/genericActions';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';

//  Other resources
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA } from 'Store/reducerProperties';
import { formatFormikReward } from 'Util/RewardType';

const propTypes = {
  rewardName: string.isRequired,
  userData: object.isRequired,
  settings: object.isRequired,
  selectedBounty: object.isRequired,
  updateDataSyncAction: func.isRequired,
};

const defaultProps = {};

const RewardContainer = (props) => {
  const {
    rewardName, selectedBounty, settings, userData, ...remainingProps
  } = props;

  useEffect(() => {
    prepareInitialData();
  }, []);

  const prepareInitialData = () => {
    const currentReward = selectedBounty.jobInfo ? selectedBounty.jobInfo[rewardName] : selectedBounty[rewardName];
    const reward = formatFormikReward({ reward: currentReward, userData, settings });

    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { [rewardName]: reward });
  };

  return (
    <RewardComponent rewardName={rewardName} {...remainingProps} />
  );
};

const mapStateToProps = (state) => ({
  userData: userDataSelector(state).data || {},
  settings: settingsSelector(state) || {},
  selectedBounty: selectedBountySelector(state) || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

RewardContainer.propTypes = propTypes;
RewardContainer.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(RewardContainer);
