import {
  COMPANY_CREATE_ROUTE,
  NOT_FOUND,
  PROMOTION_DETAILS_ROUTE,
  STREAM_SUB_DETAILS_ROUTE,
  JOB_DETAILS_WITH_TYPE_ROUTE,
  STREAM_DETAILS_WITH_TYPE_ROUTE,
  USER_SENTS_DETAILS_ROUTE,
  USER_SENTS_SUB_DETAILS_ROUTE,
} from 'Constants/routes';
import { companyDialogOptionsConfig } from 'Modules/posts/add/utils/createOrgOptionsDialog';
import { AppConfig } from 'Util/AppUtils';
import { isSubBounty } from 'Models/bounty/Bounty';
import { PROMOTION, JOB } from 'Constants/bounty/bountyType';

export function generateLink(route, params = {}) {
  let formattedRoute = route;

  Object.keys(params).forEach((key) => {
    formattedRoute = formattedRoute.replace(`:${key}`, params[key]);
  });

  return formattedRoute;
}

export const generateMenuOptionLink = (type) => {
  if (companyDialogOptionsConfig[type]) {
    return generateLink(COMPANY_CREATE_ROUTE, { orgType: `${type}/new` });
  }

  return NOT_FOUND;
};

export function generateLinkForBountyDetails(bounty = {}, match = {}, isOutboundCard = false) {
  const { id, type, parentBounty } = bounty;
  const { params } = match;
  const subBounty = isSubBounty(bounty);
  const bountyType = type || 'bounty';

  if (subBounty) {
    const routeParams = { bountyType, bountyId: parentBounty?.id, subBountyId: id };
    return generateLink(isOutboundCard ? STREAM_SUB_DETAILS_ROUTE : USER_SENTS_SUB_DETAILS_ROUTE, routeParams);
  }

  if (!params || (params && (!Object.keys(params).length || params.bountyId !== bounty.id))) {
    const streamRoute = isOutboundCard ? USER_SENTS_DETAILS_ROUTE : STREAM_DETAILS_WITH_TYPE_ROUTE;
    let gotoLink = streamRoute;
    if (bounty.type === PROMOTION) {
      gotoLink = PROMOTION_DETAILS_ROUTE;
    }
    if (bounty.type === JOB) {
      gotoLink = JOB_DETAILS_WITH_TYPE_ROUTE;
    }
    return generateLink(gotoLink, { bountyType, bountyId: bounty.id });
  }

  return null;
}

export function getBaseUrl() {
  const { appLinkSchema, appTld } = AppConfig;
  return `${appLinkSchema}://${appTld}`;
}

export function getPromotionDetailsLink(promotionId) {
  return `${getBaseUrl()}/promotions/${promotionId}`;
}

export function getBountyDetailsLink(bountyId, type) {
  return `${getBaseUrl()}/bounties/${bountyId}/type/${type}#details`;
}
