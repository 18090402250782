import { string, func, object } from 'prop-types';
import { TextareaAutosize } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const TextAreaAuto = (props) => {
  const { name, handleChanges, validationErrors, ...otherProps } = props;
  const classes = useStyles();

  return (
    <>
      <TextareaAutosize
        {...otherProps}
        className={`${classes.autoTextArea} ${validationErrors[name] && classes.errorBorder} mt-10 mb-5`}
        margin="dense"
        onChange={handleChanges}
        name={name}
        error={validationErrors[name]}
        helpertext={validationErrors[name]}
        minRows={1}
      />
      {validationErrors[name] && validationErrors[name]
        .map((error) => <p key={error} className={classes.errorMessage}>{error}</p>)}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  autoTextArea: {
    border: 'none',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    width: '100%',
  },
  errorBorder: {
    borderBottom: `2px solid ${theme.palette.error.main}`,

    '&::placeholder': {
      color: `${theme.palette.error.main} !important`,
      opacity: '1',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '12px',
    margin: '0 14px',
  },
}));

TextAreaAuto.propTypes = {
  name: string.isRequired,
  validationErrors: object,
  handleChanges: func.isRequired,
  classes: object,
};

TextAreaAuto.defaultProps = {
  classes: {},
  validationErrors: {},
};

export default TextAreaAuto;
