// Actions/Reducers
import { getHandlers } from 'Store/reducers/helpers';

//  Others
import { GROUPS, GROUP_INVITES, GROUP_MEMBERS, EMPLOYEES, GROUP_PROFILE } from 'Store/reducerProperties';
import { GROUP_NAMESPACE } from 'Store/namespaces';

// initial state
const INIT_STATE = {
  [GROUPS]: [],
  [GROUP_PROFILE]: {},
  [GROUP_INVITES]: [],
  [GROUP_MEMBERS]: [],
  [EMPLOYEES]: [],
};

const handlersReducer = getHandlers(GROUP_NAMESPACE);

export default function groupReducer(state = INIT_STATE, action = {}) {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}
