import bigInt from 'big-integer';
import { cloneDeep } from 'lodash';
import {
  SORT_ORDER_CREATOR,
  SORT_ORDER_BY_TYPE_RECENT,
  SORT_ORDER_BY_TYPE_POPULAR,
  SORT_ORDER_BY_TYPE_DISCUSSED,
  SORT_ORDER_BY_LIST_RECENT,
  SORT_ORDER_BY_LIST_POPULAR,
  SORT_ORDER_BY_LIST_DISCUSSED,
  SORT_ORDER_RECENT,
  SORT_ORDER_POPULAR,
  SORT_ORDER_DISCUSSED,
  SORT_ORDER_BY_STREAM_RECENT,
  SORT_ORDER_BY_STREAM_POPULAR,
  SORT_ORDER_BY_STREAM_DISCUSSED,
  sortKeys,
} from 'Modules/posts/bounty/constants/sortConfig';
import { NORMAL, IMPORTANT, UNIMPORTANT } from 'Modules/posts/bounty/constants/categories';
import { CODE_REGULAR, STREAM_ID } from 'Modules/posts/bounty/constants/codeType';
import { SHOWN } from 'Constants/bounty/bounty';
import { DRAFT_STATE } from 'Constants/bounty/bountyState';
import { isDraftState } from 'Models/bounty/BountyState';
import { listTypeData } from 'Modules/posts/bounty/constants/listType';
import { ListDef } from 'Models/Lists';
import { firstNonNull } from 'Util/ObjectUtils';

const MAX_VALUE_LONG = '9223372036854775807';
const HEX_ARRAY = '0123456789ABCDEF'.split('');
const KEY_SEP = '-';

function longToBytes(nr) {
  const byteArray = [0, 0, 0, 0, 0, 0, 0, 0];
  let cloneNr = nr;

  byteArray.forEach((item, index) => {
    byteArray[7 - index] = cloneNr.and(0xff);
    cloneNr = cloneNr.shiftRight(8);
  });

  return byteArray;
}

function bytesToHex(bytes) {
  let hexa = '';

  bytes.forEach((item) => {
    const v = item & 0xFF; // eslint-disable-line
    hexa = `${hexa}${HEX_ARRAY[v >>> 4]}${HEX_ARRAY[v & 0x0F]}`; // eslint-disable-line
  });

  return hexa;
}

function getTimestampAsSortKeyAsc(ts) {
  const longValue = bigInt(ts);
  return bytesToHex(longToBytes(longValue));
}

export function getTimestampAsSortKeyDesc(ts) {
  const longValue = bigInt(MAX_VALUE_LONG).subtract(ts);
  return bytesToHex(longToBytes(longValue));
}

export function stitchParts(tuple) {
  if (!Object.keys(tuple)) {
    return '';
  }

  return Object.values(tuple).join(KEY_SEP);
}

function latest(updatedAt, createdAt) {
  const ts = Math.max(updatedAt, createdAt);

  return ts > 0 ? ts : new Date().getTime();
}

function getDiscussionScore({ nrResponses = null, nrComments = null }) {
  return 10 * nrResponses + nrComments;
}

export function getFavoritePriority(now) {
  const tuple = {
    type: NORMAL,
    list: CODE_REGULAR,
    category: NORMAL,
    sortl: getTimestampAsSortKeyDesc(now),
    ctrl: SHOWN,
  };

  return stitchParts(tuple);
}

// TODO: check how list code is define
function getSortKey(bountyKeyInfo, type, ts) {
  const listCode = bountyKeyInfo.listId && listTypeData[bountyKeyInfo.listId]
    ? listTypeData[bountyKeyInfo.listId].code
    : null;
  const listPrefix = !listCode || bountyKeyInfo.isOutbound
  || type === listCode ? CODE_REGULAR : listCode;
  let category = NORMAL;
  let copyTs = ts;

  if (bountyKeyInfo.pinnedAt) {
    category = IMPORTANT;
    copyTs = bountyKeyInfo.pinnedAt;
  }
  const sortl = getTimestampAsSortKeyDesc(copyTs);

  const tuple = {
    type: type || '0',
    list: listPrefix || CODE_REGULAR,
    category: category || NORMAL,
    sortl: sortl || getTimestampAsSortKeyDesc(0),
    ctrl: SHOWN,
  };

  return stitchParts(tuple);
}

function getSortKeyByListCommon(bountyKeyInfo, nr) {
  let copyListId = bountyKeyInfo.listId;

  if (!copyListId) {
    copyListId = STREAM_ID;
  }

  return getSortKey(bountyKeyInfo, copyListId, nr);
}

function getSortKeyByCreator(bountyKeyInfo) {
  const ts = latest(bountyKeyInfo.updatedAt, bountyKeyInfo.createdAt);
  return getSortKey(bountyKeyInfo, bountyKeyInfo.creatorId, ts);
}

function getSortKeyByType(bountyKeyInfo) {
  return getSortKey(bountyKeyInfo, bountyKeyInfo.bountyType, bountyKeyInfo.updatedAt);
}

function getSortKeyByTypeAndPopularity(bountyKeyInfo) {
  return getSortKey(bountyKeyInfo, bountyKeyInfo.bountyType, bountyKeyInfo.starRating);
}

function getSortKeyByTypeAndMostDiscussed(bountyKeyInfo) {
  return getSortKey(bountyKeyInfo, bountyKeyInfo.bountyType, getDiscussionScore(bountyKeyInfo));
}

function getSortKeyByList(bountyKeyInfo) {
  return getSortKeyByListCommon(bountyKeyInfo, bountyKeyInfo.updatedAt);
}

function getSortKeyByListAndPopularity(bountyKeyInfo) {
  return getSortKeyByListCommon(bountyKeyInfo, bountyKeyInfo.starRating);
}

function getSortKeyByListAndMostDiscussed(bountyKeyInfo) {
  return getSortKeyByListCommon(bountyKeyInfo, getDiscussionScore(bountyKeyInfo));
}

function getSortKeyByStream(bountyKeyInfo) {
  return getSortKey(bountyKeyInfo, STREAM_ID, bountyKeyInfo.updatedAt);
}

function getSortKeyByStreamAndPopularity(bountyKeyInfo) {
  return getSortKey(bountyKeyInfo, STREAM_ID, bountyKeyInfo.starRating);
}

function getSortKeyByStreamAndMostDiscussed(bountyKeyInfo) {
  return getSortKey(bountyKeyInfo, STREAM_ID, getDiscussionScore(bountyKeyInfo));
}

function getSortKeyByBountyForResponse(responseKeyInfo) {
  const ts = latest(responseKeyInfo.updatedAt, responseKeyInfo.createdAt);
  return getSortKey(responseKeyInfo, responseKeyInfo.bountyId, ts);
}

function getSortKeyByBountyAndPopularityForResponse(responseKeyInfo) {
  const { bountyId, nrLikes, starRating } = responseKeyInfo;
  return getSortKey(responseKeyInfo, bountyId, nrLikes + starRating);
}

function getSortKeyByBountyMostDiscussedForResponse(responseKeyInfo) {
  const { bountyId, nrComments } = responseKeyInfo;
  return getSortKey(responseKeyInfo, bountyId, nrComments);
}

function getSortKeyByRecommendationApplicantForResponse(responseKeyInfo) {
  const ts = latest(responseKeyInfo.updatedAt, responseKeyInfo.createdAt);
  return getSortKey(responseKeyInfo, responseKeyInfo.bountyId, ts);
}

const keyGeneratorByType = {
  [SORT_ORDER_CREATOR]: getSortKeyByCreator,
  [SORT_ORDER_BY_TYPE_RECENT]: getSortKeyByType,
  [SORT_ORDER_BY_TYPE_POPULAR]: getSortKeyByTypeAndPopularity,
  [SORT_ORDER_BY_TYPE_DISCUSSED]: getSortKeyByTypeAndMostDiscussed,
  [SORT_ORDER_BY_LIST_RECENT]: getSortKeyByList,
  [SORT_ORDER_BY_LIST_POPULAR]: getSortKeyByListAndPopularity,
  [SORT_ORDER_BY_LIST_DISCUSSED]: getSortKeyByListAndMostDiscussed,
  [SORT_ORDER_BY_STREAM_RECENT]: getSortKeyByStream,
  [SORT_ORDER_BY_STREAM_POPULAR]: getSortKeyByStreamAndPopularity,
  [SORT_ORDER_BY_STREAM_DISCUSSED]: getSortKeyByStreamAndMostDiscussed,
};

export function getBountyKeyInfo(bounty) {
  const { stats = {}, rating = {}, creator = {} } = bounty;
  let nrResponses = 0;
  let nrComments = 0;

  if (stats) {
    nrResponses = stats.responseCount;
    nrComments = stats.commentCount;
  }

  return {
    creatorId: creator.id,
    bountyType: bounty.type,
    pinnedAt: bounty.pinnedAt,
    starRating: rating.totalStars || 0,
    nrResponses,
    nrComments,
    createdAt: bounty.createdAt,
    updatedAt: bounty.updatedAt,
    listId: ListDef.getEffectiveListId(bounty.listId, bounty.listCode, bounty.type),
    isOutbound: bounty.isOutbound || bounty.state === DRAFT_STATE,
    state: DRAFT_STATE,
  };
}

export function getSortKeysObject(bountyKeyInfo, keys = sortKeys) {
  const generatedKeys = {};

  keys.forEach((key) => {
    const generatorFn = keyGeneratorByType[key];

    if (generatorFn) {
      generatedKeys[key] = generatorFn(bountyKeyInfo);
    }
  });

  return generatedKeys;
}

export function getOutboundPriority(isDraft, createdAt) {
  const category = isDraft ? IMPORTANT : NORMAL;
  const tuple = {
    type: NORMAL,
    list: CODE_REGULAR,
    category,
    sortl: getTimestampAsSortKeyDesc(createdAt),
    ctrl: SHOWN,
  };

  return stitchParts(tuple);
}

function getInboundPriority(bounty) {
  const bountyKeyInfo = getBountyKeyInfo(bounty);
  const ts = latest(bountyKeyInfo.updatedAt, bountyKeyInfo.createdAt);
  return getSortKey(bountyKeyInfo, NORMAL, ts);
}

export function getPriority(bounty) {
  if (bounty.isOutbound) {
    return getOutboundPriority(isDraftState(bounty.state), bounty.createdAt);
  }

  return getInboundPriority(bounty);
}

export function getPriorityForComment(comment) {
  const {
    text, reward, editedAt, sentAt,
  } = comment;
  let category = IMPORTANT;

  if (!text || (text && !text.trim())) {
    category = UNIMPORTANT;
  } else if (!reward) {
    category = NORMAL;
  }

  const ts = firstNonNull(editedAt, sentAt);
  return ['00', category, 'GG', getTimestampAsSortKeyAsc(ts)].join(KEY_SEP);
}

export function getPriorityForProduct(productInfo) {
  return getTimestampAsSortKeyAsc(productInfo.addedAt ? productInfo.addedAt : 0);
}

export function forResponse(response) {
  const { stats } = response;
  const nrComments = (stats && stats.commentCount) || 0;
  const nrLikes = (stats && stats.likesCount) || 0;
  const starRating = (response.rating && response.rating.totalStars) || 0;

  const creatorId = response.creator != null ? response.creator.id : null;
  const bountyId = response.bountyInfo != null ? response.bountyInfo.id : null;

  return {
    creatorId,
    bountyId,
    state: response.state,
    pinnedAt: response.pinnedAt || null,
    starRating,
    nrComments,
    nrLikes,
    createdAt: response.updatedAt || null,
    updatedAt: response.updatedAt || null,
  };
}

function getSortKeyForResponse(key, responseKeyInfo) {
  switch (key) {
    case SORT_ORDER_CREATOR:
      return getSortKeyByCreator(responseKeyInfo);
    case SORT_ORDER_RECENT:
      return getSortKeyByBountyForResponse(responseKeyInfo);
    case SORT_ORDER_POPULAR:
      return getSortKeyByBountyAndPopularityForResponse(responseKeyInfo);
    case SORT_ORDER_DISCUSSED:
      return getSortKeyByBountyMostDiscussedForResponse(responseKeyInfo);
    default:
      console.log('illegal key'); // eslint-disable-line
  }
}

export function getSortKeysForResponse(keyInfo, keys) {
  let clonedKeys = cloneDeep(keys);

  if (clonedKeys == null || clonedKeys.length === 0) {
    clonedKeys = [
      SORT_ORDER_CREATOR,
      SORT_ORDER_RECENT,
      SORT_ORDER_POPULAR,
      SORT_ORDER_DISCUSSED,
    ];
  }
  const sortKeysForResponse = {};

  clonedKeys.forEach((key) => {
    sortKeysForResponse[key] = getSortKeyForResponse(key, keyInfo);
  });

  return sortKeysForResponse;
}

export function updateAllSortKeys(response) {
  const keyInfo = forResponse(response);
  response.creatorUpdatedAtSortKey = getSortKeyByCreator(keyInfo);
  response.bountyCreatedAtSortKey = getSortKeyByBountyForResponse(keyInfo);
  response.bountyPopularitySortKey = getSortKeyByBountyForResponse(keyInfo);
  response.bountyDiscussedSortKey = getSortKeyByBountyForResponse(keyInfo);

  if (response.recommendation && response.recommendation.applicant) {
    // eslint-disable-next-line max-len
    response.recommendation.applicantSortKey = getSortKeyByRecommendationApplicantForResponse(keyInfo);
  }

  return response;
}

export function getOutboundPriorityForResponse(response) {
  const responseKeyInfo = forResponse(response);
  const isDraft = isDraftState(responseKeyInfo.state);
  const ts = latest(responseKeyInfo.updatedAt, responseKeyInfo.createdAt);

  return getOutboundPriority(isDraft, ts);
}

export const getParentOrderSortKey = (parentId, order) => {
  const MAX_VALUE = 2147483647;
  const tuple = {
    parentId,
    category: order < 0 ? IMPORTANT : NORMAL,
    format: String(order < 0 ? MAX_VALUE + order : order).padStart(7, '0'),
  };

  return stitchParts(tuple);
};
