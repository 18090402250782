import classNames from 'classnames';
import { object, bool } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Fragment, useState } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import { Box } from '@mui/material';

import IntlMessages from 'Util/IntlMessages';
import { useFilters } from 'Util/hooks/useFilters';
import { handleDrawerToggle } from 'Store/actions/commonActions';
import * as styles from './index.module.scss';

const NavMenuItem = ({ menu, external }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const { resetFilters } = useFilters();

  const onToggleCollapseMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const closeMenu = () => {
    dispatch(handleDrawerToggle(false));
  };

  if (menu.child_routes != null) {
    return (
      <>
        <ListItem
          button
          component="li"
          onClick={closeMenu}
          className={`list-item ${classNames({ 'item-active': menu.open })}`}
        >
          <Box display="flex" alignItems="center">
            <ListItemIcon className="menu-icon">
              <span className="black-alpha-50 material-icons">
                <span className={menu.menu_icon} />
              </span>
            </ListItemIcon>
            <span className="menu font-15">
              <IntlMessages id={menu.menu_title} />
            </span>
          </Box>
        </ListItem>
        <Collapse in={menu.open} timeout="auto" className="sub-menu">
          <List className="list-unstyled py-0">
            {menu.child_routes.map((subMenu, index) => (!subMenu.child_routes ? (
              <ListItem button component="li" key={index}>
                <NavLink
                  activeClassName="item-active"
                  to={subMenu.path}
                  onClick={resetFilters}
                >
                  <span className="menu">
                    <IntlMessages id={menu.menu_title} />
                  </span>
                </NavLink>
              </ListItem>
            ) : (
              <Fragment key={index}>
                <ListItem
                  button
                  component="li"
                  onClick={onToggleCollapseMenu}
                  className={`list-item ${classNames({ 'item-active': subMenuOpen })}`}
                >
                  <span className="menu">
                    <IntlMessages id={menu.menu_title} />
                  </span>
                </ListItem>
                <Collapse in={subMenuOpen} timeout="auto">
                  {subMenu.child_routes.map((nestedMenu, nestedKey) => (
                    <ListItem button component="li" key={nestedKey}>
                      <NavLink
                        activeClassName="item-active"
                        to={nestedMenu.path}
                        onClick={resetFilters}
                      >
                        <span className="menu">
                          <IntlMessages id={menu.menu_title} />
                        </span>
                      </NavLink>
                    </ListItem>
                  ))}
                </Collapse>
              </Fragment>
            )))}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItem
      button
      component="li"
      onClick={closeMenu}
      className={`no-right-arrow list-item ${styles.navItem} ${classNames({ 'item-active': menu.open })}`}
    >
      {menu.path && !external && (
        <NavLink activeClassName="item-active" to={menu.path} onClick={resetFilters}>
          <Box className={styles.navItemWrapper}>
            <span className={`mr-20 material-icons ${styles.imageWrapper}`}>
              <span className={menu.menu_icon} />
            </span>
            <span className="menu">
              <span className="font-15"><IntlMessages id={menu.menu_title} /></span>
            </span>
          </Box>
        </NavLink>
      )}
      {!menu.path && (
        <div className={styles.fakeLink}>
          <Box className={styles.navItemWrapper}>
            <span className={`mr-20 material-icons ${styles.imageWrapper}`}>
              <span className={menu.menu_icon} />
            </span>
            <span className="menu">
              <span className="font-15"><IntlMessages id={menu.menu_title} /></span>
            </span>
          </Box>
        </div>
      )}
      {menu.path && external && (
        <a className={styles.fakeLink} href={menu.path} target="_blank" rel="noopener noreferrer">
          <Box className={styles.navItemWrapper}>
            <span className={`mr-20 material-icons ${styles.imageWrapper}`}>
              <span className={menu.menu_icon} />
            </span>
            <span className="menu">
              <span className="font-15"><IntlMessages id={menu.menu_title} /></span>
            </span>
          </Box>
        </a>
      )}
    </ListItem>
  );
};

NavMenuItem.propTypes = {
  menu: object.isRequired,
  external: bool,
};
NavMenuItem.defaultProps = {
  external: false,
};

export default NavMenuItem;
