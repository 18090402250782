import { useEffect, useState } from 'react';
import TextAreaAuto from 'Common/components/TextAreaAuto';
import { useDispatch, useSelector } from 'react-redux';
import * as modalSelectors from 'Modules/modal/store/selectors';
import useDebounce from 'Util/AppUtils';
import { updateDataSyncAction } from 'Store/actions/genericActions';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_INPUTS_DATA, MODAL_VALIDATION_ERRORS } from 'Store/reducerProperties';
import {
  getTranslationsCodesFromText,
  textToTranslations,
  translationsToText,
  updateTranslationByCode,
} from 'Util/TranslationsUtils';
import { translationsCommonCodes } from 'Constants/translations';
import { useIntl } from 'react-intl';
import { object } from 'prop-types';

const TranslatableTitleContainer = ({ validationErrors }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [selectedTitle, setSelectedTitle] = useState('');
  const [fullTitle, setFullTitle] = useState('');
  const [translations, setTranslations] = useState([]);

  const title = useSelector((state) => modalSelectors.inputsData(state).title || '');
  const description = useSelector((state) => modalSelectors.inputsData(state).description || '');
  const currentLanguageCode = useSelector((state) => modalSelectors.inputLanguage(state));

  const debouncedStoreUpdate = useDebounce(fullTitle, 700);

  const getTranslationByCode = (code) => translations.find((t) => t.code === code);

  // Sync title translations with description
  useEffect(() => {
    const codes = getTranslationsCodesFromText(description);

    const newTranslations = codes
      .filter((code) => code !== translationsCommonCodes.united)
      .map((code) => getTranslationByCode(code) || { code, text: '' });

    newTranslations
      .push({ code: translationsCommonCodes.united, text: translationsToText(newTranslations) });

    setTranslations(newTranslations);
  }, [description]);

  useEffect(() => setTranslations(textToTranslations(title)), [title]);

  // Save changes to translations array.
  useEffect(() => {
    if (!currentLanguageCode) return;

    const updatedTranslations = currentLanguageCode === translationsCommonCodes.united
      ? textToTranslations(selectedTitle) // Generate translations from the scratch
      : updateTranslationByCode(translations, currentLanguageCode, selectedTitle);

    setTranslations(updatedTranslations);
  }, [selectedTitle]);

  // Set selected translation text.
  useEffect(() => {
    if (!currentLanguageCode) return;

    const currentTranslate = getTranslationByCode(currentLanguageCode);
    if (currentTranslate) {
      setSelectedTitle(currentTranslate?.text || '');
    }
  }, [translations, currentLanguageCode]);

  useEffect(() => {
    if (translations.length) {
      setFullTitle(getTranslationByCode(translationsCommonCodes.united).text);
    }
  }, [translations]);

  useEffect(() => {
    dispatch(updateDataSyncAction(MODAL_NAMESPACE, MODAL_INPUTS_DATA, { title: fullTitle }));
    dispatch(updateDataSyncAction(MODAL_NAMESPACE, MODAL_VALIDATION_ERRORS, { ...validationErrors, title: null }));
  }, [debouncedStoreUpdate]);

  const handleChange = (e) => setSelectedTitle(e.target.value);

  return (
    <TextAreaAuto
      disabled={currentLanguageCode === translationsCommonCodes.united}
      aria-label="minimum height"
      placeholder={intl.formatMessage({
        id: currentLanguageCode === translationsCommonCodes.united
          ? 'label.pickLanguageFirst' : 'bounty.formControls.labels.jobTitle',
      })}
      required
      handleChanges={handleChange}
      size="medium"
      value={currentLanguageCode === translationsCommonCodes.united ? '' : selectedTitle}
      name="title"
      validationErrors={validationErrors}
    />
  );
};

TranslatableTitleContainer.propTypes = {
  validationErrors: object.isRequired,
};

export default TranslatableTitleContainer;
