import yaml from 'js-yaml';

const DESCRIPTION_SEP = '\n---\n---';
const PROP_SEP = '\n';

export function promotionToString(description, information) {
  const text = `${description}${DESCRIPTION_SEP}`;
  const additionalInfo = yaml.dump(information, { quotingType: '"', forceQuotes: true });

  return `${text}${PROP_SEP}${additionalInfo}${PROP_SEP}`;
}

export function parsePromotion(description) {
  const { text } = description;
  const info = text.split(DESCRIPTION_SEP)[1];
  return info ? yaml.load(info, 'utf8') : {};
}

export function getPromDescription(description) {
  const { text } = description;
  return text.split(DESCRIPTION_SEP)[0];
}
