import UserManagementService from 'Services/UserManagementService';
import { NotificationManager } from 'react-notifications';
import { getApplications } from 'Store/actions/applicationsActions';

export const ACTIONS = {
  LOADING: 'managementReducer/Loading',
  GET_LIST: 'managementReducer/GetList',
  ERASE_LIST: 'managementReducer/EraseList',
  UPDATE_TOTAL: 'managementReducer/UpdateTotal',
  UPDATE_FILTERS: 'managementReducer/UpdateFilters',
  UPDATE_REQ_PROPS: 'managementReducer/UpdateReqProps',
  UPDATE_SEARCH_FIELDS: 'managementReducer/UpdateSearchFields',
  RESET_FILTERS: 'managementReducer/ResetFilters',
};

const updateLoadingStatus = (bool) => (dispatch) => {
  dispatch({
    type: ACTIONS.LOADING,
    payload: bool,
  });
};

export const getManagement = (options) => (dispatch) => {
  updateLoadingStatus(true)(dispatch);

  UserManagementService.getUsers(options)
    .then((response) => {
      dispatch({ type: ACTIONS.GET_LIST, payload: response.users });
      dispatch({ type: ACTIONS.UPDATE_TOTAL, payload: response.totalCount });
    })
    .catch((err) => {
      NotificationManager.error(err.message);
    })
    .finally(() => {
      updateLoadingStatus(false)(dispatch);
    });
};

export const eraseApplications = () => (dispatch) => dispatch({ type: ACTIONS.ERASE_LIST });

export const getCompanyApplications = (options, companyId) => getApplications({ ...options, companyId });

const updateManagementReqProps = (reqProps) => (dispatch) =>
  dispatch({ type: ACTIONS.UPDATE_REQ_PROPS, payload: reqProps });

export const updateManagementOffset = (offset) => updateManagementReqProps({ start: offset });

export const updateManagementLimit = (limit) => updateManagementReqProps({ limit });

export const updateManagementOrder = (order) => updateManagementReqProps({ order, start: 0 });

export const updateManagementQuery = (query) => updateManagementReqProps({ query, start: 0 });

export const updateManagementFilters = (filters) => (dispatch) =>
  dispatch({
    type: ACTIONS.UPDATE_FILTERS,
    payload: { filters, reqProps: { start: 0 } },
  });

export const updateManagementSearchFields = (fields) => (dispatch) =>
  dispatch({ type: ACTIONS.UPDATE_SEARCH_FIELDS, payload: fields });

export const resetManagementFilters = () => (dispatch) =>
  dispatch({ type: ACTIONS.RESET_FILTERS });
