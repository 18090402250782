// Actions/Reducers
import { getHandlers } from 'Store/reducers/helpers';

//  Others

import { APPLICANTS_NAMESPACE } from 'Store/namespaces';

// initial state
// TODO: Place applicants list to reducer
const INIT_STATE = {
  isLoading: false,
  applicants: [],
};

const handlersReducer = getHandlers(APPLICANTS_NAMESPACE);

export default function applicantsReducer(state = INIT_STATE, action = {}) {
  const handler = handlersReducer[action.type];

  if (!handler) return state;

  return handler(state, action.payload, action.reducerProperty);
}
