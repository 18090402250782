import moment from 'moment';
import cs from 'classnames';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ButtonBase, Button, Collapse, ClickAwayListener } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';

import IntlMessage from 'Util/IntlMessages';
import { getUserUid } from 'AuthSelectors';
import { getUserNotifications } from 'Modules/notification/operations';
import { deleteMyNotifications } from 'Services/UserService';
import { getRouteByLink } from 'Modules/notification/utils/helpers';
import { sortUsersDueRelevantDate } from 'Util/UserUtils';
import * as styles from './index.module.scss';

const Notifications = () => {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const userUid = useSelector(getUserUid);
  const history = useHistory();

  useEffect(() => {
    getUserNotifications(userUid, (results) => {
      const sortedNotifications = sortUsersDueRelevantDate(results);
      setNotifications(sortedNotifications);
    });
  }, []);

  const toggle = () => {
    notifications.length && setOpen(!open);
  };
  const close = () => setOpen(false);
  const handleClear = () => {
    deleteMyNotifications();
    close();
  };

  const handleClickNotification = (notification) => () => {
    const to = getRouteByLink(notification.link);

    if (to) {
      history.push(to);
      toggle();
    }
  };

  return (
    <div className={styles.bell}>
      <ButtonBase
        disableRipple
        size="large"
        aria-label="bell"
        className={`${cs(styles.bellButton, { [styles.bellButtonActive]: open })} no-shake`}
        onClick={toggle}
      >
        <div
          className={`icon-notification-2 ${cs(styles.bellIcon, {
            [styles.bellIconActive]: !!notifications.length,
          })}`}
        />
        <IntlMessage id="title.notifications" tagName="span" />
      </ButtonBase>
      <Collapse
        className={styles.bellContent}
        in={open}
        mountOnEnter
        unmountOnExit
      >
        <ClickAwayListener onClickAway={close}>
          <div>
            <Scrollbars
              className="rct-scroll"
              autoHeight
              autoHeightMax={312}
            >
              <ul>
                {notifications?.map((notification, key) => (
                  <li
                    key={key}
                    role="presentation"
                    onClick={handleClickNotification(notification)}
                    className={`${styles.bellItem} cursor-pointer`}
                  >
                    <p>{notification.body}</p>
                    <p>{moment(notification.sentAt).format('DD MMM')}</p>
                  </li>
                ))}
              </ul>
            </Scrollbars>
            <div className={styles.bellClear}>
              <Button color="primary" onClick={handleClear}>
                <IntlMessage id="menu_notification_delete" />
              </Button>
            </div>
          </div>
        </ClickAwayListener>
      </Collapse>
    </div>
  );
};

export default Notifications;
