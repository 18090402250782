import { string } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';

//  Components
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const HtmlTooltipCustom = ({ text }) => {
  const classes = useStyles();

  return (
    <HtmlTooltip
      title={(
        <div className="p-10-12" dangerouslySetInnerHTML={{ __html: text }} /> //eslint-disable-line
      )}
    >
      <InfoIcon className={classes.info} />
    </HtmlTooltip>
  );
};

HtmlTooltipCustom.propTypes = {
  text: string.isRequired,
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 280,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    padding: '12px 12px 30px',
    fontSize: theme.typography.pxToRem(15),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles({
  info: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: '5px',
    color: '#01c5ff',
  },
});

export default HtmlTooltipCustom;
