import get from 'lodash/get';
import * as BOUNTY_TYPES from 'Constants/bounty/bountyType';
import { PRODUCT_CONSTRAINTS } from 'Util/validation/constraints';
import { BOTH, EITHER } from 'Util/RewardType';
import * as FIELDS from 'Constants/fields';
import { jobTitleRegex } from './regexCollection';

const industries = { presence: true };
const skills = { presence: { allowEmpty: false } };
const title = { presence: true, translatedTitlesNotEmpty: true, format: jobTitleRegex };
const hrsPerWeekMax = { numericality: true };
const hrsPerWeekMin = { numericality: true };

const payLowEnd = { numericality: { greaterThan: 0 } };
const payHighEnd = { numericality: { greaterThan: 0 } };

const merchantSku = { presence: true };
// const positions = { presence: true };
// const description = { presence: true };

// TODO: return Min/Max pay validation later for budget form only.
export const CONSTRAINTS = {
  [BOUNTY_TYPES.JOB]: {
    industries,
    skills,
    title,
    hrsPerWeekMax,
    hrsPerWeekMin,
    'payLowEnd.money.amount': payLowEnd,
    'payHighEnd.money.amount': payHighEnd,
    // positions,
  },
  [BOUNTY_TYPES.PRODUCT]: {
    merchantSku,
  },
};

export function getConstraintsByBountyType(type) {
  return CONSTRAINTS[type] || {};
}

export function getProductConstraints(values, isTicket) {
  const rules = JSON.parse(JSON.stringify(PRODUCT_CONSTRAINTS));

  if (isTicket) {
    if (!get(values, FIELDS.useQuantityField.name)
      && !get(values, FIELDS.unlimitedUseQuantityField.name)) {
      rules[FIELDS.useQuantityField.name] = { presence: true };
    }
  }

  if ([BOTH, EITHER].includes(values?.customData?.currencyType)) {
    rules[FIELDS.alt1MsrpField.name] = { presence: true };
    rules[FIELDS.alt2MsrpField.name] = { presence: true };

    if (get(values, FIELDS.alt1PriceField.name) || get(values, FIELDS.alt2PriceField.name)) {
      rules[FIELDS.alt1PriceField.name] = { presence: true };
      rules[FIELDS.alt2PriceField.name] = { presence: true };
    }
  } else {
    rules[FIELDS.mainMsrpField.name] = { presence: true };
  }

  return rules;
}
