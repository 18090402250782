export function isBlank(value) {
  return value == null || value.trim().length === 0;
}

export function removeSpacesAndHyphens(cardNumberWithSpaces) {
  if (isBlank(cardNumberWithSpaces)) {
    return null;
  }

  return cardNumberWithSpaces.replace(/\s/g, '');
}

export function hasAnyPrefix(number, prefixes) {
  if (number == null) {
    return false;
  }

  let hasPrefix = false;

  prefixes.forEach((prefix) => {
    if (number.startsWith(prefix)) {
      hasPrefix = true;
    }
  });

  return hasPrefix;
}
