import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { array, func, object } from 'prop-types';
import { CardMedia, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useIntl } from 'react-intl';

//  Actions/Selectors
import { selectedProductsSelector, selectedPromotionsSelector } from 'Modules/modal/store/selectors';
import { updateListSyncAction } from 'Store/actions/genericActions';
import { selectedBountySelector } from 'Modules/companySents/store/selectors';

//  Other resources
import ImagePlaceholder from 'Assets/img/general/image_placeholder.png';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { PRODUCT, PROMOTION } from 'Constants/bounty/bountyType';
import { getPromotionCoverUrl, getProductCoverUrl } from 'Util/BountyUtils';
import { PRODUCT_TO_ATTACH, PROMOTION_TO_ATTACH } from 'Store/reducerProperties';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

const AttachedPromotion = (props) => {
  const { selectedPromotions, selectedBounty, selectedProducts } = props;
  const [attachedPromotions, setAttachedPromotions] = useState([]);
  const classes = useStyles();
  const intl = useIntl();

  useEffect(() => {
    const attachments = selectedBounty?.attachments?.attachments
      ? Object
        .values(selectedBounty.attachments.attachments)
        .filter((attach) => [PROMOTION, PRODUCT].includes(attach.meaning))
      : [];
    setAttachedPromotions(attachments);
  }, [selectedBounty]);

  const removePromotion = (item) => {
    const index = selectedPromotions.findIndex((selectedProm) => selectedProm.id === item.id);

    if (index > -1) {
      const newData = selectedPromotions.filter(((selectedProm) => selectedProm.id !== item.id));
      return props.updateListSyncAction(MODAL_NAMESPACE, PROMOTION_TO_ATTACH, newData);
    }
  };

  const removeProduct = (item) => {
    const index = selectedProducts.findIndex((selectedProd) => selectedProd.id === item.id);

    if (index > -1) {
      const newData = selectedProducts.filter(((selectedProd) => selectedProd.id !== item.id));
      return props.updateListSyncAction(MODAL_NAMESPACE, PRODUCT_TO_ATTACH, newData);
    }
  };

  return (
    <>
      {attachedPromotions?.length > 0 && (
        <div>
          <p>
            {intl.formatMessage({ id: 'bounty.attachedItems' })}
            :
          </p>
          {attachedPromotions.map((attach) => (
            <Box
              key={attach.id}
              m={2}
              width="120px"
              display="inline-block"
              position="relative"
              className={classes.promotionCard}
            >
              <CardMedia
                component="img"
                alt="promotion"
                height="120"
                classes={{ root: classes.cardRoot }}
                image={attach.url || ImagePlaceholder}
              />
              <Box
                className={classes.overlayCard}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  role="presentation"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  className="pointer"
                  p={2}
                >
                  <span>{attach.meaning}</span>
                </Box>
              </Box>
            </Box>
          ))}
        </div>
      )}
      {(selectedPromotions?.length > 0 || selectedProducts?.length > 0) && (
        <div>
          <p>{intl.formatMessage({ id: 'bounty.promotionsToAttach' })}</p>
          <div>
            {selectedPromotions.map((prom) => (
              <Box key={prom.id} m={2} position="relative" className={classes.promotionCard}>
                <CardMedia
                  component="img"
                  alt="promotion"
                  height="120"
                  classes={{ root: classes.cardRoot }}
                  image={getPromotionCoverUrl(prom) || ImagePlaceholder}
                />
                <Box position="absolute" top={0} right={0}>
                  <IconButton aria-label="delete" size="small" onClick={() => removePromotion(prom)}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </div>
          <div>
            {selectedProducts.map((product) => (
              <Box key={product.id} m={2} position="relative" className={classes.promotionCard}>
                <CardMedia
                  component="img"
                  alt="promotion"
                  height="120"
                  classes={{ root: classes.cardRoot }}
                  image={getProductCoverUrl(product) || ImagePlaceholder}
                />
                <Box position="absolute" top={0} right={0}>
                  <IconButton aria-label="delete" size="small" onClick={() => removeProduct(product)}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

AttachedPromotion.propTypes = {
  selectedPromotions: array.isRequired,
  selectedProducts: array.isRequired,
  selectedBounty: object.isRequired,
  updateListSyncAction: func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  promotionCard: {
    display: 'inline-block',
    width: '120px',
    '&:hover $overlayCard': {
      visibility: 'visible',
    },
  },
  overlayCard: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
    top: 0,
    color: 'white',
    visibility: 'hidden',
  },
  cardRoot: {
    borderRadius: theme.spacing(1),
  },
}));

const mapStateToProps = (state) => ({
  selectedPromotions: selectedPromotionsSelector(state) || [],
  selectedProducts: selectedProductsSelector(state) || [],
  selectedBounty: selectedBountySelector(state) || {},
});

const mapDispatchToProps = {
  updateListSyncAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachedPromotion);
