import IntlMessages from '../util/IntlMessages';

export const PREFIX = 'avatar:';
export const UNDERCOVER_AVATAR_URI = `${PREFIX}undercover`;
export const UNDERCOVER_NAME = 'Undercover';
const CUSTOM_PREFIX = 'rep:';
const ICON_PREFIX = 'gmd_';

export const USER_GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
};

export const USER_GENDER_LABELS = {
  [USER_GENDER.MALE]: <IntlMessages id="label.gender.male" />,
  [USER_GENDER.FEMALE]: <IntlMessages id="label.gender.female" />,
  [USER_GENDER.OTHER]: <IntlMessages id="label.gender.other" />,
};

export const GENDERS_DICTIONARY = [
  {
    label: USER_GENDER_LABELS.MALE,
    value: USER_GENDER.MALE,
  },
  {
    label: USER_GENDER_LABELS.FEMALE,
    value: USER_GENDER.FEMALE,
  },
  {
    label: USER_GENDER_LABELS.OTHER,
    value: USER_GENDER.OTHER,
  },
];

export const USER_GENDER_OPTIONS = [
  {
    label: <IntlMessages id="label.none" />,
    value: '',
  },
  {
    label: USER_GENDER_LABELS.MALE,
    value: USER_GENDER.MALE,
  },
  {
    label: USER_GENDER_LABELS.FEMALE,
    value: USER_GENDER.FEMALE,
  },
  {
    label: USER_GENDER_LABELS.OTHER,
    value: USER_GENDER.OTHER,
  },
];

export const USER_REPUTATION = {
  NONE: 'NONE',
  VIP: 'VIP',
  PRO: 'PRO',
  CUSTOM: 'CUSTOM',
  VERIFIED: 'VERIFIED',
};

export const USER_IDENTITY_CONFIG_STATE = {
  NONE: 'NONE',
  ALL: 'ALL',
  NOT_VERIFIED: 'NOT_VERIFIED',
  REQUIRED: 'REQUIRED',
  INCOMPLETE: 'INCOMPLETE',
  READY_FOR_KYC: 'READY_FOR_KYC',
  COMPLETED: 'COMPLETED',
  VERIFIED: 'VERIFIED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  UNKNOWN: 'UNKNOWN',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  ACCEPTED_GLOBAL: 'ACCEPTED_GLOBAL',
  ACCEPTED_EUROPE: 'ACCEPTED_EUROPE',
  ACCEPTED_LOCAL: 'ACCEPTED_LOCAL',
};

export const APPLICATION_CONFIG_STATE = {
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  NEW: 'NEW',
  PENDING: 'PENDING',
  UNAVAILABLE: 'UNAVAILABLE',
};

export const KYC_LEVELS = {
  EUROPE: 'EUROPE',
  INTERNATIONAL: 'INTERNATIONAL',
  LOCAL: 'LOCAL',
};

export const REGION_LEVELS = {
  EUROPE: 'EUROPE',
  LOCAL: 'LOCAL',
  GLOBAL: 'GLOBAL',
};

export const KYC_REJECT_REASONS = {
  FRAUD: 'FRAUD',
  UNVERIFIABLE: 'UNVERIFIABLE',
  BLACKLISTED: 'BLACKLISTED',
  OTHER: 'OTHER',
};

export const UserKind = {
  USER: 'USER',
  GROUP: 'GROUP',
  COMPANY: 'COMPANY',
  UNKNOWN: 'UNKNOWN',
};

export const KYC_REJECT_REASONS_LABELS = {
  [KYC_REJECT_REASONS.FRAUD]: 'Fraud',
  [KYC_REJECT_REASONS.UNVERIFIABLE]: 'Unverifiable',
  [KYC_REJECT_REASONS.BLACKLISTED]: 'Blacklisted',
  [KYC_REJECT_REASONS.OTHER]: 'Other',
};

export const USER_IDENTITY_STATE_LABELS = {
  [USER_IDENTITY_CONFIG_STATE.NONE]: 'None',
  [USER_IDENTITY_CONFIG_STATE.NOT_VERIFIED]: 'Not verified',
  [USER_IDENTITY_CONFIG_STATE.REQUIRED]: 'Required',
  [USER_IDENTITY_CONFIG_STATE.INCOMPLETE]: 'Incomplete',
  [USER_IDENTITY_CONFIG_STATE.READY_FOR_KYC]: 'Ready for KYC',
  [USER_IDENTITY_CONFIG_STATE.COMPLETED]: 'Completed',
  [USER_IDENTITY_CONFIG_STATE.ACCEPTED]: 'Accepted',
  [USER_IDENTITY_CONFIG_STATE.ALL]: 'All',
  [USER_IDENTITY_CONFIG_STATE.DECLINED]: 'Declined',
  [USER_IDENTITY_CONFIG_STATE.VERIFIED]: 'Verified',
  [USER_IDENTITY_CONFIG_STATE.REJECTED]: 'Rejected',
  [USER_IDENTITY_CONFIG_STATE.PENDING]: 'Pending',
  [USER_IDENTITY_CONFIG_STATE.UNKNOWN]: 'Unknown',
  [USER_IDENTITY_CONFIG_STATE.ACCEPTED_GLOBAL]: 'Global Accepted',
  [USER_IDENTITY_CONFIG_STATE.ACCEPTED_EUROPE]: 'Europe Accepted',
  [USER_IDENTITY_CONFIG_STATE.ACCEPTED_LOCAL]: 'Local Accepted',
};

export const APPLICATION_STATUSES = [
  {
    label: <IntlMessages id="label.userStatus.new" />,
    value: APPLICATION_CONFIG_STATE.NEW,
  },
  {
    label: <IntlMessages id="label.userStatus.accepted" />,
    value: APPLICATION_CONFIG_STATE.ACCEPTED,
  },
  {
    label: <IntlMessages id="label.userStatus.pending" />,
    value: APPLICATION_CONFIG_STATE.PENDING,
  },
  {
    label: <IntlMessages id="label.userStatus.declined" />,
    value: APPLICATION_CONFIG_STATE.DECLINED,
  },
  {
    label: <IntlMessages id="label.userStatus.unavailable" />,
    value: APPLICATION_CONFIG_STATE.UNAVAILABLE,
  },
];

export const APPLICANTS_KYS_FILTER_OPTIONS = [
  {
    label: <IntlMessages id="label.none" />,
    value: '',
  },
  {
    label: USER_IDENTITY_STATE_LABELS.ACCEPTED_GLOBAL,
    value: USER_IDENTITY_CONFIG_STATE.ACCEPTED_GLOBAL,
  },
  {
    label: USER_IDENTITY_STATE_LABELS.ACCEPTED_EUROPE,
    value: USER_IDENTITY_CONFIG_STATE.ACCEPTED_EUROPE,
  },
  {
    label: USER_IDENTITY_STATE_LABELS.ACCEPTED_LOCAL,
    value: USER_IDENTITY_CONFIG_STATE.ACCEPTED_LOCAL,
  },
  {
    label: USER_IDENTITY_STATE_LABELS.PENDING,
    value: USER_IDENTITY_CONFIG_STATE.PENDING,
  },
  {
    label: USER_IDENTITY_STATE_LABELS.REJECTED,
    value: USER_IDENTITY_CONFIG_STATE.REJECTED,
  },
];

export const REVIEW_APPLICANTS_KYS_FILTER_OPTIONS = [
  {
    label: USER_IDENTITY_STATE_LABELS.ALL,
    value: '',
  },
  {
    label: USER_IDENTITY_STATE_LABELS.ACCEPTED,
    value: USER_IDENTITY_CONFIG_STATE.ACCEPTED,
  },
  {
    label: USER_IDENTITY_STATE_LABELS.REJECTED,
    value: USER_IDENTITY_CONFIG_STATE.DECLINED,
  },
];

export const KYC_LEVELS_LABELS = {
  [KYC_LEVELS.INTERNATIONAL]: 'Global',
  [KYC_LEVELS.EUROPE]: 'Europe',
  [KYC_LEVELS.LOCAL]: 'Local',
};

export const REGION_LEVELS_LABELS = {
  [REGION_LEVELS.GLOBAL]: 'Global',
  [REGION_LEVELS.EUROPE]: 'Europe',
  [REGION_LEVELS.LOCAL]: 'Local',
};

export const KYC_LEVELS_LABELS_UI = {
  [KYC_LEVELS.INTERNATIONAL]: 'Global - the applicant can be hired for any location',
  [KYC_LEVELS.EUROPE]: 'Europe - the applicant has only met the requirements to work in a European country',
  [KYC_LEVELS.LOCAL]: 'Local - the applicant has only met the requirements to work locally (in the country they are in)', // eslint-disable-line
};

export const KYC_LEVELS_AS_OPTIONS = [
  {
    label: KYC_LEVELS_LABELS.LOCAL,
    value: KYC_LEVELS.LOCAL,
  },
  {
    label: KYC_LEVELS_LABELS.EUROPE,
    value: KYC_LEVELS.EUROPE,
  },
  {
    label: KYC_LEVELS_LABELS.INTERNATIONAL,
    value: KYC_LEVELS.INTERNATIONAL,
  },
];

export const APPLICANT_REGIONS_AS_OPTIONS = [
  {
    label: REGION_LEVELS_LABELS.LOCAL,
    value: REGION_LEVELS.LOCAL,
  },
  {
    label: REGION_LEVELS_LABELS.GLOBAL,
    value: REGION_LEVELS.GLOBAL,
  },
  {
    label: REGION_LEVELS_LABELS.EUROPE,
    value: REGION_LEVELS.EUROPE,
  },
];

export const FILTERS_FOR_APPLICANTS = [
  {
    filterTitle: 'gender',
    options: USER_GENDER_OPTIONS,
    label: <IntlMessages id="label.filter_by_gender" />,
    visible: true,
  },
  {
    filterTitle: 'kycStatuses',
    options: APPLICANTS_KYS_FILTER_OPTIONS,
    label: <IntlMessages id="label.filter_by_kyc" />,
    visible: true,
  },
  {
    filterTitle: 'country',
    options: [],
    label: <IntlMessages id="label.filter_by_country" />,
    visible: true,
  },
  {
    filterTitle: 'skillset',
    options: [],
    label: <IntlMessages id="label.filter_by_skillset" />,
    visible: true,
  },
];

export const FILTERS_FOR_REVIEW_APPLICANTS = [
  {
    filterTitle: 'applicationStatuses',
    options: REVIEW_APPLICANTS_KYS_FILTER_OPTIONS,
    label: <IntlMessages id="label.filter_application_status" />,
    visible: true,
  },
];

export function getUserKindAsEnum(user) {
  if (!user || !user.kind) {
    return UserKind.USER;
  }

  return user.kind.toUpperCase();
}

export function reputationAsEnum(reputation) {
  const reputationType = reputation && reputation.toUpperCase();
  return USER_REPUTATION[reputationType] || USER_REPUTATION.CUSTOM;
}

export const reputationBuilder = (author = {}) => {
  const { reputation } = author;
  const parts = (reputation
    && reputation.substring(CUSTOM_PREFIX.length).split('&').filter((item) => item))
    || [];
  const reputationProps = {};

  parts.forEach((part) => {
    const [name, value] = part.split('=').filter((item) => item);
    reputationProps[name] = value;
  });

  if (reputationProps.icon && reputationProps.icon.startsWith(ICON_PREFIX)) {
    reputationProps.icon = reputationProps.icon.substring(ICON_PREFIX.length);
  }

  return reputationProps;
};
