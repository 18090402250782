import { ACTIONS } from 'Store/actions/applicationsActions';
import { formatSortQueryProp } from 'Common/utils/sort';
import { APPLICATIONS_SORT_FIELDS, SORT_DIRECTION } from 'Constants/sort';
import { APPLICATIONS_SEARCH_FIELDS } from 'Constants/filtersByType';

const lsLimitName = 'applicationsLimit';
const defaultLimit = Number(localStorage.getItem(lsLimitName)) || 10;

const initialState = {
  list: [],
  isLoading: false,
  reqProps: {
    start: 0,
    limit: defaultLimit,
    order: formatSortQueryProp(APPLICATIONS_SORT_FIELDS.APPLICATION_CREATED_AT, SORT_DIRECTION.DESC),
    query: '',
  },
  filters: {
    citizenship: [],
    country: [],
    gender: [],
    applicationStatuses: [],
    languages: [],
    userId: null,
    bountyIds: null,
    withCertificates: false,
  },
  searchFields: [
    APPLICATIONS_SEARCH_FIELDS.USER_NAME,
    APPLICATIONS_SEARCH_FIELDS.APPLICANT_POSITION,
  ],
  total: 0,
};

const applicationsReducer = (state = initialState, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case ACTIONS.GET_LIST:
      return {
        ...state,
        list: payload,
      };

    case ACTIONS.ERASE_LIST:
      return {
        ...state,
        list: [],
      };

    case ACTIONS.UPDATE_TOTAL:
      return {
        ...state,
        total: payload,
      };

    case ACTIONS.UPDATE_REQ_PROPS:
      if (payload.limit) {
        localStorage.setItem(lsLimitName, payload.limit);
      }
      return {
        ...state,
        reqProps: {
          ...state.reqProps,
          ...payload,
        },
      };

    case ACTIONS.UPDATE_SEARCH_FIELDS:
      return {
        ...state,
        searchFields: payload,
      };

    case ACTIONS.UPDATE_FILTERS:
      return {
        ...state,
        reqProps: {
          ...state.reqProps,
          ...payload.reqProps,
        },
        filters: {
          ...state.filters,
          ...payload.filters,
        },
      };

    case ACTIONS.RESET_FILTERS:
      return {
        ...state,
        filters: {
          ...initialState.filters,
        },
      };

    case ACTIONS.LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default applicationsReducer;
