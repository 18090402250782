import { belong } from 'Constants/common';

const belongConfig = {
  FLAVOR: 'belong',
  id: belong,
  title: 'Belong',
  name: 'Belong app',
  brandName: 'Belong',
  appLinkSchema: 'belong',
  appTld: 'belong.is',
  firebaseConfig: {
    apiKey: 'AIzaSyA6VeZE9T17hCS0cKxVqGsh6wAf0naAH8w', // Your Api key will be here - this is the prod
    authDomain: 'belong-prod.firebaseapp.com', // Your auth domain
    databaseURL: 'https://belong-prod.firebaseio.com', // data base url
    projectId: 'belong-prod', // project id
    storageBucket: 'belong-prod.appspot.com', // storage bucket
    messagingSenderId: '46644680459', // messaging sender id
    appId: '1:46644680459:web:5c37e76b03fd3e9cd7b40e', // app id
    measurementId: 'G-N93NLBBSCY',
  },
  API_BASE_URL: 'https://api.youkno.ai/api/v1',
  API_REPORT: 'https://api.youkno.ai/download/report',
  login: {
    customCss: '',
    loginWithPin: true,
  },
  algolia_app_id: 'R4HH3UVTXU',
  algolia_api_key: 'e577321c647215eaa4691513316d1597',
  TERMS_OF_SERVICE_URL: 'http://belong.is/terms/',
  PRIVACY_STATEMENT_URL: 'http://belong.is/privacy/',
};

export default belongConfig;
