/* eslint-disable max-classes-per-file */
import { firebaseGetTimestamp } from 'Services/FirebaseService';
import { COMPLETED } from 'Constants/bounty/progressState';
import { SCORE, SURVEY } from 'Constants/bounty/bountyType';
import { isRewardEmpty, rewardToString } from 'Models/Reward';
import * as StringUtils from 'Util/StringUtils';

const alphabet = 'abcdefghijklmnopqrstuvwxyz';
const QUESTION_SEP = '\n--';
const STAR = '★';
const MAX_STAR_INDEX = 5;
const LINE_PAT = /(.*) \(=([0-9]+)?(, ?)?(reward:<(.*)>)?\)$/;
export const BULLET_SEP = ':';
export const BULLET_SEPS = '.:>-';
export const REWARD_PREFIX = 'reward:';
export const DEFAULT_WRONG_ANSWER_WEIGHT = 0;
export const DEFAULT_CORRECT_ANSWER_WEIGHT = 100;

function isEmpty(value) {
  return value === null || value === undefined;
}

export class Option {
  static toString(option) {
    const {
      code, text, weight, reward,
    } = option;
    let buf = '';

    if (isEmpty(code)) {
      buf = text;
    } else if (!text) {
      buf = code;
    } else {
      buf = `${code}${BULLET_SEP} ${text}`;
    }

    if (!isEmpty(weight) || !isEmpty(reward)) {
      buf = `${buf} (=`;

      if (!isEmpty(weight)) {
        buf = `${buf}${weight}`;
      }

      if (!isEmpty(reward) && !isRewardEmpty(reward)) {
        if (!isEmpty(weight)) {
          buf = `${buf}, `;
        }

        buf = `${buf}${REWARD_PREFIX}<${rewardToString(reward)}>`;
      }
      buf = `${buf})`;
    }

    return buf;
  }

  static valueOf(string) {
    const answer = { code: null, text: null };
    const str = string.trim();
    let txt = null;

    if (str) {
      if (str.length === 1) {
        answer.code = str;
      } else {
        const sep = str.charAt(1);

        if (BULLET_SEPS.indexOf(sep) >= 0) {
          answer.code = str.substring(0, 1);
          txt = str.substring(2).trim();
        } else {
          txt = str;
        }
      }
    }

    if (txt !== null) {
      const m = txt.match(LINE_PAT);

      if (m) {
        answer.text = m[1] || '';
        const weightStr = m[2];
        const rewardStr = m[5];

        if (!StringUtils.isEmpty(weightStr)) {
          answer.weight = weightStr;
        }

        if (!StringUtils.isEmpty(rewardStr)) {
          answer.reward = rewardStr;
        }
      } else {
        answer.text = txt || '';
      }
    }

    return answer;
  }
}

export class SurveyResult {
  static getCodeByIndex({ code, index }) {
    const nextIndex = code ? alphabet.indexOf(code.toLowerCase()) + 1 : index;
    return alphabet.charAt(nextIndex).toUpperCase();
  }

  static toString(survey, type = null) {
    const { question, options } = survey;
    const lines = [];

    if (question) {
      lines.push(type && type !== SURVEY ? `${type}${BULLET_SEP} ${question}` : question);
    }

    lines.push(QUESTION_SEP);

    options.forEach((option) => (
      lines.push(Option.toString(option))
    ));

    return Object.values(lines).join('\n');
  }
}

export function valueOf(description, type) {
  const { text } = description;

  if (!text) {
    return null;
  }

  return parse(text, type);
}

export function parse(str, type) {
  const indexOfQuestionSep = str.indexOf(QUESTION_SEP);
  let question = null;
  const options = [];

  if (indexOfQuestionSep < 0) {
    question = str.trim();
  } else {
    question = str.substring(0, indexOfQuestionSep).trim();
    const optionsString = str.substring(indexOfQuestionSep + QUESTION_SEP.length).trim();
    const rawOptions = optionsString.trim().split('\n');

    rawOptions.forEach((option) => (
      options.push(Option.valueOf(option))
    ));
  }

  if (question) {
    if (question.startsWith(`${type}${BULLET_SEP}`)) {
      question = question.substring(question.indexOf(BULLET_SEP) + 1).trim();
    }
  }

  return {
    question,
    options,
    type,
  };
}

export function getTotalCount(answers) {
  if (answers) {
    return Object.values(answers).reduce((acc, currentValue) => (acc + currentValue.count), 0);
  }

  return 0;
}

export function getAnswerCount(answers, code) {
  const answer = answers[code] || {};
  return answer.count || 0;
}

export function getCompletedSurveyProps() {
  const ts = firebaseGetTimestamp();

  return {
    status: COMPLETED,
    progress: 1,
    workStartedAt: ts,
    workCompletedAt: ts,
  };
}

export function setSurveyDescription(surveyOptionsData, description, bountyType) {
  const survey = {
    question: description,
    options: [],
  };

  survey.options = Object.values(surveyOptionsData);
  return { text: SurveyResult.toString(survey, bountyType) };
}

export function setScoreDescription(description) {
  const survey = {
    question: description,
    options: [],
  };

  for (let i = MAX_STAR_INDEX; i > 0; i--) {
    let optionStars = '';

    for (let j = 0; j < i; j++) {
      optionStars += STAR;
    }

    survey.options.push({
      code: SurveyResult.getCodeByIndex({ index: MAX_STAR_INDEX - i }),
      text: optionStars,
      weight: i,
    });
  }

  return { text: SurveyResult.toString(survey, SCORE) };
}

export function isCorrectChoice(option, correctWeight) {
  return !!(option.weight && +option.weight === correctWeight);
}

export function isCorrectAnswer(answer) {
  return !!(answer && answer.maxPoints && answer.maxPoints === answer.answerPoints);
}
