import { useEffect, useState } from 'react';
import { func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { FormControlLabel, Select, MenuItem, Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useIntl } from 'react-intl';

//  Actions/Selectors
import { selectedBountySelector } from 'Modules/companySents/store/selectors';
import { listDistributionTargets } from 'Services/bounty/BountyService';
import { updateDataSyncAction } from 'Store/actions/genericActions';

//  Other resources
import { distTargetGroupField } from 'Constants/fields';
import { MODAL_NAMESPACE } from 'Store/namespaces';
import { MODAL_OPTIONS_DATA } from 'Store/reducerProperties';
import {
  extractDistributionTargetList,
  extractDistributionTargetCodes,
  filterSelectedDistributionAreas,
} from 'Models/bounty/DistributionGroup';
import IntlMessages from '../../../../util/IntlMessages';

const propTypes = {
  updateDataSyncAction: func.isRequired,
  selectedBounty: object.isRequired,
  bountyType: string.isRequired,
  classes: object.isRequired,
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250,
    },
  },
};

const TargetGroupContainer = (props) => {
  const [targetList, setTargetList] = useState([]);
  const [selectedTargetCodes, setSelectedTargetsCodes] = useState([]);
  const [targetsDetails, setTargetsDetails] = useState([]);
  const { selectedBounty, bountyType, classes } = props;
  const intl = useIntl();

  const setTargetsInfo = (codes, details) => {
    setSelectedTargetsCodes(codes);
    setTargetsDetails(details);
    props.updateDataSyncAction(MODAL_NAMESPACE, MODAL_OPTIONS_DATA, { distribution: details });
  };

  const setInitialTargetList = () => {
    const details = extractDistributionTargetList(selectedBounty.distribution);
    const codes = extractDistributionTargetCodes(details);
    setTargetsInfo(codes, details);
  };

  useEffect(() => {
    setInitialTargetList();
    listDistributionTargets({ bountyId: selectedBounty.id, bountyType })
      .then((response) => setTargetList(response.list));
  }, []);

  const handleChange = (event) => {
    const { target: { value } } = event;
    const details = filterSelectedDistributionAreas(targetList, value);
    setTargetsInfo(value, details);
  };

  return (
    <div>
      <FormControlLabel
        classes={{
          root: classes.formRoot,
          label: classes.formLabel,
        }}
        control={(
          <Select
            size="small"
            displayEmpty
            multiple
            label={null}
            value={selectedTargetCodes}
            onChange={handleChange}
            MenuProps={MenuProps}
            renderValue={() => (
              targetsDetails.length
                ? (
                  <div className={classes.chips}>
                    {targetsDetails.map((target) => (
                      <Chip key={target.code} label={target.text} className={classes.chip} />
                    ))}
                  </div>
                ) : (
                  <div><IntlMessages id="label.all" /></div>
                )
            )}
          >
            {targetList && targetList.map((target) => (
              <MenuItem
                key={target.code}
                value={target.code}
              >
                {target.text}
              </MenuItem>
            ))}
          </Select>
        )}
        label={intl.formatMessage({ id: distTargetGroupField.text })}
        labelPlacement="start"
      />
    </div>
  );
};

TargetGroupContainer.propTypes = propTypes;

const mapStateToProps = (state) => ({
  selectedBounty: selectedBountySelector(state) || {},
});

const mapDispatchToProps = {
  updateDataSyncAction,
};

const styles = {
  formRoot: {
    margin: 0,
  },
  formLabel: {
    paddingRight: 10,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TargetGroupContainer));
