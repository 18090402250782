import moment from 'moment';
import { USER } from 'Models/EntityType';

export const getLevel1Id = ({ id }) => id ?? '';

export const getLevel2Id = () => null;

export const getEntityType = () => USER;

export const getCreator = (user) => user;

export const getUserAge = (dob) => {
  if (dob) {
    const currentDate = moment();
    return currentDate.diff(moment(dob), 'years');
  }

  return '-';
};
