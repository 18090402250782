import { createSelector } from 'reselect';
import { userDataSelector, settingsSelector } from 'Store/selectors/settings';
import { isAllowed } from 'Models/Settings';
import * as Ops from 'Models/Op';
import { PRODUCT, JOB } from 'Constants/bounty/bountyType';
import { getAllowedBountyTypes } from 'Modules/posts/add/utils/createBountyOptionsDialog';
import { getAllowedCompanyTypes } from 'Modules/posts/add/utils/createOrgOptionsDialog';
import { getStreamListDefs } from 'Models/ListManager';
import { MATCH_DETAILS } from 'Modules/posts/bounty/constants/listType';

export const isAllowedToUploadProductsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => {
    const allowedBountyTypes = getAllowedBountyTypes(settings, userData.data);
    return allowedBountyTypes.includes(PRODUCT);
  },
);

export const routePermissionsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => {
    const allowedBountyTypes = getAllowedBountyTypes(settings, userData.data);
    const streamLists = getStreamListDefs(settings, userData.data);

    const hasMatchDetails = streamLists.find((list) => list.id === MATCH_DETAILS);

    return ({
      isAllowedToUploadProducts: allowedBountyTypes.includes(PRODUCT),
      isAllowedToCreateJobs: allowedBountyTypes.includes(JOB), // TODO: remove this if a new op for SEE_JOBS is added
      isAllowedToNotify: isAllowed({ op: Ops.SEND_NOTIFICATIONS.name, settings, userData: userData.data }),
      isAllowedToSeePromotions: isAllowed({ op: Ops.CREATE_PROMOTION.name, settings, userData: userData.data }),
      isAllowedToCreateBadge: isAllowed({ op: Ops.CREATE_BADGE.name, settings, userData: userData.data }),
      isAllowedToSeeReports: isAllowed({ op: Ops.SEE_REPORTS.name, settings, userData: userData.data }),
      isAllowedToSeeLeaderBoard: isAllowed({ op: Ops.SEE_LEADERBOARD.name, settings, userData: userData.data }),
      isAllowedToSeeMarket: isAllowed({ op: Ops.SEE_MARKET.name, settings, userData: userData.data }),
      isAllowedToSeeMyAccount: isAllowed({ op: Ops.SEE_ACCOUNT.name, settings, userData: userData.data }),
      isAllowedToSeeCompanies: isAllowed({ op: Ops.SEE_COMPANIES.name, settings, userData: userData.data }),
      isAllowedToSeeResponses: isAllowed({ op: Ops.SEE_RESPONSES.name, settings, userData: userData.data }),
      isAllowedToSeeRecvOrders: isAllowed({ op: Ops.SEE_RECV_ORDERS.name, settings, userData: userData.data }),
      isAllowedToSeeGroups: isAllowed({ op: Ops.SEE_GROUPS.name, settings, userData: userData.data }),
      isAllowedToSeeApplicants: isAllowed({ op: Ops.SEE_APPLICANTS.name, settings, userData: userData.data }),
      isAllowedToUpdateAccount: isAllowed({ op: Ops.UPDATE_ACCOUNT.name, settings, userData: userData.data }),
      isAllowedToSeeBlackList: isAllowed({ op: Ops.CAN_BLACKLIST.name, settings, userData: userData.data }),
      isAllowedToCreateOrganization: isAllowed({ op: Ops.CREATE_ORGANIZATION.name, settings, userData: userData.data }),
      isAllowedShell: isAllowed('SHELL_ACCESS'),
      isAllowedToSeeMatch: !!hasMatchDetails,
    });
  },
);

export const canSeeRealIdentitySelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_REAL_IDENTITY.name, settings, userData: userData.data }),
);

export const canSeePrivateInfoSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_PRIVATE_INFO.name, settings, userData: userData.data }),
);

export const canAddToBlackListSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_BLACKLIST.name, settings, userData: userData.data }),
);

export const canUpdateAccountSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.UPDATE_ACCOUNT.name, settings, userData: userData.data }),
);

export const canSeeMarketSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_MARKET.name, settings, userData: userData.data }),
);

export const canSeeNegotiatedAmountSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_NEGOTIATE_PRICE.name, settings, userData: userData.data }),
);

export const canSeeGroupEverybodySelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_GROUP_EVERYBODY.name, settings, userData: userData.data }),
);

export const allowedOrganizationTypesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => getAllowedCompanyTypes(settings, userData.data),
);

export const allowedBountyTypesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => getAllowedBountyTypes(settings, userData.data),
);

export const canSeeResponsesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_RESPONSES.name, settings, userData: userData.data }),
);

export const canSeeGlobalReportSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_GLOBAL_REPORTS.name, settings, userData: userData.data }),
);

export const canSeeOnlyInviteesReportsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_ONLY_INVITEES_REPORTS.name, settings, userData: userData.data }),
);

export const canSeeKycSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.KYC_REVIEW.name, settings, userData: userData.data }),
);

export const canControlKYCSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CONTROL_KYC.name, settings, userData: userData.data }),
);

export const canSeeBasicKYCSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_BASIC_KYC.name, settings, userData: userData.data }),
);

export const canSeeApplicantsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_APPLICANTS.name, settings, userData: userData.data }),
);

export const canControlResponsesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CONTROL_RESPONSES.name, settings, userData: userData.data }),
);

export const canEditCapsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.EDIT_CAPS.name, settings, userData: userData.data }),
);

export const canSeePushNotificationsSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.MANAGE_CAMPAIGN.name, settings, userData: userData.data }),
);

export const canSeeSearchModeFilterSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_SEARCH_MODE_FILTER.name, settings, userData: userData.data }),
);

export const canSeeEmployeesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.SEE_EMPLOYEES.name, settings, userData: userData.data }),
);

export const canArchiveChatSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_ARCHIVE_CHAT.name, settings, userData: userData.data }),
);

export const canAttachProductSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.CAN_ATTACH_PRODUCT.name, settings, userData: userData.data }),
);

export const canAssignRolesSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.ASSIGN_ROLES.name, settings, userData: userData.data }),
);

export const canEditUserProfileSelector = createSelector(
  userDataSelector,
  settingsSelector,
  (userData, settings) => isAllowed({ op: Ops.EDIT_USER_PROFILE.name, settings, userData: userData.data }),
);
