import { string, number, bool, object, oneOfType } from 'prop-types';

//  Others
import IntlMessages from 'Util/IntlMessages';

const propTypes = {
  emptyImage: string.isRequired,
  title: string,
  subTitle: string,
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  values: object,
  withTranslation: bool,
  withSubtitle: bool,
};

const defaultProps = {
  subTitle: 'emptyContent.defaultSubtitle',
  title: '',
  width: 158,
  height: 150,
  values: {},
  withTranslation: true,
  withSubtitle: false,
};

const EmptyResults = ({ emptyImage, title, subTitle, width, height, values, withTranslation, withSubtitle }) => (
  <div className="text-center mt-30">
    <div
      style={{ backgroundImage: `url(${emptyImage})`, width, height }}
      className="image-post m-0-auto mb-20"
    />
    { withTranslation ? (
      <>
        { title && <h3 className="black-color font-21 font-500"><IntlMessages id={title} values={values} /></h3> }
        {(subTitle || withSubtitle) && (
          <p className="black-color font-21"><IntlMessages id={subTitle} values={values} /></p>
        )}
      </>
    ) : (
      <>
        { title && <h3 className="black-color font-21 font-500">{title}</h3> }
        {(subTitle || withSubtitle) && <p className="black-color font-21">{subTitle}</p>}
      </>
    )}
  </div>
);

EmptyResults.propTypes = propTypes;
EmptyResults.defaultProps = defaultProps;

export default EmptyResults;
