import { BigPlayButton, ControlBar, Player } from 'video-react';
import { CardMedia } from '@mui/material';
import classnames from 'classnames';

//  Others
import pdfPlaceholder from 'Assets/img/general/pdf-placeholder.png';
import {
  DISPLAY_STYLE,
  VISIBILITY,
  MEANING,
  ATTACHMENT_STATUS,
} from 'Constants/attachment';
import audioPoster from 'Assets/img/general/audio-cover.png';
import { PRODUCT, PROMOTION } from 'Constants/bounty/bountyType';

const reYoutubeUrl = /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;

export function getYoutubeLinks(text) {
  if (!text) {
    return [];
  }

  const youtubeLinks = [];
  const content = text.replace(/[\r\n]+/gm, ' ').trim();

  if (content && reYoutubeUrl.test(content)) {
    content.split(' ').forEach((word) => {
      if (reYoutubeUrl.test(word.trim())) {
        const embedUrl = word.trim()
          .replace('watch?v=', 'embed/')
          .replace('youtu.be', 'youtube.com/embed/');
        youtubeLinks.push(embedUrl.split('&')[0]);
      }
    });
  }

  return youtubeLinks;
}

export function getDisplayStyleAsEnum(style) {
  if (!style || !DISPLAY_STYLE[style]) {
    return DISPLAY_STYLE.ATTACH;
  }

  return style;
}

export function getVisibilityAsEnum(visibility) {
  if (!visibility) {
    return VISIBILITY.ALL;
  }

  return VISIBILITY[visibility] || VISIBILITY.NONE;
}

export function getMeaningAsEnum(meaning) {
  if (!meaning || !MEANING[meaning]) {
    return MEANING.OTHER;
  }

  return meaning;
}

export function getAttachmentStatusAsEnum(status) {
  if (!status || !ATTACHMENT_STATUS[status]) {
    return ATTACHMENT_STATUS.ACTIVE;
  }

  return status;
}

// eslint-disable-next-line react/prop-types
export function renderImage({ url, className = '' }) {
  return (
    <CardMedia
      className={classnames('image-post', className)}
      component="img"
      alt="cropped_img"
      image={url}
    />
  );
}

// eslint-disable-next-line react/prop-types
export function renderPdf({ url, width }) {
  return (
    <embed
      src={url}
      width={width || '100%'}
      height="100%"
    />
  );
}

// eslint-disable-next-line react/prop-types
export function renderDefaultPdf({ className = '' }) {
  return (
    <img
      className={className}
      src={pdfPlaceholder}
      alt="cropped_img"
      // width="100%"
      // height="100%"
    />
  );
}

// eslint-disable-next-line react/prop-types
export function renderVideo({ url, height }) {
  return (
    <div className="full-height">
      <Player
        src={url}
        fluid={false}
        height={height}
        width="100%"
      >
        <ControlBar autoHide={false}>
          <BigPlayButton position="center" />
        </ControlBar>
      </Player>
    </div>
  );
}

// eslint-disable-next-line react/prop-types
export function renderAudio({ url, height }) {
  return (
    <div className="full-height">
      <Player
        poster={audioPoster}
        src={url}
        fluid={false}
        height={height}
        width="100%"
      >
        <ControlBar autoHide={false}>
          <BigPlayButton position="center" />
        </ControlBar>
      </Player>
    </div>
  );
}

export function getPreviewConfig(attachments) {
  if (attachments.length === 1) {
    return [
      {
        source: attachments[0].url,
        type: attachments[0].type,
        cols: 2,
        rows: 1,
      },
    ];
  }

  if (attachments.length === 2) {
    return [
      {
        source: attachments[0].url,
        type: attachments[0].type,
        cols: 1,
        rows: 1,
      },
      {
        source: attachments[1].url,
        type: attachments[1].type,
        cols: 1,
        rows: 1,
      },
    ];
  }

  if (attachments.length === 3) {
    return [
      {
        source: attachments[0].url,
        type: attachments[0].type,
        cols: 1,
        rows: 1,
      },
      {
        source: attachments[1].url,
        type: attachments[1].type,
        cols: 1,
        rows: 1,
      },
      {
        source: attachments[2].url,
        type: attachments[2].type,
        cols: 2,
        rows: 1,
      },
    ];
  }

  if (attachments.length > 3) {
    return [
      {
        source: attachments[0].url,
        type: attachments[0].type,
        cols: 1,
        rows: 1,
      },
      {
        source: attachments[1].url,
        type: attachments[1].type,
        cols: 1,
        rows: 1,
      },
      {
        source: attachments[2].url,
        type: attachments[2].type,
        cols: 1,
        rows: 1,
      },
      {
        source: attachments[3].url,
        type: attachments[3].type,
        cols: 1,
        rows: 1,
      },
    ];
  }
}

export function getAttachments(attachments, visibility = VISIBILITY.ALL) {
  return Object
    .values(attachments)
    .filter((att) => getVisibilityAsEnum(att.visibility) === visibility)
    .sort((a, b) => +a.order - +b.order);
}

export function getAttachmentsWithoutCustomMeaning(bounty) {
  return bounty.attachments && bounty.attachments.attachments
    ? Object
      .values(bounty.attachments.attachments)
      .filter((attach) => (![PROMOTION, PRODUCT].includes(attach.meaning)))
    : [];
}
