import { func, object, string, bool, number } from 'prop-types';
import { Box, Checkbox, IconButton, InputAdornment, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import { isCorrectChoice } from 'Models/survey/Survey';
import { isCheckable } from '../utils/choicesHandler';

const ChoiceComponent = (props) => {
  const {
    defaultData,
    bountyType,
    option,
    hasDelete,
    correctWeight,
    handleCorrectChoiceSelection,
    handleSurveyOptionsChanges,
    handleDeleteOption,
    ...otherProps
  } = props;
  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: 'bounty.choiceCode' }, { code: option.code });

  const isChecked = isCorrectChoice(option, correctWeight);

  return (
    <Box display="flex" justifyContent="space-between">
      {isCheckable(bountyType) && (
        <Checkbox
          value={option.code}
          className={classnames({ 'text-success': isChecked })}
          checked={isChecked}
          onChange={handleCorrectChoiceSelection}
        />
      )}
      <TextField
        fullWidth
        className="mt-10 mb-10"
        margin="dense"
        onChange={handleSurveyOptionsChanges}
        defaultValue={defaultData[option.code] ? defaultData[option.code].text : ''}
        name={option.code}
        placeholder={placeholder}
        {...otherProps}
        InputProps={
          hasDelete ? ({
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  className="text-danger mb-15"
                  aria-label="Delete"
                  onClick={handleDeleteOption}
                  size="large"
                >
                  <i className="zmdi zmdi-delete" />
                </IconButton>
              </InputAdornment>
            ),
          }) : {}
        }
      />
    </Box>
  );
};

ChoiceComponent.propTypes = {
  handleSurveyOptionsChanges: func.isRequired,
  handleCorrectChoiceSelection: func.isRequired,
  handleDeleteOption: func.isRequired,
  bountyType: string.isRequired,
  option: object.isRequired,
  correctWeight: number,
  hasDelete: bool,
  defaultData: object,
};

ChoiceComponent.defaultProps = {
  correctWeight: 0,
  hasDelete: false,
  defaultData: {},
};

export default ChoiceComponent;
