//  Others
import {
  firebaseGetMyCompanySettingsRef,
  firebaseGetSystemInfoRef,
  firebaseGetSystemSettingsRef,
  firebaseGetOrgTypeSettingsRef,
  firebaseGetUserDataRef,
  firebaseGetCurrentUser,
} from 'Services/FirebaseService';
import {
  isLoadingAction,
  updateDataAsyncAction,
} from 'Store/actions/genericActions';
import * as reducerProperties from 'Store/reducerProperties';
import { SETTINGS_NAMESPACE } from 'Store/namespaces';

export const getUserData = ({ userId }) => (dispatch) => (
  new Promise((resolve, reject) => {
    dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.USER_DATA, true));

    firebaseGetUserDataRef(userId).on('value', (dataSnapshot) => {
      const user = dataSnapshot.val() || {};
      if (!user.myself) {
        const fbUser = firebaseGetCurrentUser();

        user.myself = {
          id: fbUser.uid,
          name: fbUser.displayName,
          avatar: fbUser.photoURL || null,
        };
      }

      dispatch(updateDataAsyncAction(SETTINGS_NAMESPACE, reducerProperties.USER_DATA, user));
      dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.USER_DATA, false));

      resolve(user);
    }, (error) => {
      dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.USER_DATA, false));
      reject(error);
    });
  })
    .catch((error) => console.log('userData err:', error)) // eslint-disable-line
);

export const getCompanySettings = ({ companyId }) => (dispatch) => (
  new Promise((resolve, reject) => {
    dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.COMPANY_SETTINGS, true));
    firebaseGetMyCompanySettingsRef(companyId).on('value', (dataSnapshot) => {
      const result = dataSnapshot.val();

      dispatch(updateDataAsyncAction(SETTINGS_NAMESPACE, reducerProperties.COMPANY_SETTINGS, result));
      dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.COMPANY_SETTINGS, false));

      resolve(result);
    }, (error) => {
      dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.COMPANY_SETTINGS, false));
      reject(error);
    });
  })
    .catch((error) => console.log('companySettings err:', error))// eslint-disable-line
);

export const getSystemSettings = () => (dispatch) => (
  new Promise((resolve, reject) => {
    dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.SYSTEM_SETTINGS, true));
    firebaseGetSystemSettingsRef()
      .on('value', (dataSnapshot) => {
        const result = dataSnapshot.val();

        dispatch(updateDataAsyncAction(SETTINGS_NAMESPACE, reducerProperties.SYSTEM_SETTINGS, result));
        dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.SYSTEM_SETTINGS, false));
        resolve(result);
      }, (error) => {
        dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.SYSTEM_SETTINGS, false));
        reject(error);
      });
  })
    .catch((error) => console.log('systemSettings err:', error))// eslint-disable-line
);

export const getOrgTypeSettings = (orgType) => (dispatch) => (
  new Promise((resolve, reject) => {
    if (!orgType) {
      return resolve();
    }

    dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.ORG_TYPE_SETTINGS, true));
    firebaseGetOrgTypeSettingsRef(orgType)
      .on('value', (dataSnapshot) => {
        const result = dataSnapshot.val();

        dispatch(updateDataAsyncAction(SETTINGS_NAMESPACE, reducerProperties.ORG_TYPE_SETTINGS, result));
        dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.ORG_TYPE_SETTINGS, false));
        resolve(result);
      }, (error) => {
        dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.ORG_TYPE_SETTINGS, false));
        reject(error);
      });
  })
    .catch((error) => console.log('orgTypeSettings err:', error))// eslint-disable-line
);

export const getSystemInfo = () => (dispatch) => (
  new Promise((resolve, reject) => {
    dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.SYSTEM_INFO, true));
    firebaseGetSystemInfoRef()
      .on('value', (dataSnapshot) => {
        const result = dataSnapshot.val();

        dispatch(updateDataAsyncAction(SETTINGS_NAMESPACE, reducerProperties.SYSTEM_INFO, result));
        dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.SYSTEM_INFO, false));
        resolve(result);
      }, (error) => {
        dispatch(isLoadingAction(SETTINGS_NAMESPACE, reducerProperties.SYSTEM_INFO, false));
        reject(error);
      });
  })
    .catch((error) => console.log('systemInfo err:', error))// eslint-disable-line
);
