import { getApplicationConfig } from 'Util/AppUtils';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';

const { firebaseConfig } = getApplicationConfig();

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

export default firebaseApp;
